.loader-container {
    margin-bottom: 10px;
    text-align: center;
    margin-top: 10px;
}
.loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #555;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    display: inline-block;
    vertical-align: middle;
}
/* .loader-text {
    font-size:1.1em;
    line-height: 30px;
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
} */
.pager-loader-container {
    position: absolute;
    top: 0;
    width: 370px;
    text-align: center;
    vertical-align: middle;
    height: 35px;
    padding-top: 5px;
    right: 0;
    background-color: rgba(230, 230, 230, 0.5);
    z-index: 9;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.slick-prev {
    left: 10px;
    z-index: 9;
    width: 40px;
    height: 40px;
    top: 50%;
}
.slick-next {
    right: 10px;
    z-index: 9;
    width: 40px;
    height: 40px;
    top: 50%;
}
.slick-prev:before, .slick-next:before {
    font-size: 40px;
    color:grey;
}
.toggle.btn {
    border: 1px solid transparent;
}
/* .toggle-handle {
    top: 0 !important;
    background-color: #fff !important;
} */
.toggle-on.btn, .toggle-off.btn {
    display: inline-block;
}
/* .toggle-off.btn, .toggle-on.btn {
    background-color: #f7951f !important;
} */

body,
html {
    height:100%;
}
body,
figure {
    margin: 0
}
.navbar-fixed-bottom .navbar-collapse,
.navbar-fixed-top .navbar-collapse,
.pre-scrollable {
    max-height: 340px
}
*,
:after,
:before {
    box-sizing: border-box
}
body {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 15px;
    line-height: 1.428571429
}
a:focus,
a:hover {
    color: #000000;
    text-decoration: underline
}
hr {
    margin-top: 21px;
    margin-bottom: 21px;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family:"Roboto", Helvetica, Arial, sans-serif;
    font-weight: 600;
    color: #44515e;
}
.h1 .small,
.h1 small,
.h2 .small,
.h2 small,
.h3 .small,
.h3 small,
.h4 .small,
.h4 small,
.h5 .small,
.h5 small,
.h6 .small,
.h6 small,
h1 .small,
h1 small,
h2 .small,
h2 small,
h3 .small,
h3 small,
h4 .small,
h4 small,
h5 .small,
h5 small,
h6 .small,
h6 small {
    color: #f5f5f5
}
.h1,
.h2,
.h3,
h1,
h2,
h3 {
    margin-top: 21px;
    margin-bottom: 10.5px
}
.h4,
.h5,
.h6,
h4,
h5,
h6 {
    margin-top: 10.5px;
    margin-bottom: 10.5px
}
.h1,
h1 {
    font-size: 45px
}
.h2,
h2 {
    font-size:27px
}
.h3,
h3 {
    font-size:20px
}
.h4,
.h5,
h4,
h5 {
    font-size: 16px
}
p {
    margin: 0 0 10.5px
}
.lead {
    margin-bottom: 21px;
    font-size: 17px;
    font-weight: 300;
    line-height: 1.4
}
/* dt,
kbd kbd,
label {
    font-weight: 700
} */
address,
blockquote .small,
blockquote footer,
blockquote small,
dd,
dt,
pre {
    line-height: 1.428571429
}

.small,
small {
    font-size: 86%
}
.text-muted {
    color: #f5f5f5
}
.text-primary {
    color: #a2c037
}
a.text-primary:hover {
    color: #81982b
}
.bg-primary {
    color: #fff;
    background-color: #a2c037
}
a.bg-primary:hover {
    background-color: #81982b
}
pre code,
table {
    background-color: transparent
}
.page-header {
    padding-bottom: 9.5px;
    margin: 42px 0 21px;
    border-bottom: 1px solid #eee
}
dl,
ol,
ul {
    margin-top: 0
}
blockquote ol:last-child,
blockquote p:last-child,
blockquote ul:last-child,
ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}
address,
dl {
    margin-bottom: 21px
}
ol,
ul {
    margin-bottom: 10.5px
}
.dl-horizontal dd:after,
.dl-horizontal dd:before {
    content: " ";
    display: table
}
.dl-horizontal dd:after {
    clear: both
}
.clearfix:after,
.container-fluid:after,
.container:after,
.dropdown-menu>li>a,
.form-horizontal .form-group:after,
.modal-footer:after,
.navbar-collapse:after,
.navbar-header:after,
.navbar:after,
.row:after,
.ui-helper-clearfix:after {
    clear: both;
    white-space: inherit;
}
blockquote .small,
blockquote footer,
blockquote small {
    display: block;
    font-size: 80%;
    color: #f5f5f5
}
legend,
pre {
    color: #7f7f7f
}
code,
kbd {
    padding: 2px 4px;
    font-size: 90%
}
.table {
    width: 100%;
    margin-bottom: 21px
}
.table .table {
    background-color: #173C6A
}
input[type=search] {
    box-sizing: border-box;
    -webkit-appearance: none
}
.form-control,
output {
    font-size: 15px;
}
.form-control {
    height: 30px;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s
}
.form-control::-moz-placeholder {
    color: #555;
}
.form-control:-ms-input-placeholder {
    color: #555
}
.form-control::-webkit-input-placeholder {
    color: #555
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type=date],
    input[type=time],
    input[type=datetime-local],
    input[type=month] {
        line-height: 35px
    }
    .input-group-sm input[type=date],
    .input-group-sm input[type=time],
    .input-group-sm input[type=datetime-local],
    .input-group-sm input[type=month],
    .input-group-sm>.input-group-btn>input[type=date].btn,
    .input-group-sm>.input-group-btn>input[type=time].btn,
    .input-group-sm>.input-group-btn>input[type=datetime-local].btn,
    .input-group-sm>.input-group-btn>input[type=month].btn,
    .input-group-sm>input[type=date].form-control,
    .input-group-sm>input[type=date].input-group-addon,
    .input-group-sm>input[type=time].form-control,
    .input-group-sm>input[type=time].input-group-addon,
    .input-group-sm>input[type=datetime-local].form-control,
    .input-group-sm>input[type=datetime-local].input-group-addon,
    .input-group-sm>input[type=month].form-control,
    .input-group-sm>input[type=month].input-group-addon,
    input[type=date].input-sm,
    input[type=time].input-sm,
    input[type=datetime-local].input-sm,
    input[type=month].input-sm {
        line-height: 31px
    }
    .input-group-lg input[type=date],
    .input-group-lg input[type=time],
    .input-group-lg input[type=datetime-local],
    .input-group-lg input[type=month],
    .input-group-lg>.input-group-btn>input[type=date].btn,
    .input-group-lg>.input-group-btn>input[type=time].btn,
    .input-group-lg>.input-group-btn>input[type=datetime-local].btn,
    .input-group-lg>.input-group-btn>input[type=month].btn,
    .input-group-lg>input[type=date].form-control,
    .input-group-lg>input[type=date].input-group-addon,
    .input-group-lg>input[type=time].form-control,
    .input-group-lg>input[type=time].input-group-addon,
    .input-group-lg>input[type=datetime-local].form-control,
    .input-group-lg>input[type=datetime-local].input-group-addon,
    .input-group-lg>input[type=month].form-control,
    .input-group-lg>input[type=month].input-group-addon,
    input[type=date].input-lg,
    input[type=time].input-lg,
    input[type=datetime-local].input-lg,
    input[type=month].input-lg {
        line-height: 48px
    }
}
.form-group {
    margin-bottom: 5px
}
.checkbox label,
.radio label {
    min-height: 21px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer
}
.form-control-static {
    min-height: 36px
}
.input-group-sm>.form-control,
.input-group-sm>.input-group-addon,
.input-group-sm>.input-group-btn>.btn,
.input-sm {
    height: 31px;
    font-size: 13px;
}
.input-group-sm>.input-group-btn>select.btn,
.input-group-sm>select.form-control,
.input-group-sm>select.input-group-addon,
select.input-sm {
    height: 31px;
    line-height: 31px
}
.input-group-sm>.input-group-btn>select[multiple].btn,
.input-group-sm>.input-group-btn>textarea.btn,
.input-group-sm>select[multiple].form-control,
.input-group-sm>select[multiple].input-group-addon,
.input-group-sm>textarea.form-control,
.input-group-sm>textarea.input-group-addon,
select[multiple].input-sm,
textarea.input-sm {
    height: auto
}
.form-group-sm .form-control {
    height: 31px;
}
.form-group-sm select.form-control {
    height: 31px;
    line-height: 31px
}
.form-group-sm .form-control-static {
    height: 31px;
    padding: 5px 10px;
    font-size: 13px;
    line-height: 1.5;
    min-height: 34px
}
.input-group-lg>.form-control,
.input-group-lg>.input-group-addon,
.input-group-lg>.input-group-btn>.btn,
.input-lg {
    height: 48px;
}
.input-group-lg>.input-group-btn>select.btn,
.input-group-lg>select.form-control,
.input-group-lg>select.input-group-addon,
select.input-lg {
    height: 48px;
    line-height: 48px
}
.form-group-lg .form-control {
    height: 48px;
    font-size: 19px;
}
.form-group-lg select.form-control {
    height: 48px;
    line-height: 48px
}
.form-group-lg select[multiple].form-control,
.form-group-lg textarea.form-control {
    height: auto
}
.form-group-lg .form-control-static {
    height: 48px;
    padding: 10px 16px;
    font-size: 19px;
    line-height: 1.3333333;
    min-height: 40px
}
.has-feedback {
    position: relative
}
.has-feedback .form-control {
    padding-right: 43.75px
}
.form-control-feedback {
    width: 35px;
    height: 35px;
    line-height: 35px;
}
.collapsing,
.dropdown,
.dropup {
    position: relative
}
.input-group-lg>.form-control+.form-control-feedback,
.input-group-lg>.input-group-addon+.form-control-feedback,
.input-group-lg>.input-group-btn>.btn+.form-control-feedback,
.input-lg+.form-control-feedback {
    width: 48px;
    height: 48px;
    line-height: 48px
}
.input-group-sm>.form-control+.form-control-feedback,
.input-group-sm>.input-group-addon+.form-control-feedback,
.input-group-sm>.input-group-btn>.btn+.form-control-feedback,
.input-sm+.form-control-feedback {
    width: 31px;
    height: 31px;
    line-height: 31px
}
.has-success .form-control:focus {
    border-color: #2b542c;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #67b168
}
.has-success .input-group-addon {
    color: #3c763d;
    border-color: #3c763d;
    background-color: #dff0d8
}
.has-feedback label~.form-control-feedback {
    top: 26px
}
.help-block {
    color: #959595
}
.btn-block,
input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
    width: 100%
}
.form-horizontal .checkbox,
.form-horizontal .radio {
    min-height: 28px
}

.btn.focus,
.btn:focus,
.btn:hover {
    color: #fff;
}

.btn-primary {
    border-color: #fff
}
/* .btn-primary.active,
.btn-primary.focus,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.open>.btn-primary.dropdown-toggle {
    color: #44515e;
    background-color: #e6e6e6;
    border-color: #e0e0e0
} */
.btn-primary.disabled,
.btn-primary.disabled.active,
.btn-primary.disabled.focus,
.btn-primary.disabled:active,
.btn-primary.disabled:focus,
.btn-primary.disabled:hover,
.btn-primary[disabled],
.btn-primary[disabled].active,
.btn-primary[disabled].focus,
.btn-primary[disabled]:active,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary.active,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:hover {
    background-color: #fff;
    border-color: #fff
}
.btn-primary .badge {
    color: #fff;
    background-color: #44515e
}
.btn-info.active,
.btn-info.focus,
.btn-info:active,
.btn-info:focus,
.btn-info:hover,
.open>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #31b0d5;
    border-color: #269abc
}
.btn-warning.active,
.btn-warning.focus,
.btn-warning:active,
.btn-warning:focus,
.btn-warning:hover,
.open>.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #ec971f;
    border-color: #d58512
}
.btn-danger.active,
.btn-danger.focus,
.btn-danger:active,
.btn-danger:focus,
.btn-danger:hover,
.open>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925
}
.btn-link {
    color: #a2c037;
    font-weight: 400;
    border-radius: 0
}
.btn-link[disabled]:focus,
.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:focus,
fieldset[disabled] .btn-link:hover {
    color: #f5f5f5;
    text-decoration: none
}
.btn-lg {
    font-size: 19px;
}
.btn-sm,
.btn-xs {
    font-size: 13px;
}
.btn-sm {
    padding: 5px 10px
}
.btn-xs {
    padding: 1px 5px
}
.dropdown-menu>li>a {
    font-weight: 400;
    color: #7f7f7f
}
.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover {
    color: #727272;
}
.dropdown-menu>.active>a,
.dropdown-menu>.active>a:focus,
.dropdown-menu>.active>a:hover {
    background-color: #a2c037
}
.dropdown-header,
.dropdown-menu>.disabled>a,
.dropdown-menu>.disabled>a:focus,
.dropdown-menu>.disabled>a:hover,
.nav>li.disabled>a {
    color: #f5f5f5
}
.dropdown:hover > .dropdown-menu {
    display: block;
}
.dropdown-header {
    font-size: 13px
}
.input-group-addon {
    padding: 6px 12px;
    font-size: 15px;
}
.input-group-addon.input-sm,
.input-group-sm>.input-group-addon,
.input-group-sm>.input-group-btn>.input-group-addon.btn {
    font-size: 13px;
}
.input-group-addon.input-lg,
.input-group-lg>.input-group-addon,
.input-group-lg>.input-group-btn>.input-group-addon.btn {
    font-size: 19px;
}
.nav>li.disabled>a:focus,
.nav>li.disabled>a:hover {
    color: #f5f5f5;
}
/* .nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
    border-color: #a2c037
}
.nav .nav-divider {
    margin: 9.5px 0;
}
.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
    color: grey;
    background-color: #173C6A;
    border: 1px solid #ddd;
    border-bottom-color: transparent;
    cursor: default
} */
.nav-pills>li.active>a,
.nav-pills>li.active>a:focus,
.nav-pills>li.active>a:hover {
    background-color: #a2c037
}
.navbar-form {
    margin: 7.5px -15px
}
.navbar-inverse .navbar-brand {
    color: #fff
}
/* .navbar-brand > img {
    margin: 18px 0 18px 70px;
    height: 60px;
    width: 200px;
} */
.navbar-inverse .navbar-nav>.active>a,
.navbar-inverse .navbar-nav>.active>a:focus,
.navbar-inverse .navbar-nav>.active>a:hover {
    background-color: #090909
}
.navbar-inverse .navbar-nav>.open>a,
.navbar-inverse .navbar-nav>.open>a:focus,
.navbar-inverse .navbar-nav>.open>a:hover {
    background-color: #090909;
}
@media (max-width: 767px) {
    .navbar-inverse .navbar-nav .open .dropdown-menu>.dropdown-header {
        border-color: #090909
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
        background-color: #090909
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu>li>a {
        color: #fff
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a,
    .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:focus,
    .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:hover {
        background-color: #090909
    }
}
.navbar-inverse .btn-link,
.navbar-inverse .btn-link:focus,
.navbar-inverse .btn-link:hover,
.navbar-inverse .navbar-link,
.navbar-inverse .navbar-link:hover {
    color: #fff
}

/* .pagination>.disabled>a,
.pagination>.disabled>a:focus,
.pagination>.disabled>a:hover,
.pagination>.disabled>span,
.pagination>.disabled>span:focus,
.pagination>.disabled>span:hover {
    color: #f5f5f5;
    background-color: #fff;
    border-color: #ddd;
    cursor: not-allowed
} */
/* .pagination-lg>li>a,
.pagination-lg>li>span {
    font-size: 19px
}
.pagination-sm>li>a,
.pagination-sm>li>span {
    font-size: 13px
} */
.tooltip {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 13px;
}
.ui-helper-hidden {
    display: none
}
.ui-helper-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}
.ui-helper-reset {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    line-height: 1.3;
    text-decoration: none;
    font-size: 100%;
    list-style: none
}
.ui-helper-clearfix:after,
.ui-helper-clearfix:before {
    content: "";
    display: table;
    border-collapse: collapse
}
.ui-helper-zfix {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0;
    filter: Alpha(Opacity=0)
}
.ui-front {
    z-index: 100
}
.ui-state-disabled {
    cursor: default!important;
    pointer-events: none
}
.ui-icon {
    display: inline-block;
    vertical-align: middle;
    margin-top: -.25em;
    position: relative;
    text-indent: -99999px;
    overflow: hidden;
    background-repeat: no-repeat
}
.ui-widget-icon-block {
    left: 50%;
    margin-left: -8px;
    display: block
}
.ui-widget-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}
.ui-autocomplete,
.ui-menu .ui-menu {
    position: absolute
}
.ui-autocomplete {
    top: 0;
    left: 0;
    cursor: default
}
.ui-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
    outline: 0
}
.ui-menu .ui-menu-item {
    margin: 0;
    cursor: pointer;
}
.ui-menu .ui-menu-item-wrapper {
    position: relative;
    padding: 3px 1em 3px .4em
}
.ui-menu .ui-menu-divider {
    margin: 5px 0;
    height: 0;
    font-size: 0;
    line-height: 0;
    border-width: 1px 0 0
}
.ui-menu .ui-state-active,
.ui-menu .ui-state-focus {
    margin: -1px
}
.ui-menu-icons {
    position: relative
}
.ui-menu-icons .ui-menu-item-wrapper {
    padding-left: 2em
}
.ui-menu .ui-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: .2em;
    margin: auto 0
}
.ui-menu .ui-menu-icon {
    left: auto;
    right: 0
}
.ui-slider {
    position: relative;
    text-align: left
}
.lb-loader,
.lightbox {
    text-align: center;
    line-height: 0
}
.ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 1.2em;
    height: 1.2em;
    cursor: default;
    touch-action: none
}
.ui-slider .ui-slider-range {
    position: absolute;
    z-index: 1;
    font-size: .7em;
    display: block;
    border: 0;
    background-position: 0 0
}
.ui-widget,
.ui-widget .ui-widget {
    font-size: 1em
}
.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range {
    filter: inherit
}
.ui-slider-horizontal {
    height: .8em
}
.ui-slider-horizontal .ui-slider-handle {
    top: -.3em;
    margin-left: -.6em
}
.ui-slider-horizontal .ui-slider-range {
    top: 0;
    height: 100%
}
.ui-slider-horizontal .ui-slider-range-min {
    left: 0
}
.ui-slider-horizontal .ui-slider-range-max {
    right: 0
}
.ui-slider-vertical {
    width: .8em;
    height: 100px
}
.ui-slider-vertical .ui-slider-handle {
    left: -.3em;
    margin-left: 0;
    margin-bottom: -.6em
}
.ui-slider-vertical .ui-slider-range {
    left: 0;
    width: 100%
}
.ui-slider-vertical .ui-slider-range-min {
    bottom: 0
}
.ui-slider-vertical .ui-slider-range-max {
    top: 0
}
.ui-widget {
    font-family: Arial, Helvetica, sans-serif
}
.ui-widget button,
.ui-widget input,
.ui-widget select,
.ui-widget textarea {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em
}
.ui-widget.ui-widget-content {
    border: 1px solid #c5c5c5
}
.ui-widget-content {
    border: 1px solid #ddd;
    background: #fff;
    color: #333
}
.ui-widget-header {
    border: 1px solid #ddd;
    background: #e9e9e9;
    font-weight: 700
}
.ui-button,
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
html .ui-button.ui-state-disabled:active,
html .ui-button.ui-state-disabled:hover {
    border: 1px solid #c5c5c5;
    background: #f6f6f6;
    font-weight: 400;
    color: #454545
}
.ui-button,
.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited,
a.ui-button,
a:link.ui-button,
a:visited.ui-button {
    color: #454545;
    text-decoration: none
}
.ui-button:focus,
.ui-button:hover,
.ui-state-focus,
.ui-state-hover,
.ui-widget-content .ui-state-focus,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-focus,
.ui-widget-header .ui-state-hover {
    border: 1px solid #ccc;
    background: #ededed;
    font-weight: 400;
    color: #2b2b2b
}
.ui-state-focus a,
.ui-state-focus a:hover,
.ui-state-focus a:link,
.ui-state-focus a:visited,
.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited,
a.ui-button:focus,
a.ui-button:hover {
    color: #2b2b2b;
    text-decoration: none
}
.ui-visual-focus {
    box-shadow: 0 0 3px 1px #5e9ed6
}
.ui-button.ui-state-active:hover,
.ui-button:active,
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active {
    border: 1px solid #003eff;
    background: #007fff;
    font-weight: 400;
    color: #fff
}
.ui-icon-background,
.ui-state-active .ui-icon-background {
    border: #003eff;
    background-color: #fff
}
.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
    color: #fff;
    text-decoration: none
}
.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
    border: 1px solid #dad55e;
    background: #fffa90;
    color: #777620
}
.ui-state-checked {
    border: 1px solid #dad55e;
    background: #fffa90
}
.ui-state-highlight a,
.ui-widget-content .ui-state-highlight a,
.ui-widget-header .ui-state-highlight a {
    color: #777620
}
.ui-state-error,
.ui-widget-content .ui-state-error,
.ui-widget-header .ui-state-error {
    border: 1px solid #f1a899;
    background: #fddfdf;
    color: #5f3f3f
}
.ui-state-error a,
.ui-state-error-text,
.ui-widget-content .ui-state-error a,
.ui-widget-content .ui-state-error-text,
.ui-widget-header .ui-state-error a,
.ui-widget-header .ui-state-error-text {
    color: #5f3f3f
}
.ui-priority-primary,
.ui-widget-content .ui-priority-primary,
.ui-widget-header .ui-priority-primary {
    font-weight: 700
}
.ui-priority-secondary,
.ui-widget-content .ui-priority-secondary,
.ui-widget-header .ui-priority-secondary {
    opacity: .7;
    filter: Alpha(Opacity=70);
    font-weight: 400
}
.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
    opacity: .35;
    filter: Alpha(Opacity=35);
    background-image: none
}
.ui-state-disabled .ui-icon {
    filter: Alpha(Opacity=35)
}
.ui-corner-all,
.ui-corner-left,
.ui-corner-tl,
.ui-corner-top {
    border-top-left-radius: 3px
}
.ui-corner-all,
.ui-corner-right,
.ui-corner-top,
.ui-corner-tr {
    border-top-right-radius: 3px
}
.ui-corner-all,
.ui-corner-bl,
.ui-corner-bottom,
.ui-corner-left {
    border-bottom-left-radius: 3px
}
.ui-corner-all,
.ui-corner-bottom,
.ui-corner-br,
.ui-corner-right {
    border-bottom-right-radius: 3px
}
.ui-widget-overlay {
    background: #aaa;
    opacity: .3;
    filter: Alpha(Opacity=30)
}
.ui-widget-shadow {
    box-shadow: 0 0 5px #666
}
.form-control,
.form-control:focus {
    box-shadow: none
}
.lb-dataContainer:after,
.lb-outerContainer:after {
    content: "";
    clear: both
}
body.lb-disable-scrolling {
    overflow: hidden
}
.lightboxOverlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: #000;
    filter: alpha(Opacity=80);
    opacity: .8;
    display: none
}
.lb-outerContainer,
body,
select.form-control {
    background-color: #fff
}
.lightbox {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 10000;
    font-weight: 400
}
.lightbox .lb-image {
    display: block;
    height: auto;
    max-width: inherit;
    border-radius: 3px
}
.lightbox a img {
    border: none
}
.lb-outerContainer {
    position: relative;
    width: 250px;
    height: 250px;
    margin: 0 auto;
    border-radius: 4px
}
.lb-loader,
.lb-nav {
    position: absolute;
    left: 0
}
.lb-outerContainer:after {
    display: table
}
.lb-container {
    padding: 4px
}
.lb-loader {
    top: 43%;
    height: 25%;
    width: 100%
}
.lb-cancel {
    display: block;
    width: 32px;
    height: 32px;
    margin: 0 auto
}
.lb-nav {
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 10
}
.lb-container>.nav {
    left: 0
}
.lb-nav a {
    outline: 0
}
.lb-next,
.lb-prev {
    height: 100%;
    cursor: pointer;
    display: block
}
.lb-nav a.lb-prev {
    width: 34%;
    left: 0;
    float: left;
    filter: alpha(Opacity=0);
    opacity: 0;
    transition: opacity .6s
}
.lb-nav a.lb-prev:hover {
    filter: alpha(Opacity=100);
    opacity: 1
}
.lb-nav a.lb-next {
    width: 64%;
    right: 0;
    float: right;
    filter: alpha(Opacity=0);
    opacity: 0;
    transition: opacity .6s
}
.lb-nav a.lb-next:hover {
    filter: alpha(Opacity=100);
    opacity: 1
}
.lb-dataContainer {
    margin: 0 auto;
    padding-top: 5px;
    width: 100%;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px
}
.fluid,
.row.fluid {
    margin-left: auto;
    margin-right: auto
}
.lb-dataContainer:after {
    display: table
}
.lb-details {
    width: 85%;
    float: left;
    text-align: left;
    line-height: 1.1em
}
.lb-number {
    display: block;
    clear: left;
    padding-bottom: 1em;
    font-size: 12px;
    color: #999
}
a,
body {
    color: #555
}

.lb-close {
    display: block;
    float: right;
    width: 30px;
    height: 30px;
    text-align: right;
    outline: 0;
    filter: alpha(Opacity=70);
    opacity: .7;
    transition: opacity .2s
}
.lb-close:hover {
    cursor: pointer;
    filter: alpha(Opacity=100);
    opacity: 1;
    text-decoration: none
}
.fixed .header-content,
.header-content,
.lt-ie11 .header {
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffab45', GradientType=1)
}
.bxslider-about .owl-video-play-icon:before,
.checkbox-custom+label:before,
.datepicker table thead .next:before,
.datepicker table thead .prev:before,
.lb-nav a.lb-next:before,
.lb-nav a.lb-prev:before,
.list-style-arrow-right li:before,
.list-style-cross>li:before,
.list-style-pointing-down>li:before,
.list-style-pointing-right>li:before,
.list-style-star li:before,
.list-style-tick li:before,
.list-style-tick-simple>li:before,
.list-style-wheel li:before,
.ribbon>a:before,
/* [class*=" icon-"],
[class^=icon-] {
    font-family: myfont;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    text-decoration: none;
    text-transform: none;
    line-height: 1;
    speak: none;
    vertical-align: middle
} */
.block-hotel .hotel-info .hotel-price .number-price,
.datepicker table tr td,
.list-style-order-color li:before,
.main-heading .heading-note,
.subscribe .subscribe-name {
    font-family: Roboto, Helvetica, Arial, sans-serif
}
.header-top-bar .header-list-right .btn-search-top:hover,
.header-top-bar .header-list-right .btn-wish-list:hover,
.underline {
    text-decoration:none;
}
.icon-5star:before {
    content: "\E001"
}
.icon-adult:before {
    content: "\E002"
}
.icon-arrow-down:before,
.ribbon>a:before {
    content: "\E003"
}
.datepicker table thead .prev:before,
.icon-arrow-left:before,
.lb-nav a.lb-prev:before {
    content: "\E004"
}
.icon-arrow-line-right:before {
    content: "\E005"
}
.datepicker table thead .next:before,
.icon-arrow-right:before,
.lb-nav a.lb-next:before,
.list-style-arrow-right li:before {
    content: "\E006"
}
.icon-arrow-up:before {
    content: "\E007"
}
.icon-best:before {
    content: "\E008"
}
.icon-blog:before {
    content: "\E009"
}
.icon-calendar:before {
    content: "\E00A"
}
.icon-camera:before {
    content: "\E00B"
}
.icon-car:before {
    content: "\E00C"
}
.icon-cellphone:before {
    content: "\E00D"
}
.icon-chat:before {
    content: "\E00E"
}
.icon-child:before {
    content: "\E00F"
}
.icon-clickwheel:before,
.list-style-wheel li:before {
    content: "\E010"
}
.icon-close:before {
    content: "\E011"
}
.icon-cross:before,
.list-style-cross>li:before {
    content: "\E012"
}
.icon-cruise:before {
    content: "\E013"
}
.icon-facebook-logo:before {
    content: "\E014"
}
.icon-fax:before {
    content: "\E015"
}
.icon-flag:before {
    content: "\E016"
}
.icon-flight:before {
    content: "\E017"
}
.icon-globe:before {
    content: "\E018"
}
.icon-google-plus:before {
    content: "\E019"
}
.icon-goto:before {
    content: "\E01A"
}
.icon-grid:before {
    content: "\E01B"
}
.icon-headphone:before {
    content: "\E01C"
}
.icon-heart-solid:before {
    content: "\E01D"
}
.icon-heart:before {
    content: "\E01E"
}
.icon-holiday:before {
    content: "\E01F"
}
.icon-hotel:before {
    content: "\E020"
}
.icon-info:before {
    content: "\E021"
}
.icon-link:before {
    content: "\E022"
}
.icon-linkedin-logo:before {
    content: "\E023"
}
.icon-list:before {
    content: "\E024"
}
.icon-location-circle:before {
    content: "\E025"
}
.icon-location-solid:before {
    content: "\E026"
}
.icon-location:before {
    content: "\E027"
}
.icon-lock:before {
    content: "\E028"
}
.icon-mail-solid:before {
    content: "\E029"
}
.icon-mail:before {
    content: "\E02A"
}
.icon-message:before {
    content: "\E02B"
}
.icon-minus:before {
    content: "\E02C"
}
.icon-network:before {
    content: "\E02D"
}
.icon-next:before {
    content: "\E02E"
}
.icon-phone-solid:before {
    content: "\E02F"
}
.icon-phone:before {
    content: "\E030"
}
.icon-pinterest-logo:before {
    content: "\E031"
}
.icon-play-video:before {
    content: "\E032"
}
.bxslider-about .owl-video-play-icon:before,
.icon-play:before {
    content: "\E033"
}
.icon-plus:before {
    content: "\E034"
}
.icon-pointing-down:before,
.list-style-pointing-down>li:before {
    content: "\E035"
}
.icon-pointing-right:before,
.list-style-pointing-right>li:before {
    content: "\E036"
}
.icon-prev:before {
    content: "\E037"
}
.icon-question-bold:before {
    content: "\E038"
}
.icon-question:before {
    content: "\E039"
}
.icon-quote-left:before {
    content: "\E03A"
}
.icon-quote:before {
    content: "\E03B"
}
.icon-room:before {
    content: "\E03C"
}
.icon-search:before {
    content: "\E03D"
}
.icon-setting:before {
    content: "\E03E"
}
.icon-share:before {
    content: "\E03F"
}
.icon-skype:before {
    content: "\E040"
}
.icon-slider:before {
    content: "\E041"
}
.icon-star:before,
.list-style-star li:before {
    content: "\E042"
}
.icon-success:before,
.list-style-tick li:before {
    content: "\E043"
}
.icon-tags:before {
    content: "\E044"
}
.checkbox-custom+label:before,
.icon-tick:before,
.list-style-tick-simple>li:before {
    content: "\E045"
}
.icon-time:before {
    content: "\E046"
}
.icon-trolley:before {
    content: "\E047"
}
.icon-twitter:before {
    content: "\E048"
}
.icon-update:before {
    content: "\E049"
}
.icon-user:before {
    content: "\E04A"
}
.icon-warning:before {
    content: "\E04B"
}
@font-face {
    font-family: myfont;
    src: url(../fonts/revicons/myfont.eot);
    src: url(../fonts/revicons/myfont.eot?#iefix) format("eot"), url(../fonts/revicons/myfont.woff) format("woff"), url(../fonts/revicons/myfont.ttf) format("truetype"), url(../fonts/revicons/myfont.svg#myfont) format("svg")
}
.row.fluid {
    max-width: 1200px
}
.fluid {
    max-width: 1170px
}
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}
a:hover {
    color: #000000;
}
.border-skew:after {
    content: ""
}
.full-page {
    width: 100%;
    height: 100%
}
main {
    padding-top: 43px;
}
main.transparent-header {
    padding-top: 0
}
@media only screen and (max-width: 991px) {
    main {
        padding-top: 81px
    }
}
/* html {
    font-size: 10px
} */
/* .section,
section {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 20px
} */
a,
a:active,
a:focus {
    outline: 0
}
input.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #555
}
.breadcrumd .ribbon select{background-color: transparent;
    color: #fff !important;
}
.ribbon a{background-color: transparent;
    color: #fff !important;
    text-decoration: none;
}
.ribbon select option {padding-left: 0px !important;
    width: 100%;
    border-right: 1px solid #555;
}
select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 10px auto;
    color: #555
}
select.form-control option {
    color: #555;
    text-align: center;
    padding-left: 30px
}
@-moz-document url-prefix() {
    select.form-control {
        color: transparent;
        text-shadow: 0 0 0 #555
    }
}
select::-ms-expand {
    display: none
}
input[type=number] {
    -moz-appearance: textfield
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none
}
blockquote {
    font-size: 15px;
    padding: 0;
    margin: 0;
    border-left: 0
}
.star-rating {
    position: relative;
    width: 23%;
    height: 17px;
    overflow: hidden;
    display: inline-block;
    margin-left: 0px
}
.property_location { width:100%; float:left; }
.property_location .star-rating { width:22%;}
.property_location .star-rating:last-child { width:29%; }
.star-rating .star-color,
.star-rating .star-empty {
    position: absolute;
    left: 0;
    top: 0;
    transition: all .25s ease
}
.star-rating .star-empty .icon {
    color: #ffc107
}
.star-color i{
    color: #f7a13a;
    margin-right:5px
}
.star-rating .star-color {
    overflow: hidden;
    color: #f7951f
}
.star-rating .star-color .icon {
    color: #ffc107
}
.star-rating .icon {
    font-size: 100px
}
.star-rating.empty .star-color {
    width: 0
}
.star-rating.star05 {
    width: 20px
}
.star-rating.star05 .star-color {
    width: 58%
}
.star-rating.star1 {
    width: 20px
}
.star-rating.star15 {
    width: 40px
}
.star-rating.star15 .star-color {
    width: 76%
}
.star-rating.star2 {
    width: 40px
}
.star-rating.star25 {
    width: 60px
}
.star-rating.star25 .star-color {
    width: 84%
}
.star-rating.star3 {
    width: 60px
}
.star-rating.star35 {
    width: 80px
}
.star-rating.star35 .star-color {
    width: 87%
}
.star-rating.star4 {
    width: 80px
}
.star-rating.star45 {
    width: 100px
}
.star-rating.star45 .star-color {
    width: 90%
}
.star-rating.star5 {
    width: 100px
}
.star-rating.star5 .star-color {
    width: 100%
}
.star-rating.full-star {
    width: 100px
}
.star-rating.full-star.star1 .star-color {
    width: 20%
}
.star-rating.full-star.star15 .star-color {
    width: 30%
}
.star-rating.full-star.star2 .star-color {
    width: 40%
}
.star-rating.full-star.star25 .star-color {
    width: 50%
}
.star-rating.full-star.star3 .star-color {
    width: 60%
}
.star-rating.full-star.star35 .star-color {
    width: 70%
}
.star-rating.full-star.star4 .star-color {
    width: 80%
}
.star-rating.full-star.star45 .star-color {
    width: 90%
}
.main-heading img,
.middle-block,
.star-rating.full-star.star5 .star-color,
img {
    width: 100%
}
.main-heading {
    position: relative
}
.main-heading .heading-note {
    font-weight: 500;
    color: #ffc107;
    text-transform: uppercase;
    margin: 0;
    font-size: 12px
}
.main-heading h2 {
    margin-top: 0;
    font-size:26px; 
    /*color: #44515e*/
}
.main-heading .heading-content {
    position: relative;
    z-index: 1;
    margin-bottom: 10px
}
.main-heading .heading-content.white-version,
.main-heading .heading-content.white-version h2 {
    color: #fff
}
.main-heading .heading-icon {
    text-align: right
}
.owl-dots,
.text-center {
    text-align: center
}
.main-heading .heading-icon .icon {
    font-size: 160px;
    margin-top: 60px;
    display: inline-block;
    color: #edf0f5
}
@media only screen and (max-width: 767px) {
    .main-heading .heading-icon {
        position: absolute;
        top: 0;
        width: 100%
    }
    .main-heading .heading-icon .icon {
        margin-top: -17px;
        font-size: 90px
    }
}
.heading-nomal {
    font-weight: 300
}
img {
    height: auto
}
.middle-block {
    position: relative;
    display: block;
    overflow: hidden;
    height: 100%
}
.middle-block .middle-item {
    width: 100%;
    height: auto
}
.block-table-center {
    display: table
}
.block-table-center .center-item {
    display: table-cell;
    vertical-align: middle
}
.circularG {
    position: relative;
    width: 30px;
    height: 30px;
    margin: auto;
    display: inline-block
}
.circular-loading {
    position: absolute;
    background-color: #ffc107;
    width: 10px;
    height: 10px;
    border-radius: 9px;
    animation-name: bounce_circularG;
    animation-duration: 1.1s;
    animation-iteration-count: infinite;
    animation-direction: normal
}
.circularG_1 {
    left: -3px;
    top: -1px;
    animation-delay: .41s
}
.circularG_2 {
    left: -6px;
    top: 10px;
    animation-delay: .55s
}
.circularG_3 {
    left: -2px;
    bottom: -2px;
    animation-delay: .69s
}
.circularG_4 {
    right: 9px;
    bottom: -5px;
    animation-delay: .83s
}
.circularG_5 {
    left: 20px;
    bottom: -1px;
    animation-delay: .97s
}
.circularG_6 {
    right: -5px;
    top: 9px;
    animation-delay: 1.1s
}
.circularG_7 {
    right: -1px;
    top: -1px;
    animation-delay: 1.24s
}
.circularG_8 {
    top: -5px;
    left: 8px;
    animation-delay: 1.38s
}
.display-table-line {
    display: table
}
.display-table-line .table-title {
    width: 1%;
    display: table-cell;
    padding: 0 6px
}
.display-table-line .table-content {
    display: table-cell;
    width: 100%;
    position: relative
}
.list-style-star {
    padding-left: 0
}
.list-style-star li {
    list-style-type: none;
    position: relative;
    padding-left: 20px
}
.list-style-star li:before {
    position: absolute;
    left: 4px;
    top: 6px;
    color: #ffc107;
    font-size: 9px
}
.list-style-arrow-right {
    padding-left: 0
}
.list-style-arrow-right li {
    list-style-type: none;
    position: relative;
    padding-left: 20px
}
.list-style-arrow-right li:before {
    position: absolute;
    left: 4px;
    top: 6px;
    color: #ffc107;
    font-size: 9px
}
.list-style-color-disc {
    padding-left: 20px
}
.list-style-color-disc li {
    position: relative;
    list-style-type: none
}
.list-style-color-disc li:before {
    content: "• ";
    color: #ffc107;
    position: absolute;
    font-size: 20px;
    left: -15px;
    top: -3px
}
.list-style-wheel {
    padding-left: 0
}
.list-style-wheel li {
    list-style-type: none;
    position: relative;
    padding-left: 20px
}
.list-style-wheel li:before {
    position: absolute;
    left: 5px;
    top: 8px;
    color: #ffc107;
    font-size: 7px
}
.list-style-tick {
    padding-left: 0
}
.list-style-tick li {
    list-style-type: none;
    position: relative;
    padding-left: 25px;
    margin-bottom: 3px
}
.list-style-order-color li,
.list-style-pointing-down>li,
.list-style-pointing-right>li {
    margin-bottom: 5px;
    list-style-type: none
}
.list-style-tick li:before {
    position: absolute;
    left: 0;
    top: 4px;
    color: #ffc107
}
.list-style-tick-simple {
    padding-left: 0
}
.list-style-tick-simple>li {
    list-style-type: none;
    padding-left: 25px;
    position: relative
}
.list-style-tick-simple>li:before {
    position: absolute;
    left: 0;
    top: 4px;
    font-size: 13px;
    color: #ffc107
}
.list-style-pointing-right {
    padding-left: 0
}
.list-style-pointing-right>li {
    padding-left: 25px;
    position: relative
}
.list-style-pointing-right>li:before {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 17px;
    color: #ffc107
}
.list-style-pointing-down {
    padding-left: 0
}
.list-style-pointing-down>li {
    padding-left: 25px;
    position: relative
}
.list-style-pointing-down>li:before {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 17px;
    color: #ffc107
}
.list-style-cross {
    padding-left: 0
}
.list-style-cross>li {
    list-style-type: none;
    padding-left: 25px;
    position: relative
}
.list-style-cross>li:before {
    position: absolute;
    left: 0;
    top: 4px;
    font-size: 13px;
    color: #ffc107
}
.list-style-order-color {
    counter-reset: list;
    padding-left: 0
}
.list-style-order-color li:before {
    counter-increment: list;
    content: counter(list) ". ";
    padding-right: 10px;
    padding-left: 5px;
    color: #ffc107;
    font-weight: 500
}
.list-style-order-color li ol {
    padding-left: 25px;
    counter-reset: listsub
}
.list-style-order-color li ol li:before {
    counter-increment: listsub;
    content: counter(list) "." counter(listsub)
}
.list-style-order-color li ol li ol {
    counter-reset: listsub2
}
.list-style-order-color li ol li ol li:before {
    counter-increment: listsub2;
    content: counter(list) "." counter(listsub) "." counter(listsub2)
}
.full-width {
    width: 100%
}
.text-bold {
    font-weight: 700
}
/* .nav-tabs>li>a,
.text-semibold {
    font-weight: 600
} */
.alert {
    font-size: 13px;
    padding: 5px 15px;
    margin-top: 15px
}
.alert-success {
    background-color: #dff0d8;
    border: 1px solid #afdd9c;
    color: #468847
}
.alert-advice {
    background-color: #fff5d2;
    border: 1px solid #ffddbc;
    color: #eba32f
}
.global-scroll-top {
    position: fixed;
    right: 15px;
    bottom: 45px;
    background-color: #555;
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    line-height: 40px;
    z-index: 999;
    display: none;
    transition: opacity .25s ease
}

.margin-bottom-section {
    margin-bottom: 10px
}
.margin-top-section {
    margin-top: 50px
}
.margin-bottom-item {
    margin-bottom: 10px
}
.margin-top-item {
    margin-top: 10px
}
.margin-top4 {
    margin-top: 4px
}
.no-padding {
    padding: 0
}
.border-right {
    border-right: 1px solid #dbdddf
}
.no-margin {
    margin: 0
}
.overflow-hidden {
    overflow: hidden
}
.color-error {
    color: #FF3131
}
.fixed .header-top-bar ul {
    display: none
}
/* .nav-tabs {
    margin: 0 15px
}
.nav-tabs>li {
    margin-bottom: 0
}
.nav-tabs>li>a {
    background-color: transparent;
    font-size: 13px;
    text-transform: uppercase;
    border: 0;
    border-bottom: 2px solid transparent;
    padding:15px 25px
}
@media only screen and (max-width: 767px) {
    .nav-tabs>li>a {
        padding: 15px
    }
}
.nav-tabs>li>a:hover {
    border-bottom: 2px solid #f76b1c;
    background-color: #fff;
    color: #44515e
} */

/* .tab-pane {
    padding: 15px
} */
.input-group-addon {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    padding-left: 5px;
    color:var(--theme-primary-color, #1f407b);
}
.input-group .form-control
{padding-left: 10px;}
/* .form-control {
    border-radius: 0;
    border: 0;
    padding-left: 0;
    padding-right: 0
} */
.accordion-itinerary {
    padding-left: 15px
}
.accordion-itinerary  {
    list-style-type: none;
    margin-top: 25px;
    position: relative;
    padding-left: 50px
}
.accordion-itinerary  {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    left: 15px;
    top: 20px;
    border-left: 1px dashed #44515e
}
.accordion-itinerary .icon-title {
    position: absolute;
    top: 0;
    left: 5px;
    color: #ffc107;
    font-size: 20px;
    z-index: 1;
    cursor: pointer
}
.btn-sun.btn-bg-left:after,
.btn-sun.btn-bg-right:after,
.btn-sun:after {
    content: '';
    z-index: -1
}
.btn {
    transition: .25s all ease;
    border: 0;
    border-radius: 0;
    font-size: 13px;
    padding: 8px 30px;
    text-transform:none; 
}
.btn.disabled,
.btn.disabled:hover {
    background-color: #dbdddf;
    color: #555;
    font-weight: 700;
    cursor: not-allowed;
    border-color: #dbdddf
}

.btn.btn-lg {
    padding: 15px 40px;
    font-size: 15px
}
.btn.btn-sm {
    padding: 5px 20px;
    font-size: 12px
}
/* .btn.btn-xs {
    padding: 3px 10px;
    font-size: 12px
} */
.btn-secondary {
    background-color: transparent;
    color: #fff;
    text-transform:none; 
}
.btn-secondary:hover {
    color: #ffc107
}
.btn-border-default {
    background-color: #fff;
    color: #555
}
.btn-border-default:hover,
.btn-sun {
    color: #ffc107
}
.btn-sun {
    background-color: transparent;
    position: relative
}
.btn-sun:hover {
    background-color: #ffc107;
    color: #fff
}
.btn-sun:after {
    position: absolute
}
.btn-sun.btn-bg-top:after {
    width: 100%;
    height: 0;
    transition: height .25s;
    top: 0;
    left: 0;
    background-color: #ffc107
}
.btn-sun.btn-bg-top:hover {
    background-color: transparent;
    color: #fff
}
.btn-sun.btn-bg-top:hover:after {
    height: 100%
}
.btn-sun.btn-bg-left:after {
    width: 0;
    height: 100%;
    transition: width .25s;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffc107
}
.btn-sun.btn-bg-left:hover {
    background-color: transparent
}
.btn-sun.btn-bg-left:hover:after {
    width: 100%
}
.btn-sun.btn-bg-bottom:after {
    width: 100%;
    height: 0;
    transition: height .25s;
    bottom: 0;
    left: 0;
    background-color: #ffc107
}
.btn-sun.btn-bg-bottom:hover {
    background-color: transparent;
    color: #fff
}
.btn-sun.btn-bg-bottom:hover:after {
    height: 100%
}
.btn-sun.btn-bg-right:after {
    width: 0;
    height: 100%;
    transition: width .25s;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #ffc107
}
.btn-sun.btn-bg-right:hover {
    background-color: transparent
}
.btn-sun.btn-bg-right:hover:after {
    width: 100%
}
.btn-sun.btn-bg-scale {
    overflow: hidden
}
.btn-sun.btn-bg-scale:after {
    width: 100%;
    height: 0;
    top: 50%;
    left: 50%;
    background: #ffc107;
    opacity: 0;
    transition: opacity .25s, height .25s;
    transform: translateX(-50%) translateY(-50%) rotate(45deg)
}
.btn-sun-secondary,
.btn-sun-secondary:hover,
.btn-sun.btn-bg-scale:hover {
    background-color: transparent
}
.btn-sun.btn-bg-scale:hover:after {
    height: 450%;
    opacity: 1
}
.btn-sun-secondary {
    color: #555
}
.btn-sun-secondary:hover {
    color: #ffc107
}
/* .btn-primary {
    padding: 8px 30px;
    background-color: #fff;
    color: #555
} */
/* .btn-primary:hover {
    color: #ffc107;
    background-color: #fff
} */
.btn-has-icon {
    background-color: #fff;
    border: 2px solid #ffc107
}
.btn-has-icon .icon {
    color: #ffc107;
    transition: color .25s;
    font-size: 18px;
    margin-left: 5px
}
.btn-has-icon:hover {
    background-color: #ffc107;
    color: #fff
}
.btn-has-icon:hover .icon {
    color: #fff
}
.btn-standar {
    color: #44515e;
    background-color: #edf0f5;
    border: 1px solid #555
}
.btn-standar:hover {
    color: #44515e;
    background-color: #dbdddf
}
.btn-color-error,
.btn-color-error:hover {
    background-color: #FF3131;
    color: #fff;
    border: 2px solid #FF3131
}
.btn-color-error {
    opacity: .75
}
.btn-color-error:hover {
    opacity: 1
}
.btn-color-error-light,
.btn-color-error-light:hover {
    background-color: #fdabab;
    color: #44515e;
    border: 2px solid #fdabab
}
.btn-color-error-light {
    opacity: .75
}
.btn-color-error-light:hover {
    opacity: 1
}
.btn-color-success,
.btn-color-success:hover {
    background-color: #3cce31;
    color: #fff;
    border: 2px solid #3cce31
}
.btn-color-success {
    opacity: .75
}
.btn-color-success:hover {
    opacity: 1
}
.btn-color-success-light,
.btn-color-success-light:hover {
    background-color: #b5f9b0;
    color: #44515e;
    border: 2px solid #b5f9b0
}
.btn-color-success-light {
    opacity: .75
}
.btn-color-success-light:hover {
    opacity: 1
}
.btn-color-warning,
.btn-color-warning:hover {
    background-color: #dca80a;
    color: #fff;
    border: 2px solid #dca80a
}
.btn-color-warning {
    opacity: .75
}
.btn-color-warning:hover {
    opacity: 1
}
.btn-color-warning-light,
.btn-color-warning-light:hover {
    background-color: #fffba4;
    color: #44515e;
    border: 2px solid #fffba4
}
.btn-color-warning-light {
    opacity: .75
}
.btn-color-warning-light:hover {
    opacity: 1
}
.btn-color-default,
.btn-color-default:hover {
    background-color: #44515e;
    color: #fff;
    border: 2px solid #44515e
}
.btn-color-default {
    opacity: .75
}
.btn-color-default:hover {
    opacity: 1
}
.btn-color-info,
.btn-color-info:hover {
    background-color: #7998c7;
    color: #fff;
    border: 2px solid #7998c7
}
.btn-color-info {
    opacity: .75
}
.btn-color-info:hover {
    opacity: 1
}
.btn-color-blue-light,
.btn-color-blue-light:hover {
    background-color: #dbdddf;
    color: #44515e;
    border: 2px solid #dbdddf
}
.btn-color-blue-light {
    opacity: .75
}
.btn-color-blue-light:hover {
    opacity: 1
}
.button-shortcode-page .btn {
    margin: 10px 10px 15px 0
}
a, a:active, a:focus {
    outline: 0;
    text-decoration: none;
    color: #555;
}
.header {
    width: 100%;
    position: fixed;
    z-index:99999;
    top: 0;
    left: 0;
    background-color: #fff;
    border-style: solid;
    border-width: 0px 0px 3px 0px;
    border-color: #B2B2B2;

}
.lt-ie11 .header {
    background: linear-gradient(to right, #fff 0, #fff 50%, #ffc107 50%, #ffc107 100%)
}
.header.transparent-header {
    background-color: transparent
}
.header.transparent-header.fixed {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .2);
    background-color: #fff
}
@media only screen and (max-width: 991px) {
    .header.animated {
        animation-duration: 0s;
        animation-fill-mode: none;
        animation-name: none;
        transition: none
    }
     .new_stylemenu .container { width:100%; }
}
@media only screen and (min-width: 992px) {
    .header.fixed {
        position: fixed;
        top: 0;
        box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .2)
    }
    #agentprofilecontent {
        position: relative;
        /*top: -40px;
        max-width: 500px;*/
    }
}
.header-top-bar,
.header-top-bar>div {
    position: relative
}
.header-top-bar {
    background-color:rgba(74,74,74, .90);
    color: #fff;
    font-size: 13px;
    width: 100%;
    height: 3px
}
.transparent-header .header-top-bar {
    background-color: transparent;
    box-shadow: 0 1px 1px 0 rgba(255, 255, 255, .2)
}
.lt-ie11 .header-top-bar {
    height: 30.5px
}
.fixed .header-top-bar {
    height: 0
}
.header-top-bar .icon {
    margin-right: 5px;
    position: absolute;
    top: 9px;
    left: 0
}
.header-top-bar ul {
    margin: 0
}
.header-top-bar ul li {
    display: inline-block;
    list-style-type: none
}
.header-top-bar ul li:last-child a:after {
    border: 0
}
.header-top-bar ul li.active a {
    color: #ffc107
}
.header-top-bar a {
    position: relative;
    padding: 6px 20px;
    display: inline-block;
    margin-right: 20px;
    color: #fff;
    transition: all .25s ease
}
.header-top-bar a:hover {
    color: #ffc107
}
.transparent-header .header-top-bar a {
    color: #fff
}
.header-top-bar .contact-info a:after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 35%;
    top: 10px;
    left: 0;
    z-index: 1;
    background-color: transparent;
    border-right: 1px solid #ffc107;
    transform-origin: bottom right;
    transform: skew(-20deg, 0deg)
}

@media only screen and (min-width: 992px) {
    .fixed .header-top-bar .header-list-right {
        top: 1px
    }
}

.transparent-header .header-top-bar .header-list-right {
    background-color: transparent
}
.fixed .header-top-bar .header-list-right {
    background-color: #ffc107
}
@media only screen and (max-width: 991px) {
    .fixed .header-top-bar .header-list-right {
        background-color: transparent
    }
}
/* .header-top-bar .header-list-right:before {
    content: "";
    position: absolute;
    display: block;
    width: 30px;
    height: 100%;
    top: 0;
    left: -30px;
    z-index: 1;
    background-color:#f7951f;
    transform-origin: bottom right;
    transform: skew(-20deg, 0deg)
} */
.transparent-header .header-top-bar .header-list-right:before {
    background-color: transparent
}
.fixed .header-top-bar .header-list-right:before {
    background-color: #ffc107
}
.header-top-bar .header-list-right .btn-search-top,
.header-top-bar .header-list-right .btn-wish-list {
    height: 45px;
    float: right;
    position: relative;
    z-index: 1;
    line-height: 40px;
    transition: all .25s ease;
    padding:0px;
    color: #fff;
    text-transform: none;
    font-weight: 400;
    font-size: 15px;
    cursor: pointer
}
.header-top-bar .header-list-right .btn-wish-list i { margin:0 5px 0 0px; }
.lt-ie11 .header-top-bar .header-list-right .btn-search-top,
.lt-ie11 .header-top-bar .header-list-right .btn-wish-list {
    height: 77px
}
.transparent-header .header-top-bar .header-list-right .btn-search-top:hover,
.transparent-header .header-top-bar .header-list-right .btn-wish-list:hover {
    color: #ffc107
}
.fixed .header-top-bar .header-list-right .btn-search-top:hover,
.fixed .header-top-bar .header-list-right .btn-wish-list:hover {
    color: #fff
}
.header-top-bar .header-list-right .btn-search-top:hover .icon,
.header-top-bar .header-list-right .btn-wish-list:hover .icon {
    text-decoration: none
}
.header-top-bar .header-list-right .btn-search-top .icon,
.header-top-bar .header-list-right .btn-wish-list .icon {
    margin-right: 5px;
    top: 38%
}
.header-top-bar .header-list-right .btn-wish-list {
    margin-right:10px;
    margin-left:10px;
}
.header-top-bar .input-search-top {
    position: absolute;
    right: 15px;
    top: 0;
    left: 90%;
    padding: 0px 15px;
    background-color: #fff;
    border: 1px solid #44515e;
    transition: left .35s ease, opacity .35s;
    width: auto;
    overflow: hidden;
    opacity: 0;
    z-index: -1
}
@media only screen and (max-width: 991px) {


    .transparent-header .header-top-bar .header-list-right .btn-search-top,
    .transparent-header .header-top-bar .header-list-right .btn-wish-list {
        color: #fff
    }
    .header-top-bar .header-list-right .btn-search-top .btn-text,
    .header-top-bar .header-list-right .btn-search-top:after,
    .header-top-bar .header-list-right .btn-wish-list .btn-text,
    .header-top-bar .header-list-right .btn-wish-list:after {
        display: none
    }
    .header-top-bar .header-list-right .btn-search-top:hover,
    .header-top-bar .header-list-right .btn-wish-list:hover {
        color: #ffc107
    }
    .header-top-bar .input-search-top {
        padding: 8px 15px
    }
}
.header-top-bar .input-search-top.active {
    left: 15px;
    opacity: 1;
    z-index: 3
}
.header-top-bar .input-search-top .icon {
    font-size: 22px
}
.header-top-bar .input-search-top .icon-search {
    top: 6px
}
.header-top-bar .input-search-top input {
    padding-left: 10px;
    font-size: 18px
}
@media only screen and (max-width: 991px) {
    .header-top-bar .input-search-top input {
        font-size: 15px
    }
}
.header-top-bar .input-search-top .icon-close,
.header-top-bar .input-search-top  {
    color: #555;
    left: auto;
    right: 0;
    cursor: pointer;
    transition: transform .3s;
    font-size: 18px
}
.header-top-bar .input-search-top .icon-close:hover,
.header-top-bar .input-search-top   {
    transform: rotate(360deg)
}
.header-content {
    width: 100%;
    /* background: linear-gradient(to right, #fff 0, #fff 50%, #f7951f 50%, #f7951f 100%) */
}
.lt-ie11 .header-content,
.lt-ie11 .header-content>div {
    background-color: #fff
}
.transparent-header .header-content {
    background: 0 0
}
.fixed .header-content {
    background: linear-gradient(to right, #fff 0, #fff 50%, #ffc107 50%, #ffc107 100%)
}
.header-content .logo {
    position: absolute;
    top:3px;
    margin-left: 15px;
    z-index: 1
}
.fixed .header-content .logo {
    top: 2px
}
.header-content .logo img {
    height: 45px;
    width: auto;
    transition: height .25s ease
}
@media only screen and (max-width: 991px) {
    .fixed .header-content {
        background: #fff
    }
    .header-content {
        background: #fff;
        text-align: left
    }
    .header-content .logo {
        left: 0;
        top: 10px
    }
    .header-content .logo img {
        height: 30px
    }
    .main-nav {
        display: none
    }
}
.header-content .logo-mobile {
    display: none
}
.ribbon,
.ribbon .icon {
    position: relative
}
.ribbon>a {
    margin-right: 0
}
.ribbon>a:hover {
    text-decoration: none
}
.ribbon>a:before {
    position: absolute;
    right: -2px;
    top: 38%;
    font-size: 10px
}
.ribbon>a:after {
    border: 0
}
.ribbon .ribbon-dropdown {
    position: absolute;
    right: -5px;
    top: -9999px;
    z-index: 5;
    visibility: hidden;
    background-color: #44515e;
    opacity: 0;
    transition: opacity .2s ease-in;
    border: 1px solid #44515e;
    border-top: 0;
    padding: 0
}
.ribbon .ribbon-dropdown li {
    padding: 0
}
.ribbon .ribbon-dropdown li a {
    min-width: 120px;
    margin: 0;
    padding: 5px 20px;
    text-align: center;
    color: #939ea9;
    position: relative;
    width: 100%
}
.ribbon .ribbon-dropdown li a:after {
    border: 0
}
.ribbon .ribbon-dropdown li a:hover {
    text-decoration: none;
    color: #ffc107
}
.ribbon:hover a {
    color: #ffc107
}
.ribbon:hover .ribbon-dropdown {
    top: 30px;
    visibility: visible;
    filter: alpha(opacity=100);
    opacity: 1
}
.main-nav {
    margin: 0 0 0 200px;
    background-color: #fff;
    padding: 0;
    padding-top:0px;
    height: 45px
}
.transparent-header .main-nav {
    background-color: transparent
}
.fixed .main-nav {
    height: auto;
    background-color: #fff
}
.main-nav>li>a:after,
.transparent-header .main-nav>li>a:after {
    background-color: transparent
}
.main-nav>li {
    display: inline-block;
    list-style-type: none
}
.main-nav>li:last-child a:after {
    border-right: 0
}
.main-nav>li.active a,
.main-nav>li:hover a {
    border-bottom: 2px solid #f7941e;
    color: #44515e;
    text-decoration: none
}
.main-nav>li:hover .submenu {
    visibility: visible
}
.main-nav>li>a {
    padding:15px 25px 11px;
    display: inline-block;
    border-bottom: 2px solid transparent;
    transition: .25s all ease;
    font-weight: 600;
    font-size: 13px;
    position: relative;
    text-transform: uppercase;
    line-height: normal;
}
.main-nav>li>a:after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 15px;
    top: 14px;
    left: 0;
    z-index: 1;
    border-right: 2px solid #f7941e;
    transform-origin: bottom left;
    transform: skew(-20deg, 0deg)
}
.transparent-header .main-nav>li a {
    color: #fff
}
.transparent-header .main-nav>li.active a,
.transparent-header .main-nav>li:hover a {
    color: #ffc107
}
.fixed .main-nav>li a {
    color: #555
}
.fixed .main-nav>li.active a,
.fixed .main-nav>li:hover a {
    color: #44515e
}
.main-nav .submenu {
    position: absolute;
    margin: 0;
    top: 103px;
    padding: 15px 0 30px;
    z-index: 99;
    background-color: rgba(60, 69, 79, .9);
    font-size: 13px;
    border-left: 1px solid #dbdddf;
    border-right: 1px solid #dbdddf;
    border-bottom: 3px solid #ffc107;
    visibility: hidden
}
.fixed .main-nav .submenu {
    top: 74px
}
.main-nav .submenu>ul {
    padding: 0
}
.main-nav .submenu li {
    list-style-type: none;
    text-align: left;
    min-width: 180px
}
.main-nav .submenu li a {
    color: #fff;
    padding: 10px 20px;
    display: inline-block;
    transition: color .25s ease, left .25s;
    border-bottom: 0;
    width: 100%;
    position: relative;
    left: 0
}
.fixed .main-nav .submenu li a {
    color: #fff
}
.fixed .main-nav .submenu li.active a,
.fixed .main-nav .submenu li:hover a {
    color: #ffc107
}
.main-nav .submenu li:hover a {
    text-decoration: none;
    color: #ffc107;
    left: 10px
}
.main-nav .submenu.col-4,
.main-nav .submenu.col-4 li a.hover-no-effect,
.main-nav .submenu.col-4 li a.hover-no-effect:hover {
    left: 0
}
.transparent-header .main-nav .submenu li a {
    color: #fff
}
.transparent-header .main-nav .submenu li:hover a {
    color: #ffc107
}
/* .main-nav .submenu.col-4 li {
    width: 25%;
    display: inline-block;
    float: left;
    text-transform: uppercase
} */
.main-nav .submenu.col-4 li a {
    padding-top: 20px;
    padding-bottom: 20px
}
.main-nav .submenu.col-4 li .submenu li {
    text-transform: none
}
.main-nav .submenu.col-4 li .submenu li a {
    position: relative;
    left: 0;
    transition: left .2s ease
}
.main-nav .submenu.col-4 li .submenu li a:hover {
    left: 10px
}
.main-nav .submenu .icon {
    font-size: 8px;
    color: #ffc107
}
.main-nav .submenu.col-1 li {
    position: relative
}
.main-nav .submenu.col-1 li .icon {
    position: absolute;
    right: 15px;
    top: 15px
}
.main-nav .submenu.col-1 li .submenu {
    position: absolute;
    left: 100%;
    top: -16px;
    visibility: hidden;
    width: 0;
    transition: width .25s ease;
    padding-top: 15px;
    padding-bottom: 25px;
    border: 1px solid #fff;
    border-bottom: 3px solid #ffc107
}
.main-nav .submenu.col-1 li .submenu a {
    padding-top: 10px;
    padding-bottom: 10px;
    left: 0
}
.main-nav .submenu.col-1 li .submenu a:hover {
    left: 10px
}
.fixed .main-nav .submenu.col-1 li .submenu li a,
.transparent-header .main-nav .submenu.col-1 li .submenu li a {
    color: #fff
}
.fixed .main-nav .submenu.col-1 li .submenu li.active a,
.fixed .main-nav .submenu.col-1 li .submenu li:hover a,
.transparent-header .main-nav .submenu.col-1 li .submenu li.active a,
.transparent-header .main-nav .submenu.col-1 li .submenu li:hover a {
    color: #ffc107
}
.main-nav .submenu.col-1 li:hover .submenu {
    visibility: visible;
    width: 100%
}
.main-nav .submenu .submenu {
    margin: 0;
    padding: 0;
    position: relative;
    border: 0;
    top: 0
}
.main-nav .submenu .submenu li {
    display: block;
    width: 100%
}
.main-nav .submenu .submenu li a {
    color: #fff;
    padding-top: 5px;
    padding-bottom: 5px
}
.main-nav .submenu .submenu li a:hover {
    color: #ffc107
}
.transparent-header .main-nav .submenu .submenu li a {
    color: #fff
}
.transparent-header .main-nav .submenu .submenu li:hover a {
    color: #ffc107
}
.fixed .main-nav .submenu .submenu li a {
    color: #fff
}
.fixed .main-nav .submenu .submenu li:hover a {
    color: #ffc107
}
/* .btn-navigation {
    cursor: pointer;
    display: inline-block;
    padding: 15px 0 15px 15px;
    float: right
} */
.btn-navigation .lines {
    display: inline-block;
    width: 30px;
    height: 4px;
    background: #44515e;
    border-radius: 5px;
    transition: .3s;
    position: relative
}
.transparent-header .btn-navigation .lines {
    background: #fff
}
.btn-navigation .lines:after,
.btn-navigation .lines:before {
    display: inline-block;
    width: 30px;
    height: 4px;
    background: #44515e;
    border-radius: 5px;
    transition: .3s;
    position: absolute;
    left: 0;
    content: '';
    transform-origin: 5px center
}
.transparent-header .btn-navigation .lines:after,
.transparent-header .btn-navigation .lines:before {
    background: #fff
}
.btn-navigation .lines:after {
    top: -8px
}
.btn-navigation .lines:before {
    top: 8px
}
.btn-navigation.close .lines {
    background-color: transparent
}
.btn-navigation.close .lines:before {
    transform: rotate3d(0, 0, 1, 45deg);
    transform-origin: 50% 50%;
    top: 0
}
.btn-navigation.close .lines:after {
    transform: rotate3d(0, 0, 1, -45deg);
    transform-origin: 50% 50%;
    top: 0
}
.cd-nav-mobile-dropdown {
    width: 100%;
    transition: max-height .25s cubic-bezier(.71, .05, .64, .93), opacity .35s;
    max-height: 0;
    overflow: hidden;
    background-color: #44515e
}
.cd-nav-mobile-dropdown ul {
    padding-left: 0;
    margin-bottom: 0;
    width: 100%
}
.cd-nav-mobile-dropdown ul li {
    list-style-type: none;
    width: 100%
}
.cd-nav-mobile-dropdown ul li a {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 13px;
    color: #fff;
    background-color: #44515e;
    width: 100%;
    display: block;
    padding: 20px 15px;
    text-decoration: none;
    border-bottom: 1px solid #6b5d2c;
    position: relative
}
.cd-nav-mobile-dropdown ul li>.submenu {
    overflow: hidden;
    transition: all .25s ease;
    max-height: 0;
    padding-left: 40px
}
.cd-nav-mobile-dropdown ul li>.submenu a {
    text-transform: none;
    font-size: 15px;
    padding: 15px
}
.cd-nav-mobile-dropdown ul li>.submenu.open {
    max-height: 800px
}
.cd-nav-mobile-dropdown ul li>.submenu li:last-child a:after {
    content: '';
    width: 40px;
    height: 1px;
    background-color: #6b5d2c;
    bottom: -1px;
    left: -40px;
    position: absolute
}
.block-des:after,
.block-des:before {
    top: 0;
    left: 0;
    content: ''
}
.cd-nav-mobile-dropdown ul li>.submenu .submenu a {
    font-weight: 400
}
.cd-nav-mobile-dropdown ul li .icon-arrow-down,
.cd-nav-mobile-dropdown ul li .ribbon>a:before {
    display: none
}
.cd-nav-mobile-dropdown ul li .datepicker table thead .next:before,
.cd-nav-mobile-dropdown ul li .icon-arrow-right,
.cd-nav-mobile-dropdown ul li .lb-nav a.lb-next:before,
.cd-nav-mobile-dropdown ul li .list-style-arrow-right li:before,
.datepicker table thead .cd-nav-mobile-dropdown ul li .next:before,
.lb-nav .cd-nav-mobile-dropdown ul li a.lb-next:before,
.list-style-arrow-right .cd-nav-mobile-dropdown ul li li:before {
    display: inline-block
}
.cd-nav-mobile-dropdown ul li.sub-nav-active .datepicker table thead .next:before,
.cd-nav-mobile-dropdown ul li.sub-nav-active .icon-arrow-right,
.cd-nav-mobile-dropdown ul li.sub-nav-active .lb-nav a.lb-next:before,
.cd-nav-mobile-dropdown ul li.sub-nav-active .list-style-arrow-right li:before,
.datepicker table thead .cd-nav-mobile-dropdown ul li.sub-nav-active .next:before,
.lb-nav .cd-nav-mobile-dropdown ul li.sub-nav-active a.lb-next:before,
.list-style-arrow-right .cd-nav-mobile-dropdown ul li.sub-nav-active li:before {
    display: none
}
.cd-nav-mobile-dropdown ul li.sub-nav-active .icon-arrow-down,
.cd-nav-mobile-dropdown ul li.sub-nav-active .ribbon>a:before {
    display: inline-block
}
.cd-nav-mobile-dropdown.nav-active {
    max-height: 2000px
}
.block-des {
    padding-bottom: 70%;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden
}
.block-des .middle-block,
.block-des:after,
.block-des:before {
    position: absolute
}
.block-des:before {
    width: 120%;
    height: 120%;
    background: rgba(0, 0, 0, .5);
    transition: transform .6s;
    z-index: 1;
    transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0)
}
.block-des:after {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .2);
    transition: background-color .25s ease
}
.block-des img {
    transform: scale(1.12);
    transition: transform .25s ease
}
.block-des:hover img {
    transform: scale(1)
}
.block-des:hover:before {
    transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0)
}
.block-des:hover .local-name,
.block-des:hover .temparature {
    z-index: 0
}
.block-des:hover .des-info-total>div {
    opacity: 1;
    visibility: visible;
    transform: scale(1)
}
.block-des:hover:after {
    background-color: rgba(0, 0, 0, .7)
}
.block-des .des-info-total {
    width: 100%;
    position: absolute;
    z-index: 1;
    color: #fff;
    top: 50%;
    margin-top: -55px;
    padding: 0 15px
}
.block-des .des-info-total>div {
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    transition: all .15s ease;
    transition-delay: .15s;
    transform: scale(.8)
}
.block-des .des-info-total a {
    position: relative;
    display: inline-block;
    color: #fff
}
.block-des .des-info-total .icon {
    color: #ffc107;
    position: absolute;
    font-size: 18px;
    top: 5px
}
.block-des .des-info-total .total-info {
    margin: 0;
    padding-left: 25px;
    display: inline-block
}
.block-des .des-info-total .total-info p {
    margin: 0;
    font-size: 12px;
    text-transform: uppercase
}
.block-des .des-info-total .total-info p:first-child {
    font-size: 22px;
    font-weight: 500
}
.block-des .temparature {
    position: absolute;
    top: 35px;
    right: 35px;
    z-index: 1
}
.block-des .temparature h4 {
    text-align: right;
    font-weight: 400
}
.block-des h4 {
    margin: 0;
    font-weight: 700;
    color: #fff
}
.block-des h4 sup {
    font-size: 50%
}
.block-des .name {
    text-transform: uppercase;
    color: #ffc107;
    font-weight: 700;
    font-size: 12px
}
.block-des .local-name {
    position: absolute;
    left: 35px;
    bottom: 35px;
    z-index: 1
}
.des-list-country {
    margin-bottom: 80px;
    margin-top: 30px
}
.des-list-country ul {
    margin: 0;
    padding-left: 15px
}
.des-list-country ul a {
    padding-bottom: 10px;
    display: inline-block;
    min-width: 150px
}
.des-list-country ul>div {
    padding-left: 0
}
.popular-destination-items {
    margin: 10px 0 0
}
.popular-destination-item {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    float: left;
    width: 25%;
    height: 200px;
    overflow: hidden;
    margin-top: -1px
}
.sticky_sorting {z-index:999;}
.list-col-2 .sticky.sticky_sorting { width:800px !important; }
.list-col-2 .sticky.sticky_pagination { width:800px !important; }
.list-full .sticky_sorting { z-index:90 !important; }
.mb_show_map{display:none;}
.client_mg_popup.modal { top:90px;}
ul.room_features_list {padding:0 0 0 15px;}
.bg-overlay .padding_left{ padding-left:15px;}

/* Terms and conditions EULA */
.terms_condition_page { width:100%; padding:0 10px;  display:inline-block; color:#000; font-size:13px; line-height:normal; max-height:600px; overflow-y:scroll;}
.terms_condition_page .content_part {width:100%; float:left; margin:30px 0; line-height:21px }
.terms_condition_page .content_part .highlight_text { color:#555; font-weight:700; text-align: center; text-transform:uppercase; line-height: 22px;}
.terms_condition_page .content_part .highlight_title { width:100%; float:left; margin:0 0 15px 0;  text-transform:uppercase; text-align:center; color:#f7951f; font-size:20px;     }
.terms_condition_page .content_part ul { padding:0 0 0 20px; }
.terms_condition_page .content_part ul li { padding:2px 0;}
.terms_condition_page .content_part .uppercase_text{text-transform:uppercase;}
.inner_logo {width:100%; float:left; text-align:center;}
.inner_logo img { max-width:200px; }
.modal {z-index:99999;}

/*#popover-save-search .popover-content .form-group .autocomp.agentcommute { position:inherit !important;}
#popover-save-search .popover-content .form-group .autocomp.agentcommute .rbt-aux {margin-top:-22px;}*/
#popover-save-search .popover-content .form-group .form-control .dropdown-menu {width:100%;}
#popover-save-search .popover-content .form-group .form-control .dropdown-menu li > a {white-space:inherit; line-height: normal; padding:3px 10px;}
#popover-save-search .popover-content .form-group .autocomp .rbt-aux {margin-top:-22px;}
#popover-save-search .popover-content .form-group .rbt-input{ width:100% !important;}
.top_search_box .input-group .dropdown-menu {min-width:300px;}
/* .highcharts-container { height:220px !important; margin-bottom:-20px; } */
.quick-bar-btn.btn-default {width:32px; height:32px; padding:0px !important; margin:0;}
.quick-bar-btn.btn-default:hover { background-color:inherit !important; box-shadow:inherit; }
.quick-bar-btn .notification-badge {top:-4px !important;right: -7px !important;}
.quick-bar-btn {position:relative; }
.t_icon:after {
    background:url(../img/svg/chat_icon_m.svg) no-repeat right; 
    /* background:url(../img/chat_icon.png)no-repeat right;  */
    content:""; position:absolute; top:-3px; left:0; width:40px;height:40px; background-size:cover;}
.inner_dl_tab {display:none;  width:100%; float:left; box-shadow:0px 1px 10px rgba(0,0,0,0.2); background:#5d5c5c; font-weight:normal; font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; line-height:20px; }
ul.property_nav { list-style:none; width:100%; float:left; margin:5px 0 2px 0; padding:0px;}
ul.property_nav li {list-style:none; border-bottom: 2px solid transparent; margin:0 12px; color:#fff; cursor:pointer;  float:none; display:inline-block; vertical-align:middle; font-size:13px;  }
ul.property_nav li:hover {border-bottom:2px solid #f7941e;}
ul.property_nav li:first-child { margin:0 12px 0 0;}
ul.property_nav li.active {border-bottom:2px solid #f7941e;}

.sticky.header_sticky { z-index:999;}
.sticky.header_sticky .inner_dl_tab { display:block;}
.sticky.sidebar_sticky_pd { padding:20px 0 0 0;}

.save_searchtable .react-bs-container-body .fa-bell-slash,.saved_searches_rt .react-bs-container-body .fa-bell { font-size:1.3em;}
.save_searchtable .react-bs-container-body .fa-trash { font-size:1.3em;}
.save_searchtable .react-bs-container-body .fa-check-circle { font-size:1.2em; /* color: #5cb85c */}
.save_searchtable .react-bs-container-body .tags {margin-left:0px;}
.save_searchtable .react-bs-container-body .breadcrumbheader {padding-left:0px;}
.save_searchtable .react-bs-container-body .table-bordered > tbody >tr > td { padding:8px 8px 7px 8px;}
/*.right-wrapper.scrollarea .breadcrumbheader {padding-top:10px;}
.com_panel_heading .panel-heading {padding:0px;} */
/* .com_panel_heading .panel-heading a {padding:10px 15px !important;} */
.text_center { text-align:center !important;}
/* .react-bs-table table td {white-space:inherit !important;}
.react-bs-table table th { text-transform:none; } */

.last_comment { width:auto; float:right; margin:-5px 6px 10px 0; text-align:right; font-size:13px; line-height:normal; }
.view_more_comment { width:auto; float:left; text-align:center; font-size:13px; margin:-5px 0 10px 6px; color:#555; line-height:normal;}
.view_more_comment span{cursor:pointer; vertical-align:middle;}
.view_more_comment span:hover {color:#f7951f;}
.view_more_comment .fa { font-size:14px; font-weight:bold;}
.after-filters {margin-left:225px; margin-bottom:0px; margin-top:0px;  }
/************************************timeline********************************************************/
.dropdown .react-select-box-options {z-index:9999999 !important; }
.filter_view_pop.hide_filter .filter_sub_bg {transform:translateY(calc(-100% - 120px)); transition:transform .95s ease-out;}
.filter_view_pop.show_filter .filter_sub_bg{transition:transform .95s ease-out;}

.detail_mid_form{ width:100%;float:left; margin:0 0 8px 0;}
.form-horizontal .change_password.form-group { margin-left:0px; margin-right:0px;}
.change_password_bt {padding-left:130px;}

.add-client-wrapper {width:45%; margin: 0 auto;}
.add-client-title { width:100%; float:left; margin:10px 0 20px 0;}
.add-client-title h3 { margin:0px;}
.addclient-btns {display: inline-block; margin: 20px 5px;}
.addclient-btns .btn {width: 210px; padding: 8px;}
/* .tooltip_box { max-width:300px; } */

/* .newtap_icons .nav-tabs li a {padding:10px 15px !important; font-size:15px;} */
.newtap_icons.saved_searches_rt .nav-tabs li a:focus {background:none;}
.newtap_icons .nav-tabs li a span {cursor:pointer; color:#555; }
.newtap_icons .tabicon_box {display:inline-block; vertical-align:top;}
.newtap_icons .tabicon_text { display:inline-block; vertical-align:middle; line-height:normal; font-weight: normal;}
.slider_photo {display:none;}
.header_sticky .slider_photo {display:inline-block; vertical-align:bottom; width:100px; height:56px; overflow:hidden; margin:0 25px 0 0; position: relative;}
.header_sticky .slider_photo:after{width:100%; padding-bottom:74%; display:block; content:""; }
.header_sticky .slider_photo img {width:100%; display: block; height:auto; position:absolute; margin:auto; top: -100%; right: -100%; bottom: -100%; left: -100%;}
.address_topinfo {display:inline-block; vertical-align:middle;}
.panel-arrows .panel-heading .panel-title h4 {
    /* margin:0px;  */
    color:#333333;
}
.panel-arrows.lifestyle_costbox .panel-heading { background-color:#f7941e !important; border-radius:inherit !important; color:#fff !important; cursor:inherit !important;}
.panel-arrows.lifestyle_costbox .panel-heading .panel-title { cursor:inherit !important;   padding:10px 15px!important;}
.panel-arrows.lifestyle_costbox .panel-heading .panel-title h4 {color:#fff !important; margin:0px; }
.panel-arrows.lifestyle_costbox .panel-heading .panel-title a:after { display:none;}
.panel-arrows.lifestyle_costbox.panel { border:inherit !important; border-radius:inherit !important; box-shadow:inherit !important}
.panel-arrows.lifestyle_costbox .toggle_header_right {cursor:inherit !important;}
.panel-arrows.lifestyle_costbox .toggle_header_left {cursor:inherit !important;}

.subcompany_box {width:35px; height:35px;  display:none; vertical-align:bottom; margin:5px 5px 0 15px;}
.subcompany_img { width:130px; padding:1px; height:32px; float:left; border:1px solid #fac17d; margin:2px 0 0 1px;}
.subcompany_box img {float:left; border:none; }
.subcompany_logo.nsmls_logo{background:url(../img/nsmls_logo.jpg) no-repeat center; background-size:cover; width:126px; height:28px;}
.subcompany_logo.crmls_logo{background:url(../img/crmls_logo.jpg) no-repeat center; background-size:cover; width:126px; height:28px;}
.subcompany_logo.mlsli_logo{background:url(../img/mlsli_logo.jpg) no-repeat center; background-size:cover; width:126px; height:28px;}
.subcompany_logo.myfr_logo{background:url(../img/myfr_logo.jpg) no-repeat center; background-size:cover; width:126px; height:28px;}
.main-heading h2 .show_more .btn { position:absolute; bottom:0px; right:0;}
.small_labels {font-style:italic;font-size:11px; font-Weight:normal; padding-left:10px;}

/* .animation_logo {border:1px solid rgba(255,255,255, .1); padding:10px; position:absolute; z-index:99;top:10px; font-size:14px; right:10px;text-align:center;background:rgba(74,74,74, 1); width:auto;color:#fff;line-height:normal;} */
.animation_logo {border:1px solid rgba(255,255,255, .1); padding:10px; position:absolute; z-index:99;top:10px; font-size:14px; left:10px;text-align:center;background:rgba(74,74,74, 1); width:auto;color:#fff;line-height:normal;}
.embosse_logo { display:none;  position:absolute; z-index:99;top:18px;font-size:14px; right:15px;text-align:center; padding:0px; width:200px;color:#000;line-height:normal;text-shadow:-1px -1px 0px rgba(255,255,255,0.3), 1px 1px 0px rgba(0,0,0,0.8);}
.embosse_logobox { position:relative; width:100%; float:left; padding:0px 10px 13px 10px; background:url(../img/patton_steel_mid.jpg) repeat-y top; background-size:100%;}
.embosse_logobox:after { position:absolute; top:-21px; left:0; content:""; background:url(../img/patton_steel_top.png) no-repeat center; background-size:100%;  width:100%; height:21px;}
.embosse_logobox:before { position:absolute; bottom:0; left:0; content:""; background:url(../img/patton_steel_btm.jpg) no-repeat bottom; background-size:100%;  width:100%; height:13px;}

.switch {background: #ccc !important;border: 1px solid #ccc !important;}
.switch.on {background: #f7951f !important;border: 1px solid #f7951f !important;}
.switch-small {width: 35px !important; height: 18px !important; border-radius: 9px !important;}
.switch-small .switch-toggle {width: 16px !important; height: 16px !important; border-radius: 8px !important;}
.switch-small.switch.on .switch-toggle {left: 18px !important;}

.tbl-imgbox {border:1px solid var(--theme-primary-light-color, #365b9d); width:102px; height:68px; margin:0 11px 8px 0; float:left;  }
.tbl-img {border:1px solid #fff;  padding:0px; position: relative; width:100px;overflow:hidden; height:66px; display:inline-block; vertical-align:top;}
.tbl-img img {width:100%; display: block; height:auto; position:absolute; margin:auto; top: -100%; right: -100%; bottom: -100%; left: -100%;}
.tbl-img:after {width:100%; padding-bottom:74%; display:block; content:""; }
/* .tbl-propbox {display:block; overflow:hidden; float:none; vertical-align:top;  text-decoration: none; color: #555;} */
.tbl-propbox:hover {text-decoration: none; color: #555;}
.tbl-propbox-txt {line-height: 1.4; margin-bottom: 5px;}
.suggested_data .suggest_boxlink:hover{ text-decoration:none;}
.status_suggest .label-primary {max-width:100%; display: inline-block; line-height:normal; }

.table-bordered > thead > tr > th, 
.table-bordered > tbody > tr > th, 
.table-bordered > tfoot > tr > th, 
.table-bordered > thead > tr > td, 
.table-bordered > tbody > tr > td, 
.table-bordered > tfoot > tr > td { border:none !important;}
/* .react-bs-table-bordered {border: 1px solid #eee !important; border-radius:0 !important;} */
.suggested_agent_dr { display:inline-block; vertical-align:middle; margin:0 20px 0 0;}
.suggested_agent_dr .dropdown {border:none;}
.suggested_agent_dr .dropdown .btn{padding:6px 30px; text-transform:none;}
.suggested_agent_dr .dropdown .dropdown-toggle.btn {padding:6px 10px;}
.suggested_agent_dr .dropdown-toggle { border:1px solid #ddd !important; background:#ddd !important; }
.suggested_agent_dr .dropdown-toggle:hover{background:#ddd !important; box-shadow:inherit !important;}
.suggested_agent_dr .btn-group.open .dropdown-toggle:active {box-shadow:inherit !important;}
.suggested_agent_dr .btn-warning { background:#fff; color:#555 !important; border:1px solid #ddd; margin-left:0px !important;}
.suggested_agent_dr .btn-warning:hover,.suggested_agent_dr .btn-warning:active{background:#fff; box-shadow:inherit !important;  color:#555 !important; border:1px solid #ddd;}
.suggested_agent_dr .dropdown-menu {padding:0px; border-radius:inherit;}
.suggested_agent_dr .dropdown-menu .divider {margin:0px;}
.suggested_agent_dr .dropdown-menu li a { padding:8px; }
.suggested_agent_dr  .dropdown-menu .disabled {background:#ddd;}
.suggested_agent_dr  .dropdown-menu .disabled a div{ color:#7b7a7a !important; }
.suggested_agent_dr .loader_ani { text-align:center;}
.table_heading_lt { display:inline-block; vertical-align:middle; width:100%;  }
.table_heading_lt .btn-add-client {margin:0 15px 10px 0;}
.filters-stage { position:absolute; min-width:200px; top:-3px; left:198px; z-index: 999999;right: unset;}
.filters-stage .dropdown.compact {text-transform: none;}
.filters-stage .dropdown.compact .react-select-box {padding: 8px 0;}
.filters-stage .dropdown.compact .react-select-box:before {top: 5px;}
.filters-stage .dropdown.compact .react-select-box:after {top: 14px;}
.filters-stage .react-select-box-options .react-select-box-option:last-child { font-style: italic; background-color: #ccc;}
.filtertable {position: relative}
.radiogroup_box {width:100%; float:left;}
.radiogroup_box .btn-group {width:100%; float:left;}
.radiogroup_box .btn-group .btn {padding:8px; width:33.33%; min-height:36px;}
.radiogroup_box .btn-group .btn-info {background-color: #f7941e !important;border: 1px solid #dca80a !important; color:#fff; box-shadow:inset 0 3px 5px rgba(0, 0, 0, .125);}
.radiogroup_box .btn-group .btn-default {background-color: #fff !important; border:1px solid #908e8e !important; color: #7b7a7a;}
.radiogroup_box .btn-group .btn-default:hover {background-color: #f7941e !important;border: 1px solid #dca80a !important; color:#fff; box-shadow:inset 0 3px 5px rgba(0, 0, 0, .125);}
.padding_left_30 { padding-left:30px;}
.padding_left_right_20 { padding-left:20px; padding-right:20px;}
.mapPanel{padding-top:5px;}
.cropping_box { position:relative; }
.profile_photo_btn { width:100%; float:left; text-align:center;}
.profile_photo_btn .profile_photo_btnbox { float:none; display:inline-block; vertical-align:top; }

/*.leaflet_icon_draw{background:url(../img/draw_polygon.png) no-repeat left;width:48px !important;height:48px !important;background-size:80%; background-position: center center; }
.leaflet_icon_draw_clear{background:url(../img/leaflet_remove_icon.png) no-repeat left;width:48px !important;height:48px !important;background-size:80%; background-position: center center; }*/
/* .leaflet_icon_draw{background:url(../img/svg/draw_polygon.svg) no-repeat left;width:48px !important;height:48px !important;background-size:80%; background-position: center center; } */
.leaflet_icon_draw_clear{background:url(../img/svg/remove_polygon.svg) no-repeat left;width:48px !important;height:48px !important;background-size:80%; background-position: center center; }
/* .leaflet-control-layers-toggle { background:url(../img/svg/truebytlc_maplayers.svg)} */
/* .leaflet-touch .leaflet-control-layers-toggle { background:url(../img/svg/truebytlc_maplayers.svg) !important} */
.highlight_amount {color:#000;font-weight:bold; }
.show_more {float: right; margin-top: 5px;}
.show_more .fa { padding:0px; vertical-align:top; font-size:18px; }
.show_more .btn {border:1px solid #f7941e; padding:8px 20px;}
.show_more .btn span { padding:0 10px; vertical-align:middle;}
.main-heading h2 .show_more .btn span {vertical-align:top;}
.show_more.bottom_showmore { float:none; margin:13px 0 5px 0; display:inline-block;}
.filter_btns { text-align:right; }
.filter_btns .filter_box { float:none; display:inline-block;}
.filter_btns .comn_button { float:none; min-width:120px; }
.filter_btns .comn_button .fa { padding:0 5px 0 0;}


.chatprofile_name { background:#fff; border-radius:100%; width:48px; height:48px; text-transform:uppercase;  text-align:center; float:left; border:1px solid #969494; padding:1px;}
.chatprofile_name .chatprofile_box {background: #4c4c4c; font-size:15px; line-height:42px; color:#fff; padding:2px; border-radius:100%; width:44px; float:left; height:44px;}

/* .profile_st_names {font-size:35px; width:100%; height:100%; line-height:80px; background: #C8C8C8; color:#000; text-transform:uppercase; border:1px solid #fff; border-radius:100%; } */
.client_address_info .mini_height { min-height:40px;}
.saved_searches_rt .nav-tabs li a {
    /* font-size:14px; 
    text-transform:none; 
    font-family: "Roboto", Helvetica, Arial, sans-serif !important; */

    font-size: 12px;
    text-transform: none;
    padding: 15px;
    background-color: #F1F1F1;
    margin: 0;
}
.detail_gallery .carousel .slide img{position:absolute; width:100%;height:auto;margin:auto;top:-101%;right:-100%;bottom:-105%; left:-100%;}
.detail_gallery .carousel .slide:before {width:100%;padding-bottom:54%;display:block;content:"";}
.profile_client_bt .btn, .profile_client_bt .btn-default {display:block; text-transform:none; width:100%; border:none !important;}
.detail_gallery .carousel.carousel-slider .control-arrow {padding: 15px; height: 200px; margin-top: -100px; top: 50%;}
.detail_gallery .carousel .thumb {position:relative; cursor:pointer; width:80px; height:61px;   margin: 0 0 4px 0;}
.detail_gallery .carousel .thumb img{position:absolute; width:150%; height:auto;margin:auto; top:-100%;right:-100%;bottom:-100%; left:-100%;}
.detail_gallery .carousel .thumb:before {width:100%;padding-bottom:4%;display:block;content:"";}
.filter_matches { width:100%; text-align:center; float:left; font-size:14px; margin-top:15px;  margin-bottom:0;}
.filter_matches label{font-size:17px !important; color:#000 !important;  margin:0 8px;  float:none !important; width:auto !important;  display:inline-block; vertical-align:middle;}
.filter_matches .comn_button { float:none; display:inline-block; vertical-align:middle; margin:0 8px;}
.filter_matches .comn_button .fa { padding:0 5px 0 0;}

.left-wrapper .leaflet-control-zoom {margin-top: 50px;}
.left-wrapper .leaflet-control-zoom a {color: #BABABA; }
.joyride-overlay {z-index: 999999 !important;/* height: 100vw !important; */}
/* .leaflet-left .leaflet-control-scale {margin-left:65px;} */
.fico_tlctext { width:100%; float:left; padding:3px 10px;}
.tour-details-tlc { float:right; }
.alerts_save {width:100%; float:left;  padding:7px 0 12px 0;}
.alerts_save i {padding:0 5px 0 0; color: var(--theme-secondary-color, #fbb040);} 
.alerts_save .alerts_input { width:auto; float:none; display:inline-block; padding:0 0 0 5px;}

.inline-control {display: inline-block;}
.gross_income_input {width:100%;float:left; }
.gross_income_input.first {width:30%;}
.gross_income_input.last {width:70%; min-height:46px; line-height:17px; padding:4px 0 2px 8px; margin:0; background-color: #ddd; }
.gross_income_input.last label { margin:0px 0 3px 0; }


.inline-control.last label {margin-bottom: 0;}
.propdetail_sidebar .prot_cost_box.total_cost_box.gray_box {background: #C6C6C6; color: #555; margin: 0 -10px 0 -10px;}
.propdetail_sidebar .prot_cost_box.total_cost_box.gray_box.last {border-top: 1px solid #D4D4D4;}
.tour-monthly-tlc{height:42px;}

/************************************tax rate html********************************************************/
/* .chart_tooltip { width:400px !important; max-width:400px !important;} 
.chart_ratebox { width:100%; text-align:left; padding:0px; float: left; color:#000;  background:#fff; border:1px solid #e7e7e8;}
.chart_ratebox .rate_header { width:100%; float:left; font-size:12px;  }
.chart_ratebox .rate_header.header_top{color: #fff; background:#4c4c4c;  font-size:13px;}
.chart_ratebox .rate_header .header_box1 { width:11%; float:left; padding:5px 7px; border-right:1px solid #ddd; border-bottom:1px solid #ddd;}
.chart_ratebox .rate_header .header_box2 { width:45%; float:left; padding:5px 7px; border-right:1px solid #ddd; border-bottom:1px solid #ddd;}
.chart_ratebox .rate_header .header_box3 { width:44%; float:left; padding:5px 7px; border-bottom:1px solid #ddd;}
.chart_ratebox .rate_header:last-child .header_box1,.chart_ratebox .rate_header:last-child .header_box2,.chart_ratebox .rate_header:last-child .header_box3 { border-bottom:none}*/


.autocomp.homesearch .rbt-input {overflow:hidden;}
.homesearch .dropdown-menu { width:100%; }
.homesearch .dropdown-menu li > a {white-space:inherit;}
.form-fields-group {background-color: #eee; padding:15px; display:inline-block; }
.form-fields-group2 {padding:5px 15px 15px 15px;  float:left; width:100%;}
.form-fields-group-header {border-bottom:1px solid #fff; padding-bottom: 15px;}
.incom_hide_input .hide_input { width:15px; margin:0 5px 0 0; float:left; }
.incom_hide_input .hide_input_text {display:block; overflow: hidden;float: none;width:auto;}
/* .personalize_text { width:100%; float:left;  } */

.total-home-score-intro {padding:0px !important; line-height:20px !important;  font-size:14px;}
.total-home-score-widget {line-height:normal !important;}
.score-factor-list { margin-top:10px; line-height:20px; margin-bottom:0px;}
/* .notification-message {font-family: "FilsonProRegular" !important;} */

.notifications-tc {
    z-index:9999999 !important;
    transform:translate(-50%,-50%);
    top:50% !important;
    left:50% !important;
    margin:0px !important;
    min-width:340px;
}

.notification .notification-dismiss {
    top:2px !important;
    right:10px !important;
    font-size:40px !important;
    line-height:34px !important;
    font-weight:normal !important;
    color: #000 !important;
    width:auto !important;
    height:auto !important;
}
body .notification .notification-dismiss {
    background-color:inherit !important;
}

.notification-error {
    /*background-color:rgba(236,191,191, 1) !important;
    box-shadow:0 0 10px 0 #0f0f0f !important;*/
    box-shadow:inherit !important;
    background-color: #B2B2B2 !important;
    color:#ffffff !important;
}
.notification-error .notification-dismiss {background-color:rgb(231, 115, 115) !important;}
.notification-error .notification_btn .btn-default { background:#ec3d3d !important; border-color:#ec3d3d !important }
.notification-error .notification_btn .btn-default:hover {background:#ec3d3d !important;}

 /*.notification-info {
   background-color:rgba(178,220,238, 1) !important;
    box-shadow:0 0 10px 0 #0f0f0f !important;*/
    /* box-shadow:inherit !important;
    background-color: #B2B2B2 !important;
    color:#ffffff !important; 
 }*/
.notification-info .notification-dismiss {background-color: rgb(46,177,241) !important;}
.notification-info .notification_btn .btn-default { background:#369cc7 !important; border-color:#369cc7 !important }
.notification-info .notification_btn .btn-default:hover {background:#369cc7 !important;}

.notification-success {
  /*background-color:rgba(220, 237, 196, 1) !important;
  box-shadow:0 0 10px 0 #0f0f0f !important;*/
  box-shadow:inherit !important;
  background-color: #B2B2B2 !important;
  color:#ffffff !important;
 }
.notification-success .notification-dismiss {background-color:rgb(110,162,39) !important;}
.notification-success .notification_btn .btn-default { background:#5ea400 !important; border-color:#5ea400 !important }
.notification-success .notification_btn .btn-default:hover {background:#5ea400 !important;}

.notification-warning {
     /*background-color:rgba(245, 233, 205, 1) !important;
    box-shadow:0 0 10px 0 #0f0f0f !important;*/
    box-shadow:inherit !important;
    background-color: #B2B2B2 !important;
    color:#ffffff !important;
}
.notification-warning .notification-dismiss {background-color:rgb(235, 182, 57) !important;}
.notification-warning .notification_btn .btn-default { background:#ebad1a !important; border-color:#ebad1a !important }
.notification-warning .notification_btn .btn-default:hover {background:#ebad1a !important;}
.notification_btn {display:inline-block; margin:7px 0 0 0; text-align:center; width:100%;}
.notification .notification_btn .btn-default {min-width:82px; padding:5px; margin:5px 3px; color:#fff !important;}
.score-factor-list > li {font-weight:normal !important; font-size:14px; line-height:22px;}
.mortgage_text { font-size:14px; padding-bottom:10px;}
.utilities_text{ font-size:14px; padding-bottom:10px;}
.score-widget-body { width:100%; float:left; height:auto !important; }
.score-level-description {font-weight:normal !important; font-size:14px; margin-top:10px; line-height:20px;}
.score-title {display:block;}
.score-circle {font-size:19px !important;}
.score-tab-name {font-weight: initial !important;}

.commute-time-box {
    width: 210px;
    display: inline-block;
}
.commute-mode-box {
    display: inline-block;
    width: 35px;
    border: 1px solid #ddd;
    height: 35px;
    vertical-align: top;
    margin-left: 5px;
    padding: 2px 4px;
    font-size: 22px;
}
/* .commute-name-box {
    width: 186px;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
}
.commute-name-select {
    display: inline-block;
    margin-left: 8px;
    background: var(--theme-secondary-color, #fbb040);
    padding: 2px 5px 2px 5px;
    cursor: pointer;
    color: #fff;
    font-size: 12px;
    width: 44px;
    vertical-align: middle;
} */
/* .commute-profile-box {
    border: 1px solid #ddd;
    padding: 5px;
    margin-bottom: 8px;
}
.commute-profile-box-label {
    margin: -12px 0 0 -3px;
    font-size: 12px;
    padding: 0 0 0 2px;
}
.commute-profile-box-label span {
    background: #fff;
    padding: 0 3px 0 3px;
}
.commute-profile-box-content {
    width: 100%;
    margin-top: 5px;
}
.commute-update-profile-box {
    font-size: 13px;
    margin-top: 8px;
    border-top: 1px solid #ddd;
    padding: 5px 0 5px 0;
}
.commute-update-profile-btn {
    background:var(--theme-secondary-color, #fbb040);
    padding: 2px 5px 2px 5px;
    cursor: pointer;
    color: #fff;
    font-size: 12px;
    vertical-align: middle;
    margin-left: 5px;
} */

.popup-fixed {
    position: fixed;
    top: auto;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    transform: none !important;
    margin: 0;
    border-radius: 0;
}
.popup-fixed .leaflet-popup-tip-container {
    display: none;
}
/* .popup-fixed .leaflet-popup-content-wrapper {
    border-radius: 0;
} */
.leaflet-fixed-pane {
    z-index: 1000;
}

/*********************************google_map_slider************************************************************/
.tour-detail-slider .google_map_thumb + div > .carousel.carousel-slider + .carousel > .thumbs-wrapper  { margin-left:80px !important;}
.detail_slider .google_map_thumb + div > .carousel + .carousel .control-arrow.control-prev {left:81px !important;}
.detail_slider .google_map_thumb + div > .carousel + .carousel .control-arrow.control-prev + .thumbs {padding:0px;}
.detail_slider.tour-detail-slider .google_map_slider {width:100%;float:left; height:calc(100% - 61px);; background: #ddd; position:absolute;top:0;left:0;z-index:90;}
.detail_slider.tour-detail-slider {position:relative; }
.google_map_thumb {width:80px; cursor:pointer; border:2px solid #fff; height:61px; z-index:90; position:absolute; left:0; bottom:0; background:#000; }
.google_map_thumb:hover,.google_map_thumb.active {border:2px solid #555;} 

.google_map_thumb img {height:57px;}
.google_map_thumb .map_text { position:absolute; color:#000; top:10px; left:0; width:100%; text-align:center; font-size:14px; }
.google_map_thumb .map_text span { display:block; width:100%;}
.google_map_thumb .map_text .fa { font-size:19px; color: #f7941e; visibility: hidden; }
.google_map_thumb.active + div > .carousel.carousel-slider + .carousel > .thumbs-wrapper > .thumbs > .thumb.selected{ border:2px solid #fff; }

.subscription_title { width:100%; float:left;}
.subscription_title h3 { font-size:17px; margin-bottom:10px;}
.subscribe_info .control-label { padding-top:0px;}
.titleVendor input[type="radio"] {position: relative; }

_:-ms-lang(x), .dropdown.semicompact .react-select-box#react-select-box-8 { padding:7px 0 6px 0;} 

@media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
.dropdown.semicompact .react-select-box#react-select-box-8 { padding:7px 0 6px 0; } 
}
/********************************common content*************************************************************/
.common_content { width:100%; float:left; margin:20px 0;}
.common_content .content_list {padding: 0 0 0 15px;} 
.common_content .content_list li {padding: 3px 0 3px 0; display:list-item;}
.common_content .small_banner { width:100%; text-align:center;  float:left; margin:10px 0;}
.common_content .small_banner img { width:auto; }
/* .common_content h2 {margin-top:0; border-bottom:2px solid #f7941e; padding:5px 0 10px; color:#44515e;} */


/* .nav-login-btn {
    padding: 5px 10px;
    background: rgba(255,255,255,0.6);
    border-radius: 2px;
    color: #555555;
    font-weight: bold;
} */

/************************************start media css********************************************************/
/********************************home page search filter*************************************************************/
.search-comp .search-border { width:100%; float:left; background:#fff; }
.search-comp .search-border .form-group { margin:0px;}
.home_filter_tlc_price { width:100%; display:inline-block; vertical-align:middle; height:41px; }
.home_filter_tlc_price span { width:100%; float:left; color:#555; line-height:41px; font-size:15px;}
.tlc_what_popup{ width:100%; float:left; background:#fff; position:relative;}
.tlc_what_popup .gray_bg_text { width:100%; font-weight:bold; float:left; color:#525e6a; font-size:13px; line-height:16px; background:#f7f7f7; border-bottom:1px solid #ebebeb;  padding:5px 15px 5px 5px;}
.tlc_what_popup .what_tlc_text { width:100%; float:left; color:#555555; font-size:13px; line-height:18px; padding:5px 7px; }
.tlc_what_popup .what_tlc_text span {display:block; padding:3px 0;}
.tlc_what_popup .close_popup { position: absolute; top:-8px; right:-8px; background:#fff; border-radius:100%; width:22px; height:22px; line-height:normal; text-align:center; }

.tour-property-beds > div { float: left; }
.tour-property-beds div + div::before {
    content: " | ";
    padding: 0 5px;
    color: #a9a9a9;
}
.tour-property-beds img { width:auto; }
.tour-property-beds span {display:inline-block; vertical-align:middle; line-height:normal;}
.tour-property-beds .form-group .property_icons_txt { font-size:16px;}
.tour-property-beds .form-group { margin-bottom:10px;}
.tour-property-beds .form-group span .fa.fa-2 { font-size:18px;} 
.tour-property-beds .property_icons { margin-right:5px;}
.tour-property-beds .property_icons_txt {margin-right:5px;}
.tour-property-beds .property_txt { font-weight:bold; }
.tour-property-beds:last-child { margin-bottom:0px;}

.new_stylemenu .fa-circle {color: lightgreen;} /*** no idea where this belongs ***/

.dashboard_page .nav-tabs {margin:0px;
    background-color: #F1F1F1;
    /* padding-left:10%; */
    text-align: center;
}
#tabSections > ul, #tabsProfile > ul {
    display: flex;
}
#tabSections > ul > li, #tabsProfile > ul > li {
    flex-grow: 1;
}
#tabSections .nav-tabs i,
#tabsProfile .nav-tabs i,
.myagent_tab .nav-tabs i,
.dashboard_page .nav-tabs i {
    font-size: 26px;
    padding-right: 8px;
}
.nav-tabs > li {
    float: none;
    display: inline-block;
}

div[id^="dashboardTabs-pane-"] {
    padding: 15px;
}
/* #dashboardTabs-pane-1 { padding:15px 0;} */ 
/* .dashboard_page .tab-content .tab-pane { padding:15px 0;} */

.includecommute-wrapper.add_location .fa { font-size:30px; color:#f7941e;}
.includecommute-wrapper.add_location .location_icon_text { margin-left:7px; font-size:17px; display:inline-block; vertical-align:middle; }

.tour-copyright-cnt { margin-top: -2px; margin-bottom: 10px;}
#oa_social_link_container { width:100%; float:left;}
#oa_social_link_container iframe { width:100% !important; float:left;}
/***************************************Available Funds popup******************************************************/
.funds_edit { width:100%; display:block;}
.funds_edit .edit_label { font-size:13px; line-height: normal;}
.funds_edit .edit_label span { font-size:11px; display:inline-block; }
.funds_edit .edit_input input{font-size:13px;}
.funds_edit .edit_input .form-control {font-size:13px;}
.funds_edit .edit_input .react-select-box-label {font-size:13px;}
.funds_edit .autocomp.tru-edit-input .rbt-aux {margin-top:-25px;}
.funds_edit .tru-edit-input .rbt-input-main {width: 90%;}
.funds_edit .tru-dropdown .react-select-box-label {padding:0 3px 0 10px;}

/********************************Search Commute*************************************************************/
.filter_inner_commute { width:100%; float:left; cursor:pointer; background:#fff; border:1px solid #908e8e; min-height:36px; }
.filter_inner_commute .commute_car {width:15%; float:left; border-right:1px solid #908e8e; text-align:center; line-height:36px;}
.filter_inner_commute .commute_car .fa { font-size:20px; color:#555;}
.filter_inner_commute .commute_car .fa.orange { color:#f7941e;}
.filter_inner_commute .location_name {padding:8px; white-space:nowrap; max-width:234px; overflow:hidden; text-overflow:ellipsis; text-align:left; font-size:14px; color:#7b7a7a; width:85%; }
.filter_inner_commute .location_name span {color:#555; font-weight:bold; }

.breadcrumb_box {width:100%; float:left; border-radius:2px; color:#4c4c4c; background:#e9e8e8; padding:5px 10px; margin:0 0 15px 0;}
.breadcrumb_box span { color:#555555; display:inline-block; vertical-align:middle; margin:0 3px 0 0;   }
.breadcrumb_box .fa {font-weight:bold; color:#666; font-size:16px; padding:0 6px;}
#popover-save-search.commute_filter .autocomp .rbt-aux {margin-top:-22px;}
.close_commute {display:none;}
.property_popup_wrapper, .property_popup { width: 300px; }
.property_popup { height: 150px !important; min-height: 150px !important; }
.breadcrumb_box .fa {font-weight:bold; color:#666; font-size:16px; padding:0 6px;   }

.agent_top_listing{width:auto; float:left; text-align:left; margin:0 0 0 -15px; }
.agent_top_listing span{display:inline-block; vertical-align:middle; padding:0 7px 0 0;}
.addclient_info label {font-size:13px;}
.addclient_info .label-error {font-size:12px;}
ol.progtrckr { padding:0px;}
.addclient_info .react-select-box {padding:14px 0;}
.done-icon-we { width:100%; float:left;}
.addclient_info .textbox-large {padding:0 10px;}
.addclient_info .dropdown .react-select-box-label {padding:0 3px 0 10px;}
.addclient_info .commute-search { padding-top:4px;}
.addclient_info .commute-search.textbox-large { padding-top:7px;}
.addclient_info .rbt-input { width:100% !important;}
.addclient_info .rbt-input > div {width:100% !important;}
.addclient_info .rbt-input > div > div {width:100% !important;}
.addclient_info .rbt-input > div > div > input {width:100% !important;}
.profile_client_bt {padding:0px;}
.profile_client_bt.form-group{ margin:0 0 10px 0;}
.profile_client_bt a {display:block; width:100%; border:none !important;}
.change_password.form-group input {padding:10px;}

/**************************************location Nearby css*************************************************/
.form-control.homesearch { padding-left:0px;}
.form-control.homesearch .rbt-input { width:93%; }
.form-control.homesearch .rbt-input > div { width:100%;}
.form-control.homesearch .rbt-input + .dropdown-menu { width:100%;}
.form-control.homesearch .rbt-input > div > div { width:100%;}
.form-control.homesearch .rbt-input > div > div input { width:100% !important; max-width:100% !important; }
.search-comp .search-bar select.form-control {padding-left:0px;}
.location_nearby {
    position:absolute; 
    z-index:3; 
    box-shadow:0 6px 12px rgba(0, 0, 0, .175);  
    width:100%; 
    /* top:100%;  */
    top: 45px;
    border:1px solid rgba(0, 0, 0, .15);  
    /* left:0;  */
    background:#fff; 
    min-height:100px;
}
.location_dropdown { position:relative;}
#location_dropdown {z-index:9; }
.top_search_box .location_nearby {left:-11px;}
.location_nearby .recent_search {width:100%; float:left; padding:10px; border-bottom:1px solid rgba(0, 0, 0, .15);;}
.location_nearby .recent_search:last-child{ border-bottom:none; }
.recent_search .recent_icon {width:30px; line-height:normal;  float:left; text-align: center; color:#f76b1c; font-size:16px;}
.recent_search .recent_icon .fa { vertical-align:top;}
.recent_search .recent_location { width:auto; overflow:hidden; float:none; display:block; line-height:normal;}
.recent_search .recent_location .item_sub_title {width:100%; float:left; padding:0 0 3px 0; font-size:15px; color:#555; }
.recent_search .recent_location .location_city {width:100%; cursor:pointer; float:left; font-style:italic; font-weight:bold;   font-size:13px; color:#555;}
.recent_search .recent_location .nearby_item {width:100%; float:left; padding-top:5px;} 
.recent_search .recent_location .nearby_item .icon_namebox { width:auto; margin-right:17px; padding:5px 10px; background:#f5f5f5; border-radius:2px;  border:1px solid rgba(0, 0, 0, .15);;  float:left; text-align:center; cursor:pointer;} 
.recent_search .recent_location .nearby_item .icon_namebox:last-child { margin-right:0px; }
.recent_search .recent_location .nearby_item .icon_namebox span { font-size:13px; vertical-align:middle; display:inline-block; padding:0 0px 0 5px; color:#555; }
.recent_search .recent_location .nearby_item .icon_box { text-align:center;  text-align:center;display:inline-block; vertical-align:middle; border:1px solid #939393; background:#dedede;  }
.recent_search .recent_location .nearby_item .icon_box .fa { font-size:20px; color:#555;vertical-align:middle;}
.recent_search .recent_location .nearby_item .icon_namebox:hover span { color:#f7941e;}
.recent_search .recent_location .nearby_item .icon_namebox:hover .icon_box .fa { color:#f7941e; }
button#nearby_open_houses:disabled,
button#nearby_open_houses:disabled > i,
button#nearby_open_houses:disabled span,
button#nearby_sale:disabled,
button#nearby_sale:disabled > i,
button#nearby_sale:disabled span {
    color: lightgray;
}

#nearby_open_houses span, #nearby_sale span {
    font-size: 13px;
    color: black;
}
.terms_condition_page { width:100%; padding:0 10px;  display:inline-block; color:#000; font-size:13px; line-height:normal; max-height:600px; overflow-y:scroll;}
.terms_condition_page .content_part {width:100%; float:left; margin:30px 0; line-height:21px }
.terms_condition_page .content_part .highlight_text { color:#555; font-weight:700; text-align: center; text-transform:uppercase; line-height: 22px;}
.terms_condition_page .content_part .highlight_title { width:100%; float:left; margin:0 0 15px 0;  text-transform:uppercase; text-align:center; color:#f7951f; font-size:20px;     }
.terms_condition_page .content_part ul { padding:0 0 0 20px; }
.terms_condition_page .content_part ul li { padding:2px 0;}
.terms_condition_page .content_part .uppercase_text{text-transform:uppercase;}
.inner_logo {width:100%; float:left; text-align:center;}
.inner_logo img { max-width:200px; }
.comn_button.reset_mobile { display:none; }
.filter_matches .comn_button .mob_results { display:none;}
.scroll_section { padding:0px; width:100%; display:block;}
/************************************start media css********************************************************/
/*********************************************************************************************/
@media only screen and (max-width:1440px){
.list-col-2 .sticky.sticky_sorting {width:783px !important; }
.list-col-2 .sticky.sticky_pagination {width:783px !important; }
.checkbox_style { width:auto !important;}

}
/*********************************************************************************************/
@media only screen and (max-width:1366px){
/*.search-comp_addition {bottom:20% !important;}*/
.banner-top-title {font-size:55px !important; top:18% !important; }
.banner-top-price {top:36% !important; font-size:25px !important; }
.propdetail_sidebar .prot_cost_box label span {display:inline-block;}
.propdetail_sidebar .prot_cost_box .cost_amountbox .currency_amount {font-size:13px !important;  min-width:56px !important;}
.propdetail_sidebar .prot_cost_box .btn-edit { margin-left:4px;}
.ct_profile_left .com_profile_box .com_profile_number { text-align:right;}
.show_more .btn { line-height:normal;}
}
@media only screen and (max-width:1365px){
.subcompany_box {height:35px; width:35px; float: right;}
.subcompany_img {height:35px; width:35px; border-radius:100%; margin:0px;}
.subcompany_img img {border-radius:100%;}
.subcompany_logo.nsmls_logo{background:url(../img/nsmls_logo_mb.jpg) no-repeat center; background-size:cover; width:31px; height:31px;}
.subcompany_logo.crmls_logo{background:url(../img/crmls_logo_mb.jpg) no-repeat center; background-size:cover; width:31px; height:31px;}
.subcompany_logo.mlsli_logo{background:url(../img/mlsli_logo_mb.jpg) no-repeat center; background-size:cover; width:31px; height:31px;}
.subcompany_logo.myfr_logo{background:url(../img/myfr_logo_mb.jpg) no-repeat center; background-size:cover; width:31px; height:31px;}
}
@media only screen and (max-width:1230px){
.autocomp.homesearch .rbt-input-main{max-width:197px !important;}
.location_dropdown .form-control {padding-left:0px;}
}
@media only screen and (max-width:1231px){
.autocomp.homesearch .rbt-input-main{max-width:179px !important;}
}
@media only screen and (max-width:1080px){
/*.input-search-top {width:275px;}*/
.select_style{margin:0 7px;}
.top_search_box .autocomp.search .rbt-input-main{max-width:133px !important;font-size:13px;}
.common_content .small_banner img {width:100%;}
}
/*********************************************************************************************/
@media only screen and (max-width:1024px){
.banner-top-title { font-size:44px !important; top:16% !important;}
.banner-top-price{top:28%;}
/*.search-comp_addition { bottom:7% !important; }*/
.banner-top-subtitle {font-size:2.6vh !important;}
.search-comp .search-bar .form-group.location_dropdown .dropdown-menu { height:155px; position:absolute; top:100%; left:-24px;}
.banner-top-price { top:30% !important; font-size:28px !important; }
.autocomp.homesearch .rbt-input-main {max-width:178px !important; }
.common_content h2 {font-size:22px;}
/*.input-search-top{ width:220px; }*/
.autocomp.search { width:74% !important;}
.autocomp.search .rbt-input-main, .autocomp.search .rbt-input-hint {width:100% !important;}
.find_filter { margin:0 0 0 6px !important; }
.filter_content { padding:0 15px; }
.checkbox_text { font-size:12px;}
.find_filter .width_80 {width:70px;}
.find_filter .width_85 {width:75px;}
.find_filter .width_103 {width:95px;}
.list-col-2 .sticky.sticky_sorting {width:800px !important;}
.list-col-2 .sticky.sticky_pagination {width:800px !important;}
.map-list-toggle { width:21px !important; }
.map_arrow_btn { width:21px !important; }
/* .propcount-wrapper { padding:5px !important; }
.list-full .propcount-wrapper div {margin: 0 0 0 -10px;} */
.left-wrapper.list-full {z-index:0;}
.toggle_header_right {font-size:14px; padding:0 20px 0 0 !important;}
h3 {font-size:17px; margin-top:10px;margin-bottom:10px;}
.panel-title{font-size:14px; }
.toggle_header_left span i{margin-right:5px !important;}
/*.filter_sub_bg .filter_close {top: 5px;width:25px;height:25px; line-height:normal;}
.filter_sub_bg .filter_close .fa {font-size:25px;}*/
.personalize_bnt .btn{ padding:8px;}
.header-top-bar .header-list-right .btn-search-top,.header-top-bar .header-list-right .btn-wish-list {padding:0px;}
.header-top-bar .header-list-right {padding-right:10px;}
/* .react-bs-table-tool-bar .react-bs-table-search-form {width:70%; float:right;}
.react-bs-table-bordered {margin:10px 0 20px 0;} */
.saved-search-title {font-size:14px !important;}
.table_heading_lt .btn-add-client .btn-default{padding:10px; line-height:normal;}
.btn-saved-searches .btn-add-client {margin-right:20px !important;}
.ct_profile_left .com_profile_box .com_profile_number {text-align:right; font-size:13px !important;}
.ct_profile_left .com_profile_box .com_profile_text { padding:0 0 0 5px !important; font-size:13px !important;}
.ct_profile_left .ct_profile_name {font-size:19px !important;}
.ct_profile_left .ct_profile_photo .profile_photo { width:70px !important; height:70px !important;}
.ct_profile_left .ct_profile_photo .profile_photo img { width:70px !important; height:70px !important;}
.addclient_info .textbox-large {min-height:45px; font-size:13px;}
.addclient_info .dropdown .react-select-box-label {font-size: 13px !important;font-weight:normal;}
/* .addclient_info .react-select-box { padding:8px 0 9px 0;} */
.addclient_info .react-select-box-clear {top:12px;}
.addclient_info .react-select-box:before {top:7px;}
.addclient_info .dropdown .react-select-box-clear:before {top:0px;}
.addclient_info .commute-search {padding-top:4px;height:45px;}
.client_address_info .textbox-large {height:40px;}
.btn.btn-lg {padding:7px 15px; font-size:14px;}
.saved_searches_rt .nav-tabs li a{ font-size:13px; line-height:normal;}
.property_price .price_left { padding:0 5px 0 0; }
.prop_status {max-width:54px; border-radius:3px;}
.includecommute-wrapper {max-width:380px; right:5px !important; font-size:13px !important;}
/* .propcount-wrapper { font-size:13px !important; } */
.save_searchtable .react-bs-container-body .table-bordered > tbody >tr > td {padding:6px; font-size:13px; }
.suggested_data .save_searchtable .react-bs-container-body .table-bordered > tbody >tr > td {padding:6px;}
/* .react-bs-table table td{white-space:inherit !important; font-size:12px; } */
.client_address_info .btn-toggle {min-width:53px; padding:5px 5px !important;}
.client_address_info .dropdown-menu {font-size:13px;}
.add-client-wrapper {width:50%;}
.inner_pd_sc .client_short a { display:inline-block;}
.inner_pd_sc .client_short a span{max-width:147px; text-align:left; display:block;  white-space:nowrap; overflow:hidden; text-overflow:ellipsis;}
.main-heading h2 .show_more .btn{ padding:7px 7px; line-height:normal; font-size:12px;}
.main-heading h2 .show_more .btn .fa { font-size:12px;}
.main-heading h2 .show_more .btn span { padding:0 2px;}
.subcompany_box { display:none;}
.embosse_logo { display:none;}
.filters-stage {left:197px; right:unset;}
.after-filters {margin-left:210px;}
.filters-stage .react-select-box-option {padding:10px; font-size:13px; }
/* .profile_st_names { font-size:30px; line-height:70px;} */
.tour_button { display:none;}
/* .compare_icon { display:none !important;} */
.propdetail_sidebar .prot_cost_box label {max-width:134px;}
.gross_income_input.last {line-height:12px; min-height:45px; padding:3px 0 2px 8px;}
.gross_income_input.last label{margin:0px 0px 2px 0px; font-size:12px;}
.content-wrapper .detail_mid_form .gross_income_input.last {padding:3px 0 1px 8px;}
.tour-property-beds .form-group .property_icons_txt {font-size:14px;}
.tour-property-beds .form-group span .fa.fa-2 {font-size:15px;}
.tour-property-beds img { width:16px; }
.tour-property-beds .form-group .property_icons {font-size:13px;}
.tour-property-beds .property_icons { margin-right:4px;}
.tour-property-beds .property_icons.basement_icon img {width:15px;}
.tour-property-beds .property_icons.pool_icon  img {width:19px;}
.tour-property-beds .property_icons_txt {margin-right:4px;}
.tour-property-beds .form-group .property_txt {font-size:13px;}
/* .new_stylemenu .nav li a {padding:15px 15px 11px;} */
.filter_inner_commute .location_name {max-width:195px;}
.filter_btns .filter_box {margin:0 5px;}
.common_h2_style h2 {font-size:22px;}
.location_nearby { width:310px; }
.search-comp .search-bar .location_dropdown .input-group-addon {padding:12px 10px 12px 10px;}
.recent_search .recent_icon {width:25px; text-align:left; }
}

/*********************************************************************************************/
@media only screen and (max-device-width:999px) and (orientation: landscape) and (min-device-aspect-ratio: 1/1){
.search-comp .search-bar .input-group-addon:before {width:9% !important;} 
.search-comp .search-bar .input-group-addon {padding:12px 5px 12px 15px !important;}    
.home_filter_tlc_price span { font-size:13px;}
.ct_profile_left .com_profile_box:first-child { border-top:none;}
}
@media only screen and (max-width: 991px) {
.popular-destination-item {width:50%;height:220px;}
/* .new_stylemenu .nav li a {padding:15px 7px 11px;} */
.autocomp.homesearch {padding-left:0px;}
.top_search_box .autocomp.search .rbt-input-main{max-width:133px !important;font-size:13px;}
.top_search_box .autocomp.search .rbt-aux { margin-right:-9px;}
.homesearch.autocomp .rbt-aux { margin-top:-32px !important; margin-right:0px;}
.autocomp.homesearch .rbt-input-main {max-width:150px !important; font-size:13px;}
.searchbox_half .form-control{font-size:13px;}
.form-control.budget_inputbox {font-size:13px;padding-left:0px;}
.location_dropdown .input-group-addon {padding:12px 10px 12px 10px !important;}
.select_style{margin:0 1px;font-size:13px;padding-left:5px !important; padding-right:5px !important;}
/*.input-search-top {width:181px;}*/
.find_filter {float:left;}
.select_style select { font-size:13px !important;}
.find_filter .width_103 {width:95px !important;}
.top_search_box .input-group-addon { width:35px;}
.find_filter .width_85 {width:63px;}
/* .new_stylemenu .nav li a { font-size:13px; } */
.ct_profile_left .ct_profile_rt:after {content:""; position:absolute; left:-7px; top:0;  width:12px; height:100%; background:#fff;}
.ct_profile_left .ct_profile_rt {min-height:166px;}
.profile_client_bt_small {width:49%; margin:0 0 0 1%; float:left;}
.profile_client_bt_small.mar_right {margin: 0 1% 10px 0;}
}

@media only screen and (max-width:830px) {
.autocomp.homesearch .rbt-input-main {max-width:130px !important;font-size:13px;}
/*.input-search-top{width:145px;}*/
.select_style {font-size:12px;}
.navbar-toggle {margin-bottom:3px;}
/* .toggle-off.btn-xs {padding-left:20px !important;} */
.banner-top-subtitle {font-size: 2.6vh !important;}
}
@media only screen and (max-width:800px) {
.banner-top-subtitle {font-size:4vh !important; top: 7% !important;}  
.banner-top-price {top:38% !important;} 
.banner-top-title {font-size:34px !important; top:24% !important;} 
}
@media only screen and (max-width:812px) {
/*.filter_view_pop {overflow-y:scroll; height:auto;}
.filter_view_pop.hide_filter{height:0px;}*/
.client_address_info .col_first { margin:27px 0 15px 0;}
.change_password_bt{ padding:0px;}
.ct_profile_left {margin:0 0 10px 0; text-align:center; }
.client_address_info .dropdown-menu { font-size:13px;}
.client_address_info .dropdown-menu li a {padding:3px 10px;}
.modify_popup{ width:300px; max-width:100% !important;}
.modify_popup .popover-content { width:100% !important; margin:0px;}
.modify_popup .autocomp .dropdown-menu {width:100%; font-size:13px; }
.modify_popup .autocomp .dropdown-menu li a { padding:3px 10px; }
.modify_popup .rbt-input > div > div > input {font-size:13px;}
.ct_profile_left .ct_profile_rt:after {content:""; position:absolute; left:-7px; top:0;  width:12px; height:100%; background:#fff;}
/*.profile_client_bt_full {position:absolute; bottom:30px; right:15px; width:48%; border-top:10px solid #fff; padding: 0 0 0 5px;margin: 0;}*/
.profile_client_bt_small { width:49%; padding:0; margin:0 0 0 1%; float:left;}
.profile_client_bt_small.mar_right {margin:0 1% 0 0;}
.profile_client_bt {width:100%; margin:0px;  padding:0;}
/*.profile_client_bt_full {position:absolute; bottom:30px; right:15px; width:48%; border-top:10px solid #fff; padding: 0 0 0 4px;margin: 0;}*/
.ct_profile_left .com_profile_box:first-child { border:none; padding: 7px 0 7px 0;}
.ct_profile_left .com_profile_box:first-child { border-top:none;}
.ct_profile_left .com_profile_box{ padding: 7px 0 7px 0 !important;}
.add-client-wrapper {width:100%;}
.newtap_icons .tabicon_text { display:none;}
.newtap_icons.tap_33 .nav li {width:33.33%;}
.newtap_icons.tap_25 .nav li {width:20%;}
.newtap_icons.tap_20 .nav li {width:20%;}
.newtap_icons.tap_50 .nav li {width:50%;}
.newtap_icons.tap_14 .nav li {width:14%;}
.newtap_icons .tabicon_box .fa { font-size:25px; }
.saved_searches_rt.newtap_icons .nav-tabs li a {padding:10px 14px !important; text-align:center;}
.addclient-btns { margin:10px 2px;}
/* .tooltip_box {max-width:200px; margin:0 0 0 30px; word-break:break-word;} */
.tooltip_box2 {max-width:240px; margin:0 0 0 10px; word-break:break-word; text-align:left; }
.header_sticky .slider_photo { width:75px; height:48px; margin:0 15px 0 0;}
.header_sticky .home_shortinfo span { font-size:12px !important; }
ul.property_nav li { font-size:12px; margin:0 6px;}
ul.property_nav li:first-child { margin:0 6px 0 0;}
.inner_pd_sc a {font-size:12px;}
.small_labels {font-style:italic;font-size:10px; display:block; font-Weight:normal; padding-left:0px;}
.btn-edit {margin-left:2px !important; font-size:11px !important;}
.btn-edit img { vertical-align:top;}
.suggested_data .center_text { text-align:center !important;}
.suggested_data .openhousefacility_list .star-rating {width:auto;}
.openhousefacility_list .star-rating {padding-right:16px; padding-bottom:0px;}
.property_address .address_left { padding:0 0 2px 0;}
.filter_content {padding:0px 15px 0px 15px !important;}
.filter_matches .comn_button {padding:5px !important;}
.filter_content label {font-size:13px !important; margin:0 5px 0 0; }
.filter_matches .comn_button {margin: 0 3px;}
.smartrange .list-row {max-height: 160px !important;}
.search-comp .search-bar .input-group-addon:before {width:8% !important;}
.home_filter_tlc_price span { font-size:13px;}
}
@media only screen and (max-device-width:770px) and (orientation: landscape) and (min-device-aspect-ratio: 1/1){
.animation_logo { position:inherit; font-size:15px; width:100%; border:none;border-bottom:2px solid rgba(255,255,255, .1);  background:rgba(74,74,74, .90);  float:left; padding:10px 3px; top:inherit; right:inherit;}
/* .new_stylemenu .nav li a {padding:15px 7px 11px;} */
/*.new_rightemenu.navbar-right {margin:0 125px 0 0;}*/
.search-comp_addition {position: inherit !important;}
}
@media only screen and (max-device-width:767px) and (orientation: landscape) and (min-device-aspect-ratio: 1/1){
.tour-details-tlc { width:100%; }    
body .search-comp_addition .tab-pane {background-color: rgba(74,74,74, .90); padding:15px 7px 15px 7px !important; float:left;width: 100%;}    
.location_nearby {
     width:100%; 
     /* top:42px */
     top: 55px;
     z-index: 5;
}   
.top_search_box .location_nearby {left:0px; top:31px;}
}
@media only screen and (max-device-width:768px) and (orientation: landscape) and (min-device-aspect-ratio: 1/1){

.search-comp .search-bar .form-group.location_dropdown {border-bottom: 5px solid rgba(74,74,74, .90); border-right:none; }
.form-group.search_bar_btn {
    /* border-top: 5px solid rgba(74,74,74, .90) !important; */
        margin:20px 0;
    }
}
@media only screen and (max-width: 736px) {
.ct_profile_left .ct_profile_rt:after { display:none; }
/* .new_searchlist .search-map-list, .new_searchlist .openhouse_box {width:100%;} */
.filter_inner_commute .commute_car .fa {font-size:15px;} 
.filter_inner_commute .location_name {max-width:100%;}    
/* .new_searchlist .search-map-list, .new_searchlist .openhouse_box {width:100%;}  */
.location_nearby { 
    width:100%; 
    /* top:42px */
    top: 55px;
    z-index: 5;
}   
.top_search_box .location_nearby {left:0px; top:31px;}
}
@media only screen and (max-width: 768px) {
    .new_stylemenu .nav li a {
        /* padding:15px 10px 11px;  */
        font-size:12px; 
    }
   /*.header-top-bar .header-list-right.header-list-right-new {min-width:110px;}*/
    /*.new_rightemenu.navbar-right {margin:0 130px 0 0;}*/
    /*.search-comp_addition { bottom:0px !important;}*/
    .search-comp .search-bar .input-group-addon:before{ display:none !important;}
    .search-comp .search-bar .input-group-addon { padding:10px 2px 10px 10px !important;}
    /*.search-comp {margin-top:0px !important; margin-bottom:10px !important; padding:0px !important; }*/
    .banner-top-title {top:45px !important; font-size:41px !important; line-height:normal;}
    .banner-top-subtitle  {top:7px !important; font-size:25px !important; }
    .banner-top-price .icon {font-size:20px; padding:20px 0 0 0; }
    .banner-top-price span.price-number {font-size:22px;}
    .banner-top-price { top:40% !important;}
    .banner-top-price .icon { padding:0px; }
    .search-bar .form-control { font-size:13px; }
    .search-bar .input-group .form-control{padding-left:3px;}
    .search-bar .input-group .form-control.budget_inputbox { z-index:1; }
    .search-comp_addition .tab-pane {padding:15px 15px 0px 15px !important;float:left;width:100%;}
    .search-comp .search-bar .form-group { border-right:1px solid #ddd; margin-bottom:0px; }
    .search-comp .search-bar .form-group.location_dropdown .rbt-input { overflow:hidden; }
    .search-comp .search-bar .col-sm-2:last-child .form-group{border:none;}
    .search-comp .search-bar .form-group.location_dropdown .dropdown-menu {height:155px; position:absolute; font-size:13px;  top:100%; left:-24px; width:360px;}
    .search-comp .search-bar .form-group.location_dropdown .dropdown-menu li a {padding:3px 10px;}
    .autocomp.homesearch .rbt-input-main {max-width:127px !important;}
    .new_searchlist .openhouse_img .carousel .slide {min-height:190px;}
    .top_search_box .input-group-addon {width:25px;}
    .top_search_box .input-group-addon i { font-size:12px; padding:0 3px 0 13px;}

    /********************Search page********************/
    /*.input-search-top{width:19%;}*/
    .find_filter {margin:0 0 0 7px !important;}
    .select_style {padding-left:0px !important;padding-right:0px !important; font-size:12px; min-height:30px;}
    .select_style .filter_more span { font-size:12px !important; }
    .search_semicompact .react-select-box-option { font-size:13px !important;}
    .includecommute-wrapper.add_location .fa {font-size:24px;}
    .includecommute-wrapper.add_location .location_icon_text {font-size:16px;}
    .react-select-box-container.search_semicompact .react-select-box-label { font-size:12px !important; }
    .autocomp.search {width:75% !important;}
    .find_filter .width_80 { width:60px;}
    .find_filter .width_85 { width:66px;}
    .find_filter .width_103 { width:87px;}
    /*.find_filter .filter_more span {padding:0 3px 0 0;}*/
    .select_style .form-control{font-size:12px;}
    /*.filter_content label{font-size:13px;}*/
    .find_filter .filter_tlc_pr span {padding:0 5px 0 0;}
    .top_search_box .input-group-addon .loader { margin:5px 0 0 0px;}
    /*.filter_view_pop .reset_btn_bt{width:100%; float:left; text-align:center;}
    .filter_view_pop .reset_btn_bt .comn_button { float:none; display:inline-block; vertical-align:top;}
    .filter_content .reset_btn_box { display:none;}*/
    .filter_content .reset_btn_box{ margin:0px; }
    .popover-content { font-size:13px; padding:9px 10px;}
    /* .leaflet-top { top:45px;} */

    /********************product page********************/
    .gallery_text { font-size:13px !important; }
    .slider-hotels {margin:7px 0 0 0;}
    .basic_information .form-group{ margin-bottom:12px !important; }
    .full_slider.is_openhouse .icon_detail_fav {top:20px !important;}
    .icon_detail_fav{top:20px !important;}
    .full_slider.is_openhouse .icon_detail_fav {top:43px !important;}
    .fontsize_text {font-size:13px !important; }
    ul.room_features_list li {font-size:13px; padding:2px 0;}
    .panel-heading { padding:10px 0px;}
    .toggle_header_right {padding:0 11px 0 0 !important;}
     /* .filter_sub_bg .filter_close {top:5px;width:22px;height:22px; line-height:normal;}
    .filter_sub_bg .filter_close .fa{font-size:22px; line-height:21px;}*/
    .icon_detail_fav .div_icon { padding:5px !important; width:30px !important; height:30px !important; text-align:center;}
    .icon_detail_fav .div_icon > div { float:none;}
    .icon_detail_fav i { font-size:20px !important;padding-left:0px !important; padding-top:0px !important; }
    .detail_slider .carousel .control-arrow {height:80px !important; margin-top:-40px !important;}
    .detail_slider .carousel + .carousel .control-arrow { height:auto !important; top:10%;  margin-top:0 !important; padding:15px 5px 15px 15px;}
    .inner_logo img { max-width:150px; }
    ol.progtrckr li span {padding:0 7px; font-size:14px;}
    .ct_profile_left .com_profile_box:first-child { border:none; padding: 7px 0 7px 0;}
    .ct_profile_left {margin:0 0 10px 0;}
    .ct_profile_left .com_profile_box{ padding:7px 0 !important;}
    /* .react-bs-table-tool-bar .react-bs-table-search-form {width:70%; float:right;} */
    .ct_profile_bg .ct_profile_lt {min-height:166px;}
    .ct_profile_left .ct_profile_rt {min-height:166px;}
    .ct_profile_left .ct_profile_rt:after { content:""; position:absolute; left:-7px; top:0;  width:12px; height:100%; background:#fff;}
    .ct_profile_left .com_profile_box:first-child { border-top:none;}
    /*.profile_client_bt_small { width:49%; padding:0; margin:0 0 0 1%; float:left;}*/
    .profile_client_bt_small.mar_right {margin:0 1% 0 0;}
    .profile_client_bt {width:100%; margin:0px;  padding:0;}
    /*.profile_client_bt_full {position:absolute; bottom:30px; right:15px; width:48%; border-top:10px solid #fff; padding: 0 0 0 5px;margin: 0;}*/
    .includecommute-wrapper {max-width:146px; right:5px !important; font-size:13px !important;}
    /* .propcount-wrapper {font-size:13px !important; } */
    .modify_popup .popover-content {width:200px;}
    .modify_popup .popover-content .btn { line-height:normal !important;}
    .panel-arrows .panel-heading .panel-title a.collapsed:after{top:9px; right:6px !important;}
    .panel-arrows .panel-heading .panel-title a:after{top:9px;right:6px !important;}
    .toggle_header_left span { margin:0 5px !important; }
    .toggle_header_left span i {margin-right:3px !important;}
    .homesearch.autocomp .rbt-aux {margin-right:7px;}
    .top_search_box .autocomp.search .rbt-input-main {max-width:90px !important; font-size:13px;}
    .add-client-wrapper {width:60%;}
    /* .com_panel_heading .panel-heading a {padding:10px 5px !important;} */
    .new_stylemenu .new_leftemenu {margin:0 0 0 5px;}
    .suggested_data .suggest_boxlink .openhousefacility_list { position:inherit; left:inherit; bottom:inherit; width:inherit;}
    .filters-stage .dropdown.compact .react-select-box-label{max-width: 152px;}
    .left-wrapper .leaflet-control-zoom {margin-top:10px;}
    .dropdown.search_semicompact .react-select-box {padding:6px 15px 6px 0px !important;}
    .filter_tlc_pr .smart-tlc-range span { font-size:12px !important; }
    .dropdown.search_semicompact .react-select-box:after {right:0;}
    .find_filter .filter_tlc_pr span {padding:6px 15px 6px 0px !important; z-index:9;}
  /*   .joyride-tooltip__main { font-size:13px !important;} */
    .content-wrapper .detail_mid_form .gross_income_input.last {padding:3px 0 1px 8px;}
    .checkbox_text {margin:5px 0 0 6px !important;}
    .home_filter_tlc_price span {font-size:13px; padding:0 0 0 5px; line-height:39px;}
    .new_stylemenu .container {width:100% !important;}
    .score-title > span { font-size:17px;}
    .score-circle { font-size:16px !important;}
    .modify_popup {width:320px;}
    .funds_edit .edit_input .react-select-box-label {font-size:12px;}
    .funds_edit .edit_input .dropdown .react-select-box-option {font-size:12px;padding: 10px 10px;}
    .filter_inner_commute .commute_car .fa {font-size:15px;}
    .form-fields-group { margin:12px 15px;}
}

/*********************************************************************************************/
@media only screen and (max-width:767px) {
.popular-destination-item {height: 190px}
.autocomp.homesearch .rbt-input-main {max-width:100% !important;}
.searchbox_half .form-control {z-index:1;}
.search-comp .search-bar .input-group-addon {padding:12px 5px 12px 20px;}
.full_slider.is_openhouse .icon_detail_fav { top:35px !important;}
.remove_padding > div > div {padding-bottom:0px !important;}
.header_sticky .slider_photo{display:none;}
.panel-arrows.lifestyle_costbox .toggle_header_left .remove_icon {display:none;}
.animation_logo {left:0; position:inherit; font-size:15px; width:100%; border:none;border-bottom:2px solid rgba(255,255,255, .1);  background:rgba(74,74,74, .90);  float:left; padding:10px 3px; top:inherit; right:inherit;}
.after-filters { position:inherit !important; right:inherit !important; top:inherit !important; float:none; }
.table_heading_lt .btn-add-client { margin:0px;}
.label_text { margin:10px 0 5px 0;}
.profile_photo_btn .btn { padding:8px 27px;}
/*.chat_header_name { display:table; min-height:46px;} */
/* .left-wrapper .leaflet-control-zoom {margin-top:15px;}  */
.navbar-toggle.collapsed span {transform:inherit !important;}
.navbar-toggle.collapsed .sr-only {display:block;}
.navbar-toggle.collapsed span:nth-child(3n) {display:block; }
.header-top-bar .header-list-right.header-list-right-new {min-width:inherit;background:inherit; z-index:10;}
.header-top-bar .header-list-right:before { display:none; }
.dropdown-menu ul { display: block !important; list-style-type: none; margin:0; padding-left: 20px;}
.includecommute-wrapper.add_location .fa {font-size:22px;}
.includecommute-wrapper.add_location .location_icon_text {font-size:14px; margin-left:10px}
.property_popup_wrapper, .property_popup { width:100%; }
.property_popup { height:auto !important; min-height: 150px !important; }
.filters-stage { z-index:9999; }
.sorting_bg .sorting_resultsnumber { display:none; }
.comn_button.reset_mobile { display:inline-block; }
.filter_matches .comn_button .mob_results { display:inline-block;}
.filter_matches .comn_button .des_results {display:none;}
.ct_profile_left .ct_profile_rt:after { display:none; }
}
/*********************************************************************************************/
@media only screen and (max-width: 600px) {.popular-destination-item {width: 100%;height:174px}  }
/*********************************************************************************************/
@media only screen and (max-width:767px){
/*.search-comp_addition { position:inherit !important;}*/
.search-comp_addition .tab-pane {background-color:rgba(74,74,74, .90); padding:10px 2px 10px 3px!important;}
.search-comp .search-border { margin:0 0px !important;}
.search-comp_addition .tab-pane {padding:10px 10px 10px 10px!important;}
.form-group.search_bar_btn { margin-bottom:0px;}
.search-comp_addition .tab-pane {padding: 10px 6px 10px 7px;}
.filter_matches { width:100%; color:#fff; position:fixed; left:0; text-align:center; bottom:0; background-color: rgba(74,74,74, 1); padding:7px; z-index:99;  }
.select_style:before { display:none;}
.select_style:after { display:none;}
.select_style { margin:0 1px !important;}
.select_style.more-item-filter {margin:0 !important;}
.tour-details-tlc { float:left; width:100%;}
.tour_button { display:none;}
/* .compare_icon { display:none !important;} */
.propdetail_sidebar .prot_cost_box label {max-width:inherit;}
.propdetail_sidebar .prot_cost_box .btn-edit .fa-pencil {margin-left: 4px;}
/* .chart_tooltip { width:370px !important; max-width:100% !important;} */
.chart_ratebox .rate_header .header_box1 {width: 12%;}
.chart_ratebox .rate_header .header_box2 {width:44%;}
.chart_ratebox .rate_header .header_box3 {width:44%;}
/* .personalize_text h2 { font-size:20px; line-height:normal;} */
.score-title > span { font-size:16px; }
.score-circle { font-size:16px !important;}
#root {height:100%;}
#site_content {height:100%;}
.search-comp .search-bar .form-group.location_dropdown {border-bottom:5px solid rgba(74,74,74, .90); border-right:none; }

/* .nav-login-btn { color: rgba(255,255,255,1); background: #555; margin-top: 3px; display: inline-block; } */

.form-group.search_bar_btn {
    /* border-top:5px solid rgba(74,74,74, .90) !important; */
    margin:10px 0;
}
.tour-property-beds { margin-bottom:0px;}
.tour-property-beds .form-group { margin-bottom:10px;}
.tour-property-beds .property_icons {margin-right:7px;}

/************commute filter css****************/
#popover-save-search.commute_filter { position:fixed;  top:0px !important; border-radius:inherit; box-shadow:inherit; left:0 !important; background:#fff;  width:100%; max-width:100% !important; height:calc(100%); margin:0 !important;}
#popover-save-search.commute_filter .form-group {width:100% !important; }
#popover-save-search.commute_filter .form-group .commute-time-box {width:90%; margin-top:10px; }
#popover-save-search.commute_filter .form-group .commute-mode-box {margin-top:10px; width:34px; }
#popover-save-search.commute_filter .form-group > div > div {width:100% !important;}
#popover-save-search.commute_filter .arrow:after { display:none; }
#popover-save-search.commute_filter .popover-content { font-size:14px; }
#popover-save-search.commute_filter .close_commute { width:100%; text-align:right; cursor:pointer; margin:5px 0 15px 0;  display:block;}
#popover-save-search.commute_filter .close_commute  .fa {color:#f99e30; font-size:17px; line-height:14px; text-align:center; width:25px; height:25px; background:#555; padding:5px; border-radius:100%;}
}
/*********************************************************************************************/
@media only screen and (max-width:767px){
    .header-top-bar .header-list-right:before {display:none}
    .header-top-bar .header-list-right.header-list-right-new{ min-width:inherit; background:inherit; z-index:10; }
    .header-top-bar .header-list-right .btn-wish-list { margin-right:2px; padding:0px; margin-top:-2px; }
    .subcompany_box {margin:-2px 5px 0 15px; vertical-align:top;  }
    /*.personalized .header-top-bar .header-list-right {right: 15px; top: 30px; left: inherit;}*/
    .new_stylemenu .new_leftemenu.nav li a:after{ display:none;}
    .new_stylemenu.navbar-inverse .navbar-toggle.collapsed {/*border:1px solid #f7941e !important;*/ padding:7px; height:30px }
    .new_stylemenu.navbar-inverse .navbar-toggle .icon-bar {background-color:#fff;}
    .new_stylemenu.navbar-inverse .navbar-toggle.collapsed .icon-bar {background-color:var(--theme-primary-color, #f7941e);}
    .new_stylemenu.navbar-inverse .navbar-toggle:hover, .new_stylemenu.navbar-inverse .navbar-toggle:focus {background-color:inherit; border:1px solid #f7941e; }
    .header-top-bar .header-list-right .btn-wish-list {line-height:normal; height:auto;}
    .new_stylemenu.navbar-inverse .navbar-collapse, .new_stylemenu.navbar-inverse .navbar-form { border-top:none;}
    .new_stylemenu.navbar-inverse .navbar-collapse.in {right:0px; position:relative; background:#5c5c5c;  float:right; height:100vh; width:280px;transition: all 100ms ease-out 0s; border-right:1px solid #ddd;}
    .mobile-menu-open {overflow:hidden;}

    /*.new_rightemenu.navbar-right { margin:0px;}*/
    .new_stylemenu.navbar-inverse .navbar-nav > li > a:hover, .new_stylemenu.navbar-inverse .navbar-nav > li > a:focus { border:none; }
    .search-bar-fixed { z-index:9999 !important;}
    /* .right-wrapper { width:100% !important; position:inherit !important; } */
    .mobile_menu_open {overflow:hidden;}
    /* #popover-select-client.popover { position:fixed !important; padding-top:40px;} */
    .search-comp .search-bar select.form-control { position:relative; z-index:1;}
    .search-bar .input-group .form-control.budget_inputbox { z-index:1; }
    .quick-bar-btn.btn-default { width:25px; height:25px; color:#f7941e; margin:0px 0 0 10px !important; margin:0; font-size: 22px !important;}
    .t_icon:after { 
        background:url(../img/svg/chat_icon_m.svg) no-repeat right; 
        background-size:cover; width:29px; left:0px; top:0px; height:29px;}
    .quick-bar-btn.btn-default:hover { background-color:inherit !important; color:#f7941e !important; }
    .quick-bar-btn .notification-badge {top:-6px !important;right: -7px !important;}
    /*.input-search-top { width:100%; margin:0 0 5px 0;}*/
    .mb_show_map{display:inline-block; float:none !important; vertical-align:top;}
    /****mobile filter new changes***/
    .mb_show_map.mobile_toggle_icon .toggle-handle { display:none;}
    .mb_show_map.mobile_toggle_icon .toggle-off.btn { display:inline-block; vertical-align:middle; line-height:30px;}
    .mb_show_map.mobile_toggle_icon .toggle-on.btn { display:inline-block; vertical-align:middle; line-height:30px; }
    .mb_show_map.mobile_toggle_icon .btn-warning {border:1px solid #f0ad4e;}
    .mb_show_map.mobile_toggle_icon .toggle.btn { border:inherit;}
    .mb_show_map.mobile_toggle_icon .btn { transition:inherit !important; }
    .mb_show_map.mobile_toggle_icon .toggle-group { transition:inherit !important; }
    /********************/
    /* .left-wrapper{ border:none !important; z-index:1000 !important; }
    .right-wrapper {top:40px !important;} */
    .autocomp.search {width:86% !important;}
    .autocomp.search .rbt-input { line-height:normal;}
    .autocomp.search .rbt-input input{ max-width:100%; }
    .map-list-toggle {display:none;}
    .select_style { font-size:14px;}
    .sorting_resultsnumber { margin:0; width:100%;}
    .sorting_option { width:100%; }
    .filter_results_number {width:43%; padding:5px 5px 0 5px;}
   /*  .pagination { width:100%; margin:0px !important;} */
    .sorting_optionbox { width:50%; }
    .sorting_optionbox .react-select-box-container.photo_icon { width:100% !important;}
    .sorting_optionbox .react-select-box-container.low_high_icon { width:100% !important;}
    /* .propcount-wrapper { top:15px !important; font-size:13px !important; z-index:998 !important;} */
    /* .leaflet-top, .leaflet-bottom { z-index:998;} */
    .monthly_costbox { color:#fff;}
    .monthly_costbox { color:#555; font-size:13px;}
    /*.dropdown .react-select-box-label, .dropdown .react-select-box-option {font-size:13px !important;}
    .filter_content .price_input input {font-size:13px;}
    .filter_tlc_pr { width:100%; min-height:35px; line-height:20px; float:left; margin:0; background:#fff; padding: 7px; position:relative; }
    .filter_tlc_pr .fa { float:right; padding:2px 3px 0 0;}
    .filter_tlc_pr .fa:after{content:' '; z-index: 1;position: absolute; height:20px; top:7px; right:28px;border-left:1px solid #CBD2D7;}*/
    .checkbox_text { font-size:13px;}
    .find_filter .filter_more {transform:inherit; min-width: 80px;}
    .top_search_box .input-group:before { display:none; }
    .top_search_box .input-group:after { display:none; }
    .top_search_box .input-group-addon:after { display:none; }
    /*.input-group-addon:last-child { padding-left:0px !important;}*/
    /*.filter_content { height:490px; overflow-y:scroll;}*/
    /*.filter_view_pop {overflow-y:scroll; height:auto;}
    .filter_view_pop.hide_filter{height:0px;}*/
    .show_filter .filter_content{overflow-y:scroll; height:auto;}
    .filter_content { margin-top:15px; padding:0px 15px 15px 15px !important;}
    .radiogroup_box .btn-group .btn-default { font-size:12px; }
    .checkbox_label { display:none;}
    /*.filter_content .form-group { margin-bottom:10px;}*/
    .hotels-list-page { display:inline-block; width:100%;}
    .sticky_pagination.sticky { position:fixed !important; top:126px !important; }
    /* .pagination_results { bottom:0; } */
    /* .pagination_results { border-bottom:inherit; padding:7px 5px 5px 5px; } */
    .top_search_box .input-group-addon i {padding:0 3px 0 3px;}
    .top_search_box .input-group-addon {width:40px;}
    .client_mg_popup.modal{top:130px;}
    .sticky_sorting {position:fixed !important; 
        /* top:91px;  */
        top:180px; 
        width:100%;}
    .sorting_optionbox .photo_icon .react-select-box-options { width:100%;}
    .sorting_optionbox .low_high_icon .react-select-box-options { width:100%;}
    #highcharts-0jy4eum-12 { width:220px !important; height:220px !important;  }
    .toggle_header_left {
        /* width:58% !important;  */
        line-height:18px;
    }
    .roundbadge { width:30px !important; height:30px !important; font-size:13px !important; padding-top:0px !important; line-height:30px !important;}
    .agent_top_listing_m { width:100%; float:left; }
    /*.filter_sub_bg { margin:75px 0 0 0;}*/
    .main-heading .heading-content {font-size:14px;}
    .footer .footer-content {font-size:13px; word-break: break-all;}
    .footer_first {font-size:13px;}
    .footer .info-company li {padding-left:20px !important;}
    ol.progtrckr { padding:0px;}
    .profile_client_bt {padding:0px;}
    .saved_searches_rt .nav-tabs li a {padding:10px 5px !important;}
    .btn-saved-searches { width:100%; float:left; position:inherit !important; z-index:inherit !important; }
    /* .react-bs-table-tool-bar .col-xs-6 { width:100% !important;}
    .react-bs-table-tool-bar .react-bs-table-search-form {width:100% !important;} */
    .nb-text { text-align:center !important; padding:10px !important;}
    .btn-saved-searches .btn-add-client {margin-right:0px !important; float:right; margin-bottom:0px !important; }
    /* .react-bs-table-sizePerPage-dropdown .btn { padding:10px !important;} */
    .saved-search-title {padding:0px; margin:0 !important;}
    .btn-saved-searches .btn-add-client .btn-default { font-size:13px !important; padding:5px !important;  }
    /* .react-bootstrap-table-page-btns-ul.pagination { text-align:right;} */
    .react-bootstrap-table-page-btns-ul.pagination li { display:inline-block;}
    .new_searchlist .property_price {font-size:12px;}
    .property_price .price_left {padding:0 3px 0 0;}
    /*.saved_searches_rt .tab-pane { padding:10px !important;}*/
    /*.saved_searches_rt #tabSections-pane-2.tab-pane { padding:0px !important;}*/
    .profile_save_bt { padding:10px 7px; background-color:#ddd; margin:0 15px; }
    .ct_profile_left .ct_profile_info { min-height:inherit; border-left:none;}
    .ct_profile_left .com_profile_box:first-child {border-top:1px solid #737373;}
    /*.ct_profile_left .ct_profile_rt:after { display:none; }*/
   /*.gallery_short_list .gallery_box img { width:100% !important;} */
   
    .mobile_chat{padding:0px !important;}
    .td-col-0    {padding:0px !important; width:0px !important; }
    .td-col-7   {max-width:inherit !important; width:7% !important; position:inherit !important;   display:inline-block !important; overflow:inherit !important;}
    .td-col-10  {max-width:inherit !important; width:10% !important; position:inherit !important;  display:inline-block !important; overflow:inherit !important;}
    .td-col-20  {max-width:inherit !important; width:20% !important; position:inherit !important;  display:inline-block !important; overflow:inherit !important;}
    .td-col-16  {max-width:inherit !important; width:16% !important; position:inherit !important;  display:inline-block !important; overflow:inherit !important;}
    .td-col-24  {max-width:inherit !important; width:24% !important; position:inherit !important;  display:inline-block !important; overflow:inherit !important;}
    .td-col-25  {max-width:inherit !important; width:25% !important; position:inherit !important;  display:inline-block !important; overflow:inherit !important;}
    .td-col-26  {max-width:inherit !important; width:26% !important; position:inherit !important;  display:inline-block !important; overflow:inherit !important;}
    .td-col-28  {max-width:inherit !important; width:28% !important; position:inherit !important;  display:inline-block !important; overflow:inherit !important;}
    .td-col-30  {max-width:inherit !important; width:30% !important; position:inherit !important;  display:inline-block !important; overflow:inherit !important;}
    .td-col-33  {max-width:inherit !important; width:33% !important; position:inherit !important;  display:inline-block !important; overflow:inherit !important;}
    .td-col-40  {max-width:inherit !important; width:40% !important; position:inherit !important;  display:inline-block !important; overflow:inherit !important;}
    .td-col-50  {max-width:inherit !important; width:50% !important; position:inherit !important;  display:inline-block !important; overflow:inherit !important;}
    .td-col-60  {max-width:inherit !important; width:60% !important; position:inherit !important;  display:inline-block !important; overflow:inherit !important;}
    .td-col-70  {max-width:inherit !important; width:70% !important; position:inherit !important;  display:inline-block !important; overflow:inherit !important;}
    .td-col-80  {max-width:inherit !important; width:80% !important; position:inherit !important;  display:inline-block !important; overflow:inherit !important;}
    .td-col-90  {max-width:inherit !important; width:90% !important; position:inherit !important;  display:inline-block !important; overflow:inherit !important;}
    .td-col-100 {max-width:inherit !important; width:100% !important; position:inherit !important; display:inline-block !important; overflow:inherit !important;}
    /* .save_searchtable .react-bs-table-bordered table{ width:100%; float:left; table-layout:inherit !important;}
    .save_searchtable .react-bs-table-bordered table tbody { width:100%; float:left;}
    .save_searchtable .table tr {width:100%; padding:3px !important; float:left;}
    .save_searchtable .table td {min-width:inherit !important; padding:5px 6px !important; border:none; font-size:14px; word-break:break-all !important; line-height:normal !important; white-space:inherit;}
    .save_searchtable .table td a{display:inline-block; color:#de8801;}
    .save_searchtable .table td a:hover{display:inline-block; color:#000; text-decoration:none;}
    .save_searchtable .table td.td-col-rt{text-align:right !important;}
    .save_searchtable .breadcrumbheader {padding:0px !important; position:inherit !important;}
    .save_searchtable .breadcrumbheader .tags{margin-left:0px;} */
    /* .save_searchtable.check_box_table .react-bs-table-bordered .react-bs-container-body .table-bordered tbody tr td:first-child{ width:auto !important; text-align:left !important; position:inherit !important; padding:5px 6px !important; display:inline-block !important; float:left !important; overflow:inherit !important;} */
    /* .save_searchtable .table tbody tr:nth-of-type(odd){background-color:#f9f5f5;} */
    /***************table title*******************/
    /* .save_searchtable .table td .tagdisabled {margin-bottom:4px !important; margin-top:4px;}
    .save_searchtable .react-bs-table-bordered .react-bs-container-header .table-bordered thead tr { padding:0px !important;}
    .save_searchtable.check_box_table .react-bs-table-bordered .react-bs-container-header .table-bordered thead tr th:first-child{ width:10% !important; text-align:center !important; position:inherit !important; padding:10px 10px !important; display:block !important; float:left !important; overflow:inherit !important;} */
    .td-col-0 {padding:0px !important; border:none !important;}
    /* .save_searchtable .react-bs-table-bordered .react-bs-container-header .table-bordered thead tr th {min-width:inherit !important; min-height:40px; font-weight:normal !important; padding:10px 5px; text-transform:none;}
    .save_searchtable .react-bs-table-bordered .react-bs-container-header .table-bordered thead tr th .order {margin:0px 0px 0px 5px !important; }
    .save_searchtable .react-bs-table-bordered .react-bs-container-header .table-bordered thead tr th .fa {margin:0px 0px 0px 5px !important; } */
    .includecommute-wrapper {top:15px !important; right:5px !important;}
    /* .pagination_results .pagination{ width:auto !important; padding:0px 5px 0 0 !important; float:right;}
    .pagination_results .pagination li a, .pagination_results .pagination li .page-link {padding:4px 3px; font-size:13px;  display:inline-block !important; vertical-align:top;} */
    /* .sorting_bg.sort-by {
        margin-bottom: 20px;
        margin-top: -20px;
    } */
    .modify_popup .popover-content { width:176px;}
    .sticky.header_sticky .inner_dl_tab { display:none;}
    .sticky.sidebar_sticky_pd { padding:0 !important; position:inherit !important; top:inherit !important; left:inherit !important; width:100% !important; }
    .sticky.header_sticky {position:inherit !important; top:inherit !important; left:inherit !important; width:100% !important; }
    .save_searchtable .react-bs-container-body .table-bordered > tbody >tr > td:last-child { text-align:right !important;}
    .suggested_data .save_searchtable .react-bs-container-body .table-bordered > tbody >tr > td:last-child { text-align:center !important;}
    .timeline_comm .chat-action-btns {right:-3px;}
    .timeline_comm .notes_btnbox .btn_submit .btn {max-width:30px !important;}
    .timeline_comm .notes_btnbox .btn_submit {width:31px !important;}
    .timeline_comm .notes_btnbox .btn_submit.btn-width80 {width:61px !important; }
    /*.property_address { padding:0 0 0 50px;}
    .feed-right-column { width:100% !important; overflow:inherit !important;}*/
    .autocomp.homesearch .rbt-input-main { max-width:100% !important;}
    .client_address_info .col_first { margin:0px 0 15px 0;}
    .add-client-wrapper {width:100%;}
    .addclient-btns .btn {width:160px; font-size:11px; padding:8px 4px;}
    .addclient-btns .btn .fa { margin-right:3px !important;}
    .suggested_data .suggest_boxlink {position:relative; padding-bottom:15px; }
    .suggested_data .suggest_boxlink .openhousefacility_list{position:absolute; left:0; bottom:0; padding:0px;  width:100%;}
    .status_suggest .label-primary {white-space:nowrap;max-width:70px;overflow: hidden;text-overflow:ellipsis;}
    .suggested_agent_dr {float:left; margin:0 5px 0 0;}
    .save_searchtable .saved-search-title.right_title { float:right; text-align:right;}
    .suggested_agent_dr .dropdown .btn {padding:6px 20px;}
    .main-heading h2 .show_more .btn {padding: 7px 3px;line-height:normal;font-size:11px;}
    .garages_info {display:none;}
    .mls_number_info {display:none;} 
    /* .leaflet_icon_draw { display:none !important;} */
   

/************New fix popover popup*******************************/
    .bottom-popover.popover { background-color:inherit; border:none; box-shadow:inherit;}
    .bottom-popover.popover .arrow { display:none !important;} 
    .bottom-popover .popover-content {position:fixed;width:100% !important; background-color:#eee;bottom:0;left:0; border-top:2px solid #ccc; }  
    .bottom-popover .popover-content .dropdown-menu {bottom:30px; top:inherit;}
}
/*********************************************************************************************/

/*********************************************************************************************/
@media only screen and (max-device-width:812px) and (orientation: landscape) and (min-device-aspect-ratio: 1/1){
/*.ct_profile_bg .ct_profile_lt { width:50%; min-height: 166px;}*/
.modify_popup{ width:320px;  max-width:100% !important;}
.add-client-wrapper {width:65%;}
.addclient-btns .btn {width:184px; font-size:12px;}
.addclient-btns .btn .fa { margin-right:5px !important;}
.saved_searches_rt.newtap_icons .nav-tabs li a{padding:10px 38px !important; text-align:center;}
.show_more{margin-top:5px !important;}
/*.left-wrapper.list-full .propcount-wrapper { display:none;}*/
/* .main-wrapper { top:83px !important;} */
/* .left-wrapper.list-full .map-list-toggle { z-index:9999;} */
.suggested_data .suggest_boxlink .openhousefacility_list { position:inherit; left:inherit; bottom:inherit; width:inherit;}
.suggested_data .suggest_boxlink { padding-bottom:0px;}
.tbl-imgbox {margin: 0 11px 0px 0;}
.banner-top-subtitle {font-size:8vh !important;top:0% !important;}
.label_text { margin:0;}
.profile_photo_btn .btn { padding:8px 27px;}
.filter_btns { text-align:center;}
.addclient_info label {padding: 0 10px 0 0px;}
.detail_gallery .carousel .slide:before {padding-bottom:31%;}
.filter_content {padding:0px 15px 70px 15px !important; overflow-y:auto; margin:15px 0 0 0;}
.filter_content label { font-size:14px !important; }
.filters-stage .dropdown .react-select-box-options {height:150px; overflow-y:auto;}
.garages_info {display:none;}
.mls_number_info {display:none;}
/* .leaflet_icon_draw { display:none !important;} */
/* .left-wrapper .leaflet-control-zoom {margin-top:15px !important;} */
.smartrange .list-row {max-height:122px !important;}
.gross_income_input.last {padding:3px 0 2px 8px;}

/************New fix popover popup*******************************/
.bottom-popover.popover { background-color:none; border:none;}
.bottom-popover.popover .arrow { display:none !important;} 
.bottom-popover .popover-content {position:fixed; width:100% !important; background-color:#eee;bottom:0;left:0; border-top:2px solid #ccc; }  
.funds_edit .edit_input .react-select-box-label {font-size:12px;}
.funds_edit .edit_input .dropdown .react-select-box-option {font-size:12px;padding: 10px 10px;}
.filter_btns .filter_box {margin:0 5px;}

/* .leaflet-popup-content { width:auto !important; margin:0px !important;}
#map .leaflet-popup-content-wrapper {padding:0px; border:inherit; background:inherit;} */
#map .property_popup { height:100% !important;background: #c1bfbf; }
#map .popup_property_photo a { height:225px !important; display:inline-block; }
#map .popup_property_photo a:after {padding-bottom: 26% !important;}
#map .popup_property_photo img{ width:auto !important; height:235px; }
#map .popup_property_photo .carousel .slide { background:#c1bfbf;}
/* body #map .leaflet-popup-close-button {top:0px !important; width: 30px !important; height:30px !important; line-height:25px !important;font-size:22px;} */
.panel-arrows .panel-heading .panel-title h4 {font-size:14px;}

}

@media only screen and (max-device-width:736px) and (orientation: landscape) and (min-device-aspect-ratio: 1/1){
    .selectclient-popup {max-height:100px !important;}
    /*.search-comp.search-comp_addition { padding:0px;}*/
    .search-comp_addition .tab-pane {padding:15px 7px 15px 7px !important;float:left; width:100%;}
    .banner-top-price { top:31% !important;}
    /* .search-comp{ margin-top:15px !important;} */
    .banner-top-title { font-size:36px !important;}
    .banner-top-price span.price-number {font-size:19px;}
    .banner-top-price .icon { padding:12px 0 0 0;}
    .home_slider .carousel.carousel-slider .control-arrow { display:none;}
    #popover-select-client .popover-content .form-group {max-height:140px;overflow: scroll;}
    .popover-content .form-group.dailyalert_poup {max-height:inherit;overflow:inherit;}
    .ct_profile_left .ct_profile_rt:after { display:none; }
    .col_two { width:50%; }
    .col_two.col_full { width:100%; }
    .client_address_info .col_two.col_full { width:50%; }
    .client_address_info .margin-bottom-15 { margin-bottom:10px;}
    .saved_searches_rt .nav-tabs li a { padding:10px 10px !important; }
    .new_password { width:50%; float:left; padding:0 13px; margin:0px; }
    .table_heading_lt.client_saved_searches .btn-add-client {margin-right:20px !important;}
    .label_text { margin:10px 0 5px 0;}
    .filters-stage{ left:195px !important; right:unset !important;}
    .chat_header_name { min-height:inherit; }
    .client_address_info .mini_height { min-height:64px;}
    .client_address_info .textbox-large { min-height:35px;}
   .includecommute-wrapper {max-width:380px;}
   .filter_content {padding:0px 15px 70px 15px !important; margin-top: 10px;}
   .gross_income_input.last {padding:4px 0 2px 8px;}
   .content-wrapper .detail_mid_form .gross_income_input.last {padding:3px 0 1px 8px;}
   .subscribe_info .control-label { padding-right:0px; }
   .breadcrumbheader {padding-top:100px !important;}
   .show_filter .filter_content { padding-bottom:55px !important;}

}

@media only screen and (max-device-width:823px) and (orientation: landscape) and (min-device-aspect-ratio: 1/1){
.main-wrapper { top:74px !important;} 
.filter_content {padding:0px 15px 0px 15px !important; overflow-y:auto; margin:15px 0 0 0;}
.search-comp .search-bar .input-group-addon:before {width:8% !important;}
.home_filter_tlc_price span { font-size:13px; }
.tour-property-beds { margin-bottom:0px;}
.tour-property-beds .form-group { margin-bottom:10px;}
.tour-property-beds .property_icons {margin-right:7px;}
.new_stylemenu .new_leftemenu {margin: 0 0 0 10px;}
.includecommute-wrapper.add_location .fa {font-size:22px;}
.includecommute-wrapper.add_location .location_icon_text {font-size:15px;}
/*.ct_profile_bg .ct_profile_rt { width:50%; min-height: 166px;}
.ct_profile_left .com_profile_box:first-child { border-top:none;}*/
.ct_profile_left .com_profile_box {padding: 7px 0 7px 0;}
.ct_profile_left {margin:0 0 10px 0;}
/************commute filter css****************/
#popover-save-search.commute_filter { top:0px !important; left:0 !important; background:#fff;  width:100%; max-width:100% !important; height:calc(100% - 45px); margin:45px 0 0 0 !important;}
#popover-save-search.commute_filter .form-group {width:100% !important; overflow:inherit;  }
#popover-save-search.commute_filter .form-group .commute-time-box {width:94.50%; margin-top:10px; }
#popover-save-search.commute_filter .form-group .commute-mode-box {margin-top:10px; width:34px; }
#popover-save-search.commute_filter .form-group > div > div {width:100% !important;}
#popover-save-search.commute_filter .arrow:after { display:none; }
#popover-save-search.commute_filter .popover-content { font-size:14px; }
#popover-save-search.commute_filter .close_commute { width:100%; text-align:right; cursor:pointer;  display:block; }
.filters-stage {min-width: 180px;}
.after-filters {margin-left:180px;}
.filters-stage {left:188px;}
.after-filters {margin-left:0px; position:absolute; right:-60px;top: 1px;}
.sticky.sidebar_sticky_pd {position:inherit !important; left:inherit !important; top:inherit !important;}
/*.small_dropdown { width:auto !important;}*/
}

/*********************************************************************************************/
@media only screen and (max-device-width:736px) and (orientation: landscape) and (min-device-aspect-ratio: 1/1){
    .main-wrapper {top:127px !important;}   
}
@media only screen and (max-device-width:667px) and (orientation: landscape) and (min-device-aspect-ratio: 1/1){
    .selectclient-popup {max-height:80px !important;}
    .search-comp_addition .tab-pane {padding:10px 6px 10px 7px !important;}
    .banner-top-price {top:56% !important;}
    .banner-top-title {top:22% !important;}
    /* .new_searchlist .search-map-list, .new_searchlist .openhouse_box {width:50%;} */
    .popover.bottom { margin-top:0px; }
    #popover-select-client .popover-content .form-group {max-height:140px;overflow: scroll;}
    .popover-content .form-group.dailyalert_poup {max-height:inherit;overflow:inherit;}
    .ct_profile_left { position:relative; }
    /*.profile_client_bt_full {position:absolute; bottom:10px;right:0px;width:50%;border-top:10px solid #fff;padding:0 0 0 5px;margin:0;}*/
    .autocomp.homesearch .rbt-input-main {max-width:100% !important;}
    .add-client-wrapper {width:72%;}
    .gross_income_input.last {padding:3px 0 2px 8px;}
    .content-wrapper .detail_mid_form .gross_income_input.last {padding:2px 0 1px 8px;}
    #popover-save-search.commute_filter .form-group .commute-time-box {width:93.50%;}
    
}
@media only screen and (max-device-width:568px) and (orientation: landscape) and (min-device-aspect-ratio: 1/1){
    .selectclient-popup {max-height:66px !important;}
    /* .new_stylemenu .nav li a {padding:11px 10px;} */
    .search-comp_addition .tab-pane { padding:10px!important;}
    /* .new_searchlist .search-map-list, .new_searchlist .openhouse_box {width:100%;} */
    #popover-select-client .popover-content .form-group {max-height:84px;overflow: scroll;}
    .popover-content .form-group.dailyalert_poup {max-height:inherit;overflow:inherit;}
    .detail_gallery .carousel .slide:before {padding-bottom:27%;}
    .gross_income_input.first {width:26%;}
    .gross_income_input.last {width:74%;}
    .gross_income_input.last label { font-size:11px; padding: 0;}
    .gross_income_input.first .textbox-large {padding:0 5px;}
    #popover-save-search .popover-content {font-size:12px;}
    #popover-save-search.commute_filter .form-group .commute-time-box { width:92%; }
    /* .leaflet_icon_draw_clear,.leaflet_icon_draw { width:40px  !important; height:40px  !important;} */
    .leaflet-touch .leaflet-control-layers-toggle {width:37px; height:37px;}
    .leaflet-touch .leaflet-bar a {height:28px;line-height:28px;}

}
/*********************************************************************************************/
@media only screen and (max-width:667px) {}
/*********************************************************************************************/
@media only screen and (max-width:375px) {
.search-comp_addition .tab-pane {padding:10px 3px 10px 2px!important;}
.saved_searches_rt .nav-tabs {margin:0px;}
.autocomp.homesearch .rbt-input-main { max-width:300px !important;}
.padding_left_30 {padding-left:15px; }
.padding_left_right_20 { padding-left:10px; padding-right:10px;}
/* .chart_tooltip {left:0 !important;} */   

}
/*********************************************************************************************/
@media only screen and (max-width: 480px) {
    body { font-size:14px;}
    .section-gallery-home .gallery-item { height: auto  }
    .section-gallery-home .gallery-item img {height: auto; width: 100%  }
    .banner-top-title { top:30% !important;  font-size:36px !important;}
    .banner-top-price { top:57% !important;}
    .search-comp_addition .tab-pane {padding:10px 10px 10px 10px!important;}
    .common_content h2 {font-size:19px;}
    .section, section { padding-top:10px;}
    .bookmark-title { padding:6px; font-size:13px;}
    .checkbox_label { display:none;}
    .autocomp.search .dropdown-menu { width:300px; left:0 !important; }
    .addclient_info .dropdown-menu {width:335px;}
    .addclient_info .dropdown-menu li a{padding:3px 10px;}
    .saved_searches_rt .nav-tabs li a {padding:10px 2px !important; font-size:11px;}
    .saved_searches_rt.myagent_tab .nav-tabs li a {font-size:11.5px;}
    .btn-saved-searches .btn-add-client .btn-default {font-size:12px !important; text-transform: none;}
    .btn-saved-searches .btn-add-client { margin-right:5px !important; }
    .profile_client_bt_small { width:100% !important; margin:0px 0 10px 0; padding:0px; border:none;}
    .profile_client_bt_small.mar_right { width:100%; margin:0px 0 5px 0; padding:0px; }
    .ct_profile_left .ct_profile_rt { min-height:inherit;}
    .feed-left-column {width:40px !important;}
    .feed-right-column { font-size:14px; line-height:normal;}
    /* .save_searchtable .react-bs-table-bordered .react-bs-container-header .table-bordered thead tr th { font-size:12px;}
    .save_searchtable .table td { font-size:13px;}
    .react-bs-table-pagination .row  div:nth-child(1){width:25% !important;}
    .react-bs-table-pagination .row  div:nth-child(2){width:60% !important; float:right; float:right;}
    .react-bs-table-pagination .row  div:nth-child(1) .btn { padding:5px 10px !important;} */
    .view_more_comment {margin:-10px 0 15px 0;}
    .last_comment { margin:-10px 0 15px 0; }
    .comments_more { margin:5px 0 0 0; }
    .comments_box { font-size:12px; }
   .view_more_comment {font-size:12px; }
   .last_comment {font-size:12px; }
   .profile_save_bt .btn {padding:8px 10px !important;}
   .filter_matches .comn_button {padding:5px !important; font-size:12px;}
   .smart-price-range {padding:7px 3px 6px 3px !important;}
   .smart-tlc-range.mob {width: 91% !important;}
   .includecommute-wrapper.add_location .location_icon_text {margin-left:8px;}
   .includecommute-wrapper.add_location .fa {font-size:20px;}
   #popover-save-search.commute_filter .form-group .commute-time-box {width:88%;}
   
}
@media only screen and (max-width: 412px) {
.ct_profile_bg .ct_profile_lt { width:100%; min-height: 166px; float:left;}
.ct_profile_bg .ct_profile_rt { width:100%;}
.ct_profile_left .com_profile_box:first-child {border-top: 1px solid #737373;}
.ct_profile_left .ct_profile_bg {margin: 0 0 10px 0;}
.profile_client_bt_full {bottom:inherit;}
.ct_profile_left .ct_profile_rt:after {display:none;}

}
@media only screen and (max-width:360px){
.search_result .result_boxs .profile_photobox {width:50px;height:50px;}   
}
/*********************************************************************************************/
@media only screen and (max-width:320px){
.new_stylemenu.navbar-inverse .navbar-collapse {position:absolute;width:260px;top:100%; right:-260px;}
.new_stylemenu.navbar-inverse .navbar-collapse.in {width:260px;}
.search-bar .searchbox_half { width:50%; }
.search-bar .searchbox_full { width:100%;  border-top:5px solid rgba(74,74,74, .90); }
.banner-top-title {top:35% !important; font-size:32px !important;}
.banner-top-price {top:54% !important; line-height:normal;}
.banner-top-price span.price-number {font-size:19px;}
.banner-top-price .icon {padding: 5px 0 0 0;font-size: 15px;}
.autocomp.homesearch .rbt-input-main {max-width:225px!important;}
.banner-top-subtitle { top:0px !important;}
.banner-top-title {top:27% !important;font-size:29px !important;}
.addclient_info .dropdown-menu {width:280px;}
.new_searchlist .block-hotel .hotel-info {padding:0px 5px 5px 5px;}
.new_searchlist .price_right_tlc { font-size:12px !important;}
.new_searchlist .property_price {font-size:12px !important;}
.property_price .unit {font-size:11px;}
.new_searchlist .block-hotel .hotel-info{padding: 0px 5px 5px 5px;}
.prop_status {max-width:52px;}
.new_searchlist .star-rating {font-size:13px;}
.leaflet-popup-content .price_right_tlc { padding:0 10px 0 0;}
/* body .leaflet-popup-close-button {right:0 !important;} */
.autocomp.homesearch .rbt-input-main {max-width:251px!important;}
.search-comp .search-bar .form-group.location_dropdown .dropdown-menu{width:280px;}
.addclient-btns .btn {font-size:10px; width:140px; padding:8px 2px;}
.addclient-btns .btn .fa {margin-right:5px !important;}
.addclient-btns .btn .fa {margin-right:2px !important;}
/* .highcharts-container { height:285px !important;} */
.main-heading h2 .show_more .btn {  padding:3px 1px; font-size:9px;}
.saved-search-title {word-wrap:break-word; font-size:13px !important;}
.save_searchtable .saved-search-title.right_title { width:100%; float:left; text-align:left;}
.suggested_agent_dr .dropdown .dropdown-toggle.btn {padding:6px 5px;}
.property_address .property_icon .property_icon_box {margin: 0 0 0 6px;}
.property_address .property_icon i {font-size:18px;}
.main-heading h2 .show_more .btn span {padding:0 1px;}
.includecommute-wrapper {max-width:125px;}
.tru-commute img {height:auto;}
.smart-price-range {padding:7px 3px 6px 3px !important;}
.smart-tlc-range.mob {width: 91% !important;}
.gross_income_input.first {width: 100%;}
.gross_income_input.last {width: 100%;}
/* .chart_tooltip { width:300px !important; max-width:300px !important;} */
.chart_ratebox .rate_header.header_top {font-size:11px;}
.chart_ratebox .rate_header {font-size:11px;}
.chart_ratebox .rate_header .header_box1 { width:13%; float:left; padding:3px; border-right:1px solid #ddd; border-bottom:1px solid #ddd;}
.chart_ratebox .rate_header .header_box2 { width:43%; float:left; padding:3px; border-right:1px solid #ddd; border-bottom:1px solid #ddd;}
.chart_ratebox .rate_header .header_box3 { width:44%; float:left; padding:3px; border-bottom:1px solid #ddd;}
.subscribe_info .control-label {padding-left:0; padding-right:0;}
#popover-save-search.commute_filter .form-group .commute-time-box {width:86%;}
.filter_content {padding:0px 15px 35px 15px !important;}
}


/************************ End****************************************/
.popular-destination-item:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .2);
    transition: all .25s ease
}
.popular-destination-item .item-img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0
}
.popular-destination-item .item-img img {
    height: auto;
    width: 100%
}

.popular-destination-item:hover:after {
    background-color: rgba(0, 0, 0, .5)
}
.popular-destination-item:hover .item-content .local-name {
    margin-top: 0
}
.popular-destination-item:hover .item-content .properties {
    opacity: 1;
    bottom: 0
}
.popular-destination-item:hover .item-content .btn-sun {
    opacity: 1;
    bottom: 15px
}
.popular-destination-item .item-content {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    bottom: 0;
    left: 0;
    color: #fff;
    padding: 15px
}
.popular-destination-item .item-content .local-name {
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: 600;
    transition: all .3s ease;
    margin-top: 10px;
    color: #fff;
    line-height: 1.4
}
.popular-destination-item .item-content .continent {
    font-size: 13px;
    font-weight: 600;
    color: #ffc107;
    text-transform: none;
    display: block
}
.popular-destination-item .item-content .properties {
    transition: all .3s ease;
    opacity: 0;
    position: relative;
    bottom: -10px
}
.popular-destination-item .item-content .properties .property {
    width: 50%;
    float: left;
    position: relative;
    padding-left: 20px;
    font-size: 13px
}
.popular-destination-item .item-content .properties .property .icon {
    position: absolute;
    left: 0;
    color: #ffc107
}
.popular-destination-item .item-content .properties .property .total {
    display: block;
    font-weight: 600;
    font-size: 18px;
    line-height: 1
}
.popular-destination-item .item-content .btn-sun {
    background-color: transparent;
    color: #ffc107;
    padding: 5px 12px;
    transition: all .3s ease;
    position: absolute;
    right: 15px;
    bottom: 0;
    opacity: 0;
    min-width: 80px
}
.popular-destination-item .item-content .btn-sun:hover {
    color: #fff;
    background-color: #ffc107
}
.list-destination-worldwide .popular-destination-item .item-content {
    padding: 20px
}
.list-destination-worldwide .popular-destination-item .item-content .properties {
    color: #ffc107;
    font-size: 12px;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
    margin: 10px 0 2px;
    font-weight: 600;
    opacity: 1;
    bottom: 0
}
.list-destination-worldwide .popular-destination-item .item-content .des-name {
    font-size: 24px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
    z-index: 1;
    line-height: 1;
    margin: 0;
    transition: all .25s ease
}
.list-destination-worldwide .popular-destination-item .item-content .btn-sun {
    min-width: 120px
}
.list-destination-worldwide .popular-destination-item:hover .item-content .properties {
    margin-top: 0
}
.list-destination-worldwide .popular-destination-item:hover .item-content .btn-sun {
    bottom: 20px
}
.list-destination-asc {
    padding-left: 15px
}
.list-destination-asc li {
    width: 25%;
    float: left;
    padding-right: 15px;
    margin-bottom: 5px
}

.just-booked-block {
    margin-right: 1px
}
.just-booked-block .type-booking {
    color: #ffc107;
    font-weight: 500;
    font-size: 18px;
    margin-right: 3px
}
.just-booked-block .booked-item {
    margin-top: 15px;
    padding: 15px;
    border: 1px solid #dbdddf;
    position: relative;
    min-height: 100px
}
.just-booked-block .booked-item .booked-item-img {
    width: 100px;
    height: 70px;
    overflow: hidden;
    position: absolute;
    top: 15px;
    left: 15px
}
.just-booked-block .booked-item .booked-item-img:after {
    content: "";
    position: absolute;
    display: block;
    width: 30px;
    height: 100%;
    top: 0;
    left: 70px;
    z-index: 1;
    background-color: #fff;
    transform-origin: bottom right;
    transform: skew(-20deg, 0deg)
}
.just-booked-block .booked-item .booked-item-content {
    padding-left: 115px
}
.just-booked-block .booked-item .booked-item-content .name {
    color: #44515e;
    margin: 0 0 5px;
    font-weight: 500;
    text-transform: none
}
.destination-city-tabs .nav-tabs>li {
    margin-bottom: -1px
}
.destination-city-tabs .nav-tabs>li>a {
    border: 1px solid #ddd;
    border-bottom: 0;
    border-radius: 0;
    padding: 15px 20px;
    margin-right: 0;
    background-color: #fff
}
.destination-city-tabs .nav-tabs>li>a:focus,
.destination-city-tabs .nav-tabs>li>a:hover {
    border: 1px solid #ddd;
    border-top: 1px solid #ffc107;
    border-bottom: 0
}
.destination-city-tabs .nav-tabs>li:first-child a {
    border-right: 0
}
.destination-city-tabs .nav-tabs>li.active>a {
    border-left: 1px solid #ddd;
    border-top: 1px solid #ffc107;
    border-bottom: 0
}
.destination-city-tabs .tab-content {
    padding: 0 15px
}
.destination-city-tabs .tab-content .tab-pane {
    border: 1px solid #ddd;
    border-top: 0;
    padding: 30px 15px 15px
}
.destination-city-tabs .tab-content .list-style-color-disc {
    padding-left: 15px;
    margin: 0
}
.destination-city-tabs .tab-content .list-style-color-disc li {
    width: 50%;
    float: left;
    margin-bottom: 10px
}
@media only screen and (max-width: 767px) {
    .destination-city-tabs .tab-content .list-style-color-disc li {
        width: 100%
    }
}
.carousel-dots-bottom .owl-dots {
    text-align: center
}
.carousel-dots-bottom .owl-dot {
    display: inline-block;
    margin: 0 5px
}
.carousel-dots-bottom .owl-dot span {
    margin: 0;
    background-color: #fff
}
.carousel-dots-bottom .owl-next,
.carousel-dots-bottom .owl-prev {
    background-color: rgba(0, 0, 0, .6);
    padding: 5px 7px
}
.carousel-dots-bottom .owl-prev {
    left: -50%;
    transition: left .35s
}
.carousel-dots-bottom .owl-next {
    right: -50%;
    transition: right .35s
}

.range-condensed {
    width: 50%;
    height: 228px;
    position: relative;
    float: left
}

.range-condensed .range-img {
    overflow: hidden;
    height: 100%;
    width: 50%;
    float: left;
    position: relative
}

.range-condensed .range-img img {
    height: 100%;
    width: auto
}



.range-condensed .range-img:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .2)
}
.range-condensed .range-content {
    width: 50%;
    float: left;
    text-align: center;
    padding: 52px 15px;
    position: relative;
    z-index: 2
}

.range-condensed .range-content .name {
    color: #44515e;
    font-size: 24px;
    font-weight: 700;
    text-transform: none;
    margin: 0
}
.range-condensed .range-content .name .country {
    color: #555;
    font-size: 15px;
    font-style: italic
}
.range-condensed .range-content .price {
    margin: 5px 0 15px
}
.range-condensed .range-content .price .price-number {
    margin-left: 5px;
    font-weight: 500
}
.range-condensed .range-content .price .number {
    font-size: 23px
}
.range-condensed .range-content:before {
    content: '';
    position: absolute;
    left: -15px;
    top: 50%;
    z-index: 1;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-right: 15px solid #fff;
    margin-top: -20px
}
.range-condensed:after,
.range-condensed:before {
    content: '';
    position: absolute;
    left: 0;
    z-index: 1
}
.range-condensed:before {
    width: 100%;
    height: 0;
    border-left: 7px solid #ffc107;
    border-right: 7px solid #ffc107;
    top: 0;
    transition: height .35s ease
}
.range-condensed:after {
    width: 0;
    height: 100%;
    border-top: 7px solid #ffc107;
    border-bottom: 7px solid #ffc107;
    top: 0;
    transition: width .35s ease
}
.range-condensed:hover:after {
    width: 100%
}
.range-condensed:hover:before {
    height: 100%
}

.block-hotel {
    margin-bottom:25px;
    padding-left: 0;
    position: relative;
    /*box-shadow: 0 0 1px rgba(0, 0, 0, .1);*/
    transition: box-shadow .25s;
    border:1px solid #ddd;
    width:100%;
    float:left;
}
@media only screen and (max-width: 991px) {
    .range-condensed .range-content:before,
    .range-condensed:after,
    .range-condensed:before {
        display: none
    }
    .block-hotel {
        height: 190px
    }
    .compact .block-hotel {
        height: inherit;
    }
}

.block-hotel:hover .hotel-img:after {
    transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 11deg) translate3d(100%, 0, 0)
}
.block-hotel:hover .hotel-img:before {
    background-color: rgba(255, 193, 7, .2);
    display:none;

}
.block-hotel .btn {
    padding-bottom: 10px;
    padding-top: 10px;
    text-transform: uppercase;
    font-size: 12px
}
.block-hotel .hotel-info {
    width: 50%;
    float: left;
    padding:15px;
}
@media only screen and (max-width: 400px) {
    .block-hotel .hotel-info {
        width: 55%
    }
}
.block-hotel .hotel-info .hotel--info {
    width:100%;
    float:left;
    margin:0 0 20px 0;
}
.block-hotel .hotel-info:after {
    content: "";
    position: absolute;
    display: none;
    width: 100px;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 1;
    background-color: #fff;
    transform-origin: bottom left;
    transform: skew(-15deg, 0deg)
}
.block-hotel .hotel-info .hotel-price {
    margin-bottom:7px;
    margin-left:0px;
    margin-top:7px;
    width:100%;
    float:left;
}
@media only screen and (max-width: 767px) {
    .block-hotel .hotel-info .hotel-price {
        font-size: 13px
    }
}
.block-hotel .hotel-info .hotel-price .number-price {
    font-size:1.3em;
    color: #44515e;
    margin-left:5px;
    line-height:normal;
}
.block-hotel .hotel-info .hotel-price .dollar {
    color: #44515e;
    margin-left:0px;
    font-size:1.3em;
}
.block-hotel .hotel-info .reviews-score {
    margin-bottom:5px;
    margin-top:5px;
    display: inline-block
}

.block-hotel .hotel-info .reviews-score .yellow {
    color:#f8b05a;
    margin:0 3px 0 0;
}
.block-hotel .hotel-info .hotel-desc {
    font-size: 14px
}

.block-hotel .hotel-info .hotel-desc .icon {
    color: #44515e;
    vertical-align: bottom;
    margin-right: 7px
}
.block-hotel .hotel-info .hotel-name {
    font-weight:700;
    color: #44515e;
    text-transform: none;
    font-size: 16px;
    margin:0px 0 10px;
    line-height:20px;
}
.block-hotel .hotel-info .hotel-name .first_address{
    font-weight:700;
    text-transform: none;
    font-size: 16px;
    line-height:20px;
    max-width:254px;
    text-overflow:ellipsis;
    white-space: nowrap;
    overflow:hidden;
}

.block-hotel .hotel-info .hotel-name span { font-weight: 500; font-size:14px; display:block;  }
.block-hotel .hotel-info .hotel-name span i { padding-right:3px;}
.block-hotel .hotel-info .btn-book-now {
    left:0;
    border: 1px solid #fbc17b;
    width:50%;
    z-index: 2;
    position: absolute;
    bottom: 0;
    padding-top:7px;
    padding-bottom:7px;
}
.block-hotel .hotel-info .btn-book-now span {
    position: relative;
    z-index: 2;
    font-size:1.4em;
    font-weight:500;
}
.block-hotel .hotel-info .btn-book-now:hover:after {
    background-color: #ffc107
}
.block-hotel.block-hotel-grid .hotel-info:after,
.lt-ie11 .block-hotel .hotel-img:after {
    display: none
}
.block-hotel .hotel-img {
    width: 50%;
    float: left;
    height: 100%;
    position: relative;
    overflow: hidden;
    transition: all .3s ease
}
@media only screen and (max-width: 400px) {
    .block-hotel .hotel-img {
        width: 55%
    }
}
.block-hotel .hotel-img:after,
.block-hotel .hotel-img:before {
    top: 0;
    left: 0;
    width: 100%;
    position: absolute;
    height: 100%;
    content:'';
    z-index:99;
    display:none;

}
.block-hotel .hotel-img:before {
    background-color: rgba(0, 0, 0, .2);
    transition: background-color .3s
}
.block-hotel .hotel-img:after {
    background: rgba(255, 255, 255, .2);
    transition: transform .6s;
    transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 11deg) translate3d(100%, 0, 0)
}
.block-hotel .hotel-img .local-name {
    position: absolute;
    bottom: 10px;
    left: 30px;
    color: #fff;
    font-size: 14px
}
@media only screen and (max-width: 500px) {
    .block-hotel .hotel-img .local-name {
        left: 15px
    }
}
.lt-ie11 .block-hotel .hotel-img .local-name {
    left: 10px
}
.block-hotel .hotel-img .discount {
    position: absolute;
    top: 20px;
    left: 45px;
    background-color: #ffc107;
    border: 0;
    color: #fff;
    font-size: 1.1em;
    font-weight: 700;
    border-radius: 0;
    min-width: 80px;
    text-align: center;
    padding-right: 15px
}
.lt-ie11 .block-hotel .hotel-img .discount {
    left: 0;
    min-width: 60px;
    padding-right: 10px
}
.block-hotel.block-hotel-grid {
    height: auto;
    float: left;
    width: 100%;
    min-height: 400px
}
.block-hotel.block-hotel-grid .hotel-info {
    width: 100%;
    padding: 0 15px 15px
}
.block-hotel.block-hotel-grid .hotel-info .icon-location {
    color: #ffc107;
    font-weight: 700
}
.block-hotel.block-hotel-grid .hotel-grid-img {
    width: 100%;
    max-height: 220px;
    overflow: hidden;
    position: relative;
}
.block-hotel.block-hotel-grid .hotel-grid-img:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .2);
    top: 0;
    left: 0;
    transition: background-color .3s
}
.block-hotel.block-hotel-grid .hotel-grid-img .discount {
    background-color: #ffc107;
    top: 0;
    left: 15px;
    position: absolute;
    font-size: 18px;
    padding: 20px 10px;
    color: #fff;
    font-weight: 700
}
@media only screen and (max-width: 991px) {
    .block-hotel.block-hotel-grid {
        min-height: 350px
    }
    .block-hotel.block-hotel-grid .hotel-grid-img .discount {
        padding: 10px
    }
}
.block-hotel.block-hotel-grid .hotel-grid-img .btn-book-now {
    position: absolute;
    top: 60%;
    margin-top: -20px;
    left: 50%;
    margin-left: -65px;
    color: #ffc107;
    opacity: 0;
    transition: background-color .3s, opacity .3s, top .35s
}
.block-hotel.block-hotel-grid .hotel-grid-img .btn-book-now:hover {
    color: #fff
}
.block-hotel.block-hotel-grid:hover .hotel-grid-img:before {
    background-color: rgba(0, 0, 0, .5)
}
.block-hotel.block-hotel-grid:hover .hotel-grid-img .btn-book-now {
    opacity: 1;
    top: 50%
}
/* rebrand property card */
.tru-cardaddress {
    background-color: white;
    padding: 10px;
    border: 1px solid #B2B2B2;
    text-align: left;
    font-size: 13px;
}
.tru-property-info {
    width: 100%;
    position: absolute;
    bottom: -44px;
    z-index: 3;
    overflow: hidden;
}
.compact .tru-property-info {
    position: inherit;
    bottom: inherit;
    min-height:103px; 
}
/* end rebrand property card */
.sort-by {
    /*margin-bottom:10px;*/
    padding:5px 14px 5px 14px;
    /* background-color:#edf0f5;
    border:1px solid #dbdddf */
}
.sort-by .title {
    font-weight: 600;
    margin-right: 30px;
    color: #44515e
}
@media only screen and (max-width: 767px) {
    .sort-by {
        float: left;
        width: 100%
    }
    .sort-by .title {
        display: block;
        margin-bottom: 10px
    }
}
.sort-by .sort-item {
    margin-right: 20px;
    display: inline-block;
    position: relative;
    transition: .25s color ease
}
@media only screen and (max-width: 767px) {
    .sort-by .sort-item {
        margin: 0 0 5px;
        width: 50%;
        float: left
    }
}
.sort-by .sort-item .btn-group.open .btn,
.sort-by .sort-item:hover .btn {
    color: #ffc107
}
.sort-by .sort-item .btn {
    background-color: #edf0f5;
    border: 0;
    color: #555;
    padding: 0
}
.sort-by .icon.selected,
.sort-by .sort-item .btn.selected,
.sort-by .sort-item .dropdown-menu a:hover {
    color: #ffc107
}
.sort-by .sort-item .btn:focus {
    outline: 0
}
.sort-by .sort-item .dropdown-menu {
    top: 35px;
    min-width: 100px
}
@media only screen and (max-width: 991px) {
    .sort-by .sort-item .dropdown-menu {
        top: 20px
    }
}
.sort-by .icon {
    position: absolute;
    padding: 10px;
    top: 0
}
.sort-by .icon:hover {
    text-decoration: none;
    color: #ffc107
}
.sort-by .icon-grid {
    right: 20px
}
.sort-by .icon-list {
    right: 60px
}
.hotels-page {
    margin-bottom: 100px;
    clear: both
}
.hotels-page h2 {
    color: #44515e;
    margin: 0 0 15px
}
@media only screen and (max-width: 991px) {
    .hotels-page .hotel-description {
        margin-bottom: 30px
    }
}
.hotels-page .list-style-tick {
    text-transform: uppercase;
    margin-top: 15px;
    margin-bottom: 10px
}
.hotels-page .check-avail {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 14px;
    margin-top: 10px
}
.hotels-page .search-comp.sidebar-accordion-mobile  {
    display: none
}
@media only screen and (max-width: 767px) {
    .hotels-page .search-comp.sidebar-accordion-mobile {
        padding-top: 0
    }
    .hotels-page .search-comp.sidebar-accordion-mobile {
        padding-left: 0;
        padding-right: 0;
        display: block
    }
}
.search-comp.search-page-detail {
    padding: 15px 15px 0;
    margin: 30px 0 10px
}
.search-comp.search-page-detail .search-bar {
    background-color: transparent
}
.sidebar-accordion-mobile .icon-arrow-down,
.sidebar-accordion-mobile .icon-arrow-up,
.sidebar-accordion-mobile .ribbon>a:before {
    float: right;
    padding-top: 2px;
    display: none
}
.sidebar-accordion-mobile  {
    padding: 15px 30px;
    color: #44515e;
    margin: 0
}
@media only screen and (max-width: 767px) {
    .sidebar-accordion-mobile .icon-arrow-up {
        display: none
    }
    .sidebar-accordion-mobile .icon-arrow-down,
    .sidebar-accordion-mobile .ribbon>a:before,
    .sidebar-accordion-mobile.open .icon-arrow-up {
        display: inline-block
    }
    .sidebar-accordion-mobile.open .icon-arrow-down,
    .sidebar-accordion-mobile.open .ribbon>a:before {
        display: none
    }
    .sidebar-accordion-mobile {
        padding: 15px
    }
}
.list-hotels {
    clear: both
}
.list-hotels .block-hotel {
    padding-right: 0
}
.list-hotels .block-hotel .hotel-info .btn-bottom {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0
}
@media only screen and (max-width: 991px) {
    .list-hotels .block-hotel .hotel-info .btn-bottom {
        width: 100%
    }
}
.list-hotels .block-hotel .hotel-info .btn-bottom .btn {
    position: relative;
    float: left;
    bottom: 0
}
.list-hotels .block-hotel .hotel-info .local-name {
    display: none
}
@media only screen and (min-width: 992px) {
    .list-hotels .block-hotel .hotel-info {
        width: 50%
    }
    .list-hotels .block-hotel .hotel-info .local-name {
        position: absolute;
        left: 0;
        bottom: 10px;
        z-index: 2;
        display: block
    }
    .list-hotels .block-hotel .hotel-img {
        width: 40%
    }
    .list-hotels .block-hotel .hotel-img .local-name {
        display: none
    }
}
.list-hotels .block-hotel.content-right .hotel-info:after {
    left: 0;
    transform: skew(15deg, 0deg)
}
.list-hotels .block-hotel.content-right .hotel-info .local-name {
    right: 0;
    left: auto;
    text-align: right
}
.list-hotels .block-hotel.content-right .hotel-info .btn-bottom {
    left: 0
}
.list-hotels .block-hotel.content-right .hotel-info .btn-bottom .btn-book-now:after {
    left: -2px;
    transform: skew(15deg, 0deg)
}
.list-hotels .block-hotel.content-right .hotel-img:after {
    transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, -11deg) translate3d(72%, 0, 0)
}
.list-hotels .block-hotel.content-right .hotel-img .discount {
    right: 45px;
    left: auto
}
.lt-ie11 .list-hotels .block-hotel.content-right .hotel-img .discount {
    right: 0
}
.list-hotels .block-hotel.content-right:hover .hotel-img:after {
    transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, -11deg) translate3d(-100%, 0, 0)
}
.hotels-list-page .search-comp {
    margin-bottom: 30px;
    margin-top: 0;
    background-color: #edf0f5;
    border: 1px solid #dbdddf
}
.slider-hotels {
    padding: 0 0 25px
}
.slider-hotels .carousel-hotel-dots {
    padding: 0px;
    margin-top:-35px;
    position: relative;
    text-align: center
}
.slider-hotels .carousel-hotel-dots .owl-item {
    width: 16.667%
}
.slider-hotels .carousel-hotel-dots .owl-item.selected .thumbnail-item:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid #ffc107;
    background-color: rgba(255, 173, 9, .3)
}
.search-comp .nav-tabs li a:hover {
    color: #fff;
    border-bottom: 0;
    transform: skew(-15deg, 0deg);
    transform-origin: bottom right;
}

@media only screen and (max-width: 767px) {
    .slider-hotels .carousel-hotel-dots {
        display: none
    }
    .slider-hotels .carousel-hotel-dots .owl-item.selected .thumbnail-item {
        background-color: #fff
    }
}
.slider-hotels .carousel-hotel-dots .thumbnail-item {
    width: 15%;
    height: 80px;
    display: table-cell;
    overflow: hidden;
    border: 2px solid #fff;
    position: relative;
    cursor: pointer
}
.slider-hotels .carousel-hotel-dots .thumbnail-item img {
    height: 100%
}
.slider-hotels .carousel-hotel-dots .owl-next,
.slider-hotels .carousel-hotel-dots .owl-prev {
    background-color: #ffc107;
    height: 80px;
    width: 30px;
    top: 0;
    padding: 0;
    margin-top: 0;
    line-height: 80px;
    text-align: center;
    position: absolute;
    color: #fff;
    font-size: 16px;
    cursor: pointer
}
.slider-hotels .carousel-hotel-dots .owl-prev {
    left: 0
}
.slider-hotels .carousel-hotel-dots .owl-next {
    right: 0
}
.slider-hotels .carousel-hotel-big {
    position: relative;
    padding: 0
}

.slider-hotels .carousel-hotel-big .owl-next,
.slider-hotels .carousel-hotel-big .owl-prev {
    width: 40px;
    height: 40px;
    text-align: center;
    background-color: rgba(0, 0, 0, .5);
    opacity: 1;
    padding: 5px;
    transition: opacity .35s, right .25s, left .25s
}
.slider-hotels .carousel-hotel-big .owl-next.disabled,
.slider-hotels .carousel-hotel-big .owl-prev.disabled {
    opacity: 0;
    cursor: auto
}
.col-list-style-star .list-style-star {
    padding-left: 0;
    transition: all .25s ease;
    max-height: 250px;
    height: auto
}
.col-list-style-star .list-style-star li {
    margin-bottom: 3px;
    visibility: hidden;
    display: none
}
.col-list-style-star .list-style-star li.visible-item,
.col-list-style-star .list-style-star li:nth-child(-n+9) {
    visibility: visible;
    display: list-item
}
.col-list-style-star .btn-more {
    padding: 20px 20px 20px 0;
    color: #44515e;
    font-weight: 600;
    text-decoration: underline
}
.val {
    font-size: 16px;
    font-style: italic;
    color: #44515e
}
.val .price,
.val .save {
    display: block;
    font-style: normal;
    font-weight: 500
}
.val .save {
    font-size: 20px
}
.val .price {
    font-size: 24px
}
.title {
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    padding: 15px 30px;
    background-color: #44515e !important;
    border-bottom: 2px solid #ffc107 !important;
    margin: 0
}
.table th {
    text-transform: uppercase;
    font-size: 13px;
    border-bottom: 0
}
.table .btn-sun {
    width: 100%
}
.table .room-name {
    font-weight: 600;
    color: #44515e;
    padding: 15px 0 10px;
    margin: 0;
    text-transform: none
}
.table .list-style-color-disc {
    padding-left: 15px
}
.table .room-img-main {
    width: 100%;
    max-width: 250px;
    height: 150px;
    overflow: hidden;
    cursor: pointer;
    margin-top: 10px
}
.table .max-user {
    color: #ffc107;
    position: relative;
    padding-top: 15px;
    top: 17px
}
.table .max-user .icon-question {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -7px
}
.table .max-user .icon-user {
    margin-left: -5px
}
.table .td-price {
    color: #44515e;
    font-size: 26px;
    font-weight: 600
}
.table .td-price .sale-off {
    color: #FF3131;
    text-decoration: line-through
}
.table .td-price .dollar {
    font-size: 15px;
    font-weight: 400
}
.table .td-price .icon {
    color: #555;
    padding: 0 5px 10px;
    font-size: 11px
}
.table .td-price.sold {
    font-size: 16px;
    text-transform: uppercase
}
.table .td-price.sold .tooltip,
.table .td-price.sold sup {
    text-transform: none
}
.table td {
    min-width: 140px
}
.table .compare td, th {
    min-width: 140px
}
.no-min-width th, .no-min-width td {
    min-width: initial;
    font-size: 12px;
}

/* .modal-header { border-bottom: 0; }
.modal-content { background-color: #F0F0F0; } */

.number-left-room {
    text-align: right;
    margin: 10px 0;
    color: #FF3131
}
.location-area {
    clear: both;
    overflow: hidden;
    height: 360px;
    transition: height .35s ease
}
.location-area.hide-locaion {
    height: 0
}
.location-area .location-map {
    margin-bottom: 20px;
    height: 350px
}
.location-area .info-location {
    display: inline-block;
    margin-right: 15px
}
.location-area .info-location .icon {
    color: #ffc107
}
.title-area {
    color: #44515e;
    border-bottom: 2px solid #ffc107;
    padding: 15px 0;
    margin-bottom: 25px;
    margin-top: 50px
}
.amenties-area {
    clear: both;
    padding: 0 15px
}
.amenties-area .list-amenties {
    margin-bottom: 15px
}
@media only screen and (max-width: 767px) {
    .amenties-area .list-amenties:nth-child(2n+1) {
        clear: left
    }
}
.customer-review,
.sub-section-review {
    clear: both;
    border-bottom: 1px solid #dbdddf
}
.amenties-area{
    font-weight: 700;
    text-transform: uppercase;
    color: #44515e;
    font-size: 14px;
    padding-left: 15px;
    margin: 0 0 5px
}
.amenties-area .list-style-color-disc {
    padding-left: 15px
}
.review-area {
    padding: 0 15px
}
.review-area .title-area {
    margin-bottom: 0
}
.review-area .nav-tabs {
    margin: 0
}
.review-area .tab-content {
    border: 1px solid #dbdddf;
    margin-top: 30px
}
@media only screen and (max-width: 767px) {
    .review-area .tab-content {
        margin: 30px -15px 0
    }
    .review-area .write-review .btn {
        margin-top: 10px
    }
}
.review-area .tab-pane {
    padding: 0
}
.review-area .write-review {
    text-align: center;
    color: #44515e;
    font-size: 22px;
    padding: 40px
}
.review-area .write-review .btn {
    margin-left: 10px
}
.customer-review {
    position: relative;
    margin-bottom: 15px;
    padding-right: 30px;
    width: 100%;
    float: left
}
@media only screen and (max-width: 991px) {
    .customer-review {
        padding-right: 0
    }
}
.customer-review .customer-review-rate {
    background-color: #44515e;
    padding: 30px 20px 25px;
    color: #ffc107;
    text-align: center;
    min-width: 170px;
    display: inline-block;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0
}
.customer-review .customer-review-rate .review-number {
    color: #fff;
    font-size: 50px;
    font-size: "Roboto", Helvetica, Arial, sans-serif;
    line-height: 1
}
.customer-review .customer-review-rate .review-rating-count {
    color: #555
}
.customer-review .review-content {
    padding: 15px 20px 15px 170px;
    position: relative;
    z-index: 1;
    width: 100%;
    float: left;
    min-height: 170px
}
@media only screen and (max-width: 991px) {
    .customer-review .review-content {
        padding: 30px 0 15px 170px
    }
}
.customer-review .review-content ul {
    float: left;
    padding-left: 0;
    margin-bottom: 0
}
@media only screen and (max-width: 767px) {
    .customer-review .customer-review-rate {
        width: 100%;
        position: relative
    }
    .customer-review .review-content {
        padding-left: 0
    }
    .customer-review .review-content ul {
        width: 100%
    }
}
.customer-review .review-content li {
    text-align: right;
    list-style-type: none;
    padding-left: 30px;
    margin-bottom: 5px
}
@media only screen and (max-width: 767px) {
    .customer-review .review-content li {
        padding: 0 15px;
        max-width: 300px;
        margin: auto
    }
}
/* .customer-review .review-content li .review-name {
    float: left;
    margin-right: 10px;
    display: inline-block
} */
.customer-review .review-content li .review-rating-number {
    min-width: 20px;
    display: inline-block;
    text-align: left;
    vertical-align: top;
    padding-top: 3px
}
.customer-review .review-content li .star-rating {
    margin-top: 5px;
    height: 20px
}
.customer-review .review-image {
    position: absolute;
    right: 0;
    top: 0;
    width: 275px
}
@media only screen and (max-width: 991px) {
    .customer-review .review-image {
        display: none;
        width: 0
    }
}
.sub-section-review {
    position: relative;
    padding: 30px 0;
    margin: 0 30px
}
.sub-section-review .user-info {
    width: 170px;
    position: absolute;
    top: 30px;
    left: -30px;
    text-align: center
}
@media only screen and (max-width: 767px) {
    .sub-section-review {
        margin: 0;
        padding: 30px 15px
    }
    .sub-section-review .user-info {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        text-align: left;
        height: 80px;
        margin-bottom: 15px
    }
    .sub-section-review .user-info .user-name {
        padding-left: 95px;
        padding-top: 20px
    }
}
.sub-section-review .user-info .user-name {
    font-weight: 600;
    color: #939ea9;
    text-transform: none;
    margin: 0
}
.sub-section-review .user-info .user-avt {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    display: inline-block;
    margin-bottom: 7px
}
.sub-section-review .user-info .total-review {
    font-size: 13px
}
@media only screen and (max-width: 767px) {
    .sub-section-review .user-info .user-avt {
        position: absolute
    }
    .sub-section-review .user-info .total-review {
        padding-left: 95px
    }
}
.sub-section-review .review-detail {
    padding-left: 140px
}
@media only screen and (max-width: 767px) {
    .sub-section-review .review-detail {
        padding-left: 0
    }
}
.sub-section-review .review-detail .sub-header-review {
    position: relative
}
.sub-section-review .review-detail .sub-header-review .number-star {
    vertical-align: top;
    display: inline-block
}
.sub-section-review .review-detail .sub-header-review .title {
    font-size: 20px;
    font-weight: 600;
    color: #44515e;
    text-transform: none;
    margin: 0
}
.sub-section-review .review-detail .sub-header-review .user-rating {
    position: absolute;
    right: 0;
    bottom: -5px
}
.sub-section-review .review-detail .sub-header-review .user-address {
    color: #7f7f7f;
    margin-bottom: 10px;
    font-size: 13px
}
.sub-section-review .review-detail .sub-header-review .user-address .date-post {
    color: #555
}
.sub-section-review .review-detail .comment-detail {
    padding-top: 15px;
    border-top: 1px solid #dbdddf
}
.quick-scroll {
    background-color: #fff;
    width: 100%
}
.quick-scroll.sticky {
    position: fixed;
    top: 64px;
    z-index: 5;
    width: 100%;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .1)
}
.quick-scroll li,
.quick-scroll li a {
    display: inline-block;
    position: relative
}
@media only screen and (max-width: 991px) {
    .quick-scroll.sticky {
        top: 0
    }
}
.quick-scroll.sticky li a {
    padding-bottom: 20px;
    padding-top: 20px
}
.quick-scroll ul {
    padding: 0px;
    margin-bottom: 0
}
.quick-scroll .star-rating {
    top: 12px
}
.quick-scroll li {
    text-decoration: none
}
.quick-scroll li a {
    padding: 20px 13px 20px 0;
    transition: padding .25s ease;
    font-weight: 600
}
.sticky .quick-scroll li a {
    padding-top: 330px
}
.quick-scroll li a:after {
    content: '';
    width: 0;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #ffc107;
    transition: width .35s ease
}
.quick-scroll li.active a,
.quick-scroll li:focus a,
.quick-scroll li:hover a {
    color: #44515e;
    text-decoration: none
}
.quick-scroll li.active a:after,
.quick-scroll li:focus a:after,
.quick-scroll li:hover a:after {
    width: 90%
}
@media only screen and (max-width: 992px) {
    .quick-scroll li:hover {
        color: #555
    }
    .quick-scroll li:hover:after {
        width: 0
    }
}
.list-gallery-img .gallery-item .search-image .icon,
.subscribe {
    color: #fff
}
.quick-scroll li:first-child a:after {
    left: 0
}
.list-gallery-img .grid-sizer {
    width: 25%
}
@media only screen and (max-width: 991px) {
    .list-gallery-img .grid-sizer {
        width: 33.33%
    }
}
@media only screen and (max-width: 767px) {
    .quick-scroll li a {
        padding: 15px 15px 15px 0;
        font-size: 13px
    }
    .list-gallery-img .grid-sizer {
        width: 50%
    }
}


.list-gallery-img .gallery-item:after,
.list-gallery-img .gallery-item:before {
    height: 0;
    width: 100%;
    opacity: .7;
    position: absolute;
    transition: all .25s ease;
    content: '';
    z-index: 1
}

.list-gallery-img .gallery-item:after {
    left: -65%;
    background-color: #353e46;
    transform-origin: bottom left;
    transform: skew(-15deg, 0deg);
    top: 0
}
.lt-ie11 .list-gallery-img .gallery-item:after {
    left: -50%
}
.list-gallery-img .gallery-item:before {
    left: 35%;
    background-color: #ffc107;
    transform-origin: bottom left;
    transform: skew(-15deg, 0deg);
    bottom: 0
}
.lt-ie11 .list-gallery-img .gallery-item:before {
    left: 50%
}
.list-gallery-img .gallery-item .search-image {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    z-index: 1;
    position: absolute;
    right: 15px;
    font-size: 18px;
    opacity: 0;
    visibility: hidden;
    transition: all .25s ease;
    bottom: 15px;
    border: 1px solid #fff
}

.list-gallery-img .gallery-item .search-image:hover {
    text-decoration: none
}
.list-gallery-img .gallery-item:hover .search-image {
    opacity: 1;
    visibility: visible
}
.list-gallery-img .gallery-item:hover:after,
.list-gallery-img .gallery-item:hover:before {
    height: 100%
}
.section-gallery-home {
    margin: 0 0 100px
}
.section-gallery-home .list-gallery-img {
    margin-top: 30px
}
.section-gallery-home .gallery-item {
    height: 178px
}
.section-gallery-home .gallery-item img {
    height: 100%;
    width: 100%
}
.Sitemap{color:#fff}

.gallery-page {
    margin-bottom: 100px
}
.gallery-page .nav-tabs {
    margin-bottom: 60px
}
.subscribe {
    padding-top: 0
}
.subscribe .bg-overlay {
    background-color: #dddddd
}
.subscribe .subscribe-title {
    margin: 0;
    color: #000
}
@media only screen and (max-width: 991px) {
    .subscribe .subscribe-title {
        font-size: 32px;
        margin-top: 3px
    }
}
@media only screen and (max-width: 767px) {
    .subscribe .subscribe-title {
        margin-bottom: 15px
    }
}
.subscribe .subscribe-name {
    font-size: 12px;
    text-transform: uppercase;
    color: #44515e;
    font-weight: 500
}
.subscribe .subscribe-content {
    padding: 30px 0;
    position: relative;
    z-index: 1
}
.subscribe .form-group {
    position: relative;
    margin: 0
}
.subscribe .form-group input {
    padding: 15px 50px;
    height: auto;
    color: #555
}
.subscribe .icon-goto {
    position: absolute;
    right: 20px;
    top: 17px;
    font-size: 20px;
    color: #555
}
.subscribe .icon-mail {
    position: absolute;
    left: 20px;
    top: 18px;
    font-size: 16px;
    color: #555
}

.footer.search-footer {position: relative;}
.list-full .footer { z-index:90; }
/* .footer a, .footer a:active, .footer a:focus { color:#fff; cursor:pointer;} */
/*.footer:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color:rgba(74,74,74, .90);

}*/
.footer .footer-content {
    position: relative;
    z-index: 1
}

.footer .copyright {
    color: #fff;
    position: relative;
    width: 100%;
    z-index: 1;
    margin: 0;
    font-weight: 400;
    font-size:12px
}

/* .footer .logo {
    margin-bottom: 15px;
    padding-bottom: 10px
} 
.footer .logo img {
    height: 40px;
    width: auto
}
.footer ul {
    padding: 0;
    margin: 0
}
.footer ul li {
    list-style-type: none;
    margin-bottom: 15px
}
.footer .useful-blog-small li {
    position: relative;
    min-height: 70px;
    width: 100%;
    font-size:14px;
}
.footer .useful-blog-small .box-img {
    width: 80px;
    height:53px;
    overflow: hidden;
    position: absolute
}
.footer .useful-blog-small .box-img:after {
    content: "";
    position: absolute;
    display: block;
    width: 30px;
    height: 100%;
    top: 0;
    left: 60px;
    z-index: 1;
    background-color:rgba(74,74,74, .90);
    transform-origin: bottom right;
    transform: skew(-15deg, 0deg)
}
.footer .useful-blog-small .content {
    padding-left: 85px
}
.footer .useful-blog-small .content .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 3px;
    display: inline-block
}
.footer .useful-blog-small .content .icon {
    color:#f7951f;
    margin-right: 5px;
    font-size: 13px
}
.footer .info-company li {
    padding-left:20px;
    position: relative
}
.footer .info-company li .icon {
    position: absolute;
    top: 3px;
    left: 0;
    color:#f7951f;
}
.footer .list-social a {
    padding: 5px 6px;
    border: 1px solid #ffc107;
    margin-right: 15px;
    margin-top: 10px;
    transition: color .25s;
    display: inline-block
}
.footer .list-social a:hover {
    color: #ffc107;
    text-decoration: none
}*/

.bxslider-about .slider-about-item {
    height: 330px
}
.bxslider-about .slider-about-item img {
    height: 100%;
    width: auto
}
.bxslider-about .owl-video-tn {
    background-size: cover;
    position: relative
}
.bxslider-about .owl-video-tn:before {
    width: 100%;
    height: 100%;
    position: absolute
}
.bxslider-about .owl-video-play-icon {
    color: #ffc107;
    font-size: 80px;
    margin-top: -60px
}

.search-happen {
    padding: 20px 15px;
    margin-bottom: 30px;
    background-color: #edf0f5
}
.search-happen .form-group {
    margin-bottom: 0
}
.search-happen .form-group input {
    min-height: 45px
}
.sidebar-block {
    border: 1px solid #dbdddf;
    padding: 10px 10px;
    position: relative;
}
.sidebar-block .sidebar-title {
    color: #44515e;
    font-size: 18px;
    margin: 0;
    padding-bottom: 10px;
    border-bottom: 2px solid #ffc107;
    position: relative;
    margin-bottom: 10px;
}
.sidebar-block .sidebar-content .list-style-color-disc a:hover,
.sidebar-block .sidebar-content .list-style-color-disc li.active a {
    color: #ffc107
}
.sidebar-block .sidebar-title:after {
    content: '';
    position: absolute;
    width: 30px;
    left: -30px;
    bottom: -2px;
    height: 2px;
    background-color: #ffc107
}
.sidebar-block .sidebar-content {
    padding-top: 25px
}
.sidebar-block .sidebar-content .list-style-color-disc {
    margin-bottom: 0;
    padding-left: 15px
}
.sidebar-block .sidebar-content .list-style-color-disc a {
    display: block;
    padding-bottom: 10px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px
}
.sidebar-block .sidebar-content .list-style-color-disc a .total {
    float: right
}
.sidebar-block .sidebar-amount {
    color: #44515e;
    margin: 0;
    border-bottom: 2px solid #ffc107;
    position: relative;
}
.sidebar-block .sidebar-amount:after {
    content: '';
    position: absolute;
    width: 30px;
    left: -30px;
    bottom: -2px;
    height: 2px;
    background-color: #ffc107
}
.sidebar-block .sidebar-seperator {
    color: #44515e;
    margin: 0;
    border-bottom: 1px solid #dbdddf;
    position: relative;
}
.sidebar-block .sidebar-seperator:after {
    content: '';
    position: absolute;
    width: 30px;
    bottom: -1px;
    height: 1px;
    background-color: #dbdddf;
}
.tags{margin-left: 8px; margin-bottom: 10px; }
.tags a {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    padding: 7px 15px 7px 25px;
    display: inline-block;
    background-color: #edf0f5;
    position: relative;
    margin-bottom: 10px;
    margin-right: 5px;
    transition: background-color .2s, color .2s
}
.closeicon-breadcrum{
    position: absolute;
    right:6px;
    /* top: 12px; */
    top: 10.5px;
    font-size: 8px;
}
.tags a:after
{
    background: #fff;
    content: '';
    position: absolute
}
.tags a:before {
    border-radius: 10px;
    box-shadow: inset 0 1px rgba(0, 0, 0, .25);
    height: 6px;
    left: 10px;
    width: 6px;
    top: 13px
}
.tags a:after {
    border-bottom: 16px solid transparent;
    border-left: 13px solid #edf0f5;
    border-top: 16px solid transparent;
    right: 0;
    top: 0;
    transition: border-left-color .2s
}
.tags a:hover {
    color: #fff;
    background-color: #ffc107;
    text-decoration: none;
    cursor: pointer;
}
.tags a:hover:after {
    border-left-color: #ffc107
}
.recent-comment a:hover {
    text-decoration: underline
}
.recent-comment .post-name {
    text-transform: uppercase;
    color: #44515e;
    font-size: 14px;
    font-weight: 600;
    padding: 0 5px
}
.recent-comment .user-name {
    color: #939ea9;
    text-transform: none;
    font-size: 15px;
    padding-right: 5px
}
.sidebar-solid-header {
    margin: 0 auto 30px;
    float: left;
    clear: both;
    border: 1px solid #dbdddf;
    padding-bottom: 0px;
    width: 100%
}
.sidebar-solid-header .sidebar-title {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
    background-color: #44515e;
    border-bottom: 2px solid #ffc107;
    padding: 13px 30px;
    margin: 0 0 0px
}
.sidebar-solid-header .sidebar-content {
    padding: 0 0px 30px
}
.sidebar-list-with-img {
    width: 100%;
    clear: both;
    float: left;
    margin-top: 0px;
    position: relative;
    overflow: hidden;
    min-height: 70px;
    box-shadow: 0 0 2px rgba(0, 0, 0, .1)
}
.sidebar-list-with-img:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, .1);
    text-decoration: none
}
.sidebar-list-with-img:hover .sub-title {
    text-decoration: underline
}
.sidebar-list-with-img .img-item {
    width: 80px;
    max-height: 70px;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 10px
}
.profile-img{
    overflow: hidden;
    left: 0;
    top: 0px;
    height: 110px
}
.sidebar-list-with-img .img-item:after {
    content: "";
    position: absolute;
    display: block;
    width: 20px;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 1;
    background-color: #fff;
    transform-origin: bottom right;
    transform: skew(-15deg, 0deg)
}
.range-blog .range-img:after,
.range-blog .range-img:before,
.search-comp .nav-tabs li:before {
    content: '';
    position: absolute
}
.blog-author,
.box-color {
    background-color: #edf0f5
}
.sidebar-list-with-img .post-info {
    padding-right: 15px;
    padding-left: 10px;
    padding-top: 10px;
    font-size: 14px;
    width: 50%;
    float: left;
}
.sidebar-list-with-img .post-info .sub-title {
    color: #44515e;
    font-weight: 600;
    text-transform: none;
    margin: 0 0 5px;
    font-size: 14px
}
.sidebar-list-with-img .post-info .date {
    font-size: 13px
}
.sidebar-list-with-img .post-info .date .icon {
    color: #ffc107
}
.sidebar-list-with-img .post-info .price-line {
    margin-top: -10px
}
.sidebar-list-with-img .post-info .price-line .price-number {
    font-size: 20px;
    font-weight: 600;
    color: #44515e
}
.sidebar-list-with-img .post-info .price-line .price-number {
    font-size: 15px;
    font-weight: 400
}
.show-current-date .datepicker-inline {
    width: 100%;
    padding: 0
}
.show-current-date .datepicker-inline table {
    width: 100%
}
.blog-detail-content {
    position: relative
}
.blog-detail-content .blog-image {
    overflow: hidden;
    max-height: 500px;
    width: 100%
}
.blog-detail-content .blog-info-title {
    position: relative;
    font-weight: 500;
    margin-bottom: 15px;
    border-top: 1px dashed #dbdddf;
    border-bottom: 1px dashed #dbdddf;
    padding: 15px 0;
    font-size: 14px
}
.blog-detail-content .blog-info-title .date {
    display: inline-block;
    margin-right: 10px
}
.blog-detail-content .blog-info-title .type-post a:hover {
    color: #44515e
}
.blog-detail-content .blog-info-title .icon {
    color: #ffc107;
    margin-right: 3px
}
.quote,
.text-bold {
    color: #44515e
}
.blog-detail-content .blog-info-title .total-comment {
    position: absolute;
    top: 15px;
    right: 0
}
.blog-detail-content h2,
.blog-detail-content h3,
.blog-detail-content h4 {
    color: #44515e;
    margin: 30px 0 15px
}
.blog-detail-content .blog-share {
    left: 30px
}
.blog-author,
.tags-box {
    border: 1px solid #dbdddf
}
.tags-box .social-share-line a:hover,
.tags-box .tags-list a:hover {
    text-decoration: none;
    color: #ffc107
}
.tags-box {
    padding: 15px 25px;
    float: left;
    width: 100%;
    clear: both;
    margin-top: 30px
}
.tags-box .tags-list {
    width: 60%;
    float: left;
    margin: 0
}
.tags-box .tags-list a {
    padding: 5px
}
.tags-box .social-share-line {
    float: right;
    width: 40%;
    text-align: right;
    margin: 0
}
.tags-box .social-share-line a {
    padding: 5px 5px 5px 25px
}
.search-comp .search-border {
    /* padding:15px 15px 10px 15px;
    background-color: rgba(255,255,255, .5); */
    margin:0px
}

.search-comp .nav-tabs {
    border: 0;
    display: inline-block;
    overflow: hidden;
    margin-bottom: -6px
}
.search-comp .nav-tabs li {
    margin-left: -2px
}
.search-comp .nav-tabs li:last-child {
    margin-left: 0
}
.search-comp .nav-tabs li:last-child a {
    padding-right: 30px
}
.search-comp .nav-tabs li:before {
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .8);
    transform-origin: bottom right;
    transform: skew(-15deg, 0deg);
    border-right: 1px solid #fff;
}
.search-comp .nav-tabs li a {
    background-color: transparent;
    border-bottom: 0;
    border-radius: 0;
    padding: 15px 30px 15px 43px;
    font-weight: 700
}
.search-comp .search-bar>div,
.search-comp .tab-pane {
    padding: 0
}
.search-comp .nav-tabs li a:hover {
    color: #fff;
    border-bottom: 0;
    background-color:rgba(0, 0, 0, .7);
    cursor: pointer;
}
.search-comp .nav-tabs li.active:before,
.search-comp .nav-tabs li:hover:before {
    background-color: rgba(0, 0, 0, .5)
}
.search-comp .nav-tabs li.active a {
    color: #fff;
    border-bottom: 0;
    background-color: transparent
}
.search-comp .search-bar {
    padding: 0;
    margin: 0;
    font-size: 13px;
    /*overflow: hidden*/
}
.search-comp .search-bar .icon {
    color: #ffc107;
    position: relative;
    z-index: 2
}
.search-comp .search-bar .input-group-addon {
    padding: 12px 10px 12px 20px;
    background-color: #fff;
}
.search-comp .search-bar .input-group-addon:before {
    content: "";
    position: absolute;
    display: block;
    width: 20%;
    height: 100%;
    top: 0;
    right: -1px;
    z-index: 1;
    background-color: #fff;
    border-right: 1px solid #dbdddf;
    transform-origin: bottom left;
    transform: skew(-20deg, 0deg)
}
.search-comp .search-bar input {
    min-height: 41px
}
.lt-ie11 .search-comp .search-bar .form-group {
    overflow: hidden;
    border: .5px solid #dbdddf
}

.search-comp .search-bar select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #555;
    height: 41px;
    padding: 5px
}
.lt-ie11 .search-comp .search-bar select.form-control {
    width: 130%
}
.search-comp .search-bar select.form-control::-ms-expand {
    display: none
}
.search-comp .search-bar .btn {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    height: 41px;
    border: 2px solid transparent
}
.search-bar-top {
    height: auto;
    max-height: 0;
    position: absolute;
    z-index: 3;
    overflow: hidden;
    transition: max-height .25s;
    left: 0;
    top: 40px
}
.search-bar-top.open {
    max-height: 400px;
    width: 100%
}


.lt-ie11 .page-404 .page-content {
    left: 0;
    top: 0;
    position: relative;
    padding-top: 120px
}
.page-404 .page-content .error-message {
    font-size: 3rem;
    line-height: 1
}
.section-block-info .block-info h4,
.tour-block .tour-img .discount {
    font-family: Roboto, Helvetica, Arial, sans-serif
}
.section-block-info {
    margin-bottom: 100px
}
@media only screen and (min-width: 992px) {
    .section-block-info {
        width: 950px
    }
}
.section-block-info .block-info {
    text-align: center;
    border: 1px solid #dbdddf;
    display: block;
    padding: 20px 10px 25px
}
.section-block-info .block-info .icon {
    font-size: 40px;
    color: #44515e
}
.section-block-info .block-info h4 {
    color: #ffc107;
    text-transform: none;
    font-weight: 400;
    min-height: 40px;
    font-size: 20px
}
.section-block-info .block-info h4 .support-text {
    display: block;
    font-size: 13px;
    margin-top: 3px;
    color: #555
}
.section-block-info .block-info:hover {
    text-decoration: none
}
.parallax-bg {
    height: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover
}
.tour-block {
    box-shadow: 0 0 2px rgba(0, 0, 0, .1);
    transition: box-shadow .25s;
    margin-bottom: 5px;
    width:100%;
    float:left;
    border:1px solid #ddd;
}
.tour-block .tour-img {
    overflow: hidden;
    width: 100%;
    position: relative;
    min-height:190px;
    display:block;
}
.tour-block .tour-img img {
    transform: scale(1);
    transition: transform .35s ease;
    width:125%;
    position:absolute;
    height:auto;
    margin:auto;
    top:-100%;
    right:-100%;
    bottom:-100%;
    left:-100%;
}
.tour-block .tour-img:after {
    content:" ";
    width:100%;
    display: block;
    padding-bottom:62%;
    height:0;
}
.tour-block .tour-img:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, .2);
    transition: background-color .35s ease;
    display:none;
}
.tour-block .tour-img:hover:before {
    background-color: transparent
}
.tour-block .tour-img:hover img {
    transform: scale(1.05)
}
.tour-block .tour-img .discount {
    padding: 15px 10px;
    color: #fff;
    font-weight: 700;
    background-color: #ffc107;
    position: absolute;
    top: 0;
    left: 15px;
    text-align: center;
    z-index: 1;
    font-size: 17px
}
.tour-block .tour-info {
    padding:15px 15px 0;
    width:100%;
    float:left;
}
.tour-block .tour-info .tour-name {
    font-weight:700;
    text-transform:none;
    color:#44515e;
    margin:0px;
    padding:0 0 10px 0;
    line-height:20px;
    font-size:15px;
}
.tour-block .tour-info .tour-name span{ font-weight:500; font-size: 14px; display:block; }
.tour-block .tour-info .tour-name span i { padding-right:3px;}
.tour-block .tour-info .tour-price .price {
    font-size: 26px;
    color: #44515e
}
.tour-block .tour-info .tour-price .price{
    font-size: 15px
}
.tour-block .tour-info .tour-price .price.sale-off {
    text-decoration: line-through;
    margin-right: 15px;
    font-size: 20px
}
.tour-block .tour-info .bottom-info {
    width:100%;
    float:left;
}
.tour-block .tour-info .bottom-info .bottom-detail {
    padding: 10px;
    border-top: 1px solid #ddd;
    border-right: 0;
    text-align: center;
    position: relative;
    background-color:#f7941e;
    color:#fff;
}
.tour-block .tour-info .bottom-info .bottom-detail .icon {
    color: #555;
    font-size: 17px;
    margin-right: 5px
}
.tour-block .tour-info .bottom-info .bottom-detail:last-child {
    transition: background-color .25s, color .25s;
    background-color:inherit;
    color:#555;
}
.tour-block .tour-info .bottom-info .bottom-detail:last-child .icon {
    margin-left: 5px;
    margin-right: 0
}
.tour-block .tour-info .bottom-info .bottom-detail:last-child:before {
    content: '';
    position: absolute;
    display: block;
    width: 20px;
    height:100%;
    top: 0;
    left: -14px;
    z-index: 1;
    background-color: #fff;
    border-left: 1px solid #ffc107;
    transform-origin: bottom right;
    transform: skew(-15deg, 0deg);
    transition: background-color .25s;

}
.tour-block .tour-info .bottom-info .bottom-detail:last-child:hover:before,
.tour-block-list:hover .box-img:before {
    background-color:#f7941e
}
.tour-block .tour-info .bottom-info .bottom-detail:last-child:hover {
    background-color: #f7941e;
    color: #fff;
    text-decoration: none
}
.tour-block .tour-info .bottom-info .bottom-detail:last-child:hover .icon {
    color: #fff
}
.tour-block:hover {
    box-shadow: 0 0 30px rgba(0, 0, 0, .2)
}
.tour-block-list {
    margin-bottom: 30px;
    box-shadow: 0 0 2px rgba(0, 0, 0, .1);
    transition: box-shadow .25s;
    padding: 0;
    float: left;
    overflow: hidden;
    width: 100%
}
.tour-block-list:hover {
    box-shadow: 0 0 30px rgba(0, 0, 0, .2)
}
.tour-block-list:hover .box-img:after {
    transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 11deg) translate3d(-100%, 0, 0)
}
.tour-block-list .box-img {
    width: 50%;
    transition: height .25s;
    float: left;
    overflow: hidden;
    height: 228px;
    position: relative
}

.tour-block-list .box-img:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transition: background-color .3s;
    background-color: transparent;
    opacity: .15
}
.tour-block-list .box-img:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .2);
    content: '';
    transition: transform .6s ease;
    transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 11deg) translate3d(80%, 0, 0);
    z-index: 1
}
.lt-ie11 .tour-block-list .box-img:after {
    display: none
}
.tour-block-list .box-img img {
    width: 100%;
    height: 100%
}

.tour-block-list .box-content {
    width: 50%;
    height: 228px;
    float: left;
    padding: 25px;
    position: relative;
    z-index: 1
}

.tour-block-list .box-content:before {
    content: "";
    position: absolute;
    display: block;
    width: 61px;
    height: 100%;
    top: 0;
    left: -61px;
    background-color: #fff;
    transform-origin: bottom right;
    transform: skew(-15deg, 0deg);
    z-index: -1
}
@media only screen and (max-width: 510px) {
    .tour-block-list .box-content:before {
        left: auto;
        right: 0;
        height: 190px;
        top: -190px;
        width: 52px
    }
}
.lt-ie11 .tour-block-list .box-content:before {
    display: none
}
.tour-block-list .box-content .tour-name {
    color: #44515e;
    margin-top: 0
}
.tour-block-list .box-content .line-info {
    width: 100%;
    float: left;
    margin-top: -10px
}
.tour-block-list .box-content .line-info .star-rating {
    float: left
}
.tour-block-list .box-content .line-info .time-left {
    float: right
}
.tour-block-list .box-content .line-info .time-left .icon {
    color: #ffc107;
    margin-right: 5px
}
.tour-block-list .box-content .tour-price .price {
    font-size: 26px;
    color: #44515e;
    line-height: 1
}
.tour-block-list .box-content .tour-price .price.sale-off {
    text-decoration: line-through;
    margin-right: 15px;
    font-size: 20px
}
.tour-block-list .box-content .bottom-detail {
    border: 1px solid #ffc107;
    transition: color .25s, background-color .25s;
    text-transform: none;
    font-weight: 400;
    font-size: 15px;
    margin-top: 10px
}
@media only screen and (max-width: 510px) {
    .tour-block-list .box-content .line-info .time-left {
        float: left;
        width: 100%
    }
    .tour-block-list .box-content .bottom-detail {
        width: 100%;
        max-width: 200px;
        margin-left: auto;
        margin-right: auto;
        display: block
    }
}
.tour-block-list .box-content .bottom-detail .icon {
    color: #ffc107;
    margin-left: 5px
}
.tour-block-list .box-content .bottom-detail:active,
.tour-block-list .box-content .bottom-detail:focus,
.tour-block-list .box-content .bottom-detail:hover {
    background-color: #ffc107;
    color: #fff
}
.tour-block-list .box-content .bottom-detail:active .icon,
.tour-block-list .box-content .bottom-detail:focus .icon,
.tour-block-list .box-content .bottom-detail:hover .icon {
    color: #fff
}
@media only screen and (min-width: 510px) {
    .tour-block-list.content-left .box-img {
        float: right
    }
    .tour-block-list.content-left .box-content:before {
        right: 0;
        left: auto
    }

}

@media only screen and (max-width: 767px) {
    .section-block-info .block-info {margin-bottom:30px}
}
/* .pagination {
    margin: 0 5px;
    float:none;
    display:inline-block;
    vertical-align:middle;
    float:right;

} */

.image-max-h155 {
    /*max-height: 155px; */ /*With Top Header Ad*/
    max-height: 180px; /*Without Top Header Ad*/
}

/* .pagination li a, .pagination li .page-link {
    color: #555;
    background-color: #fff;
    transition: color .25s, border-color .25s background-color .25s;
    padding:4px 7px;
} */
/* .right-wrapper .pagination li a, .right-wrapper .pagination li .page-link {padding:3px 4px;} */


/* .test { position: fixed; } */
/* .pagination li a:hover, .pagination li .page-link:hover {
    color:#000000;
    border-color:#000000;
    z-index: 2;
    background-color: #edf0f5;
    cursor: pointer;
} */
/* .pagination li.active a,
.pagination li.active a:hover,
.pagination li.active .page-link,
.pagination li.active .page-link:hover{
    background-color:rgb(31,64,123);
    border-color:rgb(31,64,123);
}
.pagination li.active a, .pagination li.active .page-link {
    color: #fff
} */

.lt-ie11  {
    margin-left: -12px
}
.price {
    font-size: 32px;
    font-weight: 700;
    font-family: Roboto, Helvetica, Arial, sans-serif
}
.price {
    font-size: 15px;
    font-weight: 400
}
/* slider css */
.rs-starring .star-rating span {
    overflow: hidden;
    float: left;
    top: 0;
    left: 0;
    position: absolute;
    padding-top: 1.5em;
    font-size: 1em!important
}
.rs-starring .star-rating span:before {
    content: "\53\53\53\53\53";
    top: 0;
    position: absolute;
    left: 0
}
.rs-starring .star-rating {
    color: #FFC321!important
}
body.rtl .tp-kbimg {
    left: 0!important
}
.margintop10
{margin-top:10px}
.closeicon{
    position: absolute;
    color: black;
    right: 44px;
    z-index: 2;
    top: 4px;
}
.bordernone li{border:none !important;}
.breadcrumb1 {
    padding: 0px;
    margin-bottom: 0px;
    list-style: none;
    border-radius: 4px;
}
.breadcrumb1 > li {
    display: inline-block;
}
/* .breadcrumb1 > li + li::before {
    padding: 0 5px;
    color: #ccc;
    content: "\E006";
    font-family: myfont;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    text-decoration: none;
    text-transform: none;
    line-height: 1;
    speak: none;
    vertical-align: middle;
} */
.breadcrumbheader {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 0px;
    padding-left: 8px;
}
.btn-plane {
    background-color: transparent;
    color: #555555 !important;
    border: 1px solid #ffc107 !important;
}
.marginleft5px
{margin-left: -5px;}
/* .btn:hover {
    background-color:rgb(31,64,123);
    color: #FFF !important;
} */
/* map Start*/
/* .map-company {
    height: 650px;
} */
/* map End*/
/* Banner crousel css */

.banner-top-subtitle {
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    font-size: 4vh;
    margin-bottom: 10px;
    font-weight: 500;
    position: absolute;
    width: 100%;
    z-index: 3;
    top: 5%;
    left: 0px;
}
.banner-top-title {
    /* text-transform: uppercase; */
    margin: 0;
    color: #fff;
    /* text-align: center; */
    text-align: left;
    font-weight: 500;
    font-size: 7vh;
    position: absolute;
    width: 100%;
    z-index: 3;
    top: 10%;
    left: 0px;
}


.banner-top-price .icon {
    display: block;
    text-align: center;
}
.bouncing {
    animation: bouncing 1s infinite;
}
.banner-top-price {
    color: #fff;
    font-size: 3vh;
    position: absolute;
    width: 100%;
    top: 25%;
    left: 0;
}
span.price-number {
    position: absolute;
    width: 100%;
    text-align: center;
}
.banner-top-price span.price-number {
    left: 0;
}

.property-li li {
    float:  left;
    list-style:none;
}
/* detail page */
.slider-hotels {
    padding: 0 0 25px;
}
.slider-hotels .carousel-hotel-big.owl-carousel {
    position: relative;
    padding: 0;
}
/* .owl-carousel.owl-loaded {
    display: block;
}
.owl-carousel {
    position: relative;
}
.owl-carousel {
    width: 100%;
    overflow: hidden;
    z-index: 1;
}
.owl-carousel, .owl-carousel .owl-item, html {
    -webkit-tap-highlight-color: transparent;
}
.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0);
} */
/* .owl-carousel .owl-stage::after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}
.owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-touch-callout: none;
} */
.slider-hotels .carousel-hotel-big.owl-carousel .bxslider-item {
    width: 100%;
}
.owl-carousel .owl-item img {
    display: block;
    width: 100%;
}
.slider-hotels .carousel-hotel-dots .owl-item.selected .thumbnail-item::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid #ffc107;
    background-color: rgba(255, 173, 9, .3);
}

/* property detail heading */
.heading-comp {
    position: relative;
    padding-top: 0px;
}
.heading-comp .heading-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    overflow: hidden;
    background-size: cover;
    background-position: right;
}
.heading-comp.bg-overlay-light .bg-overlay  {background-color:#f7f7f7; border-bottom:1px solid rgb(221, 221, 221);}
.heading-comp.bg-overlay-light  .heading-content.bg-overlay { color:rgb(63, 63, 63);}
.heading-comp.bg-overlay-light .inner_dl_tab {background:#f2eeea;}
.heading-comp.bg-overlay-light .inner_dl_tab ul.property_nav li { color:#000;}
.heading-comp.bg-overlay-light .inner_pd_sc a,.heading-comp.bg-overlay-light .inner_pd_sc span { color:#000;}

.heading-comp .heading-content {
    position: relative;
    z-index: 1;
    color: #fff;
    padding: 0;
}
.heading-comp .heading-content h2{
    padding: 10px;
    margin: 0px;
    color: #fff;
    font-size: 2vh;

}
.heading-comp .heading-content .breadcrumd {
    padding: 0;
    margin: 0;
}

/*----*/
/* .leaflet-container {
    height: 500px;
    width: 100%;
    margin: 0 auto;
} */

.no-link-effect, .no-link-effect:hover, .no-link-effect:active, .no-link-effect:focus {
    text-decoration: none;
    color: inherit;
}

/* .right-wrapper {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    height: 100%;
    overflow: auto;
    z-index:1;
} */
/* .right-wrapper .right_content { width:100%; display:inline-block;  position:relative;}
.right-wrapper .right_content.not_listing_cnt + .search_pad_footer .footer.search-footer { position:absolute;}

.right-wrapper.list-full { width:100% !important; z-index:inherit;}
.right-wrapper.list-full .right_content{z-index:1;}
.right-wrapper.list-full .sticky { width:100% !important; z-index:90 !important; }
.right-wrapper.list-full .pagination { margin:0px; z-index:9999 !important; }
.right-wrapper.list-full .sticky_pagination { z-index:98!important;}
.left-wrapper {z-index:98; position:absolute; width: calc(100% - 500px);  top: 0px;  left:-100%;  bottom: 0;  height: 400px;  border-right:1px solid #dbdddf;  } */
#tlc-popover-trigger-click {  z-index: 9999;  }
/* .propcount-wrapper {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #fff;
    z-index: 999;
    padding: 5px 7px;
    border-radius: 3px;
    font-size: 14px;
    border: 2px solid rgba(0,0,0,0.2);
}
.searchmap-chk {
    left: calc(50% - 100px);
} */

.tag-label {
    /* font-weight: normal; */
    font-weight: bold;
    text-transform: initial;
}
/* .tags .tag, .tags .tagdisabled {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    padding: 7px 20px 7px 10px;
    display: inline-block;
    background-color: #edf0f5;
    position: relative;
    margin-bottom:5px;
    /*margin-right: 5px;*/
    /*margin-right:5px;
    transition:background-color .2s, color .2s
} */
.tags .tag, .tags .tagdisabled {
    font-size: 10px;
    padding: 7px 10px 7px 10px;
    display: inline-block;
    position: relative;
    /* margin-bottom: 10px;
    margin-right: 10px; */
    margin: 7px;
    border-radius: 10px;
    box-shadow: inset 0 0.5px 0.5px 0 rgba(0,0,0,0.14), 0 2px 4px 0 rgba(0,0,0,0.5);
}
.tags .tagdisabled {
    cursor: default;
    padding-left: 10px;
}
.tags .tag:after, .tags .tagdisabled:after
{
    /*background: #fff;*/
    background: transparent;
    content: '';
    position: absolute
}
.tags .tag:before, .tags .tagdisabled:before {
    border-radius: 10px;
    box-shadow: inset 0 1px rgba(0, 0, 0, .25);
    height: 6px;
    left: 10px;
    width: 6px;
    top: 13px
}
.tags .tag:after, .tags .tagdisabled:after {
    border-bottom: 16px solid transparent;
    border-left:0 solid #edf0f5;
    border-top: 16px solid transparent;
    /*right: 0;*/
    right: -13px;
    top: 0;
    transition: border-left-color .2s
}
.tags .tag:hover {
    color: #EB431C;
    /* background-color: #f7951f; */
    text-decoration: none;
    cursor: pointer;
}
.tags .tag:hover:after {
    border-left-color: #f7951f
}
.tagsdark .tag, .tagsdark .tagdisabled {
    background-color: #ddd;
}
.tagsdark .tag:after, .tagsdark .tagdisabled:after {
    border-left:0 solid #ddd;
}

/*----*/
.li-active{
    border-bottom: 2px solid #ffc107;
}
.gallery-top-margin{
    margin-top : 53px;
}
.position-absolute{
    position: absolute;
}
.position-fixed{
    position: fixed;
    width: 100%;
}
.list-span{padding-left:10px; word-wrap:break-word;}
.tempclass{width: 100%;
    position: relative;
    top: 244px;}
.sectionsetting{
    background-color: #fff;
    /*position: fixed;*/
    position: relative;
    width: 100%;
    z-index: 999;
    display: none;
}
.fixed-breadcrum{    background-color: #fff;
    z-index: 999;
    position: fixed;
    width: 100%;
    top: 175px;
    left: 0px;}
.margin-top-220{    padding-top: 60px; }
.padding0px{padding: 0px !important}

/*----*/
/* .main-fixed {
    padding-top:47px;
} */


.header-fixed {
    position: fixed;
}
.header-nonfixed {
    position: relative;
}
.padding-lr-0 {
    padding-left: 0;
    padding-right: 0;
}
.margin-bottom-10 {
    margin-bottom: 10px;
}
.search-map-list .hotel-info {
    padding: 5px;
}
.search-map-list .block-hotel .hotel-info .hotel-name {
    margin: 0px 0 5px;
    font-size: 14px;
}
.search-map-list .block-hotel .hotel-info .hotel-name span {
    font-size: 12px;
}
.search-map-list .openhousefacility_list {
    padding: 0 0 4px 0;
}
.search-map-list .openhousefacility_list .star-rating {
    font-size: 12px;
}
.search-map-list .property_price {
    font-size: 13px;
}
.search-map-list a:hover {
    color: inherit;
}
/* .no-photo-overlay {
    left: 0;
    background: rgba(0,0,0,0.5);
    width: 100%;
    z-index: 2;
    position: absolute;
    bottom: 0;
    padding-top: 9px;
    padding-bottom: 9px;
    text-align: center;
    color: #ffffff;
} */
.btn-save-search-wrapper {
    display: inline-block;
    padding-top: 1px;
    margin-top: 4px;
}
.btn-save-search {
    background-color: #f7951f;
    border: 1px solid #dca80a;
}
.btn-save-search:hover {
    border: 1px solid #d58512;
}
.panel-group .panel {
    border-radius: 0;
}
.panel {
    border-radius: 0;
    border: 0;
    box-shadow: none;
}
.panel-heading .panel-title {
    cursor: pointer;
}
.panel-heading .panel-title a {
    text-decoration: none;
    width: 100%;
    display: block;
}
.panel-heading .panel-title a:hover {
    color: inherit;
}
.panel-default > .panel-heading {
    background-color: white;
    border:0;
    padding: 10px 0;
}
.chart_bg {
    background: #f8931f;
    float: right;
    margin: 2px 0 0 7px;
    width: 20px;
    height: 20px;
    border-radius: 50px;
}
.highcharts-credits {
    display: none;
}
.float-right {
    float: right;;
}
/***Notify css**/
.notify-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-start;
    align-content: flex-start;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 999999;
    transform: translate(-50%, -50%);
}

.notify-item {
    width: 250px;
    margin: 5px 10px;
    color: #FFF;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgb(15, 15, 15);
}

.notify-item:hover {
    opacity: 0.95;
    box-shadow: 0 0 10px 0 rgb(15, 15, 15);
}

.notify-item > p {
    /*font-family: 'Lora', serif;*/
    margin: 10px;
    opacity: .9;
}

.notify-item.success {
    background-color: rgba(81, 163, 81, 0.9);
}

.notify-item.error {
    background-color: rgba(203, 100, 94, 0.9);
}

.notify-item.info {
    background-color: rgba(33, 150, 243, 0.9);
    /*background-color: rgba(240, 173, 78, 0.9);*/
}

.notify-title {
    font-weight: 700;
}
/*****/

.sound-score-map-div{
    height:395px;
    width: 100%;
}

.autocomp {
    /*border: 1px solid #ccc;*/
    color: #555;
    /*min-height: 41px;*/
    padding-top: 0;
}

.autocomp .dropdown-menu {
    border-radius: 0;
    padding: 0;
}
.autocomp .dropdown-menu .dropdown-item {
    white-space:inherit;
    padding:3px 5px;
    font-weight: 400;
    color: #7f7f7f;
    clear: both;
    display: block;
    line-height: 1.42857143;
}
.autocomp .dropdown-menu .dropdown-item:focus, .autocomp .dropdown-menu .dropdown-item:hover {
    color: #727272;
    text-decoration: none;
    background-color: #f5f5f5;
}
.autocomp .dropdown-header, .autocomp .dropdown-menu>.disabled>a, .autocomp .dropdown-menu>.disabled>a:focus,
.autocomp .dropdown-menu>.disabled>a:hover, .autocomp .nav>li.disabled>a {
    color: #ccc;
}
.autocomp .rbt-aux {
    margin-top: -32px;
    margin-right: 0px;
}
.autocomp.homesearch {
    min-height: 41px;
}
.autocomp.homesearch .rbt-input-main {
    max-width:204px;
}
.autocomp.search {
    width: 80%;
    padding-top: 0;
    padding-left: 5px;
}
.autocomp.search .rbt-input-main, .autocomp.search .rbt-input-hint {
    max-width:265px;
}
.autocomp.search .rbt-aux {
    margin-top: -23px;
    margin-right:-12px;
}

.autocomp.search .dropdown-menu {
    transform: skew(0deg, 0deg);
    left: -12px;
}
/* .carousel .slide {
     background: #ddd;
    border-bottom: 1px solid #ddd;
} */
.carousel.carousel-slider .control-arrow {
    padding: 15px;
}
.carousel .thumbs-wrapper {
    margin: 0;
}
.carousel .thumbs {
    margin-bottom: -6px;
    text-align: center;
}
.carousel .thumb {
    border: 2px solid #fff;
    padding: 0;
    margin-right: 1px;
}
.carousel .thumb.selected, .carousel .thumb:hover {
    border: 2px solid #555;
    padding: 0;
}
.carousel.carousel-slider .control-arrow.control-next:hover {
    background: rgba(0,0,0,0);
    background-image: linear-gradient(90deg,rgba(0,0,0,.0001) 0,rgba(0,0,0,.5));
    background-repeat: repeat-x;
}
.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 0 solid transparent;
    border-bottom: 0 solid transparent;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 22px;
}
.carousel .control-next.control-arrow:before {
    border-left: 0px solid #fff;
    /* content: "\f054"; */
    /* content: "\e903"; */
    /* font-family: 'icomoon' !important; */
}
.carousel .control-prev.control-arrow:before {
    border-right: 0px solid #fff;
    /* content: "\f053"; */
    /* content: "\e903"; */
    /* font-family: 'icomoon' !important; */
    /* -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1); */
}
.carousel.carousel-slider .control-arrow.control-prev:hover {
    background: rgba(0,0,0,0);
    background-image: linear-gradient(90deg,rgba(0,0,0,.5) 0,rgba(0,0,0,.0001));
    background-repeat: repeat-x;
}
.roundbadge {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    font-size: 20px;
    padding-top: 11px;
}

.fav_info_iconbox {
    cursor: pointer;
}

.fav_info_iconbox .heart_icon { color:#fff; display: inline-block; vertical-align:middle; margin:0 0px 0 7px; font-size:16px;}
.fav_info_iconbox .heart_icon:hover,.fav_info_iconbox .heart_icon.active {color:#F7941E;}

.fav_info_iconbox .bookmark_icon {display: inline-block; vertical-align:middle; color:#fff; margin:0 0px 0 7px; font-size:16px;}
.fav_info_iconbox .bookmark_icon:hover,.fav_info_iconbox .bookmark_icon.active {color:#F7941E;}

/***Walkscore***/
#ws-walkscore-tile{position:relative;text-align:left}
#ws-walkscore-tile *{float:none;}

/*****/

.tabpanel .panel-body {
    padding: 0;
}
.tabpanel .panel-body .nav-tabs {
    margin: 0;
}
.tabpanel .nav-tabs {
    margin: 0;
}
.tabpanel .panel-body .nav-tabs>li>a {
    /* padding: 15px 20px; */
    padding: 15px;
    border-radius: 0;
    text-transform: none;
}
.separator-light {
    margin: 20px 0 20px;
    border-bottom: 1px solid #eee;
}
.textbox {
    border: 1px solid #ddd;
    padding: 5px 10px;
    box-shadow: none;
}
.textbox.textbox_height {
    min-height: 40px;
}

.textbox.selected, .textbox:focus {
    border: 1px solid #b2b2b2;
}
.textbox-large {
    min-height: 46px;
    padding: 0px 20px 0px 20px;
}
.margin-bottom-15 {
    margin-bottom: 15px;
}
.margin-bottom-20 {
    margin-bottom: 20px;
}
.fileContainer{
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0);
    align-items: left;
    justify-content: left;
}
.fileContainer .deleteImage {
    background: #f7941e;
}
.fileContainer p {
    display: block;
    width: 50%;
}
.fileContainer .errorsContainer {
    display: block;
    width: 50%;
}
.fileContainer .chooseFileButton {
    background: #f7941e;
    border-radius: 0;
    display: block;
    width: 185px;
}
.fileContainer .chooseFileButton:hover {
    background: #f7941e;
}
.fileContainer .uploadPicturesWrapper {
    width: 40%;
    justify-content: right;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;
    max-height: 120px;
}
.fileContainer .uploadPictureContainer {
    width: 60%;
    padding: 0px;
}
.bottom-row {
    margin: 0 -15px 0 -15px;
    padding: 10px;
    width: 100%;
    background-color: #ddd;
    width: -moz-calc(100% + 30px);
    width: -webkit-calc(100% + 30px);
    width: -o-calc(100% + 30px);
    width: calc(100% + 30px);
}
.profile_save_bt { padding:10px 7px; background-color:#ddd; }
/* .addclient_info .form-control { height:35px !important;} */
.client_address_info .textbox-large { min-height:40px; }
.client_address_info .textbox-large.commute-search { padding-top:4px; }
/*.btn-default {border-color: #f76b1c;}
.btn-default.active, .btn-default.focus, .btn-default:active,
 .btn-default:focus, .btn-default:hover, .open>.btn-default.dropdown-toggle {
    background-color: #f7941e;
   border: 1px solid #dca80a;
}*/
/* .dropdown {
    width: 100%;
    border-radius: 0;
    -webkit-box-shadow: inherit;
    box-shadow: inherit;
    border: 1px solid #ddd;
} */
.dropdown .react-select-box-label, .dropdown .react-select-box-option {
    color:#555;
    font-size:13px;
}
.dropdown .react-select-box-option-selected {
    background-color: #f4f4f4;
}
.dropdown .react-select-box-options {
    padding: 0;
    border-radius: 0;
    width: 100%;
    z-index: 1000;
    max-height: 212px;
    overflow-y: auto;
}
.margin-left-10 {
    margin-left: 10px;
}
.dropdown .react-select-box-clear:before,
.dropdown .react-select-box-clear:hover:before,
.dropdown .react-select-box-clear:focus:before {
    background-color: inherit;
}
.dropdown .react-select-box-option:hover {
    color: #555;
    text-decoration: none;
}
.dropdown .react-select-box:before {
    z-index: 9;
}
.dropdown .react-select-box-clear:before {
    position: absolute;
    background-color: inherit;
    top: 1px;
    right: 0px;
    content: "\F107";
    font-family: "FontAwesome";
    font-size: 16px;
    color: #555;
    border: none !important;
}
.dropdown.compact .react-select-box {
    padding: 6px 0;
}
.dropdown.compact .react-select-box:before {
    top: 4px;
    right: 25px;
}
.dropdown.compact .react-select-box-label {
    padding: 0 5px;
}
.dropdown.compact .react-select-box:after {
    top: 15px;
    right: 7px;
}
.dropdown.compact .react-select-box-clear {
    top: 6px;
    width: 30px;
}
.dropdown.compact .react-select-box-clear::before {
    top: 1px;
    right: 0px;
}

.dropdown.semicompact .react-select-box {
    padding:9px 0 8px 0;
}
.dropdown.semicompact .react-select-box:before {
    top: 8px;
    right: 25px;
}
.dropdown.semicompact .react-select-box-label {
    padding:0 10px 0 10px;
    font-weight:normal;
}
.dropdown.semicompact .react-select-box:after {
    top: 15px;
    right: 7px;
}
.dropdown.semicompact .react-select-box-clear {
    top: 8px;
    width: 30px;
}
.dropdown.semicompact .react-select-box-clear::before {
    top: 1px;
    right: 0px;
}

*:focus {outline: none;}
.fontsize_text {font-size:14px; line-height:20px; }
.gallery_text {font-size:14px; line-height:20px; }
.basic_information label { margin-bottom:0px;}
.basic_information .form-group { margin-bottom:15px;}
/* .map-company {width:100%; float: left; margin-bottom:15px;} */
.panel_text {font-size:14px; line-height:20px; margin-top:10px;}

.nb-text {padding: 0 32px 15px;text-align: right;font-size: 13px;font-style: italic;display: block; width: 100%; color: #4c4c4c;}

/**my agent profile page***/
.agent_profilepage{ min-height:357px;}
.agent_profile_photo {width:80px; background:#fff;  z-index:99; position:relative; margin:20px auto 0; height:80px; border-radius:50px; }
.agent_profile_photo img{border-radius:50px; width:80px;height:80px; border:1px solid #ddd;}
.agent_detailbox {width:100%; position:relative;  float:left; background:#4c4c4c;}
.agent_detailbox:after { position:absolute; top:0; left:0; content:""; width:100%; min-height:70px; background:#f7951f; -webkit-box-shadow: -2px 3px 5px -2px rgba(0,0,0,0.21);
    -moz-box-shadow: -2px 3px 5px -2px rgba(0,0,0,0.21); box-shadow: -2px 3px 5px -2px rgba(0,0,0,0.21); }
.agent_detailbox .profile_info{ width:100%; float:left; padding:12px;color:#fff;  text-align:center;}
.agent_detailbox .profile_info .profile_name{font-size:20px; width:100%; float:left; padding:0 0 5px 0; line-height:normal;}
.agent_detailbox .profile_info .profile_name span { display:inline-block; vertical-align:middle; padding:0 2px;}
.agent_detailbox .profile_info .profile_email { width:100%; float:left; padding:0 0 9px 0; font-size:13px; border-bottom:1px solid #737373;}
.agent_detailbox .profile_info .profile_phone{width:100%; float:left; font-size:15px;  padding:15px 0 15px 0; }
.agent_detailbox .profile_info .profile_phone span {display: inline-block; vertical-align:middle;}
.agent_detailbox .profile_info .profile_phone i { color:#f7951f; font-size:14px; padding:0 7px 0 0; }
.agent_detailbox .profile_info .profile_office {width:100%;  font-size:15px; float:left; padding:15px 0 15px 0;  border-top:1px solid #737373;}
.agent_detailbox .profile_info .profile_office span {display: inline-block; vertical-align:middle;}
.agent_detailbox .profile_info .profile_office i {color:#f7951f; font-size:14px; padding:0 7px 0 0; }

/******clients profile page******/
.ct_profile_left {width:100%; float:left;}
.ct_profile_left .ct_profile_photo {width:100%; float:left; text-align:center;}
.ct_profile_left .ct_profile_photo .profile_photo {width:80px; z-index:99; background:#fff;  position:relative; margin:20px auto 0; height:80px; border-radius:50px; }
.ct_profile_left .ct_profile_photo .profile_photo img {border-radius:50px; width:80px;height:80px; border:1px solid #ddd;}
.ct_profile_left .ct_profile_name {font-size:22px; text-align: center; color:#fff; width:100%; float:left; padding:15px 0 5px 0; line-height:20px;}
.ct_profile_left .ct_profile_email{font-size:13px; text-align: center; color:#fff; width:100%; float:left; padding:5px 0; line-height:normal;}
.ct_profile_left .ct_profile_mlsnumber{font-size:15px; text-align: center; color:#fff; width:100%; float:left; padding:0 0 5px 0; line-height:normal;}
.ct_profile_left .ct_profile_info {width:100%; float:left; padding:0 12px 12px 12px;color:#fff;}
.ct_profile_left .com_profile_box {width:100%; float:left; font-size:14px;  padding:13px 0 13px 0; border-top:1px solid #737373;}
.ct_profile_left .com_profile_box .com_subprofile_box { padding-bottom:5px;}
.ct_profile_left .com_profile_box .com_subprofile_box:last-child { padding-bottom:0px; }
.ct_profile_left .com_profile_box .icon_box {width:20px; float:none; text-align:center;  display:inline-block; vertical-align:middle;}
.ct_profile_left .com_profile_box .icon_box .fa-6 { font-size:20px; }
.ct_profile_left .com_profile_box .com_profile_text { width:auto; float:none; display:inline-block; padding:0 0 0 11px;  vertical-align:middle;font-weight:normal;font-size: 14px;line-height:normal;}
.ct_profile_left .com_profile_box .com_profile_number { width:50px; float:right; vertical-align:middle; word-break:break-all;}
.dropdown .react-select-box-label {position:relative; z-index:99; padding:0 3px 0 20px;}

/******property detail page******/
.detail_slider .carousel .slide img{position:absolute;width:100%;height:auto;margin:auto;top:-101%;right:-100%;bottom:-105%; left:-100%;}
.detail_slider .carousel .thumb {position:relative; cursor:pointer; width:80px; height:61px;}
.detail_slider .carousel .thumb img{position:absolute; width:150%; height:auto;margin:auto; top:-100%;right:-100%;bottom:-100%; left:-100%;}
.detail_slider .carousel .thumb:before {width:100%;padding-bottom:4%;display:block;content:"";}

.detail_slider .carousel .slide:before {width:100%;padding-bottom:54%;display:block;content:"";}
.detail_slider .carousel .control-arrow {padding: 15px; height: 200px; margin-top: -100px; top: 50%;}
.detail_slider .carousel + .carousel .control-arrow { opacity:1;  height:auto; top:10%;  margin-top:0; padding:15px 5px 15px 15px;}
.toggle_header_left {width:auto; cursor:pointer; display:inline-block; vertical-align:middle; }
.toggle_header_left span i {margin-right: 8px;}
/* .toggle_header_right {width:auto; padding:0 30px 0 0; float:right; vertical-align:middle; cursor:pointer; display:inline-block; text-align:right;} */
.panel-arrows .panel-heading .panel-title a.collapsed:after{
    /* font-family:'FontAwesome'; 
    line-height:normal;  */
    position:absolute;
    top:25px; 
    right:20px; 
    content:url(../img/svg/true-plus.svg);
    /* content: "\f107"; 
    color:#000;  */
    font-size:16px;
    filter: invert(100%);
}
.panel-arrows .panel-heading .panel-title a:after{
    /* line-height:normal;  
    font-family:'FontAwesome';  */
    position:absolute;
    top:20px;
    right:20px; 
    /* background:url(../img/svg/true-plus.svg) no-repeat right;  */
    /* content: "\f106";  */
    content:url(../img/svg/true-minus.svg);
    /* color:#000;  */
    font-size:16px;
}
.panel-arrows .panel-heading .panel-title { position:relative;}
.extra_fields {width:100%; float:left; text-align:left; font-size:10px; color:#A0A0A0; margin:0 0 -20px 0; }

.icon_detail_fav { position: absolute; right:40px; top:10px; }
.full_slider.is_openhouse .icon_detail_fav { top:17px;}
.icon_detail_fav .div_icon .fa {-webkit-text-stroke-width:2px; -webkit-text-stroke-color:rgba(74,74,74, 1);}
.icon_detail_fav i{color: white; font-size: 23px; padding-left:0px; padding-top: 2px; cursor: pointer;}
.icon_detail_fav .div_icon{ text-align:center; /*background: rgba(0, 0, 0, 0.7); padding:10px;*/ padding:5px; border-radius: 1px; width:35px; height:35px; display: inline-block; margin-left: 5px; vertical-align: top;}
.icon_detail_fav .div_icon > div { float:none;}
.icon_detail_fav .div_icon .active i {color:var(--theme-secondary-color, #fbb040); padding-left:0px; padding-top: 2px;}

.btn-edit {margin-left: 5px;font-size: 12px;cursor: pointer}
.update_note {width:100%; float: left; padding:8px; font-size: 13px; margin: -10px 0px 0 0px; color:#555; line-height:normal; background-color:#eee;}

/* Timeline Feed */



/******/
.border-error {border-color: #a94442;  }
.label-error {float:right;font-size:12px;margin:8px 8px 0;color:#a94442;  }
.done-icon-ws {position: relative;color:#46be8a;}
.done-icon-we {position:relative;color:#e9595b;}
.done-icon-o {font-size:100px;}
.done-icon-y {position:absolute;top:38px; left:calc(50% - 25px);font-size:50px;}
.done-icon-n {position:absolute;top:38px; left:calc(50% - 10px);font-size:50px;}
.multi-step .footer-buttons {margin-top:20px;padding:0 17px; float:left; width:100%;  }
.multi-step .footer-buttons .btn-next, .multi-step .footer-buttons .btn-prev {background-color:#f7951f;color:#fff;}
.btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {outline:inherit;outline-offset:0;}
/**notes css**/
.notes_sidebar .chat_mainbox { width:100%; float:left; margin:0 0 10px 0;}
.notes_title{width:100%;  float:left; text-align:center; font-weight:600; margin:0 -20px 10px -20px; padding:5px 20px; background:#ddd;  color:#555; font-size:14px; width:-moz-calc(100% + 40px); width:-webkit-calc(100% + 40px); width:-o-calc(100% + 40px);  width:calc(100% + 40px);}
/**notes right css**/

/*.chat_right .chat_time { width:100%; float:left; padding:5px 0 0px 0; margin:0 0 10px 0;   font-size:9px; text-align:center;}
.chat_right .chat_time span { position:relative; width:130px; display:inline-block;}*/
/*.chat_right .chat_time span:after { position:absolute; left:-57%; top:6px; width:50%;  content:""; height: 1px; background:#ddd; }*/
/*.chat_right .chat_time span:before { position:absolute; right:-57%; top:6px; width:50%;  content:""; height: 1px; background:#ddd; }*/
/*.chat_right {float:right; width:100%; padding:0; margin:0 0 15px 0;}
.chat_right .chat_right_author { width:100%; text-align: right; float:right; padding-right:40px; font-weight:600; color:#555; font-size:11px; margin:0 0 3px 0; }*/
/*.chat_right .chat_right_mes {padding:7px 0 7px 10px; display:table;  min-height:20px;  width:100%; text-align:left; position:relative;   float:right; font-size:13px; background:#fbefe1; border-radius:3px; color:#000;margin-top:17px;border: 1px solid #fbefe1;}*/
/*.chat_right .chat_right_mes .chat_box{display:table; min-height:20px; float:left; padding:0 35px 0 0; max-width:100%; width: 100%; cursor: pointer;}*/
/*.chat_right .chat_right_mes .chat_box span{display:table-cell; vertical-align:middle; }*/
.chat_right .chat_right_mes .chat_profile_round{ position:absolute;top:-18px; right:-10px;   /*-webkit-box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.75);-moz-box-shadow:0px 0px 5px 0px rgba(50, 50, 50, 0.75);
    box-shadow:0 0 5px 0 rgba(50, 50, 50, 0.75);*/ border:1px solid #e4e4e4;  width:40px;height:40px;background:#fff; border-radius:100%;float:right;color:#fff;font-size:14px;text-align:center;line-height:40px;margin:0px;}

/*.chat_right .chat_right_mes .chat_profile_round.text_bg {background:#4c4c4c;}*/
.chat_right .chat_right_mes .chat_profile_round img{ float:left; width:100%; border-radius:100%;}
.chat_right .chat_right_mes .remove_options {float:right; padding:0 35px 0 0;}
.chat_right .chat_right_mes .remove_options .fa {font-size:13px; color:#787979; cursor:pointer; }
.chat_right .chat_right_mes .remove_options .fa:hover{color:#393c3d}
.chat_right .chat_right_mes .remove_options .remove_box {float:left; padding:0 5px;}
/**notes left css**/
/*.chat_left .chat_time { width:100%; float:left;  font-size:9px; padding:5px 0 0px 0; margin:0 0 10px 0; text-align:center; }
.chat_left .chat_time span { position:relative; width:135px; display:inline-block;}*/
/*.chat_left .chat_time span:after { position:absolute; left:-50%; top:6px; width:50%;  content:""; height: 1px; background:#ddd; }*/
/*.chat_left .chat_time span:before { position:absolute; right:-50%; top:6px; width:50%;  content:""; height: 1px; background:#ddd; }*/
/*.chat_left {float:left; width:100%; padding:0; margin:0 0 15px 0;}
.chat_left .chat_right_author { width:100%; text-align:left; float:left; padding-left:40px; font-weight:600; color:#555; font-size:12px; margin:0 0 3px 0;}*/
/*.chat_left .chat_right_mes {padding:7px 10px 7px 0; display:table; min-height:20px;  width:100%; text-align:left; position:relative;float:left; font-size:13px; background:#eeecec; border-radius:3px; color:#000;margin-top:17px;}*/
/*.chat_left .chat_right_mes .chat_box{display:table; min-height:25px; float:left; padding:0 0 0 40px; max-width:100%;}
.chat_left .chat_right_mes .chat_box span{display:table-cell; vertical-align:middle; }*/
/*.chat_left .chat_right_mes .chat_profile_round{position:absolute;top:-18px; left:-10px; border:1px solid #eeecec; width:40px;height:40px;background:#fff; border-radius:100%;float:left;color:#fff;font-size:14px;text-align:center;line-height:40px;margin:0px;}*/
.chat_left .chat_right_mes .chat_profile_round img{ float:left; width:100%; border-radius:100%;}
.chat_right .chat_right_mes .chat_box:hover .chat-action-btns {display: block !important;}    
.chat_left .chat_right_mes .remove_options {float:right; padding:0 10px 0 0;}
.chat_left .chat_right_mes .remove_options .fa {font-size:13px; color:#787979; cursor:pointer; }
.chat_left .chat_right_mes .remove_options .fa:hover{color:#393c3d}
.chat_left .chat_right_mes .remove_options .remove_box {float:left; padding:0 5px;}

.propdetail_sidebar .notes_sidebar .sidebar-title {margin:0 -20px 0 -20px; width:-moz-calc(100% + 40px); width:-webkit-calc(100% + 40px); width:-o-calc(100% + 40px);  width:calc(100% + 40px);}
/*.chat_repeater{ margin-top:-31px;}*/
/**notes action buttons css**/

/*.chat-action-btns {position: absolute; top: 7px; right: 30px; background: #fbefe1; padding-right: 5px; display: none !important;}
.chat-action-btns i {margin-left:5px;}*/

.btn-cancel-notes {max-width: 40px !important; border-right: 1px solid #fff !important;}
.input_rpad85 input[type="text"] {padding-right:85px !important;}
.btn-width80 {width: 80px !important;}
.btn-max40 {max-width: 40px !important;}
/****/
/* .leaflet-right .leaflet-control {margin-right:50px;} */
.map-list-toggle {border-radius:7px 0 0 7px ; bottom:0; position:absolute; right:0px;  top:0px; width:36px; z-index:999;}
.map-list-toggle .map-list-toggle-button {width:100%; height:50px;  background-color:rgba(255,255,255,.8);  cursor:pointer; font-size:30px;  text-align:center; }
.map-list-toggle .map-list-toggle-button.left{position:relative; border-radius:0 0 0px 10px; border-left:1px solid rgba(0,0,0,.20); border-top:1px solid rgba(0,0,0,.20); border-bottom:1px solid rgba(0,0,0,.20);}
/*.map-list-toggle .map-list-toggle-button.left:after{ width:10px; height:10px; background:url(../img/map_toggle_bottom_right.png)no-repeat right;  position:absolute; bottom:-10px; right:0;  content:""; }*/
/*.map-list-toggle .map-list-toggle-button.right:before{width:10px; height:10px; background:url(../img/map_toggle_top_right.png)no-repeat right;  position:absolute; top:-9px; right:0;  content:""; }*/
.map-list-toggle .map-list-toggle-button.right{border-radius:10px 0 0 0; border-top:1px solid rgba(0,0,0,.20); border-left:1px solid rgba(0,0,0, .20); }
.map-list-toggle .map-list-toggle-button .fa {color:#AAA; font-size:45px; line-height:50px;}
.map-list-toggle .map-list-toggle-button.inactive .fa{color:#ccc; cursor:default;}
.left-wrapper.list-full { border:none; z-index:78;}
.left-wrapper.list-full .map-list-toggle {background-color:rgba(255,255,255,1); border-radius:inherit;}
.left-wrapper.list-full .map-list-toggle .map-list-toggle-button.left {border-radius:0px 0px 7px 0px ; border-right:1px solid rgba(0,0,0,.20); border-left:none;}
/*.left-wrapper.list-full .map-list-toggle .map-list-toggle-button.left:after{ width:10px; height:10px; background:url(../img/map_toggle_bottom_left.png)no-repeat right;  position:absolute; bottom:-10px; left:0;  content:""; }*/
.left-wrapper.list-full .map-list-toggle .map-list-toggle-button.right {border-radius:0px 7px 0px 0px ; border-left:none; border-right:1px solid rgba(0,0,0,.20);}
/*.left-wrapper.list-full .map-list-toggle .map-list-toggle-button.right:before{ width:10px; height:10px; background:url(../img/map_toggle_top_left.png)no-repeat right;  position:absolute; top:-9px; left:0;  content:""; }*/
.map_arrow_btn { width:35px; position:absolute; right:-1px;  top:170px;}
.list-full .map_arrow_btn {right:1px; top:250px;}
/**Full Map**/
.right-wrapper.map-full { width:0px !important;}

/**half  list**/

.right-wrapper.list-col-2 .padding-lr-0{ padding-left:7px; padding-right:7px;}
/*.right-wrapper.list-col-2 .sorting_option {width:auto; float:right;}*/
.right-wrapper.list-col-2 .sorting_optionbox {width:auto;}
.right-wrapper.list-col-2 .sorting_optionbox .react-select-box-container.low_high_icon {width:170px !important;}
.right-wrapper.list-col-2 .sorting_optionbox .react-select-box-container.photo_icon{width:130px !important;}
.right-wrapper.list-col-2 .sorting_resultsnumber { margin:15px 0 0 0;}
/**Full list**/
.right-wrapper.list-full .padding-lr-0{ padding-left:7px; padding-right:7px;}
/*.right-wrapper.list-full .sorting_bg {border-left:1px solid #dbdddf;border-right:1px solid #dbdddf;}*/
/*.right-wrapper.list-full .sorting_option {width:auto; float:right;}*/
.right-wrapper.list-full .sorting_optionbox {width:auto;}
.right-wrapper.list-full .sorting_optionbox .react-select-box-container.low_high_icon {width:170px !important;}
.right-wrapper.list-full .sorting_optionbox .react-select-box-container.photo_icon{width:130px !important;}
/*.right-wrapper.list-full .sorting_bg .sorting_resultsnumber { padding:0px; margin:9px 0 0 0;}*/
.right-wrapper.list-full .sorting_resultsnumber { padding:0px; margin:15px 0 0 0;}
.form-control.filter-search-class { border: 1px solid #ddd; border-radius: 0; float: left}
.table.table-bordered {margin-bottom: 0}
.roomtypes, .roomtypes h4 {
    font-size: 14px; 
    margin-top: 0px;}
.roomtypes {
    padding: 15px 15px 15px 20px;
    column-count: 2 }
.roomtype-section {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
}
.table .order .dropdown{border: none}
/* .image-blur{ -webkit-filter: blur(3px);filter: blur(3px);  } */
/**property popup**/
/* .leaflet-popup-content { margin:10px; overflow:hidden;} */
.property_popup {width:100%; display:block;  position:relative;
    height:auto;
    min-height: 160px;
}
.popup_property_info {width:100%; padding:0 7px !important;  position:absolute; bottom:0; left:0; z-index:99; background:linear-gradient(to bottom,transparent 0,rgba(0,0,0,.60) 90%,rgba(0,0,0,.60) 100%);}
.popup_property_info .popup_facility_list { width:100%; display:block;  }
.popup_property_info .popup_facility_list .star-rating {width:auto;padding:0 16px 0 0; color:#fff;font-size:12px;}
.popup_property_info .popup_facility_list .star-rating i{color:#fff;font-size:13px; padding-right:5px;}
.popup_property_info .popup_property_price { width:100%; float:left; padding-bottom:5px;}
.popup_property_info .popup_property_price .price_left { width:auto; padding-left:10px;  color:#fff; font-size:13px; float:left;}
.popup_property_info .popup_property_price .price_left:first-child{ padding-left:0px;}
.popup_property_info h4 {color:#fff; margin-top:0px; font-size:15px; font-weight:600; margin-bottom:5px; line-height:normal;}
.popup_property_info h4 span{color:#fff; font-size:13px; font-weight: normal; display:block; line-height:normal;}
.popup_property_photo{ width:100%; display:block;}
.popup_property_photo a{width:100%; display:block;position:relative;  overflow: hidden;}
.popup_property_photo a:after{width:100%;height:0;padding-bottom:50%;display:block;content:""; }
.popup_property_photo img {position:absolute;width:100%;height:auto;margin:auto;top:-100%;right:-100%;bottom:-100%;left:-100%;}
/* .leaflet-popup-close-button { background:#5c5c5c !important; border-radius:100%; width:20px !important; height:20px !important;color:#fff !important;line-height:20px !important;padding:0px !important;
    top:-10px !important; z-index: 99999 !important;} */
.price_right_tlc { float:right; color:#fff; font-size:13px;}
.price_right_tlc span{font-weight:700; color:#f7951f;}
.property_group_nav {
    width: 100%; display: block; background: #555; color: #fff; padding: 5px; border-top: 1px solid #fff; z-index:100; position: relative;
}
.property_group_nav .property_group_nav_btn {
    display: inline-block; width: 8%; vertical-align: middle; text-align: left; cursor: pointer;
}
.property_group_nav .property_group_nav_content {
    display: inline-block; width: 84%; text-align: center; vertical-align: middle;
}
.property_group_nav .property_group_nav_btn.right {
    text-align: right;
}
/**Navigation Bar**/
.no-menu-separator:after{border-right: 0 !important;  }
/******************/
.selectclient-popup {max-height:220px; overflow:auto;min-width:200px;}
.margin-top-0 {margin-top: 0;  }
.browse-photo {  max-width: 250px;  overflow: hidden;  }
.photo-loader-wrapper {  position: absolute;  top: 0;  left: 0;  width: 80px;  height: 80px;  text-align: center;  vertical-align: middle;  padding-top: 25%;  }
.row-selection:hover { cursor: pointer; text-decoration: underline; }
/* .react-bs-table-bordered{border-radius: 0;}
.react-bs-table .table-bordered > thead > tr:first-child > td, .react-bs-table .table-bordered > thead > tr:first-child > th { color: #fff; margin: 0; }
.react-bs-table-search-form input {border: 1px solid #ddd; border-radius: 0px !important;}
.react-bs-table-tool-bar {margin-bottom:13px !important;} */
.btn-saved-searches {position: absolute; z-index: 999999;}
.btn-saved-searches .btn-add-client {display:inline-block; margin-right: 30px;}
.saved-search-title {display:inline-block; margin-top:0; margin-bottom:0; vertical-align:top; line-height:31px; padding:0px 0 0 0; font-size:17px; color:#555 !important; }
/* .react-bs-table-sizePerPage-dropdown {border: none;} */
.btn-default[disabled]:focus,.btn-default[disabled]:hover {background-color:#f7941e;}
.commute-search{padding-top: 12px;  }
.commute-search button.close{display: none;  }

.includecommute-wrapper input[type="checkbox"] {margin-right:5px;}
.includecommute-wrapper a {color:#f7951f; float:left; text-decoration:underline;}
.includecommute-wrapper.add_location{ padding:0px;}
.includecommute-wrapper.add_location a {color:#f7951f; padding:5px 7px !important; float:left;}
.includecommute-wrapper a:hover {color:#ffc107;  text-decoration:underline;}
.includecommute-wrapper a:focus {color:#ffc107;  text-decoration:underline;}
.includecommute-wrapper input[type="radio"], input[type="checkbox"] { margin-top: -1px;vertical-align: middle;  }
.includecommute-wrapper span {vertical-align: middle;margin-left:0px;  }
/* .leaflet-pane.leaflet-marker-pane div {width: 10px !important; height: 10px !important; border-radius: 5px !important; left:5px !important; top:5px !important;} */
/* .leaflet-container.crosshair-cursor-enabled {cursor:url("../img/draw_pencil.ico") 6 23, auto;} */
.left-wrapper.list-full .includecommute-wrapper {z-index:998;}

.comn_button {border:none; width:auto; float:left;  padding:7px 13px;  background:#f7951f; box-shadow:inset 0 0 2px rgba(256,256,256,0.75);
    -moz-box-shadow:inset 0 0 2px rgba(256,256,256,0.75); -webkit-box-shadow: inset 0 0 2px rgba(256,256,256,0.75); font-size:14px; color:#fff;}
.comn_button:hover,.comn_button:focus,.comn_button:active{background:#f99e30; }
.comn_button:hover,.comn_button.btn-default:hover,.comn_button.btn:hover{border:none;}
.comn_button.btn:active,.comn_button.btn-default:active {box-shadow:inherit;}
.client_mg_btn { width:100%; float:left; text-align:center;}
.client_mg_btn .comn_button { margin:0 10px 0 0;}
.client_mg_popup .modal-footer .btn + .btn { margin-left:0px;}
.client_mg_popup .checkbox-inline{ text-align:left; margin-bottom:10px;}
.client_mg_popup .checkbox-inline input[type="checkbox"]{ float:left; position:inherit; margin:0px !important; margin-top:3px !important; }
.client_mg_popup .checkbox-inline span { float:left; margin-left:8px;}

.leaflet-tooltip.tooltip-button { cursor: help; }
.tooltip-button { cursor: pointer; }
.tooltip-link { color: rgb(247, 148, 30); text-decoration: underline; }
.leaflet-interactive { cursor: auto; }

.profile-mls {width: calc(100% - 20px) !important;vertical-align: top !important;  }

/*.modal-open .modal-backdrop{z-index: 99999}
.modal-open .modal{z-index: 99999}*/
.modal-backdrop.fade.in {z-index:99999;}
/* .carousel_mappopup{height: 150px;}
.carousel_mappopup .slider-wrapper{height: 150px;} */
.carousel_mappopup .control-arrow{z-index: 99999 !important;}

.leaflet-popup-content .carousel .carousel-status {top:inherit; color:#fff !important;  bottom:5px; left:43%; margin:0px;  width:50px; background-color:rgba(0,0,0,0.6); text-align:center; border-radius:3px;}
.leaflet-popup-content .popup_property_info.hide_details{transform:translateY(100%); transition:transform .35s ease-out;}
.leaflet-popup-content .popup_property_info.show_details{transition:transform .35s ease-out;}
.leaflet-popup-content .carousel.carousel-slider .control-arrow {padding:0px !important; top:-52px !important;}
.leaflet-container .leaflet-marker-pane img { cursor:pointer;}
.popup_property_info.show_details + .popup_property_photo .carousel .carousel-status {display:none}

/* cluster marker */
/* .clusterMarker .clusterMarkerBackground {
    width: 50px;
    height: 50px;
    transition: transform .2s ease-in-out;
    background-clip: padding-box;
    background-color: #53dff7;
    border-style: solid;
    border-color: rgba(83,223,247,.35);
    border-radius: 50%;
    opacity: .9;
    width: 34px;
    height: 34px;
    border-width: 6px;
} */

/* .clusterMarker .clusterCount {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    line-height: 0;
    color: #000;
    position: absolute;
    z-index: 110;
    opacity: .9;
    font-family: Libre Franklin,-apple-system,BlinkMacSystemFont,Roboto,Droid Sans,Helvetica,Arial,sans-serif;
} */
/* .clusterMarker {
    position: absolute;
    line-height: 0;
    cursor: pointer;
    top: -10px;
    left: -10px;
} */
/* .clusterMarkerBackground.size_1 { width:35px; height:35px;} */
.clusterMarkerBackground.size_2 { width:35px; height:35px;}
.clusterMarkerBackground.size_3 { width:40px; height:40px;}
.clusterMarkerBackground.size_4 { width:50px; height:50px;}
.clusterMarkerBackground.size_5 { width:55px; height:55px;}
.clusterMarkerBackground.size_6 { width:60px; height:60px;}
.clusterMarkerBackground.size_7 { width:65px; height:65px;}
.clusterMarkerBackground.size_8 { width:70px; height:70px;}
.leaflet-div-icon {background: none;border: 0;}
.popover.left { margin-top: 20px; }
.popover.left > .arrow { margin-top: -31px; }

@media only screen and (max-width: 768px) {
.filters-stage {min-width:175px; right: unset; left:187px;}
.after-filters {margin-left:0px;position:absolute;right:-70px;top:1px;}
.sticky.sidebar_sticky_pd {position:inherit !important; left:inherit !important; top:inherit !important;}

}
@media only screen and (max-width: 767px) {
.filters-stage {min-width:175px;left: unset;right: 0;}
.table_heading_lt { margin:0 0 10px 0;}
.table_heading_lt .btn-add-client .btn-default {padding:6px 5px !important;}
/*.small_dropdown { width:47%;}*/
}
@media only screen and (max-width: 567px) {
.filters-stage {min-width:100%;left:0 !important;right: 0;top: 42px; }
.btn-saved-searches {margin-bottom:0px;}
.table_heading_lt.client_saved_searches {margin-bottom:58px;}
.table_heading_lt{margin-bottom:10px;}
.table_heading_lt .btn-add-client .btn-default {padding:10px 5px;}
.table_heading_lt .btn-add-client {margin-right: 0 !important;}
/* .leaflet-popup-content { margin:0px;}
.leaflet-popup-content-wrapper, .leaflet-popup-tip {background:none;box-shadow: none;}  */
}


.padding-left-10 {padding-left: 10px;}

.col_first {padding-right: 0; margin-top: 27px;}

.commute-to {padding-top: 4px;}
.commute-to button.close {display: none;}
.commute-to .rbt-input { width:100% !important;}
.commute-to .rbt-input > div {width:100% !important;}
.commute-to .rbt-input > div > div {width:100% !important;}
.commute-to .rbt-input > div > div > input {width:100% !important;}

.btn-archive-client {margin-left: 5px;}

/* .personalized .header-nonfixed {margin-top: -47px;}
.personalized .header { margin-bottom:0px; }
.personalized .content-wrapper {padding-top: 30px;}
.personalized .content-wrapper .top-text {margin-bottom: 25px;} */

.inner_pd_sc {text-align: right; margin: 5px 0 2px 0; color: #fff; font-size: 13px;}
.inner_pd_sc a {color: #fff; vertical-align: middle; font-size: 13px; padding-bottom: 2px; text-decoration: none;}
.inner_pd_sc a:hover {border-bottom: 2px solid #f7941e;}


.margin-left-5 {margin-left: 10px;}
.margin-bottom-4m {margin-bottom: -4px;}
.margin-right-5 {margin-right: 5px !important;}
.__react_component_tooltip {z-index: 99999999 !important; padding:10px!important; text-align:left; }

.margin-top-45 {margin-top: 45px;}
.single-feed-wrapper {min-height: 550px;}
.margin-left-60 {margin-left: 60px;}

.qmrule {padding: 5px; background-color: #f96868; color: #fff; margin-bottom: 10px;}
.prot_cost_box.qmrule {margin: 0 -10px; width: calc(100% + 20px); padding: 7px 10px; background-color: #fff; color: #EB564F;}

.lbl-accept-invite {font-weight: normal; cursor: pointer; padding: 5px 8px 4px 5px;}

.padding-right-0 {padding-right: 0;}
.messages_bottom {float: left; width: 100%;}

.smart-price-range {color: #555;width: 100%;border: 1px solid #dbdddf;padding: 7px 10px 6px 10px;max-width: 100% !important; margin: 0px;font-size: 14px;min-height: 35px;background-color: #fff;cursor: pointer;}

.smart-tlc-range {cursor: pointer;display: inline-block;cursor: pointer;}
.smart-tlc-range.mob {width: 94%;}
.filter_tlc_pr.srtlc .fa {font-size: 17px;}
.filter_tlc_pr.srtlc .fa:after {border: none;}

/********** wordpress menu **********************/
/* .dropdown-menu li.active a { color: #fff !important; }
.dropdown-menu>.active>a { background-color: #f7951f !important; }

@media (min-width: 768px) {
    .dropdown-menu ul {
        position: absolute;
        left: calc(100%);
        background-color: white;
        line-height: 30px;
        top: 0px;
        padding: 0px;
        display: none;
        list-style-type: none;
        border-left: none;
        border-radius: 0;
    }
    .dropdown-menu ul li {
        text-decoration:none;
        width: 100%;
    }

    .dropdown-menu > li > span:hover {
        background-color: #f5f5f5;
    }

    .new_stylemenu .nav li > span {
        display: inline-block;
        position: relative;
        width: 100%; 
        color: #555 !important;
        padding: 15px 25px 11px;
        border-bottom: 2px solid transparent;
        transition: .25s all ease;
        text-transform: uppercase;
        line-height: normal;
        font-weight: 600;
        font-size: 13px;
    }
}
/* mobile */
/*@media (max-width: 768px) {
    .new_stylemenu .nav li > span {
        border-bottom: none;
        float: left;
        padding: 20px 10px;
        text-transform: uppercase;
        line-height: normal;
    }
} */
/************ end wordpress menu **************/

@media only screen and (min-device-width : 375px) and (max-device-width : 667px) and (orientation : landscape) { 
/* #map .leaflet-popup-content { width:auto !important; margin:0px 0 0 0 !important;  } */
/* #map .leaflet-popup-content-wrapper {padding:0px; border:inherit; background:inherit;  } */
#map .property_popup { height:100% !important; background: #c1bfbf; min-height:200px; }
#map .popup_property_photo a { height:235px !important;}
#map .popup_property_photo a:after {padding-bottom:10% !important;}
#map .popup_property_photo img{ width:auto !important; height:235px; }
#map .popup_property_photo .carousel .slide { background:#c1bfbf;}
/* body #map .leaflet-popup-close-button {top:0px !important; width: 30px !important; height:30px !important; line-height:25px !important;font-size:22px;} */
#map .popup-fixed {bottom:45px !important;}
}
/* .react-bs-table-sizePerPage-dropdown .dropdown-menu a {
    display: block;
    padding: 3px 20px;
}
.react-bs-table-sizePerPage-dropdown .dropdown-menu a:hover {
    background-color: #f5f5f5;
    text-decoration:none;
} */
@media only screen and (max-device-width:767px) and (orientation: landscape) and (min-device-aspect-ratio: 1/1){
.ct_profile_left .ct_profile_rt {
    min-height:inherit;
}
}
@media only screen and (max-device-width:834px) and (orientation: landscape) {
    .new_searchlist { text-align: center;}
}