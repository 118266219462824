.loader-container {
  margin-bottom: 10px;
  text-align: center;
  margin-top: 10px; }
  .loader-container .loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #555;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    display: inline-block;
    vertical-align: middle; }
  .loader-container .loader-text {
    font-size: 1.1em;
    line-height: 30px;
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle; }

.pager-loader-container {
  position: absolute;
  top: 0;
  width: 370px;
  text-align: center;
  vertical-align: middle;
  height: 35px;
  padding-top: 5px;
  right: 0;
  background-color: rgba(230, 230, 230, 0.5);
  z-index: 9; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@font-face {
  font-family: 'RobotoMedium';
  src: url("../fonts/Roboto-Medium.eot");
  src: url("../fonts/Roboto-Medium.otf") format("otf"), url("../fonts/Roboto-Medium.woff") format("woff"), url("../fonts/Roboto-Medium.ttf") format("truetype"); }

@font-face {
  font-family: 'RobotoRegular';
  src: url("../fonts/Roboto-Regular.eot");
  src: url("../fonts/Roboto-Regular.otf") format("otf"), url("../fonts/Roboto-Regular.woff") format("woff"), url("../fonts/Roboto-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'GothamMedium';
  src: url("../fonts/Gotham-Medium.woff");
  src: url("../fonts/Gotham-Medium.otf") format("otf"), url("../fonts/Gotham-Medium.woff") format("woff"), url("../fonts/Gotham-Medium.ttf") format("truetype"); }

@font-face {
  font-family: 'GothamBook';
  src: url("../fonts/Gotham-Book.eot");
  src: url("../fonts/Gotham-Book.otf") format("otf"), url("../fonts/Gotham-Book.woff") format("woff"), url("../fonts/Gotham-Book.ttf") format("truetype"); }

@font-face {
  font-family: 'GothamLight';
  src: url("../fonts/Gotham-Light.eot");
  src: url("../fonts/Gotham-Light.otf") format("otf"), url("../fonts/Gotham-Light.woff") format("woff"), url("../fonts/Gotham-Light.ttf") format("truetype"); }

@font-face {
  font-family: 'GothamBold';
  src: url("../fonts/Gotham-Bold.eot");
  src: url("../fonts/Gotham-Bold.otf") format("otf"), url("../fonts/Gotham-Bold.woff") format("woff"), url("../fonts/Gotham-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'GothamOfficeRegular';
  src: url("../fonts/GothamOffice-Regular.eot");
  src: url("../fonts/GothamOffice-Regular.otf") format("otf"), url("../fonts/GothamOffice-Regular.woff") format("woff"), url("../fonts/GothamOffice-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'GothamOfficeBold';
  src: url("../fonts/GothamOffice-Bold.eot");
  src: url("../fonts/GothamOffice-Bold.otf") format("otf"), url("../fonts/GothamOffice-Bold.woff") format("woff"), url("../fonts/GothamOffice-Bold.ttf") format("truetype"); }

/*@font-face {font-family:'GothamThin';src: url('../fonts/theme-fonts/Gotham-Thin.eot');src: url('../fonts/theme-fonts/Gotham-Thin.otf') format('otf'),url('../fonts/theme-fonts/Gotham-Thin.woff') format('woff'),url('../fonts/theme-fonts/Gotham-Thin.ttf') format('truetype');}

@font-face {font-family:'GothamUltra';src: url('../fonts/theme-fonts/Gotham-Ultra.eot');src: url('../fonts/theme-fonts/Gotham-Ultra.otf') format('otf'),url('../fonts/theme-fonts/Gotham-Ultra.woff') format('woff'),url('../fonts/theme-fonts/Gotham-Ultra.ttf') format('truetype');}

@font-face {font-family:'GothamNarrowThin';src: url('../fonts/theme-fonts/GothamNarrow-Thin.eot');src: url('../fonts/theme-fonts/GothamNarrow-Thin.otf') format('otf'),url('../fonts/theme-fonts/GothamNarrow-Thin.woff') format('woff'),url('../fonts/theme-fonts/GothamNarrow-Thin.ttf') format('truetype');}

@font-face {font-family:'GothamNarrowBlack';src: url('../fonts/theme-fonts/GothamNarrow-Black.eot');src: url('../fonts/theme-fonts/GothamNarrow-Black.otf') format('otf'),url('../fonts/theme-fonts/GothamNarrow-Black.woff') format('woff'),url('../fonts/theme-fonts/GothamNarrow-Black.ttf') format('truetype');}

@font-face {font-family:'GothamNarrowBook';src: url('../fonts/theme-fonts/GothamNarrow-Book.eot');src: url('../fonts/theme-fonts/GothamNarrow-Book.otf') format('otf'),url('../fonts/theme-fonts/GothamNarrow-Book.woff') format('woff'),url('../fonts/theme-fonts/GothamNarrow-Book.ttf') format('truetype');}

@font-face {font-family:'GothamNarrowUltra';src: url('../fonts/theme-fonts/GothamNarrow-Ultra.eot');src: url('../fonts/theme-fonts/GothamNarrow-Ultra.otf') format('otf'),url('../fonts/theme-fonts/GothamNarrow-Ultra.woff') format('woff'),url('../fonts/theme-fonts/GothamNarrow-Ultra.ttf') format('truetype');} */
@font-face {
  font-family: 'OpenSansRegular';
  src: url("../fonts/OpenSans-Regular.eot");
  src: url("../fonts/OpenSans-Regular.woff") format("woff"), url("../fonts/OpenSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'OpenSansLight';
  src: url("../fonts/OpenSans-Light.eot");
  src: url("../fonts/OpenSans-Light.woff") format("woff"), url("../fonts/OpenSans-Light.ttf") format("truetype"); }

@font-face {
  font-family: 'OpenSansBold';
  src: url("../fonts/OpenSans-Bold.eot");
  src: url("../fonts/OpenSans-Bold.woff") format("woff"), url("../fonts/OpenSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'OpenSansSemibold';
  src: url("../fonts/OpenSans-Semibold.eot");
  src: url("../fonts/OpenSans-Semibold.woff") format("woff"), url("../fonts/OpenSans-Semibold.ttf") format("truetype"); }

@font-face {
  font-family: 'OpenSansItalic';
  src: url("../fonts/OpenSans-Italic.eot");
  src: url("../fonts/OpenSans-Italic.woff") format("woff"), url("../fonts/OpenSans-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'OpenSansBoldItalic';
  src: url("../fonts/OpenSans-BoldItalic.eot");
  src: url("../fonts/OpenSans-BoldItalic.woff") format("woff"), url("../fonts/OpenSans-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'OpenSansBoldItalic';
  src: url("../fonts/OpenSans-BoldItalic.eot");
  src: url("../fonts/OpenSans-BoldItalic.woff") format("woff"), url("../fonts/OpenSans-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'aleo-bold';
  src: url("../fonts/aleo-bold-webfont.eot");
  src: url("../fonts/aleo-bold-webfont.woff") format("woff"), url("../fonts/aleo-bold-webfont.ttf") format("truetype"); }

@font-face {
  font-family: 'aleo-regular';
  src: url("../fonts/aleo-regular-webfont.eot");
  src: url("../fonts/aleo-regular-webfont.woff") format("woff"), url("../fonts/aleo-regular-webfont.ttf") format("truetype"); }

h1, h2, h3, h4 {
  margin: 0px;
  padding: 0px;
  font-weight: normal;
  font-family: 'GothamMedium';
  line-height: normal; }

a {
  color: #00ADBB;
  font-size: 14px;
  font-weight: normal; }
  a:hover {
    text-decoration: none;
    color: #033258; }

.dark-blue-link {
  color: #033258; }
  .dark-blue-link:hover {
    color: #00ADBB; }

input, textarea {
  margin: 0px;
  padding: 10px 15px;
  border-radius: 4px;
  width: 100%;
  outline: none;
  border: none;
  background: #fff;
  font-size: 16px;
  color: #4A4A4A;
  /* appearance: none; */ }

input::placeholder, textarea::placeholder {
  color: #bfbfbf; }

.input-with-border {
  border: 1px solid #dddddd;
  min-height: 32px;
  font-size: 13px;
  width: 100%;
  padding: 10px;
  text-align: left; }
  .input-with-border.border-error {
    border-color: #ff0000; }
  .input-with-border:disabled {
    background: #f2f2f2; }
  .input-with-border.small-height {
    min-height: 32px;
    padding: 4px 10px; }

.input-with-border.medium-height {
  min-height: 45px; }

textarea {
  outline: none;
  border-radius: 4px; }

.btn {
  font-family: 'RobotoMedium'; }

.btn-group {
  margin-bottom: 15px; }

.label-error {
  float: right !important;
  font-size: 13px !important;
  margin: -22px 8px 0 !important;
  color: #ff0000 !important; }

/***************************button**********************************/
.theme-btn {
  border: none;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 15px;
  padding: 10px 15px;
  font-weight: normal;
  line-height: normal;
  display: inline-block;
  margin: 0px; }
  .theme-btn:hover {
    color: #fff; }
  .theme-btn:focus {
    box-shadow: none;
    outline: none; }
  .theme-btn:disabled {
    cursor: not-allowed; }
  .theme-btn.btn-primary {
    background-color: #00adbb !important;
    border-color: inherit; }
    .theme-btn.btn-primary:hover {
      color: #fff;
      background-color: #00939f !important; }
    .theme-btn.btn-primary:focus {
      box-shadow: none;
      outline: none;
      background: #007882 !important; }
    .theme-btn.btn-primary:active {
      box-shadow: none;
      outline: none;
      background: #007882 !important; }
  .theme-btn.min-width {
    min-width: 110px; }
  .theme-btn.small-padding-btn {
    padding: 4px 15px; }
  .theme-btn.min-width-80 {
    min-width: 80px; }
  .theme-btn.theme-white-btn {
    padding: 9px 15px;
    /* background:#fff; */
    border: 1px solid;
    /* &:hover {
			color:#fff;
		} */ }
    .theme-btn.theme-white-btn:focus {
      box-shadow: none;
      outline: none;
      /* color:#fff; */ }

/**************************type searching ***********************************/
.typeahead-search {
  border-radius: 5px;
  height: 55px;
  padding: 0px;
  font-size: 16px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  background: #ffffff; }
  @media only screen and (max-width: 767px) {
    .typeahead-search {
      font-size: 13px;
      height: 55px;
      width: 100%;
      margin-bottom: 20px; } }
  .typeahead-search .form-control {
    border: none;
    font-size: 15px;
    height: 100%;
    padding: 10px 15px; }
    @media only screen and (max-width: 767px) {
      .typeahead-search .form-control {
        padding: 11px 12px; } }
    .typeahead-search .form-control:focus {
      box-shadow: none; }
    .typeahead-search .form-control ::placeholder {
      color: #9B9B9B; }
  .typeahead-search .rbt-input-main::placeholder {
    color: #9B9B9B; }
  .typeahead-search .rbt-input-hint {
    height: 100%; }
  .typeahead-search .dropdown-menu {
    margin: 0px;
    border-radius: 4px; }
    .typeahead-search .dropdown-menu .dropdown-header {
      font-size: 14px; }
    .typeahead-search .dropdown-menu .dropdown-item {
      padding: 7px 10px 7px 14px;
      font-family: 'GothamBook';
      font-size: 13px; }
      .typeahead-search .dropdown-menu .dropdown-item:hover, .typeahead-search .dropdown-menu .dropdown-item:active {
        background: #f5f5f5; }
  .typeahead-search button.close {
    font-size: 24px;
    line-height: 12px;
    padding-right: 15px; }

/*****************select dropdwon start**************/
.type-select {
  outline: none;
  font-size: 13px;
  color: #033258; }
  .type-select.type-select-33-ht .react-select__control {
    min-height: 33px; }
  .type-select .react-select__menu {
    box-shadow: inherit;
    border: 1px solid #ddd;
    margin-bottom: 0px; }
    .type-select .react-select__menu .react-select__menu-list {
      max-height: 220px;
      margin-top: -1px; }
  .type-select .react-select__control {
    border: 1px solid #DDDDDD;
    box-shadow: none;
    min-height: 40px; }
    .type-select .react-select__control:hover, .type-select .react-select__control:focus {
      border: 1px solid #DDDDDD; }
    .type-select .react-select__control:focus {
      border: none; }
    .type-select .react-select__control .react-select__indicator {
      position: relative;
      padding: 0;
      min-height: 30px; }
      .type-select .react-select__control .react-select__indicator:after {
        position: absolute;
        content: "\F107";
        font-family: "FontAwesome";
        top: 0px;
        right: 10px;
        border: none;
        font-size: 16px;
        margin: 0;
        line-height: 30px;
        color: #033258; }
      .type-select .react-select__control .react-select__indicator svg {
        display: none; }
    .type-select .react-select__control .css-1okebmr-indicatorSeparator {
      display: none; }
    .type-select .react-select__control .css-1n7v3ny-option {
      background-color: #f5f5f5 !important; }
    .type-select .react-select__control .react-select__single-value {
      font-size: 13px;
      max-width: calc(100% - 34px); }
    .type-select .react-select__control .react-select__value-container {
      overflow: inherit; }
    .type-select .react-select__control .css-b8ldur-Input {
      width: 100%;
      padding-right: 20px; }
      .type-select .react-select__control .css-b8ldur-Input .react-select__input {
        width: 100%; }
        .type-select .react-select__control .css-b8ldur-Input .react-select__input input {
          width: 100% !important; }
  .type-select .react-select__menu {
    /* dropdown top space */
    margin-top: 0px;
    /* dropdown top space */ }
    .type-select .react-select__menu .react-select__menu-list {
      padding-bottom: 0px;
      padding-top: 0px;
      border-radius: 4px; }
      .type-select .react-select__menu .react-select__menu-list .react-select__option {
        color: #033258;
        font-size: 13px;
        outline: none;
        word-break: break-word;
        overflow-wrap: break-word; }
        .type-select .react-select__menu .react-select__menu-list .react-select__option.css-9gakcf-option {
          background-color: #f5f5f5; }
        .type-select .react-select__menu .react-select__menu-list .react-select__option.css-1n7v3ny-option {
          background-color: #f5f5f5; }

/*****************select dropdwon End**************/
/*****************breadcrumb start**************/
.breadcrumb {
  display: inline-block;
  vertical-align: top;
  padding-left: 10px;
  font-size: 14px;
  background: none;
  padding: 0;
  margin: 0; }
  .breadcrumb:before {
    padding-right: 5px; }
  .breadcrumb .breadcrumb {
    background: #ffffff;
    padding: 0px;
    margin-bottom: 0px;
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    color: #9B9B9B; }
    .breadcrumb .breadcrumb .breadcrumb-item {
      padding: 0 0 0 10px;
      display: inline-block; }
      .breadcrumb .breadcrumb .breadcrumb-item:before {
        padding-right: 5px;
        content: "\f105" !important;
        font-family: "FontAwesome"; }

/*****************breadcrumb End**************/
/**************Range without value******************/
.price-range-box {
  width: 100%;
  display: block;
  margin-top: 21px; }
  .price-range-box .input-range__label-container {
    display: none; }
  .price-range-box .input-range__track {
    height: 1px;
    background: #dddddd; }
    .price-range-box .input-range__track.input-range__track--active {
      background: #00adbb; }
  .price-range-box .input-range__slider {
    width: 10px;
    height: 10px;
    margin-top: -5px; }

/**************Range without value******************/
/**********Tap list start**********/
.tab-list .nav-tabs {
  border-bottom: 1px solid #dddddd; }
  .tab-list .nav-tabs .nav-item {
    margin-bottom: 0px; }
  .tab-list .nav-tabs .nav-link {
    border: none;
    outline: none;
    padding: 0px 0 15px 0;
    margin-right: 30px;
    font-size: 14px; }
    .tab-list .nav-tabs .nav-link:last-child {
      margin-right: 0px; }
    @media only screen and (max-width: 990px) {
      .tab-list .nav-tabs .nav-link {
        margin-right: 12px; } }
    @media only screen and (max-width: 576px) {
      .tab-list .nav-tabs .nav-link {
        margin-right: 10px;
        padding: 0px 0 10px 0;
        font-size: 12px; } }
    @media only screen and (max-width: 374px) {
      .tab-list .nav-tabs .nav-link {
        margin-right: 8px;
        padding: 0px 0 10px 0;
        font-size: 10px; } }
  .tab-list .nav-tabs.center-tabs {
    text-align: center;
    display: block;
    width: 100%; }
    .tab-list .nav-tabs.center-tabs .nav-link {
      display: inline-block; }
  .tab-list .nav-tabs .tabicon-text {
    display: inline-block;
    vertical-align: middle; }
    @media only screen and (max-width: 767px) {
      .tab-list .nav-tabs .tabicon-text {
        display: none; } }
  .tab-list .nav-tabs .tabicon-box {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px; }
    @media only screen and (max-width: 990px) {
      .tab-list .nav-tabs .tabicon-box {
        margin-right: 7px;
        font-size: 22px; } }
    @media only screen and (max-width: 767px) {
      .tab-list .nav-tabs .tabicon-box {
        margin-right: 0px;
        padding: 0 10px; } }
    @media only screen and (max-width: 374px) {
      .tab-list .nav-tabs .tabicon-box {
        margin-right: 0px;
        padding: 0 10px; } }

.tab-list .tab-container {
  width: 100%;
  display: inline-block;
  padding: 20px 15px 0px 15px; }
  @media only screen and (max-width: 767px) {
    .tab-list .tab-container {
      padding: 20px 0px; } }

.tab-list .nav-pills {
  border-right: 1px solid #dddddd; }
  @media only screen and (max-width: 1023px) {
    .tab-list .nav-pills {
      border-right: none;
      margin-bottom: 25px; } }
  .tab-list .nav-pills .nav-item {
    min-height: 36px;
    margin-top: 10px; }
    @media only screen and (max-width: 1023px) {
      .tab-list .nav-pills .nav-item {
        width: 50%;
        padding: 0 10px;
        display: inline-block;
        margin-top: 5px;
        margin-bottom: 5px;
        vertical-align: top; } }
    .tab-list .nav-pills .nav-item:first-child {
      margin-top: 0px; }
    .tab-list .nav-pills .nav-item .nav-link {
      border-radius: inherit;
      font-size: 14px;
      padding: 0 0 10px 0;
      display: block;
      border-bottom: 2px solid #ffffff; }
      @media only screen and (max-width: 1230px) {
        .tab-list .nav-pills .nav-item .nav-link {
          font-size: 13px; } }
      @media only screen and (max-width: 1023px) {
        .tab-list .nav-pills .nav-item .nav-link {
          padding: 0 0 10px 0; } }
      .tab-list .nav-pills .nav-item .nav-link:focus {
        outline: none; }
      .tab-list .nav-pills .nav-item .nav-link.active {
        background-color: inherit; }
        .tab-list .nav-pills .nav-item .nav-link.active:focus {
          outline: none; }
      .tab-list .nav-pills .nav-item .nav-link .tabicon-box {
        font-size: 19px;
        float: left;
        overflow: hidden;
        width: 25px;
        height: 25px;
        line-height: 25px;
        margin-right: 10px; }
        @media only screen and (max-width: 1230px) {
          .tab-list .nav-pills .nav-item .nav-link .tabicon-box {
            margin-right: 7px;
            width: 20px; } }
      .tab-list .nav-pills .nav-item .nav-link .tabicon-text {
        display: block;
        overflow: hidden;
        padding-right: 23px;
        line-height: 19px; }

.tab-list.vertical-tab .nav-pills {
  height: 100%; }
  @media only screen and (max-width: 1023px) {
    .tab-list.vertical-tab .nav-pills {
      display: inline-block;
      width: 100%; } }

/**********Tap list End**********/
/*****************multi select dropdwon start**************/
.multi-select .dropdown-container {
  border: 1px solid #DDDDDD; }
  .multi-select .dropdown-container:focus-within {
    box-shadow: none;
    border: 1px solid #DDDDDD; }
  .multi-select .dropdown-container .dropdown-heading:after {
    position: absolute;
    content: "\F107";
    font-family: FontAwesome;
    top: 0px;
    right: 10px;
    border: none;
    font-size: 16px;
    margin: 0;
    line-height: 36px; }
  .multi-select .dropdown-container .dropdown-heading .dropdown-heading-value {
    margin-top: 0px;
    font-size: 13px;
    font-weight: normal;
    padding: 0 20px 0 0; }
    .multi-select .dropdown-container .dropdown-heading .dropdown-heading-value .gray {
      font-size: 13px; }
  .multi-select .dropdown-container .dropdown-heading .dropdown-heading-dropdown-arrow {
    display: none; }
  .multi-select .dropdown-container .dropdown-heading .clear-selected-button {
    display: none; }
  .multi-select .dropdown-container .dropdown-content {
    margin-top: -1px; }
    .multi-select .dropdown-container .dropdown-content .panel-content {
      box-shadow: inherit;
      border: 1px solid #ddd;
      border-radius: 4px;
      padding: 7px 16px 15px 16px;
      height: 220px;
      overflow-y: scroll;
      margin-top: -6px; }
      .multi-select .dropdown-container .dropdown-content .panel-content .options {
        overflow: inherit;
        margin-bottom: 0px;
        display: inline-block;
        max-height: 100%;
        width: 100%; }
        .multi-select .dropdown-container .dropdown-content .panel-content .options label {
          margin-top: 0; }
        .multi-select .dropdown-container .dropdown-content .panel-content .options .item-renderer {
          display: block; }
      .multi-select .dropdown-container .dropdown-content .panel-content .select-panel {
        font-size: 13px;
        font-family: 'GothamBook'; }
        .multi-select .dropdown-container .dropdown-content .panel-content .select-panel .select-item {
          width: 100%;
          float: left;
          padding-left: 0px;
          padding-right: 0px;
          padding-bottom: 2px;
          padding-top: 7px;
          margin-top: 5px;
          margin-bottom: 0px;
          line-height: normal; }
          .multi-select .dropdown-container .dropdown-content .panel-content .select-panel .select-item.selected {
            background: none !important; }
          .multi-select .dropdown-container .dropdown-content .panel-content .select-panel .select-item:hover, .multi-select .dropdown-container .dropdown-content .panel-content .select-panel .select-item:focus {
            background: none !important; }
          .multi-select .dropdown-container .dropdown-content .panel-content .select-panel .select-item .item-renderer input[type=checkbox] {
            display: inline-block;
            vertical-align: middle;
            width: 18px;
            height: 18px;
            background: #ffffff;
            border: 2px solid #757575;
            border-radius: 3px;
            margin: 0px;
            appearance: none;
            position: relative;
            cursor: pointer; }
            .multi-select .dropdown-container .dropdown-content .panel-content .select-panel .select-item .item-renderer input[type=checkbox]::before {
              position: absolute;
              content: '';
              display: block;
              top: -1px;
              left: 5px;
              width: 5px;
              height: 11px;
              border: solid white;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
              opacity: 0; }
            .multi-select .dropdown-container .dropdown-content .panel-content .select-panel .select-item .item-renderer input[type=checkbox]:checked {
              color: #ffffff; }
              .multi-select .dropdown-container .dropdown-content .panel-content .select-panel .select-item .item-renderer input[type=checkbox]:checked::before {
                opacity: 1; }
          .multi-select .dropdown-container .dropdown-content .panel-content .select-panel .select-item .item-renderer span {
            display: inline-block;
            cursor: pointer;
            font-size: 13px;
            font-family: 'GothamBook';
            padding-left: 15px;
            vertical-align: middle;
            margin-top: 0px;
            width: calc(100% - 30px); }

.multi-select.rmsc .dropdown-container {
  border: 1px solid #ddd;
  border-radius: 4px; }
  .multi-select.rmsc .dropdown-container:focus-within {
    box-shadow: inherit;
    border-color: #DDDDDD; }

.bottom-multiselect .multi-select .dropdown-content {
  top: inherit !important;
  bottom: 38px !important; }
  @media only screen and (max-width: 1199px) {
    .bottom-multiselect .multi-select .dropdown-content {
      top: 39px !important;
      bottom: inherit !important; } }

/*****************multi select dropdwon End**************/
/********table td data start***********************/
.area-data {
  width: 100%;
  max-width: 100%;
  background-color: #fff;
  color: #7B8AA0;
  /********rating data******/
  /********rating data******/ }
  @media only screen and (max-width: 767px) {
    .area-data thead {
      display: none; } }
  .area-data thead th {
    color: #B6BDC4;
    font-size: 12px;
    border-bottom: 1px solid #E9EBF1;
    padding-bottom: 16px;
    font-weight: normal; }
  @media only screen and (max-width: 767px) {
    .area-data tbody tr {
      display: block;
      margin-bottom: 12px; } }
  .area-data tbody tr th {
    font-size: 12px;
    border-bottom: 1px solid #E9EBF1; }
    @media only screen and (max-width: 767px) {
      .area-data tbody tr th {
        font-size: 13px; } }
  .area-data tbody tr td {
    font-size: 12px;
    padding: 10px 7px;
    border-bottom: 1px solid #E9EBF1;
    word-break: break-all;
    vertical-align: top; }
    @media only screen and (max-width: 767px) {
      .area-data tbody tr td {
        font-size: 13px;
        padding: 10px 16px; } }
    .area-data tbody tr td:first-child {
      text-align: left; }
      @media only screen and (max-width: 767px) {
        .area-data tbody tr td:first-child {
          padding: 10px 7px; } }
    @media only screen and (max-width: 767px) {
      .area-data tbody tr td:last-child {
        border-bottom: none; } }
    .area-data tbody tr td:nth-child(1) {
      width: 40%;
      padding: 10px 7px 10px 0px; }
      @media only screen and (max-width: 767px) {
        .area-data tbody tr td:nth-child(1) {
          padding: 10px 16px 10px 16px; } }
    .area-data tbody tr td:nth-child(2) {
      width: 20%; }
    .area-data tbody tr td:nth-child(3) {
      width: 20%; }
    .area-data tbody tr td:nth-child(4) {
      width: 20%; }
    @media only screen and (max-width: 767px) {
      .area-data tbody tr td {
        display: block;
        text-align: left; }
        .area-data tbody tr td:nth-child(1) {
          width: 100%; }
        .area-data tbody tr td:nth-child(2) {
          width: 100%; }
        .area-data tbody tr td:nth-child(3) {
          width: 100%; }
        .area-data tbody tr td:nth-child(4) {
          width: 100%; }
        .area-data tbody tr td::before {
          content: attr(data-title);
          width: 100%;
          text-align: left;
          font-weight: 600; }
        .area-data tbody tr td .label-value {
          margin-top: 7px;
          display: block; }
        .area-data tbody tr td:nth-child(odd) {
          background: #f1f1f1; }
        .area-data tbody tr td:nth-child(even) {
          background: #f7f7f7; } }
  .area-data .rating-number {
    width: 26px;
    height: 14px;
    border-radius: 6px;
    font-size: 8px;
    text-align: center;
    display: inline-block;
    line-height: 15px; }
    .area-data .rating-number.green-rating {
      background: #CDF9A0;
      color: #3A9F00; }
    .area-data .rating-number.orange-rating {
      background: #FFDCA4;
      color: #EE8822; }
    .area-data .rating-number.red-rating {
      background: #FAAEB8;
      color: #D0021B; }
  .area-data.multi-searches-data {
    border: 1px solid  #dddddd; }
    @media only screen and (max-width: 480px) {
      .area-data.multi-searches-data {
        border: none; } }
    .area-data.multi-searches-data thead th {
      padding: 15px 15px 15px 15px;
      background: #9B9B9B;
      color: #ffffff;
      font-weight: 600; }
      @media only screen and (max-width: 1024px) {
        .area-data.multi-searches-data thead th {
          padding: 10px 10px 10px 10px; } }
    @media only screen and (max-width: 767px) {
      .area-data.multi-searches-data tbody tr {
        margin-bottom: 10px !important; }
        .area-data.multi-searches-data tbody tr:last-child {
          margin-bottom: 0px !important; } }
    .area-data.multi-searches-data tbody tr:nth-child(odd) {
      background-color: #f7f7f7; }
    .area-data.multi-searches-data tbody tr:last-child td {
      border-bottom: none; }
    .area-data.multi-searches-data tbody tr td {
      font-size: 13px;
      padding: 15px 15px 15px 15px;
      border-bottom: 1px solid  #dddddd; }
      @media only screen and (max-width: 1024px) {
        .area-data.multi-searches-data tbody tr td {
          padding: 10px 10px 10px 10px; } }
      @media only screen and (max-width: 767px) {
        .area-data.multi-searches-data tbody tr td {
          max-width: 100%;
          padding: 10px 15px 10px 15px; } }
      @media only screen and (max-width: 767px) {
        .area-data.multi-searches-data tbody tr td:first-child {
          max-width: 100%;
          padding: 10px 13px 10px 13px; } }
      .area-data.multi-searches-data tbody tr td.nt-icon-alignment {
        text-align: right; }
        .area-data.multi-searches-data tbody tr td.nt-icon-alignment:before {
          padding-bottom: 0px;
          margin-top: -10px; }
        @media only screen and (max-width: 767px) {
          .area-data.multi-searches-data tbody tr td.nt-icon-alignment {
            text-align: center; } }
      .area-data.multi-searches-data tbody tr td:nth-child(1) {
        width: inherit; }
      .area-data.multi-searches-data tbody tr td:nth-child(2) {
        width: inherit; }
      .area-data.multi-searches-data tbody tr td:nth-child(3) {
        width: inherit; }
      .area-data.multi-searches-data tbody tr td:nth-child(4) {
        width: inherit; }
      .area-data.multi-searches-data tbody tr td.pd-lt-0 {
        padding-left: 0px; }
        @media only screen and (max-width: 767px) {
          .area-data.multi-searches-data tbody tr td.pd-lt-0 {
            padding-left: 15px; } }
      .area-data.multi-searches-data tbody tr td.pd-rt-0 {
        padding-right: 0px; }
        @media only screen and (max-width: 767px) {
          .area-data.multi-searches-data tbody tr td.pd-rt-0 {
            padding-right: 10px; } }
    .area-data.multi-searches-data .search-notification-icon {
      display: inline-block; }
      .area-data.multi-searches-data .search-notification-icon .material-icons {
        display: inline-block;
        margin-left: 10px;
        cursor: pointer; }
        @media only screen and (max-width: 1199px) {
          .area-data.multi-searches-data .search-notification-icon .material-icons {
            font-size: 20px; } }
        .area-data.multi-searches-data .search-notification-icon .material-icons:first-child {
          margin-left: 0px; }
    .area-data.multi-searches-data .common-checkbox {
      padding-left: 0px; }
      @media only screen and (max-width: 767px) {
        .area-data.multi-searches-data .common-checkbox {
          display: inline-block; } }
      .area-data.multi-searches-data .common-checkbox .checkmark {
        height: 15px;
        width: 15px; }
        .area-data.multi-searches-data .common-checkbox .checkmark:after {
          left: 3px;
          top: -1px; }

.save-searches-data {
  width: 100%;
  font-size: 14px;
  margin-bottom: 25px;
  word-break: break-word; }
  @media only screen and (max-width: 1024px) {
    .save-searches-data {
      font-size: 13px; } }
  @media only screen and (max-width: 767px) {
    .save-searches-data {
      /* border: 1px solid #dddddd; */
      margin-bottom: 25px; } }
  .save-searches-data thead tr th {
    color: #3f4259;
    background: #f3f6f9;
    padding-top: 15px;
    padding-bottom: 15px;
    text-transform: uppercase; }
    .save-searches-data thead tr th:first-child {
      border-radius: 5px 0 0 5px; }
    .save-searches-data thead tr th:last-child {
      border-radius: 0px 5px 5px 0px; }
  @media only screen and (max-width: 767px) {
    .save-searches-data thead {
      display: none; } }
  .save-searches-data tbody tr {
    border-bottom: 1px solid #ebedf3; }
    .save-searches-data tbody tr:hover {
      background-color: #f3f6f9; }
    @media only screen and (max-width: 767px) {
      .save-searches-data tbody tr {
        display: block;
        margin-bottom: 0px;
        border-bottom: 1px solid #dddddd;
        margin: 0px;
        padding: 10px 0; }
        .save-searches-data tbody tr:last-child {
          border-bottom: none; } }
  .save-searches-data tbody td {
    padding-top: 25px;
    padding-bottom: 25px;
    color: #8a8c9f;
    vertical-align: top; }
    @media only screen and (max-width: 767px) {
      .save-searches-data tbody td {
        max-width: 100%;
        display: block;
        text-align: left;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 0px; }
        .save-searches-data tbody td::before {
          content: attr(data-title);
          width: 100%;
          text-align: left;
          font-weight: 600;
          color: #3f4259; }
        .save-searches-data tbody td .label-value {
          display: block; } }
    @media only screen and (max-width: 767px) and (max-width: 767px) {
      .save-searches-data tbody td .label-value {
        padding-top: 5px;
        margin-left: 0px; } }
    @media only screen and (max-width: 767px) {
        .save-searches-data tbody td:last-child {
          margin-bottom: 0px; }
        .save-searches-data tbody td.no-title::before {
          display: none; } }
  .save-searches-data .tags-data {
    display: block;
    width: 100%; }
    @media only screen and (max-width: 767px) {
      .save-searches-data .tags-data {
        margin-top: 7px; } }
    .save-searches-data .tags-data .tags-box {
      display: inline-block;
      padding-right: 6px;
      margin-right: 6px;
      margin-bottom: 6px;
      border-right: 1px solid #9b9b9b; }
      .save-searches-data .tags-data .tags-box:last-child {
        border-right: none; }
  .save-searches-data .search-notification-icon {
    display: inline-block;
    text-align: right;
    width: 100%; }
    @media only screen and (max-width: 767px) {
      .save-searches-data .search-notification-icon {
        text-align: center;
        margin-bottom: 0px; } }
    .save-searches-data .search-notification-icon .edit-icon {
      cursor: pointer;
      margin-left: 8px;
      font-size: 22px; }
      @media only screen and (max-width: 1366px) {
        .save-searches-data .search-notification-icon .edit-icon {
          margin-left: 6px;
          font-size: 20px; } }
      @media only screen and (max-width: 1024px) {
        .save-searches-data .search-notification-icon .edit-icon {
          font-size: 20px; } }
      @media only screen and (max-width: 1000px) {
        .save-searches-data .search-notification-icon .edit-icon {
          font-size: 17px; } }
      @media only screen and (max-width: 767px) {
        .save-searches-data .search-notification-icon .edit-icon {
          font-size: 22px;
          margin-left: 10px;
          margin-right: 10px; } }
      .save-searches-data .search-notification-icon .edit-icon:first-child {
        margin-left: 0px; }
      .save-searches-data .search-notification-icon .edit-icon.exchange-icon {
        font-size: 16px; }
  .save-searches-data .td-stages .filters-stages {
    z-index: 999; }

.table-top-title {
  font-size: 14px;
  line-height: 20px; }

.save-search-table-header .filter-title {
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
  padding-top: 8px; }

@media only screen and (max-width: 991px) {
  .save-search-table-header .table-search-filter {
    margin-top: 20px; } }

.save-search-table-header .table-search-filter .typeahead-search {
  height: 40px;
  width: calc(100% - 81px);
  display: inline-block;
  vertical-align: top; }
  .save-search-table-header .table-search-filter .typeahead-search .dropdown-menu {
    top: 0px;
    padding: 0px; }
  .save-search-table-header .table-search-filter .typeahead-search .form-control {
    font-size: 13px;
    border: 1px solid #ddd;
    padding: 10px; }

.save-search-table-header .table-search-filter .theme-btn {
  margin-left: 15px; }

.save-search-table-footer .table-search-pagination {
  text-align: right; }
  @media only screen and (max-width: 575px) {
    .save-search-table-footer .table-search-pagination {
      text-align: center;
      margin-top: 10px; } }
  .save-search-table-footer .table-search-pagination .pagination li:last-child a {
    padding-right: 0px; }
    @media only screen and (max-width: 575px) {
      .save-search-table-footer .table-search-pagination .pagination li:last-child a {
        padding-right: 8px; } }

.save-search-table-footer .table-showing-number {
  font-size: 15px; }
  @media only screen and (max-width: 575px) {
    .save-search-table-footer .table-showing-number {
      text-align: center;
      margin-bottom: 10px; } }

/********table td data End***********************/
/********content text p start***********************/
p {
  line-height: 22px;
  margin: 7px 0; }

/********content text p start***********************/
/********datepicker start***********************/
.react-datepicker-wrapper {
  border: 1px solid  #dddddd !important;
  border-radius: 4px;
  min-height: 40px;
  width: 100%; }
  .react-datepicker-wrapper .react-datepicker__input-container input {
    font-size: 14px;
    min-height: 38px; }

/********datepicker End***********************/
.full-width {
  width: 100%; }

.dp-block {
  display: block; }

.dp-inline {
  display: inline-block; }

.dp-table {
  display: table; }

.vertical-mid-content {
  vertical-align: middle;
  display: table-cell; }
  @media only screen and (max-width: 575px) {
    .vertical-mid-content.vertical-mid-full {
      display: block; } }

@media only screen and (max-width: 991px) {
  .mb-d-none {
    display: none !important; } }

.v-align-md {
  vertical-align: middle; }

.v-align-tp {
  vertical-align: top; }

.v-align-bt {
  vertical-align: bottom; }

.ft-none {
  float: none; }

.ft-lt {
  float: left; }

.ft-rt {
  float: right; }

.cr-lt {
  clear: left; }

.width-auto {
  width: auto; }

.cursor-pointer {
  cursor: pointer; }

.text-uppercase {
  text-transform: uppercase; }

.text-justify {
  text-align: justify; }

.ft-bd {
  font-weight: bold !important; }

.pos-rl {
  position: relative; }

.pos-ab {
  position: absolute; }

.form-control-text {
  font-size: 13px;
  font-weight: normal;
  margin-bottom: 5px; }

.br-bt-gray {
  border-bottom: 1px solid #e4e4e4; }

/******************padding top********************/
.pd-tp-0 {
  padding-top: 0px; }

.pd-tp-5 {
  padding-top: 5px; }

.pd-tp-10 {
  padding-top: 10px; }

.pd-tp-15 {
  padding-top: 15px; }

.pd-tp-20 {
  padding-top: 20px; }

.pd-tp-24 {
  padding-top: 24px; }

.pd-tp-25 {
  padding-top: 25px; }

.pd-tp-30 {
  padding-top: 30px; }

.pd-tp-35 {
  padding-top: 35px; }

.pd-tp-40 {
  padding-top: 40px; }

.pd-tp-45 {
  padding-top: 45px; }

.pd-tp-50 {
  padding-top: 50px; }

.pd-tp-55 {
  padding-top: 55px; }

.pd-tp-60 {
  padding-top: 60px; }

/******************padding top end********************/
/******************padding right start********************/
.pd-rt-0 {
  padding-right: 0; }

.pd-rt-2 {
  padding-right: 2px; }

.pd-rt-10 {
  padding-right: 10px; }

.pd-rt-15 {
  padding-right: 15px; }

.pd-rt-20 {
  padding-right: 20px; }

.pd-rt-18 {
  padding-right: 18px; }

.pd-rt-19 {
  padding-right: 19px; }

.pd-rt-25 {
  padding-right: 25px; }

.pd-rt-30 {
  padding-right: 30px; }

.pd-rt-35 {
  padding-right: 35px; }

.pd-rt-40 {
  padding-right: 40px; }

.pd-rt-45 {
  padding-right: 45px; }

.pd-rt-50 {
  padding-right: 50px; }

.pd-rt-55 {
  padding-right: 55px; }

.pd-rt-60 {
  padding-right: 60px; }

/******************padding right End********************/
/******************padding bottom start********************/
.pd-bt-0 {
  padding-bottom: 0px; }

.pd-bt-5 {
  padding-bottom: 5px; }

.pd-bt-10 {
  padding-bottom: 10px; }

.pd-bt-15 {
  padding-bottom: 15px; }

.pd-bt-20 {
  padding-bottom: 20px; }

.pd-bt-24 {
  padding-bottom: 24px; }

.pd-bt-25 {
  padding-bottom: 25px; }

.pd-bt-30 {
  padding-bottom: 30px; }

.pd-bt-35 {
  padding-bottom: 35px; }

.pd-bt-40 {
  padding-bottom: 40px; }

.pd-bt-45 {
  padding-bottom: 45px; }

.pd-bt-50 {
  padding-bottom: 50px; }

.pd-bt-55 {
  padding-bottom: 55px; }

.pd-bt-60 {
  padding-bottom: 60px; }

/******************padding bottom End********************/
/******************padding left start********************/
.pd-lt-0 {
  padding-left: 0px; }

.pd-lt-5 {
  padding-left: 5px; }

.pd-lt-10 {
  padding-left: 10px; }

.pd-lt-15 {
  padding-left: 15px; }

.pd-lt-17 {
  padding-left: 17px; }

.pd-lt-20 {
  padding-left: 20px; }

.pd-lt-23 {
  padding-left: 23px; }

.pd-lt-25 {
  padding-left: 25px; }

.pd-lt-30 {
  padding-left: 30px; }

.pd-lt-35 {
  padding-left: 35px; }

.pd-lt-40 {
  padding-left: 40px; }

.pd-lt-45 {
  padding-left: 45px; }

.pd-lt-50 {
  padding-left: 50px; }

.pd-lt-55 {
  padding-left: 55px; }

.pd-lt-60 {
  padding-left: 60px; }

/******************padding left End********************/
/******************padding Top Bottom start********************/
.pd-tp-bt-0 {
  padding-top: 0px;
  padding-bottom: 0px; }

.pd-tp-bt-10 {
  padding-top: 10px;
  padding-bottom: 10px; }

.pd-tp-bt-13 {
  padding-top: 13px;
  padding-bottom: 13px; }

.pd-tp-bt-15 {
  padding-top: 15px;
  padding-bottom: 15px; }

.pd-tp-bt-20 {
  padding-top: 20px;
  padding-bottom: 20px; }

.pd-tp-bt-30 {
  padding-top: 30px;
  padding-bottom: 30px; }

.pd-tp-bt-40 {
  padding-top: 40px;
  padding-bottom: 40px; }

.pd-tp-bt-50 {
  padding-top: 50px;
  padding-bottom: 50px; }

/******************padding Top Bottom End********************/
/******************padding Left Right start********************/
.pd-lt-rt-0 {
  padding-left: 0;
  padding-right: 0; }

.pd-lt-rt-5 {
  padding-left: 5px;
  padding-right: 5px; }

.pd-lt-rt-10 {
  padding-left: 10px;
  padding-right: 10px; }

.pd-lt-rt-15 {
  padding-left: 15px;
  padding-right: 15px; }

.pd-lt-rt-20 {
  padding-left: 20px;
  padding-right: 20px; }

.pd-lt-rt-30 {
  padding-left: 30px;
  padding-right: 30px; }

.pd-lt-rt-40 {
  padding-left: 40px;
  padding-right: 40px; }

.pd-lt-rt-50 {
  padding-left: 50px;
  padding-right: 50px; }

/******************padding Left Right End********************/
/******************padding All site start********************/
.pd-0 {
  padding: 0; }

.pd-5 {
  padding: 5px; }

.pd-10 {
  padding: 10px; }

.pd-20 {
  padding: 20px; }

/******************padding All site End********************/
/******************margin Top start********************/
.mg-tp-0 {
  margin-top: 0; }

.mg-tp-5 {
  margin-top: 5px; }

.mg-tp-6 {
  margin-top: 6px; }

.mg-tp-8 {
  margin-top: 8px; }

.mg-tp-10 {
  margin-top: 10px; }

.mg-tp-12 {
  margin-top: 12px; }

.mg-tp-15 {
  margin-top: 15px; }

.mg-tp-16 {
  margin-top: 16px; }

.mg-tp-20 {
  margin-top: 20px; }

.mg-tp-24 {
  margin-top: 24px; }

.mg-tp-25 {
  margin-top: 25px; }

.mg-tp-30 {
  margin-top: 30px; }

.mg-tp-35 {
  margin-top: 35px; }

.mg-tp-40 {
  margin-top: 40px; }

.mg-tp-45 {
  margin-top: 45px; }

.mg-tp-50 {
  margin-top: 50px; }

/******************margin Top End********************/
/******************margin right start********************/
.mg-rt-0 {
  margin-right: 0; }

.mg-rt-5 {
  margin-right: 5px; }

.mg-rt-7 {
  margin-right: 7px; }

.mg-rt-10 {
  margin-right: 10px; }

.mg-rt-15 {
  margin-right: 15px; }

.mg-rt-20 {
  margin-right: 20px; }

.mg-rt-25 {
  margin-right: 25px; }

.mg-rt-30 {
  margin-right: 30px; }

.mg-rt-35 {
  margin-right: 35px; }

.mg-rt-40 {
  margin-right: 40px; }

.mg-rt-45 {
  margin-right: 45px; }

.mg-rt-50 {
  margin-right: 50px; }

/******************margin right End********************/
/******************margin bottom start********************/
.mg-bt-0 {
  margin-bottom: 0px; }

.mg-bt-5 {
  margin-bottom: 5px; }

.mg-bt-8 {
  margin-bottom: 8px; }

.mg-bt-10 {
  margin-bottom: 10px; }

.mg-bt-12 {
  margin-bottom: 12px; }

.mg-bt-15 {
  margin-bottom: 15px; }

.mg-bt-16 {
  margin-bottom: 16px; }

.mg-bt-20 {
  margin-bottom: 20px; }

.mg-bt-25 {
  margin-bottom: 25px; }

.mg-bt-30 {
  margin-bottom: 30px; }

.mg-bt-35 {
  margin-bottom: 35px; }

.mg-bt-40 {
  margin-bottom: 40px; }

.mg-bt-45 {
  margin-bottom: 45px; }

.mg-bt-50 {
  margin-bottom: 50px; }

/******************margin bottom End********************/
/******************margin left start********************/
.mg-lt-0 {
  margin-left: 0px; }

.mg-lt-5 {
  margin-left: 5px; }

.mg-lt-8 {
  margin-left: 8px; }

.mg-lt-9 {
  margin-left: 9px; }

.mg-lt-10 {
  margin-left: 10px; }

.mg-lt-15 {
  margin-left: 15px; }

.mg-lt-20 {
  margin-left: 20px; }

.mg-lt-25 {
  margin-left: 25px; }

.mg-lt-30 {
  margin-left: 30px; }

.mg-lt-35 {
  margin-left: 35px; }

.mg-lt-40 {
  margin-left: 40px; }

.mg-lt-45 {
  margin-left: 45px; }

.mg-lt-50 {
  margin-left: 50px; }

.mg-lt-55 {
  margin-left: 55px; }

.mg-lt-60 {
  margin-left: 60px; }

/******************margin left End********************/
/******************margin All site start********************/
.mg-auto {
  margin: 0px auto; }

.mg-0 {
  margin: 0; }

.mg-5 {
  margin: 5px; }

.mg-10 {
  margin: 10px; }

.mg-20 {
  margin: 20px; }

/******************margin All site End********************/
/******************margin Top Bottom start********************/
.mg-tp-bt-0 {
  margin-top: 0;
  margin-bottom: 0; }

.mg-tp-bt-5 {
  margin-top: 5px;
  margin-bottom: 5px; }

.mg-tp-bt-10 {
  margin-top: 10px;
  margin-bottom: 10px; }

.mg-tp-bt-15 {
  margin-top: 15px;
  margin-bottom: 15px; }

.mg-tp-bt-20 {
  margin-top: 20px;
  margin-bottom: 20px; }

.mg-tp-bt-25 {
  margin-top: 25px;
  margin-bottom: 25px; }

.mg-tp-bt-30 {
  margin-top: 30px;
  margin-bottom: 30px; }

.mg-tp-bt-40 {
  margin-top: 40px;
  margin-bottom: 40px; }

.mg-tp-bt-50 {
  margin-top: 50px;
  margin-bottom: 50px; }

/******************margin Top Bottom End********************/
/******************margin Left right start********************/
.mg-lt-rt-5 {
  margin-left: 5px;
  margin-right: 5px; }

.mg-lt-rt-10 {
  margin-left: 10px;
  margin-right: 10px; }

.mg-lt-rt-20 {
  margin-left: 20px;
  margin-right: 20px; }

.mg-lt-rt-30 {
  margin-left: 30px;
  margin-right: 30px; }

.mg-lt-rt-40 {
  margin-left: 40px;
  margin-right: 40px; }

.mg-lt-rt-50 {
  margin-left: 50px;
  margin-right: 50px; }

/******************margin Left right End********************/
/******************font********************/
.ft-size-15 {
  font-size: 15px; }

.ft-size-14 {
  font-size: 14px; }

.ft-size-12 {
  font-size: 12px; }

.ft-size-10 {
  font-size: 10px; }

/****************************************reponsive*******************/
@media only screen and (max-width: 1199px) {
  .mb-ads-mg-bt-15 {
    margin-bottom: 15px; } }

@media only screen and (max-width: 768px) {
  .mb-mg-tp-12 {
    margin-top: 12px; }
  .mb-mg-bt-15 {
    margin-bottom: 15px; } }

@media only screen and (max-width: 767px) {
  .mb-mg-tp-14 {
    margin-top: 14px; }
  .mb-mg-tp-15 {
    margin-top: 15px; }
  .mb-mg-tp-10 {
    margin-top: 10px; }
  .mb-mg-bt-10 {
    margin-bottom: 10px; }
  .mb-mg-bt-14 {
    margin-bottom: 14px; } }

/*****************************Footer start***********************************/
.footer {
  width: 100%;
  float: left;
  padding-top: 60px; }
  @media only screen and (max-width: 767px) {
    .footer {
      padding-top: 30px; } }
  .footer .footer-banner {
    text-align: center; }
    .footer .footer-banner .banner-ads {
      width: 728px;
      height: 90px;
      display: inline-block;
      float: none;
      margin: 0px auto; }
      @media only screen and (max-width: 1025px) {
        .footer .footer-banner .banner-ads {
          display: none; } }
  .footer .footer-bg {
    width: 100%;
    float: left;
    padding: 115px 60px 80px 60px;
    position: relative;
    margin-top: 140px;
    font-size: 12px;
    word-break: break-word;
    /* &:after {
			position:absolute;
			top:-212px;
			left:calc(100% - 830px);
			background-repeat:no-repeat;
			background-size:cover;
			background-position:top right;
			background-image:url(../img/nd/footer-bg.png);
			width:830px;
			content:"";
			min-height:309px;
			@include breakpointMax(1400) {
				top:-184px;
				left:calc(100% - 700px);
				min-height: 260px;
				width:700px;
			}
			@include breakpointMax(1200) {
				top:-155px;
				left:calc(100% - 616px);
				min-height:227px;
				width:616px;
			}
			@include breakpointMax(1025) {
				display:none;
			}
		} */ }
    @media only screen and (max-width: 1800px) {
      .footer .footer-bg {
        padding: 140px 40px 80px 40px; } }
    @media only screen and (max-width: 1400px) {
      .footer .footer-bg {
        padding: 120px 30px 80px 30px; } }
    @media only screen and (max-width: 1025px) {
      .footer .footer-bg {
        margin-top: 0;
        padding: 30px 15px 30px 15px; } }
    @media only screen and (max-width: 800px) {
      .footer .footer-bg {
        font-size: 11px; } }
    @media only screen and (max-width: 576px) {
      .footer .footer-bg {
        padding: 20px 20px 20px 20px; } }
    .footer .footer-bg .row.custom-row {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      margin-left: 0px;
      margin-right: 0px; }
      .footer .footer-bg .row.custom-row > div {
        display: inline-block;
        vertical-align: top; }
    .footer .footer-bg .search-footer {
      display: none; }
    .footer .footer-bg h4 {
      font-size: 16px;
      margin-bottom: 13px; }
    .footer .footer-bg .footer-nav-sm-title {
      font-size: 16px;
      margin-bottom: 13px;
      display: block;
      width: 100%;
      font-weight: 400; }
    .footer .footer-bg a {
      display: inline-block;
      font-size: 12px; }
      @media only screen and (max-width: 800px) {
        .footer .footer-bg a {
          font-size: 11px; } }
    .footer .footer-bg .footer-bg-image {
      position: absolute;
      top: -212px;
      left: calc(100% - 830px);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top right;
      background-image: url(../img/nd/footer-bg.png);
      width: 830px;
      content: "";
      min-height: 309px; }
      @media only screen and (max-width: 1400px) {
        .footer .footer-bg .footer-bg-image {
          top: -184px;
          left: calc(100% - 700px);
          min-height: 260px;
          width: 700px; } }
      @media only screen and (max-width: 1200px) {
        .footer .footer-bg .footer-bg-image {
          top: -155px;
          left: calc(100% - 616px);
          min-height: 227px;
          width: 616px; } }
      @media only screen and (max-width: 1025px) {
        .footer .footer-bg .footer-bg-image {
          display: none; } }
    .footer .footer-bg .footer-logo {
      /* @include breakpointMax(1025) {
				width: 90px;	
				height: auto;
			}*/ }
      .footer .footer-bg .footer-logo .footer-logo-text {
        display: block;
        margin: 5px 0 8px; }
      .footer .footer-bg .footer-logo img {
        width: 100%;
        height: auto;
        max-width: 95px;
        margin-bottom: 12px; }
      @media only screen and (max-width: 767px) {
        .footer .footer-bg .footer-logo {
          max-width: 70px;
          margin-bottom: 20px;
          text-align: center;
          display: inline-block;
          text-align: center; } }
    .footer .footer-bg .footer-sub-link .footer-nav-box {
      width: 320px;
      padding: 0 15px;
      display: inline-block;
      vertical-align: top; }
    @media only screen and (max-width: 1199px) {
      .footer .footer-bg .footer-nav {
        margin-bottom: 20px; } }
    .footer .footer-bg .footer-nav ul {
      list-style: none;
      padding: 0;
      margin: 0; }
    .footer .footer-bg .footer-nav li {
      display: block;
      list-style: none;
      margin-bottom: 10px; }
    .footer .footer-bg .address-details .company_address {
      display: block;
      margin-bottom: 10px; }
    .footer .footer-bg .footer-parter {
      margin-top: 0px; }
      @media only screen and (max-width: 1025px) {
        .footer .footer-bg .footer-parter {
          margin-top: 0;
          text-align: center; } }
      @media only screen and (max-width: 1270px) {
        .footer .footer-bg .footer-parter {
          margin-top: 0px; } }
      @media only screen and (max-width: 1025px) {
        .footer .footer-bg .footer-parter {
          margin-top: 0; } }
      @media only screen and (max-width: 576px) {
        .footer .footer-bg .footer-parter {
          margin-top: 20px; } }
      .footer .footer-bg .footer-parter .parter-logo {
        display: block;
        margin-bottom: 15px; }
        .footer .footer-bg .footer-parter .parter-logo .parter-logo-text {
          display: block;
          margin: 5px 0 8px; }
        .footer .footer-bg .footer-parter .parter-logo img {
          width: 100%;
          max-width: 120px;
          height: auto; }
          .footer .footer-bg .footer-parter .parter-logo img.mw-110 {
            max-width: 110px; }
          .footer .footer-bg .footer-parter .parter-logo img.mw-100 {
            max-width: 100px; }
          .footer .footer-bg .footer-parter .parter-logo img.mw-90 {
            max-width: 90px; }
          .footer .footer-bg .footer-parter .parter-logo img.mw-80 {
            max-width: 80px; }
          .footer .footer-bg .footer-parter .parter-logo img.mw-70 {
            max-width: 70px; }
          .footer .footer-bg .footer-parter .parter-logo img.mw-60 {
            max-width: 60px; }
          .footer .footer-bg .footer-parter .parter-logo img.mw-50 {
            max-width: 50px; }
        @media only screen and (max-width: 1410px) {
          .footer .footer-bg .footer-parter .parter-logo {
            margin-left: 0px; } }
        @media only screen and (max-width: 1270px) {
          .footer .footer-bg .footer-parter .parter-logo {
            text-align: center; } }
        @media only screen and (max-width: 1200px) {
          .footer .footer-bg .footer-parter .parter-logo {
            margin-left: 0px;
            margin-bottom: 15px;
            display: block; } }
        @media only screen and (max-width: 767px) {
          .footer .footer-bg .footer-parter .parter-logo {
            display: inline-block;
            vertical-align: top;
            margin-left: 7px;
            margin-right: 7px;
            margin-bottom: 15px;
            margin-top: 10px; }
            .footer .footer-bg .footer-parter .parter-logo img {
              max-width: 87px; } }
        @media only screen and (max-width: 576px) {
          .footer .footer-bg .footer-parter .parter-logo {
            margin-bottom: 10px; } }
        @media only screen and (max-width: 350px) {
          .footer .footer-bg .footer-parter .parter-logo {
            margin-left: 13px; } }
    .footer .footer-bg .external-images {
      text-align: center; }
      @media only screen and (max-width: 576px) {
        .footer .footer-bg .external-images {
          /* display: none; */ } }
      .footer .footer-bg .external-images .external-image img {
        width: 100%;
        max-width: 280px;
        height: auto; }
        .footer .footer-bg .external-images .external-image img.mw-250 {
          max-width: 250px; }
        .footer .footer-bg .external-images .external-image img.mw-220 {
          max-width: 220px; }
        .footer .footer-bg .external-images .external-image img.mw-200 {
          max-width: 200px; }
        .footer .footer-bg .external-images .external-image img.mw-180 {
          max-width: 180px; }
        .footer .footer-bg .external-images .external-image img.mw-160 {
          max-width: 160px; }
        .footer .footer-bg .external-images .external-image img.mw-150 {
          max-width: 150px; }
        .footer .footer-bg .external-images .external-image img.mw-130 {
          max-width: 130px; }
        .footer .footer-bg .external-images .external-image img.mw-120 {
          max-width: 120px; }
        .footer .footer-bg .external-images .external-image img.mw-100 {
          max-width: 100px; }
        .footer .footer-bg .external-images .external-image img.mw-80 {
          max-width: 80px; }
        .footer .footer-bg .external-images .external-image img.mw-60 {
          max-width: 60px; }
        .footer .footer-bg .external-images .external-image img.mw-50 {
          max-width: 50px; }
    .footer .footer-bg .footer-social {
      margin-top: 50px;
      text-align: center; }
      .footer .footer-bg .footer-social.footer-social-sm {
        margin-top: 24px; }
      .footer .footer-bg .footer-social.footer-social-mt-0 {
        margin-top: 0; }
      @media only screen and (max-width: 1800px) {
        .footer .footer-bg .footer-social {
          margin-top: 0px; } }
      @media only screen and (max-width: 1199px) {
        .footer .footer-bg .footer-social {
          text-align: center;
          margin-top: 0px; } }
      @media only screen and (max-width: 767px) {
        .footer .footer-bg .footer-social {
          margin-top: 0px; } }
      @media only screen and (max-width: 576px) {
        .footer .footer-bg .footer-social {
          /* display: none; */ } }
      .footer .footer-bg .footer-social ul {
        margin: 0px;
        padding: 0px; }
      .footer .footer-bg .footer-social li {
        display: inline-block;
        padding: 0;
        list-style: none;
        display: inline-block;
        width: 40px;
        border-radius: 100%;
        text-align: center;
        margin-bottom: 10px;
        margin-right: 4px;
        margin-left: 4px;
        height: 40px;
        line-height: 38px;
        position: relative;
        z-index: 8; }
        .footer .footer-bg .footer-social li:after {
          content: '';
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          position: absolute;
          border-radius: 100%;
          opacity: 0.24; }
        .footer .footer-bg .footer-social li:first-child {
          margin-left: 0px; }
        @media only screen and (max-width: 1440px) {
          .footer .footer-bg .footer-social li {
            margin: 0 5px 10px 5px;
            width: 35px;
            height: 35px;
            line-height: 33px; } }
        @media only screen and (max-width: 1024px) {
          .footer .footer-bg .footer-social li {
            display: inline-block;
            margin: 0 3px 10px 3px;
            width: 25px;
            height: 25px;
            line-height: 26px; } }
        @media only screen and (max-width: 767px) {
          .footer .footer-bg .footer-social li {
            display: inline-block;
            margin: 0 5px;
            vertical-align: middle;
            width: 35px;
            height: 35px;
            line-height: 33px; }
            .footer .footer-bg .footer-social li:first-child {
              margin: 0 5px; } }
        .footer .footer-bg .footer-social li:hover:after {
          opacity: 0.50; }
        .footer .footer-bg .footer-social li a {
          text-align: center;
          display: block;
          z-index: 99;
          position: relative; }
      .footer .footer-bg .footer-social i {
        font-size: 20px;
        display: inline-block;
        vertical-align: middle;
        line-height: 39px; }
        @media only screen and (max-width: 1440px) {
          .footer .footer-bg .footer-social i {
            font-size: 16px;
            line-height: 33px; } }
        @media only screen and (max-width: 1024px) {
          .footer .footer-bg .footer-social i {
            font-size: 12px;
            line-height: 25px; } }
        @media only screen and (max-width: 767px) {
          .footer .footer-bg .footer-social i {
            font-size: 16px;
            line-height: 33px; } }
      .footer .footer-bg .footer-social .svg-inline--fa {
        font-size: 18px;
        float: none;
        vertical-align: middle;
        height: 19px; }
        @media only screen and (max-width: 1440px) {
          .footer .footer-bg .footer-social .svg-inline--fa {
            font-size: 16px;
            height: 20px; } }
        @media only screen and (max-width: 1024px) {
          .footer .footer-bg .footer-social .svg-inline--fa {
            font-size: 13px;
            height: 13px; } }
        @media only screen and (max-width: 767px) {
          .footer .footer-bg .footer-social .svg-inline--fa {
            height: 17px; } }
    @media only screen and (max-width: 576px) {
      .footer .footer-bg .d-sm-none .external-images {
        display: none; } }
    @media only screen and (max-width: 576px) {
      .footer .footer-bg .d-sm-none .footer-social {
        display: none; } }
    .footer .footer-bg .footer-second-social {
      width: 100%;
      margin-top: 10px;
      display: inline-block; }
      @media only screen and (max-width: 576px) {
        .footer .footer-bg .footer-second-social .footer-social {
          display: block; }
        .footer .footer-bg .footer-second-social .external-images {
          display: block; } }
  .footer .footer-team {
    width: 100%;
    float: left;
    text-align: center;
    font-size: 9px;
    padding: 25px 20px; }
    @media only screen and (max-width: 1024px) {
      .footer .footer-team {
        text-align: left;
        padding: 25px 20px 10px 20px; } }
    .footer .footer-team .team-text {
      padding-bottom: 15px;
      display: block; }
      .footer .footer-team .team-text a {
        font-size: 9px; }

.footer-otherstyle {
  width: 100%;
  display: block;
  /* margin-top: 120px; */ }
  .footer-otherstyle .footer-patternbg {
    width: 100%;
    display: block;
    /* background-image:url(../img/nd/footer-pattern-bg.jpg);
		background-repeat:repeat; */ }
    .footer-otherstyle .footer-patternbg .footer-bg-color {
      width: 100%;
      display: block;
      padding: 25px 0px 28px 0px; }
      .footer-otherstyle .footer-patternbg .footer-bg-color .footer-logo-box {
        width: 100%;
        display: block; }
        @media only screen and (max-width: 767px) {
          .footer-otherstyle .footer-patternbg .footer-bg-color .footer-logo-box {
            width: 100%;
            display: block;
            text-align: center; } }
        .footer-otherstyle .footer-patternbg .footer-bg-color .footer-logo-box .footer-logo {
          width: 228px;
          display: inline-block;
          margin-bottom: 20px; }
          .footer-otherstyle .footer-patternbg .footer-bg-color .footer-logo-box .footer-logo img {
            max-width: 270px; }
            @media only screen and (max-width: 1120px) {
              .footer-otherstyle .footer-patternbg .footer-bg-color .footer-logo-box .footer-logo img {
                max-width: 250px; } }
          @media only screen and (max-width: 1024px) {
            .footer-otherstyle .footer-patternbg .footer-bg-color .footer-logo-box .footer-logo {
              width: 100%; } }
          @media only screen and (max-width: 767px) {
            .footer-otherstyle .footer-patternbg .footer-bg-color .footer-logo-box .footer-logo {
              width: 228px; } }
          @media only screen and (max-width: 767px) {
            .footer-otherstyle .footer-patternbg .footer-bg-color .footer-logo-box .footer-logo {
              margin-bottom: 10px; } }
      .footer-otherstyle .footer-patternbg .footer-bg-color .footer-left-info {
        width: 100%;
        /* display:block; */
        position: absolute;
        bottom: 0;
        left: 0;
        text-align: center; }
        @media only screen and (max-width: 767px) {
          .footer-otherstyle .footer-patternbg .footer-bg-color .footer-left-info {
            text-align: center;
            position: inherit;
            bottom: inherit;
            margin-bottom: 20px; } }
        .footer-otherstyle .footer-patternbg .footer-bg-color .footer-left-info .company-name-info {
          width: 100%;
          display: block;
          font-size: 17px; }
        .footer-otherstyle .footer-patternbg .footer-bg-color .footer-left-info .company-address-info {
          width: 100%;
          display: block;
          font-size: 14px;
          padding-top: 5px;
          padding-bottom: 3px; }
          .footer-otherstyle .footer-patternbg .footer-bg-color .footer-left-info .company-address-info .company-box {
            display: inline-block;
            vertical-align: middle;
            border-right: 1px solid #fff;
            line-height: 14px;
            padding-right: 5px;
            padding-left: 5px; }
            .footer-otherstyle .footer-patternbg .footer-bg-color .footer-left-info .company-address-info .company-box:last-child {
              padding-right: 0px;
              border-right: none; }
            .footer-otherstyle .footer-patternbg .footer-bg-color .footer-left-info .company-address-info .company-box:first-child {
              padding-left: 0px; }
        .footer-otherstyle .footer-patternbg .footer-bg-color .footer-left-info .footer-left-nav {
          width: 100%;
          display: block;
          padding-top: 9px; }
          .footer-otherstyle .footer-patternbg .footer-bg-color .footer-left-info .footer-left-nav ul {
            list-style: none;
            padding: 0;
            margin: 0; }
            .footer-otherstyle .footer-patternbg .footer-bg-color .footer-left-info .footer-left-nav ul li {
              display: inline-block;
              list-style: none;
              position: relative;
              line-height: 14px;
              border-right: 1px solid #fff;
              margin-bottom: 6px; }
              .footer-otherstyle .footer-patternbg .footer-bg-color .footer-left-info .footer-left-nav ul li a {
                font-size: 18px;
                padding: 0px 7px 0px 7px;
                line-height: 14px;
                text-decoration: underline; }
                @media only screen and (max-width: 1030px) {
                  .footer-otherstyle .footer-patternbg .footer-bg-color .footer-left-info .footer-left-nav ul li a {
                    font-size: 15px; } }
              .footer-otherstyle .footer-patternbg .footer-bg-color .footer-left-info .footer-left-nav ul li:first-child a {
                padding: 0px 7px 0px 0px;
                display: inline-block; }
              .footer-otherstyle .footer-patternbg .footer-bg-color .footer-left-info .footer-left-nav ul li:last-child {
                border-right: none; }
      .footer-otherstyle .footer-patternbg .footer-bg-color .footer-nav-box {
        width: 100%;
        /* display:table-cell;
				vertical-align:bottom; */
        text-align: right;
        display: block; }
        @media only screen and (max-width: 767px) {
          .footer-otherstyle .footer-patternbg .footer-bg-color .footer-nav-box {
            margin-top: 5px;
            display: block;
            text-align: center;
            position: inherit;
            bottom: inherit;
            right: inherit; } }
        .footer-otherstyle .footer-patternbg .footer-bg-color .footer-nav-box .footer-nav ul {
          list-style: none;
          padding: 0;
          margin: 0; }
          .footer-otherstyle .footer-patternbg .footer-bg-color .footer-nav-box .footer-nav ul li {
            display: block;
            list-style: none;
            padding: 0px;
            margin: 0 0 25px 0; }
            @media only screen and (max-width: 768px) {
              .footer-otherstyle .footer-patternbg .footer-bg-color .footer-nav-box .footer-nav ul li {
                padding: 0px;
                display: inline-block;
                margin: 0 5px 25px 5px; } }
            .footer-otherstyle .footer-patternbg .footer-bg-color .footer-nav-box .footer-nav ul li:first-child {
              padding: 0px; }
              @media only screen and (max-width: 768px) {
                .footer-otherstyle .footer-patternbg .footer-bg-color .footer-nav-box .footer-nav ul li:first-child {
                  padding: 0px; } }
            .footer-otherstyle .footer-patternbg .footer-bg-color .footer-nav-box .footer-nav ul li:last-child {
              padding: 0px; }
              @media only screen and (max-width: 768px) {
                .footer-otherstyle .footer-patternbg .footer-bg-color .footer-nav-box .footer-nav ul li:last-child {
                  padding: 0px; } }
            .footer-otherstyle .footer-patternbg .footer-bg-color .footer-nav-box .footer-nav ul li a {
              font-size: 20px;
              display: inline-block;
              text-transform: uppercase;
              padding: 15px 17px;
              border: 1px solid #fff;
              min-width: 218px;
              text-align: center; }
              @media only screen and (max-width: 1080px) {
                .footer-otherstyle .footer-patternbg .footer-bg-color .footer-nav-box .footer-nav ul li a {
                  min-width: 100%; } }
              @media only screen and (max-width: 1260px) {
                .footer-otherstyle .footer-patternbg .footer-bg-color .footer-nav-box .footer-nav ul li a {
                  font-size: 18px; } }
              @media only screen and (max-width: 1030px) {
                .footer-otherstyle .footer-patternbg .footer-bg-color .footer-nav-box .footer-nav ul li a {
                  font-size: 15px;
                  padding: 15px 10px; } }
              @media only screen and (max-width: 767px) {
                .footer-otherstyle .footer-patternbg .footer-bg-color .footer-nav-box .footer-nav ul li a {
                  min-width: inherit;
                  width: auto; } }
              @media only screen and (max-width: 768px) {
                .footer-otherstyle .footer-patternbg .footer-bg-color .footer-nav-box .footer-nav ul li a {
                  font-size: 14px; } }
      .footer-otherstyle .footer-patternbg .footer-bg-color .footer-brokerage-info {
        width: 100%;
        display: block;
        margin-bottom: 22px; }
        .footer-otherstyle .footer-patternbg .footer-bg-color .footer-brokerage-info .footer-brokerage-box {
          width: 47%;
          display: inline-block;
          margin-right: 15px;
          vertical-align: top;
          padding-left: 10px; }
          @media only screen and (max-width: 900px) {
            .footer-otherstyle .footer-patternbg .footer-bg-color .footer-brokerage-info .footer-brokerage-box {
              width: 100%;
              margin-right: 0px;
              padding-left: 0px;
              text-align: center; }
              .footer-otherstyle .footer-patternbg .footer-bg-color .footer-brokerage-info .footer-brokerage-box:first-child {
                margin-bottom: 15px; }
              .footer-otherstyle .footer-patternbg .footer-bg-color .footer-brokerage-info .footer-brokerage-box:last-child {
                margin-bottom: 0px; } }
          @media only screen and (max-width: 767px) {
            .footer-otherstyle .footer-patternbg .footer-bg-color .footer-brokerage-info .footer-brokerage-box {
              width: 50%;
              margin-right: 0px;
              padding: 0 7px;
              margin-bottom: 15px;
              text-align: left; }
              .footer-otherstyle .footer-patternbg .footer-bg-color .footer-brokerage-info .footer-brokerage-box:last-child {
                margin-bottom: 0px; } }
          @media only screen and (max-width: 374px) {
            .footer-otherstyle .footer-patternbg .footer-bg-color .footer-brokerage-info .footer-brokerage-box {
              width: 100%;
              padding: 0px;
              text-align: center; } }
          .footer-otherstyle .footer-patternbg .footer-bg-color .footer-brokerage-info .footer-brokerage-box:last-child {
            margin-right: 0px; }
          .footer-otherstyle .footer-patternbg .footer-bg-color .footer-brokerage-info .footer-brokerage-box .brokerage-cnt-box {
            width: 100%;
            display: block;
            font-size: 15px;
            margin-bottom: 13px;
            word-break: break-word; }
            @media only screen and (max-width: 900px) {
              .footer-otherstyle .footer-patternbg .footer-bg-color .footer-brokerage-info .footer-brokerage-box .brokerage-cnt-box {
                margin-bottom: 5px;
                font-size: 14px; } }
            @media only screen and (max-width: 374px) {
              .footer-otherstyle .footer-patternbg .footer-bg-color .footer-brokerage-info .footer-brokerage-box .brokerage-cnt-box {
                margin-bottom: 8px; } }
            .footer-otherstyle .footer-patternbg .footer-bg-color .footer-brokerage-info .footer-brokerage-box .brokerage-cnt-box.ft-17 {
              font-size: 17px; }
              @media only screen and (max-width: 1030px) {
                .footer-otherstyle .footer-patternbg .footer-bg-color .footer-brokerage-info .footer-brokerage-box .brokerage-cnt-box.ft-17 {
                  font-size: 16px; } }
            .footer-otherstyle .footer-patternbg .footer-bg-color .footer-brokerage-info .footer-brokerage-box .brokerage-cnt-box:last-child {
              margin-bottom: 0px; }
            .footer-otherstyle .footer-patternbg .footer-bg-color .footer-brokerage-info .footer-brokerage-box .brokerage-cnt-box.brokerage-logo-kw {
              width: 138px;
              display: block; }
              @media only screen and (max-width: 900px) {
                .footer-otherstyle .footer-patternbg .footer-bg-color .footer-brokerage-info .footer-brokerage-box .brokerage-cnt-box.brokerage-logo-kw {
                  display: inline-block; } }
              @media only screen and (max-width: 1030px) {
                .footer-otherstyle .footer-patternbg .footer-bg-color .footer-brokerage-info .footer-brokerage-box .brokerage-cnt-box.brokerage-logo-kw {
                  width: 110px; } }
              @media only screen and (max-width: 374px) {
                .footer-otherstyle .footer-patternbg .footer-bg-color .footer-brokerage-info .footer-brokerage-box .brokerage-cnt-box.brokerage-logo-kw {
                  display: inline-block;
                  width: 100px; } }
            .footer-otherstyle .footer-patternbg .footer-bg-color .footer-brokerage-info .footer-brokerage-box .brokerage-cnt-box.brokerage-logo-llc {
              width: 80px;
              display: block; }
              @media only screen and (max-width: 900px) {
                .footer-otherstyle .footer-patternbg .footer-bg-color .footer-brokerage-info .footer-brokerage-box .brokerage-cnt-box.brokerage-logo-llc {
                  display: inline-block; } }
              @media only screen and (max-width: 1030px) {
                .footer-otherstyle .footer-patternbg .footer-bg-color .footer-brokerage-info .footer-brokerage-box .brokerage-cnt-box.brokerage-logo-llc {
                  width: 70px; } }
              @media only screen and (max-width: 374px) {
                .footer-otherstyle .footer-patternbg .footer-bg-color .footer-brokerage-info .footer-brokerage-box .brokerage-cnt-box.brokerage-logo-llc {
                  display: inline-block;
                  width: 70px; } }
      .footer-otherstyle .footer-patternbg .footer-bg-color .footer-mls-logos {
        width: 100%;
        display: block;
        text-align: left; }
        @media only screen and (max-width: 767px) {
          .footer-otherstyle .footer-patternbg .footer-bg-color .footer-mls-logos {
            text-align: center;
            margin-top: 20px; } }
        .footer-otherstyle .footer-patternbg .footer-bg-color .footer-mls-logos .footer-mls-logobox {
          width: auto;
          display: inline-block;
          margin-right: 30px;
          margin-top: 15px;
          vertical-align: top; }
          @media only screen and (max-width: 1480px) {
            .footer-otherstyle .footer-patternbg .footer-bg-color .footer-mls-logos .footer-mls-logobox {
              margin-right: 20px; } }
          @media only screen and (max-width: 1299px) {
            .footer-otherstyle .footer-patternbg .footer-bg-color .footer-mls-logos .footer-mls-logobox {
              margin-right: 15px; } }
          @media only screen and (max-width: 767px) {
            .footer-otherstyle .footer-patternbg .footer-bg-color .footer-mls-logos .footer-mls-logobox {
              margin-right: 14px;
              margin-bottom: 17px; } }
          @media only screen and (max-width: 374px) {
            .footer-otherstyle .footer-patternbg .footer-bg-color .footer-mls-logos .footer-mls-logobox {
              /* width:100%;
						margin-right:0px; */
              width: auto;
              margin-right: 14px; } }
          .footer-otherstyle .footer-patternbg .footer-bg-color .footer-mls-logos .footer-mls-logobox img {
            width: auto;
            height: 50px; }
            @media only screen and (max-width: 1480px) {
              .footer-otherstyle .footer-patternbg .footer-bg-color .footer-mls-logos .footer-mls-logobox img {
                height: 47px; } }
            @media only screen and (max-width: 1280px) {
              .footer-otherstyle .footer-patternbg .footer-bg-color .footer-mls-logos .footer-mls-logobox img {
                height: 45px; } }
            @media only screen and (max-width: 1230px) {
              .footer-otherstyle .footer-patternbg .footer-bg-color .footer-mls-logos .footer-mls-logobox img {
                height: 40px; } }
            @media only screen and (max-width: 1120px) {
              .footer-otherstyle .footer-patternbg .footer-bg-color .footer-mls-logos .footer-mls-logobox img {
                height: 37px; } }
            @media only screen and (max-width: 1030px) {
              .footer-otherstyle .footer-patternbg .footer-bg-color .footer-mls-logos .footer-mls-logobox img {
                height: 37px; } }
            @media only screen and (max-width: 1000px) {
              .footer-otherstyle .footer-patternbg .footer-bg-color .footer-mls-logos .footer-mls-logobox img {
                height: 27px; } }
            @media only screen and (max-width: 374px) {
              .footer-otherstyle .footer-patternbg .footer-bg-color .footer-mls-logos .footer-mls-logobox img {
                height: 35px; } }
          .footer-otherstyle .footer-patternbg .footer-bg-color .footer-mls-logos .footer-mls-logobox:last-child {
            margin-right: 0px; }
      .footer-otherstyle .footer-patternbg .footer-bg-color .powerby-logo {
        width: auto;
        float: right;
        text-align: left;
        margin-top: 18px;
        min-width: 220px; }
        @media only screen and (max-width: 1080px) {
          .footer-otherstyle .footer-patternbg .footer-bg-color .powerby-logo {
            min-width: 100%; } }
        @media only screen and (max-width: 767px) {
          .footer-otherstyle .footer-patternbg .footer-bg-color .powerby-logo {
            margin-bottom: 15px;
            text-align: center; } }
        .footer-otherstyle .footer-patternbg .footer-bg-color .powerby-logo .powerby-text-box {
          width: 100%;
          display: block;
          color: #ffffff;
          margin-bottom: 18px;
          font-size: 18px; }
        .footer-otherstyle .footer-patternbg .footer-bg-color .powerby-logo .powerby-logo-box {
          width: 100%;
          display: block; }
          .footer-otherstyle .footer-patternbg .footer-bg-color .powerby-logo .powerby-logo-box img {
            width: 100%;
            max-width: 200px;
            height: auto; }
            @media only screen and (max-width: 1280px) {
              .footer-otherstyle .footer-patternbg .footer-bg-color .powerby-logo .powerby-logo-box img {
                max-width: 170px; } }
      .footer-otherstyle .footer-patternbg .footer-bg-color .footer-other {
        width: 100%;
        display: block;
        padding: 0 55px 0 55px; }
        @media only screen and (max-width: 1440px) {
          .footer-otherstyle .footer-patternbg .footer-bg-color .footer-other {
            padding: 0 50px; } }
        @media only screen and (max-width: 1030px) {
          .footer-otherstyle .footer-patternbg .footer-bg-color .footer-other {
            padding: 0 20px; } }
      .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-footer-column {
        display: table;
        text-align: center; }
        .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-footer-column .footer-logo {
          width: 90%;
          margin-bottom: 5px; }
        .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-footer-column .footer-social-icon {
          width: 100%;
          display: block;
          margin-top: 25px; }
          .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-footer-column .footer-social-icon .social-icon-list {
            margin: 0px;
            padding: 0px; }
            .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-footer-column .footer-social-icon .social-icon-list li {
              list-style-type: none;
              display: inline-block;
              vertical-align: middle;
              margin-right: 27px;
              margin-bottom: 8px; }
              @media only screen and (max-width: 1024px) {
                .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-footer-column .footer-social-icon .social-icon-list li {
                  margin-right: 24px; } }
              @media only screen and (max-width: 768px) {
                .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-footer-column .footer-social-icon .social-icon-list li {
                  margin-right: 19px; } }
              @media only screen and (max-width: 580px) {
                .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-footer-column .footer-social-icon .social-icon-list li {
                  margin-right: 17px; } }
              .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-footer-column .footer-social-icon .social-icon-list li:last-child {
                margin-right: 0px; }
              .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-footer-column .footer-social-icon .social-icon-list li a {
                display: block;
                cursor: pointer; }
                .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-footer-column .footer-social-icon .social-icon-list li a.fb-footer-icon {
                  background-image: url(../img/nd/fb-up.jpg);
                  background-repeat: no-repeat;
                  width: 40px;
                  height: 40px; }
                  .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-footer-column .footer-social-icon .social-icon-list li a.fb-footer-icon:hover {
                    background-image: url(../img/nd/fb-down.jpg); }
                .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-footer-column .footer-social-icon .social-icon-list li a.ig-footer-icon {
                  background-image: url(../img/nd/ig-up.jpg);
                  background-repeat: no-repeat;
                  width: 40px;
                  height: 40px; }
                  .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-footer-column .footer-social-icon .social-icon-list li a.ig-footer-icon:hover {
                    background-image: url(../img/nd/ig-down.jpg); }
                .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-footer-column .footer-social-icon .social-icon-list li a.in-footer-icon {
                  background-image: url(../img/nd/in-up.jpg);
                  background-repeat: no-repeat;
                  width: 40px;
                  height: 40px; }
                  .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-footer-column .footer-social-icon .social-icon-list li a.in-footer-icon:hover {
                    background-image: url(../img/nd/in-down.jpg); }
                .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-footer-column .footer-social-icon .social-icon-list li a.tk-footer-icon {
                  background-image: url(../img/nd/tk-up.jpg);
                  background-repeat: no-repeat;
                  width: 40px;
                  height: 40px; }
                  .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-footer-column .footer-social-icon .social-icon-list li a.tk-footer-icon:hover {
                    background-image: url(../img/nd/tk-down.jpg); }
      @media only screen and (max-width: 767px) {
        .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-othermls-column {
          margin-top: 10px; } }
      .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-othermls-column .footer-brokerage-info .footer-brokerage-box {
        width: 100%;
        display: block;
        margin-right: 0px;
        padding-left: 0px;
        margin-bottom: 35px; }
        .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-othermls-column .footer-brokerage-info .footer-brokerage-box .brokerage-cnt-box {
          display: inline-block;
          margin-bottom: 3px; }
          .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-othermls-column .footer-brokerage-info .footer-brokerage-box .brokerage-cnt-box.ft-17 {
            font-size: 18px; }
          .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-othermls-column .footer-brokerage-info .footer-brokerage-box .brokerage-cnt-box.brokerage-logo-kw {
            width: 140px;
            display: inline-block;
            vertical-align: top; }
            .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-othermls-column .footer-brokerage-info .footer-brokerage-box .brokerage-cnt-box.brokerage-logo-kw img {
              width: auto;
              max-width: 126px; }
              @media only screen and (max-width: 1030px) {
                .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-othermls-column .footer-brokerage-info .footer-brokerage-box .brokerage-cnt-box.brokerage-logo-kw img {
                  max-width: 100px; } }
            @media only screen and (max-width: 1030px) {
              .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-othermls-column .footer-brokerage-info .footer-brokerage-box .brokerage-cnt-box.brokerage-logo-kw {
                width: 100px; } }
          .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-othermls-column .footer-brokerage-info .footer-brokerage-box .brokerage-cnt-box.brokerage-logo-llc {
            width: 140px;
            display: inline-block;
            vertical-align: top; }
            .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-othermls-column .footer-brokerage-info .footer-brokerage-box .brokerage-cnt-box.brokerage-logo-llc img {
              width: auto;
              max-width: 126px; }
              @media only screen and (max-width: 1030px) {
                .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-othermls-column .footer-brokerage-info .footer-brokerage-box .brokerage-cnt-box.brokerage-logo-llc img {
                  max-width: 100px; } }
            @media only screen and (max-width: 1030px) {
              .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-othermls-column .footer-brokerage-info .footer-brokerage-box .brokerage-cnt-box.brokerage-logo-llc {
                width: 100px; } }
          .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-othermls-column .footer-brokerage-info .footer-brokerage-box .brokerage-cnt-box .address-border {
            display: inline-block;
            border-left: 1px solid #ffffff;
            padding-left: 5px;
            margin-left: 3px;
            line-height: 15px;
            vertical-align: middle; }
        .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-othermls-column .footer-brokerage-info .footer-brokerage-box .footer-brokerage-data {
          width: calc(100% - 140px);
          display: inline-block;
          padding-left: 20px;
          font-size: 18px; }
          @media only screen and (max-width: 1030px) {
            .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-othermls-column .footer-brokerage-info .footer-brokerage-box .footer-brokerage-data {
              padding-left: 20px;
              width: calc(100% - 100px); } }
          @media only screen and (max-width: 600px) {
            .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-othermls-column .footer-brokerage-info .footer-brokerage-box .footer-brokerage-data {
              width: 100%;
              margin-top: 15px;
              padding-left: 0px; } }
        .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-othermls-column .footer-brokerage-info .footer-brokerage-box:last-child {
          margin-bottom: 0px; }
        @media only screen and (max-width: 900px) {
          .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-othermls-column .footer-brokerage-info .footer-brokerage-box {
            text-align: left; } }
        @media only screen and (max-width: 767px) {
          .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-othermls-column .footer-brokerage-info .footer-brokerage-box {
            width: 50%;
            display: inline-block;
            margin-bottom: 10px; } }
        @media only screen and (max-width: 600px) {
          .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column.new-othermls-column .footer-brokerage-info .footer-brokerage-box {
            text-align: center;
            width: 100%;
            display: inline-block; } }
      .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column .footer-column-logo {
        display: table-cell;
        vertical-align: middle; }
      .footer-otherstyle .footer-patternbg .footer-bg-color .footer-column .footer-logo-address {
        width: 100%;
        display: block;
        margin-top: 15px;
        font-size: 16px; }

.column-list-view .footer-otherstyle {
  width: calc(100% + 30px);
  margin: 0 -15px -20px -15px; }
  @media only screen and (max-width: 990px) {
    .column-list-view .footer-otherstyle .footer-patternbg .footer-bg-color {
      padding: 20px 20px 20px 20px; } }
  .column-list-view .footer-otherstyle .footer-patternbg .footer-bg-color .footer-nav-box {
    text-align: center; }
    .column-list-view .footer-otherstyle .footer-patternbg .footer-bg-color .footer-nav-box .footer-nav ul {
      margin-top: 18px; }
      .column-list-view .footer-otherstyle .footer-patternbg .footer-bg-color .footer-nav-box .footer-nav ul li {
        display: inline-block;
        margin: 10px 0 0 0; }
        .column-list-view .footer-otherstyle .footer-patternbg .footer-bg-color .footer-nav-box .footer-nav ul li a {
          display: inline-block; }
  .column-list-view .footer-otherstyle .footer-patternbg .footer-bg-color .footer-mls-logos {
    margin-top: 25px;
    /* text-align:center; */
    text-align: right; }
    @media only screen and (max-width: 990px) {
      .column-list-view .footer-otherstyle .footer-patternbg .footer-bg-color .footer-mls-logos {
        margin-top: 20px; }
        .column-list-view .footer-otherstyle .footer-patternbg .footer-bg-color .footer-mls-logos .footer-mls-logobox {
          display: block;
          margin-bottom: 10px; } }
  .column-list-view .footer-otherstyle .footer-patternbg .footer-bg-color .powerby-logo {
    /* text-align:center; */
    text-align: right; }
  @media only screen and (max-width: 990px) {
    .column-list-view .footer-otherstyle .footer-patternbg .footer-bg-color .footer-logo-box {
      width: 100%;
      text-align: center;
      display: block; } }
  .column-list-view .footer-otherstyle .footer-patternbg .footer-bg-color .footer-logo-box .footer-logo {
    width: 228px;
    margin-bottom: 15px; }
    @media only screen and (max-width: 990px) {
      .column-list-view .footer-otherstyle .footer-patternbg .footer-bg-color .footer-logo-box .footer-logo {
        width: 200px; } }
  .column-list-view .footer-otherstyle .footer-patternbg .footer-bg-color .footer-other {
    padding: 0 20px; }
  .column-list-view .footer-otherstyle .footer-patternbg .footer-bg-color .footer-left-info {
    position: inherit;
    bottom: inherit;
    margin-bottom: 15px; }
  .column-list-view .footer-otherstyle .footer-patternbg .footer-bg-color .footer-nav-box {
    position: inherit;
    bottom: inherit;
    right: inherit; }
  .column-list-view .footer-otherstyle .footer-patternbg .footer-bg-color .footer-brokerage-info .footer-brokerage-box {
    padding-left: 0px; }
    @media only screen and (max-width: 990px) {
      .column-list-view .footer-otherstyle .footer-patternbg .footer-bg-color .footer-brokerage-info .footer-brokerage-box {
        width: 100%;
        display: block;
        margin-bottom: 20px;
        text-align: center; }
        .column-list-view .footer-otherstyle .footer-patternbg .footer-bg-color .footer-brokerage-info .footer-brokerage-box:last-child {
          margin-bottom: 0px; } }
    @media only screen and (max-width: 990px) {
      .column-list-view .footer-otherstyle .footer-patternbg .footer-bg-color .footer-brokerage-info .footer-brokerage-box .brokerage-logo-llc {
        display: inline-block; } }
    @media only screen and (max-width: 990px) {
      .column-list-view .footer-otherstyle .footer-patternbg .footer-bg-color .footer-brokerage-info .footer-brokerage-box .brokerage-logo-kw {
        display: inline-block; } }
    @media only screen and (max-width: 990px) {
      .column-list-view .footer-otherstyle .footer-patternbg .footer-bg-color .footer-brokerage-info .footer-brokerage-box .brokerage-cnt-box {
        text-align: center;
        margin-bottom: 8px; } }
  @media only screen and (max-width: 990px) {
    .column-list-view .footer-otherstyle .footer-patternbg .footer-column {
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%; }
      .column-list-view .footer-otherstyle .footer-patternbg .footer-column .footer-left-info {
        text-align: center; }
      .column-list-view .footer-otherstyle .footer-patternbg .footer-column .footer-nav-box {
        text-align: center;
        margin-top: 18px;
        display: block; } }
  .column-list-view .footer-otherstyle .footer-column {
    width: 100%;
    flex: 100%;
    max-width: 100%; }
    .column-list-view .footer-otherstyle .footer-column.new-ft-menulink-column .footer-nav-box {
      text-align: center; }
      .column-list-view .footer-otherstyle .footer-column.new-ft-menulink-column .footer-nav-box .footer-nav ul {
        margin-top: 18px; }
        .column-list-view .footer-otherstyle .footer-column.new-ft-menulink-column .footer-nav-box .footer-nav ul li {
          display: inline-block;
          margin: 0 8px 13px 7px; }
          .column-list-view .footer-otherstyle .footer-column.new-ft-menulink-column .footer-nav-box .footer-nav ul li a {
            display: inline-block;
            padding: 15px 10px;
            min-width: 165px; }
    .column-list-view .footer-otherstyle .footer-column.new-ft-menulink-column .powerby-logo {
      width: 100%;
      display: block;
      text-align: center; }
    .column-list-view .footer-otherstyle .footer-column.new-othermls-column .footer-mls-logos {
      text-align: center;
      margin-top: 20px;
      margin-bottom: 15px; }
      .column-list-view .footer-otherstyle .footer-column.new-othermls-column .footer-mls-logos .footer-mls-logobox img {
        height: 45px; }
      @media only screen and (max-width: 990px) {
        .column-list-view .footer-otherstyle .footer-column.new-othermls-column .footer-mls-logos .footer-mls-logobox {
          margin-right: 0px;
          margin-bottom: 15px; } }
    .column-list-view .footer-otherstyle .footer-column.new-othermls-column .footer-brokerage-info {
      margin-top: 15px; }
    @media only screen and (max-width: 990px) {
      .column-list-view .footer-otherstyle .footer-column.new-othermls-column .footer-brokerage-box {
        text-align: center; } }
    .column-list-view .footer-otherstyle .footer-column.new-othermls-column .footer-brokerage-box .footer-brokerage-data {
      width: 100%;
      padding-left: 0px;
      margin-top: 10px;
      display: block; }
      @media only screen and (max-width: 990px) {
        .column-list-view .footer-otherstyle .footer-column.new-othermls-column .footer-brokerage-box .footer-brokerage-data {
          width: 100% !important;
          padding-left: 0px; } }

.column-list-full-view .footer-otherstyle {
  width: calc(100% + 100px);
  margin: 0 -50px; }
  @media only screen and (max-width: 1366px) {
    .column-list-full-view .footer-otherstyle {
      margin: 0 -20px;
      width: calc(100% + 40px); } }
  @media only screen and (max-width: 1280px) {
    .column-list-full-view .footer-otherstyle {
      margin: 0 -5px;
      width: calc(100% + 10px); } }
  @media only screen and (max-width: 1199px) {
    .column-list-full-view .footer-otherstyle {
      margin: 0 -15px;
      width: calc(100% + 30px); } }

/*****************************Footer End***********************************/
body {
  margin: 0px;
  padding: 0px;
  line-height: normal;
  font-weight: normal;
  font-size: 14px; }

.custom-container {
  max-width: 1440px;
  margin: 0px auto;
  float: none !important; }
  @media only screen and (max-width: 1400px) {
    .custom-container {
      max-width: 100%; } }

a {
  font-size: 14px; }

.body-wrapper {
  min-height: 600px; }

/*******************************************common css start*******************************/
/***********************************Gotham Start*********************/
.GothamFont {
  font-family: "GothamBook";
  /********login page*****/
  /********login page End*****/
  /********home page start*****/
  /********home page End*****/
  /********property detail page start*****/
  /********property detail page start*****/
  /********realtor page start*****/
  /********realtor page End*****/
  /********accordion Start*****/
  /********accordion End*****/
  /********Search page Start*****/
  /********Search page End*****/
  /********agentprofile page Start*****/
  /********agentprofile page End*****/
  /********neighborhood detail page Start*****/
  /********neighborhood detail page End*****/
  /********Profile Agent page Start*****/
  /********Dashboard Start*****/
  /********dashboard right start*****/
  /********dashboard right End*****/
  /***********************************Dashboard End**********************************/
  /********Blog Start*****/
  /********Blog End*****/
  /********Manage start*****/ }
  .GothamFont h1,
  .GothamFont h2,
  .GothamFont h3,
  .GothamFont h4,
  .GothamFont h5 {
    font-family: "GothamMedium"; }
  .GothamFont select,
  .GothamFont textarea {
    font-family: "GothamBook"; }
  .GothamFont input {
    font-family: "RobotoRegular"; }
  .GothamFont button {
    font-family: "RobotoMedium"; }
    .GothamFont button.material-icons {
      font-family: "Material Icons"; }
  .GothamFont a {
    font-family: "GothamBook"; }
  .GothamFont .notification.notification-success {
    font-family: "GothamBook"; }
  .GothamFont .notification .fill-color-button {
    font-family: "GothamBook"; }
  .GothamFont .notification .white-fill-color-button {
    font-family: "GothamBook"; }
  .GothamFont .login-form h2 {
    font-family: "GothamMedium"; }
  .GothamFont .punchline-text {
    font-family: "GothamMedium"; }
  .GothamFont .navbar-signup-button {
    font-family: "GothamBook"; }
  .GothamFont .navbar-login-button {
    font-family: "GothamBook"; }
    .GothamFont .navbar-login-button.login-dropdown-button button {
      font-family: "GothamBook"; }
  .GothamFont .banner-title {
    font-family: "GothamMedium"; }
  .GothamFont .loading-title {
    font-family: "GothamMedium"; }
  .GothamFont .banner-sub-title {
    font-family: "GothamLight"; }
  .GothamFont .rbt-input-main {
    font-family: "RobotoRegular"; }
  .GothamFont .rbt-input-hint {
    font-family: "RobotoRegular"; }
  .GothamFont .typeahead-search {
    font-family: "RobotoRegular"; }
  .GothamFont .openhouse-filter {
    font-family: "RobotoRegular"; }
  .GothamFont .section-title h2 {
    font-family: "GothamMedium"; }
  .GothamFont .section-title .heading-sub-text.heading-title-text {
    font-family: "GothamMedium"; }
  .GothamFont .prop-card .card-prop-info .card-prop-amount {
    font-family: "GothamBold"; }
  .GothamFont .prop-card.blog-manage-card .blog-card-info .blog-card-name {
    font-family: "GothamBold"; }
  .GothamFont .latest-mls-update .update-mls-text .update-time .large-time {
    font-family: "GothamLight"; }
  .GothamFont .latest-mls-update .update-mls-text .update-time .small-time {
    font-family: "GothamMedium"; }
  .GothamFont .afford-cost-clc .cost-range-titel {
    font-family: "GothamMedium"; }
  .GothamFont .home-neighborhood .neighborhood-listing .neighborhood-card .neighborhood-name {
    font-family: "GothamLight"; }
    .GothamFont .home-neighborhood .neighborhood-listing .neighborhood-card .neighborhood-name .name-highlight {
      font-family: "GothamMedium"; }
  .GothamFont .prop-card .card-prop-info .prop-info-basic .card-add-lt .address-line:first-child {
    font-family: "GothamMedium"; }
  .GothamFont .prop-card .card-prop-info .prop-info-basic .prop-info-ext .prop-open-house-ext {
    font-family: "GothamMedium"; }
  .GothamFont .tlc-how-work .desktop-img-banner .tlc-price-amount {
    font-family: "GothamBold"; }
  .GothamFont .tlc-how-work .tlc-mortgage-text {
    font-family: "GothamMedium"; }
  .GothamFont .tlc-how-work .tlc-utilities-text {
    font-family: "GothamMedium"; }
  .GothamFont .tlc-how-work .tlc-commute-text {
    font-family: "GothamMedium"; }
  .GothamFont .tlc-how-works .desktop-img-banner .tlc-price-amount {
    font-family: "GothamBold"; }
  .GothamFont .tlc-how-works .tlc-mortgage-text {
    font-family: "GothamMedium"; }
  .GothamFont .tlc-how-works .tlc-utilities-text {
    font-family: "GothamMedium"; }
  .GothamFont .tlc-how-works .tlc-commute-text {
    font-family: "GothamMedium"; }
  .GothamFont .tlc-how-works .desktop-img-banner .round-text {
    font-family: "GothamBold"; }
    .GothamFont .tlc-how-works .desktop-img-banner .round-text .round-subtext {
      font-family: "GothamBook"; }
  .GothamFont .tlc-how-works .desktop-img-banner .tlc-home-smallbox .sub-box-inner .box-info {
    font-family: "GothamBook"; }
    .GothamFont .tlc-how-works .desktop-img-banner .tlc-home-smallbox .sub-box-inner .box-info .info-highlight {
      font-family: "GothamMedium"; }
  .GothamFont .mb-img-banner .mb-tlc-price-amount {
    font-family: "GothamBold"; }
  .GothamFont .mb-img-banner .mb-tlc-mortgage-text {
    font-family: "GothamMedium"; }
  .GothamFont .mb-img-banner .mb-tlc-utilities-text {
    font-family: "GothamMedium"; }
  .GothamFont .mb-img-banner .mb-tlc-commute-text {
    font-family: "GothamMedium"; }
  .GothamFont .mb-img-tlcbanner .mb-property-full-tlc {
    font-family: "GothamBold"; }
  .GothamFont .mb-img-tlcbanner .mb-tlc-mortgage-text {
    font-family: "GothamMedium"; }
  .GothamFont .mb-img-tlcbanner .mb-tlc-utilities-text {
    font-family: "GothamMedium"; }
  .GothamFont .mb-img-tlcbanner .mb-tlc-commute-text {
    font-family: "GothamMedium"; }
  .GothamFont .mb-img-tlcbanner .mb-tlc-home-smallbox .sub-box-inner .box-info {
    font-family: "GothamBook"; }
    .GothamFont .mb-img-tlcbanner .mb-tlc-home-smallbox .sub-box-inner .box-info .info-highlight {
      font-family: "GothamMedium"; }
  .GothamFont .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-tlc .sub-box-inner .box-info {
    font-family: "GothamBold"; }
    .GothamFont .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-tlc .sub-box-inner .box-info .info-highlight {
      font-family: "GothamBold"; }
  .GothamFont .breadcrumb a {
    font-family: "GothamMedium"; }
  .GothamFont .property-description {
    /* & .property-address {
			& .sub-address {
				font-family: 'GothamMedium';
			}
		} */ }
    .GothamFont .property-description h1 {
      font-family: "GothamMedium"; }
    .GothamFont .property-description .property-price {
      font-family: "GothamBold"; }
    .GothamFont .property-description .utility-list .utility-text .hilight-text {
      font-family: "GothamBold"; }
    .GothamFont .property-description .property-stats .property-stats-card-value {
      font-family: "GothamBold"; }
  .GothamFont .property-details-main .sidebar-right .sidebar-com-box .sidebar-rt-cost.listing-cost {
    font-family: "GothamBook"; }
  .GothamFont .property-details-main .sidebar-right .sidebar-com-box .affordability-details .tlc-cost {
    font-family: "GothamOfficeRegular"; }
    .GothamFont .property-details-main .sidebar-right .sidebar-com-box .affordability-details .tlc-cost.final-tlc-cost {
      font-family: "GothamOfficeBold"; }
  .GothamFont .property-details-main .sidebar-right .sidebar-com-box .affordability-details .tlc-calculat-label {
    font-family: "GothamBold"; }
  .GothamFont .property-details-main .sidebar-right .sidebar-com-box .detail-agent-info .agent-details .agent-name {
    font-family: "GothamBold"; }
    .GothamFont .property-details-main .sidebar-right .sidebar-com-box .detail-agent-info .agent-details .agent-name a {
      font-family: "GothamBold"; }
  .GothamFont .property-details-main .accordion-listing .card-body .property-accordion-content .mortgage-list .mortgage-cost {
    font-family: "GothamMedium"; }
  .GothamFont .property-details-main .accordion-see-all {
    font-family: "GothamMedium"; }
  .GothamFont .card-header .header-name {
    font-family: "GothamBook"; }
  .GothamFont .type-select .react-select__control .react-select__single-value {
    font-family: "GothamBook"; }
  .GothamFont .gallery-type3 .prop-open-house .open-house-status {
    font-family: "GothamBold"; }
  .GothamFont .gallery-box .view-photo .theme-btn {
    font-family: "GothamBook"; }
  .GothamFont .gallery-box .prop-status .status-active {
    font-family: "GothamLight"; }
  .GothamFont .gallery-box .prop-status .gl-active {
    font-family: "GothamLight"; }
  .GothamFont .gallery-box .prop-open-house .open-house-status {
    font-family: "GothamBold"; }
  .GothamFont .pre-approv-btn .theme-btn {
    font-family: "GothamBold"; }
  .GothamFont .tlc-panel-title {
    font-family: "GothamMedium"; }
  .GothamFont .panel-title-box {
    font-family: "GothamMedium"; }
    .GothamFont .panel-title-box .category-amount {
      font-family: "GothamBook"; }
      .GothamFont .panel-title-box .category-amount.category-final-amount {
        font-family: "GothamMedium"; }
  .GothamFont .calculate-your-tlc .theme-btn {
    font-family: "GothamMedium"; }
  .GothamFont .blog-mid-content.inner-blog-mid-content .blog-full-detail .content-title {
    font-family: "GothamBold"; }
  .GothamFont .tlc-calculat-edit h3 {
    font-family: "GothamMedium"; }
  .GothamFont .tlc-calculat-edit label {
    font-family: "GothamLight"; }
    .GothamFont .tlc-calculat-edit label.calculat-label {
      font-family: "GothamBook"; }
    .GothamFont .tlc-calculat-edit label.tlc-calculat-label {
      font-family: "GothamBold"; }
  .GothamFont .tlc-calculat-edit .est-cost {
    font-family: "GothamLight"; }
  .GothamFont .tlc-calculat-edit .final-cost [type="text"] {
    font-family: "GothamBook"; }
  .GothamFont .tlc-calculat-edit .tlc-cost {
    font-family: "GothamOfficeRegular"; }
    .GothamFont .tlc-calculat-edit .tlc-cost.final-tlc-cost {
      font-family: "GothamOfficeBold"; }
  .GothamFont .cost-calculat-edit h3 {
    font-family: "GothamMedium"; }
  .GothamFont .cost-calculat-edit label {
    font-family: "GothamBook"; }
  .GothamFont .cost-calculat-edit .final-cost [type="text"] {
    font-family: "GothamBook"; }
  .GothamFont .cost-calculat-edit .final-total-cost {
    font-family: "GothamMedium"; }
  .GothamFont .chat-window .chat-profile-round {
    font-family: "GothamMedium"; }
  .GothamFont .mb-prop-open-house {
    font-family: "GothamBold"; }
  .GothamFont .agent-dt-box .agent-dt-right .agent-name {
    font-family: "GothamBold"; }
    .GothamFont .agent-dt-box .agent-dt-right .agent-name a {
      font-family: "GothamBold"; }
  .GothamFont .find-agent-result h3 {
    font-family: "GothamMedium"; }
  .GothamFont .find-agent-filter .nav-tabs .nav-link.active {
    font-family: "GothamMedium"; }
  .GothamFont .accordion-listing .card-header .header-name .office-name {
    font-family: "GothamBold"; }
  .GothamFont .accordion-listing .card-header .header-name .office-address {
    font-family: "GothamBook"; }
  .GothamFont .accordion-listing .card-body .property-accordion-content .tlc-calculat-edit.mortgage-calculat label {
    font-family: "GothamBook"; }
  .GothamFont .accordion-listing .card-body .property-accordion-content .mortgage-chart .highcharts-title tspan:first-child {
    font-family: "GothamMedium"; }
  .GothamFont .accordion-listing .card-body .property-accordion-content .mortgage-chart .highcharts-title tspan:last-child {
    font-family: "GothamLight"; }
  .GothamFont .accordion-listing .card-body .update-note {
    font-family: "GothamBook"; }
  .GothamFont .accordion-listing .card-body .update-note-btn {
    font-family: "GothamMedium"; }
  .GothamFont .budget-chart .highcharts-title .budget-price {
    font-family: "GothamBold"; }
  .GothamFont .budget-chart .highcharts-title .cost-living {
    font-family: "GothamMedium"; }
  .GothamFont .budget-chart .highcharts-title .border-bottom {
    font-family: "GothamBold"; }
  .GothamFont .search-bar-panel .dropdown .dropdown-toggle {
    font-family: "GothamBook"; }
  .GothamFont .search-bar-panel .dropdown .dropdown-menu {
    font-family: "GothamBook"; }
  .GothamFont .search-bar-panel .com-panel-box .reset-button {
    font-family: "GothamBook"; }
  .GothamFont .search-bar-panel .price-range-control [type="text"] {
    font-family: "GothamBook"; }
  .GothamFont .search-bar-panel .prop-save-searh-button .theme-btn {
    font-family: "GothamMedium"; }
  .GothamFont .more-filter-panel .more-filter-container [type="text"] {
    font-family: "GothamBook"; }
  .GothamFont .more-filter-panel .more-filter-container .matching-listing-bg {
    font-family: "GothamMedium"; }
  .GothamFont .search-container .right-wrapper .hide-map-view .theme-btn,
  .GothamFont .search-container .right-wrapper .remove-line .theme-btn {
    font-family: "GothamBook"; }
  .GothamFont .extra-filters-bar .dropdown .dropdown-toggle {
    font-family: "GothamBook"; }
  .GothamFont .extra-filters-bar .dropdown .dropdown-menu {
    font-family: "GothamBook"; }
  .GothamFont .extra-filters-bar .price-range-control [type="text"] {
    font-family: "GothamBook"; }
  .GothamFont .horizontal-prop-card .current-prop-box .current-prop-dt .current-pro-address {
    font-family: "GothamMedium"; }
  .GothamFont .horizontal-prop-card .current-prop-box .current-prop-dt .current-pro-price {
    font-family: "GothamBold"; }
  .GothamFont .horizontal-prop-card .current-prop-box .current-prop-right .current-tlc-amount {
    font-family: "GothamBook"; }
  .GothamFont .agent-detail-bg .agent-biodata-left h2 {
    font-family: "GothamMedium"; }
  .GothamFont .agent-detail-bg .agent-biodata-left h3 {
    font-family: "GothamLight"; }
  .GothamFont .agent-detail-bg .small-sub-title {
    font-family: "GothamBold"; }
  .GothamFont .neighborhood-detail .neighborhood-state-info h2 {
    font-family: "GothamMedium"; }
  .GothamFont .neighborhood-detail .neighborhood-state-info .state-utility-list .state-utility-text .hilight-text {
    font-family: "GothamBold"; }
  .GothamFont .neighborhood-detail .tab-list .nav-tabs .nav-link {
    font-family: "GothamMedium"; }
  .GothamFont .neighborhood-detail .tab-list .tab-content .nav-tabs .nav-link {
    font-family: "GothamBook"; }
  .GothamFont .neighborhood-detail .average-score .mortgage-list .mortgage-name {
    font-family: "GothamBold"; }
  .GothamFont .neighborhood-detail .sign-up-neighborhood {
    font-family: "GothamMedium"; }
  .GothamFont .table-result-title {
    font-family: "GothamBold"; }
  .GothamFont .last-top-comments .chat-right .chat-box .comments-box .profile-name,
  .GothamFont .last-top-comments .chat-left .chat-box .comments-box .profile-name {
    font-family: "GothamMedium"; }
  .GothamFont .last-top-comments .chat-right .chat-box .comments-box .profile-text,
  .GothamFont .last-top-comments .chat-left .chat-box .comments-box .profile-text {
    font-family: "GothamBook"; }
  .GothamFont .last-top-comments .view-more-comment {
    font-family: "GothamBook"; }
  .GothamFont .dash-profile-left .dash-profile-name {
    font-family: "GothamBook"; }
    .GothamFont .dash-profile-left .dash-profile-name .dash-profile-fullname {
      font-family: "GothamMedium"; }
  .GothamFont .left-content-box .affordability-titel {
    font-family: "GothamBook"; }
  .GothamFont .affordability-list .affordability-name {
    font-family: "GothamBold"; }
  .GothamFont .dashboard-right {
    /********dashboard tab start*****/
    /********dashboard tab End*****/
    /********timeline left start*****/
    /********timeline left End*****/
    /********timeline right start*****/
    /********timeline right End*****/
    /********account info start*****/ }
    .GothamFont .dashboard-right [type="text"] {
      font-family: "GothamBook"; }
    .GothamFont .dashboard-right .tab-list .nav-tabs .nav-link {
      font-family: "GothamMedium"; }
    .GothamFont .dashboard-right .timeline-left-col .timeline-short-name .timeline-name-box {
      font-family: "GothamMedium"; }
    .GothamFont .dashboard-right .timeline-right-col .mb-timeline-left-col .timeline-short-name .timeline-name-box {
      font-family: "GothamMedium"; }
    .GothamFont .dashboard-right .timeline-right-col .timeline-create-info .timeline-create {
      font-family: "GothamMedium"; }
      .GothamFont .dashboard-right .timeline-right-col .timeline-create-info .timeline-create .create-item {
        font-family: "GothamBook"; }
      .GothamFont .dashboard-right .timeline-right-col .timeline-create-info .timeline-create .last-active-time {
        font-family: "GothamBook"; }
    .GothamFont .dashboard-right .timeline-right-col .timeline-comment-bg .last-top-comments .chat-profile-round {
      font-family: "GothamMedium"; }
    .GothamFont .dashboard-right .account-detail .signal-switch {
      font-family: "GothamBook"; }
    .GothamFont .dashboard-right .account-detail .price-range {
      font-family: "GothamMedium"; }
    .GothamFont .dashboard-right .commute-calculat-edit {
      font-family: "GothamLight"; }
    .GothamFont .dashboard-right .clients-info .clients-name .clients-fullname {
      font-family: "GothamBold"; }
      .GothamFont .dashboard-right .clients-info .clients-name .clients-fullname .client-last-logged {
        font-family: "GothamBook"; }
    .GothamFont .dashboard-right .agent-client-detail .detail-title {
      font-family: "GothamBook"; }
  .GothamFont .contactus-small-label {
    font-family: "GothamMedium"; }
  .GothamFont .blog-banner-sub-title {
    font-family: "GothamLight"; }
  .GothamFont .blog-card .blog-card-info .blog-header {
    font-family: "GothamBold"; }
  .GothamFont .blog-card .blog-short-content .blog-ct-more a {
    font-family: "GothamBold"; }
  .GothamFont .blog-comments-content .blog-comments-listing .comments-number-title {
    font-family: "GothamMedium"; }
  .GothamFont .blog-comments-content .blog-comments-listing .comments-reply-bt a {
    font-family: "GothamBold"; }
  .GothamFont .MuiTypography-body2 {
    font-family: "GothamBook"; }
  .GothamFont .manage-card .card-prop-top {
    font-family: "GothamMedium"; }
  .GothamFont .manage-card .card-prop-footer {
    font-family: "GothamMedium"; }
  .GothamFont .table-top-title {
    font-family: "GothamBook"; }
  .GothamFont .save-search-table-header .filter-title {
    font-family: "GothamMedium"; }
  .GothamFont .white-card-content .content-item-text {
    font-family: "GothamBook"; }
  .GothamFont .pd-loader-text-t1 {
    font-family: "GothamMedium"; }
  .GothamFont .pd-loader-text-t2 {
    font-family: "GothamLight"; }
  .GothamFont .compare-cost-header .highlight-text {
    font-family: "GothamMedium"; }
  .GothamFont .compare-result-info .compare-result-price {
    font-family: "GothamMedium"; }
  .GothamFont .compare-result-info .compare-result-city {
    font-family: "GothamMedium"; }
  .GothamFont .compare-city-box .compare-city-name {
    font-family: "GothamMedium"; }
  .GothamFont .compare-city-box .compare-last-amount {
    font-family: "GothamMedium"; }
  .GothamFont .more-tlc-price-filter .prop-price-filter .dropdown .dropdown-toggle {
    font-family: "GothamBook"; }
  .GothamFont .more-commute-filter .dropdown .dropdown-toggle {
    font-family: "GothamBook"; }
  .GothamFont .more-commute-filter .rbt-input-main {
    font-family: "RobotoRegular" !important; }
  .GothamFont .agent-listing-info .tab-list .nav-tabs .nav-link {
    font-family: "GothamMedium"; }
  .GothamFont .profile-location-dropdown .rbt-input-main {
    font-family: "RobotoRegular"; }
  .GothamFont .joyride-tooltip__header {
    font-family: "GothamBold"; }
  .GothamFont .joyride-tooltip__button.joyride-tooltip__button--skip {
    font-family: "GothamBook"; }
  .GothamFont .joyride-tooltip__button.joyride-tooltip__button--secondary {
    font-family: "GothamBook"; }
  .GothamFont .widget-card h2 {
    font-family: "GothamBook"; }
  .GothamFont .widget-card .numeric-input-box .form-control {
    font-family: "GothamBook"; }
  .GothamFont .controls-wrapper .numeric-input-box .form-control {
    font-family: "GothamBook"; }
  .GothamFont .expenses-cost-header .expenses-content {
    font-family: "GothamMedium"; }
  .GothamFont .tlc-note {
    font-family: "GothamBook"; }
  .GothamFont .journey-left .sidebar-journey-title {
    font-family: "GothamMedium"; }
  .GothamFont .journey-content h3 {
    font-family: "GothamMedium"; }
  .GothamFont .journey-content h4 {
    font-family: "GothamMedium"; }
  .GothamFont .journey-content .journey-checkbox-fill .journey-checkbox-title {
    font-family: "GothamMedium"; }
  .GothamFont .journey-content .journey-checkbox-fill .journey-checkbox-subtitle {
    font-family: "GothamBook"; }
  .GothamFont .journey-content .journey-radio-fill .journey-icon-wrap .journey-radio-bold-title {
    font-family: "GothamMedium"; }
  .GothamFont .journey-content .move-range-box .input-range__track .input-range__label--value .input-range__label-container {
    font-family: "GothamBold"; }
  .GothamFont .journey-content .move-range-box .input-range .input-range__label--min .input-range__label-container {
    font-family: "GothamBook"; }
  .GothamFont .journey-content .move-range-box .input-range .input-range__label--max .input-range__label-container {
    font-family: "GothamBook"; }
  .GothamFont .journey-content .month-range-info {
    font-family: "GothamMedium"; }
  .GothamFont .journey-content .bold-title {
    font-family: "GothamMedium"; }
  .GothamFont .journey-content .mortgage-types-chart .circular-chart .percentage {
    font-family: "GothamMedium"; }
  .GothamFont .journey-content .pdf-download-link .pdf-dn-name {
    font-family: "GothamMedium"; }
  .GothamFont .leaflet-popup-content .poi_tooltip .poi-header-name {
    font-family: "GothamMedium"; }
  .GothamFont .personal-note-lt .personal-note-tip-number {
    font-family: "GothamMedium"; }
  .GothamFont .personal-note-lt .personal-note-tip-cnt {
    font-family: "GothamBook"; }
  .GothamFont .footer-nav-box .footer-nav-sm-title {
    font-family: "GothamMedium"; }
  .GothamFont .reg-blog-content .heading-sub-text {
    font-family: "GothamLight"; }
  .GothamFont .reg-blog-content .reg-blog-ctn .reg-blog-blox .reg-blog-title {
    font-family: "GothamBold"; }
  .GothamFont .reg-blog-content .reg-blog-ctn .reg-blog-blox .reg-blog-readmore {
    font-family: "GothamBold"; }
    .GothamFont .reg-blog-content .reg-blog-ctn .reg-blog-blox .reg-blog-readmore a {
      font-family: "GothamBold"; }
  .GothamFont .reg-blog-content .reg-blog-more-listing {
    font-family: "GothamMedium"; }
    .GothamFont .reg-blog-content .reg-blog-more-listing a {
      font-family: "GothamMedium"; }
  .GothamFont .terms-cnt-list .small-cnt-header {
    font-family: "GothamMedium"; }
  .GothamFont .market-trends .data-highlight {
    font-family: "GothamBold"; }

/***********************************Gotham Start*********************/
.OpensansFont {
  font-family: "OpenSansRegular";
  /********login page*****/
  /********login page End*****/
  /********home page start*****/
  /********home page End*****/
  /********property detail page start*****/
  /********property detail page start*****/
  /********realtor page start*****/
  /********realtor page End*****/
  /********accordion Start*****/
  /********accordion End*****/
  /********Search page Start*****/
  /********Search page End*****/
  /********agentprofile page Start*****/
  /********agentprofile page End*****/
  /********neighborhood detail page Start*****/
  /********neighborhood detail page End*****/
  /********Profile Agent page Start*****/
  /********Dashboard Start*****/
  /********dashboard right start*****/
  /********dashboard right End*****/
  /***********************************Dashboard End**********************************/
  /********Blog Start*****/
  /********Blog End*****/
  /********Manage start*****/ }
  .OpensansFont h1,
  .OpensansFont h2,
  .OpensansFont h3,
  .OpensansFont h4,
  .OpensansFont h5 {
    font-family: "OpenSansSemibold"; }
  .OpensansFont select,
  .OpensansFont textarea {
    font-family: "OpenSansRegular"; }
  .OpensansFont input {
    font-family: "OpenSansLight"; }
  .OpensansFont button {
    font-family: "OpenSansRegular"; }
    .OpensansFont button.material-icons {
      font-family: "Material Icons"; }
  .OpensansFont a {
    font-family: "OpenSansLight"; }
  .OpensansFont .italic-style-font {
    font-family: "OpenSansItalic"; }
  .OpensansFont .bolditalic-style-font {
    font-family: "OpenSansBoldItalic"; }
  .OpensansFont .notification.notification-success {
    font-family: "OpenSansRegular"; }
  .OpensansFont .notification .fill-color-button {
    font-family: "OpenSansRegular"; }
  .OpensansFont .notification .white-fill-color-button {
    font-family: "OpenSansRegular"; }
  .OpensansFont .login-form h2 {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .punchline-text {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .navbar-signup-button {
    font-family: "OpenSansRegular"; }
  .OpensansFont .navbar-login-button {
    font-family: "OpenSansRegular"; }
    .OpensansFont .navbar-login-button.login-dropdown-button button {
      font-family: "OpenSansRegular"; }
  .OpensansFont .banner-title {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .loading-title {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .banner-sub-title {
    font-family: "OpenSansLight"; }
  .OpensansFont .rbt-input-main {
    font-family: "OpenSansLight"; }
  .OpensansFont .rbt-input-hint {
    font-family: "OpenSansLight"; }
  .OpensansFont .typeahead-search {
    font-family: "OpenSansLight"; }
  .OpensansFont .openhouse-filter {
    font-family: "OpenSansLight"; }
  .OpensansFont .section-title h2 {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .prop-card .card-prop-info .card-prop-amount {
    font-family: "OpenSansBold"; }
  .OpensansFont .prop-card.blog-manage-card .blog-card-info .blog-card-name {
    font-family: "OpenSansBold"; }
  .OpensansFont .latest-mls-update .update-mls-text .update-time .large-time {
    font-family: "OpenSansLight"; }
  .OpensansFont .latest-mls-update .update-mls-text .update-time .small-time {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .afford-cost-clc .cost-range-titel {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .home-neighborhood .neighborhood-listing .neighborhood-card .neighborhood-name {
    font-family: "OpenSansLight"; }
    .OpensansFont .home-neighborhood .neighborhood-listing .neighborhood-card .neighborhood-name .name-highlight {
      font-family: "OpenSansSemibold"; }
  .OpensansFont .prop-card .card-prop-info .prop-info-basic .card-add-lt .address-line:first-child {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .prop-card .card-prop-info .prop-info-basic .prop-info-ext .prop-open-house-ext {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .tlc-how-work .desktop-img-banner .tlc-price-amount {
    font-family: "OpenSansBold"; }
  .OpensansFont .tlc-how-work .tlc-mortgage-text {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .tlc-how-work .tlc-utilities-text {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .tlc-how-work .tlc-commute-text {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .tlc-how-works .desktop-img-banner .tlc-price-amount {
    font-family: "OpenSansBold"; }
  .OpensansFont .tlc-how-works .tlc-mortgage-text {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .tlc-how-works .tlc-utilities-text {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .tlc-how-works .tlc-commute-text {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .tlc-how-works .desktop-img-banner .round-text {
    font-family: "OpenSansBold"; }
    .OpensansFont .tlc-how-works .desktop-img-banner .round-text .round-subtext {
      font-family: "OpenSansRegular"; }
  .OpensansFont .tlc-how-works .desktop-img-banner .tlc-home-smallbox .sub-box-inner .box-info {
    font-family: "OpenSansRegular"; }
    .OpensansFont .tlc-how-works .desktop-img-banner .tlc-home-smallbox .sub-box-inner .box-info .info-highlight {
      font-family: "OpenSansSemibold"; }
  .OpensansFont .mb-img-banner .mb-tlc-price-amount {
    font-family: "OpenSansBold"; }
  .OpensansFont .mb-img-banner .mb-tlc-mortgage-text {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .mb-img-banner .mb-tlc-utilities-text {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .mb-img-banner .mb-tlc-commute-text {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .mb-img-tlcbanner .mb-property-full-tlc {
    font-family: "OpenSansBold"; }
  .OpensansFont .mb-img-tlcbanner .mb-tlc-mortgage-text {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .mb-img-tlcbanner .mb-tlc-utilities-text {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .mb-img-tlcbanner .mb-tlc-commute-text {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .mb-img-tlcbanner .mb-tlc-home-smallbox .sub-box-inner .box-info {
    font-family: "OpenSansRegular"; }
    .OpensansFont .mb-img-tlcbanner .mb-tlc-home-smallbox .sub-box-inner .box-info .info-highlight {
      font-family: "OpenSansSemibold"; }
  .OpensansFont .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-tlc .sub-box-inner .box-info {
    font-family: "OpenSansBold"; }
    .OpensansFont .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-tlc .sub-box-inner .box-info .info-highlight {
      font-family: "OpenSansBold"; }
  .OpensansFont .breadcrumb a {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .property-description {
    /* & .property-address {
			& .sub-address {
				font-family: 'OpenSansSemibold';
			}
		} */ }
    .OpensansFont .property-description h1 {
      font-family: "OpenSansSemibold"; }
    .OpensansFont .property-description .property-price {
      font-family: "OpenSansBold"; }
    .OpensansFont .property-description .utility-list .utility-text .hilight-text {
      font-family: "OpenSansBold"; }
    .OpensansFont .property-description .property-stats .property-stats-card-value {
      font-family: "OpenSansBold"; }
  .OpensansFont .property-details-main .sidebar-right .sidebar-com-box .sidebar-rt-cost.listing-cost {
    font-family: "OpenSansRegular"; }
  .OpensansFont .property-details-main .sidebar-right .sidebar-com-box .affordability-details .tlc-cost {
    font-family: "GothamOfficeRegular"; }
    .OpensansFont .property-details-main .sidebar-right .sidebar-com-box .affordability-details .tlc-cost.final-tlc-cost {
      font-family: "GothamOfficeBold"; }
  .OpensansFont .property-details-main .sidebar-right .sidebar-com-box .affordability-details .tlc-calculat-label {
    font-family: "OpenSansBold"; }
  .OpensansFont .property-details-main .sidebar-right .sidebar-com-box .detail-agent-info .agent-details .agent-name {
    font-family: "OpenSansBold"; }
    .OpensansFont .property-details-main .sidebar-right .sidebar-com-box .detail-agent-info .agent-details .agent-name a {
      font-family: "OpenSansBold"; }
  .OpensansFont .property-details-main .accordion-listing .card-body .property-accordion-content .mortgage-list .mortgage-cost {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .property-details-main .accordion-see-all {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .card-header .header-name {
    font-family: "OpenSansRegular"; }
  .OpensansFont .type-select .react-select__control .react-select__single-value {
    font-family: "OpenSansRegular"; }
  .OpensansFont .gallery-type3 .prop-open-house .open-house-status {
    font-family: "OpenSansBold"; }
  .OpensansFont .gallery-box .view-photo .theme-btn {
    font-family: "OpenSansRegular"; }
  .OpensansFont .gallery-box .prop-status .status-active {
    font-family: "OpenSansLight"; }
  .OpensansFont .gallery-box .prop-status .gl-active {
    font-family: "OpenSansLight"; }
  .OpensansFont .gallery-box .prop-open-house .open-house-status {
    font-family: "OpenSansBold"; }
  .OpensansFont .pre-approv-btn .theme-btn {
    font-family: "OpenSansBold"; }
  .OpensansFont .tlc-panel-title {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .panel-title-box {
    font-family: "OpenSansSemibold"; }
    .OpensansFont .panel-title-box .category-amount {
      font-family: "OpenSansRegular"; }
      .OpensansFont .panel-title-box .category-amount.category-final-amount {
        font-family: "OpenSansSemibold"; }
  .OpensansFont .calculate-your-tlc .theme-btn {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .blog-mid-content.inner-blog-mid-content .blog-full-detail .content-title {
    font-family: "OpenSansBold"; }
  .OpensansFont .tlc-calculat-edit h3 {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .tlc-calculat-edit label {
    font-family: "OpenSansLight"; }
    .OpensansFont .tlc-calculat-edit label.calculat-label {
      font-family: "OpenSansRegular"; }
    .OpensansFont .tlc-calculat-edit label.tlc-calculat-label {
      font-family: "OpenSansBold"; }
  .OpensansFont .tlc-calculat-edit .est-cost {
    font-family: "OpenSansLight"; }
  .OpensansFont .tlc-calculat-edit .final-cost [type="text"] {
    font-family: "OpenSansRegular"; }
  .OpensansFont .tlc-calculat-edit .tlc-cost {
    font-family: "GothamOfficeRegular"; }
    .OpensansFont .tlc-calculat-edit .tlc-cost.final-tlc-cost {
      font-family: "GothamOfficeBold"; }
  .OpensansFont .cost-calculat-edit h3 {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .cost-calculat-edit label {
    font-family: "OpenSansRegular"; }
  .OpensansFont .cost-calculat-edit .final-cost [type="text"] {
    font-family: "OpenSansRegular"; }
  .OpensansFont .cost-calculat-edit .final-total-cost {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .chat-window .chat-profile-round {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .mb-prop-open-house {
    font-family: "OpenSansBold"; }
  .OpensansFont .agent-dt-box .agent-dt-right .agent-name {
    font-family: "OpenSansBold"; }
    .OpensansFont .agent-dt-box .agent-dt-right .agent-name a {
      font-family: "OpenSansBold"; }
  .OpensansFont .find-agent-result h3 {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .find-agent-filter .nav-tabs .nav-link.active {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .accordion-listing .card-header .header-name .office-name {
    font-family: "OpenSansBold"; }
  .OpensansFont .accordion-listing .card-header .header-name .office-address {
    font-family: "OpenSansRegular"; }
  .OpensansFont .accordion-listing .card-body .property-accordion-content .tlc-calculat-edit.mortgage-calculat label {
    font-family: "OpenSansRegular"; }
  .OpensansFont .accordion-listing .card-body .property-accordion-content .mortgage-chart .highcharts-title tspan:first-child {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .accordion-listing .card-body .property-accordion-content .mortgage-chart .highcharts-title tspan:last-child {
    font-family: "OpenSansLight"; }
  .OpensansFont .accordion-listing .card-body .update-note {
    font-family: "OpenSansRegular"; }
  .OpensansFont .accordion-listing .card-body .update-note-btn {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .budget-chart .highcharts-title .budget-price {
    font-family: "OpenSansBold"; }
  .OpensansFont .budget-chart .highcharts-title .cost-living {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .budget-chart .highcharts-title .border-bottom {
    font-family: "OpenSansBold"; }
  .OpensansFont .search-bar-panel .dropdown .dropdown-toggle {
    font-family: "OpenSansRegular"; }
  .OpensansFont .search-bar-panel .dropdown .dropdown-menu {
    font-family: "OpenSansRegular"; }
  .OpensansFont .search-bar-panel .com-panel-box .reset-button {
    font-family: "OpenSansRegular"; }
  .OpensansFont .search-bar-panel .price-range-control [type="text"] {
    font-family: "OpenSansRegular"; }
  .OpensansFont .search-bar-panel .prop-save-searh-button .theme-btn {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .more-filter-panel .more-filter-container [type="text"] {
    font-family: "OpenSansRegular"; }
  .OpensansFont .more-filter-panel .more-filter-container .matching-listing-bg {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .search-container .right-wrapper .hide-map-view .theme-btn,
  .OpensansFont .search-container .right-wrapper .remove-line .theme-btn {
    font-family: "OpenSansRegular"; }
  .OpensansFont .horizontal-prop-card .current-prop-box .current-prop-dt .current-pro-address {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .horizontal-prop-card .current-prop-box .current-prop-dt .current-pro-price {
    font-family: "OpenSansBold"; }
  .OpensansFont .horizontal-prop-card .current-prop-box .current-prop-right .current-tlc-amount {
    font-family: "OpenSansRegular"; }
  .OpensansFont .agent-detail-bg .agent-biodata-left h2 {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .agent-detail-bg .agent-biodata-left h3 {
    font-family: "OpenSansLight"; }
  .OpensansFont .agent-detail-bg .small-sub-title {
    font-family: "OpenSansBold"; }
  .OpensansFont .neighborhood-detail .neighborhood-state-info h2 {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .neighborhood-detail .neighborhood-state-info .state-utility-list .state-utility-text .hilight-text {
    font-family: "OpenSansBold"; }
  .OpensansFont .neighborhood-detail .tab-list .nav-tabs .nav-link {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .neighborhood-detail .tab-list .tab-content .nav-tabs .nav-link {
    font-family: "OpenSansRegular"; }
  .OpensansFont .neighborhood-detail .average-score .mortgage-list .mortgage-name {
    font-family: "OpenSansBold"; }
  .OpensansFont .neighborhood-detail .sign-up-neighborhood {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .table-result-title {
    font-family: "OpenSansBold"; }
  .OpensansFont .last-top-comments .chat-right .chat-box .comments-box .profile-name,
  .OpensansFont .last-top-comments .chat-left .chat-box .comments-box .profile-name {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .last-top-comments .chat-right .chat-box .comments-box .profile-text,
  .OpensansFont .last-top-comments .chat-left .chat-box .comments-box .profile-text {
    font-family: "OpenSansRegular"; }
  .OpensansFont .last-top-comments .view-more-comment {
    font-family: "OpenSansRegular"; }
  .OpensansFont .dash-profile-left .dash-profile-name {
    font-family: "OpenSansRegular"; }
    .OpensansFont .dash-profile-left .dash-profile-name .dash-profile-fullname {
      font-family: "OpenSansSemibold"; }
  .OpensansFont .left-content-box .affordability-titel {
    font-family: "OpenSansRegular"; }
  .OpensansFont .affordability-list .affordability-name {
    font-family: "OpenSansBold"; }
  .OpensansFont .dashboard-right {
    /********dashboard tab start*****/
    /********dashboard tab End*****/
    /********timeline left start*****/
    /********timeline left End*****/
    /********timeline right start*****/
    /********timeline right End*****/
    /********account info start*****/ }
    .OpensansFont .dashboard-right [type="text"] {
      font-family: "OpenSansRegular"; }
    .OpensansFont .dashboard-right .tab-list .nav-tabs .nav-link {
      font-family: "OpenSansSemibold"; }
    .OpensansFont .dashboard-right .timeline-left-col .timeline-short-name .timeline-name-box {
      font-family: "OpenSansSemibold"; }
    .OpensansFont .dashboard-right .timeline-right-col .mb-timeline-left-col .timeline-short-name .timeline-name-box {
      font-family: "OpenSansSemibold"; }
    .OpensansFont .dashboard-right .timeline-right-col .timeline-create-info .timeline-create {
      font-family: "OpenSansSemibold"; }
      .OpensansFont .dashboard-right .timeline-right-col .timeline-create-info .timeline-create .create-item {
        font-family: "OpenSansRegular"; }
      .OpensansFont .dashboard-right .timeline-right-col .timeline-create-info .timeline-create .last-active-time {
        font-family: "OpenSansRegular"; }
    .OpensansFont .dashboard-right .timeline-right-col .timeline-comment-bg .last-top-comments .chat-profile-round {
      font-family: "OpenSansSemibold"; }
    .OpensansFont .dashboard-right .account-detail .signal-switch {
      font-family: "OpenSansRegular"; }
    .OpensansFont .dashboard-right .account-detail .price-range {
      font-family: "OpenSansSemibold"; }
    .OpensansFont .dashboard-right .commute-calculat-edit {
      font-family: "OpenSansLight"; }
    .OpensansFont .dashboard-right .clients-info .clients-name .clients-fullname {
      font-family: "OpenSansBold"; }
      .OpensansFont .dashboard-right .clients-info .clients-name .clients-fullname .client-last-logged {
        font-family: "OpenSansRegular"; }
    .OpensansFont .dashboard-right .agent-client-detail .detail-title {
      font-family: "OpenSansRegular"; }
  .OpensansFont .contactus-small-label {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .blog-banner-sub-title {
    font-family: "OpenSansLight"; }
  .OpensansFont .blog-card .blog-card-info .blog-header {
    font-family: "OpenSansBold"; }
  .OpensansFont .blog-card .blog-short-content .blog-ct-more a {
    font-family: "OpenSansBold"; }
  .OpensansFont .blog-comments-content .blog-comments-listing .comments-number-title {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .blog-comments-content .blog-comments-listing .comments-reply-bt a {
    font-family: "OpenSansBold"; }
  .OpensansFont .MuiTypography-body2 {
    font-family: "OpenSansRegular"; }
  .OpensansFont .manage-card .card-prop-top {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .manage-card .card-prop-footer {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .table-top-title {
    font-family: "OpenSansRegular"; }
  .OpensansFont .save-search-table-header .filter-title {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .white-card-content .content-item-text {
    font-family: "OpenSansRegular"; }
  .OpensansFont .pd-loader-text-t1 {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .pd-loader-text-t2 {
    font-family: "OpenSansLight"; }
  .OpensansFont .compare-cost-header .highlight-text {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .compare-result-info .compare-result-price {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .compare-result-info .compare-result-city {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .compare-city-box .compare-city-name {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .compare-city-box .compare-last-amount {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .more-tlc-price-filter .prop-price-filter .dropdown .dropdown-toggle {
    font-family: "OpenSansRegular"; }
  .OpensansFont .more-commute-filter .dropdown .dropdown-toggle {
    font-family: "OpenSansRegular"; }
  .OpensansFont .more-commute-filter .rbt-input-main {
    font-family: "RobotoRegular" !important; }
  .OpensansFont .agent-listing-info .tab-list .nav-tabs .nav-link {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .profile-location-dropdown .rbt-input-main {
    font-family: "RobotoRegular"; }
  .OpensansFont .joyride-tooltip__header {
    font-family: "OpenSansBold"; }
  .OpensansFont .joyride-tooltip__button.joyride-tooltip__button--skip {
    font-family: "OpenSansRegular"; }
  .OpensansFont .joyride-tooltip__button.joyride-tooltip__button--secondary {
    font-family: "OpenSansRegular"; }
  .OpensansFont .widget-card h2 {
    font-family: "OpenSansRegular"; }
  .OpensansFont .widget-card .numeric-input-box .form-control {
    font-family: "OpenSansRegular"; }
  .OpensansFont .expenses-cost-header .expenses-content {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .tlc-note {
    font-family: "OpenSansRegular"; }
  .OpensansFont .journey-left .sidebar-journey-title {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .journey-content h3 {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .journey-content h4 {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .journey-content .journey-checkbox-fill .journey-checkbox-title {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .journey-content .journey-checkbox-fill .journey-checkbox-subtitle {
    font-family: "OpenSansRegular"; }
  .OpensansFont .journey-content .journey-radio-fill .journey-icon-wrap .journey-radio-bold-title {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .journey-content .move-range-box .input-range__track .input-range__label--value .input-range__label-container {
    font-family: "OpenSansBold"; }
  .OpensansFont .journey-content .move-range-box .input-range .input-range__label--min .input-range__label-container {
    font-family: "OpenSansRegular"; }
  .OpensansFont .journey-content .move-range-box .input-range .input-range__label--max .input-range__label-container {
    font-family: "OpenSansRegular"; }
  .OpensansFont .journey-content .month-range-info {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .journey-content .bold-title {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .journey-content .mortgage-types-chart .circular-chart .percentage {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .journey-content .pdf-download-link .pdf-dn-name {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .leaflet-popup-content .poi_tooltip .poi-header-name {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .home-banner2-bg .header-search-filter .filter-mid-box .tlc-range-box .tlc-range-title {
    font-family: "OpenSansLight"; }
  .OpensansFont .home-banner2-bg .header-search-filter .filter-mid-box .tlc-range-box .input-range__label--min .input-range__label-container {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .home-banner2-bg .header-search-filter .filter-mid-box .tlc-range-box .input-range__label--max .input-range__label-container {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .home-banner2-bg .header-titles-box .banner-title {
    font-family: "OpenSansBold"; }
  .OpensansFont .home-banner2-bg .filter-find-button button {
    font-family: "OpenSansLight"; }
  .OpensansFont.with-submenu .short-menubox .short-menu-link .nav-link {
    font-family: "OpenSansRegular"; }
    .OpensansFont.with-submenu .short-menubox .short-menu-link .nav-link.active {
      font-family: "OpenSansRegular"; }
  .OpensansFont .block-image-subheader .section-content .block-title {
    font-family: "OpenSansLight"; }
  .OpensansFont .block-image-subheader .section-content .block-subtitle {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .landing-page-mid .landing-mid-content .landing-mid-title {
    font-family: "OpenSansLight"; }
  .OpensansFont .landing-page-mid .landing-mid-content .heading-sub-text {
    font-family: "OpenSansRegular"; }
  .OpensansFont .landing-page-mid .patternbg-card-content h1 {
    font-family: "OpenSansLight"; }
  .OpensansFont .landing-page-mid .patternbg-card-content .more-listing a {
    font-family: "OpenSansLight"; }
  .OpensansFont .question-card-patternbg .question-name {
    font-family: "OpenSansLight"; }
  .OpensansFont .blogs-cards-patternbg .card-content {
    font-family: "OpenSansRegular"; }
  .OpensansFont .blogs-cards-patternbg .cms-blog-title {
    font-family: 'aleo-bold'; }
  .OpensansFont .blogs-cards-patternbg .cms-subblog-title {
    font-family: "OpenSansLight"; }
  .OpensansFont .blogs-cards-patternbg .cms-blog-readmore {
    font-family: 'aleo-regular'; }
    .OpensansFont .blogs-cards-patternbg .cms-blog-readmore a {
      font-family: 'aleo-regular'; }
  .OpensansFont .blogs-cards-patternbg .more-blog-listing a {
    font-family: "OpenSansLight"; }
  .OpensansFont .footer-otherstyle .footer-bg-color {
    font-family: "OpenSansLight"; }
  .OpensansFont .footer-nav-box .footer-nav a {
    font-family: "OpenSansLight"; }
  .OpensansFont .modal-content .modal-header {
    font-family: "OpenSansRegular"; }
    .OpensansFont .modal-content .modal-header h1 {
      font-family: "OpenSansRegular"; }
    .OpensansFont .modal-content .modal-header h2 {
      font-family: "OpenSansRegular"; }
  .OpensansFont .modal-content .modal-body {
    font-family: "OpenSansRegular"; }
    .OpensansFont .modal-content .modal-body [type="text"] {
      font-family: "OpenSansLight"; }
    .OpensansFont .modal-content .modal-body .typeahead-search .form-control {
      font-family: "OpenSansRegular"; }
    .OpensansFont .modal-content .modal-body h2 {
      font-family: "OpenSansRegular"; }
    .OpensansFont .modal-content .modal-body .sub-label {
      font-family: "OpenSansLight"; }
    .OpensansFont .modal-content .modal-body .modal-small-title {
      font-family: "OpenSansSemibold"; }
    .OpensansFont .modal-content .modal-body .MuiTypography-body2 {
      font-family: "OpenSansRegular"; }
    .OpensansFont .modal-content .modal-body .tlc-calculat-edit h3 {
      font-family: "OpenSansSemibold"; }
    .OpensansFont .modal-content .modal-body .tlc-calculat-edit label {
      font-family: "OpenSansLight"; }
      .OpensansFont .modal-content .modal-body .tlc-calculat-edit label.calculat-label {
        font-family: "OpenSansRegular"; }
      .OpensansFont .modal-content .modal-body .tlc-calculat-edit label.tlc-calculat-label {
        font-family: "OpenSansBold"; }
    .OpensansFont .modal-content .modal-body .tlc-calculat-edit .est-cost {
      font-family: "OpenSansLight"; }
    .OpensansFont .modal-content .modal-body .tlc-calculat-edit .final-cost [type="text"] {
      font-family: "OpenSansRegular"; }
    .OpensansFont .modal-content .modal-body .tlc-calculat-edit .tlc-cost {
      font-family: "OpenSansRegular"; }
      .OpensansFont .modal-content .modal-body .tlc-calculat-edit .tlc-cost.final-tlc-cost {
        font-family: "GothamOfficeBold"; }
    .OpensansFont .modal-content .modal-body .est-payment-text {
      color: #7b8aa0; }
    .OpensansFont .modal-content .modal-body .approved-name {
      font-family: "OpenSansBold"; }
    .OpensansFont .modal-content .modal-body .commute-calculat-edit-popup {
      font-family: "OpenSansRegular"; }
      .OpensansFont .modal-content .modal-body .commute-calculat-edit-popup .commute-result .commute-name .commute-address {
        font-family: "OpenSansSemibold"; }
    .OpensansFont .modal-content .modal-body .contact-agent-disclaimer-cnt {
      font-family: "OpenSansRegular"; }
    .OpensansFont .modal-content .modal-body .widget-blur-loader {
      font-family: "OpenSansRegular" !important; }
  .OpensansFont .reg-blog-content .heading-sub-text {
    font-family: "OpenSansLight"; }
  .OpensansFont .reg-blog-content .reg-blog-ctn .reg-blog-blox .reg-blog-title {
    font-family: "OpenSansBold"; }
  .OpensansFont .reg-blog-content .reg-blog-ctn .reg-blog-blox .reg-blog-readmore {
    font-family: "OpenSansBold"; }
    .OpensansFont .reg-blog-content .reg-blog-ctn .reg-blog-blox .reg-blog-readmore a {
      font-family: "OpenSansBold"; }
  .OpensansFont .reg-blog-content .reg-blog-more-listing {
    font-family: "OpenSansBold"; }
    .OpensansFont .reg-blog-content .reg-blog-more-listing a {
      font-family: "OpenSansBold"; }
  .OpensansFont .footer-nav-box .footer-nav-sm-title {
    font-family: "OpenSansSemibold"; }
  .OpensansFont .terms-cnt-list .small-cnt-header {
    font-family: "OpenSansBold"; }
  .OpensansFont .market-trends .data-highlight {
    font-family: "OpenSansBold"; }
  .OpensansFont .extra-filters-bar .dropdown .dropdown-toggle {
    font-family: "OpenSansRegular"; }
  .OpensansFont .extra-filters-bar .dropdown .dropdown-menu {
    font-family: "OpenSansRegular"; }
  .OpensansFont .extra-filters-bar .price-range-control [type="text"] {
    font-family: "OpenSansRegular"; }

/*****************************************/
.modal-content .modal-body .MuiTypography-body2 {
  font-family: "GothamBook"; }

.modal-content .modal-body .tlc-calculat-edit h3 {
  font-family: "GothamMedium"; }

.modal-content .modal-body .tlc-calculat-edit label {
  font-family: "GothamLight"; }
  .modal-content .modal-body .tlc-calculat-edit label.calculat-label {
    font-family: "GothamBook"; }
  .modal-content .modal-body .tlc-calculat-edit label.tlc-calculat-label {
    font-family: "GothamBold"; }

.modal-content .modal-body .tlc-calculat-edit .est-cost {
  font-family: "GothamLight"; }

.modal-content .modal-body .tlc-calculat-edit .final-cost [type="text"] {
  font-family: "GothamBook"; }

.modal-content .modal-body .tlc-calculat-edit .tlc-cost {
  font-family: "GothamOfficeRegular"; }
  .modal-content .modal-body .tlc-calculat-edit .tlc-cost.final-tlc-cost {
    font-family: "GothamOfficeBold"; }

.modal-content .modal-body .est-payment-text {
  color: #7b8aa0; }

.modal-content .modal-body .approved-name {
  font-family: "GothamBold"; }

.modal-content .modal-body .commute-calculat-edit-popup {
  font-family: "GothamBook"; }
  .modal-content .modal-body .commute-calculat-edit-popup .commute-result .commute-name .commute-address {
    font-family: "GothamMedium"; }

.modal-content .modal-body .contact-agent-disclaimer-cnt {
  font-family: "GothamOfficeRegular"; }

.modal-content .modal-body .property-description h3 {
  font-size: 18px; }

/***********************************Gotham End*********************/
img {
  width: 100%; }

.cursor-point {
  cursor: pointer; }

.underline-text {
  text-decoration: underline; }

.status-active {
  border-radius: 11px;
  padding: 3px 10px;
  font-size: 8px;
  display: inline-block;
  line-height: normal;
  vertical-align: middle; }

/*****************section start**************/
section {
  width: 100%;
  display: flex; }

.section-title h1 {
  font-size: 26px; }
  @media only screen and (max-width: 1023px) {
    .section-title h1 {
      font-size: 24px; } }
  @media only screen and (max-width: 767px) {
    .section-title h1 {
      font-size: 22px; } }
  @media only screen and (max-width: 480px) {
    .section-title h1 {
      font-size: 20px; } }

.section-title .heading-sub-text {
  padding-top: 14px;
  line-height: 25px;
  font-size: 15px;
  display: inline-block; }
  .section-title .heading-sub-text a:hover {
    text-decoration: underline; }
  .section-title .heading-sub-text ul {
    margin: 0 0 0 15px;
    padding: 0; }
    .section-title .heading-sub-text ul li {
      margin: 0 0 10px 0;
      padding: 0;
      line-height: normal; }
  @media only screen and (max-width: 630px) {
    .section-title .heading-sub-text {
      padding-bottom: 20px; } }
  .section-title .heading-sub-text.heading-title-text {
    display: block;
    text-align: center;
    font-size: 26px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: inherit; }
    .section-title .heading-sub-text.heading-title-text img {
      max-width: 200px;
      display: block;
      margin: 8px auto; }

.section-title .heading-sub-line {
  padding-top: 0;
  line-height: 25px;
  font-size: 15px;
  display: inline-block; }
  .section-title .heading-sub-line a:hover {
    text-decoration: underline; }

.white-card-cnt-box {
  width: 100%;
  display: block;
  padding: 0 20px; }

.white-card-content {
  background: #fff;
  border-radius: 8px;
  padding: 37px 0px 30px 0px;
  width: 100%;
  display: block; }
  .white-card-content .container {
    max-width: 100%;
    padding: 0 50px; }
    @media only screen and (max-width: 1367px) {
      .white-card-content .container {
        padding: 0 15px; } }
    @media only screen and (max-width: 1367px) {
      .white-card-content .container .row {
        margin-left: -15px;
        margin-right: -15px; } }
  .white-card-content .content-item-text {
    font-size: 15px;
    width: 100%;
    display: block; }

/*****************section End**************/
/*****************Overflow hidden scroll start**************/
.mb-no-scroll {
  position: fixed; }

/*****************Overflow hidden scroll End**************/
[type="button"] {
  border: none;
  outline: none; }
  [type="button"]:focus {
    outline: none; }

/*****************checkbox start**************/
.common-checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 13px;
  font-weight: normal; }
  .common-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    appearance: none; }
    .common-checkbox input:checked + .checkmark {
      background: #00adbb;
      border: 2px solid #00adbb; }
      .common-checkbox input:checked + .checkmark:after {
        display: block; }
  .common-checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background: #ffffff;
    border: 2px solid #757575;
    border-radius: 3px; }
    .common-checkbox .checkmark:after {
      left: 5px;
      top: 1px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      content: "";
      position: absolute;
      display: none; }

/*****************checkbox End**************/
/*****************radio-option start**************/
.radio-option-beds {
  width: 100%;
  border: 1px solid #dddddd;
  border-radius: 4px; }
  .radio-option-beds label {
    width: 12.5%;
    font-size: 14px;
    border-left: 1px solid #dddddd;
    min-height: 32px;
    text-align: center;
    line-height: 32px;
    margin-bottom: 0px;
    cursor: pointer; }
    .radio-option-beds label .beds-input-hidden:checked + .beds-radio-fill {
      background: #00adbb;
      color: #fff;
      min-height: 32px; }
    .radio-option-beds label:first-child {
      border: none; }
      .radio-option-beds label:first-child .beds-input-hidden:checked + .beds-radio-fill {
        border-radius: 4px 0 0 4px; }
    .radio-option-beds label:last-child .beds-input-hidden:checked + .beds-radio-fill {
      border-radius: 0 4px 4px 0; }
  .radio-option-beds.option-5 label {
    width: 20%;
    font-size: 13px; }
    @media only screen and (max-width: 990px) {
      .radio-option-beds.option-5 label {
        font-size: 11px; } }
  .radio-option-beds.option-7 label {
    width: 14.28%; }
  .radio-option-beds.option-2 label {
    width: 50%; }
  .radio-option-beds.option-3 label {
    width: 33.33%; }
  .radio-option-beds.option-auto {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    border: none; }
    .radio-option-beds.option-auto label {
      display: flex;
      line-height: normal;
      border: 1px solid #DDDDDD;
      word-break: break-word;
      font-size: 14px; }
      @media only screen and (max-width: 910px) {
        .radio-option-beds.option-auto label {
          font-size: 13px; } }
      @media only screen and (max-width: 768px) {
        .radio-option-beds.option-auto label {
          font-size: 14px; } }
      .radio-option-beds.option-auto label:first-child {
        border-radius: 4px 0 0 4px;
        border-right: none; }
      .radio-option-beds.option-auto label:last-child {
        border-radius: 0 4px 4px 0;
        border-left: none; }
      .radio-option-beds.option-auto label .beds-radio-fill {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 10px 10px; }
        @media only screen and (max-width: 910px) {
          .radio-option-beds.option-auto label .beds-radio-fill {
            padding: 10px 5px; } }
        @media only screen and (max-width: 768px) {
          .radio-option-beds.option-auto label .beds-radio-fill {
            padding: 10px 10px; } }
      .radio-option-beds.option-auto label.option-3 label {
        width: 33.33%; }
    @media only screen and (max-width: 768px) {
      .radio-option-beds.option-auto {
        width: 100%; } }

/*****************radio-option start**************/
/********************switchbox Start*************/
.switch-box {
  float: right; }
  .switch-box input[type="checkbox"]:checked + label:before {
    /* background:#88d8df; */
    opacity: 0.5;
    box-shadow: inherit; }
  .switch-box input[type="checkbox"]:checked + label:after {
    background: inherit;
    left: 25px; }
  .switch-box label {
    cursor: pointer;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 40px; }
    .switch-box label:before {
      background: #d8d8d8;
      border-radius: 6px;
      content: "";
      height: 13px;
      margin-top: -7px;
      position: absolute;
      transition: all 0.4s ease-in-out;
      width: 40px; }
    .switch-box label:after {
      background: white;
      border-radius: 16px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
      content: "";
      height: 16px;
      left: -4px;
      margin-top: -7px;
      position: absolute;
      top: -1px;
      transition: all 0.3s ease-in-out;
      width: 16px; }
  .switch-box .open-switchbox-bg {
    background-color: #00adbb; }

/********************switchbox End*************/
/********************accordion start*************/
.accordion-listing {
  width: 100%;
  float: left;
  /*************card header *******************/
  /* & .collapse {
		&.show {
			&::before {
				position: absolute;
				top:20px;
				right:20px;
				font-size:15px;
				font-family: 'Material Icons';
				content:url(../img/nd/arrow-up.png);
				@include breakpointMax(1190) {
					right:12px;
				}
			}
		}
	} */ }
  .accordion-listing .card {
    margin-bottom: 25px;
    width: 100%;
    border: none;
    border-radius: 8px !important;
    box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
    word-break: break-word; }
  .accordion-listing .card-body {
    padding: 20px 20px 20px 20px; }
    @media only screen and (max-width: 767px) {
      .accordion-listing .card-body {
        padding: 20px 20px 20px 20px; } }
    .accordion-listing .card-body .property-accordion-content {
      padding: 0px 20px 15px 20px;
      width: 100%;
      display: block;
      margin-top: -20px; }
      @media only screen and (max-width: 1024px) {
        .accordion-listing .card-body .property-accordion-content {
          padding: 0px 0px 15px 0px; } }
  .accordion-listing .card-header {
    background: #fff;
    border-radius: 8px !important;
    border: none;
    padding: 0px; }
    .accordion-listing .card-header .header-name {
      border: none;
      padding: 30px 40px;
      background: #fff;
      width: 100%;
      text-align: left;
      position: relative;
      font-size: 20px; }
      @media only screen and (max-width: 1190px) {
        .accordion-listing .card-header .header-name {
          padding: 20px 20px; } }
      .accordion-listing .card-header .header-name:after {
        position: absolute;
        top: 33px;
        right: 40px;
        font-size: 15px;
        font-family: "Material Icons";
        content: url(../img/nd/arrow-down.png); }
        @media only screen and (max-width: 1190px) {
          .accordion-listing .card-header .header-name:after {
            right: 22px;
            top: 22px; } }
      @media only screen and (max-width: 1024px) {
        .accordion-listing .card-header .header-name {
          font-size: 17px;
          padding: 25px 20px; }
          .accordion-listing .card-header .header-name:after {
            top: 26px;
            right: 22px; } }
      @media only screen and (max-width: 767px) {
        .accordion-listing .card-header .header-name {
          font-size: 15px; } }
      .accordion-listing .card-header .header-name .sub-header-name {
        color: #bec4cb;
        font-size: 14px;
        display: block;
        padding-top: 5px; }
      .accordion-listing .card-header .header-name:active, .accordion-listing .card-header .header-name:focus {
        border: none;
        outline: none; }
      .accordion-listing .card-header .header-name.open::after {
        display: none; }
      .accordion-listing .card-header .header-name.open::before {
        position: absolute;
        top: 33px;
        right: 40px;
        font-size: 15px;
        font-family: "Material Icons";
        content: url(../img/nd/arrow-up.png); }
        @media only screen and (max-width: 1190px) {
          .accordion-listing .card-header .header-name.open::before {
            right: 22px;
            top: 22px; } }
        @media only screen and (max-width: 1025px) {
          .accordion-listing .card-header .header-name.open::before {
            right: 22px;
            top: 26px; } }
        @media only screen and (max-width: 767px) {
          .accordion-listing .card-header .header-name.open::before {
            right: 20px;
            top: 21px; } }

/********************accordion end**********************/
.inner-header {
  width: 100%;
  display: block;
  padding-top: 110px; }
  @media only screen and (max-width: 1025px) {
    .inner-header {
      padding-top: 50px; } }
  .inner-header.compact-header {
    padding-top: 30px; }

/*****************common css End**************/
/*****************************home page start**********************************************************/
.banner-title {
  font-size: 35px; }
  @media only screen and (max-width: 1024px) {
    .banner-title {
      font-size: 35px; } }
  @media only screen and (max-width: 767px) {
    .banner-title {
      font-size: 24px; } }

.banner-sub-title {
  font-size: 26px;
  padding-top: 30px;
  padding-bottom: 40px;
  line-height: 40px; }
  @media only screen and (max-width: 1024px) {
    .banner-sub-title {
      font-size: 22px; } }
  @media only screen and (max-width: 767px) {
    .banner-sub-title {
      font-size: 17px;
      line-height: 30px; } }
  @media only screen and (max-width: 320px) {
    .banner-sub-title {
      font-size: 14px; } }
  .banner-sub-title span {
    display: block;
    /* padding-top:12px; */ }

/*******banner Background start***************/
.home-banner-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  /*background-image:url(../img/nd/header-bg.jpg);*/
  position: relative;
  width: 100%;
  display: block;
  min-height: 800px;
  padding-top: 110px;
  /*******banner filter ***************/ }
  .home-banner-bg.no-img {
    background-color: #f7f7f7;
    min-height: 450px; }
  .home-banner-bg.home-banner-compact {
    padding-top: 30px;
    min-height: 324px; }
  @media only screen and (max-width: 1230px) {
    .home-banner-bg {
      min-height: 670px;
      padding-top: 90px; }
      .home-banner-bg.no-img {
        min-height: 450px; }
      .home-banner-bg.home-banner-compact {
        min-height: 324px; } }
  @media only screen and (max-width: 1199px) {
    .home-banner-bg.no-img.with-ad {
      min-height: 545px; }
    .home-banner-bg.no-img.home-banner-compact {
      min-height: 320px; }
      .home-banner-bg.no-img.home-banner-compact.with-ad {
        min-height: 485px; } }
  @media only screen and (max-width: 1160px) {
    .home-banner-bg.no-img.with-ad {
      min-height: 545px; }
    .home-banner-bg.no-img.home-banner-compact {
      min-height: 320px; }
      .home-banner-bg.no-img.home-banner-compact.with-ad {
        min-height: 485px; } }
  @media only screen and (max-width: 1024px) {
    .home-banner-bg {
      min-height: 570px;
      padding-top: 63px; }
      .home-banner-bg.no-img {
        min-height: 340px; }
        .home-banner-bg.no-img.with-ad {
          min-height: 524px; }
        .home-banner-bg.no-img.home-banner-compact {
          min-height: 320px; }
          .home-banner-bg.no-img.home-banner-compact.with-ad {
            min-height: 485px; } }
  @media only screen and (max-width: 767px) {
    .home-banner-bg.no-img.with-ad {
      min-height: 545px; }
    .home-banner-bg.no-img.home-banner-compact {
      min-height: 350px; }
      .home-banner-bg.no-img.home-banner-compact.with-ad {
        min-height: 514px; } }
  @media only screen and (max-width: 480px) {
    .home-banner-bg {
      min-height: 400px;
      padding-top: 50px; }
      .home-banner-bg.no-img {
        min-height: 340px; }
        .home-banner-bg.no-img.with-ad {
          min-height: 380px; }
        .home-banner-bg.no-img.home-banner-compact {
          min-height: 350px; }
          .home-banner-bg.no-img.home-banner-compact.with-ad {
            min-height: 350px; } }
  .home-banner-bg .home-autosuggest-search .typeahead-search {
    /* width: calc(100% - 126px); */
    width: calc(100% - 180px);
    box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
    margin-right: 16px; }
    @media only screen and (max-width: 767px) {
      .home-banner-bg .home-autosuggest-search .typeahead-search {
        width: 100%;
        margin-right: 0px; } }
  .home-banner-bg .home-autosuggest-search .filter-button {
    min-height: 55px;
    min-width: 110px;
    max-width: 156px;
    vertical-align: top;
    font-size: 17px; }
    @media only screen and (max-width: 767px) {
      .home-banner-bg .home-autosuggest-search .filter-button {
        min-height: 50px;
        padding: 10px 10px;
        font-size: 14px;
        width: 110px; } }
  .home-banner-bg .home-autosuggest-search .rbt-color-input .rbt-input-hint {
    color: #4A4A4A !important; }
  .home-banner-bg .home-header-adds-wrapper {
    width: 302px;
    position: absolute;
    top: 110px;
    right: 100px;
    /* @include breakpointMax(1199) {
			top: 20px;
			right: 20px;
		} */ }
    @media only screen and (max-width: 1439px) {
      .home-banner-bg .home-header-adds-wrapper {
        right: 40px; } }
    @media only screen and (max-width: 1300px) {
      .home-banner-bg .home-header-adds-wrapper {
        right: 20px; } }
    @media only screen and (max-width: 1199px) {
      .home-banner-bg .home-header-adds-wrapper {
        top: inherit;
        right: inherit;
        left: calc(50% - 151px);
        bottom: 100px; } }
    @media only screen and (max-width: 1024px) {
      .home-banner-bg .home-header-adds-wrapper {
        top: inherit;
        right: inherit;
        left: calc(50% - 151px);
        bottom: 50px; } }
    @media only screen and (max-width: 767px) {
      .home-banner-bg .home-header-adds-wrapper {
        top: inherit;
        right: inherit;
        left: calc(50% - 151px);
        bottom: 20px; } }
    @media only screen and (max-width: 480px) {
      .home-banner-bg .home-header-adds-wrapper {
        display: none; } }
    .home-banner-bg .home-header-adds-wrapper .home-header-adds {
      border: 1px solid #b2b2b2;
      width: 302px;
      height: 152px; }
      .home-banner-bg .home-header-adds-wrapper .home-header-adds .home-header-adds-box {
        width: 100%;
        height: 50px; }
      .home-banner-bg .home-header-adds-wrapper .home-header-adds .home-header-adds-box-full {
        width: 100%;
        height: 150px; }
  @media only screen and (max-width: 1199px) {
    .home-banner-bg.no-img.with-ad .home-header-adds-wrapper {
      bottom: 10px; } }
  .home-banner-bg.home-banner-compact .home-header-adds-wrapper {
    top: 55px; }
    @media only screen and (max-width: 1199px) {
      .home-banner-bg.home-banner-compact .home-header-adds-wrapper {
        top: inherit;
        bottom: 10px; } }
  .home-banner-bg.no-title {
    min-height: 84px !important; }

/*******banner Background End***************/
/*****************************TLC Help start***********************************/
.tlc-how-work {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden; }
  @media only screen and (max-width: 1450px) {
    .tlc-how-work {
      overflow: hidden; } }
  .tlc-how-work .work-text {
    float: left;
    width: 100%;
    margin-top: 40px; }
    @media only screen and (max-width: 767px) {
      .tlc-how-work .work-text {
        position: relative;
        top: inherit;
        left: inherit;
        padding-top: 50px; } }
    @media only screen and (max-width: 480px) {
      .tlc-how-work .work-text {
        padding-top: 20px;
        padding-bottom: 30px; } }
  .tlc-how-work .desktop-img-banner {
    position: relative;
    height: 429px;
    width: 1450px;
    float: none;
    margin: 60px auto 0;
    display: inline-block; }
    @media only screen and (max-width: 1450px) {
      .tlc-how-work .desktop-img-banner {
        width: 100%;
        height: 445px; } }
    @media only screen and (max-width: 1400px) {
      .tlc-how-work .desktop-img-banner {
        height: 435px;
        margin: 60px auto 0; } }
    @media only screen and (max-width: 1350px) {
      .tlc-how-work .desktop-img-banner {
        height: 415px; } }
    @media only screen and (max-width: 1300px) {
      .tlc-how-work .desktop-img-banner {
        height: 405px; } }
    @media only screen and (max-width: 1230px) {
      .tlc-how-work .desktop-img-banner {
        height: 383px; } }
    @media only screen and (max-width: 1150px) {
      .tlc-how-work .desktop-img-banner {
        height: 352px; } }
    @media only screen and (max-width: 1100px) {
      .tlc-how-work .desktop-img-banner {
        height: 342px; } }
    @media only screen and (max-width: 1024px) {
      .tlc-how-work .desktop-img-banner {
        height: 315px; } }
    @media only screen and (max-width: 991px) {
      .tlc-how-work .desktop-img-banner {
        display: none; } }
    .tlc-how-work .desktop-img-banner .tlc-price-amount {
      position: absolute;
      left: 46%;
      bottom: 117px;
      width: 174px;
      text-align: center;
      height: 50px;
      font-size: 20px;
      padding: 3px 5px;
      line-height: 40px;
      z-index: 10; }
      @media only screen and (max-width: 1450px) {
        .tlc-how-work .desktop-img-banner .tlc-price-amount {
          bottom: 136px;
          height: 46px; } }
      @media only screen and (max-width: 1440px) {
        .tlc-how-work .desktop-img-banner .tlc-price-amount {
          bottom: 140px;
          height: 42px;
          line-height: 35px; } }
      @media only screen and (max-width: 1423px) {
        .tlc-how-work .desktop-img-banner .tlc-price-amount {
          bottom: 140px; } }
      @media only screen and (max-width: 1400px) {
        .tlc-how-work .desktop-img-banner .tlc-price-amount {
          bottom: 135px; } }
      @media only screen and (max-width: 1380px) {
        .tlc-how-work .desktop-img-banner .tlc-price-amount {
          bottom: 139px; } }
      @media only screen and (max-width: 1366px) {
        .tlc-how-work .desktop-img-banner .tlc-price-amount {
          bottom: 144px; } }
      @media only screen and (max-width: 1350px) {
        .tlc-how-work .desktop-img-banner .tlc-price-amount {
          bottom: 136px;
          width: 164px;
          height: 41px; } }
      @media only screen and (max-width: 1300px) {
        .tlc-how-work .desktop-img-banner .tlc-price-amount {
          bottom: 132px;
          width: 154px;
          height: 40px; } }
      @media only screen and (max-width: 1299px) {
        .tlc-how-work .desktop-img-banner .tlc-price-amount {
          bottom: 131px; } }
      @media only screen and (max-width: 1280px) {
        .tlc-how-work .desktop-img-banner .tlc-price-amount {
          bottom: 140px; } }
      @media only screen and (max-width: 1230px) {
        .tlc-how-work .desktop-img-banner .tlc-price-amount {
          bottom: 122px; } }
      @media only screen and (max-width: 1210px) {
        .tlc-how-work .desktop-img-banner .tlc-price-amount {
          bottom: 130px;
          width: 147px;
          height: 36px; } }
      @media only screen and (max-width: 1190px) {
        .tlc-how-work .desktop-img-banner .tlc-price-amount {
          height: 36px;
          bottom: 130px;
          line-height: 35px;
          font-size: 19px; } }
      @media only screen and (max-width: 1175px) {
        .tlc-how-work .desktop-img-banner .tlc-price-amount {
          bottom: 135px; } }
      @media only screen and (max-width: 1150px) {
        .tlc-how-work .desktop-img-banner .tlc-price-amount {
          bottom: 111px; } }
      @media only screen and (max-width: 1110px) {
        .tlc-how-work .desktop-img-banner .tlc-price-amount {
          bottom: 116px;
          font-size: 18px; } }
      @media only screen and (max-width: 1100px) {
        .tlc-how-work .desktop-img-banner .tlc-price-amount {
          width: 135px;
          bottom: 111px; } }
      @media only screen and (max-width: 1070px) {
        .tlc-how-work .desktop-img-banner .tlc-price-amount {
          bottom: 119px;
          height: 33px; } }
      @media only screen and (max-width: 1050px) {
        .tlc-how-work .desktop-img-banner .tlc-price-amount {
          bottom: 124px;
          height: 33px; } }
      @media only screen and (max-width: 1024px) {
        .tlc-how-work .desktop-img-banner .tlc-price-amount {
          bottom: 100px;
          height: 33px; } }
      @media only screen and (max-width: 1023px) {
        .tlc-how-work .desktop-img-banner .tlc-price-amount {
          bottom: 96px;
          height: 33px;
          line-height: 26px; } }
      @media only screen and (max-width: 1000px) {
        .tlc-how-work .desktop-img-banner .tlc-price-amount {
          bottom: 100px; } }
    .tlc-how-work .desktop-img-banner .tlchelp-banner {
      position: relative;
      /*  &:after {
				position:absolute;
				content:'';
				right: calc(100% - 33.45%);
				top: 5px;
				background: linear-gradient(180deg, rgba(0,173,187,0) 0%, rgba(0,173,187,0.17) 100%);
				width:100%;
				height:100%;
				z-index: 9;
				@include breakpointMax(991) {
					display: none;
				} 
				@include breakpointMax(1450) {
					top: 0px;
				}
			}  */
      /* &:before {
				position:absolute;
				content:'';
				left:calc(100% - 33.36%);
				top:5px;
				background: linear-gradient(180deg, rgba(34,91,118,0) 0%, rgba(34,91,118,0.31) 100%);
				width:100%;
				height:100%;
				z-index: 9;
				@include breakpointMax(991) {
					display:none;
				} 
				@include breakpointMax(1450) {
					top: 0px;
				}
			} */ }
      .tlc-how-work .desktop-img-banner .tlchelp-banner img {
        width: auto;
        position: relative;
        z-index: 9;
        display: inline-block;
        vertical-align: bottom; }
        @media only screen and (max-width: 1450px) {
          .tlc-how-work .desktop-img-banner .tlchelp-banner img {
            width: 100%; }
            .tlc-how-work .desktop-img-banner .tlchelp-banner img img {
              width: 100%; } }
      .tlc-how-work .desktop-img-banner .tlchelp-banner .banner-left-gradient {
        position: absolute;
        left: calc(100% - 33.36%);
        top: 5px;
        background: linear-gradient(180deg, rgba(34, 91, 118, 0) 0%, rgba(34, 91, 118, 0.31) 100%);
        width: 100%;
        height: 100%;
        z-index: 9; }
        @media only screen and (max-width: 991px) {
          .tlc-how-work .desktop-img-banner .tlchelp-banner .banner-left-gradient {
            display: none; } }
        @media only screen and (max-width: 1450px) {
          .tlc-how-work .desktop-img-banner .tlchelp-banner .banner-left-gradient {
            top: 0px; } }
      .tlc-how-work .desktop-img-banner .tlchelp-banner .banner-right-gradient {
        position: absolute;
        right: calc(100% - 33.45%);
        top: 5px;
        background: linear-gradient(180deg, rgba(0, 173, 187, 0) 0%, rgba(0, 173, 187, 0.17) 100%);
        width: 100%;
        height: 100%;
        z-index: 9; }
        @media only screen and (max-width: 991px) {
          .tlc-how-work .desktop-img-banner .tlchelp-banner .banner-right-gradient {
            display: none; } }
        @media only screen and (max-width: 1450px) {
          .tlc-how-work .desktop-img-banner .tlchelp-banner .banner-right-gradient {
            right: calc(100% - 33.31%); } }
        @media only screen and (max-width: 1450px) {
          .tlc-how-work .desktop-img-banner .tlchelp-banner .banner-right-gradient {
            top: 0px; } }
    .tlc-how-work .desktop-img-banner .tlc-mortgage-text {
      position: absolute;
      top: 86px;
      left: 12%;
      font-size: 24px;
      text-transform: uppercase;
      color: #b6c5cf;
      z-index: 10; }
      @media only screen and (max-width: 1366px) {
        .tlc-how-work .desktop-img-banner .tlc-mortgage-text {
          font-size: 22px;
          top: 82px; } }
      @media only screen and (max-width: 1300px) {
        .tlc-how-work .desktop-img-banner .tlc-mortgage-text {
          top: 78px; } }
      @media only screen and (max-width: 1230px) {
        .tlc-how-work .desktop-img-banner .tlc-mortgage-text {
          top: 72px;
          font-size: 20px; } }
      @media only screen and (max-width: 1160px) {
        .tlc-how-work .desktop-img-banner .tlc-mortgage-text {
          font-size: 18px;
          top: 69px; } }
      @media only screen and (max-width: 1100px) {
        .tlc-how-work .desktop-img-banner .tlc-mortgage-text {
          top: 65px; } }
      @media only screen and (max-width: 1024px) {
        .tlc-how-work .desktop-img-banner .tlc-mortgage-text {
          top: 61px;
          left: 11%;
          font-size: 16px; } }
      @media only screen and (max-width: 992px) {
        .tlc-how-work .desktop-img-banner .tlc-mortgage-text {
          font-size: 17px; } }
    .tlc-how-work .desktop-img-banner .tlc-utilities-text {
      position: absolute;
      top: 86px;
      left: 46%;
      font-size: 24px;
      text-transform: uppercase;
      color: #b6c5cf;
      z-index: 10; }
      @media only screen and (max-width: 1366px) {
        .tlc-how-work .desktop-img-banner .tlc-utilities-text {
          font-size: 22px;
          top: 82px; } }
      @media only screen and (max-width: 1300px) {
        .tlc-how-work .desktop-img-banner .tlc-utilities-text {
          top: 78px; } }
      @media only screen and (max-width: 1230px) {
        .tlc-how-work .desktop-img-banner .tlc-utilities-text {
          top: 72px;
          font-size: 20px; } }
      @media only screen and (max-width: 1160px) {
        .tlc-how-work .desktop-img-banner .tlc-utilities-text {
          font-size: 18px;
          top: 69px; } }
      @media only screen and (max-width: 1100px) {
        .tlc-how-work .desktop-img-banner .tlc-utilities-text {
          top: 65px; } }
      @media only screen and (max-width: 1024px) {
        .tlc-how-work .desktop-img-banner .tlc-utilities-text {
          top: 61px;
          font-size: 16px; } }
    .tlc-how-work .desktop-img-banner .tlc-commute-text {
      position: absolute;
      top: 86px;
      right: 13%;
      font-size: 24px;
      text-transform: uppercase;
      color: #b6c5cf;
      z-index: 10; }
      @media only screen and (max-width: 1450px) {
        .tlc-how-work .desktop-img-banner .tlc-commute-text {
          right: 12%; } }
      @media only screen and (max-width: 1366px) {
        .tlc-how-work .desktop-img-banner .tlc-commute-text {
          font-size: 22px;
          top: 82px; } }
      @media only screen and (max-width: 1300px) {
        .tlc-how-work .desktop-img-banner .tlc-commute-text {
          top: 78px; } }
      @media only screen and (max-width: 1230px) {
        .tlc-how-work .desktop-img-banner .tlc-commute-text {
          top: 72px;
          font-size: 20px; } }
      @media only screen and (max-width: 1160px) {
        .tlc-how-work .desktop-img-banner .tlc-commute-text {
          right: 13%;
          font-size: 18px;
          top: 69px; } }
      @media only screen and (max-width: 1100px) {
        .tlc-how-work .desktop-img-banner .tlc-commute-text {
          top: 65px; } }
      @media only screen and (max-width: 1024px) {
        .tlc-how-work .desktop-img-banner .tlc-commute-text {
          top: 61px;
          font-size: 16px; } }
    .tlc-how-work .desktop-img-banner .leftside-banner {
      width: 33.33%;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      z-index: 10;
      /* left one */
      /* left two */
      /* left three */ }
      .tlc-how-work .desktop-img-banner .leftside-banner .leftside-btm-round {
        position: absolute;
        bottom: 115px;
        left: 21%;
        width: 105px; }
        @media only screen and (max-width: 1450px) {
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-btm-round {
            bottom: 130px;
            left: 19%; } }
        @media only screen and (max-width: 1400px) {
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-btm-round {
            bottom: 115px;
            left: 19%; } }
        @media only screen and (max-width: 1350px) {
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-btm-round {
            bottom: 110px;
            left: 19%; } }
        @media only screen and (max-width: 1300px) {
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-btm-round {
            bottom: 110px;
            left: 17%; } }
        @media only screen and (max-width: 1230px) {
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-btm-round {
            bottom: 100px;
            left: 16%; } }
        @media only screen and (max-width: 1150px) {
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-btm-round {
            bottom: 86px;
            left: 16%; } }
        @media only screen and (max-width: 1130px) {
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-btm-round {
            bottom: 97px;
            left: 16%; } }
        @media only screen and (max-width: 1024px) {
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-btm-round {
            bottom: 75px; } }
        @media only screen and (max-width: 1023px) {
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-btm-round {
            bottom: 84px;
            left: 13%; } }
        .tlc-how-work .desktop-img-banner .leftside-banner .leftside-btm-round .round-box {
          width: 97px;
          height: 97px;
          background: #ffffff;
          border-radius: 100%;
          box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
          display: table;
          padding: 0 5px; }
          @media only screen and (max-width: 1150px) {
            .tlc-how-work .desktop-img-banner .leftside-banner .leftside-btm-round .round-box {
              width: 90px;
              height: 90px; } }
          @media only screen and (max-width: 1130px) {
            .tlc-how-work .desktop-img-banner .leftside-banner .leftside-btm-round .round-box {
              width: 85px;
              height: 85px; } }
          @media only screen and (max-width: 1023px) {
            .tlc-how-work .desktop-img-banner .leftside-banner .leftside-btm-round .round-box {
              width: 80px;
              height: 80px; } }
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-btm-round .round-box .round-number {
            display: table-cell;
            vertical-align: middle;
            font-size: 19px; }
            @media only screen and (max-width: 1150px) {
              .tlc-how-work .desktop-img-banner .leftside-banner .leftside-btm-round .round-box .round-number {
                font-size: 17px; } }
            @media only screen and (max-width: 1130px) {
              .tlc-how-work .desktop-img-banner .leftside-banner .leftside-btm-round .round-box .round-number {
                font-size: 16px; } }
            @media only screen and (max-width: 1023px) {
              .tlc-how-work .desktop-img-banner .leftside-banner .leftside-btm-round .round-box .round-number {
                font-size: 15px; } }
        .tlc-how-work .desktop-img-banner .leftside-banner .leftside-btm-round .round-text {
          width: 100%;
          margin-bottom: 6px;
          line-height: 18px;
          font-size: 13px; }
      .tlc-how-work .desktop-img-banner .leftside-banner .leftside-mid-round {
        position: absolute;
        bottom: 160px;
        left: 65%;
        width: 105px; }
        @media only screen and (max-width: 1450px) {
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-mid-round {
            bottom: 174px;
            left: 64%; } }
        @media only screen and (max-width: 1400px) {
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-mid-round {
            bottom: 170px;
            left: 64%; } }
        @media only screen and (max-width: 1350px) {
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-mid-round {
            bottom: 160px;
            left: 64%; } }
        @media only screen and (max-width: 1300px) {
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-mid-round {
            bottom: 153px;
            left: 64%; } }
        @media only screen and (max-width: 1230px) {
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-mid-round {
            bottom: 138px;
            left: 64%; } }
        @media only screen and (max-width: 1150px) {
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-mid-round {
            bottom: 124px;
            left: 62%; } }
        @media only screen and (max-width: 1130px) {
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-mid-round {
            bottom: 131px;
            left: 59%; } }
        @media only screen and (max-width: 1024px) {
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-mid-round {
            bottom: 106px;
            left: 61%; } }
        @media only screen and (max-width: 1023px) {
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-mid-round {
            bottom: 117px;
            left: 57%; } }
        .tlc-how-work .desktop-img-banner .leftside-banner .leftside-mid-round .round-box {
          width: 97px;
          height: 97px;
          background: #ffffff;
          border-radius: 100%;
          box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
          display: table;
          padding: 0 5px; }
          @media only screen and (max-width: 1150px) {
            .tlc-how-work .desktop-img-banner .leftside-banner .leftside-mid-round .round-box {
              width: 90px;
              height: 90px; } }
          @media only screen and (max-width: 1130px) {
            .tlc-how-work .desktop-img-banner .leftside-banner .leftside-mid-round .round-box {
              width: 85px;
              height: 85px; } }
          @media only screen and (max-width: 1023px) {
            .tlc-how-work .desktop-img-banner .leftside-banner .leftside-mid-round .round-box {
              width: 80px;
              height: 80px; } }
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-mid-round .round-box .round-number {
            display: table-cell;
            vertical-align: middle;
            font-size: 19px; }
            @media only screen and (max-width: 1150px) {
              .tlc-how-work .desktop-img-banner .leftside-banner .leftside-mid-round .round-box .round-number {
                font-size: 17px; } }
            @media only screen and (max-width: 1130px) {
              .tlc-how-work .desktop-img-banner .leftside-banner .leftside-mid-round .round-box .round-number {
                font-size: 16px; } }
            @media only screen and (max-width: 1023px) {
              .tlc-how-work .desktop-img-banner .leftside-banner .leftside-mid-round .round-box .round-number {
                font-size: 15px; } }
        .tlc-how-work .desktop-img-banner .leftside-banner .leftside-mid-round .round-text {
          width: 100%;
          margin-bottom: 6px;
          font-size: 13px;
          line-height: 18px; }
          @media only screen and (max-width: 1100px) {
            .tlc-how-work .desktop-img-banner .leftside-banner .leftside-mid-round .round-text {
              line-height: 14px; } }
          @media only screen and (max-width: 1024px) {
            .tlc-how-work .desktop-img-banner .leftside-banner .leftside-mid-round .round-text {
              margin-bottom: 3px; } }
      .tlc-how-work .desktop-img-banner .leftside-banner .leftside-top-round {
        position: absolute;
        top: -20px;
        right: 6%;
        width: 130px;
        text-align: center; }
        @media only screen and (max-width: 1450px) {
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-top-round {
            top: -18px;
            right: 6%; } }
        @media only screen and (max-width: 1400px) {
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-top-round {
            top: -20px;
            right: 6%; } }
        @media only screen and (max-width: 1350px) {
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-top-round {
            top: -24px;
            right: 6%; } }
        @media only screen and (max-width: 1300px) {
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-top-round {
            top: -24px;
            right: 6%; } }
        @media only screen and (max-width: 1230px) {
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-top-round {
            top: -30px;
            right: 6%; } }
        @media only screen and (max-width: 1150px) {
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-top-round {
            top: -31px;
            right: 3%; } }
        @media only screen and (max-width: 1130px) {
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-top-round {
            top: -30px;
            right: 3%; } }
        @media only screen and (max-width: 1024px) {
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-top-round {
            top: -35px;
            right: 3%; } }
        @media only screen and (max-width: 1023px) {
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-top-round {
            top: -34px;
            right: 0; } }
        .tlc-how-work .desktop-img-banner .leftside-banner .leftside-top-round .round-box {
          width: 97px;
          height: 97px;
          background: #ffffff;
          border-radius: 100%;
          box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
          display: table;
          padding: 0 5px; }
          @media only screen and (max-width: 1150px) {
            .tlc-how-work .desktop-img-banner .leftside-banner .leftside-top-round .round-box {
              width: 90px;
              height: 90px; } }
          @media only screen and (max-width: 1130px) {
            .tlc-how-work .desktop-img-banner .leftside-banner .leftside-top-round .round-box {
              width: 85px;
              height: 85px; } }
          @media only screen and (max-width: 1023px) {
            .tlc-how-work .desktop-img-banner .leftside-banner .leftside-top-round .round-box {
              width: 80px;
              height: 80px; } }
          .tlc-how-work .desktop-img-banner .leftside-banner .leftside-top-round .round-box .round-number {
            display: table-cell;
            vertical-align: middle;
            font-size: 19px; }
            @media only screen and (max-width: 1150px) {
              .tlc-how-work .desktop-img-banner .leftside-banner .leftside-top-round .round-box .round-number {
                font-size: 17px; } }
            @media only screen and (max-width: 1130px) {
              .tlc-how-work .desktop-img-banner .leftside-banner .leftside-top-round .round-box .round-number {
                font-size: 16px; } }
            @media only screen and (max-width: 1023px) {
              .tlc-how-work .desktop-img-banner .leftside-banner .leftside-top-round .round-box .round-number {
                font-size: 15px; } }
        .tlc-how-work .desktop-img-banner .leftside-banner .leftside-top-round .round-text {
          width: 100%;
          margin-bottom: 6px;
          font-size: 13px;
          line-height: 18px; }
    .tlc-how-work .desktop-img-banner .midside-banner {
      width: 33.33%;
      position: absolute;
      left: 33.33%;
      top: 0;
      height: 100%;
      z-index: 10;
      /* mid one */
      /* mid two */ }
      .tlc-how-work .desktop-img-banner .midside-banner .midside-top-round {
        position: absolute;
        top: 7px;
        left: 11%;
        width: 105px; }
        @media only screen and (max-width: 1450px) {
          .tlc-how-work .desktop-img-banner .midside-banner .midside-top-round {
            top: 7px;
            left: 11%; } }
        @media only screen and (max-width: 1400px) {
          .tlc-how-work .desktop-img-banner .midside-banner .midside-top-round {
            top: 3px;
            left: 10%; } }
        @media only screen and (max-width: 1350px) {
          .tlc-how-work .desktop-img-banner .midside-banner .midside-top-round {
            top: -2px;
            left: 10%; } }
        @media only screen and (max-width: 1300px) {
          .tlc-how-work .desktop-img-banner .midside-banner .midside-top-round {
            top: -2px;
            left: 10%; } }
        @media only screen and (max-width: 1230px) {
          .tlc-how-work .desktop-img-banner .midside-banner .midside-top-round {
            top: -12px;
            left: 10%; } }
        @media only screen and (max-width: 1150px) {
          .tlc-how-work .desktop-img-banner .midside-banner .midside-top-round {
            top: -12px;
            left: 9%; } }
        @media only screen and (max-width: 1130px) {
          .tlc-how-work .desktop-img-banner .midside-banner .midside-top-round {
            top: -9px;
            left: 8%; } }
        @media only screen and (max-width: 1024px) {
          .tlc-how-work .desktop-img-banner .midside-banner .midside-top-round {
            top: -20px;
            left: 9%; } }
        @media only screen and (max-width: 1023px) {
          .tlc-how-work .desktop-img-banner .midside-banner .midside-top-round {
            top: -17px;
            left: 6%; } }
        .tlc-how-work .desktop-img-banner .midside-banner .midside-top-round .round-box {
          width: 97px;
          height: 97px;
          background: #ffffff;
          border-radius: 100%;
          box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
          display: table;
          padding: 0 5px; }
          @media only screen and (max-width: 1150px) {
            .tlc-how-work .desktop-img-banner .midside-banner .midside-top-round .round-box {
              width: 90px;
              height: 90px; } }
          @media only screen and (max-width: 1130px) {
            .tlc-how-work .desktop-img-banner .midside-banner .midside-top-round .round-box {
              width: 85px;
              height: 85px; } }
          @media only screen and (max-width: 1023px) {
            .tlc-how-work .desktop-img-banner .midside-banner .midside-top-round .round-box {
              width: 80px;
              height: 80px; } }
          .tlc-how-work .desktop-img-banner .midside-banner .midside-top-round .round-box .round-number {
            display: table-cell;
            vertical-align: middle;
            font-size: 19px; }
            @media only screen and (max-width: 1150px) {
              .tlc-how-work .desktop-img-banner .midside-banner .midside-top-round .round-box .round-number {
                font-size: 17px; } }
            @media only screen and (max-width: 1130px) {
              .tlc-how-work .desktop-img-banner .midside-banner .midside-top-round .round-box .round-number {
                font-size: 16px; } }
            @media only screen and (max-width: 1023px) {
              .tlc-how-work .desktop-img-banner .midside-banner .midside-top-round .round-box .round-number {
                font-size: 15px; } }
        .tlc-how-work .desktop-img-banner .midside-banner .midside-top-round .round-text {
          width: 100%;
          margin-bottom: 6px;
          font-size: 13px;
          line-height: 18px; }
      .tlc-how-work .desktop-img-banner .midside-banner .midside-mid-round {
        position: absolute;
        top: 65px;
        right: 9%;
        width: 102px; }
        @media only screen and (max-width: 1450px) {
          .tlc-how-work .desktop-img-banner .midside-banner .midside-mid-round {
            top: 67px;
            right: 8%; } }
        @media only screen and (max-width: 1400px) {
          .tlc-how-work .desktop-img-banner .midside-banner .midside-mid-round {
            top: 60px;
            right: 8%; } }
        @media only screen and (max-width: 1350px) {
          .tlc-how-work .desktop-img-banner .midside-banner .midside-mid-round {
            top: 54px;
            right: 7%; } }
        @media only screen and (max-width: 1300px) {
          .tlc-how-work .desktop-img-banner .midside-banner .midside-mid-round {
            top: 48px;
            right: 7%; } }
        @media only screen and (max-width: 1230px) {
          .tlc-how-work .desktop-img-banner .midside-banner .midside-mid-round {
            top: 38px;
            right: 7%; } }
        @media only screen and (max-width: 1150px) {
          .tlc-how-work .desktop-img-banner .midside-banner .midside-mid-round {
            top: 38px;
            right: 7%; } }
        @media only screen and (max-width: 1130px) {
          .tlc-how-work .desktop-img-banner .midside-banner .midside-mid-round {
            top: 37px;
            right: 5%; } }
        @media only screen and (max-width: 1024px) {
          .tlc-how-work .desktop-img-banner .midside-banner .midside-mid-round {
            top: 19px;
            right: 4%; } }
        @media only screen and (max-width: 1023px) {
          .tlc-how-work .desktop-img-banner .midside-banner .midside-mid-round {
            top: 24px;
            right: 3%; } }
        .tlc-how-work .desktop-img-banner .midside-banner .midside-mid-round .round-box {
          width: 97px;
          height: 97px;
          background: #ffffff;
          border-radius: 100%;
          box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
          display: table;
          padding: 0 5px; }
          @media only screen and (max-width: 1150px) {
            .tlc-how-work .desktop-img-banner .midside-banner .midside-mid-round .round-box {
              width: 90px;
              height: 90px; } }
          @media only screen and (max-width: 1130px) {
            .tlc-how-work .desktop-img-banner .midside-banner .midside-mid-round .round-box {
              width: 85px;
              height: 85px; } }
          @media only screen and (max-width: 1023px) {
            .tlc-how-work .desktop-img-banner .midside-banner .midside-mid-round .round-box {
              width: 80px;
              height: 80px; } }
          .tlc-how-work .desktop-img-banner .midside-banner .midside-mid-round .round-box .round-number {
            display: table-cell;
            vertical-align: middle;
            font-size: 19px; }
            @media only screen and (max-width: 1150px) {
              .tlc-how-work .desktop-img-banner .midside-banner .midside-mid-round .round-box .round-number {
                font-size: 17px; } }
            @media only screen and (max-width: 1130px) {
              .tlc-how-work .desktop-img-banner .midside-banner .midside-mid-round .round-box .round-number {
                font-size: 16px; } }
            @media only screen and (max-width: 1023px) {
              .tlc-how-work .desktop-img-banner .midside-banner .midside-mid-round .round-box .round-number {
                font-size: 15px; } }
        .tlc-how-work .desktop-img-banner .midside-banner .midside-mid-round .round-text {
          width: 100%;
          margin-bottom: 6px;
          font-size: 13px;
          line-height: 18px; }
    .tlc-how-work .desktop-img-banner .rightside-banner {
      width: 33.33%;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      z-index: 10;
      /* left two */
      /* left three */ }
      .tlc-how-work .desktop-img-banner .rightside-banner .rightside-btm-round {
        position: absolute;
        bottom: 82px;
        left: 25%;
        width: 105px; }
        @media only screen and (max-width: 1450px) {
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-btm-round {
            bottom: 94px;
            left: 25%; } }
        @media only screen and (max-width: 1400px) {
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-btm-round {
            bottom: 96px;
            left: 25%; } }
        @media only screen and (max-width: 1350px) {
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-btm-round {
            bottom: 88px;
            left: 25%; } }
        @media only screen and (max-width: 1300px) {
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-btm-round {
            bottom: 90px;
            left: 24%; } }
        @media only screen and (max-width: 1230px) {
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-btm-round {
            bottom: 82px;
            left: 25%; } }
        @media only screen and (max-width: 1150px) {
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-btm-round {
            bottom: 70px;
            left: 24%; } }
        @media only screen and (max-width: 1130px) {
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-btm-round {
            bottom: 77px;
            left: 23%; } }
        @media only screen and (max-width: 1024px) {
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-btm-round {
            bottom: 53px; } }
        @media only screen and (max-width: 1023px) {
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-btm-round {
            bottom: 67px;
            left: 22%; } }
        .tlc-how-work .desktop-img-banner .rightside-banner .rightside-btm-round .round-box {
          width: 97px;
          height: 97px;
          background: #ffffff;
          border-radius: 100%;
          box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
          display: table;
          padding: 0 5px; }
          @media only screen and (max-width: 1150px) {
            .tlc-how-work .desktop-img-banner .rightside-banner .rightside-btm-round .round-box {
              width: 90px;
              height: 90px; } }
          @media only screen and (max-width: 1130px) {
            .tlc-how-work .desktop-img-banner .rightside-banner .rightside-btm-round .round-box {
              width: 85px;
              height: 85px; } }
          @media only screen and (max-width: 1023px) {
            .tlc-how-work .desktop-img-banner .rightside-banner .rightside-btm-round .round-box {
              width: 80px;
              height: 80px; } }
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-btm-round .round-box .round-number {
            display: table-cell;
            vertical-align: middle;
            font-size: 19px; }
            @media only screen and (max-width: 1150px) {
              .tlc-how-work .desktop-img-banner .rightside-banner .rightside-btm-round .round-box .round-number {
                font-size: 17px; } }
            @media only screen and (max-width: 1130px) {
              .tlc-how-work .desktop-img-banner .rightside-banner .rightside-btm-round .round-box .round-number {
                font-size: 16px; } }
            @media only screen and (max-width: 1023px) {
              .tlc-how-work .desktop-img-banner .rightside-banner .rightside-btm-round .round-box .round-number {
                font-size: 15px; } }
        .tlc-how-work .desktop-img-banner .rightside-banner .rightside-btm-round .round-text {
          width: 100%;
          margin-bottom: 6px;
          font-size: 13px;
          line-height: 18px; }
      .tlc-how-work .desktop-img-banner .rightside-banner .rightside-mid-round {
        position: absolute;
        bottom: 189px;
        left: 62%;
        width: 115px; }
        @media only screen and (max-width: 1450px) {
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-mid-round {
            bottom: 203px;
            left: 63%; } }
        @media only screen and (max-width: 1400px) {
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-mid-round {
            bottom: 200px;
            left: 63%; } }
        @media only screen and (max-width: 1350px) {
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-mid-round {
            bottom: 187px;
            left: 63%; } }
        @media only screen and (max-width: 1300px) {
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-mid-round {
            bottom: 184px;
            left: 63%; } }
        @media only screen and (max-width: 1230px) {
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-mid-round {
            bottom: 170px;
            left: 63%; } }
        @media only screen and (max-width: 1150px) {
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-mid-round {
            bottom: 153px;
            left: 61%; } }
        @media only screen and (max-width: 1130px) {
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-mid-round {
            bottom: 156px;
            left: 61%; } }
        @media only screen and (max-width: 1024px) {
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-mid-round {
            bottom: 123px; } }
        @media only screen and (max-width: 1023px) {
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-mid-round {
            bottom: 138px;
            left: 60%; } }
        .tlc-how-work .desktop-img-banner .rightside-banner .rightside-mid-round .round-box {
          width: 97px;
          height: 97px;
          background: #ffffff;
          border-radius: 100%;
          box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
          display: table;
          padding: 0 5px; }
          @media only screen and (max-width: 1150px) {
            .tlc-how-work .desktop-img-banner .rightside-banner .rightside-mid-round .round-box {
              width: 90px;
              height: 90px; } }
          @media only screen and (max-width: 1130px) {
            .tlc-how-work .desktop-img-banner .rightside-banner .rightside-mid-round .round-box {
              width: 85px;
              height: 85px; } }
          @media only screen and (max-width: 1023px) {
            .tlc-how-work .desktop-img-banner .rightside-banner .rightside-mid-round .round-box {
              width: 80px;
              height: 80px; } }
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-mid-round .round-box .round-number {
            display: table-cell;
            vertical-align: middle;
            font-size: 19px; }
            @media only screen and (max-width: 1150px) {
              .tlc-how-work .desktop-img-banner .rightside-banner .rightside-mid-round .round-box .round-number {
                font-size: 17px; } }
            @media only screen and (max-width: 1130px) {
              .tlc-how-work .desktop-img-banner .rightside-banner .rightside-mid-round .round-box .round-number {
                font-size: 16px; } }
            @media only screen and (max-width: 1023px) {
              .tlc-how-work .desktop-img-banner .rightside-banner .rightside-mid-round .round-box .round-number {
                font-size: 15px; } }
        .tlc-how-work .desktop-img-banner .rightside-banner .rightside-mid-round .round-text {
          width: 100%;
          margin-bottom: 6px;
          font-size: 13px;
          line-height: 18px; }
      .tlc-how-work .desktop-img-banner .rightside-banner .rightside-top-round {
        position: absolute;
        top: -10px;
        left: 8%;
        width: 130px;
        text-align: center; }
        @media only screen and (max-width: 1450px) {
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-top-round {
            top: -7px;
            left: 8%; } }
        @media only screen and (max-width: 1400px) {
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-top-round {
            top: -11px;
            left: 8%; } }
        @media only screen and (max-width: 1350px) {
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-top-round {
            top: -14px;
            left: 8%; } }
        @media only screen and (max-width: 1300px) {
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-top-round {
            top: -18px;
            left: 7%; } }
        @media only screen and (max-width: 1230px) {
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-top-round {
            top: -23px;
            left: 7%; } }
        @media only screen and (max-width: 1150px) {
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-top-round {
            top: -31px;
            left: 5%; } }
        @media only screen and (max-width: 1130px) {
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-top-round {
            top: -20px;
            left: 4%; } }
        @media only screen and (max-width: 1024px) {
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-top-round {
            top: -33px; } }
        @media only screen and (max-width: 1023px) {
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-top-round {
            top: -26px;
            left: 2%; } }
        .tlc-how-work .desktop-img-banner .rightside-banner .rightside-top-round .round-box {
          width: 97px;
          height: 97px;
          background: #ffffff;
          border-radius: 100%;
          box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
          display: table;
          padding: 0 5px; }
          @media only screen and (max-width: 1150px) {
            .tlc-how-work .desktop-img-banner .rightside-banner .rightside-top-round .round-box {
              width: 90px;
              height: 90px; } }
          @media only screen and (max-width: 1130px) {
            .tlc-how-work .desktop-img-banner .rightside-banner .rightside-top-round .round-box {
              width: 85px;
              height: 85px; } }
          @media only screen and (max-width: 1023px) {
            .tlc-how-work .desktop-img-banner .rightside-banner .rightside-top-round .round-box {
              width: 80px;
              height: 80px; } }
          .tlc-how-work .desktop-img-banner .rightside-banner .rightside-top-round .round-box .round-number {
            display: table-cell;
            vertical-align: middle;
            font-size: 19px; }
            @media only screen and (max-width: 1150px) {
              .tlc-how-work .desktop-img-banner .rightside-banner .rightside-top-round .round-box .round-number {
                font-size: 17px; } }
            @media only screen and (max-width: 1130px) {
              .tlc-how-work .desktop-img-banner .rightside-banner .rightside-top-round .round-box .round-number {
                font-size: 16px; } }
            @media only screen and (max-width: 1023px) {
              .tlc-how-work .desktop-img-banner .rightside-banner .rightside-top-round .round-box .round-number {
                font-size: 15px; } }
        .tlc-how-work .desktop-img-banner .rightside-banner .rightside-top-round .round-text {
          width: 100%;
          margin-bottom: 6px;
          font-size: 13px;
          line-height: 18px; }
    @media only screen and (max-width: 767px) {
      .tlc-how-work .desktop-img-banner {
        display: none; } }
  .tlc-how-work .mb-img-banner {
    display: none; }
    @media only screen and (max-width: 991px) {
      .tlc-how-work .mb-img-banner {
        display: inline-block;
        position: relative;
        z-index: 9;
        /* &:after {
				position:absolute;
				content:'';
				right: calc(100% - 39.85%);
				top: 5px;
				background: linear-gradient(180deg, rgba(0,173,187,0) 0%, rgba(0,173,187,0.17) 100%);
				width:100%;
				height:100%;
				z-index: 1;
				
			} */
        /* &:before {
				position:absolute;
				content:'';
				left: calc(100% - 41%);
				top: 5px;
				background: linear-gradient(180deg, rgba(34,91,118,0) 0%, rgba(34,91,118,0.31) 100%);
				width:100%;
				height:100%;
				z-index: 1;
			} */ }
        .tlc-how-work .mb-img-banner img {
          position: relative;
          z-index: 9; }
        .tlc-how-work .mb-img-banner .mb-banner-left-gradient {
          position: absolute;
          left: calc(100% - 41%);
          top: 5px;
          background: linear-gradient(180deg, rgba(34, 91, 118, 0) 0%, rgba(34, 91, 118, 0.31) 100%);
          width: 100%;
          height: 100%;
          z-index: 1; }
        .tlc-how-work .mb-img-banner .mb-banner-right-gradient {
          position: absolute;
          right: calc(100% - 39.85%);
          top: 5px;
          background: linear-gradient(180deg, rgba(0, 173, 187, 0) 0%, rgba(0, 173, 187, 0.17) 100%);
          width: 100%;
          height: 100%;
          z-index: 1; } }
    @media only screen and (max-width: 736px) {
      .tlc-how-work .mb-img-banner {
        margin-top: 20px; } }
    @media only screen and (max-width: 580px) {
      .tlc-how-work .mb-img-banner {
        margin-top: 40px; } }
    .tlc-how-work .mb-img-banner .mb-tlc-price-amount {
      position: absolute;
      bottom: 144px;
      left: 33%;
      font-size: 39px;
      width: 326px;
      height: 77px;
      line-height: 74px;
      text-align: center;
      z-index: 9; }
      @media only screen and (max-width: 970px) {
        .tlc-how-work .mb-img-banner .mb-tlc-price-amount {
          bottom: 140px;
          width: 318px; } }
      @media only screen and (max-width: 950px) {
        .tlc-how-work .mb-img-banner .mb-tlc-price-amount {
          bottom: 136px;
          width: 315px; } }
      @media only screen and (max-width: 930px) {
        .tlc-how-work .mb-img-banner .mb-tlc-price-amount {
          bottom: 130px;
          width: 304px; } }
      @media only screen and (max-width: 900px) {
        .tlc-how-work .mb-img-banner .mb-tlc-price-amount {
          bottom: 129px;
          width: 298px; } }
      @media only screen and (max-width: 840px) {
        .tlc-how-work .mb-img-banner .mb-tlc-price-amount {
          bottom: 116px;
          width: 298px; } }
      @media only screen and (max-width: 800px) {
        .tlc-how-work .mb-img-banner .mb-tlc-price-amount {
          font-size: 28px;
          bottom: 115px;
          width: 263px;
          height: 63px;
          line-height: 68px; } }
      @media only screen and (max-width: 812px) {
        .tlc-how-work .mb-img-banner .mb-tlc-price-amount {
          font-size: 28px;
          bottom: 118px;
          height: 63px;
          line-height: 65px;
          width: 266px; } }
      @media only screen and (max-width: 770px) {
        .tlc-how-work .mb-img-banner .mb-tlc-price-amount {
          font-size: 27px;
          bottom: 111px;
          height: 59px;
          line-height: 60px;
          width: 253px; } }
      @media only screen and (max-width: 768px) {
        .tlc-how-work .mb-img-banner .mb-tlc-price-amount {
          bottom: 108px;
          width: 250px;
          font-size: 28px;
          height: 60px;
          line-height: 55px; } }
      @media only screen and (max-width: 736px) {
        .tlc-how-work .mb-img-banner .mb-tlc-price-amount {
          bottom: 104px;
          height: 58px;
          width: 243px; } }
      @media only screen and (max-width: 710px) {
        .tlc-how-work .mb-img-banner .mb-tlc-price-amount {
          bottom: 103px;
          height: 55px;
          width: 234px;
          font-size: 24px; } }
      @media only screen and (max-width: 680px) {
        .tlc-how-work .mb-img-banner .mb-tlc-price-amount {
          bottom: 101px;
          height: 50px;
          width: 223px;
          font-size: 24px; } }
      @media only screen and (max-width: 667px) {
        .tlc-how-work .mb-img-banner .mb-tlc-price-amount {
          line-height: 50px;
          width: 218px;
          bottom: 100px;
          height: 47px;
          font-size: 22px; } }
      @media only screen and (max-width: 640px) {
        .tlc-how-work .mb-img-banner .mb-tlc-price-amount {
          width: 210px;
          bottom: 95px; } }
      @media only screen and (max-width: 620px) {
        .tlc-how-work .mb-img-banner .mb-tlc-price-amount {
          width: 202px;
          font-size: 20px;
          height: 40px;
          line-height: 40px; } }
      @media only screen and (max-width: 588px) {
        .tlc-how-work .mb-img-banner .mb-tlc-price-amount {
          width: 195px;
          bottom: 86px;
          height: 46px;
          line-height: 45px; } }
      @media only screen and (max-width: 560px) {
        .tlc-how-work .mb-img-banner .mb-tlc-price-amount {
          width: 183px;
          bottom: 81px;
          height: 41px;
          line-height: 41px; } }
      @media only screen and (max-width: 520px) {
        .tlc-how-work .mb-img-banner .mb-tlc-price-amount {
          width: 171px;
          bottom: 75px; } }
      @media only screen and (max-width: 500px) {
        .tlc-how-work .mb-img-banner .mb-tlc-price-amount {
          width: 163px;
          bottom: 74px;
          height: 36px;
          font-size: 19px; } }
      @media only screen and (max-width: 460px) {
        .tlc-how-work .mb-img-banner .mb-tlc-price-amount {
          font-size: 17px;
          width: 150px;
          line-height: 35px;
          bottom: 68px;
          height: 35px; } }
      @media only screen and (max-width: 420px) {
        .tlc-how-work .mb-img-banner .mb-tlc-price-amount {
          line-height: 30px;
          width: 134px;
          bottom: 61px;
          height: 30px;
          font-size: 17px; } }
      @media only screen and (max-width: 380px) {
        .tlc-how-work .mb-img-banner .mb-tlc-price-amount {
          width: 121px;
          bottom: 54px;
          font-size: 17px; } }
      @media only screen and (max-width: 360px) {
        .tlc-how-work .mb-img-banner .mb-tlc-price-amount {
          width: 113px;
          bottom: 50px; } }
      @media only screen and (max-width: 332px) {
        .tlc-how-work .mb-img-banner .mb-tlc-price-amount {
          width: 107px;
          height: 27px; } }
      @media only screen and (max-width: 321px) {
        .tlc-how-work .mb-img-banner .mb-tlc-price-amount {
          line-height: 30px;
          width: 104px;
          bottom: 47px;
          height: 26px;
          font-size: 15px; } }
    .tlc-how-work .mb-img-banner .mb-tlc-mortgage-text {
      position: absolute;
      top: 210px;
      left: 22.5%;
      font-size: 24px;
      text-transform: uppercase;
      color: #b6c5cf;
      transform: rotate(450deg);
      z-index: 9; }
      @media only screen and (max-width: 900px) {
        .tlc-how-work .mb-img-banner .mb-tlc-mortgage-text {
          left: 21.9%; } }
      @media only screen and (max-width: 770px) {
        .tlc-how-work .mb-img-banner .mb-tlc-mortgage-text {
          left: 20.5%;
          font-size: 23px; } }
      @media only screen and (max-width: 768px) {
        .tlc-how-work .mb-img-banner .mb-tlc-mortgage-text {
          top: 190px;
          left: 20.5%; } }
      @media only screen and (max-width: 736px) {
        .tlc-how-work .mb-img-banner .mb-tlc-mortgage-text {
          top: 170px;
          left: 20.1%; } }
      @media only screen and (max-width: 680px) {
        .tlc-how-work .mb-img-banner .mb-tlc-mortgage-text {
          top: 158px;
          left: 19%; } }
      @media only screen and (max-width: 640px) {
        .tlc-how-work .mb-img-banner .mb-tlc-mortgage-text {
          top: 148px; } }
      @media only screen and (max-width: 620px) {
        .tlc-how-work .mb-img-banner .mb-tlc-mortgage-text {
          font-size: 22px; } }
      @media only screen and (max-width: 580px) {
        .tlc-how-work .mb-img-banner .mb-tlc-mortgage-text {
          font-size: 20px;
          top: 138px; } }
      @media only screen and (max-width: 520px) {
        .tlc-how-work .mb-img-banner .mb-tlc-mortgage-text {
          top: 90px; } }
      @media only screen and (max-width: 500px) {
        .tlc-how-work .mb-img-banner .mb-tlc-mortgage-text {
          top: 70px;
          left: 18%;
          font-size: 19px; } }
      @media only screen and (max-width: 480px) {
        .tlc-how-work .mb-img-banner .mb-tlc-mortgage-text {
          top: 60px; } }
      @media only screen and (max-width: 460px) {
        .tlc-how-work .mb-img-banner .mb-tlc-mortgage-text {
          font-size: 16px;
          top: 70px;
          left: 19%; } }
      @media only screen and (max-width: 414px) {
        .tlc-how-work .mb-img-banner .mb-tlc-mortgage-text {
          font-size: 16px;
          top: 53px;
          left: 18%; } }
      @media only screen and (max-width: 375px) {
        .tlc-how-work .mb-img-banner .mb-tlc-mortgage-text {
          top: 10px;
          left: 17%; } }
      @media only screen and (max-width: 350px) {
        .tlc-how-work .mb-img-banner .mb-tlc-mortgage-text {
          left: 16%; } }
      @media only screen and (max-width: 320px) {
        .tlc-how-work .mb-img-banner .mb-tlc-mortgage-text {
          font-size: 16px;
          top: 20px;
          left: 14%; } }
    .tlc-how-work .mb-img-banner .mb-tlc-mortgage-round {
      position: absolute;
      top: 4%;
      left: 24%;
      width: 110px;
      text-align: center;
      z-index: 9; }
      @media only screen and (max-width: 736px) {
        .tlc-how-work .mb-img-banner .mb-tlc-mortgage-round {
          top: 2%; } }
      @media only screen and (max-width: 680px) {
        .tlc-how-work .mb-img-banner .mb-tlc-mortgage-round {
          top: -1%;
          left: 22%; } }
      @media only screen and (max-width: 630px) {
        .tlc-how-work .mb-img-banner .mb-tlc-mortgage-round {
          left: 22%; } }
      @media only screen and (max-width: 580px) {
        .tlc-how-work .mb-img-banner .mb-tlc-mortgage-round {
          left: 20%;
          top: -3%; } }
      @media only screen and (max-width: 520px) {
        .tlc-how-work .mb-img-banner .mb-tlc-mortgage-round {
          top: inherit;
          bottom: 50%;
          width: auto;
          min-width: 85px;
          left: 17%; } }
      @media only screen and (max-width: 480px) {
        .tlc-how-work .mb-img-banner .mb-tlc-mortgage-round {
          left: 13%; } }
      @media only screen and (max-width: 460px) {
        .tlc-how-work .mb-img-banner .mb-tlc-mortgage-round {
          left: 12%; } }
      @media only screen and (max-width: 420px) {
        .tlc-how-work .mb-img-banner .mb-tlc-mortgage-round {
          width: auto;
          max-width: 85px;
          left: 9%; } }
      @media only screen and (max-width: 375px) {
        .tlc-how-work .mb-img-banner .mb-tlc-mortgage-round {
          left: 7%; } }
      @media only screen and (max-width: 330px) {
        .tlc-how-work .mb-img-banner .mb-tlc-mortgage-round {
          left: 5%; } }
      .tlc-how-work .mb-img-banner .mb-tlc-mortgage-round .mb-round-box {
        width: 85px;
        height: 85px;
        background: #ffffff;
        border-radius: 100%;
        box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
        display: table;
        padding: 0 5px; }
        @media only screen and (max-width: 520px) {
          .tlc-how-work .mb-img-banner .mb-tlc-mortgage-round .mb-round-box {
            border-radius: inherit;
            height: auto;
            padding: 5px 5px;
            min-width: 85px;
            width: auto; } }
        .tlc-how-work .mb-img-banner .mb-tlc-mortgage-round .mb-round-box .mb-round-number {
          display: table-cell;
          vertical-align: middle;
          font-size: 19px; }
          @media only screen and (max-width: 520px) {
            .tlc-how-work .mb-img-banner .mb-tlc-mortgage-round .mb-round-box .mb-round-number {
              font-size: 15px; } }
          @media only screen and (max-width: 320px) {
            .tlc-how-work .mb-img-banner .mb-tlc-mortgage-round .mb-round-box .mb-round-number {
              font-size: 14px; } }
    .tlc-how-work .mb-img-banner .mb-tlc-utilities-round {
      position: absolute;
      top: 4%;
      left: 43%;
      width: 110px;
      text-align: center;
      z-index: 9; }
      @media only screen and (max-width: 736px) {
        .tlc-how-work .mb-img-banner .mb-tlc-utilities-round {
          top: 2%; } }
      @media only screen and (max-width: 680px) {
        .tlc-how-work .mb-img-banner .mb-tlc-utilities-round {
          top: -1%; } }
      @media only screen and (max-width: 630px) {
        .tlc-how-work .mb-img-banner .mb-tlc-utilities-round {
          left: 41%; } }
      @media only screen and (max-width: 580px) {
        .tlc-how-work .mb-img-banner .mb-tlc-utilities-round {
          left: 40%;
          top: -3%; } }
      @media only screen and (max-width: 520px) {
        .tlc-how-work .mb-img-banner .mb-tlc-utilities-round {
          left: 42%;
          top: inherit;
          bottom: 50%;
          width: auto;
          min-width: 85px; } }
      @media only screen and (max-width: 480px) {
        .tlc-how-work .mb-img-banner .mb-tlc-utilities-round {
          left: 41%; } }
      @media only screen and (max-width: 375px) {
        .tlc-how-work .mb-img-banner .mb-tlc-utilities-round {
          left: 37%; } }
      @media only screen and (max-width: 320px) {
        .tlc-how-work .mb-img-banner .mb-tlc-utilities-round {
          left: 36%; } }
      .tlc-how-work .mb-img-banner .mb-tlc-utilities-round .mb-round-box {
        width: 85px;
        height: 85px;
        background: #ffffff;
        border-radius: 100%;
        box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
        display: table;
        padding: 0 5px; }
        @media only screen and (max-width: 520px) {
          .tlc-how-work .mb-img-banner .mb-tlc-utilities-round .mb-round-box {
            border-radius: inherit;
            height: auto;
            padding: 5px 5px;
            min-width: 85px;
            width: auto; } }
        .tlc-how-work .mb-img-banner .mb-tlc-utilities-round .mb-round-box .mb-round-number {
          display: table-cell;
          vertical-align: middle;
          font-size: 19px; }
          @media only screen and (max-width: 520px) {
            .tlc-how-work .mb-img-banner .mb-tlc-utilities-round .mb-round-box .mb-round-number {
              font-size: 15px; } }
          @media only screen and (max-width: 320px) {
            .tlc-how-work .mb-img-banner .mb-tlc-utilities-round .mb-round-box .mb-round-number {
              font-size: 14px; } }
    .tlc-how-work .mb-img-banner .mb-tlc-commute-round {
      position: absolute;
      top: 4%;
      right: 23%;
      width: 110px;
      text-align: center;
      z-index: 9; }
      @media only screen and (max-width: 736px) {
        .tlc-how-work .mb-img-banner .mb-tlc-commute-round {
          top: 2%; } }
      @media only screen and (max-width: 680px) {
        .tlc-how-work .mb-img-banner .mb-tlc-commute-round {
          top: -1%; } }
      @media only screen and (max-width: 630px) {
        .tlc-how-work .mb-img-banner .mb-tlc-commute-round {
          right: 20%; } }
      @media only screen and (max-width: 580px) {
        .tlc-how-work .mb-img-banner .mb-tlc-commute-round {
          top: -3%; } }
      @media only screen and (max-width: 520px) {
        .tlc-how-work .mb-img-banner .mb-tlc-commute-round {
          top: inherit;
          bottom: 50%;
          right: 17%;
          width: auto;
          min-width: 85px; } }
      @media only screen and (max-width: 480px) {
        .tlc-how-work .mb-img-banner .mb-tlc-commute-round {
          right: 12%; } }
      @media only screen and (max-width: 460px) {
        .tlc-how-work .mb-img-banner .mb-tlc-commute-round {
          right: 11%; } }
      @media only screen and (max-width: 375px) {
        .tlc-how-work .mb-img-banner .mb-tlc-commute-round {
          right: 8%; } }
      @media only screen and (max-width: 350px) {
        .tlc-how-work .mb-img-banner .mb-tlc-commute-round {
          right: 6%; } }
      @media only screen and (max-width: 320px) {
        .tlc-how-work .mb-img-banner .mb-tlc-commute-round {
          right: 4%; } }
      .tlc-how-work .mb-img-banner .mb-tlc-commute-round .mb-round-box {
        width: 85px;
        height: 85px;
        background: #ffffff;
        border-radius: 100%;
        box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
        display: table;
        padding: 0 5px; }
        @media only screen and (max-width: 520px) {
          .tlc-how-work .mb-img-banner .mb-tlc-commute-round .mb-round-box {
            border-radius: inherit;
            height: auto;
            padding: 5px 5px;
            min-width: 85px;
            width: auto; } }
        .tlc-how-work .mb-img-banner .mb-tlc-commute-round .mb-round-box .mb-round-number {
          display: table-cell;
          vertical-align: middle;
          font-size: 19px; }
          @media only screen and (max-width: 520px) {
            .tlc-how-work .mb-img-banner .mb-tlc-commute-round .mb-round-box .mb-round-number {
              font-size: 15px; } }
          @media only screen and (max-width: 320px) {
            .tlc-how-work .mb-img-banner .mb-tlc-commute-round .mb-round-box .mb-round-number {
              font-size: 14px; } }
    .tlc-how-work .mb-img-banner .mb-tlc-utilities-text {
      position: absolute;
      top: 195px;
      left: 42.5%;
      font-size: 24px;
      text-transform: uppercase;
      color: #b6c5cf;
      transform: rotate(450deg);
      z-index: 9; }
      @media only screen and (max-width: 900px) {
        .tlc-how-work .mb-img-banner .mb-tlc-utilities-text {
          left: 43.5%; } }
      @media only screen and (max-width: 770px) {
        .tlc-how-work .mb-img-banner .mb-tlc-utilities-text {
          left: 42.5%;
          font-size: 23px; } }
      @media only screen and (max-width: 768px) {
        .tlc-how-work .mb-img-banner .mb-tlc-utilities-text {
          top: 175px; } }
      @media only screen and (max-width: 736px) {
        .tlc-how-work .mb-img-banner .mb-tlc-utilities-text {
          top: 155px; } }
      @media only screen and (max-width: 680px) {
        .tlc-how-work .mb-img-banner .mb-tlc-utilities-text {
          top: 145px; } }
      @media only screen and (max-width: 640px) {
        .tlc-how-work .mb-img-banner .mb-tlc-utilities-text {
          top: 135px; } }
      @media only screen and (max-width: 620px) {
        .tlc-how-work .mb-img-banner .mb-tlc-utilities-text {
          font-size: 22px;
          left: 41%; } }
      @media only screen and (max-width: 580px) {
        .tlc-how-work .mb-img-banner .mb-tlc-utilities-text {
          font-size: 20px;
          top: 125px; } }
      @media only screen and (max-width: 520px) {
        .tlc-how-work .mb-img-banner .mb-tlc-utilities-text {
          top: 78px; } }
      @media only screen and (max-width: 500px) {
        .tlc-how-work .mb-img-banner .mb-tlc-utilities-text {
          top: 58px; } }
      @media only screen and (max-width: 480px) {
        .tlc-how-work .mb-img-banner .mb-tlc-utilities-text {
          top: 50px; } }
      @media only screen and (max-width: 460px) {
        .tlc-how-work .mb-img-banner .mb-tlc-utilities-text {
          left: 40%; } }
      @media only screen and (max-width: 500px) {
        .tlc-how-work .mb-img-banner .mb-tlc-utilities-text {
          font-size: 19px;
          /* top:95px; */ } }
      @media only screen and (max-width: 460px) {
        .tlc-how-work .mb-img-banner .mb-tlc-utilities-text {
          font-size: 16px;
          left: 41%;
          top: 62px; } }
      @media only screen and (max-width: 414px) {
        .tlc-how-work .mb-img-banner .mb-tlc-utilities-text {
          font-size: 16px;
          left: 41%;
          top: 45px; } }
      @media only screen and (max-width: 375px) {
        .tlc-how-work .mb-img-banner .mb-tlc-utilities-text {
          left: 40%;
          top: 3px; } }
      @media only screen and (max-width: 320px) {
        .tlc-how-work .mb-img-banner .mb-tlc-utilities-text {
          font-size: 16px;
          left: 38%;
          top: 12px; } }
    .tlc-how-work .mb-img-banner .mb-tlc-commute-text {
      position: absolute;
      top: 200px;
      right: 21.5%;
      font-size: 24px;
      text-transform: uppercase;
      color: #b6c5cf;
      transform: rotate(450deg);
      z-index: 9; }
      @media only screen and (max-width: 900px) {
        .tlc-how-work .mb-img-banner .mb-tlc-commute-text {
          right: 22.6%; } }
      @media only screen and (max-width: 770px) {
        .tlc-how-work .mb-img-banner .mb-tlc-commute-text {
          right: 22%;
          font-size: 23px; } }
      @media only screen and (max-width: 768px) {
        .tlc-how-work .mb-img-banner .mb-tlc-commute-text {
          top: 180px; } }
      @media only screen and (max-width: 736px) {
        .tlc-how-work .mb-img-banner .mb-tlc-commute-text {
          top: 160px; } }
      @media only screen and (max-width: 680px) {
        .tlc-how-work .mb-img-banner .mb-tlc-commute-text {
          top: 150px; } }
      @media only screen and (max-width: 640px) {
        .tlc-how-work .mb-img-banner .mb-tlc-commute-text {
          top: 140px;
          right: 20%; } }
      @media only screen and (max-width: 620px) {
        .tlc-how-work .mb-img-banner .mb-tlc-commute-text {
          font-size: 22px;
          right: 20%; } }
      @media only screen and (max-width: 580px) {
        .tlc-how-work .mb-img-banner .mb-tlc-commute-text {
          font-size: 20px;
          top: 130px; } }
      @media only screen and (max-width: 520px) {
        .tlc-how-work .mb-img-banner .mb-tlc-commute-text {
          top: 82px; } }
      @media only screen and (max-width: 500px) {
        .tlc-how-work .mb-img-banner .mb-tlc-commute-text {
          font-size: 19px;
          top: 62px; } }
      @media only screen and (max-width: 480px) {
        .tlc-how-work .mb-img-banner .mb-tlc-commute-text {
          top: 52px; } }
      @media only screen and (max-width: 460px) {
        .tlc-how-work .mb-img-banner .mb-tlc-commute-text {
          font-size: 16px;
          top: 65px;
          right: 20%; } }
      @media only screen and (max-width: 414px) {
        .tlc-how-work .mb-img-banner .mb-tlc-commute-text {
          font-size: 16px;
          top: 47px;
          right: 20%; } }
      @media only screen and (max-width: 375px) {
        .tlc-how-work .mb-img-banner .mb-tlc-commute-text {
          top: 5px;
          right: 16%; } }
      @media only screen and (max-width: 320px) {
        .tlc-how-work .mb-img-banner .mb-tlc-commute-text {
          font-size: 16px;
          right: 17%;
          top: 16px; } }

/**********************************************************************************************************New Style start**********************************************/
.tlc-how-works {
  background: linear-gradient(180deg, rgba(34, 91, 118, 0) 0%, rgba(34, 91, 118, 0.31) 100%);
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  /**********************************Remove start************************/
  /******************************Remove End*****************************/ }
  @media only screen and (max-width: 1450px) {
    .tlc-how-works {
      overflow: hidden; } }
  .tlc-how-works .work-text {
    float: left;
    width: 100%;
    margin-top: 40px; }
    @media only screen and (max-width: 767px) {
      .tlc-how-works .work-text {
        position: relative;
        top: inherit;
        left: inherit;
        padding-top: 50px; } }
    @media only screen and (max-width: 480px) {
      .tlc-how-works .work-text {
        padding-top: 20px;
        padding-bottom: 30px; } }
  .tlc-how-works .banner-left-gradient {
    display: none; }
  .tlc-how-works .banner-right-gradient {
    display: none; }
  .tlc-how-works .proptlchelpbanner {
    display: inline-block; }
  .tlc-how-works .desktop-img-banner {
    height: 100%;
    position: relative;
    width: 1450px;
    float: none;
    margin: 60px auto 0;
    display: inline-block;
    /********************************************************************* left***************************************************/
    /********************************************************************* left***************************************************/
    /*********************************************************************Mid***************************************************/
    /*********************************************************************Mid***************************************************/
    /*********************************************************************right***************************************************/
    /*********************************************************************right End***************************************************/
    /*********************************************************************tlc price amount start***************************************************/
    /*********************************************************************tlc price amount End***************************************************/
    /*********************************************************************start***************************************************/
    /*********************************************************************End***************************************************/
    /*********************************************************************start***************************************************/
    /*********************************************************************End***************************************************/
    /*********************************************************************start***************************************************/
    /*********************************************************************End***************************************************/
    /*********************************************************************End***************************************************/
    /*********************************************************************End***************************************************/ }
    @media only screen and (max-width: 1450px) {
      .tlc-how-works .desktop-img-banner {
        width: 100%; } }
    @media only screen and (max-width: 999px) {
      .tlc-how-works .desktop-img-banner {
        display: none; } }
    .tlc-how-works .desktop-img-banner .leftside-banner {
      width: 33.33%;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      z-index: 10; }
      .tlc-how-works .desktop-img-banner .leftside-banner .leftside-top-round {
        top: 160px;
        left: 40%;
        position: absolute;
        width: 130px;
        text-align: center; }
        @media only screen and (max-width: 1450px) {
          .tlc-how-works .desktop-img-banner .leftside-banner .leftside-top-round {
            top: 156px;
            left: 39%; } }
        @media only screen and (max-width: 1300px) {
          .tlc-how-works .desktop-img-banner .leftside-banner .leftside-top-round {
            left: 38%; } }
        @media only screen and (max-width: 1190px) {
          .tlc-how-works .desktop-img-banner .leftside-banner .leftside-top-round {
            left: 36%;
            top: 121px; } }
        @media only screen and (max-width: 1100px) {
          .tlc-how-works .desktop-img-banner .leftside-banner .leftside-top-round {
            top: 108px;
            left: 35%; } }
        @media only screen and (max-width: 1085px) {
          .tlc-how-works .desktop-img-banner .leftside-banner .leftside-top-round {
            left: 35%; } }
        @media only screen and (max-width: 1030px) {
          .tlc-how-works .desktop-img-banner .leftside-banner .leftside-top-round {
            top: 95px;
            left: 33%; } }
      .tlc-how-works .desktop-img-banner .leftside-banner .leftside-mid-round {
        bottom: 153px;
        left: 23%;
        position: absolute;
        width: 105px; }
        @media only screen and (max-width: 1450px) {
          .tlc-how-works .desktop-img-banner .leftside-banner .leftside-mid-round {
            bottom: 153px;
            left: 20%; } }
        @media only screen and (max-width: 1399px) {
          .tlc-how-works .desktop-img-banner .leftside-banner .leftside-mid-round {
            bottom: 147px; } }
        @media only screen and (max-width: 1380px) {
          .tlc-how-works .desktop-img-banner .leftside-banner .leftside-mid-round {
            bottom: 144px;
            left: 20%; } }
        @media only screen and (max-width: 1280px) {
          .tlc-how-works .desktop-img-banner .leftside-banner .leftside-mid-round {
            bottom: 135px; } }
        @media only screen and (max-width: 1100px) {
          .tlc-how-works .desktop-img-banner .leftside-banner .leftside-mid-round {
            bottom: 100px;
            left: 13%; } }
        @media only screen and (max-width: 1185px) {
          .tlc-how-works .desktop-img-banner .leftside-banner .leftside-mid-round {
            bottom: 120px; } }
        @media only screen and (max-width: 1170px) {
          .tlc-how-works .desktop-img-banner .leftside-banner .leftside-mid-round {
            left: 18%; } }
        @media only screen and (max-width: 1120px) {
          .tlc-how-works .desktop-img-banner .leftside-banner .leftside-mid-round {
            left: 17%;
            bottom: 112px; } }
        @media only screen and (max-width: 1030px) {
          .tlc-how-works .desktop-img-banner .leftside-banner .leftside-mid-round {
            bottom: 102px;
            left: 15%; } }
      .tlc-how-works .desktop-img-banner .leftside-banner .leftside-btm-round {
        bottom: 309px;
        left: 4%;
        position: absolute;
        width: 105px; }
        @media only screen and (max-width: 1380px) {
          .tlc-how-works .desktop-img-banner .leftside-banner .leftside-btm-round {
            bottom: 294px; } }
        @media only screen and (max-width: 1310px) {
          .tlc-how-works .desktop-img-banner .leftside-banner .leftside-btm-round {
            bottom: 284px; } }
        @media only screen and (max-width: 1280px) {
          .tlc-how-works .desktop-img-banner .leftside-banner .leftside-btm-round {
            bottom: 273px; } }
        @media only screen and (max-width: 1250px) {
          .tlc-how-works .desktop-img-banner .leftside-banner .leftside-btm-round {
            bottom: 270px; } }
        @media only screen and (max-width: 1200px) {
          .tlc-how-works .desktop-img-banner .leftside-banner .leftside-btm-round {
            bottom: 256px; } }
        @media only screen and (max-width: 1100px) {
          .tlc-how-works .desktop-img-banner .leftside-banner .leftside-btm-round {
            bottom: 236px; } }
        @media only screen and (max-width: 1130px) {
          .tlc-how-works .desktop-img-banner .leftside-banner .leftside-btm-round {
            bottom: 241px; } }
        @media only screen and (max-width: 1085px) {
          .tlc-how-works .desktop-img-banner .leftside-banner .leftside-btm-round {
            bottom: 228px; } }
        @media only screen and (max-width: 1030px) {
          .tlc-how-works .desktop-img-banner .leftside-banner .leftside-btm-round {
            bottom: 218px; } }
    .tlc-how-works .desktop-img-banner .midside-banner {
      width: 33.33%;
      position: absolute;
      left: 33.33%;
      top: 0;
      height: 100%;
      z-index: 10; }
      .tlc-how-works .desktop-img-banner .midside-banner .midside-top-round {
        display: none; }
      .tlc-how-works .desktop-img-banner .midside-banner .midside-mid-round {
        top: 84px;
        right: 16%;
        width: 230px;
        position: absolute; }
        @media only screen and (max-width: 1399px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-mid-round {
            top: 80px; } }
        @media only screen and (max-width: 1300px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-mid-round {
            top: 75px; } }
        @media only screen and (max-width: 1100px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-mid-round {
            top: 85px;
            right: 13%; } }
        @media only screen and (max-width: 1280px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-mid-round {
            top: 75px;
            right: 15%; } }
        @media only screen and (max-width: 1200px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-mid-round {
            top: 64px; } }
        @media only screen and (max-width: 1085px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-mid-round {
            top: 57px; } }
        @media only screen and (max-width: 1030px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-mid-round {
            top: 53px;
            right: 13%; } }
        .tlc-how-works .desktop-img-banner .midside-banner .midside-mid-round .round-text {
          width: 120px;
          display: inline-block;
          vertical-align: middle;
          margin-bottom: 0;
          padding-right: 10px; }
      .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
        width: 290px;
        height: 190px;
        position: absolute;
        top: 366px;
        left: 97px;
        border: 3px solid #fff;
        overflow: hidden;
        min-height: 190px;
        background: #ffffff; }
        .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo img {
          width: 100%;
          position: absolute;
          height: auto;
          margin: auto;
          top: -100%;
          right: -100%;
          bottom: -100%;
          left: -100%;
          min-height: 190px; }
        @media only screen and (max-width: 1450px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 385px); } }
        @media only screen and (max-width: 1439px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 384px); } }
        @media only screen and (max-width: 1426px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 382px); } }
        @media only screen and (max-width: 1415px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 380px);
            top: 359px; } }
        @media only screen and (max-width: 1400px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 378px); } }
        @media only screen and (max-width: 1390px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 377px);
            top: 355px; } }
        @media only screen and (max-width: 1389px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 375px);
            top: 355px; } }
        @media only screen and (max-width: 1380px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            top: 352px; } }
        @media only screen and (max-width: 1374px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            top: 350px;
            left: calc(100% - 374px); } }
        @media only screen and (max-width: 1370px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            top: 350px;
            left: calc(100% - 373px); } }
        @media only screen and (max-width: 1365px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            top: 347px;
            left: calc(100% - 372px); } }
        @media only screen and (max-width: 1360px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 362px);
            top: 346px;
            width: 270px;
            height: 170px;
            min-height: 170px; }
            .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo img {
              min-height: 170px; } }
        @media only screen and (max-width: 1355px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 360px);
            top: 343px; } }
        @media only screen and (max-width: 1340px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 358px);
            top: 340px; } }
        @media only screen and (max-width: 1320px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 355px);
            top: 336px; } }
        @media only screen and (max-width: 1300px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 352px);
            top: 332px; } }
        @media only screen and (max-width: 1290px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            width: 260px;
            height: 160px;
            min-height: 160px;
            left: calc(100% - 344px);
            top: 329px; }
            .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo img {
              min-height: 160px; } }
        @media only screen and (max-width: 1270px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 342px);
            top: 326px; } }
        @media only screen and (max-width: 1260px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 340px); } }
        @media only screen and (max-width: 1250px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 338px);
            top: 319px; } }
        @media only screen and (max-width: 1240px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            width: 240px;
            height: 140px;
            min-height: 140px;
            left: calc(100% - 327px);
            top: 316px; }
            .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo img {
              min-height: 140px; } }
        @media only screen and (max-width: 1220px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 324px);
            top: 308px; } }
        @media only screen and (max-width: 1200px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 320px);
            top: 305px; } }
        @media only screen and (max-width: 1190px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 318px); } }
        @media only screen and (max-width: 1180px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 317px);
            top: 300px; } }
        @media only screen and (max-width: 1160px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 312px);
            top: 292px; } }
        @media only screen and (max-width: 1140px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 310px);
            top: 290px; } }
        @media only screen and (max-width: 1130px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            width: 220px;
            height: 120px;
            min-height: 120px;
            top: 263px;
            left: calc(100% - 298px);
            top: 288px; }
            .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo img {
              min-height: 120px; } }
        @media only screen and (max-width: 1120px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 297px);
            top: 284px; } }
        @media only screen and (max-width: 1110px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 295px); } }
        @media only screen and (max-width: 1100px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 293px); } }
        @media only screen and (max-width: 1085px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 291px);
            top: 276px; } }
        @media only screen and (max-width: 1080px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 290px); } }
        @media only screen and (max-width: 1070px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 288px);
            top: 274px; } }
        @media only screen and (max-width: 1060px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 286px);
            top: 270px; } }
        @media only screen and (max-width: 1040px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 283px);
            top: 266px; } }
        @media only screen and (max-width: 1050px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 285px); } }
        @media only screen and (max-width: 1030px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            width: 200px;
            height: 100px;
            min-height: 100px;
            top: 263px;
            left: calc(100% - 271px);
            top: 263px; }
            .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo img {
              min-height: 100px; } }
        @media only screen and (max-width: 1025px) {
          .tlc-how-works .desktop-img-banner .midside-banner .midside-property-photo {
            left: calc(100% - 272px); } }
    .tlc-how-works .desktop-img-banner .rightside-banner {
      width: 33.33%;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      z-index: 10; }
      .tlc-how-works .desktop-img-banner .rightside-banner .rightside-top-round {
        top: 156px;
        left: 33%;
        position: absolute;
        width: 154px;
        text-align: center; }
        @media only screen and (max-width: 1450px) {
          .tlc-how-works .desktop-img-banner .rightside-banner .rightside-top-round {
            top: 156px;
            left: 33%; } }
        @media only screen and (max-width: 1190px) {
          .tlc-how-works .desktop-img-banner .rightside-banner .rightside-top-round {
            top: 121px;
            left: 31%; } }
        @media only screen and (max-width: 1080px) {
          .tlc-how-works .desktop-img-banner .rightside-banner .rightside-top-round {
            left: 29%; } }
        @media only screen and (max-width: 1030px) {
          .tlc-how-works .desktop-img-banner .rightside-banner .rightside-top-round {
            top: 95px;
            left: 29%; } }
      .tlc-how-works .desktop-img-banner .rightside-banner .rightside-mid-round {
        bottom: 314px;
        left: 73%;
        position: absolute;
        width: 115px; }
        @media only screen and (max-width: 1450px) {
          .tlc-how-works .desktop-img-banner .rightside-banner .rightside-mid-round {
            bottom: 307px; } }
        @media only screen and (max-width: 1380px) {
          .tlc-how-works .desktop-img-banner .rightside-banner .rightside-mid-round {
            bottom: 294px;
            left: 71%; } }
        @media only screen and (max-width: 1310px) {
          .tlc-how-works .desktop-img-banner .rightside-banner .rightside-mid-round {
            bottom: 284px;
            left: 70%; } }
        @media only screen and (max-width: 1280px) {
          .tlc-how-works .desktop-img-banner .rightside-banner .rightside-mid-round {
            bottom: 273px; } }
        @media only screen and (max-width: 1250px) {
          .tlc-how-works .desktop-img-banner .rightside-banner .rightside-mid-round {
            bottom: 270px; } }
        @media only screen and (max-width: 1200px) {
          .tlc-how-works .desktop-img-banner .rightside-banner .rightside-mid-round {
            bottom: 256px;
            left: 68%; } }
        @media only screen and (max-width: 1130px) {
          .tlc-how-works .desktop-img-banner .rightside-banner .rightside-mid-round {
            bottom: 241px;
            left: 66%; } }
        @media only screen and (max-width: 1085px) {
          .tlc-how-works .desktop-img-banner .rightside-banner .rightside-mid-round {
            bottom: 230px;
            left: 66%; } }
        @media only screen and (max-width: 1030px) {
          .tlc-how-works .desktop-img-banner .rightside-banner .rightside-mid-round {
            bottom: 220px;
            left: 64%; } }
      .tlc-how-works .desktop-img-banner .rightside-banner .rightside-btm-round {
        bottom: 155px;
        left: 59%;
        position: absolute;
        width: 105px; }
        @media only screen and (max-width: 1399px) {
          .tlc-how-works .desktop-img-banner .rightside-banner .rightside-btm-round {
            bottom: 147px; } }
        @media only screen and (max-width: 1380px) {
          .tlc-how-works .desktop-img-banner .rightside-banner .rightside-btm-round {
            bottom: 144px;
            left: 59%; } }
        @media only screen and (max-width: 1300px) {
          .tlc-how-works .desktop-img-banner .rightside-banner .rightside-btm-round {
            left: 57%; } }
        @media only screen and (max-width: 1280px) {
          .tlc-how-works .desktop-img-banner .rightside-banner .rightside-btm-round {
            bottom: 135px; } }
        @media only screen and (max-width: 1180px) {
          .tlc-how-works .desktop-img-banner .rightside-banner .rightside-btm-round {
            bottom: 120px; } }
        @media only screen and (max-width: 1120px) {
          .tlc-how-works .desktop-img-banner .rightside-banner .rightside-btm-round {
            bottom: 112px; } }
        @media only screen and (max-width: 1030px) {
          .tlc-how-works .desktop-img-banner .rightside-banner .rightside-btm-round {
            bottom: 102px;
            left: 56%; } }
    .tlc-how-works .desktop-img-banner .tlc-price-amount {
      position: absolute;
      background: #ffffff;
      width: 210px;
      height: 40px;
      left: 43%;
      bottom: 86px;
      line-height: 30px;
      font-size: 20px;
      padding: 3px 5px;
      z-index: 10; }
      @media only screen and (max-width: 1429px) {
        .tlc-how-works .desktop-img-banner .tlc-price-amount {
          bottom: 83px; } }
      @media only screen and (max-width: 1420px) {
        .tlc-how-works .desktop-img-banner .tlc-price-amount {
          bottom: 80px; } }
      @media only screen and (max-width: 1415px) {
        .tlc-how-works .desktop-img-banner .tlc-price-amount {
          bottom: 80px; } }
      @media only screen and (max-width: 1410px) {
        .tlc-how-works .desktop-img-banner .tlc-price-amount {
          bottom: 76px; } }
      @media only screen and (max-width: 1400px) {
        .tlc-how-works .desktop-img-banner .tlc-price-amount {
          bottom: 75px; } }
      @media only screen and (max-width: 1390px) {
        .tlc-how-works .desktop-img-banner .tlc-price-amount {
          bottom: 78px; } }
      @media only screen and (max-width: 1385px) {
        .tlc-how-works .desktop-img-banner .tlc-price-amount {
          bottom: 77px; } }
      @media only screen and (max-width: 1360px) {
        .tlc-how-works .desktop-img-banner .tlc-price-amount {
          bottom: 84px; } }
      @media only screen and (max-width: 1330px) {
        .tlc-how-works .desktop-img-banner .tlc-price-amount {
          bottom: 80px;
          width: 205px; } }
      @media only screen and (max-width: 1300px) {
        .tlc-how-works .desktop-img-banner .tlc-price-amount {
          bottom: 75px;
          width: 200px; } }
      @media only screen and (max-width: 1290px) {
        .tlc-how-works .desktop-img-banner .tlc-price-amount {
          font-size: 18px; } }
      @media only screen and (max-width: 1250px) {
        .tlc-how-works .desktop-img-banner .tlc-price-amount {
          bottom: 74px;
          width: 192px; } }
      @media only screen and (max-width: 1255px) {
        .tlc-how-works .desktop-img-banner .tlc-price-amount {
          bottom: 72px;
          width: 195px; } }
      @media only screen and (max-width: 1240px) {
        .tlc-how-works .desktop-img-banner .tlc-price-amount {
          bottom: 84px; } }
      @media only screen and (max-width: 1190px) {
        .tlc-how-works .desktop-img-banner .tlc-price-amount {
          bottom: 76px;
          width: 190px; } }
      @media only screen and (max-width: 1150px) {
        .tlc-how-works .desktop-img-banner .tlc-price-amount {
          bottom: 70px;
          width: 180px; } }
      @media only screen and (max-width: 1130px) {
        .tlc-how-works .desktop-img-banner .tlc-price-amount {
          bottom: 82px;
          width: 180px; } }
      @media only screen and (max-width: 1110px) {
        .tlc-how-works .desktop-img-banner .tlc-price-amount {
          bottom: 78px;
          width: 175px; } }
      @media only screen and (max-width: 1070px) {
        .tlc-how-works .desktop-img-banner .tlc-price-amount {
          bottom: 70px;
          width: 165px; } }
      @media only screen and (max-width: 1050px) {
        .tlc-how-works .desktop-img-banner .tlc-price-amount {
          bottom: 66px;
          font-size: 17px; } }
      @media only screen and (max-width: 1040px) {
        .tlc-how-works .desktop-img-banner .tlc-price-amount {
          bottom: 66px;
          font-size: 17px; } }
      @media only screen and (max-width: 1030px) {
        .tlc-how-works .desktop-img-banner .tlc-price-amount {
          width: 160px;
          bottom: 74px; } }
      @media only screen and (max-width: 1010px) {
        .tlc-how-works .desktop-img-banner .tlc-price-amount {
          bottom: 69px; } }
    .tlc-how-works .desktop-img-banner .tlchelp-banner {
      position: relative; }
      .tlc-how-works .desktop-img-banner .tlchelp-banner img {
        width: auto;
        position: relative;
        z-index: 9;
        display: inline-block;
        vertical-align: bottom; }
        @media only screen and (max-width: 1450px) {
          .tlc-how-works .desktop-img-banner .tlchelp-banner img {
            width: 100%; }
            .tlc-how-works .desktop-img-banner .tlchelp-banner img img {
              width: 100%; } }
    .tlc-how-works .desktop-img-banner .tlc-home-smallbox {
      width: 270px;
      height: 40px;
      background: #ffffff;
      position: absolute;
      text-align: left; }
      .tlc-how-works .desktop-img-banner .tlc-home-smallbox .sub-box-inner {
        display: table;
        vertical-align: middle;
        height: 30px;
        width: 100%; }
        .tlc-how-works .desktop-img-banner .tlc-home-smallbox .sub-box-inner .box-info {
          display: table-cell;
          vertical-align: middle;
          font-size: 13px;
          width: 100%; }
          @media only screen and (max-width: 1260px) {
            .tlc-how-works .desktop-img-banner .tlc-home-smallbox .sub-box-inner .box-info {
              font-size: 12px; } }
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox .sub-box-inner .box-info .info-highlight {
            display: inline-block; }
            .tlc-how-works .desktop-img-banner .tlc-home-smallbox .sub-box-inner .box-info .info-highlight.first-address-box {
              display: block;
              line-height: normal;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              height: 15px;
              width: 184px; }
              @media only screen and (max-width: 1180px) {
                .tlc-how-works .desktop-img-banner .tlc-home-smallbox .sub-box-inner .box-info .info-highlight.first-address-box {
                  width: 178px; } }
              @media only screen and (max-width: 1150px) {
                .tlc-how-works .desktop-img-banner .tlc-home-smallbox .sub-box-inner .box-info .info-highlight.first-address-box {
                  width: 170px; } }
              @media only screen and (max-width: 1100px) {
                .tlc-how-works .desktop-img-banner .tlc-home-smallbox .sub-box-inner .box-info .info-highlight.first-address-box {
                  width: 170px; } }
              @media only screen and (max-width: 1110px) {
                .tlc-how-works .desktop-img-banner .tlc-home-smallbox .sub-box-inner .box-info .info-highlight.first-address-box {
                  width: 164px; } }
              @media only screen and (max-width: 1090px) {
                .tlc-how-works .desktop-img-banner .tlc-home-smallbox .sub-box-inner .box-info .info-highlight.first-address-box {
                  width: 160px; } }
              @media only screen and (max-width: 1060px) {
                .tlc-how-works .desktop-img-banner .tlc-home-smallbox .sub-box-inner .box-info .info-highlight.first-address-box {
                  width: 154px; } }
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox .sub-box-inner .box-info .second-address-box {
            font-size: 12px;
            padding-top: 3px;
            display: block;
            line-height: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 15px;
            width: 182px; }
            @media only screen and (max-width: 1385px) {
              .tlc-how-works .desktop-img-banner .tlc-home-smallbox .sub-box-inner .box-info .second-address-box {
                width: 174px; } }
            @media only screen and (max-width: 1300px) {
              .tlc-how-works .desktop-img-banner .tlc-home-smallbox .sub-box-inner .box-info .second-address-box {
                width: 164px; } }
            @media only screen and (max-width: 1180px) {
              .tlc-how-works .desktop-img-banner .tlc-home-smallbox .sub-box-inner .box-info .second-address-box {
                width: 158px; } }
            @media only screen and (max-width: 1150px) {
              .tlc-how-works .desktop-img-banner .tlc-home-smallbox .sub-box-inner .box-info .second-address-box {
                width: 152px; } }
            @media only screen and (max-width: 1110px) {
              .tlc-how-works .desktop-img-banner .tlc-home-smallbox .sub-box-inner .box-info .second-address-box {
                width: 144px; } }
            @media only screen and (max-width: 1100px) {
              .tlc-how-works .desktop-img-banner .tlc-home-smallbox .sub-box-inner .box-info .second-address-box {
                width: 144px; } }
            @media only screen and (max-width: 1090px) {
              .tlc-how-works .desktop-img-banner .tlc-home-smallbox .sub-box-inner .box-info .second-address-box {
                width: 140px; } }
            @media only screen and (max-width: 1060px) {
              .tlc-how-works .desktop-img-banner .tlc-home-smallbox .sub-box-inner .box-info .second-address-box {
                width: 132px; } }
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox .sub-box-inner .box-info .second-info-box {
            display: inline-block;
            padding-left: 5px; }
            @media only screen and (max-width: 1180px) {
              .tlc-how-works .desktop-img-banner .tlc-home-smallbox .sub-box-inner .box-info .second-info-box {
                /* display:block;
							padding-left:0px; */ } }
      .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-address {
        top: 328px;
        left: 22%;
        padding: 5px 70px 5px 12px;
        text-align: right; }
        @media only screen and (max-width: 1450px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-address {
            padding: 5px 55px 5px 12px; } }
        @media only screen and (max-width: 1430px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-address {
            top: 325px; } }
        @media only screen and (max-width: 1410px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-address {
            top: 316px; } }
        @media only screen and (max-width: 1400px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-address {
            padding: 5px 70px 5px 12px; } }
        @media only screen and (max-width: 1385px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-address {
            top: 316px; } }
        @media only screen and (max-width: 1380px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-address {
            top: 314px; } }
        @media only screen and (max-width: 1375px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-address {
            top: 310px; } }
        @media only screen and (max-width: 1355px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-address {
            top: 305px; } }
        @media only screen and (max-width: 1330px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-address {
            top: 300px; } }
        @media only screen and (max-width: 1300px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-address {
            top: 295px;
            padding: 5px 70px 5px 8px; } }
        @media only screen and (max-width: 1280px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-address {
            top: 290px;
            left: 21%; } }
        @media only screen and (max-width: 1250px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-address {
            top: 283px; } }
        @media only screen and (max-width: 1220px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-address {
            top: 275px; } }
        @media only screen and (max-width: 1200px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-address {
            top: 270px; } }
        @media only screen and (max-width: 1180px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-address {
            top: 267px; } }
        @media only screen and (max-width: 1150px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-address {
            top: 260px; } }
        @media only screen and (max-width: 1130px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-address {
            top: 256px; } }
        @media only screen and (max-width: 1110px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-address {
            top: 253px;
            padding: 5px 110px 5px 8px; } }
        @media only screen and (max-width: 1100px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-address {
            top: 249px; } }
        @media only screen and (max-width: 1085px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-address {
            top: 245px; }
            .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-address .sub-box-inner .box-info .second-address-box {
              font-size: 11px; } }
        @media only screen and (max-width: 1060px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-address {
            top: 240px; } }
        @media only screen and (max-width: 1050px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-address {
            top: 236px; } }
        @media only screen and (max-width: 1030px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-address {
            top: 230px; } }
        @media only screen and (max-width: 1020px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-address {
            top: 225px; } }
      .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-price {
        top: 222px;
        left: 34%;
        padding: 5px 48px 5px 12px;
        width: 215px;
        text-align: right; }
        @media only screen and (max-width: 1450px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-price {
            padding: 5px 48px 5px 12px; } }
        @media only screen and (max-width: 1430px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-price {
            top: 217px; } }
        @media only screen and (max-width: 1410px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-price {
            top: 215px; } }
        @media only screen and (max-width: 1400px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-price {
            padding: 5px 48px 5px 12px; } }
        @media only screen and (max-width: 1389px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-price {
            top: 209px; } }
        @media only screen and (max-width: 1366px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-price {
            top: 203px; } }
        @media only screen and (max-width: 1350px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-price {
            top: 201px; } }
        @media only screen and (max-width: 1310px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-price {
            top: 198px;
            padding: 5px 54px 5px 12px; } }
        @media only screen and (max-width: 1300px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-price {
            top: 196px;
            padding: 5px 58px 5px 8px; } }
        @media only screen and (max-width: 1280px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-price {
            top: 190px;
            left: 33%; } }
        @media only screen and (max-width: 1240px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-price {
            top: 185px; } }
        @media only screen and (max-width: 1220px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-price {
            top: 179px;
            width: 205px;
            padding: 5px 48px 5px 8px; } }
        @media only screen and (max-width: 1180px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-price {
            top: 175px;
            width: 200px; } }
        @media only screen and (max-width: 1170px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-price {
            top: 173px; } }
        @media only screen and (max-width: 1150px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-price {
            top: 169px;
            width: 196px; } }
        @media only screen and (max-width: 1130px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-price {
            top: 166px;
            width: 190px; } }
        @media only screen and (max-width: 1100px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-price {
            top: 161px;
            left: 33%; } }
        @media only screen and (max-width: 1070px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-price {
            top: 159px;
            right: 33%;
            padding: 5px 54px 5px 8px; } }
        @media only screen and (max-width: 1060px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-price {
            top: 158px;
            width: 180px; } }
        @media only screen and (max-width: 1050px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-price {
            top: 154px; } }
        @media only screen and (max-width: 1030px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-price {
            top: 153px;
            width: 179px; } }
        @media only screen and (max-width: 1020px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-price {
            top: 149px; } }
        @media only screen and (max-width: 1000px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-price {
            top: 146px;
            width: 169px;
            padding: 5px 46px 5px 8px; } }
      .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-mls {
        top: 222px;
        right: 34%;
        padding: 5px 12px 5px 48px;
        text-align: left;
        width: 215px; }
        @media only screen and (max-width: 1450px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-mls {
            padding: 5px 12px 5px 48px; } }
        @media only screen and (max-width: 1430px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-mls {
            top: 217px; } }
        @media only screen and (max-width: 1410px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-mls {
            top: 215px; } }
        @media only screen and (max-width: 1400px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-mls {
            padding: 5px 12px 5px 52px; } }
        @media only screen and (max-width: 1389px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-mls {
            top: 209px; } }
        @media only screen and (max-width: 1366px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-mls {
            top: 203px; } }
        @media only screen and (max-width: 1350px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-mls {
            top: 201px; } }
        @media only screen and (max-width: 1310px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-mls {
            top: 198px;
            padding: 5px 8px 5px 56px; } }
        @media only screen and (max-width: 1300px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-mls {
            top: 196px;
            padding: 5px 8px 5px 62px; } }
        @media only screen and (max-width: 1280px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-mls {
            top: 190px;
            right: 33%;
            padding: 5px 8px 5px 52px; } }
        @media only screen and (max-width: 1240px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-mls {
            top: 185px;
            padding: 5px 8px 5px 58px; } }
        @media only screen and (max-width: 1220px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-mls {
            top: 179px;
            width: 205px;
            padding: 5px 8px 5px 50px; } }
        @media only screen and (max-width: 1180px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-mls {
            top: 175px;
            width: 200px; } }
        @media only screen and (max-width: 1170px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-mls {
            top: 173px; } }
        @media only screen and (max-width: 1150px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-mls {
            top: 169px;
            width: 196px; } }
        @media only screen and (max-width: 1130px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-mls {
            top: 166px;
            width: 190px; } }
        @media only screen and (max-width: 1100px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-mls {
            top: 161px;
            right: 33%; } }
        @media only screen and (max-width: 1070px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-mls {
            top: 159px;
            right: 33%;
            padding: 5px 8px 5px 54px; } }
        @media only screen and (max-width: 1060px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-mls {
            top: 158px;
            width: 180px; } }
        @media only screen and (max-width: 1050px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-mls {
            top: 154px; } }
        @media only screen and (max-width: 1040px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-mls {
            top: 152px;
            width: 180px; } }
        @media only screen and (max-width: 1030px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-mls {
            top: 153px;
            width: 179px; } }
        @media only screen and (max-width: 1020px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-mls {
            top: 149px; } }
        @media only screen and (max-width: 1000px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-mls {
            top: 146px;
            width: 169px;
            padding: 5px 8px 5px 48px; } }
        .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-mls .sub-box-inner .box-info .second-info-box {
          padding-left: 5px; }
      .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed {
        top: 328px;
        right: 22%;
        padding: 5px 12px 5px 62px;
        text-align: left; }
        .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed .sub-box-inner .box-info .second-info-box {
          padding-left: 5px; }
          @media only screen and (max-width: 1400px) {
            .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed .sub-box-inner .box-info .second-info-box {
              padding-left: 3px; } }
          @media only screen and (max-width: 1110px) {
            .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed .sub-box-inner .box-info .second-info-box {
              padding-left: 5px; } }
        @media only screen and (max-width: 1450px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed {
            padding: 5px 12px 5px 62px; } }
        @media only screen and (max-width: 1430px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed {
            top: 325px; } }
        @media only screen and (max-width: 1410px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed {
            top: 316px; } }
        @media only screen and (max-width: 1400px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed {
            padding: 5px 12px 5px 64px; } }
        @media only screen and (max-width: 1385px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed {
            top: 316px;
            padding: 5px 12px 5px 74px; } }
        @media only screen and (max-width: 1380px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed {
            top: 314px; } }
        @media only screen and (max-width: 1375px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed {
            top: 310px; } }
        @media only screen and (max-width: 1355px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed {
            top: 305px; } }
        @media only screen and (max-width: 1330px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed {
            top: 300px; } }
        @media only screen and (max-width: 1300px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed {
            top: 295px;
            padding: 5px 8px 5px 84px; } }
        @media only screen and (max-width: 1280px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed {
            top: 290px;
            right: 21%;
            padding: 5px 8px 5px 80px; } }
        @media only screen and (max-width: 1260px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed {
            padding: 5px 8px 5px 82px; } }
        @media only screen and (max-width: 1250px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed {
            top: 283px; } }
        @media only screen and (max-width: 1220px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed {
            top: 275px; } }
        @media only screen and (max-width: 1200px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed {
            top: 270px;
            padding: 5px 8px 5px 86px; } }
        @media only screen and (max-width: 1180px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed {
            top: 267px;
            padding: 5px 8px 5px 88px; } }
        @media only screen and (max-width: 1150px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed {
            top: 260px;
            padding: 5px 8px 5px 96px; } }
        @media only screen and (max-width: 1130px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed {
            top: 256px;
            padding: 5px 8px 5px 108px; } }
        @media only screen and (max-width: 1110px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed {
            top: 253px;
            padding: 5px 8px 5px 112px; } }
        @media only screen and (max-width: 1100px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed {
            top: 249px; } }
        @media only screen and (max-width: 1085px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed {
            top: 245px; } }
        @media only screen and (max-width: 1060px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed {
            top: 240px;
            padding: 5px 8px 5px 118px; } }
        @media only screen and (max-width: 1050px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed {
            top: 236px; } }
        @media only screen and (max-width: 1030px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed {
            top: 230px;
            padding: 5px 8px 5px 122px; } }
        @media only screen and (max-width: 1020px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed {
            top: 228px; } }
        @media only screen and (max-width: 1000px) {
          .tlc-how-works .desktop-img-banner .tlc-home-smallbox.property-full-bed {
            top: 227px;
            padding: 5px 8px 5px 128px; } }
    .tlc-how-works .desktop-img-banner .tlc-mortgage-text {
      top: 20px;
      position: absolute;
      left: 12%;
      font-size: 24px;
      text-transform: uppercase;
      color: #b6c5cf;
      z-index: 10; }
      @media only screen and (max-width: 1200px) {
        .tlc-how-works .desktop-img-banner .tlc-mortgage-text {
          top: 15px; } }
      @media only screen and (max-width: 1190px) {
        .tlc-how-works .desktop-img-banner .tlc-mortgage-text {
          font-size: 21px; } }
      @media only screen and (max-width: 1100px) {
        .tlc-how-works .desktop-img-banner .tlc-mortgage-text {
          top: 10px; } }
      @media only screen and (max-width: 1025px) {
        .tlc-how-works .desktop-img-banner .tlc-mortgage-text {
          left: 9%; } }
    .tlc-how-works .desktop-img-banner .tlc-utilities-text {
      top: 20px;
      position: absolute;
      left: 46%;
      font-size: 24px;
      text-transform: uppercase;
      color: #b6c5cf;
      z-index: 10; }
      @media only screen and (max-width: 1200px) {
        .tlc-how-works .desktop-img-banner .tlc-utilities-text {
          top: 15px; } }
      @media only screen and (max-width: 1190px) {
        .tlc-how-works .desktop-img-banner .tlc-utilities-text {
          font-size: 21px; } }
      @media only screen and (max-width: 1100px) {
        .tlc-how-works .desktop-img-banner .tlc-utilities-text {
          top: 10px; } }
      @media only screen and (max-width: 1025px) {
        .tlc-how-works .desktop-img-banner .tlc-utilities-text {
          font-size: 21px; } }
    .tlc-how-works .desktop-img-banner .tlc-commute-text {
      top: 20px;
      position: absolute;
      right: 13%;
      font-size: 24px;
      text-transform: uppercase;
      color: #b6c5cf;
      z-index: 10; }
      @media only screen and (max-width: 1200px) {
        .tlc-how-works .desktop-img-banner .tlc-commute-text {
          top: 15px; } }
      @media only screen and (max-width: 1190px) {
        .tlc-how-works .desktop-img-banner .tlc-commute-text {
          font-size: 21px; } }
      @media only screen and (max-width: 1100px) {
        .tlc-how-works .desktop-img-banner .tlc-commute-text {
          top: 10px; } }
      @media only screen and (max-width: 1025px) {
        .tlc-how-works .desktop-img-banner .tlc-commute-text {
          right: 11%;
          font-size: 21px; } }
    .tlc-how-works .desktop-img-banner .round-text {
      width: 100%;
      margin-bottom: 6px;
      font-size: 13px;
      line-height: 18px;
      word-break: break-word; }
      @media only screen and (max-width: 1130px) {
        .tlc-how-works .desktop-img-banner .round-text {
          font-size: 11px; } }
    .tlc-how-works .desktop-img-banner .round-box {
      width: 97px;
      height: 97px;
      background: #ffffff;
      border-radius: 100%;
      box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
      display: table;
      padding: 0 5px; }
      @media only screen and (max-width: 1389px) {
        .tlc-how-works .desktop-img-banner .round-box {
          width: 90px;
          height: 90px; } }
      @media only screen and (max-width: 1350px) {
        .tlc-how-works .desktop-img-banner .round-box {
          width: 85px;
          height: 85px; } }
      @media only screen and (max-width: 1100px) {
        .tlc-how-works .desktop-img-banner .round-box {
          width: 80px;
          height: 80px; } }
      .tlc-how-works .desktop-img-banner .round-box .round-number {
        display: table-cell;
        vertical-align: middle;
        font-size: 19px; }
        @media only screen and (max-width: 1389px) {
          .tlc-how-works .desktop-img-banner .round-box .round-number {
            font-size: 17px; } }
        @media only screen and (max-width: 1350px) {
          .tlc-how-works .desktop-img-banner .round-box .round-number {
            font-size: 16px; } }
        @media only screen and (max-width: 1100px) {
          .tlc-how-works .desktop-img-banner .round-box .round-number {
            font-size: 15px; } }
  .tlc-how-works .mb-img-tlcbanner {
    display: none; }
    @media only screen and (max-width: 999px) {
      .tlc-how-works .mb-img-tlcbanner {
        display: inline-block;
        position: relative;
        z-index: 9;
        padding-top: 120px; }
        .tlc-how-works .mb-img-tlcbanner img {
          position: relative;
          z-index: 9; } }
    @media only screen and (max-width: 800px) {
      .tlc-how-works .mb-img-tlcbanner {
        padding-top: 180px; } }
    @media only screen and (max-width: 736px) {
      .tlc-how-works .mb-img-tlcbanner {
        margin-top: 20px; } }
    @media only screen and (max-width: 640px) {
      .tlc-how-works .mb-img-tlcbanner {
        padding-top: 200px; } }
    @media only screen and (max-width: 610px) {
      .tlc-how-works .mb-img-tlcbanner {
        padding-top: 210px; } }
    @media only screen and (max-width: 580px) {
      .tlc-how-works .mb-img-tlcbanner {
        margin-top: 80px; } }
    @media only screen and (max-width: 530px) {
      .tlc-how-works .mb-img-tlcbanner {
        padding-top: 210px; } }
    .tlc-how-works .mb-img-tlcbanner .mb-tlc-price-amount {
      position: absolute;
      bottom: 144px;
      left: 33%;
      font-size: 20px;
      width: 200px;
      height: 40px;
      line-height: 30px;
      text-align: center;
      z-index: 9;
      background: #ffffff;
      padding: 3px 5px; }
      @media only screen and (max-width: 970px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-price-amount {
          bottom: 140px;
          width: 318px; } }
      @media only screen and (max-width: 950px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-price-amount {
          bottom: 136px;
          width: 315px; } }
      @media only screen and (max-width: 930px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-price-amount {
          bottom: 130px;
          width: 304px; } }
      @media only screen and (max-width: 900px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-price-amount {
          bottom: 129px;
          width: 298px; } }
      @media only screen and (max-width: 840px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-price-amount {
          bottom: 116px;
          width: 298px; } }
      @media only screen and (max-width: 800px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-price-amount {
          font-size: 28px;
          bottom: 115px;
          width: 263px;
          height: 63px;
          line-height: 68px; } }
      @media only screen and (max-width: 812px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-price-amount {
          font-size: 28px;
          bottom: 118px;
          height: 63px;
          line-height: 65px;
          width: 266px; } }
      @media only screen and (max-width: 770px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-price-amount {
          font-size: 27px;
          bottom: 111px;
          height: 59px;
          line-height: 60px;
          width: 253px; } }
      @media only screen and (max-width: 768px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-price-amount {
          bottom: 108px;
          width: 250px;
          font-size: 28px;
          height: 60px;
          line-height: 55px; } }
      @media only screen and (max-width: 736px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-price-amount {
          bottom: 104px;
          height: 58px;
          width: 243px; } }
      @media only screen and (max-width: 710px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-price-amount {
          bottom: 103px;
          height: 55px;
          width: 234px;
          font-size: 24px; } }
      @media only screen and (max-width: 680px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-price-amount {
          bottom: 101px;
          height: 50px;
          width: 223px;
          font-size: 24px; } }
      @media only screen and (max-width: 667px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-price-amount {
          line-height: 50px;
          width: 218px;
          bottom: 100px;
          height: 47px;
          font-size: 22px; } }
      @media only screen and (max-width: 640px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-price-amount {
          width: 210px;
          bottom: 95px; } }
      @media only screen and (max-width: 620px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-price-amount {
          width: 202px;
          font-size: 20px;
          height: 40px;
          line-height: 40px; } }
      @media only screen and (max-width: 588px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-price-amount {
          width: 195px;
          bottom: 86px;
          height: 46px;
          line-height: 45px; } }
      @media only screen and (max-width: 560px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-price-amount {
          width: 183px;
          bottom: 81px;
          height: 41px;
          line-height: 41px; } }
      @media only screen and (max-width: 520px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-price-amount {
          width: 171px;
          bottom: 75px; } }
      @media only screen and (max-width: 500px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-price-amount {
          width: 163px;
          bottom: 74px;
          height: 36px;
          font-size: 19px; } }
      @media only screen and (max-width: 460px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-price-amount {
          font-size: 17px;
          width: 150px;
          line-height: 35px;
          bottom: 68px;
          height: 35px; } }
      @media only screen and (max-width: 420px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-price-amount {
          line-height: 30px;
          width: 134px;
          bottom: 61px;
          height: 30px;
          font-size: 17px; } }
      @media only screen and (max-width: 380px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-price-amount {
          width: 121px;
          bottom: 54px;
          font-size: 17px; } }
      @media only screen and (max-width: 360px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-price-amount {
          width: 113px;
          bottom: 50px; } }
      @media only screen and (max-width: 332px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-price-amount {
          width: 107px;
          height: 27px; } }
      @media only screen and (max-width: 321px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-price-amount {
          line-height: 30px;
          width: 104px;
          bottom: 47px;
          height: 26px;
          font-size: 15px; } }
    .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-text {
      position: absolute;
      top: 210px;
      left: 20.5%;
      font-size: 24px;
      text-transform: uppercase;
      color: #b6c5cf;
      transform: rotate(450deg);
      z-index: 9; }
      @media only screen and (max-width: 930px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-text {
          left: 19.5%; } }
      @media only screen and (max-width: 820px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-text {
          left: 18.5%; } }
      @media only screen and (max-width: 770px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-text {
          font-size: 23px; } }
      @media only screen and (max-width: 768px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-text {
          top: 190px; } }
      @media only screen and (max-width: 736px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-text {
          top: 170px; } }
      @media only screen and (max-width: 730px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-text {
          left: 17.5%; } }
      @media only screen and (max-width: 680px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-text {
          top: 158px;
          left: 16.9%; } }
      @media only screen and (max-width: 640px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-text {
          top: 148px; } }
      @media only screen and (max-width: 620px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-text {
          font-size: 22px; } }
      @media only screen and (max-width: 590px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-text {
          left: 16%; } }
      @media only screen and (max-width: 580px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-text {
          font-size: 20px;
          top: 138px;
          left: 16.5%; } }
      @media only screen and (max-width: 530px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-text {
          left: 16%; } }
      @media only screen and (max-width: 520px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-text {
          top: 0px; } }
      @media only screen and (max-width: 500px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-text {
          left: 16%;
          font-size: 19px; } }
      @media only screen and (max-width: 460px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-text {
          font-size: 16px;
          left: 16%;
          top: -3px; } }
      @media only screen and (max-width: 414px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-text {
          font-size: 16px;
          /* top:53px; */
          left: 16%; } }
      @media only screen and (max-width: 390px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-text {
          left: 15%; } }
      @media only screen and (max-width: 375px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-text {
          top: -35px;
          left: 15%; } }
      @media only screen and (max-width: 360px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-text {
          left: 15%; } }
      @media only screen and (max-width: 350px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-text {
          left: 13%; } }
      @media only screen and (max-width: 320px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-text {
          font-size: 16px;
          left: 13%; } }
    .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-round {
      position: absolute;
      top: 4%;
      left: 22%;
      width: 110px;
      text-align: center;
      z-index: 9; }
      @media only screen and (max-width: 900px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-round {
          left: 21%; } }
      @media only screen and (max-width: 736px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-round {
          top: 2%; } }
      @media only screen and (max-width: 730px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-round {
          left: 20%; } }
      @media only screen and (max-width: 680px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-round {
          top: -1%;
          left: 19%; } }
      @media only screen and (max-width: 630px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-round {
          left: 19%; } }
      @media only screen and (max-width: 580px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-round {
          left: 18%;
          top: -3%; } }
      @media only screen and (max-width: 530px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-round {
          left: 17%; } }
      @media only screen and (max-width: 520px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-round {
          top: inherit;
          bottom: 80%;
          width: auto;
          min-width: 85px;
          left: 17%; } }
      @media only screen and (max-width: 480px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-round {
          left: 13%; } }
      @media only screen and (max-width: 460px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-round {
          left: 12%; } }
      @media only screen and (max-width: 420px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-round {
          width: auto;
          max-width: 85px;
          left: 9%; } }
      @media only screen and (max-width: 375px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-round {
          left: 7%;
          /* bottom:32%; */ } }
      @media only screen and (max-width: 330px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-round {
          left: 5%; } }
      .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-round .mb-round-box {
        width: 85px;
        height: 85px;
        background: #ffffff;
        border-radius: 100%;
        box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
        display: table;
        padding: 0 5px; }
        @media only screen and (max-width: 520px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-round .mb-round-box {
            border-radius: inherit;
            height: auto;
            padding: 5px 5px;
            min-width: 85px;
            width: auto; } }
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-round .mb-round-box .mb-round-number {
          display: table-cell;
          vertical-align: middle;
          font-size: 17px; }
          @media only screen and (max-width: 520px) {
            .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-round .mb-round-box .mb-round-number {
              font-size: 15px; } }
          @media only screen and (max-width: 320px) {
            .tlc-how-works .mb-img-tlcbanner .mb-tlc-mortgage-round .mb-round-box .mb-round-number {
              font-size: 14px; } }
    .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-round {
      position: absolute;
      top: 4%;
      left: 44%;
      width: 110px;
      text-align: center;
      z-index: 9; }
      @media only screen and (max-width: 800px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-round {
          left: 43%; } }
      @media only screen and (max-width: 736px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-round {
          top: 2%; } }
      @media only screen and (max-width: 680px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-round {
          top: -1%; } }
      @media only screen and (max-width: 630px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-round {
          left: 41%; } }
      @media only screen and (max-width: 580px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-round {
          left: 40%;
          top: -3%; } }
      @media only screen and (max-width: 520px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-round {
          left: 42%;
          top: inherit;
          bottom: 80%;
          width: auto;
          min-width: 85px; } }
      @media only screen and (max-width: 480px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-round {
          left: 41%; } }
      @media only screen and (max-width: 375px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-round {
          left: 37%;
          /* bottom:32%; */ } }
      @media only screen and (max-width: 320px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-round {
          left: 36%; } }
      .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-round .mb-round-box {
        width: 85px;
        height: 85px;
        background: #ffffff;
        border-radius: 100%;
        box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
        display: table;
        padding: 0 5px; }
        @media only screen and (max-width: 520px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-round .mb-round-box {
            border-radius: inherit;
            height: auto;
            padding: 5px 5px;
            min-width: 85px;
            width: auto; } }
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-round .mb-round-box .mb-round-number {
          display: table-cell;
          vertical-align: middle;
          font-size: 17px; }
          @media only screen and (max-width: 520px) {
            .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-round .mb-round-box .mb-round-number {
              font-size: 15px; } }
          @media only screen and (max-width: 320px) {
            .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-round .mb-round-box .mb-round-number {
              font-size: 14px; } }
    .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-round {
      position: absolute;
      top: 4%;
      right: 23%;
      width: 110px;
      text-align: center;
      z-index: 9; }
      @media only screen and (max-width: 900px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-round {
          right: 22%; } }
      @media only screen and (max-width: 790px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-round {
          right: 21%; } }
      @media only screen and (max-width: 736px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-round {
          top: 2%; } }
      @media only screen and (max-width: 690px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-round {
          right: 20%; } }
      @media only screen and (max-width: 680px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-round {
          top: -1%; } }
      @media only screen and (max-width: 630px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-round {
          right: 20%; } }
      @media only screen and (max-width: 610px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-round {
          right: 19%; } }
      @media only screen and (max-width: 580px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-round {
          top: -3%; } }
      @media only screen and (max-width: 530px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-round {
          right: 17%; } }
      @media only screen and (max-width: 520px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-round {
          top: inherit;
          bottom: 80%;
          right: 17%;
          width: auto;
          min-width: 85px; } }
      @media only screen and (max-width: 480px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-round {
          right: 12%; } }
      @media only screen and (max-width: 460px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-round {
          right: 11%; } }
      @media only screen and (max-width: 375px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-round {
          right: 8%;
          /* bottom:32%; */ } }
      @media only screen and (max-width: 350px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-round {
          right: 6%; } }
      @media only screen and (max-width: 320px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-round {
          right: 4%; } }
      .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-round .mb-round-box {
        width: 85px;
        height: 85px;
        background: #ffffff;
        border-radius: 100%;
        box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
        display: table;
        padding: 0 5px; }
        @media only screen and (max-width: 520px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-round .mb-round-box {
            border-radius: inherit;
            height: auto;
            padding: 5px 5px;
            min-width: 85px;
            width: auto; } }
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-round .mb-round-box .mb-round-number {
          display: table-cell;
          vertical-align: middle;
          font-size: 17px; }
          @media only screen and (max-width: 520px) {
            .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-round .mb-round-box .mb-round-number {
              font-size: 15px; } }
          @media only screen and (max-width: 320px) {
            .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-round .mb-round-box .mb-round-number {
              font-size: 14px; } }
    .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-text {
      position: absolute;
      top: 195px;
      left: 43.5%;
      font-size: 24px;
      text-transform: uppercase;
      color: #b6c5cf;
      transform: rotate(450deg);
      z-index: 9;
      /* @include breakpointMax(480) {
				top:50px;
			} */ }
      @media only screen and (max-width: 820px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-text {
          left: 42.5%; } }
      @media only screen and (max-width: 770px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-text {
          left: 42.5%;
          font-size: 23px; } }
      @media only screen and (max-width: 768px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-text {
          top: 175px; } }
      @media only screen and (max-width: 736px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-text {
          top: 155px; } }
      @media only screen and (max-width: 680px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-text {
          top: 145px; } }
      @media only screen and (max-width: 640px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-text {
          top: 135px; } }
      @media only screen and (max-width: 630px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-text {
          left: 41%; } }
      @media only screen and (max-width: 620px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-text {
          font-size: 22px;
          left: 41%; } }
      @media only screen and (max-width: 580px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-text {
          font-size: 20px;
          top: 125px; } }
      @media only screen and (max-width: 520px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-text {
          top: -11px; } }
      @media only screen and (max-width: 500px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-text {
          /* top:58px; */
          font-size: 19px; } }
      @media only screen and (max-width: 460px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-text {
          left: 40%; } }
      @media only screen and (max-width: 460px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-text {
          font-size: 16px;
          left: 41%;
          /* top:62px; */ } }
      @media only screen and (max-width: 414px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-text {
          font-size: 16px;
          left: 41%;
          /* top:45px; */ } }
      @media only screen and (max-width: 375px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-text {
          left: 40%;
          top: -44px; } }
      @media only screen and (max-width: 320px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-utilities-text {
          font-size: 16px;
          left: 38%;
          /* top:12px; */ } }
    .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-text {
      position: absolute;
      top: 200px;
      right: 21.5%;
      font-size: 24px;
      text-transform: uppercase;
      color: #b6c5cf;
      transform: rotate(450deg);
      z-index: 9; }
      @media only screen and (max-width: 900px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-text {
          right: 20.5%; } }
      @media only screen and (max-width: 790px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-text {
          right: 19.5%; } }
      @media only screen and (max-width: 770px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-text {
          font-size: 23px; } }
      @media only screen and (max-width: 768px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-text {
          top: 180px; } }
      @media only screen and (max-width: 736px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-text {
          top: 160px; } }
      @media only screen and (max-width: 680px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-text {
          top: 150px; } }
      @media only screen and (max-width: 640px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-text {
          top: 140px;
          right: 18%; } }
      @media only screen and (max-width: 620px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-text {
          font-size: 22px; } }
      @media only screen and (max-width: 580px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-text {
          font-size: 20px;
          top: 130px;
          right: 18%; } }
      @media only screen and (max-width: 530px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-text {
          right: 18%; } }
      @media only screen and (max-width: 520px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-text {
          top: -8px; } }
      @media only screen and (max-width: 500px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-text {
          font-size: 19px;
          /* top:62px; */ } }
      @media only screen and (max-width: 480px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-text {
          /* top:52px; */ } }
      @media only screen and (max-width: 460px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-text {
          font-size: 16px;
          right: 19%; } }
      @media only screen and (max-width: 440px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-text {
          right: 18%; } }
      @media only screen and (max-width: 414px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-text {
          font-size: 16px;
          right: 18%; } }
      @media only screen and (max-width: 390px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-text {
          right: 17%; } }
      @media only screen and (max-width: 375px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-text {
          top: -41px;
          right: 17%; } }
      @media only screen and (max-width: 360px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-text {
          right: 16%; } }
      @media only screen and (max-width: 350px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-text {
          right: 15%; } }
      @media only screen and (max-width: 320px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-commute-text {
          font-size: 16px;
          right: 15%; } }
    .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox {
      width: 270px;
      height: 40px;
      background: #ffffff;
      position: absolute;
      text-align: left;
      z-index: 9; }
      .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox .sub-box-inner {
        display: table;
        vertical-align: middle;
        height: 30px;
        width: 100%; }
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox .sub-box-inner .box-info {
          display: table-cell;
          vertical-align: middle;
          font-size: 13px;
          width: 100%; }
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox .sub-box-inner .box-info .info-highlight {
            display: inline-block; }
            .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox .sub-box-inner .box-info .info-highlight.first-address-box {
              display: block;
              line-height: normal;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              height: 15px;
              width: 184px; }
              @media only screen and (max-width: 999px) {
                .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox .sub-box-inner .box-info .info-highlight.first-address-box {
                  width: 250px; } }
              @media only screen and (max-width: 650px) {
                .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox .sub-box-inner .box-info .info-highlight.first-address-box {
                  width: 230px; } }
              @media only screen and (max-width: 600px) {
                .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox .sub-box-inner .box-info .info-highlight.first-address-box {
                  width: 200px; } }
              @media only screen and (max-width: 530px) {
                .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox .sub-box-inner .box-info .info-highlight.first-address-box {
                  width: 190px; } }
              @media only screen and (max-width: 520px) {
                .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox .sub-box-inner .box-info .info-highlight.first-address-box {
                  width: 230px; } }
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox .sub-box-inner .box-info .second-address-box {
            font-size: 12px;
            padding-top: 3px;
            display: block;
            line-height: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 15px;
            width: 182px; }
            @media only screen and (max-width: 999px) {
              .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox .sub-box-inner .box-info .second-address-box {
                width: 250px; } }
            @media only screen and (max-width: 650px) {
              .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox .sub-box-inner .box-info .second-address-box {
                width: 230px; } }
            @media only screen and (max-width: 600px) {
              .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox .sub-box-inner .box-info .second-address-box {
                width: 200px; } }
            @media only screen and (max-width: 530px) {
              .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox .sub-box-inner .box-info .second-address-box {
                width: 190px; } }
            @media only screen and (max-width: 520px) {
              .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox .sub-box-inner .box-info .second-address-box {
                width: 230px; } }
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox .sub-box-inner .box-info .second-info-box {
            display: inline-block;
            padding-left: 5px; }
      .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-tlc {
        left: calc(50% - 135px);
        top: 35%;
        padding: 5px 10px;
        text-align: center; }
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-tlc .sub-box-inner .box-info {
          font-size: 18px;
          line-height: 28px; }
        @media only screen and (max-width: 800px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-tlc {
            top: 34%; } }
        @media only screen and (max-width: 650px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-tlc {
            left: calc(50% - 125px);
            width: 250px;
            top: 31.8%; } }
        @media only screen and (max-width: 600px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-tlc {
            left: calc(50% - 110px);
            width: 220px;
            top: 31.8%; } }
        @media only screen and (max-width: 530px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-tlc {
            left: calc(50% - 106px);
            width: 210px;
            top: 31.8%; } }
        @media only screen and (max-width: 520px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-tlc {
            top: 27.7%;
            left: calc(50% - 125px);
            width: 250px; } }
        @media only screen and (max-width: 450px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-tlc {
            top: 29%; } }
        @media only screen and (max-width: 400px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-tlc {
            top: 30.9%; } }
        @media only screen and (max-width: 375px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-tlc {
            top: 26.4%; } }
      .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-price {
        left: calc(50% - 135px);
        top: 40%;
        padding: 5px 10px;
        text-align: center; }
        @media only screen and (max-width: 800px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-price {
            top: 40%; } }
        @media only screen and (max-width: 650px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-price {
            left: calc(50% - 125px);
            width: 250px;
            top: 38.9%; } }
        @media only screen and (max-width: 600px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-price {
            left: calc(50% - 110px);
            width: 220px;
            top: 38.9%; } }
        @media only screen and (max-width: 530px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-price {
            left: calc(50% - 106px);
            width: 210px;
            top: 38.9%; } }
        @media only screen and (max-width: 520px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-price {
            top: 35.7%;
            left: calc(50% - 125px);
            width: 250px; } }
        @media only screen and (max-width: 450px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-price {
            top: 37%; } }
        @media only screen and (max-width: 400px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-price {
            top: 39%; } }
        @media only screen and (max-width: 375px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-price {
            top: 35.6%; } }
      .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-mls {
        left: calc(50% - 135px);
        top: 45%;
        padding: 5px 10px;
        text-align: center; }
        @media only screen and (max-width: 800px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-mls {
            top: 46%; } }
        @media only screen and (max-width: 650px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-mls {
            left: calc(50% - 125px);
            width: 250px;
            top: 45.9%; } }
        @media only screen and (max-width: 600px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-mls {
            left: calc(50% - 110px);
            width: 220px;
            top: 45.9%; } }
        @media only screen and (max-width: 530px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-mls {
            left: calc(50% - 106px);
            width: 210px;
            top: 45.9%; } }
        @media only screen and (max-width: 520px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-mls {
            top: 43.8%;
            left: calc(50% - 125px);
            width: 250px; } }
        @media only screen and (max-width: 450px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-mls {
            top: 45%; } }
        @media only screen and (max-width: 400px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-mls {
            top: 46.9%; } }
        @media only screen and (max-width: 375px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-mls {
            top: 44.9%; } }
      .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-address {
        left: calc(50% - 135px);
        top: 50%;
        padding: 5px 10px;
        text-align: center; }
        @media only screen and (max-width: 800px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-address {
            top: 52%; } }
        @media only screen and (max-width: 650px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-address {
            left: calc(50% - 125px);
            width: 250px;
            top: 53%; } }
        @media only screen and (max-width: 600px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-address {
            left: calc(50% - 110px);
            width: 220px;
            top: 53%; } }
        @media only screen and (max-width: 530px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-address {
            left: calc(50% - 106px);
            width: 210px;
            top: 53%; } }
        @media only screen and (max-width: 520px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-address {
            top: 52%;
            left: calc(50% - 125px);
            width: 250px; } }
        @media only screen and (max-width: 450px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-address {
            top: 53%; } }
        @media only screen and (max-width: 400px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-address {
            top: 55%; } }
        @media only screen and (max-width: 375px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-address {
            top: 54%; } }
      .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-bed {
        left: calc(50% - 135px);
        top: 55%;
        padding: 5px 10px;
        text-align: center; }
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-bed .sub-box-inner .box-info .second-info-box {
          padding-left: 5px; }
        @media only screen and (max-width: 800px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-bed {
            top: 58%; } }
        @media only screen and (max-width: 650px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-bed {
            left: calc(50% - 125px);
            width: 250px;
            top: 60%; } }
        @media only screen and (max-width: 600px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-bed {
            left: calc(50% - 110px);
            width: 220px;
            top: 60%; } }
        @media only screen and (max-width: 530px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-bed {
            left: calc(50% - 106px);
            width: 210px;
            top: 60%; } }
        @media only screen and (max-width: 520px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-bed {
            top: 60%;
            left: calc(50% - 125px);
            width: 250px; } }
        @media only screen and (max-width: 450px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-bed {
            top: 61%; } }
        @media only screen and (max-width: 400px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-bed {
            top: 63%; } }
        @media only screen and (max-width: 375px) {
          .tlc-how-works .mb-img-tlcbanner .mb-tlc-home-smallbox.mb-property-full-bed {
            top: 63%; } }
    .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
      width: 240px;
      height: 140px;
      position: absolute;
      bottom: 149px;
      left: calc(50% - 123px);
      border: 3px solid #fff;
      overflow: hidden;
      min-height: 140px;
      background: #ffffff;
      z-index: 9; }
      .tlc-how-works .mb-img-tlcbanner .mb-property-photo img {
        width: 100%;
        position: absolute;
        height: auto;
        margin: auto;
        top: -100%;
        right: -100%;
        bottom: -100%;
        left: -100%;
        min-height: 140px; }
      @media only screen and (max-width: 990px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          left: calc(50% - 122px); } }
      @media only screen and (max-width: 970px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          left: calc(50% - 121px);
          bottom: 145px; } }
      @media only screen and (max-width: 950px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          left: calc(50% - 112px);
          bottom: 160px;
          width: 220px;
          height: 120px;
          min-height: 120px; }
          .tlc-how-works .mb-img-tlcbanner .mb-property-photo img {
            min-height: 120px; } }
      @media only screen and (max-width: 930px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 155px; } }
      @media only screen and (max-width: 920px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 150px; } }
      @media only screen and (max-width: 900px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 145px; } }
      @media only screen and (max-width: 890px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          left: calc(50% - 111px);
          bottom: 143px; } }
      @media only screen and (max-width: 880px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 140px; } }
      @media only screen and (max-width: 870px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          left: calc(50% - 106px);
          bottom: 147px;
          width: 210px;
          height: 110px;
          min-height: 110px; }
          .tlc-how-works .mb-img-tlcbanner .mb-property-photo img {
            min-height: 110px; } }
      @media only screen and (max-width: 860px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 145px; } }
      @media only screen and (max-width: 850px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 140px; } }
      @media only screen and (max-width: 840px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 135px; } }
      @media only screen and (max-width: 820px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          left: calc(50% - 102px);
          bottom: 138px;
          width: 200px;
          height: 100px;
          min-height: 100px; }
          .tlc-how-works .mb-img-tlcbanner .mb-property-photo img {
            min-height: 100px; } }
      @media only screen and (max-width: 800px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 135px; } }
      @media only screen and (max-width: 790px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 133px;
          left: calc(50% - 91px);
          width: 180px;
          height: 90px;
          min-height: 100px; }
          .tlc-how-works .mb-img-tlcbanner .mb-property-photo img {
            min-height: 90px; } }
      @media only screen and (max-width: 780px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 130px; } }
      @media only screen and (max-width: 770px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 128px; } }
      @media only screen and (max-width: 760px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 125px; } }
      @media only screen and (max-width: 750px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 123px; } }
      @media only screen and (max-width: 740px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          left: calc(50% - 80px);
          bottom: 135px;
          width: 160px;
          height: 80px;
          min-height: 80px; }
          .tlc-how-works .mb-img-tlcbanner .mb-property-photo img {
            min-height: 80px; } }
      @media only screen and (max-width: 720px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 130px; } }
      @media only screen and (max-width: 700px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 125px; } }
      @media only screen and (max-width: 680px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 120px;
          left: calc(50% - 81px); } }
      @media only screen and (max-width: 670px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 117px; } }
      @media only screen and (max-width: 650px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 111px; } }
      @media only screen and (max-width: 640px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 117px;
          left: calc(50% - 71px);
          width: 140px;
          height: 70px;
          min-height: 70px; }
          .tlc-how-works .mb-img-tlcbanner .mb-property-photo img {
            min-height: 70px; } }
      @media only screen and (max-width: 630px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 113px; } }
      @media only screen and (max-width: 610px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 110px; } }
      @media only screen and (max-width: 600px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 105px; } }
      @media only screen and (max-width: 590px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 103px; } }
      @media only screen and (max-width: 580px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 100px;
          left: calc(50% - 72px); } }
      @media only screen and (max-width: 570px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 98px; } }
      @media only screen and (max-width: 560px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 92px;
          left: calc(50% - 71px); } }
      @media only screen and (max-width: 550px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          left: calc(50% - 60px);
          bottom: 100px;
          width: 120px;
          height: 60px;
          min-height: 60px; }
          .tlc-how-works .mb-img-tlcbanner .mb-property-photo img {
            min-height: 60px; } }
      @media only screen and (max-width: 530px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          left: calc(50% - 62px);
          bottom: 95px; } }
      @media only screen and (max-width: 520px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          left: calc(50% - 62px);
          bottom: 88px;
          width: 120px;
          height: 60px;
          min-height: 60px; }
          .tlc-how-works .mb-img-tlcbanner .mb-property-photo img {
            min-height: 60px; } }
      @media only screen and (max-width: 510px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          left: calc(50% - 61px);
          bottom: 85px; } }
      @media only screen and (max-width: 490px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          left: calc(50% - 56px);
          bottom: 85px;
          width: 110px;
          height: 55px;
          min-height: 55px; }
          .tlc-how-works .mb-img-tlcbanner .mb-property-photo img {
            min-height: 55px; } }
      @media only screen and (max-width: 470px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 80px; } }
      @media only screen and (max-width: 450px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 73px; } }
      @media only screen and (max-width: 440px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          left: calc(50% - 51px);
          bottom: 73px;
          width: 100px;
          height: 50px;
          min-height: 50px; }
          .tlc-how-works .mb-img-tlcbanner .mb-property-photo img {
            min-height: 50px; } }
      @media only screen and (max-width: 420px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          left: calc(50% - 50.5px);
          bottom: 71px; } }
      @media only screen and (max-width: 400px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          left: calc(50% - 45px);
          bottom: 70px;
          width: 90px;
          height: 45px;
          min-height: 45px; }
          .tlc-how-works .mb-img-tlcbanner .mb-property-photo img {
            min-height: 45px; } }
      @media only screen and (max-width: 390px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          left: calc(50% - 45.5px);
          bottom: 67px; } }
      @media only screen and (max-width: 380px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          left: calc(50% - 45px);
          bottom: 66px; } }
      @media only screen and (max-width: 375px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          left: calc(50% - 46px);
          bottom: 60px;
          width: 90px;
          height: 45px;
          min-height: 45px; }
          .tlc-how-works .mb-img-tlcbanner .mb-property-photo img {
            min-height: 45px; } }
      @media only screen and (max-width: 360px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          left: calc(50% - 40px);
          bottom: 63px;
          width: 80px;
          height: 40px;
          min-height: 40px; }
          .tlc-how-works .mb-img-tlcbanner .mb-property-photo img {
            min-height: 40px; } }
      @media only screen and (max-width: 350px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          left: calc(50% - 40.5px);
          bottom: 61px; } }
      @media only screen and (max-width: 340px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          left: calc(50% - 40px);
          bottom: 58px; } }
      @media only screen and (max-width: 330px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 55px; } }
      @media only screen and (max-width: 320px) {
        .tlc-how-works .mb-img-tlcbanner .mb-property-photo {
          bottom: 53px; } }
    .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-left {
      position: absolute;
      top: 195px;
      right: calc(50% - 120px);
      width: 24px; }
      @media only screen and (max-width: 870px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-left {
          right: calc(50% - 113px); } }
      @media only screen and (max-width: 850px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-left {
          right: calc(50% - 105px); } }
      @media only screen and (max-width: 800px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-left {
          right: calc(50% - 100px); } }
      @media only screen and (max-width: 760px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-left {
          top: 185px; } }
      @media only screen and (max-width: 750px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-left {
          right: calc(50% - 95px); } }
      @media only screen and (max-width: 736px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-left {
          top: 170px; } }
      @media only screen and (max-width: 750px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-left {
          right: calc(50% - 90px); } }
      @media only screen and (max-width: 680px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-left {
          top: 160px; } }
      @media only screen and (max-width: 650px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-left {
          right: calc(50% - 85px); } }
      @media only screen and (max-width: 640px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-left {
          top: 150px;
          width: 20px; } }
      @media only screen and (max-width: 600px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-left {
          right: calc(50% - 79px); } }
      @media only screen and (max-width: 580px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-left {
          top: 140px; } }
      @media only screen and (max-width: 520px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-left {
          right: calc(50% - 68px);
          top: -4px; } }
      @media only screen and (max-width: 480px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-left {
          right: calc(50% - 62px);
          width: 18px; } }
      @media only screen and (max-width: 400px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-left {
          right: calc(50% - 55px);
          width: 17px; } }
      @media only screen and (max-width: 350px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-left {
          top: -40px; } }
      @media only screen and (max-width: 330px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-left {
          right: calc(50% - 49px);
          width: 14px; } }
      @media only screen and (max-width: 330px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-left {
          right: calc(50% - 45px);
          width: 14px; } }
    .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-right {
      position: absolute;
      top: 195px;
      left: calc(50% - 120px);
      width: 24px; }
      @media only screen and (max-width: 870px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-right {
          left: calc(50% - 113px); } }
      @media only screen and (max-width: 850px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-right {
          left: calc(50% - 105px); } }
      @media only screen and (max-width: 800px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-right {
          left: calc(50% - 100px); } }
      @media only screen and (max-width: 760px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-right {
          top: 185px; } }
      @media only screen and (max-width: 750px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-right {
          left: calc(50% - 95px); } }
      @media only screen and (max-width: 736px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-right {
          top: 170px; } }
      @media only screen and (max-width: 700px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-right {
          left: calc(50% - 90px); } }
      @media only screen and (max-width: 680px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-right {
          top: 160px; } }
      @media only screen and (max-width: 650px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-right {
          left: calc(50% - 85px); } }
      @media only screen and (max-width: 640px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-right {
          top: 150px;
          width: 20px; } }
      @media only screen and (max-width: 600px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-right {
          left: calc(50% - 79px); } }
      @media only screen and (max-width: 580px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-right {
          top: 140px; } }
      @media only screen and (max-width: 520px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-right {
          left: calc(50% - 68px);
          top: -4px; } }
      @media only screen and (max-width: 480px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-right {
          left: calc(50% - 62px);
          width: 18px; } }
      @media only screen and (max-width: 400px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-right {
          left: calc(50% - 50px);
          width: 17px; } }
      @media only screen and (max-width: 350px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-right {
          top: -40px; } }
      @media only screen and (max-width: 330px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-right {
          left: calc(50% - 44px);
          width: 14px; } }
      @media only screen and (max-width: 320px) {
        .tlc-how-works .mb-img-tlcbanner .mb-tlc-plus-right {
          left: calc(50% - 42px);
          width: 14px; } }

/**********************************************************************************************************New Style End**********************************************/
/*****************************TLC Help End***********************************/
/*****************************Properties card start***********************************/
.prop-card {
  width: 290px;
  float: none;
  display: inline-block;
  box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  margin-bottom: 30px;
  text-align: left; }
  @media only screen and (max-width: 630px) {
    .prop-card {
      margin-bottom: 20px; } }
  .prop-card .slider-img-box {
    width: 100%;
    float: left;
    position: relative; }
    .prop-card .slider-img-box .oval-icon {
      height: 34px;
      width: 34px;
      background-color: #ffffff;
      border-radius: 100%;
      box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.11);
      text-align: center;
      line-height: normal; }
      .prop-card .slider-img-box .oval-icon a {
        display: block; }
      .prop-card .slider-img-box .oval-icon i {
        font-size: 24px;
        line-height: 36px; }
      .prop-card .slider-img-box .oval-icon.heart-icon {
        position: absolute;
        top: 8px;
        left: 8px; }
        .prop-card .slider-img-box .oval-icon.heart-icon.heart-second-icon {
          left: 50px; }
      .prop-card .slider-img-box .oval-icon.compare-icon {
        position: absolute;
        top: 8px;
        right: 50px; }
        .prop-card .slider-img-box .oval-icon.compare-icon.compare-last-icon {
          right: 8px; }
      .prop-card .slider-img-box .oval-icon.close-icon {
        position: absolute;
        top: 8px;
        right: 8px; }
    .prop-card .slider-img-box .prop-open-house {
      height: 23px;
      max-width: 190px;
      opacity: 0.6;
      font-size: 10px;
      text-align: left;
      padding: 5px 10px 5px 10px;
      position: absolute;
      bottom: 6px;
      right: 0;
      border-radius: 10px 0 0px 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      background: #033258;
      color: #fff; }
      .prop-card .slider-img-box .prop-open-house.prop-open-house-full {
        max-width: 275px; }
    .prop-card .slider-img-box .slider-img {
      width: 100%;
      display: block;
      position: relative;
      overflow: hidden;
      min-height: 190px;
      background: #ffffff;
      border-radius: 8px 8px 0 0; }
      .prop-card .slider-img-box .slider-img img {
        width: 100%;
        position: absolute;
        height: auto;
        margin: auto;
        top: -100%;
        right: -100%;
        bottom: -100%;
        left: -100%;
        min-height: 190px; }
      .prop-card .slider-img-box .slider-img .slide img {
        width: 100%;
        position: inherit;
        height: 190px;
        margin: auto;
        top: inherit;
        right: inherit;
        bottom: inherit;
        left: inherit;
        min-height: 190px; }
      .prop-card .slider-img-box .slider-img .carousel-slider .slider-wrapper .slider .slide a {
        display: block; }
      .prop-card .slider-img-box .slider-img .carousel-slider .slider-wrapper .slider .slide img {
        border-radius: 8px 8px 0 0; }
      .prop-card .slider-img-box .slider-img .carousel-slider .control-arrow {
        top: calc(50% - 25px);
        height: 50px;
        padding: 0px 5px;
        display: table;
        width: 30px;
        text-align: center;
        background: rgba(0, 0, 0, 0);
        background-repeat: repeat-x;
        line-height: normal;
        opacity: 1; }
        .prop-card .slider-img-box .slider-img .carousel-slider .control-arrow.control-prev {
          background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.0001));
          border-radius: 0 4px 4px 0; }
          .prop-card .slider-img-box .slider-img .carousel-slider .control-arrow.control-prev:before {
            content: "\f104";
            font-family: "FontAwesome";
            border-top: none;
            border-bottom: none;
            border-right: none;
            font-size: 20px;
            line-height: normal;
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            width: 30px; }
        .prop-card .slider-img-box .slider-img .carousel-slider .control-arrow.control-next {
          background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.0001));
          border-radius: 4px 0 0 4px; }
          .prop-card .slider-img-box .slider-img .carousel-slider .control-arrow.control-next:before {
            content: "\f105";
            font-family: "FontAwesome";
            border-top: none;
            border-bottom: none;
            border-left: none;
            font-size: 20px;
            line-height: normal;
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            width: 30px; }
  .prop-card .ads-card {
    cursor: pointer; }
    .prop-card .ads-card img {
      border-radius: 8px; }
  .prop-card .card-prop-info {
    width: 100%;
    float: left;
    padding: 12px 14px 12px 14px;
    /* min-height:100px; */
    min-height: 177px; }
    .prop-card .card-prop-info .card-prop-amount {
      width: 43%;
      float: left;
      text-align: left;
      padding: 0px;
      font-size: 14px;
      line-height: normal;
      height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
      .prop-card .card-prop-info .card-prop-amount.card-prop-amount-full {
        width: 100%; }
    .prop-card .card-prop-info .card-tlc-amount {
      width: auto;
      float: right;
      font-size: 12px;
      line-height: 14px;
      padding: 2px 4px;
      text-align: left;
      max-width: 147px;
      border: 1px solid;
      border-radius: 2px; }
    .prop-card .card-prop-info .prop-info-basic {
      width: 100%;
      float: left;
      padding-top: 7px; }
      .prop-card .card-prop-info .prop-info-basic .card-add-lt {
        width: 63%;
        display: inline-block;
        vertical-align: middle; }
        .prop-card .card-prop-info .prop-info-basic .card-add-lt .address-line {
          height: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: normal;
          font-size: 10px; }
          .prop-card .card-prop-info .prop-info-basic .card-add-lt .address-line:first-child {
            font-size: 12px;
            margin-bottom: 2px;
            height: 17px; }
        .prop-card .card-prop-info .prop-info-basic .card-add-lt.card-add-lt-full {
          width: 100%; }
      .prop-card .card-prop-info .prop-info-basic .card-add-rt {
        width: 37%;
        display: inline-block;
        vertical-align: top; }
        .prop-card .card-prop-info .prop-info-basic .card-add-rt .card-mls-number {
          font-size: 10px;
          margin-bottom: 10px;
          text-align: right; }
        .prop-card .card-prop-info .prop-info-basic .card-add-rt .card-virtual-icon {
          width: 100%;
          display: inline-block;
          vertical-align: top;
          text-align: right; }
          .prop-card .card-prop-info .prop-info-basic .card-add-rt .card-virtual-icon .card-camera-icon {
            width: 25px;
            display: inline-block;
            vertical-align: middle; }
          .prop-card .card-prop-info .prop-info-basic .card-add-rt .card-virtual-icon .card-tour-icon {
            width: 25px;
            display: inline-block;
            vertical-align: middle;
            margin-left: 7px; }
          .prop-card .card-prop-info .prop-info-basic .card-add-rt .card-virtual-icon .card-ecomm-icon {
            width: 25px;
            display: inline-block;
            vertical-align: middle;
            margin-left: 7px; }
      .prop-card .card-prop-info .prop-info-basic .prop-info-beds {
        width: 100%;
        float: left;
        line-height: normal;
        font-size: 10px;
        margin-top: 2px; }
        .prop-card .card-prop-info .prop-info-basic .prop-info-beds span {
          display: inline-block;
          vertical-align: top;
          margin-left: 7px; }
          .prop-card .card-prop-info .prop-info-basic .prop-info-beds span:first-child {
            margin-left: 0px; }
      .prop-card .card-prop-info .prop-info-basic .prop-info-ext {
        width: 100%;
        display: block;
        margin-top: 6px; }
        .prop-card .card-prop-info .prop-info-basic .prop-info-ext .prop-open-house-ext {
          font-size: 10px;
          line-height: 13px;
          background: #dee2e6;
          padding: 6px;
          border-radius: 4px;
          margin-top: 6px;
          text-align: center;
          vertical-align: middle;
          min-height: 38px;
          display: table;
          width: 100%; }
          .prop-card .card-prop-info .prop-info-basic .prop-info-ext .prop-open-house-ext .prop-open-house-cnt {
            display: table-cell;
            vertical-align: middle;
            width: 100%; }
        .prop-card .card-prop-info .prop-info-basic .prop-info-ext .courtesy-txt-ext {
          font-size: 10px;
          line-height: 13px;
          min-height: 26px;
          width: 100%;
          display: block; }
  .prop-card .card-status {
    position: absolute;
    bottom: 6px;
    left: 6px;
    margin: 0px;
    padding: 5px 10px;
    border-radius: 11px;
    font-size: 10px;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    min-height: 23px;
    max-width: 86px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
    .prop-card .card-status.card-status-full {
      max-width: 230px; }
  .prop-card .no-photo-overlay {
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    z-index: 0;
    position: absolute;
    bottom: 35px;
    /* padding-top: 9px;
		padding-bottom: 9px; */
    text-align: center;
    color: #ffffff;
    padding: 12px;
    line-height: 20px; }
  .prop-card .card-map-marker {
    margin-right: 5px; }
  .prop-card .mls-logo-overlay {
    /* top: 0px;
		left: calc(50% - 24px);
		border-radius: 0 0 4px 4px; */
    bottom: 6px;
    right: 6px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.6);
    z-index: 0;
    position: absolute;
    text-align: center;
    padding: 4px; }
    .prop-card .mls-logo-overlay img {
      width: 48px; }

.tooltip-tlc-summary-box {
  background-color: #f7f7f7 !important;
  border: 2px solid #555 !important;
  width: 270px; }
  .tooltip-tlc-summary-box::before {
    border-top: 8px solid #555 !important;
    border-width: 8px 8px 0; }
  .tooltip-tlc-summary-box::after {
    border-top: 8px solid #f7f7f7 !important;
    border-width: 8px 8px 0; }
  .tooltip-tlc-summary-box .summary-wrapper {
    font-size: 13px !important;
    color: #5a687b !important; }
    .tooltip-tlc-summary-box .summary-wrapper .summary-total {
      font-weight: bold; }
  @media only screen and (max-width: 768px) {
    .tooltip-tlc-summary-box::before {
      display: none; }
    .tooltip-tlc-summary-box::after {
      display: none; } }

/*****************************Properties card End***********************************/
.horizontal-prop-card {
  display: inline-block;
  /* @include breakpointMax(550) {
		text-align:center;
	} */ }
  .horizontal-prop-card .current-prop-box {
    width: 100%;
    float: left;
    background: #fff;
    box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
    margin-bottom: 25px;
    min-height: 110px;
    border-radius: 8px; }
    @media only screen and (max-width: 576px) {
      .horizontal-prop-card .current-prop-box {
        width: 100%;
        float: none;
        display: inline-block; } }
    .horizontal-prop-card .current-prop-box .current-prop-img {
      width: 164px;
      float: left;
      overflow: hidden;
      min-height: 110px;
      position: relative;
      background: #f7f7f7;
      border-radius: 8px 0 0 8px; }
      @media only screen and (max-width: 576px) {
        .horizontal-prop-card .current-prop-box .current-prop-img {
          width: 100%;
          border-radius: 8px 8px 0px 0px; } }
      .horizontal-prop-card .current-prop-box .current-prop-img img {
        width: 100%;
        position: absolute;
        height: auto;
        margin: auto;
        top: -100%;
        right: -100%;
        bottom: -100%;
        left: -100%;
        min-height: 110px;
        border-radius: 8px 0 0 8px; }
      .horizontal-prop-card .current-prop-box .current-prop-img .oval-icon {
        height: 34px;
        width: 34px;
        background-color: #ffffff;
        border-radius: 100%;
        box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.11);
        text-align: center;
        line-height: normal; }
        .horizontal-prop-card .current-prop-box .current-prop-img .oval-icon a {
          display: block; }
        .horizontal-prop-card .current-prop-box .current-prop-img .oval-icon i {
          font-size: 22px;
          line-height: 34px; }
        .horizontal-prop-card .current-prop-box .current-prop-img .oval-icon.heart-icon {
          position: absolute;
          top: 8px;
          left: 8px; }
          .horizontal-prop-card .current-prop-box .current-prop-img .oval-icon.heart-icon.heart-second-icon {
            top: 50px; }
    .horizontal-prop-card .current-prop-box .current-prop-dt {
      width: calc(75% - 164px);
      float: left;
      padding: 5px 0 0 16px;
      min-height: 110px; }
      @media only screen and (max-width: 1199px) {
        .horizontal-prop-card .current-prop-box .current-prop-dt {
          width: calc(80% - 164px); } }
      @media only screen and (max-width: 700px) {
        .horizontal-prop-card .current-prop-box .current-prop-dt {
          width: calc(74% - 164px); } }
      @media only screen and (max-width: 576px) {
        .horizontal-prop-card .current-prop-box .current-prop-dt {
          width: 62%;
          text-align: left;
          padding: 5px 0 0 7px; } }
      @media only screen and (max-width: 330px) {
        .horizontal-prop-card .current-prop-box .current-prop-dt {
          padding: 5px 0 5px 7px; } }
      .horizontal-prop-card .current-prop-box .current-prop-dt .current-mls-number {
        font-size: 10px;
        width: 100%;
        float: left;
        padding: 3px 0 8px 0; }
      .horizontal-prop-card .current-prop-box .current-prop-dt .current-pro-price {
        font-size: 14px;
        width: 100%;
        float: left; }
        .horizontal-prop-card .current-prop-box .current-prop-dt .current-pro-price .current-amount {
          display: inline-block;
          vertical-align: middle;
          padding-right: 8px; }
        .horizontal-prop-card .current-prop-box .current-prop-dt .current-pro-price .status-active {
          max-width: 84px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 3px 9px;
          font-size: 9px;
          text-transform: uppercase; }
      .horizontal-prop-card .current-prop-box .current-prop-dt .current-pro-address {
        font-size: 12px;
        width: 100%;
        float: left;
        padding-top: 5px;
        height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: normal; }
      .horizontal-prop-card .current-prop-box .current-prop-dt .current-pro-subaddress {
        font-size: 10px;
        width: 100%;
        float: left;
        padding-top: 5px;
        height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: normal; }
      .horizontal-prop-card .current-prop-box .current-prop-dt .current-pro-beds {
        width: 100%;
        float: left;
        line-height: normal;
        font-size: 10px;
        margin-top: 2px; }
        .horizontal-prop-card .current-prop-box .current-prop-dt .current-pro-beds span {
          display: inline-block;
          vertical-align: top;
          margin-left: 7px; }
          .horizontal-prop-card .current-prop-box .current-prop-dt .current-pro-beds span:first-child {
            margin-left: 0px; }
    .horizontal-prop-card .current-prop-box .current-prop-right {
      width: 25%;
      float: left;
      text-align: left;
      padding: 40px 0 0 15px; }
      @media only screen and (max-width: 1199px) {
        .horizontal-prop-card .current-prop-box .current-prop-right {
          width: 20%; } }
      @media only screen and (max-width: 700px) {
        .horizontal-prop-card .current-prop-box .current-prop-right {
          width: 26%; } }
      @media only screen and (max-width: 576px) {
        .horizontal-prop-card .current-prop-box .current-prop-right {
          width: 38%;
          float: right;
          padding: 30px 7px 0 5px; } }
      .horizontal-prop-card .current-prop-box .current-prop-right .current-tlc-amount {
        width: auto;
        float: left;
        font-size: 12px;
        line-height: 14px;
        padding: 2px 4px;
        text-align: left;
        max-width: 100%;
        border-radius: 2px;
        border: 1px solid; }
        @media only screen and (max-width: 550px) {
          .horizontal-prop-card .current-prop-box .current-prop-right .current-tlc-amount {
            float: right;
            font-size: 11px; } }
    .horizontal-prop-card .current-prop-box .card-status {
      border-radius: 11px;
      padding: 3px 10px;
      font-size: 8px;
      display: inline-block;
      line-height: normal;
      vertical-align: middle;
      min-height: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 72px;
      text-transform: uppercase;
      text-align: center; }
  .horizontal-prop-card.checkbox-prop-card .current-prop-main {
    width: 100%;
    float: left; }
    .horizontal-prop-card.checkbox-prop-card .current-prop-main .common-checkbox {
      display: inline-block;
      margin-top: 10px; }
    .horizontal-prop-card.checkbox-prop-card .current-prop-main .checkbox-lt {
      width: 5%;
      float: left;
      text-align: right; }
      @media only screen and (max-width: 1199px) {
        .horizontal-prop-card.checkbox-prop-card .current-prop-main .checkbox-lt {
          text-align: left; } }
      @media only screen and (max-width: 576px) {
        .horizontal-prop-card.checkbox-prop-card .current-prop-main .checkbox-lt {
          width: 7%; } }
      @media only screen and (max-width: 374px) {
        .horizontal-prop-card.checkbox-prop-card .current-prop-main .checkbox-lt {
          width: 100%;
          margin-bottom: 7px; } }
    .horizontal-prop-card.checkbox-prop-card .current-prop-main .current-prop-box {
      width: 95%; }
      @media only screen and (max-width: 576px) {
        .horizontal-prop-card.checkbox-prop-card .current-prop-main .current-prop-box {
          width: 93%; } }
      @media only screen and (max-width: 374px) {
        .horizontal-prop-card.checkbox-prop-card .current-prop-main .current-prop-box {
          width: 100%; } }

/*****************************Featured Properties start***********************************/
.home-featured-listing {
  margin-top: 25px;
  padding: 0 20px;
  width: 100%;
  float: left; }
  .home-featured-listing.extra-space {
    margin-top: 55px; }
  @media only screen and (max-width: 1300px) {
    .home-featured-listing {
      padding: 0 10px; } }
  @media only screen and (max-width: 1280px) {
    .home-featured-listing {
      padding: 0 6px; } }
  @media only screen and (max-width: 1199px) {
    .home-featured-listing {
      padding: 0 20px; } }
  @media only screen and (max-width: 630px) {
    .home-featured-listing {
      padding: 0 15px; } }
  @media only screen and (max-width: 320px) {
    .home-featured-listing {
      padding: 0 5px; } }
  .home-featured-listing .white-card-content {
    background: #fff;
    border-radius: 8px;
    padding: 37px 0px 30px 0px;
    width: 100%;
    display: block; }
    .home-featured-listing .white-card-content .section-cards {
      text-align: center;
      margin-top: 30px; }
      @media only screen and (max-width: 630px) {
        .home-featured-listing .white-card-content .section-cards .cards-scroll {
          max-width: 600px;
          overflow-x: scroll;
          overflow-y: hidden;
          /* height:320px; */
          height: 380px;
          /* white-space:nowrap; */
          white-space: normal;
          margin-right: -15px;
          padding-left: 12px; } }
      @media only screen and (max-width: 320px) {
        .home-featured-listing .white-card-content .section-cards .cards-scroll {
          margin-right: -5px; } }
    .home-featured-listing .white-card-content .container {
      max-width: 100%;
      padding: 0 50px; }
      @media only screen and (max-width: 1367px) {
        .home-featured-listing .white-card-content .container {
          padding: 0 15px; } }
      @media only screen and (max-width: 1280px) {
        .home-featured-listing .white-card-content .container {
          padding: 0 4px; } }
      @media only screen and (max-width: 1199px) {
        .home-featured-listing .white-card-content .container {
          padding: 0 15px; } }
      @media only screen and (max-width: 700px) {
        .home-featured-listing .white-card-content .container {
          padding: 0 15px; } }
      @media only screen and (max-width: 320px) {
        .home-featured-listing .white-card-content .container {
          padding: 0 5px; } }
      @media only screen and (max-width: 1280px) {
        .home-featured-listing .white-card-content .container .row {
          margin-left: -3px;
          margin-right: -3px; } }
      @media only screen and (max-width: 1199px) {
        .home-featured-listing .white-card-content .container .row {
          margin-left: -15px;
          margin-right: -15px; } }
      @media only screen and (max-width: 630px) {
        .home-featured-listing .white-card-content .container .row {
          flex-wrap: inherit; } }
      @media only screen and (max-width: 320px) {
        .home-featured-listing .white-card-content .container .row {
          margin-left: -5px;
          margin-right: -5px; } }
      @media only screen and (max-width: 1280px) {
        .home-featured-listing .white-card-content .container .row .custom-col {
          padding-left: 3px;
          padding-right: 3px; } }
      @media only screen and (max-width: 1199px) {
        .home-featured-listing .white-card-content .container .row .custom-col {
          padding-left: 15px;
          padding-right: 15px; } }
      @media only screen and (max-width: 990px) {
        .home-featured-listing .white-card-content .container .row .custom-col {
          flex: 0 0 50%;
          max-width: 50%; } }
      @media only screen and (max-width: 700px) {
        .home-featured-listing .white-card-content .container .row .custom-col {
          padding-left: 3px;
          padding-right: 3px; } }
      @media only screen and (max-width: 630px) {
        .home-featured-listing .white-card-content .container .row .custom-col {
          display: inline-block;
          flex: 0 0 20%;
          max-width: 100%;
          padding-left: 12px;
          padding-right: 11px; } }
      @media only screen and (max-width: 320px) {
        .home-featured-listing .white-card-content .container .row .custom-col {
          padding-left: 0px;
          padding-right: 4px; } }
      @media only screen and (max-width: 630px) {
        .home-featured-listing .white-card-content .container .row.openhouse-row .custom-col {
          padding-left: 11px;
          padding-right: 11px; } }
      @media only screen and (max-width: 320px) {
        .home-featured-listing .white-card-content .container .row.openhouse-row .custom-col {
          padding-left: 0px;
          padding-right: 0px; } }
    .home-featured-listing .white-card-content.pp-card-content {
      padding-top: 0;
      padding-bottom: 0; }
      .home-featured-listing .white-card-content.pp-card-content .pp-title {
        margin: auto; }
        @media only screen and (max-width: 575px) {
          .home-featured-listing .white-card-content.pp-card-content .pp-title {
            padding: 16px 0; } }
      .home-featured-listing .white-card-content.pp-card-content .container {
        max-width: 100%;
        padding: 0 50px; }
        @media only screen and (max-width: 1367px) {
          .home-featured-listing .white-card-content.pp-card-content .container {
            padding: 0 15px; } }
        @media only screen and (max-width: 1280px) {
          .home-featured-listing .white-card-content.pp-card-content .container {
            padding: 0 4px; } }
        @media only screen and (max-width: 1199px) {
          .home-featured-listing .white-card-content.pp-card-content .container {
            padding: 0 15px; } }
        @media only screen and (max-width: 700px) {
          .home-featured-listing .white-card-content.pp-card-content .container {
            padding: 0 15px; } }
        @media only screen and (max-width: 320px) {
          .home-featured-listing .white-card-content.pp-card-content .container {
            padding: 0 5px; } }
        @media only screen and (max-width: 630px) {
          .home-featured-listing .white-card-content.pp-card-content .container .row {
            flex-wrap: wrap; } }
    .home-featured-listing .white-card-content .more-listing {
      text-align: right; }
      @media only screen and (max-width: 767px) {
        .home-featured-listing .white-card-content .more-listing {
          text-align: left;
          padding-top: 15px; } }
      .home-featured-listing .white-card-content .more-listing a {
        font-family: "GothamMedium";
        font-size: 14px; }

/*****************************Latest mls start***********************************/
.home-explain-listing {
  margin-top: 25px;
  margin-bottom: 50px;
  padding: 0 20px;
  width: 100%;
  float: left; }
  .home-explain-listing .white-card-content {
    background: #fff;
    border-radius: 8px;
    width: 100%;
    display: block;
    padding: 30px 0px 30px 0px; }
    .home-explain-listing .white-card-content .container {
      padding: 0 0 0 15px; }
      .home-explain-listing .white-card-content .container .section-title {
        margin-bottom: 50px;
        padding: 0 15px 0 0; }
        @media only screen and (max-width: 730px) {
          .home-explain-listing .white-card-content .container .section-title h1 {
            line-height: 28px; } }
        .home-explain-listing .white-card-content .container .section-title .explain-logo {
          width: 100%;
          display: block;
          margin-bottom: 15px; }
          .home-explain-listing .white-card-content .container .section-title .explain-logo img {
            width: auto; }
        .home-explain-listing .white-card-content .container .section-title h2 {
          font-size: 20px; }
  .home-explain-listing .tlc-explain-box {
    width: 100%;
    display: block;
    position: relative; }
    .home-explain-listing .tlc-explain-box .explain-banner {
      width: 100%;
      display: block; }
      .home-explain-listing .tlc-explain-box .explain-banner img {
        width: 100%;
        position: relative;
        z-index: 1; }
        @media only screen and (max-width: 1023px) {
          .home-explain-listing .tlc-explain-box .explain-banner img {
            margin-top: 30px; } }
    .home-explain-listing .tlc-explain-box .explain-content-box {
      width: calc(100% - 52%);
      position: absolute;
      top: 55px;
      left: 52%;
      z-index: 0;
      /* Red start */
      /* yellow start */
      /* yellow End */
      /* green start */
      /* green End */ }
      @media only screen and (max-width: 1400px) {
        .home-explain-listing .tlc-explain-box .explain-content-box {
          top: 51px; } }
      @media only screen and (max-width: 1398px) {
        .home-explain-listing .tlc-explain-box .explain-content-box {
          top: 54px; } }
      @media only screen and (max-width: 1390px) {
        .home-explain-listing .tlc-explain-box .explain-content-box {
          top: 53px; } }
      @media only screen and (max-width: 1370px) {
        .home-explain-listing .tlc-explain-box .explain-content-box {
          top: 52px; } }
      @media only screen and (max-width: 1340px) {
        .home-explain-listing .tlc-explain-box .explain-content-box {
          top: 51px; } }
      @media only screen and (max-width: 1320px) {
        .home-explain-listing .tlc-explain-box .explain-content-box {
          top: 50px; } }
      @media only screen and (max-width: 1300px) {
        .home-explain-listing .tlc-explain-box .explain-content-box {
          top: 49px; } }
      @media only screen and (max-width: 1200px) {
        .home-explain-listing .tlc-explain-box .explain-content-box {
          top: 46px; } }
      @media only screen and (max-width: 1210px) {
        .home-explain-listing .tlc-explain-box .explain-content-box {
          top: 45px; } }
      @media only screen and (max-width: 1250px) {
        .home-explain-listing .tlc-explain-box .explain-content-box {
          top: 48px; } }
      @media only screen and (max-width: 1230px) {
        .home-explain-listing .tlc-explain-box .explain-content-box {
          top: 47px; } }
      @media only screen and (max-width: 1190px) {
        .home-explain-listing .tlc-explain-box .explain-content-box {
          top: 45px; } }
      @media only screen and (max-width: 1160px) {
        .home-explain-listing .tlc-explain-box .explain-content-box {
          top: 45px; } }
      @media only screen and (max-width: 1150px) {
        .home-explain-listing .tlc-explain-box .explain-content-box {
          top: 44px; } }
      @media only screen and (max-width: 1120px) {
        .home-explain-listing .tlc-explain-box .explain-content-box {
          top: 40px; } }
      @media only screen and (max-width: 1100px) {
        .home-explain-listing .tlc-explain-box .explain-content-box {
          top: 41px; } }
      @media only screen and (max-width: 1080px) {
        .home-explain-listing .tlc-explain-box .explain-content-box {
          top: 41px; } }
      @media only screen and (max-width: 1087px) {
        .home-explain-listing .tlc-explain-box .explain-content-box {
          top: 42px; } }
      @media only screen and (max-width: 1060px) {
        .home-explain-listing .tlc-explain-box .explain-content-box {
          top: 40px; } }
      @media only screen and (max-width: 1045px) {
        .home-explain-listing .tlc-explain-box .explain-content-box {
          top: 39px; } }
      @media only screen and (max-width: 1030px) {
        .home-explain-listing .tlc-explain-box .explain-content-box {
          top: 39px; } }
      @media only screen and (max-width: 1024px) {
        .home-explain-listing .tlc-explain-box .explain-content-box {
          top: 39px; } }
      @media only screen and (max-width: 1023px) {
        .home-explain-listing .tlc-explain-box .explain-content-box {
          position: inherit;
          width: 100%;
          display: block;
          top: inherit;
          left: inherit;
          z-index: inherit;
          margin-bottom: 5px; } }
      .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt {
        width: 72%;
        display: block;
        background: linear-gradient(90deg, #d74d4d 0%, rgba(225, 225, 225, 0) 100%);
        height: 58px;
        color: #000000;
        padding: 0 0 0 72px;
        font-size: 16px;
        overflow: hidden; }
        .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt .red-cnt-box {
          display: table;
          height: 58px;
          width: 70%; }
          @media only screen and (max-width: 1360px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt .red-cnt-box {
              height: 56px; } }
          @media only screen and (max-width: 1320px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt .red-cnt-box {
              height: 55px; } }
          @media only screen and (max-width: 1310px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt .red-cnt-box {
              height: 52px; } }
          @media only screen and (max-width: 1300px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt .red-cnt-box {
              height: 54px; } }
          @media only screen and (max-width: 1280px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt .red-cnt-box {
              height: 51px; } }
          @media only screen and (max-width: 1230px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt .red-cnt-box {
              height: 50px; } }
          @media only screen and (max-width: 1200px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt .red-cnt-box {
              height: 49px;
              width: 78%; } }
          @media only screen and (max-width: 1180px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt .red-cnt-box {
              height: 47px; } }
          @media only screen and (max-width: 1100px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt .red-cnt-box {
              height: 44px; } }
          @media only screen and (max-width: 1120px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt .red-cnt-box {
              height: 46px; } }
          @media only screen and (max-width: 1060px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt .red-cnt-box {
              height: 42px; } }
          @media only screen and (max-width: 1030px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt .red-cnt-box {
              height: 42px;
              width: 90%; } }
          @media only screen and (max-width: 1024px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt .red-cnt-box {
              height: 41px; } }
          @media only screen and (max-width: 1023px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt .red-cnt-box {
              height: auto; } }
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt .red-cnt-box .red-box {
            display: table-cell;
            vertical-align: middle; }
        @media only screen and (max-width: 1360px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt {
            height: 56px; } }
        @media only screen and (max-width: 1320px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt {
            height: 55px;
            width: 76%; } }
        @media only screen and (max-width: 1310px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt {
            font-size: 15px;
            height: 52px; } }
        @media only screen and (max-width: 1300px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt {
            height: 54px;
            font-size: 15px; } }
        @media only screen and (max-width: 1280px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt {
            height: 51px; } }
        @media only screen and (max-width: 1230px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt {
            height: 50px; } }
        @media only screen and (max-width: 1200px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt {
            height: 49px;
            padding: 0 0 0 60px; } }
        @media only screen and (max-width: 1180px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt {
            height: 47px; } }
        @media only screen and (max-width: 1100px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt {
            top: 41px;
            height: 44px; } }
        @media only screen and (max-width: 1120px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt {
            height: 46px; } }
        @media only screen and (max-width: 1080px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt {
            width: 80%; } }
        @media only screen and (max-width: 1060px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt {
            height: 42px;
            font-size: 14px; } }
        @media only screen and (max-width: 1030px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt {
            height: 42px;
            font-size: 14px;
            width: 80%; } }
        @media only screen and (max-width: 1024px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt {
            height: 41px;
            font-size: 14px;
            width: 80%; } }
        @media only screen and (max-width: 1023px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-red-cnt {
            position: inherit;
            width: 100%;
            display: block;
            top: inherit;
            left: inherit;
            height: auto;
            padding: 15px;
            margin-bottom: 10px;
            font-size: 16px; } }
      .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt {
        width: 72%;
        display: block;
        background: linear-gradient(90deg, #f6df83 0%, rgba(225, 225, 225, 0) 100%);
        height: 58px;
        color: #000000;
        padding: 0 0 0 72px;
        font-size: 16px;
        overflow: hidden;
        top: 71px;
        position: absolute; }
        @media only screen and (max-width: 1415px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt {
            top: 69px; } }
        @media only screen and (max-width: 1360px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt {
            height: 56px;
            top: 66px; } }
        @media only screen and (max-width: 1320px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt {
            top: 66px;
            width: 76%;
            height: 55px; } }
        @media only screen and (max-width: 1310px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt {
            top: 65px;
            height: 52px;
            font-size: 15px; } }
        @media only screen and (max-width: 1300px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt {
            height: 54px;
            font-size: 15px;
            top: 64px; } }
        @media only screen and (max-width: 1280px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt {
            top: 63px;
            height: 51px; } }
        @media only screen and (max-width: 1250px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt {
            top: 62px; } }
        @media only screen and (max-width: 1230px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt {
            height: 49px; } }
        @media only screen and (max-width: 1200px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt {
            height: 49px;
            top: 58px;
            padding: 0 0 0 60px; } }
        @media only screen and (max-width: 1180px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt {
            height: 47px; } }
        @media only screen and (max-width: 1150px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt {
            top: 56px; } }
        @media only screen and (max-width: 1100px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt {
            top: 52px;
            height: 46px; } }
        @media only screen and (max-width: 1120px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt {
            top: 54px; } }
        @media only screen and (max-width: 1080px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt {
            width: 80%;
            height: 46px; } }
        @media only screen and (max-width: 1060px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt {
            top: 51px;
            height: 42px;
            font-size: 14px; } }
        @media only screen and (max-width: 1030px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt {
            height: 42px;
            top: 49px;
            font-size: 14px;
            width: 80%; } }
        @media only screen and (max-width: 1024px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt {
            height: 41px; } }
        @media only screen and (max-width: 1023px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt {
            position: inherit;
            width: 100%;
            display: block;
            top: inherit;
            left: inherit;
            height: auto;
            padding: 15px;
            margin-bottom: 10px;
            font-size: 16px; } }
        .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt .red-cnt-box {
          display: table;
          height: 58px;
          width: 70%; }
          @media only screen and (max-width: 1360px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt .red-cnt-box {
              height: 56px; } }
          @media only screen and (max-width: 1320px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt .red-cnt-box {
              height: 55px; } }
          @media only screen and (max-width: 1310px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt .red-cnt-box {
              height: 52px; } }
          @media only screen and (max-width: 1280px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt .red-cnt-box {
              height: 51px; } }
          @media only screen and (max-width: 1230px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt .red-cnt-box {
              height: 49px; } }
          @media only screen and (max-width: 1200px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt .red-cnt-box {
              height: 49px;
              width: 78%; } }
          @media only screen and (max-width: 1180px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt .red-cnt-box {
              height: 47px; } }
          @media only screen and (max-width: 1100px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt .red-cnt-box {
              height: 46px; } }
          @media only screen and (max-width: 1080px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt .red-cnt-box {
              height: 46px; } }
          @media only screen and (max-width: 1060px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt .red-cnt-box {
              height: 42px; } }
          @media only screen and (max-width: 1030px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt .red-cnt-box {
              height: 42px;
              width: 90%; } }
          @media only screen and (max-width: 1024px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt .red-cnt-box {
              height: 41px; } }
          @media only screen and (max-width: 1023px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt .red-cnt-box {
              height: auto; } }
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-yellow-cnt .red-cnt-box .red-box {
            display: table-cell;
            vertical-align: middle; }
      .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt {
        width: 72%;
        display: block;
        background: linear-gradient(90deg, #b4eb81 0%, rgba(225, 225, 225, 0) 100%);
        height: 58px;
        color: #000000;
        padding: 0 0 0 72px;
        font-size: 16px;
        overflow: hidden;
        top: 143px;
        position: absolute; }
        @media only screen and (max-width: 1415px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt {
            top: 139px; } }
        @media only screen and (max-width: 1390px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt {
            top: 137px; } }
        @media only screen and (max-width: 1370px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt {
            top: 135px;
            height: 56px; } }
        @media only screen and (max-width: 1350px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt {
            top: 132px; } }
        @media only screen and (max-width: 1340px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt {
            top: 130px; } }
        @media only screen and (max-width: 1320px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt {
            width: 76%;
            top: 130px;
            height: 55px; } }
        @media only screen and (max-width: 1310px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt {
            height: 52px;
            font-size: 15px; } }
        @media only screen and (max-width: 1300px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt {
            height: 53px;
            font-size: 15px;
            top: 127px; } }
        @media only screen and (max-width: 1280px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt {
            height: 52px;
            top: 125px; } }
        @media only screen and (max-width: 1260px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt {
            height: 51px;
            top: 122px; } }
        @media only screen and (max-width: 1230px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt {
            height: 50px;
            top: 121px; } }
        @media only screen and (max-width: 1210px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt {
            top: 119px; } }
        @media only screen and (max-width: 1200px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt {
            height: 49px;
            padding: 0 0 0 60px;
            top: 117px; } }
        @media only screen and (max-width: 1180px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt {
            height: 47px; } }
        @media only screen and (max-width: 1160px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt {
            top: 113px; } }
        @media only screen and (max-width: 1150px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt {
            top: 110px; } }
        @media only screen and (max-width: 1100px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt {
            top: 106px;
            height: 45px; } }
        @media only screen and (max-width: 1080px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt {
            width: 80%;
            height: 43px; } }
        @media only screen and (max-width: 1060px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt {
            top: 103px;
            font-size: 14px; } }
        @media only screen and (max-width: 1045px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt {
            height: 42px; } }
        @media only screen and (max-width: 1040px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt {
            height: 41px;
            top: 101px; } }
        @media only screen and (max-width: 1030px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt {
            height: 42px;
            top: 100px;
            font-size: 14px;
            width: 80%; } }
        @media only screen and (max-width: 1024px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt {
            height: 40px;
            top: 99px; } }
        @media only screen and (max-width: 1023px) {
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt {
            position: inherit;
            width: 100%;
            display: block;
            top: inherit;
            left: inherit;
            height: auto;
            padding: 15px;
            margin-bottom: 10px;
            font-size: 16px; } }
        .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt .red-cnt-box {
          display: table;
          height: 58px;
          width: 70%; }
          @media only screen and (max-width: 1370px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt .red-cnt-box {
              height: 56px; } }
          @media only screen and (max-width: 1320px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt .red-cnt-box {
              height: 56px; } }
          @media only screen and (max-width: 1310px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt .red-cnt-box {
              height: 52px; } }
          @media only screen and (max-width: 1300px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt .red-cnt-box {
              height: 54px; } }
          @media only screen and (max-width: 1280px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt .red-cnt-box {
              height: 52px; } }
          @media only screen and (max-width: 1260px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt .red-cnt-box {
              height: 51px; } }
          @media only screen and (max-width: 1200px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt .red-cnt-box {
              height: 49px;
              width: 78%; } }
          @media only screen and (max-width: 1230px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt .red-cnt-box {
              height: 50px; } }
          @media only screen and (max-width: 1180px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt .red-cnt-box {
              height: 47px; } }
          @media only screen and (max-width: 1100px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt .red-cnt-box {
              height: 45px; } }
          @media only screen and (max-width: 1030px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt .red-cnt-box {
              height: 42px;
              width: 90%; } }
          @media only screen and (max-width: 1080px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt .red-cnt-box {
              height: 43px; } }
          @media only screen and (max-width: 1045px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt .red-cnt-box {
              height: 42px; } }
          @media only screen and (max-width: 1040px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt .red-cnt-box {
              height: 41px; } }
          @media only screen and (max-width: 1024px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt .red-cnt-box {
              height: 40px; } }
          @media only screen and (max-width: 1023px) {
            .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt .red-cnt-box {
              height: auto; } }
          .home-explain-listing .tlc-explain-box .explain-content-box .stoplight-green-cnt .red-cnt-box .red-box {
            display: table-cell;
            vertical-align: middle; }

/*****************************Latest mls start***********************************/
.latest-mls-update {
  width: 100%;
  float: left;
  position: relative;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center bottom;
  /* background-image:url(../img/nd/latest-mls-bg.png); */
  position: relative;
  min-height: 550px; }
  @media only screen and (max-width: 1600px) {
    .latest-mls-update {
      min-height: 500px; } }
  @media only screen and (max-width: 1025px) {
    .latest-mls-update {
      min-height: 430px; } }
  .latest-mls-update .update-mls-text {
    padding-top: 80px; }
    .latest-mls-update .update-mls-text .update-time {
      text-align: center;
      display: block;
      width: 100%;
      margin-top: 45px; }
      .latest-mls-update .update-mls-text .update-time .large-time {
        font-family: "GothamLight";
        font-size: 108px;
        line-height: 90px;
        display: inline-block; }
        @media only screen and (max-width: 1366px) {
          .latest-mls-update .update-mls-text .update-time .large-time {
            font-size: 90px; } }
        @media only screen and (max-width: 1025px) {
          .latest-mls-update .update-mls-text .update-time .large-time {
            font-size: 70px;
            line-height: 60px; } }
      .latest-mls-update .update-mls-text .update-time .small-time {
        font-size: 30px;
        line-height: 36px;
        display: inline-block;
        vertical-align: bottom;
        padding-left: 10px; }
      .latest-mls-update .update-mls-text .update-time .small-text {
        font-size: 14px;
        margin-top: 20px;
        line-height: normal; }

/*****************************Newest Listings start***********************************/
.newest-listing-bg {
  width: 100%;
  float: left;
  background: linear-gradient(180deg, #b6ccd3 0%, rgba(220, 232, 235, 0) 100%);
  margin: -1px 0 0 0;
  padding-top: 25px; }
  @media only screen and (max-width: 630px) {
    .newest-listing-bg {
      margin: -1px 0 30px 0; } }

/*****************************What Can I Afford?***********************************/
.afford-cost-clc {
  width: 100%;
  float: left;
  padding: 90px 0 65px 0; }
  .afford-cost-clc .cost-range-text {
    margin-bottom: 20px;
    width: 100%;
    display: flex; }
  .afford-cost-clc .cost-range-box {
    margin-top: 45px;
    margin-bottom: 50px; }
    @media only screen and (max-width: 480px) {
      .afford-cost-clc .cost-range-box {
        padding: 0 15px; } }
    @media only screen and (max-width: 576px) {
      .afford-cost-clc .cost-range-box {
        padding: 0 30px;
        width: 100%; } }
    .afford-cost-clc .cost-range-box .input-range__track {
      /* background:rgba(0,173,187,0.15); */ }
      .afford-cost-clc .cost-range-box .input-range__track .input-range__label--value .input-range__label-container {
        font-family: "GothamBold";
        font-size: 18px;
        bottom: 16px; }
    @media only screen and (max-width: 767px) {
      .afford-cost-clc .cost-range-box .input-range {
        width: 90%;
        margin: 0px auto; } }
    @media only screen and (max-width: 480px) {
      .afford-cost-clc .cost-range-box .input-range {
        width: 75%; } }
    @media only screen and (max-width: 320px) {
      .afford-cost-clc .cost-range-box .input-range {
        width: 63%; } }
    .afford-cost-clc .cost-range-box .input-range .input-range__label--min .input-range__label-container {
      font-family: "GothamBold";
      font-size: 16px;
      top: 12px; }
    .afford-cost-clc .cost-range-box .input-range .input-range__label--max .input-range__label-container {
      font-family: "GothamBold";
      font-size: 16px;
      top: 12px; }
  .afford-cost-clc .cost-range-titel {
    font-size: 14px; }
  .afford-cost-clc .afford-filter .afford-input {
    margin: 25px 0 30px 0;
    min-height: 55px;
    box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07); }
    @media only screen and (max-width: 767px) {
      .afford-cost-clc .afford-filter .afford-input {
        font-size: 13px;
        min-height: 50px;
        padding: 10px 10px;
        margin: 25px 0 25px 0;
        width: 100%;
        /* width:calc(100% - 97px) */ } }
  .afford-cost-clc .afford-filter .afford-button {
    width: 152px;
    min-height: 55px;
    font-size: 17px; }
    @media only screen and (max-width: 767px) {
      .afford-cost-clc .afford-filter .afford-button {
        min-height: 50px;
        padding: 10px 10px;
        font-size: 13px;
        width: 120px; } }
  .afford-cost-clc.afford-label-amount .input-range__label-container {
    color: #707070; }

/*****************************Explore Neighborhoods start***********************************/
.home-neighborhood {
  width: 100%;
  display: inline-block;
  background: #ffffff;
  padding: 40px 0 60px 0; }
  .home-neighborhood .container {
    max-width: 100%;
    padding: 0 50px; }
    @media only screen and (max-width: 1025px) {
      .home-neighborhood .container {
        padding: 0 20px; } }
    @media only screen and (max-width: 350px) {
      .home-neighborhood .container {
        padding: 0 15px; } }
  .home-neighborhood .neighborhood-listing {
    width: 100%;
    float: left;
    margin-top: 30px; }
    @media only screen and (max-width: 320px) {
      .home-neighborhood .neighborhood-listing {
        width: 298px; } }
    .home-neighborhood .neighborhood-listing .neighborhood-scroll {
      /* @include breakpointMax(576) {
				height:220px;
			} */ }
      @media only screen and (max-width: 630px) {
        .home-neighborhood .neighborhood-listing .neighborhood-scroll {
          max-width: 600px;
          overflow-x: scroll;
          overflow-y: hidden;
          /* height:240px; */
          white-space: nowrap;
          margin-right: -15px;
          padding-left: 0px; } }
      @media only screen and (max-width: 320px) {
        .home-neighborhood .neighborhood-listing .neighborhood-scroll {
          margin-right: -7px; } }
    .home-neighborhood .neighborhood-listing .neighborhood-prop {
      width: 25%;
      float: left;
      padding: 0 0 30px 30px; }
      @media only screen and (max-width: 1280px) {
        .home-neighborhood .neighborhood-listing .neighborhood-prop {
          width: 33.33%;
          padding: 0 15px 40px 15px; } }
      @media only screen and (max-width: 1025px) {
        .home-neighborhood .neighborhood-listing .neighborhood-prop {
          width: 33%; } }
      @media only screen and (max-width: 800px) {
        .home-neighborhood .neighborhood-listing .neighborhood-prop {
          width: 50%; } }
      @media only screen and (max-width: 630px) {
        .home-neighborhood .neighborhood-listing .neighborhood-prop {
          width: 500px;
          float: none;
          display: inline-block;
          vertical-align: top; } }
      @media only screen and (max-width: 576px) {
        .home-neighborhood .neighborhood-listing .neighborhood-prop {
          width: 300px; } }
      @media only screen and (max-width: 320px) {
        .home-neighborhood .neighborhood-listing .neighborhood-prop {
          width: 280px; } }
      .home-neighborhood .neighborhood-listing .neighborhood-prop:first-child {
        width: 50%;
        float: left;
        padding: 0 0 30px 0px; }
        @media only screen and (max-width: 1280px) {
          .home-neighborhood .neighborhood-listing .neighborhood-prop:first-child {
            width: 33.33%;
            padding: 0 15px 40px 15px; } }
        @media only screen and (max-width: 1025px) {
          .home-neighborhood .neighborhood-listing .neighborhood-prop:first-child {
            width: 33%; } }
        @media only screen and (max-width: 800px) {
          .home-neighborhood .neighborhood-listing .neighborhood-prop:first-child {
            width: 50%; } }
        @media only screen and (max-width: 630px) {
          .home-neighborhood .neighborhood-listing .neighborhood-prop:first-child {
            width: 500px;
            float: none;
            display: inline-block;
            vertical-align: top; } }
        @media only screen and (max-width: 576px) {
          .home-neighborhood .neighborhood-listing .neighborhood-prop:first-child {
            width: 300px; } }
        @media only screen and (max-width: 320px) {
          .home-neighborhood .neighborhood-listing .neighborhood-prop:first-child {
            width: 280px; } }
        .home-neighborhood .neighborhood-listing .neighborhood-prop:first-child .neighborhood-card .slider-img-box .slider-img {
          min-height: 358px; }
          @media only screen and (max-width: 1280px) {
            .home-neighborhood .neighborhood-listing .neighborhood-prop:first-child .neighborhood-card .slider-img-box .slider-img {
              min-height: 135px; } }
          @media only screen and (max-width: 630px) {
            .home-neighborhood .neighborhood-listing .neighborhood-prop:first-child .neighborhood-card .slider-img-box .slider-img {
              min-height: 160px; } }
          @media only screen and (max-width: 576px) {
            .home-neighborhood .neighborhood-listing .neighborhood-prop:first-child .neighborhood-card .slider-img-box .slider-img {
              min-height: 135px; } }
          .home-neighborhood .neighborhood-listing .neighborhood-prop:first-child .neighborhood-card .slider-img-box .slider-img img {
            min-height: 365px; }
            @media only screen and (max-width: 1280px) {
              .home-neighborhood .neighborhood-listing .neighborhood-prop:first-child .neighborhood-card .slider-img-box .slider-img img {
                min-height: 135px; } }
            @media only screen and (max-width: 630px) {
              .home-neighborhood .neighborhood-listing .neighborhood-prop:first-child .neighborhood-card .slider-img-box .slider-img img {
                min-height: 160px; } }
            @media only screen and (max-width: 576px) {
              .home-neighborhood .neighborhood-listing .neighborhood-prop:first-child .neighborhood-card .slider-img-box .slider-img img {
                min-height: 135px; } }
    .home-neighborhood .neighborhood-listing .neighborhood-card {
      background-color: #ffffff;
      box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
      width: 100%;
      display: inline-block;
      vertical-align: top;
      padding: 0;
      border-radius: 8px; }
      .home-neighborhood .neighborhood-listing .neighborhood-card .slider-img-box {
        width: 100%;
        float: left;
        position: relative; }
        .home-neighborhood .neighborhood-listing .neighborhood-card .slider-img-box .slider-img {
          width: 100%;
          display: block;
          position: relative;
          overflow: hidden;
          min-height: 135px;
          background: #ffffff;
          border-radius: 8px 8px 0 0; }
          @media only screen and (max-width: 630px) {
            .home-neighborhood .neighborhood-listing .neighborhood-card .slider-img-box .slider-img {
              min-height: 160px; } }
          @media only screen and (max-width: 576px) {
            .home-neighborhood .neighborhood-listing .neighborhood-card .slider-img-box .slider-img {
              min-height: 135px; } }
          .home-neighborhood .neighborhood-listing .neighborhood-card .slider-img-box .slider-img img {
            width: 100%;
            position: absolute;
            height: auto;
            margin: auto;
            top: -100%;
            right: -100%;
            bottom: -100%;
            left: -100%;
            min-height: 135px; }
      .home-neighborhood .neighborhood-listing .neighborhood-card .neighborhood-name {
        width: 100%;
        float: left;
        padding: 20px 14px 20px 14px;
        text-align: left;
        font-size: 15px;
        height: 58px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: normal; }

/**************Explore Neighborhoods*****************/
.home-realtor {
  width: 100%;
  float: left;
  background: #ffffff;
  padding: 40px 0 60px 0; }
  .home-realtor .container {
    max-width: 100%;
    padding: 0 50px; }
    @media only screen and (max-width: 1025px) {
      .home-realtor .container {
        padding: 0 20px; } }
    @media only screen and (max-width: 350px) {
      .home-realtor .container {
        padding: 0 15px; } }
    .home-realtor .container .realtor-card {
      background-color: #ffffff;
      box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
      width: 100%;
      display: inline-block;
      vertical-align: top;
      padding: 0;
      border-radius: 8px;
      margin-bottom: 16px; }
      .home-realtor .container .realtor-card .slider-img-box {
        width: 100%;
        float: left;
        position: relative; }
        .home-realtor .container .realtor-card .slider-img-box .slider-img {
          width: 100%;
          display: block;
          position: relative;
          overflow: hidden;
          min-height: 358px;
          background: #ffffff;
          border-radius: 8px; }
          @media only screen and (max-width: 1280px) {
            .home-realtor .container .realtor-card .slider-img-box .slider-img {
              min-height: 135px; } }
          @media only screen and (max-width: 630px) {
            .home-realtor .container .realtor-card .slider-img-box .slider-img {
              min-height: 160px; } }
          @media only screen and (max-width: 576px) {
            .home-realtor .container .realtor-card .slider-img-box .slider-img {
              min-height: 135px; } }
          .home-realtor .container .realtor-card .slider-img-box .slider-img img {
            width: 100%;
            min-height: 365px; }
            @media only screen and (max-width: 1280px) {
              .home-realtor .container .realtor-card .slider-img-box .slider-img img {
                min-height: 135px; } }
            @media only screen and (max-width: 630px) {
              .home-realtor .container .realtor-card .slider-img-box .slider-img img {
                min-height: 160px; } }
            @media only screen and (max-width: 576px) {
              .home-realtor .container .realtor-card .slider-img-box .slider-img img {
                min-height: 135px; } }
    .home-realtor .container .realtor-sub-text {
      display: inline-block;
      width: calc(100% - 75px);
      vertical-align: top;
      text-align: left;
      margin-bottom: 36px; }
    .home-realtor .container .realtor-sub-img {
      display: inline-block;
      width: 75px;
      vertical-align: top;
      text-align: right;
      padding-top: 14px;
      margin-bottom: 36px; }
      .home-realtor .container .realtor-sub-img img {
        width: 55px;
        height: auto; }
    .home-realtor .container .realtor-sub-btn .realtor-button {
      width: 152px;
      min-height: 55px;
      font-size: 17px; }
      @media only screen and (max-width: 767px) {
        .home-realtor .container .realtor-sub-btn .realtor-button {
          min-height: 50px;
          padding: 10px 10px;
          font-size: 13px;
          width: 120px; } }

.home-optimalb {
  width: 100%;
  float: left;
  padding: 45px 0 45px 0; }

/****************************************************Neighborhoods Full page*******************************/
.neighborhood-full-page {
  /**************Neighborhoods top spaces start*****************/
  /**************Neighborhoods top spaces End*****************/
  /**************Neighborhoods comm changes start*****************/
  /**************Neighborhoods shortening list start*****************/
  /**************Neighborhoods shortening list start*****************/ }
  .neighborhood-full-page .neighborhood-top-pd {
    padding-top: 110px; }
    @media only screen and (max-width: 1024px) {
      .neighborhood-full-page .neighborhood-top-pd {
        padding-top: 80px; } }
    @media only screen and (max-width: 767px) {
      .neighborhood-full-page .neighborhood-top-pd {
        padding-top: 50px; } }
  .neighborhood-full-page .home-neighborhood {
    padding: 0px;
    margin: 90px 0 0 0;
    background: none; }
    @media only screen and (max-width: 1024px) {
      .neighborhood-full-page .home-neighborhood {
        margin: 40px 0 0 0; } }
  .neighborhood-full-page .neighborhood-listing {
    margin-top: 0px;
    /**************Neighborhoods 1 full column start*****************/
    /**************Neighborhoods 2 column start*****************/ }
    .neighborhood-full-page .neighborhood-listing:nth-child(odd) .neighborhood-prop {
      padding: 0 0 30px 30px; }
      @media only screen and (max-width: 1280px) {
        .neighborhood-full-page .neighborhood-listing:nth-child(odd) .neighborhood-prop {
          padding: 0 15px 40px 15px; } }
      .neighborhood-full-page .neighborhood-listing:nth-child(odd) .neighborhood-prop:first-child {
        float: left;
        padding: 0 0 30px 0px; }
        @media only screen and (max-width: 1280px) {
          .neighborhood-full-page .neighborhood-listing:nth-child(odd) .neighborhood-prop:first-child {
            padding: 0 15px 40px 15px; } }
    .neighborhood-full-page .neighborhood-listing:nth-child(even) .neighborhood-prop {
      padding: 0 30px 30px 0; }
      @media only screen and (max-width: 1280px) {
        .neighborhood-full-page .neighborhood-listing:nth-child(even) .neighborhood-prop {
          padding: 0 15px 40px 15px; } }
      .neighborhood-full-page .neighborhood-listing:nth-child(even) .neighborhood-prop:first-child {
        float: right;
        padding: 0 0 30px 30px; }
        @media only screen and (max-width: 1280px) {
          .neighborhood-full-page .neighborhood-listing:nth-child(even) .neighborhood-prop:first-child {
            padding: 0 15px 40px 15px;
            float: left; } }
      .neighborhood-full-page .neighborhood-listing:nth-child(even) .neighborhood-prop:nth-child(2) {
        margin-left: -15px;
        padding: 0 15px 30px 15px; }
        @media only screen and (max-width: 1280px) {
          .neighborhood-full-page .neighborhood-listing:nth-child(even) .neighborhood-prop:nth-child(2) {
            margin-left: 0px;
            padding: 0 15px 40px 15px; } }
      .neighborhood-full-page .neighborhood-listing:nth-child(even) .neighborhood-prop:nth-child(3) {
        margin-right: 0px;
        float: right;
        padding-right: 0;
        padding-left: 0px; }
        @media only screen and (max-width: 1280px) {
          .neighborhood-full-page .neighborhood-listing:nth-child(even) .neighborhood-prop:nth-child(3) {
            float: left;
            padding-right: 15px;
            padding-left: 15px; } }
        @media only screen and (max-width: 630px) {
          .neighborhood-full-page .neighborhood-listing:nth-child(even) .neighborhood-prop:nth-child(3) {
            float: none; } }
      .neighborhood-full-page .neighborhood-listing:nth-child(even) .neighborhood-prop:nth-child(4) {
        margin-left: -15px;
        padding: 0 15px 30px 15px; }
        @media only screen and (max-width: 1280px) {
          .neighborhood-full-page .neighborhood-listing:nth-child(even) .neighborhood-prop:nth-child(4) {
            margin-left: 0px;
            padding: 0 15px 40px 15px; } }
      .neighborhood-full-page .neighborhood-listing:nth-child(even) .neighborhood-prop:nth-child(5) {
        margin-right: 0px;
        float: right;
        padding-right: 0px;
        padding-left: 0px; }
        @media only screen and (max-width: 1280px) {
          .neighborhood-full-page .neighborhood-listing:nth-child(even) .neighborhood-prop:nth-child(5) {
            float: left;
            padding-right: 15px;
            padding-left: 15px; } }
        @media only screen and (max-width: 630px) {
          .neighborhood-full-page .neighborhood-listing:nth-child(even) .neighborhood-prop:nth-child(5) {
            float: none; } }
  .neighborhood-full-page .all-neighborhood-list {
    width: 50%;
    display: inline-block;
    vertical-align: middle; }
    @media only screen and (max-width: 1024px) {
      .neighborhood-full-page .all-neighborhood-list {
        width: 80%; } }
    @media only screen and (max-width: 767px) {
      .neighborhood-full-page .all-neighborhood-list {
        width: 100%; } }
    .neighborhood-full-page .all-neighborhood-list h4 {
      font-size: 14px; }
    .neighborhood-full-page .all-neighborhood-list ul {
      margin: 35px 0 0 0;
      padding: 0px;
      width: 100%;
      display: block;
      text-align: left; }
    .neighborhood-full-page .all-neighborhood-list li {
      list-style: none;
      width: 33%;
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 15px;
      text-align: center; }
      @media only screen and (max-width: 767px) {
        .neighborhood-full-page .all-neighborhood-list li {
          width: 50%; } }
      .neighborhood-full-page .all-neighborhood-list li a {
        font-size: 14px; }

/**************Neighborhoods page*****************/
/*****************************home page  End**********************************************************/
/*********************pagination start*************/
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 0;
  border-radius: 4px; }
  .pagination li {
    display: inline-block; }
    .pagination li a {
      position: relative;
      float: none;
      display: inline-block;
      padding: 0px 8px;
      line-height: normal;
      text-decoration: none;
      color: #9b9b9b;
      background: #fff;
      border: none;
      margin-left: -1px; }
    .pagination li.active a, .pagination li:hover a {
      font-weight: bold; }

/*********Find office Result page  End**********/
/*****************************Find Agent Result page  End**********************************************************/
/*****************************Agent detail page Start**********************************************************/
/**********Agent detail info start**********/
.agent-detail-bg {
  width: 100%;
  display: block;
  background: #ffffff;
  padding: 50px 0 45px 0;
  margin-bottom: 40px; }
  @media only screen and (max-width: 1190px) {
    .agent-detail-bg {
      padding: 35px 0 35px 0; } }
  .agent-detail-bg .detail-cnt-view {
    padding: 0 20px; }
  .agent-detail-bg .container {
    padding: 0 0 0 25px;
    max-width: 100%; }
    @media only screen and (max-width: 1366px) {
      .agent-detail-bg .container {
        padding: 0; } }
    @media only screen and (max-width: 768px) {
      .agent-detail-bg .container {
        padding: 0 15px 0 15px; } }
  .agent-detail-bg .profile-agent-photo {
    border-radius: 5px;
    position: relative;
    border: 1px solid #ddd;
    background: #f7f7f7;
    overflow: hidden;
    height: 200px;
    width: 200px;
    display: inline-block; }
    @media only screen and (max-width: 1450px) {
      .agent-detail-bg .profile-agent-photo {
        height: 190px;
        width: 190px; } }
    @media only screen and (max-width: 1350px) {
      .agent-detail-bg .profile-agent-photo {
        height: 180px;
        width: 180px; } }
    @media only screen and (max-width: 1280px) {
      .agent-detail-bg .profile-agent-photo {
        height: 170px;
        width: 170px; } }
    @media only screen and (max-width: 1199px) {
      .agent-detail-bg .profile-agent-photo {
        height: 200px;
        width: 200px; } }
    @media only screen and (max-width: 991px) {
      .agent-detail-bg .profile-agent-photo {
        height: 180px;
        width: 180px; } }
    @media only screen and (max-width: 880px) {
      .agent-detail-bg .profile-agent-photo {
        height: 150px;
        width: 150px; } }
    @media only screen and (max-width: 767px) {
      .agent-detail-bg .profile-agent-photo {
        width: 240px;
        height: 240px;
        margin-bottom: 20px; } }
    .agent-detail-bg .profile-agent-photo img {
      border-radius: 5px;
      object-fit: cover;
      min-height: 100%;
      width: auto;
      max-width: 100%; }
    .agent-detail-bg .profile-agent-photo .edit-photo-btn {
      color: #ffffff;
      position: absolute;
      bottom: -5px;
      right: -5px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      border-radius: 50%;
      font-size: 18px;
      cursor: pointer;
      text-align: center; }
      @media only screen and (max-width: 1199px) {
        .agent-detail-bg .profile-agent-photo .edit-photo-btn {
          width: 25px;
          height: 25px;
          line-height: 25px;
          font-size: 15px; } }
      @media only screen and (max-width: 1000px) {
        .agent-detail-bg .profile-agent-photo .edit-photo-btn {
          width: 20px;
          height: 20px;
          line-height: 20px;
          font-size: 13px; } }
    .agent-detail-bg .profile-agent-photo.edit-photo-box {
      background: #d8d8d8;
      width: 100%;
      display: table;
      text-align: center;
      min-height: 190px; }
      @media only screen and (max-width: 1024px) {
        .agent-detail-bg .profile-agent-photo.edit-photo-box {
          min-height: 120px; } }
      @media only screen and (max-width: 767px) {
        .agent-detail-bg .profile-agent-photo.edit-photo-box {
          width: 200px; } }
      .agent-detail-bg .profile-agent-photo.edit-photo-box .profile-st-box {
        width: 100%;
        position: relative;
        display: table-cell;
        vertical-align: middle;
        text-align: center; }
        .agent-detail-bg .profile-agent-photo.edit-photo-box .profile-st-box .profile-st-names {
          font-size: 35px;
          width: 100px;
          height: 100px;
          line-height: 100px;
          background: #f1f1f1;
          text-transform: uppercase;
          border: 1px solid #fff;
          border-radius: 100%;
          margin: 0px auto; }
          @media only screen and (max-width: 1199px) {
            .agent-detail-bg .profile-agent-photo.edit-photo-box .profile-st-box .profile-st-names {
              width: 80px;
              height: 80px;
              line-height: 80px;
              font-size: 25px; } }
          @media only screen and (max-width: 1024px) {
            .agent-detail-bg .profile-agent-photo.edit-photo-box .profile-st-box .profile-st-names {
              width: 60px;
              height: 60px;
              line-height: 60px;
              font-size: 20px; } }
          @media only screen and (max-width: 767px) {
            .agent-detail-bg .profile-agent-photo.edit-photo-box .profile-st-box .profile-st-names {
              width: 70px;
              height: 70px;
              line-height: 70px;
              font-size: 23px; } }
    .agent-detail-bg .profile-agent-photo .profile-st-box {
      display: none; }
  .agent-detail-bg .agent-biodata-left {
    display: block;
    width: 100%;
    /*************Agent content start********/
    /*************Agent content End********/
    /*************Specialties list start*********/
    /****************************Specialties list end******************/ }
    .agent-detail-bg .agent-biodata-left h2 {
      font-size: 26px; }
      @media only screen and (max-width: 1190px) {
        .agent-detail-bg .agent-biodata-left h2 {
          font-size: 22px; } }
      @media only screen and (max-width: 1000px) {
        .agent-detail-bg .agent-biodata-left h2 {
          font-size: 20px; } }
      @media only screen and (max-width: 900px) {
        .agent-detail-bg .agent-biodata-left h2 {
          font-size: 18px; } }
    .agent-detail-bg .agent-biodata-left h3 {
      font-size: 16px;
      margin-top: 13px;
      margin-bottom: 13px;
      color: #657388; }
      @media only screen and (max-width: 1190px) {
        .agent-detail-bg .agent-biodata-left h3 {
          font-size: 15px; } }
      @media only screen and (max-width: 900px) {
        .agent-detail-bg .agent-biodata-left h3 {
          font-size: 14px; } }
    .agent-detail-bg .agent-biodata-left .agent-small-ct {
      line-height: 22px;
      font-size: 14px;
      padding-top: 10px; }
      @media only screen and (max-width: 1400px) {
        .agent-detail-bg .agent-biodata-left .agent-small-ct {
          font-size: 13px; } }
    .agent-detail-bg .agent-biodata-left .agent-specialties-list {
      width: 100%;
      display: block; }
      .agent-detail-bg .agent-biodata-left .agent-specialties-list ul {
        margin: 10px 0 0 0;
        padding: 0px; }
        .agent-detail-bg .agent-biodata-left .agent-specialties-list ul li {
          list-style: none;
          display: inline-block;
          padding: 0 10px 12px 0;
          font-size: 14px;
          line-height: 20px;
          width: 40%;
          vertical-align: top;
          word-break: break-all; }
          @media only screen and (max-width: 1199px) {
            .agent-detail-bg .agent-biodata-left .agent-specialties-list ul li {
              width: 50%; } }
          @media only screen and (max-width: 991px) {
            .agent-detail-bg .agent-biodata-left .agent-specialties-list ul li {
              width: 100%;
              padding: 0 0px 15px 0; } }
          .agent-detail-bg .agent-biodata-left .agent-specialties-list ul li.full-specialties {
            width: 100%;
            display: block;
            padding: 0 0 12px 0; }
          .agent-detail-bg .agent-biodata-left .agent-specialties-list ul li .agent-profile-lable {
            width: 120px;
            display: inline-block;
            vertical-align: top; }
          .agent-detail-bg .agent-biodata-left .agent-specialties-list ul li .agent-profile-lable-ct {
            width: calc(100% - 120px);
            display: inline-block;
            vertical-align: top; }
            .agent-detail-bg .agent-biodata-left .agent-specialties-list ul li .agent-profile-lable-ct .material-icons {
              vertical-align: middle; }
          .agent-detail-bg .agent-biodata-left .agent-specialties-list ul li .material-icons {
            font-size: 15px; }
  .agent-detail-bg .professional-infobox {
    width: 100%;
    float: left;
    border-radius: 5px;
    padding: 24px 30px;
    font-size: 14px;
    word-break: break-word;
    line-height: 20px;
    /*************Contact Agent/ tour button start********/
    /*************Contact Agent/ tour button End********/ }
    .agent-detail-bg .professional-infobox a {
      font-size: 14px; }
    @media only screen and (max-width: 1400px) {
      .agent-detail-bg .professional-infobox {
        font-size: 13px; }
        .agent-detail-bg .professional-infobox a {
          font-size: 13px; } }
    @media only screen and (max-width: 1025px) {
      .agent-detail-bg .professional-infobox {
        padding: 15px 15px; } }
    @media only screen and (max-width: 767px) {
      .agent-detail-bg .professional-infobox {
        margin-top: 25px; } }
    .agent-detail-bg .professional-infobox .theme-btn {
      width: 100%; }
      @media only screen and (max-width: 990px) {
        .agent-detail-bg .professional-infobox .theme-btn {
          font-size: 12px;
          padding: 10px; } }
      @media only screen and (max-width: 767px) {
        .agent-detail-bg .professional-infobox .theme-btn {
          padding: 10px 10px;
          font-size: 13px; } }
    .agent-detail-bg .professional-infobox .theme-white-btn {
      width: 100%;
      padding: 9px 15px; }
      @media only screen and (max-width: 990px) {
        .agent-detail-bg .professional-infobox .theme-white-btn {
          font-size: 12px;
          padding: 9px 10px; } }
      @media only screen and (max-width: 767px) {
        .agent-detail-bg .professional-infobox .theme-white-btn {
          padding: 9px 10px;
          font-size: 13px; } }
  .agent-detail-bg .small-sub-title {
    font-size: 14px;
    padding-bottom: 7px; }

/**********Agent detail info End**********/
/**********Agent detail Reviews/Current Listings**********/
.agent-listing-info {
  width: 100%;
  display: block;
  padding: 0;
  margin-bottom: 30px;
  /**********Agent detail Reviews/Current Listings pagination start**********/
  /**********Agent detail Reviews/Current Listings pagination end**********/ }
  .agent-listing-info .recent-list {
    width: 100%;
    float: left;
    background: #ffffff;
    box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
    padding: 32px 40px 32px 40px;
    border-radius: 8px; }
    @media only screen and (max-width: 1366px) {
      .agent-listing-info .recent-list {
        padding: 32px 25px 32px 25px; } }
    @media only screen and (max-width: 1199px) {
      .agent-listing-info .recent-list {
        margin-bottom: 30px;
        padding: 32px 15px 32px 15px; } }
    @media only screen and (max-width: 767px) {
      .agent-listing-info .recent-list {
        padding: 20px 15px 20px 15px; } }
    @media only screen and (max-width: 576px) {
      .agent-listing-info .recent-list {
        padding: 15px 15px 15px 15px; } }
    .agent-listing-info .recent-list h3 {
      color: #657388;
      font-size: 20px; }
      @media only screen and (max-width: 767px) {
        .agent-listing-info .recent-list h3 {
          font-size: 18px; } }
  .agent-listing-info .tab-list {
    margin-top: 6px; }
  .agent-listing-info .horizontal-prop-card {
    width: 100%; }
  .agent-listing-info .listing-property-view {
    width: 100%;
    padding: 0 20px;
    margin-top: 10px; }
  .agent-listing-info .pagination-reviews {
    margin-top: 10px;
    width: 100%;
    float: left;
    text-align: right; }

.agent-other-info {
  width: 100%;
  float: left;
  padding: 0;
  margin-bottom: 30px; }
  .agent-other-info .container {
    padding: 0 50px;
    max-width: 100%; }
    @media only screen and (max-width: 1230px) {
      .agent-other-info .container {
        padding: 0 38px; } }
    @media only screen and (max-width: 1190px) {
      .agent-other-info .container {
        padding: 0 50px 0 50px; } }
    @media only screen and (max-width: 1000px) {
      .agent-other-info .container {
        padding: 0 30px 0 30px; } }
    @media only screen and (max-width: 768px) {
      .agent-other-info .container {
        padding: 0 20px 0 20px; } }
    @media only screen and (max-width: 576px) {
      .agent-other-info .container {
        padding: 0 15px 0 15px; } }
  .agent-other-info .recent-list {
    width: 100%;
    float: left;
    background: #ffffff;
    box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
    padding: 40px;
    border-radius: 8px; }
    @media only screen and (max-width: 1366px) {
      .agent-other-info .recent-list {
        padding: 32px 25px 32px 25px; } }
    @media only screen and (max-width: 1199px) {
      .agent-other-info .recent-list {
        margin-bottom: 30px;
        padding: 32px 15px 32px 15px; } }
    @media only screen and (max-width: 767px) {
      .agent-other-info .recent-list {
        padding: 20px 15px 20px 15px; } }
    @media only screen and (max-width: 576px) {
      .agent-other-info .recent-list {
        padding: 15px 15px 15px 15px; } }
    .agent-other-info .recent-list h3 {
      color: #657388;
      font-size: 20px; }
      @media only screen and (max-width: 767px) {
        .agent-other-info .recent-list h3 {
          font-size: 18px; } }
  .agent-other-info .pagination-reviews {
    margin-top: 10px;
    width: 100%;
    float: left;
    text-align: right; }

/*****************************Agent detail page End**********************************************************/
/***************************************modal start*****************************************/
@media only screen and (max-width: 1023px) {
  .modal-dialog.modal-95w {
    width: 95%;
    max-width: none !important; } }

@media only screen and (max-width: 767px) {
  .modal-dialog.modal-95w {
    width: auto;
    max-width: 100% !important; } }

.modal-content {
  border-radius: 8px;
  border: none;
  padding: 30px 35px;
  font-size: 14px; }
  @media only screen and (max-width: 768px) {
    .modal-content {
      padding: 20px 25px; } }
  .modal-content .modal-header {
    border-bottom: none;
    padding: 0px;
    margin: -5px 0px 0px 0px;
    padding-right: 10px;
    font-family: "GothamBook";
    display: inline-block; }
    .modal-content .modal-header.header-border {
      border-bottom: 1px solid #dddddd;
      padding-bottom: 10px; }
    .modal-content .modal-header h1 {
      font-size: 28px;
      font-family: "GothamBook"; }
      @media only screen and (max-width: 1024px) {
        .modal-content .modal-header h1 {
          font-size: 25px; } }
    .modal-content .modal-header h2 {
      font-size: 20px;
      font-family: "GothamBook"; }
      @media only screen and (max-width: 767px) {
        .modal-content .modal-header h2 {
          font-size: 18px; } }
    .modal-content .modal-header .modal-title {
      padding-right: 15px; }
  .modal-content .modal-body {
    padding: 10px 0 0 0;
    font-family: "GothamBook";
    /* browse photo */ }
    .modal-content .modal-body [type="text"] {
      font-family: "GothamBook"; }
    .modal-content .modal-body .typeahead-search .form-control {
      font-family: "RobotoRegular";
      font-size: 13px; }
    .modal-content .modal-body h2 {
      font-size: 18px;
      font-family: "GothamBook"; }
    .modal-content .modal-body label {
      font-size: 13px;
      color: #5a687b;
      font-weight: normal; }
    .modal-content .modal-body .sub-label {
      font-family: "GothamLight"; }
    .modal-content .modal-body .modal-small-title {
      font-size: 13px;
      font-family: "GothamMedium"; }
    .modal-content .modal-body .payment-cost {
      width: 100px;
      display: inline-block;
      vertical-align: middle;
      font-size: 13px; }
      .modal-content .modal-body .payment-cost .cost-input {
        padding: 5px 7px;
        min-height: 33px; }
      .modal-content .modal-body .payment-cost.extra-long-cost {
        width: 180px; }
      .modal-content .modal-body .payment-cost .harf-edit {
        width: calc(100% - 50px);
        display: inline-block;
        vertical-align: middle; }
        .modal-content .modal-body .payment-cost .harf-edit.dp-amt {
          width: calc(100% - 62px); }
        .modal-content .modal-body .payment-cost .harf-edit .cost-input {
          border-radius: 4px 0 0px 4px; }
      .modal-content .modal-body .payment-cost .harf-select {
        width: 50px;
        display: inline-block;
        vertical-align: middle; }
        .modal-content .modal-body .payment-cost .harf-select .type-select {
          margin-left: -2px; }
          .modal-content .modal-body .payment-cost .harf-select .type-select .react-select__control {
            min-height: 33px;
            border-radius: 0 4px 4px 0; }
            .modal-content .modal-body .payment-cost .harf-select .type-select .react-select__control .react-select__single-value {
              max-width: 100%; }
            .modal-content .modal-body .payment-cost .harf-select .type-select .react-select__control .react-select__value-container {
              padding: 0px 5px;
              height: 31px; }
            .modal-content .modal-body .payment-cost .harf-select .type-select .react-select__control .css-b8ldur-Input {
              padding: 0px;
              margin: 0px; }
            .modal-content .modal-body .payment-cost .harf-select .type-select .react-select__control .react-select__indicator:after {
              display: none; }
        .modal-content .modal-body .payment-cost .harf-select.dp-per {
          width: 62px;
          position: relative; }
          .modal-content .modal-body .payment-cost .harf-select.dp-per .post-fix {
            position: absolute;
            right: 8px;
            top: 9px; }
    .modal-content .modal-body .available-cost {
      width: 100%;
      border-top: 1px solid #dddddd;
      display: block;
      padding-top: 15px; }
    .modal-content .modal-body .browse-photo-content {
      font-size: 15px;
      width: 220px;
      display: inline-block;
      vertical-align: top; }
      .modal-content .modal-body .browse-photo-content .browse-photo {
        padding: 0;
        font-size: 12px; }
    .modal-content .modal-body .browse-photo-upload {
      width: 290px;
      display: block;
      height: 190px;
      margin-top: 15px; }
      .modal-content .modal-body .browse-photo-upload.browse-agent-photo {
        width: 190px;
        height: 190px; }
        .modal-content .modal-body .browse-photo-upload.browse-agent-photo img {
          width: 190px;
          height: 190px; }
      .modal-content .modal-body .browse-photo-upload.browse-home-banner-ads {
        width: 300px;
        height: 50px; }
        .modal-content .modal-body .browse-photo-upload.browse-home-banner-ads img {
          width: 300px;
          height: 50px; }
      .modal-content .modal-body .browse-photo-upload.browse-property-card-ads {
        width: 290px;
        height: 290px; }
        .modal-content .modal-body .browse-photo-upload.browse-property-card-ads img {
          width: 290px;
          height: 290px; }
      .modal-content .modal-body .browse-photo-upload.browse-photo-ads-upload {
        width: 100%;
        height: 50px; }
        .modal-content .modal-body .browse-photo-upload.browse-photo-ads-upload img {
          width: 100%;
          height: 50px; }
        .modal-content .modal-body .browse-photo-upload.browse-photo-ads-upload.ad-300x50 {
          width: 300px;
          height: 50px; }
        .modal-content .modal-body .browse-photo-upload.browse-photo-ads-upload.ad-290x290 {
          width: 290px;
          height: 290px; }
        .modal-content .modal-body .browse-photo-upload.browse-photo-ads-upload.ad-728x90 {
          width: 350px;
          height: 44px; }
        .modal-content .modal-body .browse-photo-upload.browse-photo-ads-upload.ad-700x90 {
          width: 350px;
          height: 45px; }
        .modal-content .modal-body .browse-photo-upload.browse-photo-ads-upload.ad-921x450 {
          width: 350px;
          height: 172px; }
      .modal-content .modal-body .browse-photo-upload.browse-mortgage-card-ads {
        width: 278px;
        height: 220px; }
        .modal-content .modal-body .browse-photo-upload.browse-mortgage-card-ads img {
          width: 278px;
          height: 220px; }
      .modal-content .modal-body .browse-photo-upload.browse-large-banner-card-ads {
        width: 100%;
        height: 200px; }
        .modal-content .modal-body .browse-photo-upload.browse-large-banner-card-ads img {
          width: 100%;
          height: 200px; }
    .modal-content .modal-body .modal-short-description {
      font-size: 14px;
      line-height: 22px;
      display: inline-block; }
    .modal-content .modal-body .tlc-calculat-edit lable {
      color: #5a687b; }
    .modal-content .modal-body .tlc-calculat-edit .est-cost {
      color: #9b9b9b; }
  .modal-content .modal-footer {
    border-top: none;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    width: 100%;
    display: block; }
    .modal-content .modal-footer .theme-btn {
      padding: 10px 15px;
      min-width: 80px; }
    .modal-content .modal-footer.custom-footer {
      display: block;
      margin-top: 10px; }
      .modal-content .modal-footer.custom-footer > *.row {
        margin-left: -15px;
        margin-right: -15px; }
  .modal-content .close {
    margin: 0;
    position: absolute;
    top: 13px;
    right: 18px;
    font-weight: normal;
    width: 30px;
    height: 30px;
    background: #ffffff;
    padding: 0px;
    border-radius: 100%;
    opacity: 1 !important;
    font-size: 25px;
    box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
    line-height: 30px; }

.office-send-modal .office-send-message label {
  font-family: "GothamBold"; }

/**********modal End**********/
/***********************************************Add Client**************************************************/
.add-client-form {
  width: 600px;
  margin: 0px auto;
  float: none; }
  @media only screen and (max-width: 650px) {
    .add-client-form {
      width: 100%;
      margin: 0px;
      float: left; } }
  .add-client-form .client-add-card {
    background: #fff;
    border-radius: 8px;
    padding: 20px 20px 20px 20px;
    width: 100%;
    float: left;
    box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07); }
    .add-client-form .client-add-card h2 {
      font-size: 20px;
      margin-bottom: 30px; }
  .add-client-form label {
    font-size: 13px;
    display: block;
    margin-bottom: 7px; }
  .add-client-form .addclient-btn {
    margin: 0 5px; }
    @media only screen and (max-width: 480px) {
      .add-client-form .addclient-btn {
        margin: 7px 8px; } }

.myoffers_button {
  position: fixed;
  left: 10px;
  bottom: 60px;
  cursor: pointer;
  z-index: 9999;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2); }
  .myoffers_button img {
    width: 80px; }
  @media only screen and (max-width: 1024px) {
    .myoffers_button {
      bottom: 10px;
      z-index: 999999; } }

/**********error-tooltip Start**********/
.error-tooltip-box {
  width: 360px;
  background: #ffffff !important;
  border: 2px solid #ff0000 !important;
  color: #033258 !important;
  line-height: 22px;
  border-radius: 6px;
  opacity: 1 !important;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .error-tooltip-box {
      width: 280px; } }
  .error-tooltip-box:after {
    position: absolute;
    content: "";
    bottom: 26px;
    border-top: 8px solid #ffffff !important;
    border-width: 8px 8px 0 8px; }
  .error-tooltip-box:before {
    border-top: 8px solid #ff0000 !important;
    border-width: 8px 8px 0 8px; }
  .error-tooltip-box.warning {
    border: 2px solid #ce8530 !important; }
    .error-tooltip-box.warning:before {
      border-top: 8px solid #ce8530 !important;
      border-width: 8px 8px 0 8px; }

/**********error-tooltip End**********/
/****************************************tlc calculat Edit start******************************************/
.tlc-calculat-edit {
  width: 100%;
  display: block;
  background: #f8f7f6;
  border-radius: 8px;
  padding: 24px;
  min-height: 950px; }
  .tlc-calculat-edit.new-tlc {
    min-height: 750px; }
  @media only screen and (max-width: 991px) {
    .tlc-calculat-edit {
      padding: 24px 15px;
      min-height: inherit; } }
  .tlc-calculat-edit.tlc-modal {
    background: #ffffff;
    padding: 24px 0; }
  .tlc-calculat-edit h3 {
    font-size: 14px; }
  .tlc-calculat-edit .calculat-label {
    font-size: 13px;
    margin-bottom: 0px; }
  .tlc-calculat-edit label {
    font-size: 13px;
    display: inline-block;
    margin-bottom: 0px;
    color: #5a687b;
    font-weight: inherit !important;
    word-break: break-word; }
    .tlc-calculat-edit label span {
      font-size: 10px; }
  .tlc-calculat-edit .est-cost {
    width: auto;
    display: inline-block;
    vertical-align: middle;
    font-size: 10px;
    color: #9b9b9b; }
  .tlc-calculat-edit .tlc-cost {
    width: auto;
    display: inline-block;
    vertical-align: middle;
    font-size: 13px; }
  .tlc-calculat-edit .final-cost {
    width: 70px;
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
    font-size: 13px; }
    .tlc-calculat-edit .final-cost.profile-edit {
      width: 120px; }
    .tlc-calculat-edit .final-cost .final-cost-input {
      padding: 5px 7px;
      text-align: right;
      min-height: 30px;
      font-size: 13px; }
    .tlc-calculat-edit .final-cost.has-post-fix {
      position: relative; }
      .tlc-calculat-edit .final-cost.has-post-fix .final-cost-input {
        padding-right: 16px; }
      .tlc-calculat-edit .final-cost.has-post-fix .post-fix {
        position: absolute;
        top: 9px;
        right: 8px; }
    .tlc-calculat-edit .final-cost.combined-cost-input {
      width: 100%;
      margin-left: 0px; }
      .tlc-calculat-edit .final-cost.combined-cost-input .harf-edit {
        width: calc(100% - 70px);
        display: inline-block;
        vertical-align: middle; }
        .tlc-calculat-edit .final-cost.combined-cost-input .harf-edit .input-with-border {
          border-radius: 4px 0 0 4px; }
      .tlc-calculat-edit .final-cost.combined-cost-input .harf-select {
        width: 70px;
        text-align: left;
        display: inline-block;
        vertical-align: middle; }
        .tlc-calculat-edit .final-cost.combined-cost-input .harf-select .type-select .react-select__control {
          min-height: 37px;
          border-left: none;
          border-radius: 0 4px 4px 0; }
      .tlc-calculat-edit .final-cost.combined-cost-input .harf-edit-Percent {
        width: 70px;
        text-align: left;
        display: inline-block;
        vertical-align: middle;
        position: relative; }
        .tlc-calculat-edit .final-cost.combined-cost-input .harf-edit-Percent .input-with-border {
          border-radius: 0px 4px 4px 0px;
          border-left: none;
          min-height: 37px; }
        .tlc-calculat-edit .final-cost.combined-cost-input .harf-edit-Percent .percent-symbol {
          position: absolute;
          top: 10px;
          right: 7px;
          font-size: 12px;
          width: auto; }
  .tlc-calculat-edit .tlc-calculat-label {
    font-size: 13px;
    margin-bottom: 0px; }
  .tlc-calculat-edit.mortgage-calculat label {
    margin-bottom: 0px; }
  .tlc-calculat-edit.mortgage-calculat .final-cost {
    width: 133px;
    text-align: left;
    margin-left: 0px; }
    @media only screen and (max-width: 1440px) {
      .tlc-calculat-edit.mortgage-calculat .final-cost {
        width: 100px; } }
    .tlc-calculat-edit.mortgage-calculat .final-cost.full-final-cost {
      width: 100%; }
    .tlc-calculat-edit.mortgage-calculat .final-cost.extra-long-cost {
      width: 170px; }
    .tlc-calculat-edit.mortgage-calculat .final-cost .final-cost-input {
      text-align: left;
      min-height: 33px; }
    .tlc-calculat-edit.mortgage-calculat .final-cost .harf-edit {
      width: calc(100% - 50px);
      display: inline-block;
      vertical-align: middle; }
      .tlc-calculat-edit.mortgage-calculat .final-cost .harf-edit.dp-amt {
        width: calc(100% - 62px); }
      .tlc-calculat-edit.mortgage-calculat .final-cost .harf-edit .final-cost-input {
        border-radius: 4px 0 0px 4px; }
    .tlc-calculat-edit.mortgage-calculat .final-cost .harf-select {
      width: 50px;
      display: inline-block;
      vertical-align: middle; }
      .tlc-calculat-edit.mortgage-calculat .final-cost .harf-select .type-select {
        margin-left: -2px; }
        .tlc-calculat-edit.mortgage-calculat .final-cost .harf-select .type-select .react-select__control {
          min-height: 33px;
          border-radius: 0 4px 4px 0; }
          .tlc-calculat-edit.mortgage-calculat .final-cost .harf-select .type-select .react-select__control .react-select__single-value {
            max-width: 100%; }
          .tlc-calculat-edit.mortgage-calculat .final-cost .harf-select .type-select .react-select__control .react-select__value-container {
            padding: 0px 5px;
            height: 31px; }
          .tlc-calculat-edit.mortgage-calculat .final-cost .harf-select .type-select .react-select__control .css-b8ldur-Input {
            padding: 0px;
            margin: 0px; }
          .tlc-calculat-edit.mortgage-calculat .final-cost .harf-select .type-select .react-select__control .react-select__indicator:after {
            display: none; }
      .tlc-calculat-edit.mortgage-calculat .final-cost .harf-select .final-cost-input {
        border-radius: 0 4px 4px 0;
        margin-left: -2px;
        width: 100%; }
      .tlc-calculat-edit.mortgage-calculat .final-cost .harf-select.dp-per {
        width: 62px;
        position: relative; }
        .tlc-calculat-edit.mortgage-calculat .final-cost .harf-select.dp-per .final-cost-input {
          padding-right: 16px; }
        .tlc-calculat-edit.mortgage-calculat .final-cost .harf-select.dp-per .post-fix {
          position: absolute;
          right: 8px;
          top: 9px; }
  .tlc-calculat-edit .price-range-box {
    margin-top: 16px; }
  .tlc-calculat-edit .vertical-mid-content {
    display: table-cell; }
    .tlc-calculat-edit .vertical-mid-content.indent-left {
      padding-left: 18px; }

.OpensansFont .tlc-calculat-edit .final-cost [type="text"] {
  line-height: 15px; }

/****************************************tlc calculat Edit End******************************************/
/*****************************************Agent box start***********************************************/
.agent-dt-box {
  box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
  width: 100%;
  float: left;
  /* height:121px; */
  min-height: 130px;
  border-radius: 8px;
  background: #ffffff; }
  @media only screen and (max-width: 1023px) {
    .agent-dt-box {
      min-height: inherit;
      text-align: left;
      padding: 10px 15px; } }
  .agent-dt-box .agent-dt-left {
    width: 140px;
    height: 140px;
    float: left;
    overflow: hidden;
    border-radius: 8px 0 0 8px;
    background: #f7f7f7; }
    .agent-dt-box .agent-dt-left img {
      border-radius: 8px 0 0 8px;
      min-height: 100%;
      object-fit: cover; }
      @media only screen and (max-width: 1199px) {
        .agent-dt-box .agent-dt-left img {
          border-radius: 8px 0 0 8px; } }
      @media only screen and (max-width: 1023px) {
        .agent-dt-box .agent-dt-left img {
          border-radius: 100%;
          width: 100%;
          height: 100%;
          padding: 2px; } }
    @media only screen and (max-width: 1270px) {
      .agent-dt-box .agent-dt-left {
        width: 135px;
        height: 150px; } }
    @media only screen and (max-width: 1023px) {
      .agent-dt-box .agent-dt-left {
        width: 88px;
        height: 88px;
        border-radius: 100%;
        float: left;
        display: inline-block;
        border: 1px solid #dddddd; } }
    @media only screen and (max-width: 680px) {
      .agent-dt-box .agent-dt-left {
        width: 70px;
        height: 70px; } }
  .agent-dt-box .agent-dt-right {
    width: calc(100% - 150px);
    float: left;
    min-height: 121px;
    padding: 10px 10px 6px 15px; }
    @media only screen and (max-width: 1270px) {
      .agent-dt-box .agent-dt-right {
        width: calc(100% - 135px);
        padding: 10px 7px 6px 7px; } }
    @media only screen and (max-width: 1199px) {
      .agent-dt-box .agent-dt-right {
        padding: 10px 15px 6px 15px; } }
    @media only screen and (max-width: 1023px) {
      .agent-dt-box .agent-dt-right {
        width: auto;
        display: block;
        overflow: hidden;
        float: none;
        min-height: inherit;
        padding: 8px 15px 0px 15px; } }
    @media only screen and (max-width: 350px) {
      .agent-dt-box .agent-dt-right {
        width: calc(100% - 70px); } }
    .agent-dt-box .agent-dt-right a {
      font-size: 11px; }
    .agent-dt-box .agent-dt-right .agent-company-name {
      font-size: 11px;
      display: block;
      width: 100%;
      line-height: normal; }
      @media only screen and (max-width: 1023px) {
        .agent-dt-box .agent-dt-right .agent-company-name {
          max-height: inherit;
          font-size: 13px; } }
    .agent-dt-box .agent-dt-right .agent-name {
      font-size: 14px;
      display: block;
      width: 100%;
      padding-top: 5px;
      padding-bottom: 8px;
      max-height: inherit;
      /* height:27px;
			overflow: hidden;
			text-overflow:ellipsis;
			white-space:nowrap; */
      line-height: normal; }
      .agent-dt-box .agent-dt-right .agent-name a {
        font-size: 14px; }
      @media only screen and (max-width: 1023px) {
        .agent-dt-box .agent-dt-right .agent-name {
          max-height: inherit; } }
    .agent-dt-box .agent-dt-right .agent-phone {
      display: block;
      width: 100%;
      font-size: 10px;
      padding-bottom: 2px; }
      .agent-dt-box .agent-dt-right .agent-phone .number-box {
        width: auto;
        display: inline-block;
        /* @include breakpointMax(1270) {
					padding-left:3px;	
				} */ }
        .agent-dt-box .agent-dt-right .agent-phone .number-box:first-child {
          padding-right: 5px; }
    .agent-dt-box .agent-dt-right .agent-website {
      font-size: 11px;
      display: block;
      width: 100%;
      padding-top: 2px; }
      .agent-dt-box .agent-dt-right .agent-website span {
        display: inline-block;
        vertical-align: middle;
        word-break: break-all;
        line-height: normal;
        width: calc(100% - 20px); }
    .agent-dt-box .agent-dt-right i {
      vertical-align: middle;
      font-size: 12px;
      padding-right: 2px; }
    .agent-dt-box .agent-dt-right .message-agent {
      display: block;
      width: 100%;
      margin-top: 12px; }

/*****************************************Agent box End***********************************************/
/*****************************************My account start***********************************************/
.account-fullinfo .est-payment-text {
  color: #7b8aa0; }

.commute-calculat-edit .typeahead-search {
  height: 33px; }
  .commute-calculat-edit .typeahead-search .dropdown-menu {
    top: 0px;
    padding: 0px; }
  .commute-calculat-edit .typeahead-search .form-control {
    font-size: 13px;
    border: 1px solid #ddd;
    padding: 10px; }

/*****************************************My account End***********************************************/
.custom-modal.full-modal {
  width: 90%;
  max-width: 90%; }
  @media only screen and (max-width: 768px) {
    .custom-modal.full-modal {
      width: 97%;
      max-width: 98%; } }

.custom-modal .property-address {
  margin-top: 5px; }
  .custom-modal .property-address h1 {
    font-size: 22px; }
  .custom-modal .property-address .sub-address {
    font-size: 22px;
    display: block; }
    @media only screen and (max-width: 1024px) {
      .custom-modal .property-address .sub-address {
        font-size: 18px; } }
    @media only screen and (max-width: 767px) {
      .custom-modal .property-address .sub-address {
        font-size: 16px; } }

.custom-modal .property-beds {
  width: 100%;
  display: block;
  line-height: normal;
  font-size: 14px;
  padding-top: 4px; }
  .custom-modal .property-beds span {
    margin-right: 5px; }

.demographic-disc {
  font-size: 10px !important;
  font-style: italic !important;
  text-align: right !important;
  float: none !important;
  vertical-align: middle !important;
  line-height: 10px !important;
  width: 100% !important; }
  @media only screen and (max-width: 590px) {
    .demographic-disc {
      display: block !important;
      text-align: left !important;
      float: none !important; } }

.nearby-list {
  width: 100%;
  float: left;
  background: #ffffff;
  box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
  padding: 30px 40px;
  border-radius: 8px;
  margin-bottom: 26px; }
  @media only screen and (max-width: 1190px) {
    .nearby-list {
      padding: 20px 20px; } }
  @media only screen and (max-width: 1024px) {
    .nearby-list {
      font-size: 17px;
      padding: 25px 20px; } }
  @media only screen and (max-width: 767px) {
    .nearby-list {
      font-size: 15px;
      padding: 20px 20px; } }
  .nearby-list .header-name {
    color: #657388;
    font-size: 20px; }
    @media only screen and (max-width: 1024px) {
      .nearby-list .header-name {
        font-size: 17px; } }
    @media only screen and (max-width: 767px) {
      .nearby-list .header-name {
        font-size: 15px; } }
  .nearby-list .nearby-prop-cards {
    margin-top: 30px;
    text-align: left;
    width: 100%; }
    @media only screen and (max-width: 1330px) {
      .nearby-list .nearby-prop-cards .prop-card {
        width: 100%; } }
    @media only screen and (max-width: 1199px) {
      .nearby-list .nearby-prop-cards .prop-card {
        width: 290px; } }
    @media only screen and (max-width: 350px) {
      .nearby-list .nearby-prop-cards .prop-card {
        width: 100%; } }

.market-trends {
  width: 100%;
  float: left;
  background: #ffffff;
  box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
  padding: 30px 40px;
  border-radius: 8px;
  margin-bottom: 26px; }
  @media only screen and (max-width: 1190px) {
    .market-trends {
      padding: 20px 20px; } }
  @media only screen and (max-width: 1024px) {
    .market-trends {
      padding: 25px 20px; } }
  @media only screen and (max-width: 767px) {
    .market-trends {
      padding: 20px 20px; } }
  .market-trends h4 {
    font-size: 16px; }
  .market-trends .header-name {
    color: #657388;
    font-size: 20px; }
    @media only screen and (max-width: 1024px) {
      .market-trends .header-name {
        font-size: 17px; } }
    @media only screen and (max-width: 767px) {
      .market-trends .header-name {
        font-size: 15px; } }
  .market-trends .compete-score-box {
    margin-top: 30px;
    text-align: left;
    width: 100%;
    display: block; }
    .market-trends .compete-score-box .score-number-info {
      margin-top: 13px;
      text-align: left;
      width: 100%;
      display: flex;
      align-items: center; }
      .market-trends .compete-score-box .score-number-info .score-number {
        display: inline-block;
        font-size: 48px;
        margin-right: 10px; }
      .market-trends .compete-score-box .score-number-info .score-description {
        display: flex;
        flex-direction: column; }
        .market-trends .compete-score-box .score-number-info .score-description .description-header {
          font-size: 20px;
          display: block;
          margin-top: 3px;
          width: 100%; }
        .market-trends .compete-score-box .score-number-info .score-description .description-subheader {
          font-size: 13px;
          display: block;
          margin-top: 3px;
          width: 100%; }
  .market-trends .score-bar-slider {
    width: 100%;
    display: flex;
    margin: 25px 0px;
    position: relative;
    height: 12px; }
    .market-trends .score-bar-slider .scale-left {
      margin-right: 5px;
      margin-top: -2px;
      max-width: 20px;
      font-size: 12px; }
    .market-trends .score-bar-slider .scale-right {
      margin-left: 5px;
      margin-top: -2px;
      max-width: 20px;
      font-size: 12px; }
    .market-trends .score-bar-slider .score-background-bar {
      background-color: #f2f2f2;
      height: 12px;
      flex: 1;
      border-radius: 40px;
      vertical-align: baseline;
      width: 100%; }
      .market-trends .score-bar-slider .score-background-bar .over-background-bar {
        background: linear-gradient(90deg, #67b3f9, #e9aa3d, #e14e17);
        height: 100%;
        border-radius: 40px; }
  .market-trends .score-details {
    width: 100%;
    display: block;
    margin-bottom: 10px; }
    .market-trends .score-details ul {
      margin: 0px;
      padding: 0px; }
    .market-trends .score-details li {
      list-style-type: disc;
      margin-top: 9px;
      margin-bottom: 9px;
      padding-left: 0px;
      margin-left: 15px;
      line-height: 20px; }

.pd-loader-wrapper {
  display: block;
  width: 375px;
  margin: 155px auto; }
  @media only screen and (max-width: 400px) {
    .pd-loader-wrapper {
      width: 315px; } }
  .pd-loader-wrapper .pd-loader-icon {
    display: inline-block;
    vertical-align: top;
    margin-right: 30px; }
    @media only screen and (max-width: 400px) {
      .pd-loader-wrapper .pd-loader-icon {
        margin-right: 20px; } }
    .pd-loader-wrapper .pd-loader-icon .loader {
      width: 80px !important;
      height: 80px !important; }
      @media only screen and (max-width: 400px) {
        .pd-loader-wrapper .pd-loader-icon .loader {
          width: 70px !important;
          height: 70px !important; } }
    .pd-loader-wrapper .pd-loader-icon .fa {
      font-size: 106px; }
      @media only screen and (max-width: 400px) {
        .pd-loader-wrapper .pd-loader-icon .fa {
          font-size: 84px; } }
  .pd-loader-wrapper .pd-loader-text {
    display: inline-block;
    vertical-align: top; }
    .pd-loader-wrapper .pd-loader-text .pd-loader-text-t1 {
      font-size: 35px;
      margin-bottom: 16px; }
      @media only screen and (max-width: 400px) {
        .pd-loader-wrapper .pd-loader-text .pd-loader-text-t1 {
          margin-bottom: 10px; } }
    .pd-loader-wrapper .pd-loader-text .pd-loader-text-t2 {
      font-size: 26px; }
      @media only screen and (max-width: 400px) {
        .pd-loader-wrapper .pd-loader-text .pd-loader-text-t2 {
          font-size: 22px; } }

.submit-meg-box {
  width: 100%;
  line-height: normal;
  margin-bottom: 15px;
  display: block;
  padding: 5px 5px;
  border-radius: 3px;
  text-align: center;
  font-size: 14px; }

.commute-profile-box {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 13px; }
  .commute-profile-box .commute-profile-box-label {
    margin: -18px 0 0 -5px;
    font-size: 13px;
    padding: 0 0 0 2px; }
    .commute-profile-box .commute-profile-box-label span {
      background: #fff;
      padding: 0 3px 0 3px; }
  .commute-profile-box .commute-profile-box-content {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 4px; }
    .commute-profile-box .commute-profile-box-content .commute-name-box {
      width: 208px;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      vertical-align: middle; }
    .commute-profile-box .commute-profile-box-content .commute-name-select {
      display: inline-block;
      margin-left: 5px;
      padding: 2px 8px 1px 8px;
      cursor: pointer;
      color: #fff;
      font-size: 12px;
      border-radius: 4px;
      vertical-align: middle; }
  .commute-profile-box .commute-update-profile-box {
    font-size: 13px;
    line-height: 21px;
    margin-top: 8px;
    border-top: 1px solid #ddd;
    padding: 8px 0 5px 0; }
    .commute-profile-box .commute-update-profile-box .commute-update-profile-btn {
      padding: 2px 8px 1px 8px;
      cursor: pointer;
      font-size: 12px;
      vertical-align: middle;
      margin-left: 12px;
      border-radius: 4px; }

/************************************************************************************/
.notification {
  border: none;
  border-top: none !important;
  font-size: 15px !important;
  line-height: 22px !important;
  transform: rotate(0deg) !important;
  will-change: auto !important;
  height: auto !important;
  padding: 20px 47px 20px 20px !important;
  border-radius: 4px !important;
  color: #000000 !important;
  box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07) !important;
  background-color: #ffffff !important; }
  .notification .notification-dismiss {
    margin: 5px 0 0 0 !important;
    font-weight: normal !important;
    width: 30px !important;
    height: 30px !important;
    background: #ffffff !important;
    padding: 0px !important;
    border-radius: 100% !important;
    opacity: 1 !important;
    font-size: 25px !important;
    box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
    line-height: 30px !important; }
  .notification .notification_btn {
    margin: 25px 0 6px 0;
    width: calc(100% + 27px); }
  .notification .white-fill-color-button {
    /* color: #00ADBB;
    	background: #ffffff; */
    min-height: 30px;
    padding: 5px 15px;
    display: inline-block;
    border-radius: 4px;
    border: 1px solid;
    min-width: 110px;
    font-weight: normal;
    margin: 0 8px 0 0px;
    font-size: 15px;
    /* &:hover, &:focus {
			background: #00939f;
			color: #ffffff;
			border:1px solid #00939f;
		} */ }
  .notification .fill-color-button {
    /* color: #ffffff;
    	background: #00ADBB; */
    min-height: 30px;
    padding: 5px 15px;
    display: inline-block;
    border-radius: 4px;
    border: 1px solid;
    min-width: 110px;
    font-weight: normal;
    margin: 0 0 0 8px;
    font-size: 15px;
    /* &:hover, &:focus {
			background: #00939f;
			color: #ffffff;
		} */ }

/**********************************print piece****************************/
.print-piece-view {
  width: 100%;
  padding: 0 20px;
  margin-top: 50px; }

.filter-search-header {
  font-size: 14px;
  background: transparent;
  margin: 0;
  padding: 0; }
  @media only screen and (max-width: 990px) {
    .filter-search-header {
      font-size: 17px; } }
  .filter-search-header .tag {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    padding-right: 10px;
    cursor: pointer;
    font-size: 14px;
    position: relative;
    background: transparent;
    margin-top: 3px;
    margin-bottom: 3px; }
    .filter-search-header .tag:after {
      position: absolute;
      content: "|";
      top: 0px;
      right: 0px;
      font-size: 14px; }
    .filter-search-header .tag:hover:after {
      content: ""; }
    .filter-search-header .tag:last-child:after {
      content: ""; }
    .filter-search-header .tag:last-child:hover:after {
      content: ""; }
    .filter-search-header .tag .tag-copy {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: transform 1s; }
      .filter-search-header .tag .tag-copy:after {
        position: absolute;
        content: "";
        top: 0px;
        right: 0px;
        font-size: 14px; }
      .filter-search-header .tag .tag-copy:hover {
        opacity: 1;
        background: #ffffff;
        border: 1px solid #dddddd;
        border-radius: 4px;
        padding: 6px 24px 6px 8px;
        transform: scale(1.15);
        top: -7px;
        left: inherit;
        right: -24px;
        z-index: 9; }
        .filter-search-header .tag .tag-copy:hover:after {
          content: "X";
          top: 6px;
          right: 6px; }
      .filter-search-header .tag .tag-copy:last-child:hover:after {
        content: "X"; }
    .filter-search-header .tag.tagdisabled {
      cursor: inherit; }
      .filter-search-header .tag.tagdisabled:hover:after {
        content: "|"; }
      .filter-search-header .tag.tagdisabled:last-child:hover:after {
        content: ""; }
      .filter-search-header .tag.tagdisabled .tag-copy {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: transform 1s; }
        .filter-search-header .tag.tagdisabled .tag-copy:hover {
          opacity: 0; }
          .filter-search-header .tag.tagdisabled .tag-copy:hover:after {
            content: ""; }
        .filter-search-header .tag.tagdisabled .tag-copy:last-child:hover:after {
          content: ""; }
      .filter-search-header .tag.tagdisabled:after {
        display: none; }
      .filter-search-header .tag.tagdisabled .tag-label {
        display: inline-block;
        position: relative;
        vertical-align: bottom; }
        .filter-search-header .tag.tagdisabled .tag-label:after {
          position: absolute;
          content: "|";
          bottom: 0px;
          right: -8px;
          font-size: 14px; }
      .filter-search-header .tag.tagdisabled:last-child .tag-label:after {
        content: ""; }
      .filter-search-header .tag.tagdisabled.tag {
        padding-right: 8px; }
    .filter-search-header .tag .tag-label {
      font-weight: normal; }

.tour_button {
  display: none; }

.small-banner {
  width: 100%;
  display: block;
  margin-bottom: 36px; }
  .small-banner img {
    border-radius: 4px; }

.opt-out-msg {
  color: #ce8530 !important; }
  .opt-out-msg .material-icons {
    color: #ce8530 !important;
    margin-right: 5px;
    vertical-align: middle; }
  .opt-out-msg label {
    color: #ce8530 !important; }
  .opt-out-msg h3 {
    color: #ce8530 !important; }

/***************************************************************/
.tooltip_box {
  max-width: 300px;
  margin: 0px;
  word-break: break-word;
  white-space: inherit !important;
  line-height: 20px; }

.react-bs-table table td {
  white-space: inherit !important;
  word-break: break-word; }

.react-bs-table table th {
  white-space: inherit !important;
  word-break: break-word; }

.chart_tooltip {
  width: 400px !important;
  max-width: 400px !important; }
  @media only screen and (max-width: 1024px) {
    .chart_tooltip {
      left: 0px !important; } }
  @media only screen and (max-width: 767px) {
    .chart_tooltip {
      width: 100% !important;
      max-width: 100% !important; } }

.chart_ratebox {
  width: 100%;
  text-align: left;
  padding: 0px;
  float: left;
  color: #000;
  background: #fff;
  border: 1px solid #e7e7e8; }
  .chart_ratebox .rate_header {
    width: 100%;
    float: left;
    font-size: 11px; }
    .chart_ratebox .rate_header.header_top {
      color: #fff;
      background: #4c4c4c;
      font-size: 11px; }
    .chart_ratebox .rate_header .header_box1 {
      width: 11%;
      float: left;
      padding: 5px 7px;
      border-right: 1px solid #ddd;
      border-bottom: 1px solid #ddd; }
    .chart_ratebox .rate_header .header_box2 {
      width: 45%;
      float: left;
      padding: 5px 7px;
      border-right: 1px solid #ddd;
      border-bottom: 1px solid #ddd; }
    .chart_ratebox .rate_header .header_box3 {
      width: 44%;
      float: left;
      padding: 5px 7px;
      border-bottom: 1px solid #ddd; }
    .chart_ratebox .rate_header:last-child .header_box1 {
      border-bottom: none; }
    .chart_ratebox .rate_header:last-child .header_box2 {
      border-bottom: none; }
    .chart_ratebox .rate_header:last-child .header_box3 {
      border-bottom: none; }

/*************************************************************************************************/
/***************************************Sitetour**********************************************************/
.sitetour .joyride-tooltip__header {
  border-bottom: none !important; }

.sitetour .joyride-tooltip {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1) !important; }

.sitetour .joyride-tooltip__button {
  padding: 12px !important;
  min-width: 80px !important;
  border-radius: 4px; }
  .sitetour .joyride-tooltip__button.joyride-tooltip__button--skip {
    background-color: inherit;
    color: #ccc;
    min-width: inherit !important;
    border-radius: inherit;
    padding: 12px 0px !important; }
    .sitetour .joyride-tooltip__button.joyride-tooltip__button--skip:hover {
      background-color: inherit;
      color: #ccc;
      min-width: inherit !important;
      border-radius: inherit; }
    .sitetour .joyride-tooltip__button.joyride-tooltip__button--skip:focus {
      background-color: inherit;
      color: #ccc;
      min-width: inherit !important;
      border-radius: inherit; }
  .sitetour .joyride-tooltip__button.joyride-tooltip__button--secondary {
    background-color: inherit;
    min-width: inherit !important;
    border-radius: inherit;
    padding: 12px 0px !important;
    margin-right: 36px; }
    .sitetour .joyride-tooltip__button.joyride-tooltip__button--secondary:hover {
      background-color: inherit;
      min-width: inherit !important;
      border-radius: inherit; }
    .sitetour .joyride-tooltip__button.joyride-tooltip__button--secondary:focus {
      background-color: inherit;
      min-width: inherit !important;
      border-radius: inherit; }

.sitetour .joyride-tooltip__main {
  line-height: 20px;
  font-size: 14px;
  color: #9b9b9b;
  padding-bottom: 22px !important; }

.tlc-fund-error-icon {
  font-size: 20px;
  vertical-align: middle;
  margin-left: 5px; }

.tlc-fund-success-icon {
  font-size: 20px;
  vertical-align: middle;
  margin-left: 5px; }

.tlc-fund-warning-icon {
  font-size: 20px;
  vertical-align: middle;
  margin-left: 5px; }

/************************************New widget**************************************************/
.widget-card {
  margin-bottom: 25px;
  width: 100%;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
  background: #ffffff;
  padding: 30px 40px;
  display: inline-block; }
  @media only screen and (max-width: 1024px) {
    .widget-card {
      padding: 30px 20px; } }
  .widget-card h2 {
    font-size: 20px;
    margin-bottom: 40px; }
    @media only screen and (max-width: 767px) {
      .widget-card h2 {
        font-size: 17px; } }
  .widget-card .tlc-content-text {
    color: #bec4cb;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 24px; }
  .widget-card .property-features-title {
    font-size: 14px;
    margin-bottom: 12px; }
  .widget-card .est-payment-text {
    color: #7b8aa0;
    font-size: 12px; }
  .widget-card .panel-title-box {
    padding: 17px 20px;
    border-radius: 8px;
    margin-bottom: 5px;
    font-size: 17px;
    line-height: 22px;
    cursor: pointer; }
    @media only screen and (max-width: 1200px) {
      .widget-card .panel-title-box {
        font-size: 15px; } }
    @media only screen and (max-width: 480px) {
      .widget-card .panel-title-box {
        font-size: 18px;
        padding: 17px 15px; } }
    .widget-card .panel-title-box .panel-title-left {
      width: 30%;
      display: inline-block;
      vertical-align: middle; }
      @media only screen and (max-width: 1365px) {
        .widget-card .panel-title-box .panel-title-left {
          width: 100%;
          text-align: center;
          padding-bottom: 10px; }
          .widget-card .panel-title-box .panel-title-left .vertical-mid-content {
            display: inline-block; } }
      @media only screen and (max-width: 1199px) {
        .widget-card .panel-title-box .panel-title-left {
          width: 30%;
          text-align: left;
          padding-bottom: 0px; } }
      @media only screen and (max-width: 810px) {
        .widget-card .panel-title-box .panel-title-left {
          width: 100%;
          text-align: center;
          padding-bottom: 10px; }
          .widget-card .panel-title-box .panel-title-left .vertical-mid-content {
            display: inline-block; } }
    .widget-card .panel-title-box .panel-title-right {
      width: 70%;
      display: inline-block;
      vertical-align: middle; }
      @media only screen and (max-width: 1365px) {
        .widget-card .panel-title-box .panel-title-right {
          width: 100%;
          text-align: center !important; } }
      @media only screen and (max-width: 1199px) {
        .widget-card .panel-title-box .panel-title-right {
          width: 70%;
          text-align: right !important; } }
      @media only screen and (max-width: 810px) {
        .widget-card .panel-title-box .panel-title-right {
          width: 100%;
          text-align: center !important; } }
    @media only screen and (max-width: 420px) {
      .widget-card .panel-title-box .panel-title-full {
        width: 100%;
        flex: 100%;
        max-width: 100%;
        text-align: center !important;
        padding: 5px 0; } }
    .widget-card .panel-title-box .category-total {
      display: inline-block;
      vertical-align: middle;
      width: auto;
      min-height: 25px; }
      .widget-card .panel-title-box .category-total .category-icon {
        display: inline-block;
        vertical-align: middle;
        padding-right: 8px; }
        @media only screen and (max-width: 1480px) {
          .widget-card .panel-title-box .category-total .category-icon {
            padding-right: 6px; } }
        .widget-card .panel-title-box .category-total .category-icon img {
          width: auto;
          display: inline-block;
          vertical-align: middle; }
        .widget-card .panel-title-box .category-total .category-icon.water-icon img {
          width: 17px; }
        .widget-card .panel-title-box .category-total .category-icon.home-icon img {
          width: 20px; }
      .widget-card .panel-title-box .category-total .category-amount {
        display: inline-block;
        vertical-align: middle;
        color: #ffffff;
        font-size: 14px; }
        .widget-card .panel-title-box .category-total .category-amount.category-final-amount {
          font-size: 17px; }
          @media only screen and (max-width: 1200px) {
            .widget-card .panel-title-box .category-total .category-amount.category-final-amount {
              font-size: 15px; } }
          @media only screen and (max-width: 480px) {
            .widget-card .panel-title-box .category-total .category-amount.category-final-amount {
              font-size: 18px; } }
    .widget-card .panel-title-box .category-pluse {
      display: inline-block;
      vertical-align: middle;
      width: auto;
      color: #ffffff;
      font-size: 18px;
      padding: 0 8px; }
      @media only screen and (max-width: 1200px) {
        .widget-card .panel-title-box .category-pluse {
          padding: 0 5px; } }
      .widget-card .panel-title-box .category-pluse .material-icons {
        color: #ffffff;
        display: inline-block;
        vertical-align: middle;
        font-size: 16px; }
    .widget-card .panel-title-box .category-arrow-icon {
      display: inline-block;
      vertical-align: middle;
      width: auto;
      padding: 0 4px 0 3px; }
      .widget-card .panel-title-box .category-arrow-icon .material-icons {
        color: #ffffff;
        display: inline-block;
        vertical-align: middle;
        font-size: 26px; }
    .widget-card .panel-title-box .status-costs {
      width: 35px;
      height: 35px;
      display: inline-block;
      vertical-align: middle;
      border-radius: 100%;
      background: #ffffff;
      text-align: center;
      line-height: 26px;
      margin-left: 10px; }
      @media only screen and (max-width: 1200px) {
        .widget-card .panel-title-box .status-costs {
          width: 35px;
          height: 35px; } }
      .widget-card .panel-title-box .status-costs .status-icon {
        font-size: 22px;
        display: inline-block;
        vertical-align: bottom; }
        @media only screen and (max-width: 1200px) {
          .widget-card .panel-title-box .status-costs .status-icon {
            font-size: 21px; } }
  .widget-card .ownership-cost-section {
    width: 100%;
    display: block;
    margin-top: 20px; }
    .widget-card .ownership-cost-section .highcharts-title {
      width: 100%;
      display: block; }
      .widget-card .ownership-cost-section .highcharts-title tspan {
        width: 100%;
        display: block; }
        .widget-card .ownership-cost-section .highcharts-title tspan:first-child {
          display: block; }
      .widget-card .ownership-cost-section .highcharts-title .budget-price {
        font-size: 15px; }
        @media only screen and (max-width: 1199px) {
          .widget-card .ownership-cost-section .highcharts-title .budget-price {
            font-size: 15px; } }
        @media only screen and (max-width: 900px) {
          .widget-card .ownership-cost-section .highcharts-title .budget-price {
            font-size: 16px; } }
        @media only screen and (max-width: 768px) {
          .widget-card .ownership-cost-section .highcharts-title .budget-price {
            font-size: 15px; } }
      .widget-card .ownership-cost-section .highcharts-title .edit-price-icon {
        cursor: pointer; }
      .widget-card .ownership-cost-section .highcharts-title .success-icon {
        font-size: 75px; }
        @media only screen and (max-width: 1199px) {
          .widget-card .ownership-cost-section .highcharts-title .success-icon {
            font-size: 65px; } }
        @media only screen and (max-width: 900px) {
          .widget-card .ownership-cost-section .highcharts-title .success-icon {
            font-size: 60px; } }
    .widget-card .ownership-cost-section.highcharts-border .budget-chart {
      position: relative !important;
      width: 100%; }
      @media only screen and (max-width: 767px) {
        .widget-card .ownership-cost-section.highcharts-border .budget-chart {
          margin-top: 20px; } }
    @media only screen and (max-width: 480px) {
      .widget-card .ownership-cost-section.highcharts-border .budget-chart-box {
        padding-left: 0px;
        padding-right: 0px; } }
    .widget-card .ownership-cost-section.highcharts-border .highcharts-container {
      display: block; }
      .widget-card .ownership-cost-section.highcharts-border .highcharts-container:after {
        content: "";
        top: 47px;
        right: 80px;
        position: absolute;
        border-radius: 100%;
        width: 235px;
        height: 235px;
        /* @include breakpointMax(920) {
						width:230px;
    					height:230px;
						top:50px;
						right:calc(50% - 115px);
					}
					@include breakpointMax(899) {
						width:225px;
    					height:225px;
						top:52px;
						right:calc(50% - 113px);
					}
					@include breakpointMax(880) {
						width:220px;
    					height:220px;
						top:54px;
						right:calc(50% - 110px);
					}
					@include breakpointMax(860) {
						width:215px;
    					height:215px;
						top:57px;
						right:calc(50% - 107px);
					} */ }
        @media only screen and (max-width: 1430px) {
          .widget-card .ownership-cost-section.highcharts-border .highcharts-container:after {
            right: calc(50% - 118px); } }
        @media only screen and (max-width: 1199px) {
          .widget-card .ownership-cost-section.highcharts-border .highcharts-container:after {
            right: calc(50% - 118px);
            top: 47px;
            width: 235px;
            height: 235px; } }
        @media only screen and (max-width: 1180px) {
          .widget-card .ownership-cost-section.highcharts-border .highcharts-container:after {
            right: calc(50% - 118px); } }
        @media only screen and (max-width: 400px) {
          .widget-card .ownership-cost-section.highcharts-border .highcharts-container:after {
            width: 225px;
            height: 225px;
            right: calc(50% - 112px);
            top: 52px; } }
        @media only screen and (max-width: 369px) {
          .widget-card .ownership-cost-section.highcharts-border .highcharts-container:after {
            width: 200px;
            height: 200px;
            right: calc(50% - 100px);
            border-width: 2px;
            top: 64px; } }
    .widget-card .ownership-cost-section .expenses-cost-list {
      margin-top: 25px;
      width: 100%;
      display: block; }
      .widget-card .ownership-cost-section .expenses-cost-list .expenses-cost-header {
        width: 100%;
        display: block;
        color: #ffffff;
        border-radius: 10px;
        padding: 0 15px;
        cursor: pointer;
        margin-bottom: 55px; }
        @media only screen and (max-width: 1199px) {
          .widget-card .ownership-cost-section .expenses-cost-list .expenses-cost-header {
            margin-bottom: 45px; } }
        @media only screen and (max-width: 767px) {
          .widget-card .ownership-cost-section .expenses-cost-list .expenses-cost-header {
            margin-bottom: 35px; } }
        .widget-card .ownership-cost-section .expenses-cost-list .expenses-cost-header:last-child {
          margin-bottom: 0px; }
        .widget-card .ownership-cost-section .expenses-cost-list .expenses-cost-header.header-bg-blue {
          background: #1f6aa6; }
        .widget-card .ownership-cost-section .expenses-cost-list .expenses-cost-header.header-bg-light-blue {
          background: #488cae; }
        .widget-card .ownership-cost-section .expenses-cost-list .expenses-cost-header.header-bg-light-green {
          background: #00adbb; }
        .widget-card .ownership-cost-section .expenses-cost-list .expenses-cost-header .expenses-content {
          width: 100%;
          min-height: 60px;
          display: table;
          padding: 5px 0; }
          @media only screen and (max-width: 480px) {
            .widget-card .ownership-cost-section .expenses-cost-list .expenses-cost-header .expenses-content {
              padding: 10px 0; } }
          .widget-card .ownership-cost-section .expenses-cost-list .expenses-cost-header .expenses-content .expenses-box {
            width: 70%;
            display: table-cell;
            vertical-align: middle;
            word-break: break-word; }
            @media only screen and (max-width: 1400px) {
              .widget-card .ownership-cost-section .expenses-cost-list .expenses-cost-header .expenses-content .expenses-box {
                width: 65%; } }
            .widget-card .ownership-cost-section .expenses-cost-list .expenses-cost-header .expenses-content .expenses-box .expenses-icon {
              width: auto;
              display: inline-block;
              vertical-align: middle; }
              @media only screen and (max-width: 1365px) {
                .widget-card .ownership-cost-section .expenses-cost-list .expenses-cost-header .expenses-content .expenses-box .expenses-icon.car-icon img {
                  width: 35px; } }
            .widget-card .ownership-cost-section .expenses-cost-list .expenses-cost-header .expenses-content .expenses-box .expenses-name {
              width: 80%;
              display: inline-block;
              vertical-align: middle;
              padding-left: 10px;
              font-size: 15px; }
              @media only screen and (max-width: 1400px) {
                .widget-card .ownership-cost-section .expenses-cost-list .expenses-cost-header .expenses-content .expenses-box .expenses-name {
                  padding-left: 8px; } }
              @media only screen and (max-width: 480px) {
                .widget-card .ownership-cost-section .expenses-cost-list .expenses-cost-header .expenses-content .expenses-box .expenses-name {
                  width: auto; } }
            @media only screen and (max-width: 480px) {
              .widget-card .ownership-cost-section .expenses-cost-list .expenses-cost-header .expenses-content .expenses-box {
                width: 100%;
                display: block;
                text-align: center; } }
          .widget-card .ownership-cost-section .expenses-cost-list .expenses-cost-header .expenses-content .expenses-count {
            display: table-cell;
            vertical-align: middle;
            width: 30%;
            text-align: right;
            word-break: break-word; }
            @media only screen and (max-width: 1400px) {
              .widget-card .ownership-cost-section .expenses-cost-list .expenses-cost-header .expenses-content .expenses-count {
                width: 35%; } }
            .widget-card .ownership-cost-section .expenses-cost-list .expenses-cost-header .expenses-content .expenses-count .expenses-amount {
              font-size: 15px;
              color: #ffffff;
              display: inline-block;
              vertical-align: middle; }
            .widget-card .ownership-cost-section .expenses-cost-list .expenses-cost-header .expenses-content .expenses-count .expand-icon {
              font-size: 23px;
              color: #ffffff;
              display: inline-block;
              vertical-align: middle;
              padding-left: 2px; }
            @media only screen and (max-width: 480px) {
              .widget-card .ownership-cost-section .expenses-cost-list .expenses-cost-header .expenses-content .expenses-count {
                width: 100%;
                display: block;
                text-align: center;
                padding-top: 10px; } }
    @media only screen and (max-width: 369px) {
      .widget-card .ownership-cost-section .highcharts-container .highcharts-title .cost-living {
        font-size: 10px !important; } }
  .widget-card .cost-calculat-edit {
    width: 100%;
    display: block;
    background: #f8f7f6;
    border-radius: 8px;
    padding: 20px 15px;
    font-size: 13px; }
    @media only screen and (max-width: 767px) {
      .widget-card .cost-calculat-edit {
        margin-bottom: 25px; } }
    .widget-card .cost-calculat-edit h3 {
      font-size: 15px;
      vertical-align: middle;
      display: inline-block; }
    .widget-card .cost-calculat-edit .final-total-cost {
      display: inline-block;
      vertical-align: middle;
      text-align: right; }
      .widget-card .cost-calculat-edit .final-total-cost .cost-value {
        display: inline-block;
        vertical-align: middle;
        font-size: 15px; }
    .widget-card .cost-calculat-edit .final-cost {
      width: 100%;
      display: inline-block;
      vertical-align: middle;
      font-size: 13px; }
      @media only screen and (max-width: 1280px) {
        .widget-card .cost-calculat-edit .final-cost {
          width: 100%; } }
      .widget-card .cost-calculat-edit .final-cost .final-cost-input {
        padding: 5px 10px;
        min-height: 32px;
        font-size: 13px; }
        @media only screen and (max-width: 420px) {
          .widget-card .cost-calculat-edit .final-cost .final-cost-input {
            text-align: left !important; } }
      .widget-card .cost-calculat-edit .final-cost.extra-long-cost {
        width: 100%; }
        .widget-card .cost-calculat-edit .final-cost.extra-long-cost .harf-edit {
          width: calc(100% - 62px);
          display: inline-block; }
          @media only screen and (max-width: 1366px) {
            .widget-card .cost-calculat-edit .final-cost.extra-long-cost .harf-edit {
              width: calc(100% - 53px); } }
          .widget-card .cost-calculat-edit .final-cost.extra-long-cost .harf-edit .final-cost-input {
            border-radius: 4px 0 0 4px;
            text-align: left; }
        .widget-card .cost-calculat-edit .final-cost.extra-long-cost .harf-select {
          width: 62px;
          position: relative;
          display: inline-block;
          margin-left: -1px; }
          @media only screen and (max-width: 1366px) {
            .widget-card .cost-calculat-edit .final-cost.extra-long-cost .harf-select {
              width: 53px; } }
          .widget-card .cost-calculat-edit .final-cost.extra-long-cost .harf-select .final-cost-input {
            border-radius: 0 4px 4px 0;
            text-align: left;
            width: 100%; }
            @media only screen and (max-width: 1366px) {
              .widget-card .cost-calculat-edit .final-cost.extra-long-cost .harf-select .final-cost-input {
                padding: 5px 5px 5px 5px; } }
          .widget-card .cost-calculat-edit .final-cost.extra-long-cost .harf-select .post-fix {
            position: absolute;
            right: 8px;
            top: 9px; }
      .widget-card .cost-calculat-edit .final-cost.income-cost-input {
        width: 140px;
        display: inline-block;
        vertical-align: middle; }
        @media only screen and (max-width: 1440px) {
          .widget-card .cost-calculat-edit .final-cost.income-cost-input {
            width: 132px; } }
        @media only screen and (max-width: 1400px) {
          .widget-card .cost-calculat-edit .final-cost.income-cost-input {
            width: calc(98% - 100px); } }
      .widget-card .cost-calculat-edit .final-cost.has-post-fix {
        position: relative; }
      .widget-card .cost-calculat-edit .final-cost .post-fix {
        position: absolute;
        top: 9px;
        right: 25px; }
    .widget-card .cost-calculat-edit label {
      font-size: 13px;
      margin-bottom: 0px;
      color: #5a687b;
      font-weight: inherit !important;
      word-break: break-word;
      line-height: 18px; }
    .widget-card .cost-calculat-edit .header-name-icon {
      display: table-cell;
      vertical-align: middle;
      padding-right: 5px; }
      .widget-card .cost-calculat-edit .header-name-icon img {
        width: auto; }
    .widget-card .cost-calculat-edit .category-sub-header {
      cursor: pointer;
      display: block;
      width: 100%;
      margin-bottom: 30px; }
      .widget-card .cost-calculat-edit .category-sub-header .arrow-icon {
        display: inline-block;
        vertical-align: middle;
        font-size: 23px;
        padding-left: 5px; }
    .widget-card .cost-calculat-edit .income-option {
      width: 100px;
      border: 1px solid #dddddd;
      border-radius: 4px;
      background: #ffffff;
      display: inline-block;
      vertical-align: middle;
      margin-right: 3px; }
      @media only screen and (max-width: 1440px) {
        .widget-card .cost-calculat-edit .income-option {
          width: 99px; } }
      @media only screen and (max-width: 1400px) {
        .widget-card .cost-calculat-edit .income-option {
          width: 100px; } }
      .widget-card .cost-calculat-edit .income-option label {
        width: 50%;
        font-size: 10px;
        min-height: 30px;
        text-align: center;
        line-height: 30px;
        margin-bottom: 0px;
        cursor: pointer; }
        .widget-card .cost-calculat-edit .income-option label .beds-input-hidden:checked + .beds-radio-fill {
          color: #fff;
          min-height: 32px; }
        .widget-card .cost-calculat-edit .income-option label:first-child {
          border-right: 1px solid #dddddd; }
          .widget-card .cost-calculat-edit .income-option label:first-child .option-radio-fill {
            border-radius: 4px 0px 0px 4px; }
        .widget-card .cost-calculat-edit .income-option label:last-child .option-radio-fill {
          border-radius: 0px 4px 4px 0px; }
    .widget-card .cost-calculat-edit .going-location-dropdown .dropdown-menu {
      max-height: 164px !important;
      width: 250px !important; }
      @media only screen and (max-width: 767px) {
        .widget-card .cost-calculat-edit .going-location-dropdown .dropdown-menu {
          width: 100% !important; } }
    @media only screen and (max-width: 420px) {
      .widget-card .cost-calculat-edit .mb-full-col {
        width: 100%;
        flex: 100%;
        max-width: 100%; }
        .widget-card .cost-calculat-edit .mb-full-col.mb-pd-tp-5 {
          padding-top: 5px; } }
  .widget-card .expenses-tlc-detail .card {
    box-shadow: none;
    border-radius: 0px !important;
    margin-bottom: 5px !important;
    background: #f8f7f6; }
    .widget-card .expenses-tlc-detail .card.mg-bt-0 {
      margin-bottom: 0; }
    .widget-card .expenses-tlc-detail .card .collapse {
      background: #f8f7f6; }
  .widget-card .expenses-tlc-detail .card-header {
    background: #f8f7f6;
    border-radius: inherit !important;
    border: none;
    padding: 0px;
    margin-bottom: 1px;
    border-bottom: 1px solid #dddddd; }
    .widget-card .expenses-tlc-detail .card-header .header-name {
      border: none;
      padding: 10px 0px 10px 15px;
      background: #f8f7f6;
      width: 100%;
      text-align: left;
      position: relative;
      font-size: 15px;
      min-height: 45px;
      /* @include breakpointMax(1190) {
					padding:20px 20px;
				} */ }
      .widget-card .expenses-tlc-detail .card-header .header-name:after {
        position: absolute;
        top: 16px;
        right: inherit;
        /* font-size:22px;
					font-family: 'Material Icons';
					content:'add'; */
        font-family: "FontAwesome";
        content: "\f067 ";
        left: 0px;
        font-size: 14px; }
      @media only screen and (max-width: 1024px) {
        .widget-card .expenses-tlc-detail .card-header .header-name {
          font-size: 15px;
          padding: 10px 0px 10px 15px; } }
      .widget-card .expenses-tlc-detail .card-header .header-name .sub-header-name {
        color: #bec4cb;
        font-size: 14px;
        display: block;
        padding-top: 5px; }
      .widget-card .expenses-tlc-detail .card-header .header-name:active, .widget-card .expenses-tlc-detail .card-header .header-name:focus {
        border: none;
        outline: none; }
      .widget-card .expenses-tlc-detail .card-header .header-name.open::after {
        display: none; }
      .widget-card .expenses-tlc-detail .card-header .header-name.open::before {
        position: absolute;
        top: 16px;
        left: 0px;
        right: inherit;
        font-size: 22px;
        /* font-family: 'Material Icons';
						content:'minimize'; */
        font-family: "FontAwesome";
        content: "\f068";
        left: 0px;
        font-size: 14px;
        /* @include breakpointMax(1190) {
							
						}
						@include breakpointMax(1025) {
							
						}
						@include breakpointMax(767) {
							
						} */ }
    .widget-card .expenses-tlc-detail .card-header .utilies-icon {
      padding: 0 4px 0 5px;
      display: inline-block;
      vertical-align: middle; }
      .widget-card .expenses-tlc-detail .card-header .utilies-icon img {
        width: auto; }
    .widget-card .expenses-tlc-detail .card-header .expenses-tlc-name {
      display: inline-block;
      vertical-align: middle;
      width: 70%; }
      .widget-card .expenses-tlc-detail .card-header .expenses-tlc-name .expenses-name-box {
        display: inline-block;
        vertical-align: middle;
        padding-left: 5px;
        width: auto; }
    .widget-card .expenses-tlc-detail .card-header .expenses-tlc-amount {
      width: 30%;
      display: inline-block;
      vertical-align: middle;
      text-align: right; }
  .widget-card .expenses-tlc-detail.accordion .card {
    overflow: inherit; }
  .widget-card .expenses-tlc-detail .card-body {
    background: #f8f7f6;
    padding: 15px 0px 0px 20px; }
  .widget-card .expenses-tlc-detail.sub-detail-toggle .card {
    margin-bottom: 0px !important; }
  .widget-card .expenses-tlc-detail.sub-detail-toggle .card-header .expenses-tlc-name {
    font-size: 14px; }
  .widget-card .expenses-tlc-detail.sub-detail-toggle .card-header .header-name {
    min-height: inherit; }
    .widget-card .expenses-tlc-detail.sub-detail-toggle .card-header .header-name:after {
      top: 14px;
      font-family: "FontAwesome";
      content: "\f067 ";
      left: 0px;
      font-size: 14px; }
    .widget-card .expenses-tlc-detail.sub-detail-toggle .card-header .header-name:before {
      top: 14px; }
  .widget-card .expenses-tlc-detail.sub-detail-toggle.accordion .card {
    overflow: inherit; }
  .widget-card .numeric-input-box {
    width: 100%;
    display: inline-block; }
    .widget-card .numeric-input-box .form-control {
      font-size: 13px !important; }
      .widget-card .numeric-input-box .form-control:focus {
        border-color: inherit;
        box-shadow: inherit;
        border: 1px solid #dddddd;
        border-radius: 4px; }
    .widget-card .numeric-input-box b {
      background: inherit !important;
      border: none;
      border-color: inherit !important;
      border-style: inherit !important;
      top: 0 !important;
      bottom: 0 !important;
      border-left: 1px solid #ddd !important;
      cursor: pointer !important;
      width: 30px !important; }
      .widget-card .numeric-input-box b + b {
        border-right: 1px solid #ddd !important;
        border-left: inherit !important;
        width: 30px !important; }
  .widget-card .commute-history-box {
    width: 100%;
    border: 1px solid #dddddd;
    display: block;
    padding: 20px 15px;
    background: #ffffff;
    margin-bottom: 20px; }
    .widget-card .commute-history-box label {
      font-size: 13px;
      line-height: 18px; }
      .widget-card .commute-history-box label.vertical-mid-content.bold {
        font-weight: bold !important; }
    .widget-card .commute-history-box .transportation-option {
      width: 100%;
      display: block;
      text-align: center; }
      .widget-card .commute-history-box .transportation-option label {
        width: 30px;
        font-size: 14px;
        border-left: 1px solid #dddddd;
        height: 30px;
        border-radius: 100%;
        text-align: center;
        line-height: 30px;
        margin-bottom: 0px;
        cursor: pointer;
        margin-left: 8px;
        display: inline-block;
        vertical-align: middle; }
        .widget-card .commute-history-box .transportation-option label .option-input-hidden:checked + .option-radio-fill {
          color: #fff;
          border-radius: 100%;
          height: 30px;
          width: 30px;
          line-height: 30px; }
        .widget-card .commute-history-box .transportation-option label .option-radio-fill {
          width: 30px;
          height: 30px;
          line-height: 30px;
          border-radius: 100%;
          background: #c3c9c9; }
          .widget-card .commute-history-box .transportation-option label .option-radio-fill .material-icons {
            color: #ffffff;
            font-size: 20px;
            line-height: 28px; }
        .widget-card .commute-history-box .transportation-option label:first-child {
          margin-left: 0px; }
    .widget-card .commute-history-box .expenses-tlc-detail.sub-detail-toggle .card-header {
      background: none; }
      .widget-card .commute-history-box .expenses-tlc-detail.sub-detail-toggle .card-header .header-name {
        background: none; }
    .widget-card .commute-history-box .expenses-tlc-detail.sub-detail-toggle .card {
      background: #ffffff; }
      .widget-card .commute-history-box .expenses-tlc-detail.sub-detail-toggle .card .collapse {
        background: #ffffff; }
    .widget-card .commute-history-box .expenses-tlc-detail.sub-detail-toggle .card-body {
      background: #ffffff; }
    @media only screen and (max-width: 768px) {
      .widget-card .commute-history-box .commute-full-col {
        flex: 100%;
        max-width: 100%;
        width: 100%; }
        .widget-card .commute-history-box .commute-full-col.mb-pd-tp-5 {
          padding-top: 5px; } }

.widget-with-chart.widget-chart-hide {
  display: none; }

.widget-with-detail {
  display: none; }
  .widget-with-detail.widget-detail-open {
    display: block;
    transition: transform 0.95s ease-out; }

.controls-wrapper .numeric-input-box {
  width: 100%;
  display: inline-block; }
  .controls-wrapper .numeric-input-box .form-control {
    font-size: 13px !important; }
    .controls-wrapper .numeric-input-box .form-control:focus {
      border-color: inherit;
      box-shadow: inherit;
      border: 1px solid #dddddd;
      border-radius: 4px; }
  .controls-wrapper .numeric-input-box b {
    background: inherit !important;
    border: none;
    border-color: inherit !important;
    border-style: inherit !important;
    top: 0 !important;
    bottom: 0 !important;
    border-left: 1px solid #ddd !important;
    cursor: pointer !important;
    width: 30px !important; }
    .controls-wrapper .numeric-input-box b + b {
      border-right: 1px solid #ddd !important;
      border-left: inherit !important;
      width: 30px !important; }

/**********************************************************************************************/
.highcharts-tooltip {
  z-index: 9999 !important; }

.muted-text {
  display: block;
  font-size: 10px;
  color: #ababab; }

.header-title-box {
  background-color: transparent;
  width: 100%;
  max-width: 92%;
  height: auto;
  border-radius: 8px;
  padding: 15px;
  margin: 0px auto 25px; }
  .header-title-box.dark {
    background-color: rgba(0, 0, 0, 0.3); }
  .header-title-box h2 {
    padding-bottom: 0px !important; }
  @media only screen and (max-width: 767px) {
    .header-title-box {
      max-width: 100%; } }

.poi-filter-wrapper {
  position: absolute;
  right: 20px;
  top: 80px;
  z-index: 999;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px; }
  @media only screen and (max-width: 768px) {
    .poi-filter-wrapper {
      top: 90px; } }
  .poi-filter-wrapper .filter-button {
    background-color: white;
    width: 44px;
    height: 44px;
    cursor: pointer;
    text-align: center;
    line-height: 27px;
    border-radius: 5px;
    font-size: 32px;
    color: #b2b2b2;
    position: relative;
    margin: 0;
    display: table; }
    .poi-filter-wrapper .filter-button .filter-icon-map {
      display: table-cell;
      vertical-align: middle;
      width: 100%;
      height: 100%;
      text-align: center; }
      .poi-filter-wrapper .filter-button .filter-icon-map img {
        width: auto; }
    .poi-filter-wrapper .filter-button .filter-icon-close {
      display: table-cell;
      vertical-align: middle;
      width: 100%;
      height: 100%;
      text-align: center; }
    .poi-filter-wrapper .filter-button .filter-close-button {
      position: absolute;
      bottom: 2px;
      right: 2px;
      font-size: 18px; }
  .poi-filter-wrapper .poi-list {
    position: absolute;
    top: -2px;
    right: -2px;
    padding: 12px 0px 12px 0;
    background: white;
    border-radius: 5px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    max-height: 413px;
    width: 260px;
    background-clip: padding-box; }
    .poi-filter-wrapper .poi-list .poi-list-scroll {
      max-height: 326px;
      overflow-y: auto;
      width: 100%;
      display: block;
      padding: 0 0 0 14px; }
    .poi-filter-wrapper .poi-list .poi-list-item {
      line-height: normal;
      font-size: 13px;
      padding: 0px;
      min-width: 170px;
      position: relative;
      margin-bottom: 15px;
      display: block;
      min-height: 19px; }
      .poi-filter-wrapper .poi-list .poi-list-item .pois-input-name {
        margin-left: 20px;
        display: block;
        width: calc(100% - 20px);
        padding-left: 6px;
        font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
        font-weight: bold;
        font-size: 12px; }
      .poi-filter-wrapper .poi-list .poi-list-item:last-child {
        margin-bottom: 0px; }
    .poi-filter-wrapper .poi-list .reset-point-box {
      position: sticky;
      bottom: 0px;
      width: 100%;
      margin-top: 10px;
      padding-right: 12px;
      padding-left: 12px;
      padding-top: 10px;
      padding-bottom: 12px;
      border-top: 1px solid #dddddd;
      text-align: center;
      /* & .theme-btn {
				width:100%;
			} */ }
  .poi-filter-wrapper.open {
    border: none;
    margin-top: 2px;
    margin-right: 2px; }

@media only screen and (max-width: 567px) {
  .poi-tooltip {
    background: #fff;
    padding: 10px;
    border-radius: 10px; } }

/****************************************************Start*************************************************************/
.home-banner2-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  position: relative;
  width: 100%;
  display: block;
  min-height: 738px;
  padding-top: 166px;
  background-image: url(../img/nd/arkhomes-header-bg.jpg); }
  @media only screen and (max-width: 1440px) {
    .home-banner2-bg {
      padding-top: 156px; } }
  @media only screen and (max-width: 1230px) {
    .home-banner2-bg {
      min-height: 670px;
      padding-top: 90px; } }
  @media only screen and (max-width: 1024px) {
    .home-banner2-bg {
      min-height: 570px;
      padding-top: 63px; } }
  @media only screen and (max-width: 1000px) {
    .home-banner2-bg {
      padding-top: 55px; } }
  @media only screen and (max-width: 991px) {
    .home-banner2-bg {
      padding-top: 75px; } }
  @media only screen and (max-width: 480px) {
    .home-banner2-bg {
      min-height: 530px;
      padding-top: 40px; } }
  @media only screen and (max-width: 400px) {
    .home-banner2-bg {
      padding-top: 35px; } }
  .home-banner2-bg .header-titles-box {
    width: 87%;
    display: block;
    padding-left: 120px; }
    @media only screen and (max-width: 1440px) {
      .home-banner2-bg .header-titles-box {
        width: 85%; } }
    @media only screen and (max-width: 1280px) {
      .home-banner2-bg .header-titles-box {
        width: 80%; } }
    @media only screen and (max-width: 1025px) {
      .home-banner2-bg .header-titles-box {
        padding-left: 100px; } }
    @media only screen and (max-width: 1000px) {
      .home-banner2-bg .header-titles-box {
        width: 100%;
        padding-right: 15px; } }
    @media only screen and (max-width: 991px) {
      .home-banner2-bg .header-titles-box {
        text-align: center;
        padding-left: 15px; } }
    @media only screen and (max-width: 767px) {
      .home-banner2-bg .header-titles-box {
        padding-left: 50px; } }
    @media only screen and (max-width: 480px) {
      .home-banner2-bg .header-titles-box {
        padding-left: 15px;
        padding-right: 15px; } }
    .home-banner2-bg .header-titles-box .banner-title span {
      display: block; }
      .home-banner2-bg .header-titles-box .banner-title span span {
        display: inline-block; }
  .home-banner2-bg .header-search-filter {
    width: 568px;
    min-height: 290px;
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    bottom: 90px;
    left: calc(50% - 284px);
    padding: 45px 15px 23px 15px;
    text-align: center;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); }
    @media only screen and (max-width: 1180px) {
      .home-banner2-bg .header-search-filter {
        bottom: 40px; } }
    .home-banner2-bg .header-search-filter .filter-mid-box {
      width: 400px;
      display: inline-block;
      margin: 0px auto; }
      @media only screen and (max-width: 767px) {
        .home-banner2-bg .header-search-filter .filter-mid-box {
          width: 95%; } }
      .home-banner2-bg .header-search-filter .filter-mid-box .header-multisearch {
        width: 100%;
        display: block;
        margin-bottom: 19px; }
        .home-banner2-bg .header-search-filter .filter-mid-box .header-multisearch .typeahead-search {
          border: 1px solid #d9d9d9d9;
          width: 100%;
          display: block;
          height: 62px; }
          .home-banner2-bg .header-search-filter .filter-mid-box .header-multisearch .typeahead-search .form-control {
            font-size: 18px;
            padding: 10px 25px; }
      .home-banner2-bg .header-search-filter .filter-mid-box .tlc-range-box {
        width: 100%;
        display: block;
        min-height: 68px;
        text-align: center;
        padding: 3px 5px 7px 5px;
        border: 1px solid #d9d9d9d9;
        border-radius: 5px;
        background: #ffffff; }
        .home-banner2-bg .header-search-filter .filter-mid-box .tlc-range-box .tlc-range-title {
          width: 100%;
          display: block;
          text-align: center;
          color: #888888;
          font-size: 18px;
          margin-bottom: 4px;
          line-height: normal; }
          .home-banner2-bg .header-search-filter .filter-mid-box .tlc-range-box .tlc-range-title .fa-question-circle {
            font-size: 15px;
            padding-left: 7px; }
        .home-banner2-bg .header-search-filter .filter-mid-box .tlc-range-box .input-range {
          width: 100%;
          margin: 0; }
        .home-banner2-bg .header-search-filter .filter-mid-box .tlc-range-box .input-range__label--min {
          /* left:24px;
    					top:-19px;
						bottom:inherit; */
          display: none; }
          .home-banner2-bg .header-search-filter .filter-mid-box .tlc-range-box .input-range__label--min .input-range__label-container {
            bottom: -4px;
            font-size: 14px;
            color: #9b9b9b; }
        .home-banner2-bg .header-search-filter .filter-mid-box .tlc-range-box .input-range__label--max {
          /* right:0px;
    					top:-19px;
						bottom:inherit; */
          display: none; }
          .home-banner2-bg .header-search-filter .filter-mid-box .tlc-range-box .input-range__label--max .input-range__label-container {
            bottom: -4px;
            font-size: 14px;
            color: #9b9b9b; }
        .home-banner2-bg .header-search-filter .filter-mid-box .tlc-range-box .input-range__track {
          width: calc(100% - 50px);
          left: 25px; }
        .home-banner2-bg .header-search-filter .filter-mid-box .tlc-range-box .input-range__slider-container .input-range__label--value {
          top: 7px; }
          .home-banner2-bg .header-search-filter .filter-mid-box .tlc-range-box .input-range__slider-container .input-range__label--value .input-range__label-container {
            font-size: 13px; }
      .home-banner2-bg .header-search-filter .filter-mid-box .filter-find-button {
        width: 100%;
        display: block;
        margin-top: 25px; }
        .home-banner2-bg .header-search-filter .filter-mid-box .filter-find-button .theme-btn {
          min-width: 177px;
          text-transform: uppercase;
          font-size: 18px;
          border-radius: 8px;
          margin: 0 6px; }
          @media only screen and (max-width: 480px) {
            .home-banner2-bg .header-search-filter .filter-mid-box .filter-find-button .theme-btn {
              margin: 0 3px;
              font-size: 17px;
              min-width: 160px; } }
          @media only screen and (max-width: 450px) {
            .home-banner2-bg .header-search-filter .filter-mid-box .filter-find-button .theme-btn {
              font-size: 13px;
              min-width: 140px; } }
          @media only screen and (max-width: 375px) {
            .home-banner2-bg .header-search-filter .filter-mid-box .filter-find-button .theme-btn {
              min-width: 120px;
              font-size: 13px;
              padding: 10px 13px; } }
          @media only screen and (max-width: 360px) {
            .home-banner2-bg .header-search-filter .filter-mid-box .filter-find-button .theme-btn {
              min-width: 120px;
              font-size: 13px; } }
    @media only screen and (max-width: 1000px) {
      .home-banner2-bg .header-search-filter {
        bottom: 40px;
        padding: 25px 15px 23px 15px;
        min-height: 270px; } }
    @media only screen and (max-width: 767px) {
      .home-banner2-bg .header-search-filter {
        width: 90%;
        left: calc(50% - 45%); } }
  .home-banner2-bg .partner-logo {
    width: auto;
    display: inline-block; }
    @media only screen and (max-width: 1000px) {
      .home-banner2-bg .partner-logo {
        width: 100%;
        display: block;
        /* text-align: left; */
        text-align: right;
        padding-left: 100px;
        padding-right: 100px;
        padding-top: 20px; } }
    @media only screen and (max-width: 991px) {
      .home-banner2-bg .partner-logo {
        text-align: center;
        padding-top: 40px; } }
    @media only screen and (max-width: 767px) {
      .home-banner2-bg .partner-logo {
        padding-left: 50px;
        padding-right: 50px; } }
    @media only screen and (max-width: 480px) {
      .home-banner2-bg .partner-logo {
        padding-left: 15px;
        padding-right: 15px;
        text-align: center; } }
    @media only screen and (max-width: 400px) {
      .home-banner2-bg .partner-logo {
        padding-top: 25px; } }
    .home-banner2-bg .partner-logo .partner-logo-box1 {
      width: auto;
      display: inline-block;
      top: 52px;
      right: 68px;
      position: absolute; }
      @media only screen and (max-width: 1180px) {
        .home-banner2-bg .partner-logo .partner-logo-box1 {
          right: 58px; } }
      @media only screen and (max-width: 1100px) {
        .home-banner2-bg .partner-logo .partner-logo-box1 {
          right: 48px; } }
      @media only screen and (max-width: 1000px) {
        .home-banner2-bg .partner-logo .partner-logo-box1 {
          right: inherit;
          left: inherit;
          top: inherit;
          position: inherit;
          width: 120px;
          margin-right: 15px; } }
      @media only screen and (max-width: 991px) {
        .home-banner2-bg .partner-logo .partner-logo-box1 {
          width: 90px; } }
    .home-banner2-bg .partner-logo .partner-logo-box2 {
      width: auto;
      display: inline-block;
      top: 159px;
      right: 86px;
      position: absolute; }
      @media only screen and (max-width: 1180px) {
        .home-banner2-bg .partner-logo .partner-logo-box2 {
          right: 76px; } }
      @media only screen and (max-width: 1180px) {
        .home-banner2-bg .partner-logo .partner-logo-box2 {
          right: 66px; } }
      @media only screen and (max-width: 1000px) {
        .home-banner2-bg .partner-logo .partner-logo-box2 {
          right: inherit;
          left: inherit;
          top: inherit;
          position: inherit;
          width: 75px; } }
      @media only screen and (max-width: 991px) {
        .home-banner2-bg .partner-logo .partner-logo-box2 {
          width: 50px; } }

.short-menubox {
  display: none; }

.with-submenu .desktop-menu {
  padding-top: 116px; }
  .with-submenu .desktop-menu .navbar {
    top: 48px; }
    .with-submenu .desktop-menu .navbar .other-style-nav .nav-link {
      font-size: 18px;
      padding-top: 23px;
      padding-bottom: 22px;
      padding-left: 25px;
      padding-right: 25px; }
      @media only screen and (max-width: 1230px) {
        .with-submenu .desktop-menu .navbar .other-style-nav .nav-link {
          padding-right: 15px;
          padding-left: 15px; } }
      @media only screen and (max-width: 999px) {
        .with-submenu .desktop-menu .navbar .other-style-nav .nav-link {
          font-size: 17px; } }
      @media only screen and (max-width: 991px) {
        .with-submenu .desktop-menu .navbar .other-style-nav .nav-link {
          font-size: 16px;
          padding-top: 17px;
          padding-bottom: 17px;
          padding-left: 3px;
          padding-right: 3px; } }
  .with-submenu .desktop-menu .short-menubox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 48px;
    padding: 0px;
    z-index: 1001;
    display: block; }
    .with-submenu .desktop-menu .short-menubox .short-menu-link .navbar-brand {
      padding: 0 16px 0 0; }
    .with-submenu .desktop-menu .short-menubox .short-menu-link .nav-link {
      font-size: 13px;
      padding: 15px 16px;
      text-transform: uppercase; }
      .with-submenu .desktop-menu .short-menubox .short-menu-link .nav-link.active {
        font-size: 13px;
        background: #ffffff; }
      .with-submenu .desktop-menu .short-menubox .short-menu-link .nav-link:hover {
        background: #ffffff; }
  @media only screen and (max-width: 991px) {
    .with-submenu .desktop-menu .navbar-toggler {
      top: 19px; } }
  @media only screen and (max-width: 991px) {
    .with-submenu .desktop-menu .navbar-toggler.collapsed {
      top: 19px; } }
  @media only screen and (max-width: 999px) {
    .with-submenu .desktop-menu .login-quicklink {
      min-height: 79px; } }

.with-submenu .search-bar-panel {
  top: 117px; }
  @media only screen and (max-width: 999px) {
    .with-submenu .search-bar-panel {
      top: 116px; } }

.with-submenu .search-container {
  height: calc(100vh - 186px);
  margin-top: 70px; }
  .with-submenu .search-container .left-wrapper {
    height: calc(100vh - 186px); }

.with-submenu .more-filter-panel.hide-filter-panel {
  top: 198px; }

.with-submenu .more-filter-panel.show-filter-panel {
  top: 186px;
  height: calc(100% - 186px); }

.with-submenu .more-filter-panel .more-filter-container .more-filter-content {
  max-height: calc(100% - 106px); }
  @media only screen and (max-width: 767px) {
    .with-submenu .more-filter-panel .more-filter-container .more-filter-content {
      min-height: inherit; } }
  @media only screen and (max-device-width: 823px) and (orientation: landscape) and (min-device-aspect-ratio: 1 / 1) {
    .with-submenu .more-filter-panel .more-filter-container .more-filter-content {
      min-height: inherit; } }

.with-submenu.small-height .short-menubox {
  height: 32px; }
  .with-submenu.small-height .short-menubox .short-menu-link .nav-link {
    padding: 7px 16px; }

.with-submenu.small-height .desktop-menu {
  padding-top: 100px; }
  .with-submenu.small-height .desktop-menu .navbar {
    top: 32px; }

.with-submenu.small-height .search-bar-panel {
  top: 101px; }
  @media only screen and (max-width: 999px) {
    .with-submenu.small-height .search-bar-panel {
      top: 100px; } }

.with-submenu.small-height .more-filter-panel.hide-filter-panel {
  top: 182px; }

.with-submenu.small-height .more-filter-panel.show-filter-panel {
  top: 171px;
  height: calc(100% - 171px); }

.with-submenu.small-height .search-container .left-wrapper {
  height: calc(100vh - 170px); }

.search-bar-panel.extra-menu-height .more-filter-toggle {
  padding: 11px 10px 10px 10px; }

.search-bar-panel.extra-menu-height .prop-save-searh-button .theme-btn {
  padding: 11px 10px 10px 10px; }

.tlcinfo-tooltip-box {
  width: 300px;
  background: #ffffff !important;
  border: 2px solid #9b9b9b !important;
  color: #000000 !important;
  line-height: 22px;
  border-radius: 6px;
  opacity: 1 !important;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .tlcinfo-tooltip-box {
      width: 280px;
      white-space: normal; } }
  .tlcinfo-tooltip-box:after {
    position: absolute;
    content: "";
    bottom: 26px;
    border-top: 8px solid #ffffff !important;
    border-width: 8px 8px 0 8px; }
  .tlcinfo-tooltip-box:before {
    border-top: 8px solid #9b9b9b !important;
    border-width: 8px 8px 0 8px; }

.block-image-subheader {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
  width: 100%;
  display: block;
  min-height: 416px;
  padding: 0px;
  margin: 0px;
  background-image: url(../img/nd/block-image-1.jpg); }
  .block-image-subheader .section-content {
    width: calc(50% - 30px);
    float: right;
    height: 100%;
    position: relative; }
    .block-image-subheader .section-content::before {
      border-bottom: 416px solid rgba(34, 73, 115, 0.85);
      border-left: 98px solid transparent;
      border-right: 0px solid transparent;
      width: 100%;
      content: "";
      position: absolute; }
    .block-image-subheader .section-content .container-box {
      width: 100%;
      min-height: 416px;
      display: table;
      padding: 0 20px 0 67px;
      position: relative; }
      @media only screen and (max-width: 576px) {
        .block-image-subheader .section-content .container-box {
          padding: 0 10px 0 67px; } }
      .block-image-subheader .section-content .container-box .block-contain {
        width: 100%;
        display: table-cell;
        vertical-align: middle;
        word-break: break-word; }
        .block-image-subheader .section-content .container-box .block-contain .block-text {
          width: 33%;
          margin: 0px auto;
          text-align: left; }
          @media only screen and (max-width: 1440px) {
            .block-image-subheader .section-content .container-box .block-contain .block-text {
              width: 62%; } }
          @media only screen and (max-width: 1024px) {
            .block-image-subheader .section-content .container-box .block-contain .block-text {
              width: 90%; } }
          @media only screen and (max-width: 574px) {
            .block-image-subheader .section-content .container-box .block-contain .block-text {
              width: 100%;
              text-align: center; } }
          .block-image-subheader .section-content .container-box .block-contain .block-text .block-subtext {
            font-size: 18px;
            width: 100%;
            padding-top: 10px;
            display: block;
            line-height: 25px; }
            @media only screen and (max-width: 768px) {
              .block-image-subheader .section-content .container-box .block-contain .block-text .block-subtext {
                font-size: 16px; } }
            @media only screen and (max-width: 575px) {
              .block-image-subheader .section-content .container-box .block-contain .block-text .block-subtext {
                display: none; } }
          @media only screen and (max-width: 768px) {
            .block-image-subheader .section-content .container-box .block-contain .block-text .banner-title {
              font-size: 25px; } }
          @media only screen and (max-width: 576px) {
            .block-image-subheader .section-content .container-box .block-contain .block-text .banner-title {
              font-size: 18px; } }
  .block-image-subheader .theme-btn.theme-transparent-btm {
    min-width: 215px;
    font-size: 19px;
    margin-top: 15px;
    border-radius: inherit;
    padding: 6px 15px;
    text-align: center; }
    @media only screen and (max-width: 680px) {
      .block-image-subheader .theme-btn.theme-transparent-btm {
        min-width: 100%;
        font-size: 16px; } }
    @media only screen and (max-width: 480px) {
      .block-image-subheader .theme-btn.theme-transparent-btm {
        font-size: 14px;
        padding: 10px 10px; } }
  .block-image-subheader.block-subheader-right {
    background-image: url(../img/nd/block-image-2.jpg); }
    .block-image-subheader.block-subheader-right .section-content {
      float: left;
      margin-left: 0;
      margin-right: 30px;
      width: calc(50% + 30px); }
      .block-image-subheader.block-subheader-right .section-content::before {
        border-left: 98px solid transparent;
        border-right: 0px solid transparent;
        transform: rotate(180deg); }
      .block-image-subheader.block-subheader-right .section-content .container-box {
        padding: 0 67px 0 20px; }
        @media only screen and (max-width: 576px) {
          .block-image-subheader.block-subheader-right .section-content .container-box {
            padding: 0 67px 0 10px; } }
        .block-image-subheader.block-subheader-right .section-content .container-box .block-contain .block-text {
          width: 39%; }
          @media only screen and (max-width: 1440px) {
            .block-image-subheader.block-subheader-right .section-content .container-box .block-contain .block-text {
              width: 61%; } }
          @media only screen and (max-width: 1024px) {
            .block-image-subheader.block-subheader-right .section-content .container-box .block-contain .block-text {
              width: 90%; } }
          @media only screen and (max-width: 574px) {
            .block-image-subheader.block-subheader-right .section-content .container-box .block-contain .block-text {
              width: 100%; } }
  .block-image-subheader .block-subtitle {
    font-size: 18px; }
    @media only screen and (max-width: 1024px) {
      .block-image-subheader .block-subtitle {
        font-size: 20px; } }
    @media only screen and (max-width: 767px) {
      .block-image-subheader .block-subtitle {
        font-size: 20px; } }

.landing-page-mid {
  width: 100%;
  display: block;
  padding-top: 35px;
  padding-bottom: 70px; }
  .landing-page-mid .landing-mid-content {
    width: 100%;
    display: block;
    padding: 0 20px; }
    .landing-page-mid .landing-mid-content .landing-mid-title {
      padding-bottom: 13px; }
      .landing-page-mid .landing-mid-content .landing-mid-title .symbol-text {
        font-size: 15px;
        display: inline-block;
        vertical-align: top;
        padding-top: 11px; }
        @media only screen and (max-width: 767px) {
          .landing-page-mid .landing-mid-content .landing-mid-title .symbol-text {
            padding-top: 0px; } }
      .landing-page-mid .landing-mid-content .landing-mid-title .title-italic-fontstyle {
        font-style: italic; }
    .landing-page-mid .landing-mid-content .landing-sub-text {
      width: 100%;
      display: block;
      text-align: center;
      font-size: 18px;
      line-height: 26px; }
      @media only screen and (max-width: 767px) {
        .landing-page-mid .landing-mid-content .landing-sub-text {
          font-size: 16px; } }
    .landing-page-mid .landing-mid-content .landing-video-player {
      width: 1040px;
      display: inline-block;
      text-align: center;
      margin: 25px auto 55px; }
      @media only screen and (max-width: 1100px) {
        .landing-page-mid .landing-mid-content .landing-video-player {
          width: 100%; } }
      @media only screen and (max-width: 1039px) {
        .landing-page-mid .landing-mid-content .landing-video-player {
          width: 100%; } }
      @media only screen and (max-width: 1030px) {
        .landing-page-mid .landing-mid-content .landing-video-player {
          margin: 25px auto 40px; } }
      @media only screen and (max-width: 768px) {
        .landing-page-mid .landing-mid-content .landing-video-player {
          margin: 25px auto 35px; } }
      .landing-page-mid .landing-mid-content .landing-video-player .video-player-box {
        width: 100%;
        /* height:520px; */
        margin-bottom: 40px; }
        @media only screen and (max-width: 1030px) {
          .landing-page-mid .landing-mid-content .landing-video-player .video-player-box {
            margin-bottom: 30px; } }
        .landing-page-mid .landing-mid-content .landing-video-player .video-player-box .react-player__preview {
          min-height: 590px;
          background-repeat: no-repeat;
          background-size: 100% !important; }
          @media only screen and (max-width: 1030px) {
            .landing-page-mid .landing-mid-content .landing-video-player .video-player-box .react-player__preview {
              min-height: 560px; } }
          @media only screen and (max-width: 1000px) {
            .landing-page-mid .landing-mid-content .landing-video-player .video-player-box .react-player__preview {
              min-height: 546px; } }
          @media only screen and (max-width: 900px) {
            .landing-page-mid .landing-mid-content .landing-video-player .video-player-box .react-player__preview {
              min-height: 493px; } }
          @media only screen and (max-width: 850px) {
            .landing-page-mid .landing-mid-content .landing-video-player .video-player-box .react-player__preview {
              min-height: 465px; } }
          @media only screen and (max-width: 800px) {
            .landing-page-mid .landing-mid-content .landing-video-player .video-player-box .react-player__preview {
              min-height: 435px; } }
          @media only screen and (max-width: 750px) {
            .landing-page-mid .landing-mid-content .landing-video-player .video-player-box .react-player__preview {
              min-height: 415px; } }
          @media only screen and (max-width: 700px) {
            .landing-page-mid .landing-mid-content .landing-video-player .video-player-box .react-player__preview {
              min-height: 390px; } }
          @media only screen and (max-width: 650px) {
            .landing-page-mid .landing-mid-content .landing-video-player .video-player-box .react-player__preview {
              min-height: 353px; } }
          @media only screen and (max-width: 600px) {
            .landing-page-mid .landing-mid-content .landing-video-player .video-player-box .react-player__preview {
              min-height: 320px; } }
          @media only screen and (max-width: 550px) {
            .landing-page-mid .landing-mid-content .landing-video-player .video-player-box .react-player__preview {
              min-height: 300px; } }
          @media only screen and (max-width: 500px) {
            .landing-page-mid .landing-mid-content .landing-video-player .video-player-box .react-player__preview {
              min-height: 275px; } }
          @media only screen and (max-width: 450px) {
            .landing-page-mid .landing-mid-content .landing-video-player .video-player-box .react-player__preview {
              min-height: 245px; } }
          @media only screen and (max-width: 400px) {
            .landing-page-mid .landing-mid-content .landing-video-player .video-player-box .react-player__preview {
              min-height: 215px; } }
          .landing-page-mid .landing-mid-content .landing-video-player .video-player-box .react-player__preview .react-player__shadow {
            background: #000000 !important; }
            @media only screen and (max-width: 1030px) {
              .landing-page-mid .landing-mid-content .landing-video-player .video-player-box .react-player__preview .react-player__shadow {
                border-radius: 50px;
                width: 50px !important;
                height: 50px !important; }
                .landing-page-mid .landing-mid-content .landing-video-player .video-player-box .react-player__preview .react-player__shadow .react-player__play-icon {
                  border-width: 8px 0px 8px 13px !important;
                  margin-left: 3px !important; } }
      .landing-page-mid .landing-mid-content .landing-video-player .theme-btn.theme-transparent-btm {
        min-width: 307px;
        font-size: 25px;
        min-height: 79px;
        text-transform: uppercase;
        display: table; }
        @media only screen and (max-width: 1030px) {
          .landing-page-mid .landing-mid-content .landing-video-player .theme-btn.theme-transparent-btm {
            min-width: inherit;
            font-size: 22px;
            min-height: inherit; } }
        @media only screen and (max-width: 768px) {
          .landing-page-mid .landing-mid-content .landing-video-player .theme-btn.theme-transparent-btm {
            font-size: 20px; } }
        @media only screen and (max-width: 480px) {
          .landing-page-mid .landing-mid-content .landing-video-player .theme-btn.theme-transparent-btm {
            font-size: 18px; } }
  .landing-page-mid .patternbg-card-content {
    width: 100%;
    display: block;
    margin: 0px;
    padding: 0px;
    text-align: left; }
    .landing-page-mid .patternbg-card-content .listing-card {
      width: 100%;
      display: block; }
    .landing-page-mid .patternbg-card-content .pattern-section-title h1 {
      font-size: 30px; }
      @media only screen and (max-width: 1023px) {
        .landing-page-mid .patternbg-card-content .pattern-section-title h1 {
          font-size: 26px; } }
      @media only screen and (max-width: 767px) {
        .landing-page-mid .patternbg-card-content .pattern-section-title h1 {
          font-size: 24px; } }
      @media only screen and (max-width: 480px) {
        .landing-page-mid .patternbg-card-content .pattern-section-title h1 {
          font-size: 22px; } }
    .landing-page-mid .patternbg-card-content .section-cards {
      width: 100%;
      text-align: center;
      margin-top: 15px; }
      .landing-page-mid .patternbg-card-content .section-cards .prop-card {
        background: #ffffff;
        min-height: 290px; }
    .landing-page-mid .patternbg-card-content .more-listing {
      text-align: right;
      width: 100%;
      display: block; }
      .landing-page-mid .patternbg-card-content .more-listing a {
        font-size: 22px; }
        @media only screen and (max-width: 1023px) {
          .landing-page-mid .patternbg-card-content .more-listing a {
            font-size: 20px; } }
        @media only screen and (max-width: 767px) {
          .landing-page-mid .patternbg-card-content .more-listing a {
            font-size: 18px; } }
        @media only screen and (max-width: 480px) {
          .landing-page-mid .patternbg-card-content .more-listing a {
            font-size: 17px; } }
  .landing-page-mid .home-featured-listing .white-card-content {
    background-image: url(../img/nd/pattern-bg.jpg);
    background-repeat: repeat;
    border-radius: 0px; }

.theme-btn.theme-transparent-btm {
  border-radius: 0px;
  min-width: 215px;
  font-size: 19px;
  text-transform: uppercase;
  border-radius: 5px; }

.question-card-patternbg {
  width: 100%;
  display: block;
  background-image: url(../img/nd/pattern-bg.jpg);
  background-repeat: repeat;
  /* margin-bottom: -120px; */ }
  .question-card-patternbg .question-card-listing {
    width: 100%;
    display: block;
    padding: 0; }
    .question-card-patternbg .question-card-listing .card-content {
      width: 100%;
      display: block;
      padding: 25px 15px 25px 15px; }
      @media only screen and (max-width: 1440px) {
        .question-card-patternbg .question-card-listing .card-content {
          padding: 25px 28px 25px 28px; } }
      .question-card-patternbg .question-card-listing .card-content .question-col {
        width: 100%;
        display: block; }
        .question-card-patternbg .question-card-listing .card-content .question-col .question-box {
          width: 100%;
          display: block;
          min-height: 340px;
          background-size: cover;
          background-repeat: no-repeat;
          position: relative;
          /* &:hover {
						transform: scale(1.05) translate(0px, 0px);
    					transition: all 200ms ease-in-out 0s;
					} */ }
          @media only screen and (max-width: 1024px) {
            .question-card-patternbg .question-card-listing .card-content .question-col .question-box {
              min-height: 235px; } }
          .question-card-patternbg .question-card-listing .card-content .question-col .question-box::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            background: rgba(158, 35, 34, 0.65); }
          .question-card-patternbg .question-card-listing .card-content .question-col .question-box .question-name {
            width: 100%;
            display: block;
            padding: 25px 25px 25px 25px;
            font-size: 47px;
            line-height: normal;
            position: relative; }
            @media only screen and (max-width: 1440px) {
              .question-card-patternbg .question-card-listing .card-content .question-col .question-box .question-name {
                font-size: 40px; } }
            @media only screen and (max-width: 1200px) {
              .question-card-patternbg .question-card-listing .card-content .question-col .question-box .question-name {
                font-size: 35px; } }
            @media only screen and (max-width: 1024px) {
              .question-card-patternbg .question-card-listing .card-content .question-col .question-box .question-name {
                font-size: 25px; } }
          .question-card-patternbg .question-card-listing .card-content .question-col .question-box.question-1 {
            background-image: url(../img/nd/question-1.jpg); }
          .question-card-patternbg .question-card-listing .card-content .question-col .question-box.question-2 {
            background-image: url(../img/nd/question-2.jpg); }
          .question-card-patternbg .question-card-listing .card-content .question-col .question-box.question-3 {
            background-image: url(../img/nd/question-3.jpg); }
        @media only screen and (max-width: 767px) {
          .question-card-patternbg .question-card-listing .card-content .question-col {
            margin-bottom: 20px; } }

.blogs-cards-patternbg {
  width: 100%;
  display: block; }
  .blogs-cards-patternbg .blogs-card-listing {
    width: 100%;
    display: block;
    text-align: center; }
    .blogs-cards-patternbg .blogs-card-listing .card-content {
      /* width: 100%;
          display:block; */
      width: 1230px;
      display: inline-block;
      padding: 30px 15px 30px 15px; }
      @media only screen and (max-width: 1250px) {
        .blogs-cards-patternbg .blogs-card-listing .card-content {
          width: 100%; } }
      .blogs-cards-patternbg .blogs-card-listing .card-content .blogs-ctn {
        width: 100%;
        display: block;
        text-align: left; }
        .blogs-cards-patternbg .blogs-card-listing .card-content .blogs-ctn .blogs-box {
          width: 100%;
          display: inline-block;
          vertical-align: top;
          /* min-height:410px; */
          min-height: 402px;
          background: #ffffff; }
          @media only screen and (max-width: 1500px) {
            .blogs-cards-patternbg .blogs-card-listing .card-content .blogs-ctn .blogs-box {
              min-height: inherit; } }
          @media only screen and (max-width: 575px) {
            .blogs-cards-patternbg .blogs-card-listing .card-content .blogs-ctn .blogs-box {
              min-height: inherit; } }
          .blogs-cards-patternbg .blogs-card-listing .card-content .blogs-ctn .blogs-box .blog-img-box {
            width: 100%;
            display: block;
            min-height: 184px;
            position: relative;
            overflow: hidden; }
            @media only screen and (max-width: 1000px) {
              .blogs-cards-patternbg .blogs-card-listing .card-content .blogs-ctn .blogs-box .blog-img-box {
                min-height: 160px; } }
            .blogs-cards-patternbg .blogs-card-listing .card-content .blogs-ctn .blogs-box .blog-img-box img {
              width: 100%;
              position: absolute;
              height: auto;
              margin: auto;
              top: -100%;
              right: -100%;
              bottom: -100%;
              left: -100%;
              min-height: 184px; }
              @media only screen and (max-width: 1000px) {
                .blogs-cards-patternbg .blogs-card-listing .card-content .blogs-ctn .blogs-box .blog-img-box img {
                  min-height: 160px; } }
          .blogs-cards-patternbg .blogs-card-listing .card-content .blogs-ctn .blogs-box .blog-title-box {
            width: 100%;
            display: block;
            padding: 28px 28px 0px 28px;
            margin-bottom: 17px;
            /* font-size:32px; */
            font-size: 30px;
            color: #000000;
            line-height: normal;
            text-transform: uppercase;
            /* height:166px; */
            height: 157px;
            white-space: normal;
            display: block;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis; }
            @media only screen and (max-width: 1500px) {
              .blogs-cards-patternbg .blogs-card-listing .card-content .blogs-ctn .blogs-box .blog-title-box {
                /* font-size:30px;
                  height:155px; */
                font-size: 28px;
                height: 147px; } }
            @media only screen and (max-width: 1480px) {
              .blogs-cards-patternbg .blogs-card-listing .card-content .blogs-ctn .blogs-box .blog-title-box {
                height: 145px;
                margin-bottom: 25px; } }
            @media only screen and (max-width: 1300px) {
              .blogs-cards-patternbg .blogs-card-listing .card-content .blogs-ctn .blogs-box .blog-title-box {
                font-size: 27px;
                padding: 20px 20px 0px 20px;
                height: 135px; } }
            @media only screen and (max-width: 1200px) {
              .blogs-cards-patternbg .blogs-card-listing .card-content .blogs-ctn .blogs-box .blog-title-box {
                font-size: 22px;
                padding: 20px 20px 0px 20px;
                height: 110px; } }
            @media only screen and (max-width: 900px) {
              .blogs-cards-patternbg .blogs-card-listing .card-content .blogs-ctn .blogs-box .blog-title-box {
                font-size: 19px;
                height: 100px; } }
            @media only screen and (max-width: 800px) {
              .blogs-cards-patternbg .blogs-card-listing .card-content .blogs-ctn .blogs-box .blog-title-box {
                padding: 20px 15px 0px 15px;
                font-size: 18px;
                height: 97px; } }
            @media only screen and (max-width: 575px) {
              .blogs-cards-patternbg .blogs-card-listing .card-content .blogs-ctn .blogs-box .blog-title-box {
                font-size: 22px;
                height: inherit;
                display: block;
                -webkit-line-clamp: initial;
                -webkit-box-orient: inherit;
                overflow: inherit;
                text-overflow: inherit; } }
          .blogs-cards-patternbg .blogs-card-listing .card-content .blogs-ctn .blogs-box .cms-blog-readmore {
            width: 100%;
            display: block;
            color: #000000;
            font-size: 15px;
            text-align: left;
            padding: 5px 28px 20px 28px;
            text-transform: uppercase; }
            @media only screen and (max-width: 1300px) {
              .blogs-cards-patternbg .blogs-card-listing .card-content .blogs-ctn .blogs-box .cms-blog-readmore {
                padding: 5px 20px 20px 20px; } }
            .blogs-cards-patternbg .blogs-card-listing .card-content .blogs-ctn .blogs-box .cms-blog-readmore a {
              color: #000000;
              font-size: 15px; }
        @media only screen and (max-width: 767px) {
          .blogs-cards-patternbg .blogs-card-listing .card-content .blogs-ctn {
            margin-bottom: 25px; } }
      .blogs-cards-patternbg .blogs-card-listing .card-content .cms-blog-title {
        font-size: 45px;
        text-align: left; }
        @media only screen and (max-width: 1500px) {
          .blogs-cards-patternbg .blogs-card-listing .card-content .cms-blog-title {
            font-size: 40px; } }
        @media only screen and (max-width: 1280px) {
          .blogs-cards-patternbg .blogs-card-listing .card-content .cms-blog-title {
            font-size: 35px; } }
        @media only screen and (max-width: 1000px) {
          .blogs-cards-patternbg .blogs-card-listing .card-content .cms-blog-title {
            font-size: 30px; } }
      .blogs-cards-patternbg .blogs-card-listing .card-content .cms-subblog-title {
        width: 100%;
        display: block;
        font-size: 32px;
        padding-top: 10px;
        padding-bottom: 25px;
        text-align: left; }
        @media only screen and (max-width: 1280px) {
          .blogs-cards-patternbg .blogs-card-listing .card-content .cms-subblog-title {
            font-size: 28px; } }
      .blogs-cards-patternbg .blogs-card-listing .card-content .more-blog-listing {
        width: 100%;
        display: block;
        padding-top: 30px;
        text-align: right; }
        @media only screen and (max-width: 575px) {
          .blogs-cards-patternbg .blogs-card-listing .card-content .more-blog-listing {
            padding-top: 10px; } }
        .blogs-cards-patternbg .blogs-card-listing .card-content .more-blog-listing a {
          font-size: 24px;
          text-transform: uppercase; }
          @media only screen and (max-width: 1280px) {
            .blogs-cards-patternbg .blogs-card-listing .card-content .more-blog-listing a {
              font-size: 21px; } }
        .blogs-cards-patternbg .blogs-card-listing .card-content .more-blog-listing .arrow-right {
          font-size: 28px;
          display: inline-block;
          vertical-align: middle;
          padding-left: 5px;
          vertical-align: top; }

.homebot-wrapper {
  padding: 44px;
  width: 800px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.4); }
  @media only screen and (max-width: 1023px) {
    .homebot-wrapper {
      padding: 44px;
      width: 600px; } }
  @media only screen and (max-width: 767px) {
    .homebot-wrapper {
      padding: 44px;
      width: 500px; } }
  @media only screen and (max-width: 519px) {
    .homebot-wrapper {
      padding: 12px;
      width: 90%; } }

/****************************************************End*************************************************************/
/****************************************************Poi loading start*************************************************************/
.poi-loading-wrapper {
  position: absolute;
  height: auto;
  width: auto;
  z-index: 998;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding: 8px 8px;
  font-size: 14px;
  border-radius: 4px;
  min-width: 300px; }
  .poi-loading-wrapper .poi-loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #555;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    display: inline-block;
    vertical-align: middle; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
  .poi-loading-wrapper .poi-loader-name {
    width: calc(100% - 40px);
    font-size: 15px;
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle; }

/****************************************************Poi loading end*************************************************************/
.redirect-loading {
  position: fixed;
  height: auto;
  width: auto;
  z-index: 998;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  width: 400px; }
  @media only screen and (max-width: 400px) {
    .redirect-loading {
      width: 315px; } }
  .redirect-loading .pd-loader-icon {
    display: inline-block;
    vertical-align: top;
    margin-right: 30px; }
    @media only screen and (max-width: 400px) {
      .redirect-loading .pd-loader-icon {
        margin-right: 20px; } }
    .redirect-loading .pd-loader-icon .loader {
      width: 80px !important;
      height: 80px !important; }
      @media only screen and (max-width: 400px) {
        .redirect-loading .pd-loader-icon .loader {
          width: 70px !important;
          height: 70px !important; } }
  .redirect-loading .pd-loader-text {
    display: inline-block;
    vertical-align: top; }
    .redirect-loading .pd-loader-text .pd-loader-text-t1 {
      font-size: 35px;
      margin-bottom: 16px; }
      @media only screen and (max-width: 400px) {
        .redirect-loading .pd-loader-text .pd-loader-text-t1 {
          margin-bottom: 10px; } }
    .redirect-loading .pd-loader-text .pd-loader-text-t2 {
      font-size: 26px; }
      @media only screen and (max-width: 400px) {
        .redirect-loading .pd-loader-text .pd-loader-text-t2 {
          font-size: 22px; } }

.mls-form-mid {
  width: 100%;
  display: block; }
  .mls-form-mid .mls-list-option {
    width: 100%;
    display: block; }
  .mls-form-mid .mls-from-input {
    width: 100%;
    display: block; }
  .mls-form-mid .error {
    color: #bb0000;
    font-size: 14px;
    text-align: center; }

.outside-links {
  width: 100%;
  display: block;
  text-align: center; }
  .outside-links .link-text-cnt {
    width: 100%;
    display: block;
    margin-bottom: 20px;
    font-size: 18px; }
  .outside-links .outside-link-btn .theme-btn {
    margin: 0 10px; }

.contact-agent-disclaimer-cnt {
  width: 100%;
  display: block;
  font-size: 13px;
  font-style: italic;
  margin-top: 15px;
  background: #F8F7F6;
  padding: 10px;
  border-radius: 8px; }

.accept-signing-cnt {
  width: 100%;
  display: block;
  text-align: center;
  padding-top: 15px;
  border-top: 1px solid #efeaea;
  margin-top: 15px !important; }
  .accept-signing-cnt a {
    padding-left: 3px; }

.terms-cnt-box {
  width: 100%;
  display: block;
  margin-top: 70px; }
  @media only screen and (max-width: 768px) {
    .terms-cnt-box {
      margin-top: 30px; } }
  .terms-cnt-box a {
    text-decoration: underline; }
  .terms-cnt-box .terms-banner-title {
    font-size: 35px; }
    @media only screen and (max-width: 1030px) {
      .terms-cnt-box .terms-banner-title {
        font-size: 27px; } }
  .terms-cnt-box .terms-banner-subtitle {
    font-size: 25px; }
  .terms-cnt-box .terms-cnt-list {
    width: 100%;
    display: block;
    font-size: 14px;
    line-height: 25px; }
    .terms-cnt-box .terms-cnt-list ul {
      list-style: none;
      margin: 0px;
      padding: 0px; }
    .terms-cnt-box .terms-cnt-list ol {
      list-style: disc;
      margin: 0px;
      padding: 0px 0 0 18px; }
      .terms-cnt-box .terms-cnt-list ol li {
        margin: 8px 0;
        padding: 0px 0 0 5px; }
    .terms-cnt-box .terms-cnt-list li {
      margin: 8px 0;
      padding: 0px; }

.term-mlslogo-logo {
  width: 100%;
  display: block; }
  .term-mlslogo-logo img {
    width: auto; }

.widget-blur-loader {
  border: none;
  box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  border-radius: 4px;
  padding: 15px !important;
  font-size: 15px;
  color: #000000 !important;
  font-weight: normal !important; }

.popup-connect-client {
  width: 100%;
  display: block; }
  .popup-connect-client .connect-client-input {
    width: 100%;
    display: block;
    margin-top: 13px; }

@media only screen and (max-width: 767px) {
  .new-row-utilitylist {
    margin-top: 15px; } }

.openn-offer-box {
  text-align: right;
  margin-top: 25px; }
  @media only screen and (max-width: 767px) {
    .openn-offer-box {
      text-align: center;
      margin-top: 13px; } }
  .openn-offer-box .openn-offer-button {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    max-width: 250px; }
    .openn-offer-box .openn-offer-button a {
      display: block; }
    @media only screen and (max-width: 1100px) {
      .openn-offer-box .openn-offer-button {
        max-width: 220px; } }
    @media only screen and (max-width: 767px) {
      .openn-offer-box .openn-offer-button {
        max-width: 200px; } }
    .openn-offer-box .openn-offer-button img {
      width: 100%;
      border-radius: 5px; }

.reg-blog-content .section-title {
  padding: 0 13px; }
  @media only screen and (max-width: 1367px) {
    .reg-blog-content .section-title {
      padding: 0 0px; } }
  @media only screen and (max-width: 1280px) {
    .reg-blog-content .section-title {
      padding: 0 15px; } }
  @media only screen and (max-width: 1199px) {
    .reg-blog-content .section-title {
      padding: 0 0px; } }
  .reg-blog-content .section-title .heading-sub-text {
    font-size: 20px;
    line-height: 20px;
    padding-top: 20px; }

.reg-blog-content .reg-blog-cards {
  width: 100%;
  display: block;
  margin-top: 28px; }

.reg-blog-content .reg-blog-ctn {
  width: 100%;
  display: block;
  text-align: left;
  padding: 0 28px; }
  @media only screen and (max-width: 1367px) {
    .reg-blog-content .reg-blog-ctn {
      padding: 0 15px; } }
  .reg-blog-content .reg-blog-ctn .reg-blog-blox {
    /* width:385px; */
    width: 100%;
    float: none;
    display: inline-block;
    box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    margin-bottom: 30px;
    text-align: left;
    min-height: 358px; }
    @media only screen and (max-width: 1367px) {
      .reg-blog-content .reg-blog-ctn .reg-blog-blox {
        min-height: inherit; } }
    .reg-blog-content .reg-blog-ctn .reg-blog-blox .reg-blog-small-img {
      width: 100%;
      display: block;
      min-height: 184px;
      position: relative;
      overflow: hidden;
      border-radius: 8px 8px 0 0; }
      @media only screen and (max-width: 1000px) {
        .reg-blog-content .reg-blog-ctn .reg-blog-blox .reg-blog-small-img {
          min-height: 160px; } }
      .reg-blog-content .reg-blog-ctn .reg-blog-blox .reg-blog-small-img img {
        width: 100%;
        position: absolute;
        height: auto;
        margin: auto;
        top: -100%;
        right: -100%;
        bottom: -100%;
        left: -100%;
        min-height: 184px;
        border-radius: 8px 8px 0 0; }
        @media only screen and (max-width: 1000px) {
          .reg-blog-content .reg-blog-ctn .reg-blog-blox .reg-blog-small-img img {
            min-height: 160px; } }
    .reg-blog-content .reg-blog-ctn .reg-blog-blox .reg-blog-title {
      width: 100%;
      display: block;
      padding: 28px 28px 0px 28px;
      margin-bottom: 17px;
      font-size: 20px;
      line-height: 25px;
      height: 105px;
      white-space: normal;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis; }
      @media only screen and (max-width: 1200px) {
        .reg-blog-content .reg-blog-ctn .reg-blog-blox .reg-blog-title {
          padding: 20px 20px 0px 20px;
          height: 101px;
          font-size: 18px; } }
      @media only screen and (max-width: 575px) {
        .reg-blog-content .reg-blog-ctn .reg-blog-blox .reg-blog-title {
          font-size: 18px;
          height: inherit;
          display: block;
          -webkit-line-clamp: initial;
          -webkit-box-orient: inherit;
          overflow: inherit;
          text-overflow: inherit;
          margin-bottom: 25px; } }
    .reg-blog-content .reg-blog-ctn .reg-blog-blox .reg-blog-readmore {
      width: 100%;
      display: block;
      color: #000000;
      font-size: 13px;
      text-align: left;
      padding: 15px 28px 20px 28px; }
      .reg-blog-content .reg-blog-ctn .reg-blog-blox .reg-blog-readmore a {
        font-size: 13px; }
      @media only screen and (max-width: 1200px) {
        .reg-blog-content .reg-blog-ctn .reg-blog-blox .reg-blog-readmore {
          padding: 15px 20px 20px 20px; } }

.reg-blog-content .section-more {
  padding: 0 13px;
  width: 100%;
  display: block;
  margin-top: 15px; }
  @media only screen and (max-width: 1367px) {
    .reg-blog-content .section-more {
      padding: 0 0px; } }
  .reg-blog-content .section-more .reg-blog-more-listing {
    text-align: right; }
    .reg-blog-content .section-more .reg-blog-more-listing a {
      font-size: 14px; }

@media only screen and (max-width: 630px) {
  .home-featured-listing .white-card-content.reg-blog-content .container .row {
    flex-wrap: wrap; } }

.ft-app-store-bt {
  max-width: 90px; }
