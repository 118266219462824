$color-left: #53dff7;
$color-right: #92dfd1;
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#53dff7+0,92dfd1+74,92dfd1+74 */
/* .btn-default {
  color: #fff;
  background:var(--theme-primary-color, #1f407b);
  border-color:var(--theme-primary-color, #1f407b);
  &.active, &.focus, &:active, &:focus, &:hover, &[disabled] {
    color: #fff !important;
    background-color:var(--theme-primary-color, #1f407b);
    border-color: var(--theme-primary-color, #1f407b);
    &:focus, &:hover {
      color: #fff !important;
      background:var(--theme-primary-color, #1f407b);
      border-color: var(--theme-primary-color, #1f407b);
    }
  }
  & .badge {
    color: #ffc107;
    background-color: #fff
  }
}

.btn-toggle {
  background-color: #ccc !important;
  border: 1px solid #ccc !important;
  color: #7b7a7a !important;
  padding: 8px 15px !important;
  min-width: 62px;
  &.active {
  background-color:var(--theme-primary-color, #1f407b) !important;
  border: 1px solid var(--theme-primary-color, #1f407b) !important;
  color: #fff !important;
  }
}


.open>.btn-default.dropdown-toggle {
    color: #fff;
    background-color:var(--theme-primary-color, #1f407b);
    border-color:#adadad
} */

.tru-btn {
    // background: #53dff7; /* Old browsers */
    // background: -moz-linear-gradient(left, #53dff7 0%, #92dfd1 74%, #92dfd1 74%); /* FF3.6-15 */
    // background: -webkit-linear-gradient(left, #53dff7 0%,#92dfd1 74%,#92dfd1 74%); /* Chrome10-25,Safari5.1-6 */
    // background: linear-gradient(to right, #53dff7 0%,#92dfd1 74%,#92dfd1 74%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#53dff7', endColorstr='#92dfd1',GradientType=1 ); /* IE6-9 */

    padding: 10px;
    border-radius: 10px;
    border: 0;
    color: white;
    font-size: 15px;
    padding: 8px 30px;
}
.tru-btn-compact {
    padding:8px 10px 7px 10px;
    border-radius:6px;
    border: 0;
    color: white;
    font-size:13px;
}

.tru-btn-big {
    font-size: 20px;
    padding: 10px 30px;
}

.tru-btn.mbl {
    @media only screen and (max-width: 767px){
        font-size: 14px;
        padding: 8px 20px;
    }
}
.tru-btn.resize_btn {
  @media only screen and (max-width:767px){
      font-size: 14px;
      padding:8px 9px;
  }
  @media only screen and (max-width:350px){
    font-size:14px;
    padding:8px 5px;
}
}

.tru-btn-white {
    background-color: white;
    color: black;
    font-weight: 600;
}

.tru-btn-black {
    background-color: black;
    color: white;
}

.tru-btn-theme {background-color:var(--theme-primary-color, #1f407b);}

.tru-btn-default {
    background:var(--theme-primary-color, #1f407b);
 }
 .tru-btn.full-large {
    width:100%;
    text-align:left; 
    padding:12px 20px;
    font-size:25px;
    @media only screen and (max-width: 480px){
        font-size:18px;
        padding:8px 20px;   
    } 
}

 $themes: (
  light: (
    leftColor: lightblue,
    rightColor: lightgreen, 
    fontColor: white
   ),
 dark: (
     leftColor: darkblue,
     rightColor: darkgreen,
    fontColor: black
   )
);

@function get-theme($name) {
    @return map-get($themes, $name);
  }
  
  @function requires($themeName, $props) {
    $theme: get-theme($themeName);
    @each $key in $props {
      @if (map-has-key($theme, $key) == false) {
        @warn "To use this theme requires #{$key} to be set in the map";
        @return false;
      } 
    }
    @return true;
  }

  @mixin button-theme($name) {
    $theme: map-get($themes, $name);
    
    .tru-btn-#{$name} {
        background: #53dff7; /* Old browsers */
        background: -moz-linear-gradient(left, map-get($theme, "leftColor") 0%, map-get($theme, "rightColor") 74%, map-get($theme, "rightColor") 74%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, map-get($theme, "leftColor") 0%,map-get($theme, "rightColor") 74%,map-get($theme, "rightColor") 74%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, map-get($theme, "leftColor") 0%,map-get($theme, "rightColor") 74%,map-get($theme, "rightColor") 74%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=map-get($theme, "leftColor"), endColorstr=map-get($theme, "rightColor"),GradientType=1 ); /* IE6-9 */
        color: map-get($theme, "fontColor");
    }
  }

  $button-requirements: fontColor, leftColor, rightColor;

  @each $themeName in map-keys($themes) {
    @if (requires($themeName, $button-requirements)) {
        @include button-theme($themeName);      
    }
  }

/* .modal-content .modal-footer .btn-default, .modal-content .modal-footer .btn {
    border-radius:10px;
    border:0;
    color: #fff;
    font-size:20px;
    padding:10px 30px;
    background-color:var(--theme-primary-color, #1f407b) !important;
    font-weight:normal;
} */