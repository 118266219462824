@import '_mixins.scss';

.contactus-send-message {
	width: 100%;
	display: block;
	/* margin-top: 30px; */
	/* @include breakpointMax(1367) {
		padding: 0 15px;
	} */
	& .contactus-small-label {
		font-size: 14px;
		display: block;
		margin-top: 20px;
		margin-bottom: 15px;
		@include breakpointMax(1199) {
			margin-top: 20px;
			margin-bottom: 8px;
		}
		@include breakpointMax(767) {
			margin-top: 15px;
			margin-bottom: 4px;
		}
	}
	& .office-info {
		display: block;
		font-size: 14px;
		word-break: break-word;
		&.contactus-social {
			& .contactus-social-icon {
				display: inline-block;
				margin-left: 22px;
				&:first-child {
					margin-left: 0px;
				}
				& .svg-inline--fa {
					font-size: 15px;
				}
			}
		}
	}
	
	& .contactus-field-input {
		border-radius: 5px;
		min-height: 40px;
		padding: 10px;
		font-size: 13px;
		width: 100%;
		display: block;
		text-align:left;
		/* box-shadow: 0 4px 7px 3px rgba(0,0,0,0.07); */
		border: 1px solid #ddd;
		&:disabled {
			background: #f2f2f2;
		}
		& .type-select {
			& .react-select__control {
				border: none;
			}
			& .react-select__single-value {
				font-size: 13px;
			}
		}
		&.without-label-dropdown {
			padding:0px;
			& .type-select {
				padding:10px 0px;
				& .react-select__value-container {
					padding: 2px 10px
				}
			}
		}
		&.react-select-box-container {
			&.contact-option-dropdown {
				box-shadow: inherit;
				width: 100%;
				padding: 0px;
				& .react-select-box {
					padding: 17px 0;
					&:focus {
						box-shadow: inherit;
					}
					& .react-select-box-label {
						font-weight: normal;
						font-size: 13px;
					}
				}
				& .react-select-box-options {
					width: 100%;
				}
			}
		}
	}
	& .contactus-info {
		display: block;
		font-size: 14px;
		word-break: break-word;
		line-height: 22px;
		&.contactus-social {
			& .contactus-social-icon {
				display: inline-block;
				margin-left: 22px;
				&:first-child {
					margin-left: 0px;
				}
				& .svg-inline--fa {
					font-size: 15px;
				}
			}
		}
	}
	
}

.download-kit {
	font-size: 17px;
	display: inline-block;
	text-align: right;
	& .pdf-icon {
		font-size: 22px;
    	color: #ff0000;
		padding-left: 5px;
	}
}
