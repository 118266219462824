@import '_mixins.scss';

.container-login {
	width: 100%;  
	min-height:100vh;
	display:flex;
	@include breakpointMax(767) {
		display:block;
		overflow:hidden;
	}
}

/********************login Left site start**********************************/
.login-lt-cnt {
	display:table;
	height:100vh;
	& .login-form {
		display:table-cell;
		vertical-align:middle;
		& .form-mid {
			width:430px;
			@include breakpointMax(1025) {
				width:100%;
			}
		}
	}
}
.login-header {
	font-size:32px;
	line-height:42px;
	margin-bottom:28px;
	& span {
		display:block;
	}
	@include breakpointMax(768) {
		font-size:26px;
		line-height:34px;
	}
	@include breakpointMax(480) {
		font-size:23px;
		line-height:34px;
	}
}
/********************login logo start**********************************/
.login-logo {
	width: 100%;
	text-align: center;
	margin-bottom: 60px;
	@include breakpointMax(767) {
		margin-bottom:20px;
		margin-top:15px;
	}
	& img {
		max-height: 60px;
		width: auto;
		max-width: 270px;
	}
}


/********************login social start**********************************/
.login-social {
	display:block;
	width:100%;	
	&.sm-social {
		text-align: center;
	}
	& .social-btn {
		font-size:16px;
		padding:10px 10px 10px 25px;
		width:100%;
		display:block;
		height:55px;
		box-shadow:0 1px 5px 0px rgba(0, 0, 0, 0.2);
		border-radius:5px;
		align-items: center;
		display:flex;
		justify-content:left;
		margin-bottom:15px;
		cursor:pointer;
		background-color:#fff;
		& a {
			font-size:18px;
			display:inline-block;
			@include breakpointMax(480) {
				font-size:16px;
			}
		}
		&.btn-google {
			color:#033258;
			& a {
				color:#033258;
				&:hover {
					color:#033258;
				}
			}
			& img {
				width:26px;
    			margin-right:15px;
			}
		}
		&.btn-face {
			color:#ffffff;
			background-color:#3B5998;
			& a {
				color:#ffffff;
				&:hover {
					color:#ffffff;
				}
			}
			& i {
				margin-right:30px;
			}
		}
		&.btn-lin {
			color:#ffffff;
			background-color:#0077b5;
			& a {
				color:#ffffff;
				&:hover {
					color:#ffffff;
				}
			}
			& i {
				margin-right:25px;
			}
		}
		&.btn-twit {
			color:#ffffff;
			background-color:#1D9BF0;
			& a {
				color:#ffffff;
				&:hover {
					color:#ffffff;
				}
			}
			& i {
				margin-right:25px;
			}
		}
		&.btn-git {
			color:#ffffff;
			background-color:#24292F;
			& a {
				color:#ffffff;
				&:hover {
					color:#ffffff;
				}
			}
			& i {
				margin-right:25px;
			}
		}
		&.btn-amz {
			color:#ffffff;
			background-color:#FF9900;
			& a {
				color:#ffffff;
				&:hover {
					color:#ffffff;
				}
			}
			& i {
				margin-right:25px;
			}
		}
		&.sm-btn {
			display: inline-block;
			padding: 14px;
			margin-right: 10px;
			font-size: 24px;
			text-align: center;
			vertical-align: middle;
			width: 55px;
			&.btn-google {
				& img {
					margin-right:0;
					margin-top: -6px;
				}
			}
			& i {
				margin-right:0;				
			}
		}
	}
}
/********************login social End**********************************/

/*********************login or start*************************************/
.login-or {
	background-color:#e9ebf1;
    height:2px;
	width:100%;
	display:block;
	margin:30px 0 30px 0;
	position:relative;
	& .middle-text {
		display:block;
		position:absolute;
		left:calc(50% - 20px);
		text-align:center;
		font-size:16px;
		width:40px;
		margin-top:-8px;
		background: #f7f7f7;
		color: #033258;
		&.large-text {
			left:calc(50% - 80px);
			font-size:15px;
			width:160px;
		}
	}	
}
.login-social-wrapper {
	margin-top: 24px;
}
/*********************login or End*************************************/
.recaptcha-box {
	width: 100%;
	display: block;
	margin-top: 5px;
	margin-bottom: 20px;
}
/*********************login right start*************************************/
.login-rt-cnt {
	background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
    background-image:url(../img/nd/loginbg.jpg);
	position:relative;
	@include breakpointMax(768) {
		background-position:left;
	}
	& .punchline-text {
		position:absolute;
		top:35%;
		left:0px;
		width:100%;
		font-size:22px;
		line-height:33px;
		text-align:center;
		padding:0 10px;
		color: #033258;
		& span {
			display:block;
		}
	}
	@include breakpointMax(767) {
		display:none;
	}
}
/*********************login right End*************************************/
.login-input {
    border:1px solid #979797;
    border-radius:4px;
    font-size:17px;
    min-height:55px;
	background: #f7f7f7;
}
/*********************login User Type End*************************************/
.login-signin, .login-signup {
	& .continue-button {
		min-width:112px;
	}
	& .w-60 {
		@include breakpointMax(567) {
			width:60%;
		}
	}
	& .w-40 {
		@include breakpointMax(567) {
			width:40%;
		}
	}
}
/******  Recaptcha ******/
.recaptcha {        
	border:1px solid #dddddd;
	border-radius:4px;
	width:auto;
	@include breakpointMax(375) {
		width:100%;
	}
  };