@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
      @content;
    }
  }
  
  @mixin placeholder {
    @include optional-at-root('::-webkit-input-placeholder') {
      @content;
    }
  
    @include optional-at-root(':-moz-placeholder') {
      @content;
    }
  
    @include optional-at-root('::-moz-placeholder') {
      @content;
    }
  
    @include optional-at-root(':-ms-input-placeholder') {
      @content;
    }
  }

@mixin linearGradient($direction, $one, $two) { 
  background: -webkit-linear-gradient($direction, $one 0%, $two 100%);
  background: -moz-linear-gradient($direction, $one 0%, $two 100%);
  background: -o-linear-gradient($direction, $one 0%, $two 100%);
  background: linear-gradient(to $direction, $one 0%, $two 100%); 
}

@mixin breakpointMax($size) {
  @media only screen and (max-width : $size + px) { @content; }
}

@mixin breakpointMin($size) {
  @media only screen and (min-width : $size + px) { @content; }
}