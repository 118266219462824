
input, textarea {
	margin:0px;
	padding:10px 15px;
	border-radius:4px;
    width:100%;
	outline:none;
    border:none;
    background:#fff;
    font-size:16px;
	color: #4A4A4A;
	/* appearance: none; */
}
input::placeholder, textarea::placeholder {
    color:#bfbfbf;
}
.input-with-border {
	border:1px solid #dddddd;
	min-height:32px;
	font-size:13px;
	width:100%;
	padding:10px;
	text-align:left;
	&.border-error {
		border-color: #ff0000;
	}
	&:disabled {
		background: #f2f2f2;
	}
	&.small-height {
		min-height:32px;
		padding:4px 10px;
	}
}
.input-with-border.medium-height {
	min-height:45px;
}
textarea {
	outline:none;
	border-radius: 4px;
}
.btn {
    font-family:'RobotoMedium';
}
.btn-group {
	margin-bottom:15px;  
}
.label-error {
	float:right !important;
	font-size:13px !important;
	margin:-22px 8px 0 !important;
	color:#ff0000 !important;  
}
/***************************button**********************************/
.theme-btn {
    border:none;
	border:none;
	border-radius:4px;
	color:#fff;
	font-size:15px;
    padding:10px 15px;
    font-weight:normal;
    line-height:normal;
    display:inline-block;
    margin:0px;
    &:hover {
        color:#fff;
    }
    &:focus {
        box-shadow:none;
        outline:none;
	}
	&:disabled {
		cursor:not-allowed;
	}	
    &.btn-primary {
        background-color: #00adbb !important;
        border-color:inherit;
        &:hover {
            color:#fff;
            background-color: #00939f !important;
        }
        &:focus {
            box-shadow:none;
            outline:none;
            background: #007882 !important;
        }
		&:active {
			box-shadow:none;
            outline:none;
            background: #007882 !important;
		}
	}
	&.min-width {
		min-width:110px;
	}
	&.small-padding-btn {
		padding:4px 15px;
	}
	&.min-width-80 {
		min-width:80px;
	}
	&.theme-white-btn {
		padding:9px 15px;
		/* background:#fff; */
		border: 1px solid;
		/* &:hover {
			color:#fff;
		} */
		&:focus {
			box-shadow:none;
			outline:none;
			/* color:#fff; */
		}
	}
}
/**************************type searching ***********************************/
.typeahead-search {
	border-radius:5px;
	height:55px;
	padding:0px;
	font-size:16px;
	width:100%;
	display:inline-block;
	vertical-align:top;
	background:#ffffff;
	@include breakpointMax(767) {
		font-size: 13px;
		height: 55px;
		width: 100%;
		margin-bottom: 20px;
	}
	& .form-control {
		border: none;
		font-size: 15px;
		height: 100%;
		padding: 10px 15px;
		@include breakpointMax(767) {
			padding:11px 12px;
		}
		&:focus {
			box-shadow:none;
		}
		::placeholder {
			color: #9B9B9B;
		}
	}
	& .rbt-input-main {
		&::placeholder {
			color: #9B9B9B;
		}
	}
	& .rbt-input-hint {
		height: 100%;
	}
	& .dropdown-menu {
		margin:0px;
		border-radius: 4px;
		& .dropdown-header {
			font-size: 14px;
		}
		& .dropdown-item {
			padding: 7px 10px 7px 14px;
			font-family: 'GothamBook';
			font-size: 13px;
			&:hover, &:active {
				background:#f5f5f5 ;
			}
		}
	}
	& button {
		&.close {
			font-size: 24px;
			line-height: 12px;
			padding-right: 15px;
		}
	}
}
/*****************select dropdwon start**************/
.type-select {
    outline:none;
    font-size:13px;
	color: #033258;
	&.type-select-33-ht {
		& .react-select__control {
			min-height:33px;
		}
	}
	& .react-select__menu {
			box-shadow: inherit;	
		 	border: 1px solid #ddd;	
			margin-bottom: 0px;
		& .react-select__menu-list {
			max-height:220px;	
			margin-top: -1px;
		}
	}
	& .react-select__control {
		border:1px solid #DDDDDD;
		box-shadow:none;
		min-height:40px;
		&:hover, &:focus {
			border:1px solid #DDDDDD;
		}
		&:focus {
			border:none;
		}
		& .react-select__indicator {
			position:relative;
			padding:0;
			min-height:30px;
			&:after {
				position:absolute;
				content: "\F107";
				font-family: "FontAwesome";
				top: 0px;
				right:10px;
				border: none;
				font-size:16px;
				margin: 0;
				line-height:30px;
				color: #033258;
			}
			& svg {
				display:none;
			}
		}
		& .css-1okebmr-indicatorSeparator {
			display:none;
		}
		& .css-1n7v3ny-option {
			background-color: #f5f5f5 !important;
		}
		& .react-select__single-value {
			font-size:13px;
			max-width:calc(100% - 34px);
		}
		& .react-select__value-container {
			overflow:inherit;
		}
		& .css-b8ldur-Input {
			width: 100%;
			padding-right:20px;
			& .react-select__input {
				width:100%;
				& input {
					width:100% !important;
				}
			}
		}
}
	& .react-select__menu {
		/* dropdown top space */
		margin-top:0px;
		/* dropdown top space */
		& .react-select__menu-list {
			padding-bottom:0px;
			padding-top:0px;
			border-radius:4px;
			& .react-select__option {
				color: #033258;
				font-size:13px;
				outline:none;
				word-break: break-word;
				overflow-wrap: break-word;
				&.css-9gakcf-option {
					background-color: #f5f5f5;
				}
				&.css-1n7v3ny-option {
					background-color: #f5f5f5;
				}
			}
		}
	}
}
/*****************select dropdwon End**************/

/*****************breadcrumb start**************/
.breadcrumb {
	display:inline-block;
	vertical-align:top;
	padding-left:10px;
	font-size:14px;
	background:none;
	padding: 0;
    margin: 0;
	&:before {
		padding-right:5px;
	}
	& .breadcrumb {
		background: #ffffff;
		padding:0px;
		margin-bottom:0px;
		display:inline-block;
		vertical-align:top;
		font-size:14px;
		color: #9B9B9B;
		& .breadcrumb-item {
			padding:0 0 0 10px;
			display:inline-block;
			&:before {
				padding-right:5px;
				content: "\f105" !important;
				font-family: "FontAwesome";
			}
		}
	}
}
/*****************breadcrumb End**************/

/**************Range without value******************/
.price-range-box {
	width:100%;
	display:block;
	margin-top:21px;
	& .input-range__label-container {
		display:none;
	}
	& .input-range__track {
		height:1px;
		background: #dddddd;
		&.input-range__track--active {
			background:  #00adbb;
		}
	}
	& .input-range__slider {
		width:10px;
		height:10px;
		margin-top:-5px;
	}
}
/**************Range without value******************/

/**********Tap list start**********/
.tab-list {
	& .nav-tabs {
			border-bottom: 1px solid #dddddd;
		& .nav-item {
			margin-bottom:0px;
		}
		& .nav-link {
			border:none;
			outline:none;
			padding: 0px 0 15px 0;
			margin-right:30px;
			font-size:14px; 
			&:last-child {
				margin-right:0px;
			}
			@include breakpointMax(990) {
				margin-right:12px;
			}
			@include breakpointMax(576) {
				margin-right:10px;
				padding: 0px 0 10px 0;
				font-size:12px;
			}
			@include breakpointMax(374) {
				margin-right:8px;
				padding: 0px 0 10px 0;
				font-size:10px;
			}
		}
		&.center-tabs {
			text-align: center;
			display: block;
			width: 100%;
			& .nav-link {
				display: inline-block;
			}
		}
		& .tabicon-text {
			display:inline-block;
			vertical-align:middle;
			@include breakpointMax(767) {
				display:none;
			}
		}
		& .tabicon-box  {
			display:inline-block;
			vertical-align:middle;
			margin-right:10px;
			@include breakpointMax(990) {
				margin-right:7px;
				font-size:22px;
			}
			@include breakpointMax(767) {
				margin-right:0px;
				padding:0 10px;
			}
			@include breakpointMax(374) {
				margin-right:0px;
				padding:0 10px;
			}
		}
	}
	& .tab-container {
		width:100%;
		display:inline-block;
		padding:20px 15px 0px 15px;
		@include breakpointMax(767) {
			padding:20px 0px;
		}
	}
	& .nav-pills {
		border-right:1px solid #dddddd;
		@include breakpointMax(1023) {
			border-right:none;
			margin-bottom:25px;
		}
		& .nav-item {
			min-height:36px;
			margin-top:10px;
			@include breakpointMax(1023) {
				width:50%;
				padding:0 10px;
				display:inline-block;
				margin-top:5px;
				margin-bottom:5px;
				vertical-align:top;
			}
			&:first-child {
				margin-top:0px;
			}
			& .nav-link {
				border-radius:inherit;
				font-size:14px;	
				padding:0 0 10px 0;
				display:block;
				border-bottom:2px solid #ffffff;
				@include breakpointMax(1230) {
					font-size:13px;
				}
				@include breakpointMax(1023) {
					padding:0 0 10px 0;
				}
				&:focus {
					outline:none;
				}
				&.active {
					background-color:inherit;
					&:focus {
						outline:none;
					}
				}
				& .tabicon-box {
					font-size:19px;
					float:left;
					overflow:hidden;
					width:25px;
					height:25px;
					line-height:25px;
					margin-right:10px;
					@include breakpointMax(1230) {
						margin-right:7px;
						width:20px;
					}
				}
				& .tabicon-text {
					display:block;
					overflow:hidden;
					padding-right:23px;
					line-height:19px;
				}
			}
		}
	}
	&.vertical-tab{
		& .nav-pills {
			@include breakpointMax(1023) {
				display:inline-block;
				width:100%;
			}
			height:100%;
		}
	}
}
/**********Tap list End**********/
/*****************multi select dropdwon start**************/
.multi-select {
	& .dropdown-container {
		border:1px solid #DDDDDD;
		&:focus-within {
			box-shadow:none;
			border:1px solid #DDDDDD;
		}
		& .dropdown-heading {
			&:after {
				position:absolute;
				content: "\F107";
				font-family: FontAwesome;
				top: 0px;
				right:10px;
				border: none;
				font-size:16px;
				margin: 0;
				line-height:36px;
			}
			& .dropdown-heading-value {
				margin-top:0px;
				font-size:13px;
				font-weight:normal;
				padding:0 20px 0 0;
				& .gray {
					font-size:13px;
				}
			}
			& .dropdown-heading-dropdown-arrow {
				display:none;
			}
			& .clear-selected-button {	
				display: none;	
			}
		}
		& .dropdown-content {
			margin-top: -1px;
			& .panel-content {
				box-shadow: inherit;	
				border: 1px solid #ddd;	
				border-radius: 4px;
				padding:7px 16px 15px 16px;
				height:220px;
				overflow-y:scroll;
				margin-top:-6px;
				& .options {	
					overflow: inherit;	
					margin-bottom:0px;	
					display: inline-block;	
					max-height: 100%;	
					width: 100%;	
					& label {	
						margin-top: 0;	
					}	
					& .item-renderer {	
						display: block;	
					}	
				}
				& .select-panel {
					font-size:13px;
					font-family: 'GothamBook';
					& .select-item {
						width:100%;
						float:left;
						padding-left:0px;
						padding-right:0px;
						padding-bottom:2px;
						padding-top:7px;
						margin-top:5px;
						margin-bottom:0px;
						line-height: normal;
						&.selected {
							background:none !important;
						}
						&:hover, &:focus{
							background:none !important;
						}
						& .item-renderer {
							& input[type=checkbox] {
								display:inline-block;
								vertical-align:middle;
								width:18px;
								height:18px;
								background: #ffffff;
								border: 2px solid #757575;
								border-radius:3px;
								margin:0px;
								appearance: none;
								position: relative;
								cursor:pointer;
								&::before {
									position: absolute;
									content: '';
									display: block;
									top:-1px;
									left:5px;
									width:5px;
									height:11px;
									border: solid white;
									border-width: 0 2px 2px 0;
									transform: rotate(45deg);
									opacity: 0;
								  }
								  &:checked {
									color: #ffffff;
									&::before {
									  opacity: 1;
									}
								  }
							}
							& span {
								display:inline-block;
								cursor: pointer;
								font-size:13px;
								font-family: 'GothamBook';
								padding-left:15px;
								vertical-align: middle;	
								margin-top: 0px;
								width:calc(100% - 30px);
							}
						}
					}
				}
			}
		}
	}
	&.rmsc {
		& .dropdown-container {
			border: 1px solid #ddd;
			border-radius: 4px;
			&:focus-within {
				box-shadow: inherit;
				border-color:#DDDDDD;
			}
		}
	}
}
.bottom-multiselect {
	& .multi-select {
		& .dropdown-content {
			top:inherit !important;
			bottom: 38px !important;
			@include breakpointMax(1199) {
				top:39px !important;
				bottom:inherit !important;
			}
		}
	}
}
/*****************multi select dropdwon End**************/

/********table td data start***********************/
.area-data {
	width:100%;
	max-width:100%;
	background-color:#fff;
	color: #7B8AA0;
	& thead {
		@include breakpointMax(767) {
			display:none;
		}
		& th {
			color: #B6BDC4;
			font-size:12px;	
			border-bottom:1px solid #E9EBF1;
			padding-bottom:16px;
			font-weight:normal;
		}
	}
	& tbody {
		& tr {
			@include breakpointMax(767) {
				display:block;
				margin-bottom:12px;
			}
			& th {
				font-size:12px;	
				border-bottom:1px solid #E9EBF1;
				@include breakpointMax(767) {
					font-size:13px;
				}
			}
			& td {
				font-size:12px;	
				padding:10px 7px;
				border-bottom:1px solid #E9EBF1;
				word-break: break-all;
				vertical-align:top;
				@include breakpointMax(767) {
					font-size:13px;
					padding:10px 16px;
				}
				&:first-child {
					text-align:left;
					@include breakpointMax(767) {
						padding:10px 7px;
					}
				}
				&:last-child {
					@include breakpointMax(767) {
						border-bottom:none;
					}
				}
				&:nth-child(1) {
					width:40%;
					padding:10px 7px 10px 0px;
					@include breakpointMax(767) {
						padding:10px 16px 10px 16px;
					}
				}
				&:nth-child(2) {
					width:20%;
				}
				&:nth-child(3) {
					width:20%;
				}
				&:nth-child(4) {
					width:20%;
				}
				@include breakpointMax(767) {
					display:block;
					text-align:left;
					&:nth-child(1) {
						width:100%;
					}
					&:nth-child(2) {
						width:100%;
					}
					&:nth-child(3) {
						width:100%;
					}
					&:nth-child(4) {
						width:100%;
					}
					&::before {
						content:attr(data-title);
						width:100%;
						text-align:left;
						font-weight:600;
					}
					& .label-value {
						margin-top:7px;
						display:block;	
					}
					&:nth-child(odd) {
						background:  #f1f1f1;
					}
					&:nth-child(even) {
						background:  #f7f7f7;
					}
				}
			}
		}
	}
	/********rating data******/
	& .rating-number{
		width:26px;
		height:14px;
		border-radius:6px;
		font-size:8px;
		text-align:center;
		display:inline-block;
		line-height:15px;
		&.green-rating {
			background:#CDF9A0;
			color:#3A9F00;
		} 
		&.orange-rating {
			background:#FFDCA4;
			color:#EE8822 ;
		}
		&.red-rating {
			background:#FAAEB8;
			color:#D0021B ;
		}
	}
	/********rating data******/
	&.multi-searches-data {
		border:1px solid  #dddddd;
		@include breakpointMax(480) {
			border:none;
		}
		& thead {
			& th {
				padding:15px 15px 15px 15px;
				background: #9B9B9B;
				color:#ffffff;
				font-weight:600;
				@include breakpointMax(1024) {
					padding:10px 10px 10px 10px;
				}
			}
		}
		& tbody {
			& tr {
				@include breakpointMax(767) {
					margin-bottom:10px !important;
					&:last-child {
						margin-bottom:0px !important;
					}
				}
				&:nth-child(odd) {
					background-color: #f7f7f7;
				}
				&:last-child {
					& td {
						border-bottom:none;
					}
				}
				& td {
					font-size:13px;
					padding:15px 15px 15px 15px;
					border-bottom:1px solid  #dddddd;
					@include breakpointMax(1024) {
						padding:10px 10px 10px 10px;
					}
					@include breakpointMax(767) {
						max-width:100%;
						padding:10px 15px 10px 15px;
					}
					&:first-child {
						@include breakpointMax(767) {
							max-width:100%;
							padding:10px 13px 10px 13px;
						}
					}
					&.nt-icon-alignment {
						text-align:right;
						&:before {
							padding-bottom:0px;
							margin-top:-10px;
						}
						@include breakpointMax(767) {
							text-align:center;
						}
					}
					&:nth-child(1) {
						width:inherit;
					}
					&:nth-child(2) {
						width:inherit;
					}
					&:nth-child(3) {
						width:inherit;
					}
					&:nth-child(4) {
						width:inherit;
					}
					&.pd-lt-0 {
						padding-left:0px;
						@include breakpointMax(767) {
							padding-left:15px;
						}
					}
					&.pd-rt-0 {
						padding-right:0px;
						@include breakpointMax(767) {
							padding-right:10px;
						}
					}
				}
			}
		}
		& .search-notification-icon {
			display:inline-block;
			& .material-icons {
				display:inline-block;
				margin-left:10px;
				cursor:pointer;
				@include breakpointMax(1199) {
					font-size:20px;
				}
				&:first-child {
					margin-left:0px;
				}
			}
		}
		& .common-checkbox {
			padding-left:0px;
			@include breakpointMax(767) {
				display:inline-block;
			}
			& .checkmark {
				height:15px;
				width:15px;
				&:after {
					left:3px;
					top:-1px;
				}
			}
		}
	}
	
}
.save-searches-data {
	width: 100%;
	font-size: 14px;
	margin-bottom: 25px;
	word-break: break-word;
	@include breakpointMax(1024) {
		font-size: 13px;
	}
	@include breakpointMax(767){
		/* border: 1px solid #dddddd; */
		margin-bottom: 25px;
	}
	& thead  {
		& tr {
			& th {
				color: #3f4259;
				background: #f3f6f9;
				padding-top: 15px;
				padding-bottom: 15px;
				text-transform: uppercase;
				&:first-child {
					border-radius: 5px 0 0 5px;
				}
				&:last-child {
					border-radius: 0px 5px 5px 0px;
				}
			}
		}
		@include breakpointMax(767) {
			display:none;
		}
	}
	& tbody {
		& tr {
				border-bottom: 1px solid #ebedf3;
				&:hover {
					background-color: #f3f6f9;
				}
			@include breakpointMax(767) {
				display: block;
				margin-bottom: 0px;
				border-bottom: 1px solid #dddddd;
				margin: 0px;
				padding: 10px 0;
				&:last-child {
					border-bottom:none;
				}
			}
		}
		& td {
			padding-top: 25px;
			padding-bottom: 25px;
			color: #8a8c9f;
			vertical-align: top;
			@include breakpointMax(767) {
				max-width:100%;
				display: block;
				text-align: left;
				padding-top: 10px;
				padding-bottom: 10px;
				margin-bottom: 0px;
				&::before {
					content:attr(data-title);
					width: 100%;
					text-align: left;
					font-weight: 600;
					color: #3f4259;
				}
				& .label-value {
					display:block;
					@include breakpointMax(767) {
						padding-top: 5px;
						margin-left: 0px;
					}
				}
				&:last-child {
					margin-bottom: 0px;
				}
				&.no-title {
					&::before {
						display: none;
					}
				}
			}
		}
	}
	& .tags-data {
		display: block;
		width: 100%;
		@include breakpointMax(767) {
			margin-top: 7px;
		}
		& .tags-box {
			display: inline-block;
			padding-right: 6px;
			margin-right: 6px;
			margin-bottom: 6px;
			border-right: 1px solid #9b9b9b;
			&:last-child {
				border-right:none;
			}
		}
	}
	& .search-notification-icon {
		display: inline-block;
		text-align: right;
		width: 100%;
		@include breakpointMax(767) {
			text-align: center;
			margin-bottom: 0px;
		}
		& .edit-icon {
			cursor:pointer;
			margin-left:8px;
			font-size:22px;
			@include breakpointMax(1366) {
				margin-left:6px;
				font-size:20px;
			}
			@include breakpointMax(1024) {
				font-size:20px;
			}
			@include breakpointMax(1000) {
				font-size:17px;
			}
			@include breakpointMax(767) {
				font-size:22px;
				margin-left:10px;
				margin-right:10px;
			}
			&:first-child {
				margin-left:0px;
			}
			&.exchange-icon {
				font-size: 16px;
			}
		}
	}
	& .td-stages {
		& .filters-stages {
			z-index: 999;
		}
	}
}
.table-top-title {
	font-size: 14px;
	line-height: 20px;
}
.save-search-table-header {
	& .filter-title {
		font-size: 16px;
		display: inline-block;
		vertical-align: middle;
		padding-top: 8px;
	}
	& .table-search-filter {
		@include breakpointMax(991) {
			margin-top: 20px;
		}
		& .typeahead-search {
			height: 40px;
			width: calc(100% - 81px);
			display: inline-block;
			vertical-align: top;
			& .dropdown-menu {
				top:0px;
				padding:0px;
			}
			& .form-control {
				font-size: 13px;
				border: 1px solid #ddd;
				padding: 10px;
			}
		}
		& .theme-btn {
			margin-left: 15px;
		}
	}
}
.save-search-table-footer {
	& .table-search-pagination {
		text-align: right;
		@include breakpointMax(575) {
			text-align: center;
			margin-top: 10px;
		}
		& .pagination  {
			& li {
				&:last-child {
					& a {
						padding-right: 0px;
						@include breakpointMax(575) {
							padding-right: 8px;
						}
					}
				}
			}
		}
	}
	& .table-showing-number {
		font-size: 15px;
		@include breakpointMax(575) {
			text-align: center;
			margin-bottom: 10px;
		}
	}
}

/********table td data End***********************/

/********content text p start***********************/
p {
	line-height:22px;
	margin:7px 0;
}
/********content text p start***********************/
/********datepicker start***********************/
.react-datepicker-wrapper {
	border:1px solid  #dddddd !important;
	border-radius:4px;
	min-height:40px;
	width:100%;
	& .react-datepicker__input-container {
		& input {
			font-size:14px;
			min-height:38px;
		}
	}
}
/********datepicker End***********************/