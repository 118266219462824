@import '_variables.scss';
@import '_mixins.scss';
@import 'shared/_text.scss';
@import 'shared/_button.scss';
@import 'shared/_input.scss';
@import 'shared/_form.scss';
@import 'shared/_page.scss';

/* @include breakpointMax(768){
    .tab-pane {
        padding: 15px 0;
    }   
} */
.FilsonProFont {
    font-family:"FilsonProRegular";
    & h1, h2, h3, h4, h5 {
        font-family:"FilsonProRegular";
    }
    & input, button, select, textarea {
        font-family:"FilsonProRegular";
    }
    & .mls_update_time {
        font-family:"FilsonProLight";
    }
    & .footer_address {
        font-family:"FilsonProLight";
    }
    & .tru-bold {
        font-family:"FilsonProRegular";
        font-weight:900;
    }
    & .new_stylemenu {
            font-family:"FilsonProMedium";
        & .dropdown-menu {
            font-family:"FilsonProRegular";
        }
        & .dropdown{
            font-family:"FilsonProMedium";
            & button {
                font-family:"FilsonProMedium";
            }
        }
    }
    & .notification-message {
        font-family:"FilsonProRegular" !important;
    }
    & .tru-reg {
        font-family:"FilsonProRegular";
    }
    & .popover {
        font-family:"FilsonProRegular";
    }
    & .othersite_menubox {
        & .top_multi_menu {
            font-family:"FilsonProMedium";
        }
    }
} 

/***********************************Gotham Start*********************/

.GothamFont {
    font-family:"GothamBook";
    & h1, h2, h3, h4, h5 {
        font-family:"GothamBook";
    }
    & input, button, select, textarea {
        font-family:"GothamBook";
    }
    & .mls_update_time {
        font-family:"GothamLight";
    }
    & .footer_address {
        font-family:"GothamLight";
    }
    & .tru-bold {
        font-family:"GothamBook";
        font-weight:900;
    }
    & .new_stylemenu {
            font-family:"GothamMedium";
        & .dropdown-menu {
            font-family:"GothamBook";
        }
        & .dropdown{
                font-family:"GothamMedium";
            & button {
                font-family:"GothamMedium";
            }
        }
        
    }
    & .menu-standard {
        & .othersite_menubox {
            & .header-content {
                & .navbar {
                    &.new_stylemenu {
                        & .nav {
                            & li {
                                & a {
                                        padding:31px 8px 12px 8px !important;
                                    @include breakpointMax(1025) {
                                        padding:10px 10px 10px 10px !important;
                                    }
                                }
                            }
                        }
                        & .user_button_login {
                            & .dropdown {
                                    padding:31px 8px 12px 8px !important; 
                                @include breakpointMax(1025) {
                                    padding:20px 0px 0px 0px !important;
                                } 
                            }
                        }
                    }
                }
            }
        }
        & .new_stylemenu {
            & .nav {
                & li {
                    & .dropdown-menu {
                        & li {
                            & a {
                                    padding:15px 15px 11px 15px;
                                @include breakpointMax(1025){
                                    padding:8px 15px 8px 25px !important;
                                }
                            }
                        }
                    }
                    & a {
                            padding:23px 6px 20px 6px;
                            @include breakpointMax(1140){
                                padding:23px 3px 20px 3px;
                            }
                        @include breakpointMax(1025){
                            padding:10px 10px;
                        }
                    }
                }
            }
        }
        & .user_button_login {
            & .dropdown {
                    padding:23px 6px 20px 6px;
                @include breakpointMax(1025){
                    padding:20px 0 0 0;
                }
            }
        }
    }
    & .menu-large {
        & .othersite_menubox {
            & .header-content {
                & .navbar {
                    &.new_stylemenu {
                        & .nav {
                            & li {
                                & a {
                                        padding:58px 8px 25px 8px !important;
                                    @include breakpointMax(1025){
                                        padding:10px 10px !important;
                                    }
                                }
                            }
                        }
                        & .user_button_login {
                            & .dropdown {
                                    padding:58px 8px 25px 8px  !important;
                                @include breakpointMax(1025){
                                    padding:20px 0 0 0 !important;
                                }
                            }
                        }
                    }
                }
            }
        }
        & .header-content {
            & .navbar {
                &.new_stylemenu {
                    & .nav {
                        & li {
                            & a {
                                    padding:43px 6px 40px 6px;
                                @include breakpointMax(1160){
                                    padding:43px 4px 40px 4px;
                                }
                                @include breakpointMax(1025){
                                    padding:10px 10px;
                                }
                            }
                        }
                    }
                    & .user_button_login {
                        & .dropdown {
                                padding:43px 6px 40px 6px;
                            @include breakpointMax(1025){
                                padding:20px 0 0 0;
                            }
                        }
                    }
                }
            }
        }
        & .header-top-bar {
            & .header-list-right {
                &.personalize_photo {
                    top:31px;
                    @include breakpointMax(767){
                        top:36px;
                    }
                }
            }
        }
    }
    & .notification-message {
        font-family:"GothamBook" !important;
    }
    & .tru-reg {
        font-family:"GothamBook";
    }
    & .prop_info_box {
        font-family:"GothamBook";
    }
    & .popover {
        font-family:"GothamBook";
    }
    & .matching-listing-number {
        font-family:"GothamMedium";
    }
    & .othersite_menubox {
        & .top_multi_menu {
            font-family:"GothamMedium";
        }
    }
}
/***********************************Gotham End***********************************/
 
.panel-body {
    padding: 15px 0;
}
.tru-pencil {
    vertical-align:top; 

    @include breakpointMax(768){
        height:17px;
    }
}
.add-select-client {
	a {
		display: block;
	}
	.top-action {
		position: relative;
	}
}
.client-close-icon {
	position: absolute;
	top: 34%;
	right: 12pX;
}
.tru-commute {
	display:inline-block;
    text-align:center;
    line-height:normal;
} 
.tru-commute img {
	padding:9px;
    width:45px;
    height:45px;
}
/* .leaflet-touch .leaflet-control-layers-toggle {
	background-size:100% !important;
} */
/* .pphoto-wrapper {
    border: 1px solid #ddd;
    float: left;
    .pphoto-note-wrapper {
        padding: 15px;
        background: #eee;
        .pphoto-note {
            font-weight: 500; 
            margin: 0;       
        }
    }   
    .pphoto-img-wrapper {
        position: relative;
        width: 82px;
        height: 82px;
        margin: 0 auto;
        background: #efefef;
        border: 1px solid #ddd;
        border-radius: 50%;
    } 
} */

.breadcrumbheader {
    width:100%;
    float:left;
    margin:0;
    padding-top:0;
    @include breakpointMax(767){
        padding-top:40px !important;
        margin:0;
        padding-left:0px; 
    }
}
    
.add_alert_scroll > .popover-content {
    max-height:188px;
    overflow-y:auto;  
}
.ad-main-wrapper {
    vertical-align: top;
    .ad-sub-wrapper {
        margin-bottom: 16px;
        width: 100%;
        height: 244px;
        overflow: hidden;
        background: rgb(178, 178, 178);
        img {
            width: 100%;
            height: 244px;
        }
    }
    &.compact { 
        .ad-sub-wrapper {
            margin-bottom: 20px; 
            width: 100%;
            height: 258px;
            overflow: hidden;
            background: rgb(178, 178, 178);
            img {
                height:258px;
                vertical-align:initial;
            }
        }
        &.fpr { 
            .ad-sub-wrapper {
                width: 100%;
                height: 240px;
                img {
                    height:240px;
                }                
            }        
        }
        &.foh { 
            .ad-sub-wrapper {
                width: 100%;
                height: 258px;
                img {
                    height:258px;
                }
            }        
        }
        &.fhp { 
            .ad-sub-wrapper {
                width: 100%;
                height: 258px;
                img {
                    height:258px;
                }
            }        
        }        
    }
}
/* .ad-main-wrapper.compact { 
    .ad-sub-wrapper {
        margin-bottom: 20px; 
        img {
            height:240px;
            vertical-align:initial;
        }
    }        
} */

#site_content {
    height: 100%;
    position: relative;
}
#root {
    height: 100%;
    position:relative;
}
.App {
    position:relative;
    min-height: 100%;
}
.main-nonfixed {
    padding-top: 0px;
    /* padding-bottom:385px; */ 
    display:inline-block;
    width:100%;
    
    @include breakpointMax(1024){
        /* padding-bottom:380px; */ 
    }
    @include breakpointMax(767){
        /* padding-bottom:50px; */ 
    }
    @media only screen and (max-device-width:812px) and (orientation: landscape){
        /* padding-bottom:20px; */ 
    }
    &.footerstylelist {
        /* padding-bottom:385px; */
        &.admargin {
            padding-bottom:424px;
        }
        @include breakpointMax(1024){
            /* padding-bottom:385px; */
            &.admargin {
                padding-bottom:424px;
            }
        }
        @include breakpointMax(768){
            padding-bottom:385px;
            &.admargin {
                padding-bottom:385px;
            }
        }
        @include breakpointMax(767){
            padding-bottom:20px;
            &.admargin {
                padding-bottom:20px;
            }
        }
        &.no_ad_footer {
            padding-bottom:300px;
            @include breakpointMax(1024){
                padding-bottom:280px;
            }
            @include breakpointMax(768){
                padding-bottom:295px;
            }
            @include breakpointMax(767){
                padding-bottom:20px;
            }
        }  
    }
    &.footerstylecompact {
        /* padding-bottom:240px; */
        @include breakpointMax(768){
            padding-bottom:210px;
        }
        @include breakpointMax(767){
            padding-bottom:20px;
        }
        &.no_ad_footer {
            padding-bottom:180px;
            @include breakpointMax(768){
                padding-bottom:210px;
            }
            @include breakpointMax(767){
                padding-bottom:20px;
            }
        }
        @media only screen and (max-device-width:812px) and (orientation: landscape){
            &.no_ad_footer {
                padding-bottom:20px;
            }
        }
    }
    /*****************default footer start********************/
    &.no_ad_footer {
        padding-bottom:260px;
        @include breakpointMax(1024){
            padding-bottom:325px;
        }
        @include breakpointMax(767){
            padding-bottom:20px;
        }
    } 
    /*****************default footer start********************/
}

.main-fixed {
    &.footerstylelist {
        /* padding-bottom:385px; */
        @include breakpointMax(1024){
            /* padding-bottom:280px; */
        }
        @include breakpointMax(767){
            padding-bottom:20px;
        }
        &.no_ad_footer {
            padding-bottom:300px;
        }  
    } 
    /*****************default footer start********************/
    &.no_ad_footer {
        padding-bottom:300px;
        @include breakpointMax(1024){
            padding-bottom:325px;
        }
        @include breakpointMax(767){
            padding-bottom:20px;
        }
    } 
    /*****************default footer start********************/
}
.search-bar-fixed{
    position: fixed !important;
    padding-top:0px;
    //top:173px; //With Top Header Ad
    top:66px; //Withiout Top Header Ad
    width: 100%;
    z-index: 99;
    border-bottom: 3px solid #e4e4e4;
    display: block;
    @include breakpointMax(1100){
        top:66px;
    }
    @include breakpointMax(1025){
        top:66px;
    }
    /* @include breakpointMax(991){
        top:57px;  
    } */
    @include breakpointMax(767){
        top:66px;  
    }
}

/*
.footer{
    padding: 15px 0 0;
    background:var(--theme-footer-background-color, #4a4a4a);
    position: absolute;
    color: var(--theme-footer-font-color, #ffffff);
    width:100%;
    float:left;
    display: block;
    font-size:13px;
    bottom:0;
    @include breakpointMax(767){
        position: relative;
    } 
    @media only screen and (max-device-width:812px) and (orientation: landscape){
        position: relative;
    }

    & .publisher_notice {
        text-align:left; 
        font-size:12px;
        line-height:normal;
        margin:4px 0 0 0; 
        width:100%;
        float: left;   
    }
    & .copyright_text {
        text-align:left; 
        font-size:12px;
        line-height:normal;
        margin:0 0 5px 0;
        & p {
            margin-bottom: 2px;
        }
    }
    & .footer_address {
        font-size:12px;
        margin:5px 0 5px 0;
        display:inline-block; 
         
    }
    & .footer_logo {
        height:auto;
        width:auto;
        height:auto;
        max-width:90px;
        @include breakpointMax(1024){
            width:100%;
            height:100%;  
        }
        @include breakpointMax(767){
            width:70px;
            max-height:70px;
            margin-bottom:10px;  
        } 
    }
    & .footer_logo_box {
        width:107px;
        padding-right:17px;
    }
    & .print_piece {
        width: auto;
        height:40px; 
    }
    & .reis_mls_logo {
        width: auto;
        height:54px; 
        @include breakpointMax(1024){
            height:45px; 
        }
        @include breakpointMax(768){
            width:100%;
            height:auto; 
        }
        @include breakpointMax(767){
            width:auto;
            height:56px; 
            margin-top:10px;
        }
    }
    & .ft_powerby_logo {
        font-size:14px;
        display:inline-block;
        text-align:left;
        @include breakpointMax(767){
            width:100%;
            display:block;
            text-align:center;
        }
        & .powerby_logo {
            width:100%; 
        } 
    }
    & .realtor_mls_logo {
        width: auto;
        height:45px;   
    }
    & .copyright-text {
        text-align:center; 
    }
    h4 {
        font-weight:normal;
        line-height:normal;
        margin:0px;
        color: var(--theme-footer-font-color, #ffffff);
        text-align:right;
        @include breakpointMax(767){
            text-align:center;
            margin-top:10px;  
        }    
    }
    & .ft_social_icons {
        display:block;
        margin:10px 0 0 0;
        text-align:right;
        padding:0px;
        @include breakpointMax(767){
            text-align:center; 
        }  
        & li {
            margin:0 1px;
            padding:0px;
            display: inline-block;
            & img {
                width:auto; 
                @include breakpointMax(768){
                    width:28px; 
                }
            } 
            & a {
               display:inline-block;
               padding: 4px;
               &:hover {
                   background:#fff; 
               }
           } 
        }

    }

    & .footer_nav {
        display: block;
        margin:5px 0 5px 0; 
        & ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }
        & li {
            display: inline-block;
            border-right: 1px solid var(--theme-footer-font-color, #ffffff);
            margin-bottom: 0px;
            &:last-child {
                border:none; 
            }
            &:first-child {
               & a {
                padding: 0 8px  0 0px; 
               }
            }
            & a, & .flink {
                padding: 0 8px;
                color: var(--theme-footer-font-color, #ffffff);
                font-size:12px;
                font-weight:normal;
                &.anchor {
                    &:hover {
                        text-decoration: underline;
                        cursor: pointer;
                    }
                } 
            }
        }
        & .flink {
            padding: 0 8px;
            color: var(--theme-footer-font-color, #ffffff);
            font-size:12px;
            font-weight:normal;
            &.anchor {
                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            } 
        }
    }
}
*/

.features_slider{
    .restb_logo_box {
        width:128px;
        display:inline-block;
        vertical-align:top;
        margin-left:35px;
        margin-top:10px; 
        @include breakpointMax(1024){
            margin-left:15px;
        } 
        @include breakpointMax(480){
            width:100%; 
        }   
            img {
              vertical-align: middle;
              width:100%;  
            }     
    }
}
.card_icon_width {
    width:70%;
    display:inline-block;
    vertical-align:top;
 }
 .hm_virtual_box {
    width:30%;
    display:inline-block;
    text-align:right;
    margin-top: -9px;
    vertical-align:bottom;
        & .home_tour_icon {
            padding:0 2px;
            width:auto;
            display: inline-block;
            cursor: pointer;
            & img {
                width:100%;
            }
            &.camera_icon {
                width:53%;
                & img {
                    width:40px;
                    height:20px;
                }
            }
            &.round_icon {
                width:37%;
                & img {
                    width:27px;
                    height:27px;
                }
            }
            & a {
                display: block;
            }
        }
        &.virtul_icon_mag {
            margin-bottom:-15px;
        }
 }
 .openhouse_date {
    padding-left:5px;
 }
 .openhouse_sm_icon {
    height: 10px;
    width: 10px;
    margin-bottom:4px;
 }
 .compact {
    & .hm_virtual_box{
        margin-top:-4px;
        vertical-align:top;
        & .home_tour_icon {

            &.camera_icon {
                & img {
                    width:35px;
                    height:17px;
                }
            }
            &.round_icon {
                & img {
                    width:22px;
                    height:22px;
                }
            }   
        }
        &.virtul_icon_mag {
            margin-top:-2px;
        }     
    }
    & .hover_openhouse {
        font-size:10px;
    }
    & .openhouse_date {
        padding-left:2px;
    }
    & .openhouse_sm_icon {
        width:7px;
        height:7px;
        margin-bottom:0px;
    }
    .tru-cardtlc {
        padding:5px;
        & .prop-marker {
            height:38px;
        }
    }
}
.new_stylemenu {
    & .container {
        @include breakpointMax(1200){
            width:100%; 
        }
    }
}

.opwrapper {
    display: inline-block;
    width: 100%;
    min-height: 165px;
    background: var(--theme-tertiary-light-color, #4a4a4a);
    padding:0px; 
    & .search-comp {
        /* margin-top:10px; */
        margin-top:32px;
        margin-bottom:10px;
        padding:0px 15px;
        @include breakpointMax(1000) {
            margin-top:0px;
            margin-bottom:0px;
        }
    }
    & .home_search_filter {
        width:100%;  
        @include breakpointMax(1000) {
            background:var(--theme-tertiary-light-color, #4a4a4a);
            padding-top:35px;
            padding-bottom:25px;
            margin-top:0px;
            /* margin-bottom:15px; */
        }
        @include breakpointMax(767) {
            padding-top:15px;
            padding-bottom:5px;
        }
        
    }
}

.tru-card {
    position: relative;
    //display: flex;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    margin-bottom: 20px;

    & .tru-card-header {
        padding: .75rem 1.25rem;
        margin-bottom: 0;
        background-color: rgba(0,0,0,.03);
        border-bottom: 1px solid rgba(0,0,0,.125);
        &:first-child {
            border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
        }
        & .tru-card-sub-header {
            width: 50%;
            display: inline-block;
            vertical-align: top;
            font-size: 14px;            
            &:last-child {
                width: 45%;
            }
        }
    }
    & .tru-card-img {
        border-radius: calc(.25rem - 1px);
        height: 203px;
    }
    & .tru-card-img-middle {
        border-radius: 0;
        height: 203px;
    }
    & .tru-card-img-top {
        border-top-left-radius: calc(.25rem - 1px);
        border-top-right-radius: calc(.25rem - 1px);
        height: 203px;
    }
    & .tru-card-img-bottom {
        border-bottom-right-radius: calc(.25rem - 1px);
        border-bottom-left-radius: calc(.25rem - 1px);
        height: 203px;
    }
    & .tru-card-body {
        flex: 1 1 auto;
        padding: 1.25rem;

        & .tru-card-title {
            margin-bottom: .75rem;
        }
        & .tru-card-text {
            margin-top: 0;
            margin-bottom: 1rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    & .tru-card-footer {
        padding: .75rem 1.25rem;
        background-color: rgba(0,0,0,.03);
        border-top: 1px solid rgba(0,0,0,.125);

        &:last-child {
            border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px);
        }
    }    

    & .small, small {
        font-size: 80%;
        font-weight: 400;
    }

    & .tru-text-muted {
        color: #6c757d;
    }

    & .tru-card-img-overlay {
        position: absolute;
        //top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 1.25rem;
        background: linear-gradient(180deg,transparent 0,rgba(0,0,0,.6) 90%,rgba(0,0,0,.6));
        color: #fff;
    }

    & .tru-card-status-overlay {
        position: absolute;
        top: 0;
        right: 0;
        margin: 5px;
        color: #fff;
        background: #FB8C00;
        padding: 3px 5px;
        border-radius: 3px;
        font-size: 12px;
        line-height: normal;
        &.status-active {
            background: #43A047;
        }
    }

    & .tru-card-empty-overlay {
        position: absolute;
        top: calc(50% - 30px);
        left: calc(50% - 87px);
        color: #fff;
        font-size: 50px;
        line-height: normal;
        text-align: center;

        & .tru-empty-text {
            font-size: 24px;
            line-height: 0;
        }
    }
}
.manage-tru-card {
    &.ad-size-home_banner_300x50 {
        display: inline-block;        
        margin-right: 10px;
        width: 300px;
        & .tru-card-img-middle {
            height: 50px;
        }
        & .tru-card-empty-overlay {
            top: calc(50% - 13px);
            left: calc(50% - 52px);
            font-size: 30px;
            & .tru-empty-text {
                font-size: 15px;
            }
        }
        & .tru-card-text-overlay {
            position: absolute;
            top: calc(50% - 10px);
            left: 0;
            color: #fff;
            font-size: 30px;
            line-height: normal;
            text-align: center;
            width: 100%;
            & .tru-empty-text {
                font-size: 15px;
            }
        }
    }
    &.ad-size-property_card_270x285 {
        display: inline-block;        
        margin-right: 10px;
        /*width: 270px;*/
        width: 290px;
        & .tru-card-img-middle {
            /*height: 285px;*/
            height: 290px;
        }
        & .tru-card-text-overlay {
            position: absolute;
            top: calc(50% - 10px);
            left: 0;
            color: #fff;
            font-size: 30px;
            line-height: normal;
            text-align: center;
            width: 100%;
            & .tru-empty-text {
                font-size: 15px;
            }
        }
    }
    &.ad-size-property_card_290x290 {
        display: inline-block;        
        margin-right: 10px;
        width: 290px;
        & .tru-card-img-middle {
            height: 290px;
        }
        & .tru-card-text-overlay {
            position: absolute;
            top: calc(50% - 10px);
            left: 0;
            color: #fff;
            font-size: 30px;
            line-height: normal;
            text-align: center;
            width: 100%;
            & .tru-empty-text {
                font-size: 15px;
            }
        }
    }
    &.ad-size-footer_728x90 {
        display: inline-block;        
        margin-right: 10px;
        width: 728px;
        & .tru-card-img-middle {
            height: 90px;
        }
        & .tru-card-text-overlay {
            position: absolute;
            top: calc(50% - 10px);
            left: 0;
            color: #fff;
            font-size: 30px;
            line-height: normal;
            text-align: center;
            width: 100%;
            & .tru-empty-text {
                font-size: 15px;
            }
        }
    }
    &.ad-size-mortgage_banner_700x90 {
        display: inline-block;        
        margin-right: 10px;
        width: 700px;
        & .tru-card-img-middle {
            height: 90px;
        }
        & .tru-card-text-overlay {
            position: absolute;
            top: calc(50% - 10px);
            left: 0;
            color: #fff;
            font-size: 30px;
            line-height: normal;
            text-align: center;
            width: 100%;
            & .tru-empty-text {
                font-size: 15px;
            }
        }
    }
    &.ad-size-mortgage_card_278x220 {
        display: inline-block;        
        margin-right: 10px;
        width: 278px;
        & .tru-card-img-middle {
            height: 220px;
        }
        & .tru-card-text-overlay {
            position: absolute;
            top: calc(50% - 10px);
            left: 0;
            color: #fff;
            font-size: 30px;
            line-height: normal;
            text-align: center;
            width: 100%;
            & .tru-empty-text {
                font-size: 15px;
            }
        }
    }
    &.ad-size-mortgage_card_280x280 {
        display: inline-block;        
        margin-right: 10px;
        /*width: 280px;*/
        width: 290px;
        & .tru-card-img-middle {
            /*height: 280px;*/
            height: 290px;
        }
        & .tru-card-text-overlay {
            position: absolute;
            top: calc(50% - 10px);
            left: 0;
            color: #fff;
            font-size: 30px;
            line-height: normal;
            text-align: center;
            width: 100%;
            & .tru-empty-text {
                font-size: 15px;
            }
        }
    }
    &.ad-size-mortgage_card_290x290 {
        display: inline-block;        
        margin-right: 10px;
        width: 290px;
        & .tru-card-img-middle {
            height: 290px;
        }
        & .tru-card-text-overlay {
            position: absolute;
            top: calc(50% - 10px);
            left: 0;
            color: #fff;
            font-size: 30px;
            line-height: normal;
            text-align: center;
            width: 100%;
            & .tru-empty-text {
                font-size: 15px;
            }
        }
    }
    &.ad-size-large_banner_921x450 {
        display: inline-block;        
        margin-right: 10px;
        width: 921px;
        & .tru-card-img-middle {
            height: 450px;
        }
        & .tru-card-text-overlay {
            position: absolute;
            top: calc(50% - 10px);
            left: 0;
            color: #fff;
            font-size: 30px;
            line-height: normal;
            text-align: center;
            width: 100%;
            & .tru-empty-text {
                font-size: 15px;
            }
        }
    }
    & .tru-card-footer {
        padding:.75rem 1rem;
        @include breakpointMax(1180){
            padding:.75rem .70rem;
        }
        @include breakpointMax(991){
            padding:.75rem 1.25rem;
        }
        & .tru-text-muted {
            font-size:75%;
            @include breakpointMax(1180){
                font-size:70%;
            }
            @include breakpointMax(991){
                font-size:80%;
            }
        }
    }
    & .manage-icon {
        @include breakpointMax(1180){
            & i {
                font-size:11px;
            }
            & span {
                margin-left:5px;
            }
        }
        @include breakpointMax(991){
            & i {
                font-size:13px;
            }
            & span {
                margin-left:10px;
            }
        }
    }
}

.ad-type-title {
    display: block;
    width: 100%;
    background: #555;
    color: #fff;
    font-size: 14px;
    margin: 0 0 15px 7px;
    padding: 5px 10px;
}

.tru-slot-img {
    border: 1px solid rgba(0,0,0,.125);
    border-radius: calc(.25rem - 1px);
    height: 218px;
    width: 100%;
    &.ad-size-home_banner_300x50 {
        width: 300px;
        height: 50px;
    }
    &.ad-size-property_card_270x285 {
        /*width: 270px;
        height: 285px;*/
        width: 290px;
        height: 290px;
    }
    &.ad-size-property_card_290x290 {
        width: 290px;
        height: 290px;
    }
    &.ad-size-footer_728x90 {
        width: 409px;
        height: 50px;
    }
    &.ad-size-mortgage_banner_700x90 {
        width: 409px;
        height: 52px;
    }
    &.ad-size-mortgage_card_278x220 {
        width: 278px;
        height: 220px;
    }
    &.ad-size-mortgage_card_280x280 {
        /*width: 280px;
        height: 280px;*/
        width: 290px;
        height: 290px;
    }
    &.ad-size-mortgage_card_290x290 {
        width: 290px;
        height: 290px;
    }
    &.ad-size-large_banner_921x450 {
        width: 409px;
        height: 200px;
    }
}

.browse-photo-wrapper {
    display: inline-block;
    vertical-align: bottom;
    margin-left: 20px;
    margin-top: -4px;
}
.date-picker-popover{
    & .text-muted {
        color: #777;
    }
    & .bg-primary {
        background-color: #f76b1c;
    }
    & .text-primary {
        color: #f76b1c;
    }
}
.print-piece-wrapper {
    margin: 20px 0;
    padding: 0;
    width: 100%;
    height: 550px;
}
.tru-or-wrapper {
    display: table;
}
.tru-or-text {
    vertical-align: middle;
    display: table-cell;
    font-size: 24px;
} 
.tru-overlay-search-btn {
    position: absolute;
    top: 23px;
    right: 5px;
    font-size: 30px;
    color: #777;
    background: #fff;
    padding: 1px 7px;
    border: 1px solid #ddd;
    cursor: pointer;
}
.custom-full-modal {
    width: 90%;
}
.scrolling_text {
    width: 348px;
    height: 18px;
    overflow: hidden;
    position: relative;
    & span {
        position: absolute;
        white-space: nowrap;
        transform: translateX(0);
        transition: 1s;
    }
    &:hover {
        & span{
            transform: translateX(calc(348px - 100%));
        }
    }
}
/* .tour_button {
    position:fixed;
    left:10px;
    bottom:10px;
    height:45px;
    width:45px;
    border-radius:50%;
    background-color:var(--theme-tour-button-color, #1f407b);
    font-size:28px;
    text-align:center;
    vertical-align:middle;
    color:#fff;
    cursor:pointer;
    z-index:9999;
    box-shadow:3px 3px 10px rgba(0,0,0,0.2);
    & img {
        width:30px; 
    }
} */
.tlc_what_popup {
    & .close_popup i { 
        font-size:22px;
        color:var(--theme-secondary-color, #fbb040);
        cursor:pointer;
    }
}
.ct_profile_left {
    @include breakpointMax(768){
        text-align:center; 
    }
    & .ct_profile_bg {
        width:100%;
        float:left;
        background:var(--theme-tertiary-color, #4c4c4c);;
        margin:0 0 10px 0;
    }
}

.ct_profile_left {
    & .ct_profile_photo {
        &:after {
            position:absolute;
            top:0;
            left:0;
            content:"";
            width:100%;
            min-height:70px;
            background:var(--theme-primary-light-color, #365b9d);
            box-shadow:-2px 3px 5px -2px rgba(0,0,0,0.21);
        }
    }
}  
.ct_profile_left {
    & .com_profile_box {
        text-align:left; 
    }
}
/* .btn-change-photo {
    color: #4c4c4c;
    position: absolute;  
    bottom:0px;  
    right:0;  
    width:20px;  
    height:20px;  
    background-color: var(--theme-secondary-color, #fbb040);  
    border-radius:50%;  
    font-size:15px;  
    cursor:pointer;  
    padding:2px 0 0 2px;
}
.btn-delete-photo {
    color: var(--theme-tertiary-color, #4c4c4c);  
    position:absolute;  
    bottom:0px;  
    left:0;  
    width:20px;  
    height:20px;  
    background-color: var(--theme-secondary-color, #fbb040);   
    border-radius:50%;  
    font-size:15px;  
    cursor: pointer;
    padding: 2px 0 0 0;
} */
 
.newtap_icons {
    & .tabicon_box {
        & .fa {
            color:var(--theme-secondary-color, #fbb040);;  
            line-height:normal;
        }
    }
} 
.saved_searches_rt {
    .nav-tabs {
        & li {
            &.active {
                a {
                    background-color: #fff;
                    border: 0;
                    border-bottom: 2px solid var(--theme-primary-color, #1f407b);;
                    color: #44515e;
                }
            }
            & a:focus, a:hover {
                background-color: #fff;
                border-bottom: 2px solid var(--theme-primary-color, #1f407b);;
            }
        }
    }
}
.quickphoto_name { 
    text-align:center; 
    float:left;  
    width:31px;  
    height:31px;

    & .quickphoto_box {
        font-size:13px; 
        background: var(--theme-tertiary-color, #4c4c4c);;
        border-radius:100%; 
        line-height:32px; 
        color:#fff; 
        float:left; 
        width:31px;  
        height:31px; 
    }
 }

.anchor-link {
    text-decoration:underline;
    color:var(--theme-secondary-color, #fbb040);;
    &:hover, &:focus {
        color:var(--theme-secondary-dark-color, #fa9b0e);
        text-decoration:underline;
    }
}

/* .react-bs-table {
    & .table-bordered {
        & thead {
            & tr {
                &:first-child {
                    & td, & th {
                        background-color:var(--theme-tertiary-color, #4c4c4c) !important;
                        border-bottom:none!important;
                        opacity:1;    
                    }
                }
            }
        }
    }
} */
/* .modal-title{
    font-size:26px;
    text-align:center;
    color: var(--theme-primary-color, #1f407b);
    font-weight:300;
} */
.compare_accent { color: var(--theme-primary-color, #1f407b);}

/* .compare {
    & .fa {
        cursor: pointer; 
        color:var(--theme-primary-color, #1f407b);
    } 
} */

/* .joyride-tooltip__footer {
    & button {
        background:var(--theme-primary-color, #1f407b) !important; 
    }
    & .joyride-tooltip__button--skip {
        background:inherit !important; 
    }
    & .joyride-tooltip__button--secondary {
        background:inherit !important;
        color: var(--theme-primary-color, #1f407b) !important;
    }
}
.joyride-tooltip {
    & .joyride-tooltip__header {
        border-color:var(--theme-primary-color, #1f407b) !important;
    }
} */

.top_ads_frame {
    width:100%;
    display: block;
    text-align:center;
    background:var(--theme-tertiary-color, #4c4c4c);
    padding:13px 0;
    & .ads_frame_box {
        width:728px;
        text-align:center;
        float:none; 
        display:inline-block;
        min-height: 85px;
        & img {
            height:90px; 
            width:100%; 
        }
    }
}

.footer_ads_frame {
    width:100%;
    display:block;
    text-align:center;
    margin-top:-48px;
    padding:0 0 25px 0; 
    & .ads_frame_box {
        width:728px; 
        text-align:center;
        float:none; 
        display:inline-block;
        position: relative;
        min-height:85px;
        background:var(--theme-footer-background-color, #4a4a4a);  
        & img {
            height:90px; 
            width:100%; 
        }
        &:after {
            width:0px;
            height:0px;
            border-bottom:37px solid var(--theme-footer-background-color, #4a4a4a);
            border-left:48px solid transparent;
            position:absolute; 
            content:"";
            left:-48px;
            top:0;
        }
        &:before {
            width:0px;
            height:0px;
            border-bottom:37px solid var(--theme-footer-background-color, #4a4a4a);
            border-right:48px solid transparent;
            position:absolute; 
            content:"";
            right:-48px;
            top:0;
        }
    }
}

.main-wrapper {
    position: absolute;
    width: 100%;
    //top:231px;//With Top Header Ad
    top:82px;//Without Top Header Ad
    left:0;
    overflow: hidden;
    @include breakpointMax(1025){
        top:73px;
    }
    @include breakpointMax(767){
        top:86px !important;
    }
}
.user_access_login {
    & .logoheader {
        margin-top:0;
    }
}

.notification {
    font-family: "FilsonProRegular" !important;
    transform:rotate(0deg) !important;
    will-change:auto !important;
    height:auto !important;
    padding:15px 40px 15px 15px !important;
    font-size:17px !important;
    border-radius:10px !important;
    border-top:1px solid var(--theme-tertiary-color, #4c4c4c) !important;
    border:1px solid var(--theme-tertiary-color, #4c4c4c);
}

.autocomp {
	.dropdown-menu {
		& .active {
			& a {
				    background-color: var(--theme-secondary-color, #fbb040);;
				&:focus {
					background-color: var(--theme-secondary-color, #fbb040);;
				}
				&:hover {
					background-color: var(--theme-secondary-color, #fbb040);;
				}
			}
		}
	}
}
.carousel {
	& .control-prev {
        &.control-arrow {
            left:0;
            @include breakpointMax(767){
                opacity:1; 
            }
        }
    }
}


.carousel {
	& .control-next {
        &.control-arrow {
            right: 0;
            @include breakpointMax(767){
                opacity:1; 
            }
        }
    }
}

.autocomp {
    &.agentcommute {
        margin-top:0px; 
        padding:5px;
        min-height:40px;
        & div {
            width:100%; 
        }
        & .rbt-input-main {
            width:90% !important; 
        }
    }
}
    
.tru-tab-button{
    display:inline-block;
    vertical-align:top;
    border-radius:8px;
    padding:8px 17px;
    text-align:center;
    color:#555;
    font-size:16px;  
    cursor:pointer;
    margin:0 5px;
    border: 1px solid transparent;
    @include breakpointMax(767){
        padding:8px 15px;
    }
    @include breakpointMax(480){
        padding:8px 10px;
    }
    &:first-child {
        margin-left:0px; 
    }
    &:hover, &:focus {
        background-color: rgb(232, 233, 234);
    } 
    &.tru-tab-active {
        border:1px solid var(--theme-primary-light-color, #365b9d);
        color:#000; 
        &:hover, &:focus {
            background:none; 
        }
    }  
}
.sidebar_sticky { 
    @include breakpointMax(767){
        position:inherit !important; 
        left:inherit !important; 
        top:inherit !important; 
        width:100% !important; 
        margin:13px 0 0 0;
    }
    @include breakpointMax(834){
        position:inherit !important; 
        left:inherit !important; 
        top:inherit !important; 
        width:100% !important; 
        margin:13px 0 0 0;
    }
}
.sticky {
    &.sidebar_sticky_pd {
        @include breakpointMax(834){
            position:inherit !important; 
            left:inherit !important; 
            top:inherit !important;
        }
    }
}

.tru-cardtlc {
        border:1px solid #B2B2B2;	
        background-color: rgb(178,178,178);
        padding:10px;
        color: white;
        text-align:center;
        font-size:14px;
        opacity:0.86; 
    & .prop-marker {
        /* float: left;
        font-size: 18px;
        margin-top: -4px;
        color: #fff;
        cursor: pointer; */
        float: left;
        font-size: 20px;
        color: #fff;
        cursor: pointer;
        padding: 7px;
        margin: -11px 0 0 -10px;
        background: rgba(0,0,0,0.2);
        width: 42px;
        height: 43px;
        @include breakpointMax(768){
            display:none; 
        }
        &:hover {
            background: rgba(0,0,0,0.5);
        }
    }
}
.openhouse_prp_list {
    & .new_searchlist {
        & .openhouse_img {
            & img {
                top:24%;
                bottom:-50%; 
            }
        }
        & .remove_other_tlc {
            & .openhouse_img {
                & img {
                    top:-28%;
                    bottom:-70%; 
                }
            }
        }
    }
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    z-index: 2;
    color: #fff;
    background-color:var(--theme-primary-color, #1f407b) !important;
    border-color:var(--theme-primary-color, #1f407b) !important;
    cursor: default;
}

.ct_profile_bg {
    & .ct_profile_lt  {
        padding-bottom:15px; 
        @include breakpointMax(999){
            padding-bottom:0px; 
            &.one_col_profile {
                width:100%; 
                padding-bottom:15px; 
            }
        }
    }
}
.ct_profile_bg {
    & .ct_profile_rt  {
        @include breakpointMax(641) {
            min-height: inherit;
        }
    }
}
.profile_client_bt_small{ 
    @include breakpointMax(768){
        width:auto; 
        float:none; 
        display:inline-block;
    }
    @include breakpointMax(812){
        width:auto; 
        float:none; 
        display:inline-block;
    }
  }

.includecommute-wrapper {
    position:absolute; 
    padding:5px;top:10px; 
    border-radius:10px; 
    right:10px;  
    background-color:#b2b2b2; 
    z-index:999; 
    font-size:15px;  
    border:2px solid var(--theme-subprimary-color, #ed1c24); 
    text-align:right;
}
.update_note_btn {
    cursor:pointer; 
    display:inline-block;  
    padding:1px 5px 1px; 
    background-color:var(--theme-primary-color, #1f407b); 
    border-radius:2px; 
    color:#fff; 
    margin:0 0 0 5px;
}
.profile_Slider .rc-slider-track {
    background-color: var(--theme-primary-color, #1f407b);
}
.profile_Slider {
    .rc-slider-handle {
        border:solid 2px var(--theme-primary-color, #1f407b);
        &:hover {
            border-color: var(--theme-primary-color, #1f407b);
        }
        &:focus {
            border-color: var(--theme-primary-color, #1f407b); 
            box-shadow: 0 0 0 5px var(--theme-primary-color, #1f407b);
        }
        &:active {
            box-shadow: 0 0 5px var(--theme-primary-color, #1f407b); 
            border-color: var(--theme-primary-color, #1f407b); 
        }
    }
}
.navprofile_name .navprofile_box {
    background: var(--theme-tertiary-color, #4c4c4c);
    font-size:13px; 
    line-height:26px; 
    color:#fff; 
    padding:2px; 
    border-radius:100%; 
    width:29px; 
    float:left; 
    height:29px; 
}
.navprofile_name {
    border-radius:100%;
    width:35px;  
    height:35px; 
    text-transform:uppercase;  
    text-align:center; 
    float:left; 
    border:1px solid var(--theme-secondary-color, #fbb040);; 
    padding:2px; 
}
.left-wrapper {
    &.list-col-2 {
        width:calc(100% - 815px) !important; 
        float:left;
    }
}

.btn-compact-imp {
    padding: 8px 10px 7px 10px !important;
    font-size: 13px !important;
}

.gmap-directions-wrapper {
    z-index: 9;
    position: absolute;
    top: 10px;
    left: calc(50% - 60px);
    padding: 3px 8px;
    border-radius: 2px;
    background: #fff;
    font-size: 12px;
}
.beds-item-filter, .baths-item-filter {
    .semicompact  {
        & .react-select-box-off-screen {
            max-height: 320px;
            overflow-y: auto;
        }
    }
}

.myoffers_button {
    position:fixed;
    left:10px;
    bottom:60px;
    cursor:pointer;
    z-index:9999;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
    & img {
        width:80px; 
    }
    @include breakpointMax(1024){
        bottom:10px;
        z-index: 999999;
    }
}

/* .compare-alert {
    position: fixed;
    left: 75px;
    bottom: 0px;
    z-index: 999;
    background: #5B5B5B;
    min-width: 270px;
    border: 2px white solid;
    @include breakpointMax(767){
        width:300px;
        left:calc(100% - 300px);
    }
}
.compare-img {
    max-width:100px;
    width:100%;
    height:80px;    
}
.compare-alert {
    & .compare-content {
        color: #fff;
        padding: 10px; 
        float: left;
        @include breakpointMax(767){
            text-align:center;
        }
    }
    & .compare-property {
        float:none;
        display:inline-block;
        vertical-align:top;
        margin-top:10px;
        padding-right:10px;
        width:110px;
        position:relative;
        & a {
            display:inline-block;
        }
    }
}
.compare-header { 
    padding-bottom:8px; 
}
.compare-collapsed {
    display:none;
}
.compare-collapsible {
    display:table-row;
}
.compare-button{
    border-radius: 100%;
    position: relative;
    background: var(--theme-primary-color, #1f407b);
    text-align: center;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    padding: 0;
    width: 20px;
    height:20px;
    &.compare-open {
        left:6px;
    }
    &.compare-delete {
        right:7px;
        left:inherit;
        top:-4px;
        position:absolute; 
        & i {
            font-size:10px;
        }
    }  
}
.compare_mb_table {
    @include breakpointMax(768){
        overflow-y:auto;
        width:100%;
        float:left;
    }    
} */

.analytics-dash-wrapper {
    width: 1260px;
    /*width: 100%;*/
    height: 824px;
}
.iframe-placeholder {
    background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100% 100%"><text fill="%231f407b" x="50%" y="10%" font-family="FilsonProRegular, Roboto, Helvetica, Arial, sans-serif" font-size="26" font-weight="bold" text-anchor="middle">Preparing Stats, please wait...</text></svg>') 0px 0px no-repeat;
}

.status-sold {
    position: absolute;
    top: 0;
    left: calc(50% - 40px);
    margin-top: 27px;
    width: 80px;
    background: #c82021;
    text-align: center;
    color: #fff;
    border-radius: 0 0 5px 5px;
    padding: 3px;
    font-size: 14px;
    z-index: 99;
}

.financial_pro_bg {
    width:100%;
    float: left;
    border:1px solid  #eeeeee;
    & .profile_header {
        background:var(--theme-tertiary-light-color, #4a4a4a);
        width:100%;
        float:left;
        padding:12px 20px;
        color:#fff;
        @include breakpointMax(480){
            padding:12px 15px;
        }
        & .profile_header_lt {
            width:auto;
            float:left;
            & img {
                width:120px;
                float:left;
                @include breakpointMax(480){
                    width:100px;
                }
            }
        }
        & .profile_header_rt {
            width:auto;
            float:right;
            color:#fff;
            font-size:20px;
            text-transform: uppercase;
            line-height:20px;
            @include breakpointMax(480){
                font-size:15px;
                line-height:15px;
            }
        }
    }
    & .profile_cnt_mid {
        width:100%;
        float:left;
        padding:15px;
        & .profile_info_bg {
            width:100%;
            float:left;
            background:#f9f9f9;
            padding:10px;
            border:1px solid #efecec;
            & .profile_photo_box {
                width:70px;
                float:left;
                background:#e0dede;
                padding:2px;
                border:1px solid #ccc;
                margin:0 15px 0 0;
                @include breakpointMax(480){
                    width:60px;
                }
            }
            & .profile_info_rt {
                display:block;
                overflow:hidden;
                width:auto;
                float:none;
            }
        }
        & .financial_pro_lt {
            width:73%;
            float:left;
            margin-top:15px;
            margin-right:1%;
            @include breakpointMax(1200){
                margin-right:0px; 
                width:100%; 
            }
            @include breakpointMax(768){
                margin-right:0px; 
                width:100%; 
            }
            & .income_dt_bg {
                width:100%;
                float:left;
                background: #f9f9f9;
                padding:10px;
                border:1px solid #efecec;
                & .income_clt_box {
                    width:18%;
                    display:inline-block;
                    vertical-align:top;
                    border-left:1px solid #ddd;
                    padding: 0px 5px;
                    min-height:90px;
                    word-wrap:break-word;
                    & .income_clt_lable {
                        min-height:65px;
                    }
                    &:first-child {
                        width:10%;
                        border-left:none;
                        padding:0px;
                    }
                }
            }
            & .home_value {
                & .income_data_row {
                    & .col_22 {
                        &:nth-child(4) {
                            width:16%;
                            @include breakpointMax(767){
                                width:100%;   
                            } 
                        }
                        &:nth-child(5) {
                            width:22% !important;
                            @include breakpointMax(767){
                                width:100% !important;   
                            } 
                        }
                    }
                }
            }
            & .income_data_lt {
                width:10%;
                float:left;
                @include breakpointMax(1200){
                    width:8%;
                }
                @include breakpointMax(767){
                    width:100%;
                }
            }
            & .income_data_rt {
                width:90%;
                float:left;
                @include breakpointMax(1200){
                    width:92%;
                }
                @include breakpointMax(767){
                    width:100%;
                }
                & .income_data_row {
                    width:100%;
                    display:table;
                    @include breakpointMax(767){
                        float:left;
                        display:inherit;
                        width:100%;
                        text-align:left;
                        &.data_arount {
                            display:none;   
                        }    
                    }
                    & .col_22 {
                        width:22%;
                        display:table-cell;
                        vertical-align:top;
                        padding:5px 6px 0px 6px;
                        word-break: break-word;
                        text-align:center;
                        border-left:1px solid #ddd;
                        /* min-height:40px; */
                        line-height:normal;
                        
                        @include breakpointMax(767){
                            width:100%;
                            padding-left:0px;
                            float:left;
                            display:inherit;
                            padding:0px;
                            text-align:left;
                            border-left:none;
                            border-bottom:1px solid #ddd;
                            padding-bottom:5px;
                            &:last-child {
                                border-bottom:none;
                            }
                        }
                        &:first-child {
                            padding-left:0px;
                            width:17%;
                            border-left:none;
                            @include breakpointMax(1200){
                                width:21%;
                            }
                            @include breakpointMax(767){
                                width:100%; 
                                float:left;
                                display:inherit;  
                            }
                        }
                        &:nth-child(2) {
                            width:21%;
                            @include breakpointMax(1200){
                                width:19%;
                            }
                            @include breakpointMax(767){
                                width:100%;   
                            } 
                            
                        }
                        &:nth-child(3) {
                            width:18%;
                            @include breakpointMax(767){
                                width:100%;   
                            }
                        }
                        &:nth-child(4) {
                            
                            @include breakpointMax(1200){
                                width:19%;  
                            }
                            @include breakpointMax(767){
                                width:100%;   
                            }
                        }
                        &:nth-child(5) {
                            width:20%;
                            @include breakpointMax(1200){
                                width:16%;  
                            }
                            @include breakpointMax(767){
                                width:100%;   
                            }
                        }
                        & .mb_data_row {
                            &:first-child {
                                display:inline-block ;
                                @include breakpointMax(767){
                                    display:inline-block;
                                    vertical-align:top;
                                    width:70%;
                                    padding-right:5px;
                                }
                            }
                            &:nth-child(2) {
                                display:none;
                                @include breakpointMax(767){
                                    display:inline-block;
                                    vertical-align:top;
                                    width:30%;
                                }
                            }
                        }
                    }
                }
            }
            & .segment_text {
                width:100%;
                float:left;
                border-top:1px solid #ddd;
                padding:7px 20px 0px 20px;
                line-height:normal ;
                margin-top:10px;
                @include breakpointMax(767){
                    padding:7px 10px 0px 0px;  
                }
            }
            & .small_score_chart {
                display:block;
                margin-top:-16px;
                @include breakpointMax(1200){
                    margin-top:-5px;
                }
                @include breakpointMax(767){
                    margin-top:-15px;
                }
            }
        }
        & .financial_pro_rt {
            width:26%;
            margin-top:15px;
            float:left;
            @include breakpointMax(1200){
                width:100%;  
                min-height:initial; 
            } 
            @include breakpointMax(768){
                width:100%;   
            } 
            & .lead_score_bg {
                width:100%;
                display: table;
                background:#f9f9f9;
                padding:10px;
                margin-top:10px;
                border:1px solid #efecec;
                text-align:center;
                min-height:262px;
                @include breakpointMax(1079){
                    min-height:254px;  
                } 
                @include breakpointMax(991){
                    min-height:239px;  
                }
                @include breakpointMax(1200){
                    min-height:inherit;
                    padding:10px 10px 0 10px;  
                }
                & h2 {
                    margin:0;
                    font-size:18px;
                }
                & .Score_big {
                    font-size:50px;
                    color:#555;
                    display:block;
                    line-height:55px;
                }
                & .Score_small {
                    font-size:20px;
                    color:#555;
                    display:block;
                    line-height:20px;
                }
            }
            & .lead_score_ct {
                display:table-cell;
                vertical-align:middle;
                width:100%;
            }
            & .score_chart {
                width:100%;
                text-align:center;
                float:none;
                display:inline-block;
                margin:-15px 0 0px 0;
                @include breakpointMax(1200){
                    margin:-15px 0 -10px 0;
                }
            }
        }
        & .com_small_header {
            width:100%;
            display:block;
            background:var(--theme-primary-light-color, #365b9d);
            padding:10px;
            color:#fff;
            font-size:16px;
            @include breakpointMax(1145){
                font-size:14px;  
            } 
            & .info_icon {
                float:right;
            }
        }
    }
}
.prop_info_box {
    & .prop_info_left {
        width:100%;
        display:block;
    }
    & .prop_info_right {
        display:none;
    }
}

.prop_ecommison_list {
    & .prop_info_box {
        width:100%;
        display:block;
        & .prop_info_left {
            width:70%;
            display:inline-block;
            vertical-align:top;
        }
        & .prop_info_right {
            width:30%;
            display:inline-block;
            vertical-align:top;
            padding:5px 5px 0 0;
            text-align:right;
            & img {
                width:73px;
                height:73px;
            }
        }
    }
    & .prop_card_box {
        & .card_prop_info {
            & .card_prop_add {
                padding:0 5px 0 10px;
            }
        }
    }
}
/* .commison_icon {
    width:100%;
    float:left;
    text-align:center;
    margin-top:10px;
    @include breakpointMax(990){
        margin-bottom:20px;
    }
    @include breakpointMax(768){
        margin-top:0px;
    }
    & img {
        width:70px;
        height:70px;
        display:inline-block;
        float:none;
    }
} */

.dispay_ad_wrapper {
    background: #ddd;
    margin: 0 auto; 
    overflow: hidden; 
    & img {
        width: 100%;
        height: auto;
        max-height: 100%;
    }
}

.themecarousel {
    &.home_slider {
        background:inherit;
        position:relative;
        & .search-comp {
            padding:0px 15px;
            margin-top:32px;
            margin-bottom:32px;
            top: calc(10% + 190px);
            bottom: unset;
            @include breakpointMax(812){
                top: calc(10% + 150px);
            }
        }
    }
    & .banner-top-text {
        top:10%;
        color: #fff;
        margin-left:15px;
        text-align: left;
        position:absolute;
        max-width:500px;
        z-index:3;
        border-radius:5px;
        background-color: rgba(0,0,0,.5);
        @include breakpointMax(1000){
            max-width:550px;
        }
        @include breakpointMax(768){
            width:calc(100% - 40px);
            margin-right: 20px;
            max-width:100%;
        }
        & h1 {
            font-size:30px;
            color:#fff;
            @include breakpointMax(1024){
                font-size:24px;
            }
            @include breakpointMax(767){
                font-size:22px;
                margin-top:15px;
                margin-bottom:15px;
            }
        }
        & .banner-top-price {
            position: inherit;
            top:initial;
            left:initial;
            @include breakpointMax(1024){
                font-size:15px !important;
            }
        }        
    }
    & .search-comp_addition {
        left:0;
        right:0;
        position:absolute;
        width:100%;
        z-index:2;
        bottom:31%;
        @include breakpointMax(1366){
            bottom:20%;
        }
        @include breakpointMax(1024){
            bottom:7%;
        } 
        @include breakpointMax(768){
            bottom:0;
        } 
        @include breakpointMax(767){
            position:inherit!important;
        }
        & .home_search_adds {
            position: absolute;
            right: 0;
            top: -224px;
            @include breakpointMax(1240){
                right: 16px;
            }
            @include breakpointMax(1000){
                display: none;
            }
        }
    }
    & .tru-dropdown {
        & .react-select-box-label {
            @include breakpointMax(768){
                padding:0 3px 0 10px;
            }
        }
    }
    & #lifestyle_dropdown {
        & .tru-input {
            @include breakpointMax(768){
                padding:13px 10px 12px 10px;
            }
        }
    }
    & .tru-homesearch {
        & .rbt-input-main {
            @include breakpointMax(768){
                padding:13px 10px 12px 10px !important;
            }
        }
    }
    & .form-group {
        &.search_bar_btn {
            @include breakpointMax(767){
                margin:15px 0 10px 0;
            }
        }
    }
}

.my-offers-main {
    position: relative;
    & .offer-card {
        width: 100%;
        max-width: 480px;
        margin: 20px auto;
        & .offer-name {
            margin-bottom: 15px;
            text-align: center;
            font-weight: bold;
            border-bottom: 1px solid #e5e5e5;
            padding-bottom: 15px;
        }
        & .offer-image {
            margin-bottom: 15px;
            text-align: center;
            & img {
                width: auto;
                height: auto;
                max-width: 100%;
            }
        }
        & .offer-desc {
            margin-bottom: 15px;
            text-align: center;
        }
        & .offer-buttons {
            text-align: center;    
            margin-top: 25px;
            border-top: 1px solid #e5e5e5;
            padding-top: 15px;        
            & .offer-button {
                margin-right: 10px;
                display: inline-block;
                & button {
                    margin-bottom: 15px;
                    padding: 8px 15px;
                }
            }
        }
        & .redeem-row {
            margin: 15px;
            width: 210px;
            padding: 15px;
            border: 1px solid #e5e5e5;
            display: inline-block;
            vertical-align: top;
            border-radius: 15px;
            background: #ffffff;
            & button {
                padding: 8px 15px;
            }
        }
    }
    & .my-offers-list{        
        & .my-offers-list-row {
            margin-bottom: 15px;
            cursor: pointer;
            & .my-offers-list-photo {
                width: 150px;
                display: inline-block;
                vertical-align: top; 
                @include breakpointMax(540){
                    width: 120px;
                }
                & img {
                    width: 150px;
                    height: 150px;
                    border-radius: 20%;
                    @include breakpointMax(540){
                        width: 120px;
                        height: 120px;
                    }
                }
            }
            & .my-offers-list-title {
                width: calc(100% - 150px);
                display: inline-block;
                vertical-align: top;
                padding: 15px;
                @include breakpointMax(540){
                    width: calc(100% - 120px);
                    padding: 5px 5px 15px 15px;
                }
            }
            & .my-offers-list-arrow {
                padding-top: 70px;
                @include breakpointMax(540){
                    padding-top: 50px;
                    padding-left: 5px;
                }
            }
        }
    }
    & .my-offers-dialog {
        & .my-offers-list {
            max-height: 500px;
            overflow-y: auto;
            width: 100%;
            overflow-x: hidden;
        }
    }
    & .claim-offer-button {
        position: absolute;
        top: 0;
        right: 0;
        @include breakpointMax(400){
            right: -10px;
        }
        & button {
            @include breakpointMax(812){
                padding: 8px 15px;
            }
            @include breakpointMax(430){
                padding: 8px 10px;
            }
            @include breakpointMax(360){
                padding: 8px 5px;
            }
        }
    }
}

/**************************************Footer style 1****************************************************/
.footerstylelist {
    & .footerstyle_cnt {
        text-align:left;
    }
    &.footer {
            border-top:3px solid var(--theme-footer-border-color, #efefef);
        & .footer_logo {
            width:auto;
            height:auto;
            max-width:90px; 
            @include breakpointMax(767){
                /* width:75px;
                height:75px; */ 
                width: auto;
                height: auto;
                max-width:75px;
                margin-bottom:15px;
            }
        }
        & .ft_powerby_logo {
            font-size:14px;
            @include breakpointMax(768){
                /* margin-top:15px; */
                text-align:center; 
            }
        }
        h4 {
            font-weight:normal;
            line-height:normal;
            margin-top:0px;
            font-size:16px; 
            text-align:left;
            margin-bottom:10px;
            font-weight: bold;
            @include breakpointMax(768){
                font-size:18px;  
            }    
        }
        & .ft_social_icons {
            float:right;
            margin:0px;
            display:inherit;
            @include breakpointMax(768){
                float:none; 
                margin-top:5px;
                display:inline-block;
            }
            & ul {
                margin:0px;
                padding:0px;
            }
            & li {
                margin:0 9px;
                padding:0px;
                display: inline-block;
                &:last-child {
                    margin:0 0 0 9px;
                }
                & img {
                    width:auto; 
                    @include breakpointMax(768){
                        width:28px; 
                    }
                }
                & i {
                    font-size:19px;
                    color: var(--theme-footer-sub-font-color, #4a4a4a);
                    &:hover {
                        color:#1c79b7;
                    } 
                } 
                & a {
                   display:inline-block;
                   padding:0px;
                   &:hover, &:focus {
                        background:initial;
                   }
               } 
            }
        }
        & .footer_nav {
            display: block;
            margin:5px 0 10px 0; 
            & ul {
                list-style: none;
                padding:0 0 0 16px;
                margin:0;
                
            }
            & li {
                display:list-item;
                position:relative;
                margin-bottom:10px;
                list-style-type:square;
                border-right:none;
                & a, & .flink {
                    padding:0px;
                    font-size:14px;
                    font-weight:normal;
                    word-break: break-word;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            & .flink {
                padding:0px;
                font-size:14px;
                font-weight:normal;
                word-break: break-word;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        & .footer-content {
            padding:25px 0 20px 0; 
            @include breakpointMax(767){
                padding:15px 0 15px 0;  
            }
        }
        & .copyright_bg {
            background-color: var(--theme-footer-sub-background-color, #f7f7f7);
            padding:15px 0; 
            color: var(--theme-footer-sub-font-color, #4a4a4a);
        }
        & .comp_address_image {
            width: 50px;
            margin-right: 16px;
            margin-top: 16px;
            height: auto;
            max-height: 60px;
        }
    }
    & .footer_ads_frame {
            margin-top:-52px;
            padding-bottom:0px;
        & .ads_frame_box {
            &:after {
                border-bottom:37px solid var(--theme-footer-border-color, #efefef);;
            }
            &:before {
                border-bottom:37px solid var(--theme-footer-border-color, #efefef);;
            }
        }
    }
    & .mb_footer_full {
        @include breakpointMax(767){
            text-align:center;
            margin:4px 0px;
        }
    }
}

.search_footer {
    &.list-col-1 {
        & .footerstylelist {
            &.footer {
                & h4 {
                    text-align:left !important;
                    font-size:15px;
                }
                & .ft_powerby_logo {
                    font-size:12px;
                }
                & .ft_social_icons  {
                    & li {
                        & i {
                            font-size:16px;
                        }
                    }
                }
                & .dt_wd_35 {
                    width:35%;
                }
                & .dt_wd_44 {
                    width:44%;
                }
                & .dt_wd_45 {
                    width:45%;
                }
                & .dt_wd_50 {
                    width:50%;
                }
            }
            & .footerstyle_cnt {
                & .mb_footer_harf {
                    width:28%;
                    @include breakpointMax(768){
                        width:50%;
                    }
                }
                & .mb_footer_fl {
                    @include breakpointMax(768){
                        text-align:left;
                        width:100%;
                    }
                }
                & .footer-content {
                        padding:20px 0 20px 0;
                    & .footer_nav {
                        text-align:left;
                        & li {
                                margin-bottom:6px;
                            & a, & .flink {
                                padding:0px !important;
                                font-size:12px !important;
                                line-height:15px;
                            }
                        }
                    }
                }
                & .copyright_bg {
                    font-size:12px;
                    padding: 10px 0;
                    color: var(--theme-footer-sub-font-color, #4a4a4a);
                }
                & .mb-tru-center {
                    @include breakpointMax(768){
                        text-align:center;
                    }
                }
            }
        }
        & .footerstylecompact {
            & .footerstyle_cnt {
                padding-top:0px;
                padding-bottom:0px;
            }
        }
    }
}
/**************************************Footer style 1 End****************************************************/

/**************************************Footer style 2****************************************************/
.footerstylecompact{
    margin-top:0px;
    .footerstyle_cnt {
        padding-top:20px;
        padding-bottom:10px;
        @include breakpointMax(768){
            padding-top:10px;
            padding-bottom:10px;
        }
        & .comp_address {
            float:left;
            font-size:12px;
            color: #fff;
            position:absolute;
            top:-10px;
            max-width:165px;
            text-align:left;
            @include breakpointMax(1023){
                position: inherit;
                width:100%;
                top:inherit;
                max-width:none;
                text-align:center;
                padding-bottom:5px;
                padding-top:3px;
            }
        }
        & .footer_social {
            margin-bottom:20px;
            img {
                height:30px;
                width:30px;
            }
        }
        & .footer_logo {
            float: left;
            margin-top:-52px;
            width:auto;
            height:auto;
            & img {
                max-width:100px;
                @include breakpointMax(768){
                    display:inline-block;
                }
            }
            @include breakpointMax(768){
                width:100%;
                margin-top:10px;
                text-align:center;
            }
        }
    }
    & .footer_ads_frame {
        padding-bottom:0px;
    }
}

.ppsubscribe-wrapper {
    background-color: #EDEDED;
    width: 100%;
    display: block;
    padding: 0;
    margin-bottom: 0;
    & h2 {
        text-transform: uppercase;
        margin: 0px;
        font-size: 32px;
        padding-bottom: 10px;
        @include breakpointMax(768){
            font-size:23px;
        }
    }
    & span {
        font-size: 20px;
    }
    & .ppsubimg {
        max-width: 418px;
    }
}

.site-error-msg-wrapper {
    position: fixed;
    width: 100%;
    display: block;
    bottom: 15px;
    left: 0;
    text-align: center;
    z-index: 9999999999;
    & .site-error-msg {
        padding: 10px 15px;
        background: #eda400;
        color: #fff;
        border-radius: 10px;
        font-size: 15px;
        margin: 0 10px;
        display: inline-block;
    }
}

.main-title {
    font-size: 26px;
}
.main-subtitle {
    font-size: 22px;
}

.demographic-disc {
    font-size: 10px;
    font-style: italic;
    color: #555;
    text-align: right;
    float: right;
    vertical-align: middle;
    line-height: 22px;
    @include breakpointMax(590){
        display: block;
        text-align: left;
        float: none;
    }
}
.liberty-modal-quote {
    & .quote-checkbox {
        float:left;
        width:30px;
    }
    .quote-checkbox-text {
        overflow:hidden;
        display:block;
        width:auto;
        margin:0 0 0 10px;
    }
    &.modal {
        text-align:center;
        padding:0!important;
        &:before {
            content: '';
            display:inline-block;
            height:100%;
            vertical-align:middle;
            margin-right:0px;
          }
      }
      & .modal-dialog {
        display:inline-block;
        text-align:left;
        vertical-align:middle;
        @include breakpointMin(768){
            width:600px;
        }
      }
}
.liberty-mutual {
    display:inline-block;
    background: #FFD000;
    padding:10px;
    cursor:pointer;
    border-radius:5px;
    margin-top:30px;
    text-align:center;
    & .liberty-mutual-logo {
        width:200px;
        display:inline-block;
        vertical-align:middle;
        padding-right:10px;
        border-right:1px solid #000;
        @include breakpointMax(767){
            padding-right:0px;
            padding-bottom:10px;
            border-bottom: 1px solid #000;
            border-right:none;
        }
    }
    & .quote-text {
        display:inline-block;
        padding-left:10px;
        font-weight:bold;
        @include breakpointMax(767){
            padding-left:0px;
            padding-top:5px;
            display:block;
        }
    }
}

.dc-wrapper-left {
    width: 70%;
    display: inline-block;
    vertical-align: top;
    margin-right: -1px;
}
.dc-wrapper-right {
    width: 30%;
    display: inline-block;
    vertical-align: top;
}
/********************************/
.schedule_popup {
    & .schedule-header {
        width:100%;
        display: block;
        text-align: center;
        color: #000;
        margin-bottom:16px;
        margin-top: 0px;
        border-bottom: 1px solid #d1d1d5;
        padding-bottom: 16px;
        font-size: 25px;

    }
    & .modal-body {
        padding-left: 34px;
        padding-right: 34px;
        padding-bottom: 34px;
        padding-top: 20px;
    }
    & .modal-footer {
        padding:22px 34px;
        border-top: 2px solid #d1d1d5;
    }
    & .schedule-content {
        width: 100%;
        color: #000;
        padding: 0 50px;
        font-size: 17px;
        text-align: center;
        margin-bottom: 40px;
    }
    & .appointment-type {
        width: 100%;
        display: block;
        margin-bottom: 25px;
    }
    
    & .appointment-option {
        height:82px;
        width:100%;
        border:1px solid #DDDDDD;
        border-radius:5px;
        background: #fff;
        margin-top: 15px;
        & .option-icon {
            width: 100%;
            display: block;
            text-align: center;
            line-height: normal;
            & i {
                font-size: 18px;
            }
        }
        & .option-box {
            width: 100%;
            display: table-cell;
            vertical-align: middle;
        }
        & .option-name {
            width: 100%;
            display: block;
            text-align: center;
            padding-top: 10px;
            line-height: normal;
        }
        & [type=radio] {
            display: none;
            &:checked {
                + {
                    & .beds-radio-fill {
                        background:var(--theme-primary-light-color, #365b9d);
                    }
                }
            }
        }
        & .beds-radio-fill {
            height:80px;
            display: table;
            width: 100%;
            vertical-align: middle;
        }
        & label {
            width:50%;
            font-size:14px;
            height:80px;
            text-align:center;
            line-height:80px;
            margin-bottom:0px;
            cursor:pointer;
            & .beds-input-hidden {
                &:checked {
                    + {
                        & .beds-radio-fill {
                            background:var(--theme-primary-light-color, #365b9d);
                            color:#fff;
                            
                        }
                    }
                }
            }
            &:first-child {
                border:none;
                & .beds-input-hidden {
                    &:checked {
                        + {
                            & .beds-radio-fill {
                                border-radius:5px 5px 5px 5px;
                            }
                        }
                    }
                }
            }
            &:last-child {
                & .beds-input-hidden {
                    &:checked {
                        + {
                            & .beds-radio-fill {
                                border-radius:5px 5px 5px 5px;
                            }
                        }
                    }
                }
            }
        }
    }
    & .appointment-time-box {
        width: 100%;
        background: #fff;
        border-radius: 7px;
        display: block;
        min-height: 75px;
        padding: 13px 15px;
        box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.4);
        text-align: center;
        & .appointment-titel {
            width: 100%;
            display: block;
            color: var(--theme-primary-color, #1f407b);
            font-size: 16px;
        }
        & .appointment-time {
            width: 100%;
            display: block;
            color: var(--theme-tertiary-color, #4c4c4c);
            font-size: 19px;
            padding-top: 5px;
            font-weight: 600;
            line-height: normal;
            & span {
                display: inline-block;
            }
            & .appointment-icon {
                display: inline-block;
                color: var(--theme-tertiary-color, #4c4c4c);
                margin-right: 6px;
            }
        }
    }
    & .textbox-large {
        &.textarea-box{
            padding: 15px 20px 10px 20px;
        }
    }
    & .select-schedule-date {
        & .schedule-date-datepicker {
            margin-top: 15px;
            width: 100%;
            display: block;
            & .react-datepicker-wrapper {
                width: 100%;                
            }
            & .date-popper {
                & .react-datepicker__month-container {
                    width: 210px;
                }
                & .react-datepicker__current-month {
                    font-size: 15px;
                } 
                & .react-datepicker-time__header {
                    font-size: 15px;
                } 
                & .react-datepicker-year-header {
                    font-size: 15px;
                }
                & .react-datepicker__day-name {
                    font-size: 15px;
                    margin: 5px;
                }
                & .react-datepicker__day {
                    font-size: 15px;
                    margin: 5px;
                } 
                & .react-datepicker__time-name {
                    font-size: 15px;
                }
                & .react-datepicker__header--time {
                    padding: 18px 5px 17px;
                }  
                
                & .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
                    right: 120px;
                }

                & .react-datepicker__time-container {
                    width: 110px;
                    & .react-datepicker__time {
                        & .react-datepicker__time-box {
                            width: 109px;
                            & ul.react-datepicker__time-list {
                                & li.react-datepicker__time-list-item {
                                    font-size: 15px;
                                    height: 35px;
                                }
                            } 
                        }
                    }
                } 
            }            
        }
    }
    & .content-title {
        font-size: 18px;
        color: #000;
        display: block;
    }
}

.fbtn-wrapper {
    display: inline-block;
    text-align: left;
    vertical-align: top;
    margin-right: 10px;
    margin-bottom: 10px;
    @include breakpointMax(600){
        display: block;
        margin-right: 0;
    }
}

.td-btn {
    /* background: #9A242C !important; */
    & .td-btn-title {
        display: inline-block;
        vertical-align: top;
        margin-right: 10px;
        font-size: 15px !important;
        & .td-btn-text {
            font-size: 10px !important;
        }
    }
    & .td-btn-img {
        display: inline-block;
        vertical-align: top;
        & img {
            width: 34px;
            height: 34px;
        }
    }
}