.tru-form {
    border: 1px solid #bebebe;
    border-radius: 20px;
    width: 100%;
    padding: 15px;

    .tru-form_textbox {
        border: 1px solid #bebebe;
        color: #848484;
        @include placeholder {
            color: #bebebe;
          }
    }

    .tru-form_icon {
        width: 38px; 
        height: 38px;
    }
    .tru-form_cost {
        padding: 5px 10px;
        display: inline-block;
    }
    .tru-form_description {
        padding: 5px 10px 5px 0;
        color:#bebebe;
        float: right;
    }
    .tru-form_warning {
        padding:0 10px 5px 5px;
        color:#D73B1C;
        float:left;
        font-size:12px;
        line-height:13px;
        @include breakpointMax(768){
            line-height:normal;
        }
    }
    .tru-form_tlc {
        color: #53DFF7;
        float: right;
    }
    &.send-email-form {
        padding:0 15px;
    }

    > div > div { margin-bottom: 10px; }
    
    @extend .tru-headings
}
.mortgage_cal_arrow {
    color:#fff; 
}
.tru-form-gradient {
    @include linearGradient(top, #56dff7, #e7f9f8);
}

.tru-oval { 
    background: linear-gradient(270deg, var(--theme-secondary-color), var(--theme-secondary-dark-color));
    border-radius: 20px;
    display: inline-block;
    text-align: center;
    line-height: initial;
    //background-color:var(--theme-secondary-color, #fbb040); 
    &.primary-oval {
        background: linear-gradient(270deg, var(--theme-primary-light-color), var(--theme-primary-dark-color));
    }
    .tru-oval_text {
        color:white;
        font-size: 14px;
        font-family: FilsonProMedium;
        padding: 9.5px 0;
        width: 38px;
        display:inline-block;
    }
    img { 
        padding: 9px;
        width: 38px;
        height: 38px;
    }
    
}

@supports (-ms-ime-align:auto) {
    .tru-oval {
        background:linear-gradient(270deg, #fbb040, #fa9b0e);
    }
    .primary-oval {
        background:linear-gradient(270deg, #365b9d, #183261);
    }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .tru-oval {
        background:linear-gradient(270deg, #fbb040, #fa9b0e);
    }
    .primary-oval {
        background:linear-gradient(270deg,#365b9d 0,#183261);
    }   
}
 
.tru-imgcircle {
    overflow: hidden;
    background: #f1f0f0;
    border-radius: 100%;
    text-align: center;
}

.tru-imgcircle-sm {
    width: 40px;
    height: 40px;
}
.tru-imgcircle-lg {
    width: 70px;
    height: 70px;
}
.collapsed .arrow-down { 
    display: none;
}
.arrow-down {
    width: 0px;
    height: 0px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid black;
}

.tru-pencil {
	height: 21.92px;
    width: 21.92px;
    // margin: 0 10px;
    &.tru-pencil-small {
        width:14px;
        height:14px;
        margin-top: 2px;
    }
}

.openhouse-box {
	height: 69.31px;
	width: 105.49px;
	border-radius: 10px;
	background: linear-gradient(180deg, #CCCCCC 0%, #888888 100%);
}

