.chat-support-button {
  position: fixed;
  right: 26px;
  bottom: 20px;
  z-index: 99;
  transition: 0.3s ease-in-out; }
  .chat-support-button .chat-support {
    display: table-cell;
    vertical-align: middle;
    margin: 0;
    text-align: center; }
    .chat-support-button .chat-support .chat-icon {
      font-size: 30px;
      margin-top: 5px; }
    .chat-support-button .chat-support .ai-chatbot-icon {
      height: 36px;
      width: 36px; }
  .chat-support-button .chat-window-name {
    color: #fff;
    display: none;
    font-size: 15px;
    vertical-align: middle; }

.chat_button_bg {
  height: 65px;
  width: 65px;
  border-radius: 100%;
  text-align: center;
  cursor: pointer;
  line-height: normal;
  position: relative;
  padding: 0 10px;
  position: relative;
  color: #ffffff;
  display: table; }
  .chat_button_bg.gray_bg {
    background: #ccc; }
    .chat_button_bg.gray_bg:after {
      position: absolute;
      top: 15px;
      right: 20px;
      line-height: normal;
      font-family: 'FontAwesome';
      font-size: 17px;
      color: #fff;
      content: "\F077";
      display: none; }

.chat-window {
  width: 360px;
  height: calc(100% - 100px);
  max-height: 500px;
  position: fixed;
  right: 24px;
  bottom: -24px;
  box-sizing: border-box;
  background: #fff;
  color: #000;
  border-radius: 8px;
  box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
  z-index: 9;
  transition: 0.3s ease-in-out;
  border-radius: 12px 12px 0 0;
  /* @media (min-width: 768px) and (max-width: 812px) {
        width: 100%;
        right: 0px;
        height: 100%;
        max-height: 100%;
    } */ }
  @media only screen and (max-width: 767px) {
    .chat-window {
      width: 100%;
      right: 0px;
      height: 100%;
      max-height: 100%; } }
  .chat-window.inactive {
    transform: translateY(100%); }
  .chat-window.active {
    bottom: 24px;
    /* @media (min-width: 768px) and (max-width: 812px) {
            bottom: 0px;
            z-index: 9999;
        } */ }
    @media only screen and (max-width: 767px) {
      .chat-window.active {
        bottom: 0px;
        z-index: 9999; } }
  .chat-window .chat-header {
    width: 100%;
    position: relative;
    text-align: left;
    float: left;
    background: #fff;
    box-shadow: 0 9px 10px 0 rgba(150, 148, 148, 0.12);
    padding: 15px 15px;
    border-radius: 10px 10px 0 0;
    min-height: 55px;
    border-bottom: 1px solid #efefef; }
    .chat-window .chat-header .chat-header-name {
      width: 100%;
      line-height: normal;
      line-height: 27px;
      float: left; }
      .chat-window .chat-header .chat-header-name .header-short-name {
        display: inline-block;
        vertical-align: middle;
        font-size: 17px;
        color: #000;
        line-height: 23px;
        max-width: 280px; }
    .chat-window .chat-header .window-close {
      width: 30px;
      height: 30px;
      text-align: center;
      cursor: pointer;
      float: right;
      box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
      border-radius: 100%;
      background: #ffffff;
      color: #000; }
      .chat-window .chat-header .window-close .close-icon {
        color: #000;
        font-size: 18px;
        line-height: 32px; }
  .chat-window .message-list {
    position: relative;
    height: calc(100% - 55px);
    width: 100%;
    float: left; }
    .chat-window .message-list .message-inner {
      overflow-y: auto;
      padding: 20px 15px 20px 15px;
      height: calc(100% - 70px); }
      .chat-window .message-list .message-inner .chat-mainbox .chat-right {
        float: left;
        width: 100%;
        padding: 0;
        margin: 0; }
        .chat-window .message-list .message-inner .chat-mainbox .chat-right .chat-right-meg {
          min-height: 20px;
          width: 100%;
          text-align: left;
          position: relative;
          float: right;
          font-size: 13px;
          border-radius: 10px;
          color: #000;
          margin-top: 10px;
          margin-bottom: 18px; }
          .chat-window .message-list .message-inner .chat-mainbox .chat-right .chat-right-meg .chat-box {
            margin: 0 50px 0 0;
            max-width: 260px;
            width: 100%;
            cursor: pointer;
            background: #ebeced;
            padding: 12px 12px 12px 12px;
            border-radius: 10px;
            display: inline-block;
            vertical-align: middle;
            min-height: 40px;
            position: relative;
            line-height: 17px;
            font-size: 13px;
            word-break: break-word; }
            @media only screen and (max-width: 767px) {
              .chat-window .message-list .message-inner .chat-mainbox .chat-right .chat-right-meg .chat-box {
                max-width: 100%;
                width: calc(100% - 50px); } }
            .chat-window .message-list .message-inner .chat-mainbox .chat-right .chat-right-meg .chat-box:hover .chat-action-btn {
              display: block; }
            .chat-window .message-list .message-inner .chat-mainbox .chat-right .chat-right-meg .chat-box:before {
              content: "";
              width: 30px;
              height: 25px;
              background-color: #ebeced;
              position: absolute;
              right: -14px;
              bottom: 0; }
            .chat-window .message-list .message-inner .chat-mainbox .chat-right .chat-right-meg .chat-box:after {
              content: "";
              width: 30px;
              height: 40px;
              background-color: #fff;
              position: absolute;
              right: -30px;
              bottom: -11px;
              border-radius: 0 0 0 50px; }
            .chat-window .message-list .message-inner .chat-mainbox .chat-right .chat-right-meg .chat-box .chat-action-btn {
              position: absolute;
              top: 12px;
              right: 10px;
              padding-right: 5px;
              display: none;
              background: #ebeced;
              padding: 0px 10px 2px 2px; }
              .chat-window .message-list .message-inner .chat-mainbox .chat-right .chat-right-meg .chat-box .chat-action-btn i {
                margin-left: 5px; }
          .chat-window .message-list .message-inner .chat-mainbox .chat-right .chat-right-meg .chat-profile-round {
            position: absolute;
            top: 0px;
            right: 0px;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            float: right;
            color: #033258;
            font-size: 14px;
            text-align: center;
            line-height: 33px;
            margin: 0; }
            .chat-window .message-list .message-inner .chat-mainbox .chat-right .chat-right-meg .chat-profile-round.text-bg {
              background: #D8D8D8;
              line-height: 40px; }
            .chat-window .message-list .message-inner .chat-mainbox .chat-right .chat-right-meg .chat-profile-round.chat-photo {
              background: none;
              border: 1px solid #D8D8D8;
              border-radius: 100%;
              padding: 2px; }
              .chat-window .message-list .message-inner .chat-mainbox .chat-right .chat-right-meg .chat-profile-round.chat-photo img {
                width: 100%;
                height: 100%;
                border-radius: 100%; }
        .chat-window .message-list .message-inner .chat-mainbox .chat-right.chat-repeater {
          margin-top: -24px; }
      .chat-window .message-list .message-inner .chat-mainbox .chat-left {
        float: left;
        width: 100%;
        padding: 0;
        margin: 0; }
        .chat-window .message-list .message-inner .chat-mainbox .chat-left .chat-right-meg {
          min-height: 20px;
          width: 100%;
          text-align: left;
          position: relative;
          float: right;
          font-size: 13px;
          border-radius: 10px;
          color: #000;
          margin-top: 10px;
          margin-bottom: 18px; }
          .chat-window .message-list .message-inner .chat-mainbox .chat-left .chat-right-meg .chat-box {
            margin: 0 0 0 50px;
            max-width: 260px;
            width: 100%;
            cursor: pointer;
            background: #f5f6f7;
            padding: 12px 12px 12px 12px;
            border-radius: 10px;
            display: inline-block;
            vertical-align: middle;
            min-height: 40px;
            position: relative;
            line-height: 17px;
            font-size: 13px;
            word-break: break-word; }
            @media only screen and (max-width: 767px) {
              .chat-window .message-list .message-inner .chat-mainbox .chat-left .chat-right-meg .chat-box {
                max-width: 100%;
                width: calc(100% - 50px); } }
            .chat-window .message-list .message-inner .chat-mainbox .chat-left .chat-right-meg .chat-box:before {
              content: "";
              width: 22px;
              height: 15px;
              background-color: #f5f6f7;
              position: absolute;
              left: -7px;
              bottom: 0; }
            .chat-window .message-list .message-inner .chat-mainbox .chat-left .chat-right-meg .chat-box:after {
              content: "";
              width: 15px;
              height: 17px;
              background-color: #fff;
              position: absolute;
              left: -15px;
              bottom: -2px;
              border-radius: 0 0 50px 0; }
          .chat-window .message-list .message-inner .chat-mainbox .chat-left .chat-right-meg .chat-profile-round {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            float: right;
            color: #033258;
            font-size: 14px;
            text-align: center;
            line-height: 33px;
            margin: 0; }
            .chat-window .message-list .message-inner .chat-mainbox .chat-left .chat-right-meg .chat-profile-round.text-bg {
              background: #D8D8D8;
              line-height: 40px; }
            .chat-window .message-list .message-inner .chat-mainbox .chat-left .chat-right-meg .chat-profile-round.chat-photo {
              background: none;
              border: 1px solid #D8D8D8;
              border-radius: 100%;
              padding: 2px; }
              .chat-window .message-list .message-inner .chat-mainbox .chat-left .chat-right-meg .chat-profile-round.chat-photo img {
                width: 100%;
                height: 100%;
                border-radius: 100%; }
        .chat-window .message-list .message-inner .chat-mainbox .chat-left.chat-repeater {
          margin-top: -24px; }
      .chat-window .message-list .message-inner .chat-mainbox .chat-date-time {
        width: 100%;
        float: left;
        text-align: center;
        margin-bottom: 5px; }
        .chat-window .message-list .message-inner .chat-mainbox .chat-date-time .tag-date {
          display: inline-block;
          background: #ededed;
          padding: 5px 10px;
          font-size: 12px;
          border-radius: 50px; }
  .chat-window .chat-notes-btnbox {
    width: 100%;
    position: absolute;
    bottom: 1px;
    left: 0;
    border-top: 1px solid #efefef;
    background: #e4e4e4; }
    .chat-window .chat-notes-btnbox .input-replay-box {
      padding: 7px 45px 7px 7px;
      min-height: 38px; }
      @media only screen and (max-width: 375px) {
        .chat-window .chat-notes-btnbox .input-replay-box {
          padding: 7px 35px 7px 7px; } }
      .chat-window .chat-notes-btnbox .input-replay-box.cancel-btn {
        padding-right: 90px; }
        @media only screen and (max-width: 375px) {
          .chat-window .chat-notes-btnbox .input-replay-box.cancel-btn {
            padding-right: 70px; } }
    .chat-window .chat-notes-btnbox .notes-submit {
      width: 40px;
      position: absolute;
      top: 15px;
      right: 15px;
      height: 38px; }
      @media only screen and (max-width: 375px) {
        .chat-window .chat-notes-btnbox .notes-submit {
          width: 25px; } }
      .chat-window .chat-notes-btnbox .notes-submit.cancel-btn {
        width: 80px; }
        @media only screen and (max-width: 375px) {
          .chat-window .chat-notes-btnbox .notes-submit.cancel-btn {
            width: 50px; } }
      .chat-window .chat-notes-btnbox .notes-submit .theme-btn {
        width: 40px;
        border-radius: 0 4px 4px 0;
        padding: 2px 7px;
        height: 100%;
        text-align: center; }
        @media only screen and (max-width: 375px) {
          .chat-window .chat-notes-btnbox .notes-submit .theme-btn {
            width: 25px; } }
        .chat-window .chat-notes-btnbox .notes-submit .theme-btn .material-icons {
          line-height: 27px;
          font-size: 20px; }
          @media only screen and (max-width: 375px) {
            .chat-window .chat-notes-btnbox .notes-submit .theme-btn .material-icons {
              font-size: 12px; } }
        .chat-window .chat-notes-btnbox .notes-submit .theme-btn.cancel-btn {
          width: 39px;
          border-radius: 0;
          margin-right: 1px; }
          @media only screen and (max-width: 375px) {
            .chat-window .chat-notes-btnbox .notes-submit .theme-btn.cancel-btn {
              width: 24px; } }
    .chat-window .chat-notes-btnbox .chat-input-message {
      width: 100%;
      display: block;
      padding: 15px 15px;
      position: relative; }

.chat-window.active + .chat-support-button {
  bottom: -80px; }

#direct-chat .chat-window {
  z-index: 100; }
  @media only screen and (max-width: 767px) {
    #direct-chat .chat-window {
      bottom: 0px;
      z-index: 10000; } }
