@import '_variables.scss';

.tru-input {
   color: black;
   border-radius: 10px;
   font-size: 18px;
   padding: 10px 20px;
   border: 1px solid #ddd;
   width: 100%;

   @include placeholder {
    color: #bebebe;
  }
}


.tru-input-square {
    border-radius: 0;
}

.tru-input.large {
    min-height:60px;

    @media only screen and (max-width: 480px){
        min-height:40px;
        padding:8px 20px;
    }
}
.tru-input-ph {
    @include placeholder {
        color: #848484;
      } 
}

.tru-dropdown {
    width: 100%; 
    border-radius: 10px;
    box-shadow: inherit;
    border: 1px solid #ddd;

    .react-select-box-label {
        color: #848484;
        font-size: 18px;
        position: relative;
        z-index: 4;
        padding: 0 3px 0 20px;
        @include breakpointMax(767){ 
            padding-left:10px;
        }
        @include breakpointMax(766){ 
            padding-left:10px;
        }
    }

    .react-select-box-clear{
        &:before {
            position: absolute;
            background-color: inherit;
            top:0px;
            right:13px;
            width:13px;
            left:inherit;
            content: "\F107";
            font-family: "FontAwesome";
            font-size:14px;
            color: #000;
            border: none !important;
        }
    }

    &.react-select-box-empty{
        .react-select-box {
            &:after {
                position: absolute;
                background-color: inherit;
                top:12px;
                right:11px;
                width:13px;
                left:inherit;
                content: "\F107";
                font-family: "FontAwesome";
                font-size:14px;
                color: #000;
                border: none !important;
            }
        }
        
    }


    .react-select-box {
        // &:before {
        //     content: ' ';
        //     //z-index: 9; 
        //     position: absolute;
        //     height: 20px;
        //     top: 15px;
        //     right: 34px;
        //     border-left: 1px solid #CBD2D7;
        // }
        &:after {
            @include breakpointMax(767){ right: 5px; }
        }
        &:focus {
            box-shadow: none;
        }
    }

    .react-select-box-options {
        width: 100%;
        z-index: 5;
    }

    .react-select-box-option {
       font-size: 18px; 
       z-index: 9;
       width: 100%;
        &:hover {
            color: #555;
            text-decoration: none;
        }
    }

    .react-select-box-label, .react-select-box-option {
        font-weight: 400;
    }    
}

.tru-dropdown-square {
    border-radius: 0;
    border: 1px solid #E4E4E4;

    .react-select-box-label {
        font-size: 14px;
        color: #000000 !important;
        font-weight: normal;
   }

    .react-select-box-options {
        width:100%;
        border: 1px solid #b2b2b2; 
        border-radius: 0;
    }

    .react-select-box-option {
        font-size: 14px; 
    }    
    // .react-select-box-option.react-select-box-option-selected {
    //     color: #53DFF7;
    //     border: 1px solid #53DFF7;
    // }
}

.tru-dropdown-square-sort {
    // width: 48%;
    display: inline-block;

    .react-select-box-container {
        width: 100%;
    }
    .react-select-box {
        padding:16px 0 15px 0  !important;
        line-height: normal;
    }
    .react-select-box:after {
        top:15px !important;
    }
    .react-select-box-label, .react-select-box-option {
        font-size: 12px;
    }
    .react-select-box-label {
        /*padding: 0 35px 0 20px;*/
        padding: 0 26px 0 14px;
    }
    .react-toggle-box {
        padding: 13px 10px;
        font-size: 14px;
        cursor: pointer;
    }
}

.tru-dropdown.square {
    border-radius: 0;
    border: 1px solid #E4E4E4;

    .react-select-box-label {
        font-size: 14px;
        color: #B2B2B2;
        @include breakpointMax(767){
            color: #000;
            padding-left:20px;
        }
    }

    .react-select-box-options {
        border-radius: 0;
        max-height: 260px;
        overflow-y: auto;
    }

    .react-select-box-option {
        font-size: 14px; 
    }    
}

.tru-dropdown.square.mbl {
    div {
        display: inline-block;
        cursor: pointer;
        width: 93%;
    }
    .smart-tlc-range {
        @include breakpointMax(767){ 
            font-size: 13px;
            line-height: 16px;
            color: #000;
            padding:15px 0 15px 20px;
        }
    }
    .smart-tlc-range span {
        @include breakpointMax(767){ 
            
        }
    }

    .smart-tlc-range:after {
        @include breakpointMax(767){ 
            
        }
    }
}

.tru-dropdown.square.filter_inner_commute {
    
    .commute_car {
        border-right: 1px solid #b2b2b2;
        padding: 6px;
    }

    .location_name {
        font-size: 14px;
        color: #000;
        padding: 13px 8px 15px 8px;
    }
    
}

.tru-dropdown {
    &.square {
        &.selected {
            border: 1px solid #B2B2B2;
            & .react-select-box-label {
                color: #000000;
            }
        }
    }
}

.tru-dropdown.square.selected.filter_inner_commute {
    
    .location_name {
        color: #000000;
    }
    
}

.tru-checkbox {
	display: inline-block;
	position: relative;
	// padding-left: 35px;
    // margin-top:12px;
    margin-bottom: 0;
	cursor: pointer;
	font-size:14px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		&:checked {
			&~.tru-checkbox_checkmark {
                background-color: $white_background;
                border: 1px solid #b2b2b2;
				&:after {
					display: block;
				}
			}
		}
    }
	
	.tru-checkbox_checkmark {
        display:inline-block;
        vertical-align:middle; 
        height:24px;
        width:24px;
        border:1px solid #B2B2B2;
        background-color: $gray_background;
		&:after {
			left:8px;
			top:2px;
			width: 7px;
			height: 14px;
            border: solid #29b662;
			border-width: 0 2px 2px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
            transform: rotate(45deg);
            content: "";
            position: absolute;
            display: none;
		}
	}
}

.tru-checkbox-area  {
    & .tru-checkbok-name {
        display:inline-block;
        padding-left:10px;
        vertical-align: middle; 
        font-size:14px;
        @include breakpointMax(1024){
            font-size:13px; 
            padding-left:4px;
        }
        &.long_text_checkbox {
            overflow:hidden;
            display:block;
            padding-left:10px;
            float:none;
            @include breakpointMax(1024){
                padding-left:4px;
            }
        }
    }
}

/******************** gray checkbox close start **************************/
// .tru-checkbox-gray-close{
// 	display:inline-block;
// 	position: relative;
// 	cursor: pointer;
// 	font-size: 22px;
// 	-webkit-user-select: none;
// 	-moz-user-select: none;
// 	-ms-user-select: none;
// 	user-select: none;
// 	input {
// 		cursor: pointer;
// 		height: 0;
// 		width: 0;
// 		&:checked {
// 			&~.tru-checkbox-gray-close-check {
//                 background-color: $white_background;
//                 border: 1px solid #b2b2b2;
// 				&:after {
// 					display: block;
// 				}
// 			}
//         }
//         &:checked {
// 			&~.tru-checkbox-gray-close-check {
// 				&:before {
// 					display:none;
// 				}
// 			}
//         }
//         &:checked {
// 			&~.tru-checkbox-gray-close-check {
// 				& span:after {
//                     display:block;
//                     top:4px;
//                     left:10px;
//                     height:14px;
//                     width:2px;
//                     border: solid #f15f2b;
//                     border-width: 0 2px 2px 0;
//                     transform: rotate(-45deg); 
// 				}
// 			}
// 		}
// 	}
// 	.tru-checkbox-gray-close-check {
// 		&:after {
//             top:4px;
//             left:10px;
//             height:14px;
//             width:2px;
// 			border: solid #f15f2b;
// 			border-width: 0 2px 2px 0;
//             transform: rotate(45deg);
               
//         }
//     }
// }
// .tru-checkbox-gray-close-check{
//     display:inline-block; 
// 	height:24px;
//     width:24px;
//     border:1px solid #B2B2B2;
//     vertical-align:middle;
// 	background-color: $gray_background;
// 	&:after {
// 		content: "";
// 		position: absolute;
// 		display: none;
//     }
//     &:before {
//         position: absolute;
//         top:4px;
//         left:10px;
//         content: ' ';
//         height:14px;
//         width:2px;
//         background-color: #b2b2b2;
//         transform:rotate(45deg);
//     }
//     & span:after {
//         position: absolute;
//         top:4px;
//         left:10px;
//         content: ' ';
//         height:14px;
//         width:2px;
//         background-color: #b2b2b2;
//         transform:rotate(-45deg);
//     }
// }
/******************** gray checkbox close End **************************/

/******************** gray checkbox start**************************/

.tru-checkbox-gray {
	display: inline-block;
	position: relative;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	input {
        position: absolute;
        opacity: 0;
		cursor: pointer;
		height: 0;
        width: 0;
        border-width:0px;
		&:checked {
			&~.tru-checkbox-gray_check{
                background-color: $white_background;
                border: 1px solid #b2b2b2;
				&:after {
					display: block;
				}
            }
            &:checked {
                &~.tru-checkbox-gray_check_close {
                    & span:after {
                        display:block;
                        top:4px;
                        left:10px;
                        height:14px;
                        width:2px;
                        border: solid #ed1c24;
                        border-width: 0 2px 2px 0;
                        transform: rotate(-45deg); 
                    }
                    &:after {
                        border: solid #ed1c24;
                        position: absolute;
                        top: 4px;
                        left: 10px;
                        content: ' ';
                        height: 14px;
                        width: 2px;
                        -webkit-transform: rotate(45deg);
                        transform: rotate(45deg);
                        border-width: 0 2px 2px 0;
                    }
                }
            }
		}
    }
    
	
	.tru-checkbox-gray_check{
        display: inline-block;
        height:24px;
        width:24px;
        vertical-align:middle;
        border:1px solid #B2B2B2;
        background-color: $gray_background;
		 &:after {
		 	left:8px;
		 	top:2px;
		 	width: 7px;
		 	height: 14px;
		 	border: solid #29b662;
		 	border-width: 0 2px 2px 0;
		 	-webkit-transform: rotate(45deg);
		 	-ms-transform: rotate(45deg);
             transform: rotate(45deg);
         }
        & span:after {
            position: absolute;
            left:8px;
            top:2px;
            width: 7px;
            height: 14px;
            border: solid #B2B2B2;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            content: ' ';
        }
    }
    .tru-checkbox-gray_check.tru-checkbox-gray_check_close{
        &:after {
            width:2px;
            left:10px;
            top:3px;
        }
    }
    .tru-checkbox-gray_check_close{ 
        & span:after {
            position: absolute;
            top:4px;
            left:10px;
            content: ' ';
            height:14px;
            width:2px;
            background-color: #b2b2b2;
            transform:rotate(-45deg);
            @include breakpointMax(768){
                top:5px;
                left:12px;
            }
        } 
    }
    .tru-checkbox-gray_check_close:before {
        position: absolute;
        top: 4px;
        left: 10px;
        content: ' ';
        height: 14px;
        width: 2px;
        background-color: #b2b2b2;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        @include breakpointMax(768){
            top:5px;
            left:12px;
        }
    }
}
 .tru-checkbox-gray_check{
 	display: inline-block;
 	height:24px;
     width:24px;
     vertical-align:middle;
     border:1px solid #B2B2B2;
 	background-color: $gray_background;
 	&:after {
 		content: "";
 		position: absolute;
 		display: none;
    }
    & span:after {
        position: absolute;
        left:8px;
        top:2px;
        width: 7px;
        height: 14px;
        border: solid #B2B2B2;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        content: ' ';
     }
 }
/******************** white checkbox End**************************/

.tru-checkbox-gray.joint-checkbox, .tru-checkbox-gray-close.joint-checkbox {
	width: auto;
	margin:0px; 

    @include breakpointMax(767){ margin: 0; }
}
.tru-checkbox-gray-close.joint-checkbox {
	margin-left:-1px; 
}

.tru-input-compact {
    font-size:13px;
    padding:5px;
    min-height:33px;
    background:#fff; 
    border:1px solid #ddd;
    width:100%;
}

.tru-dropdown-compact{
    border-radius: 0;
    border:1px solid #E4E4E4;
    width:100%;
    box-shadow:inherit;
    &.react-select-container {
        &.full-height {
            & .react-select__control {
                    max-height:inherit !important;
                & .react-select__value-container {
                    max-height:inherit !important;
                }
            }
        }
    }
    
    & .react-select-box-label {
        font-size:13px;
        color: #B2B2B2;
        font-weight:normal; 
    }
    & .react-select-box-options {
        border-radius: 0;
        padding:0px; 
        margin:1px 0 0 0;
        max-height: 225px;
        overflow-y: auto; 
        width:100%; 
        
    }
    & .react-select-box-option {
        font-size: 14px; 
        color: #555555;
        font-weight:normal;
        padding:10px 5px;
        &:hover {
            text-decoration:none; 
        }
    }
    & .react-select-box {
        padding:5px 0;
        min-height:31px;
        &:focus {
            box-shadow:inherit;
        }
        @supports (-ms-ime-align:auto) {
            min-height:26px !important;
        }
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            min-height:26px !important;
        }
        &:after {
        content: "\F107";
        font-family:"FontAwesome";
        font-size: 14px;
        color: #000;
        border:none;
        top: 5px;
        right: 7px; 
        }
    }  
    & .react-select-box-label {
        padding:0 5px 0 5px;
    }
    & .react-select-box-clear {
        display:none; 
        
    }
    &.react-select-box-multi {
        & .react-select-box-options {
            & .react-select-box-off-screen {
                & a {
                    padding: 10px 25px;
                    &:before {
                        left:5px;
                        top:12px;
                    }
                }
            }
        }
    }
    &.react-select-container {
        border: 0 solid #E4E4E4;
        font-size: 13px;
        & .react-select__control, & .react-select__control--is-focused, & .react-select__control--menu-is-open {
            border-radius: 0;
            min-height:32px;    
            max-height:32px;
            box-shadow: none;
            border-color: #E4E4E4;
            & .react-select__value-container {
                @include breakpointMax(1024){
                    max-height:25px !important;
                }
            }
            
            & .react-select__value-container {
                padding: 0 5px;                
            }
            & .react-select__value-container--is-multi.react-select__value-container--has-value {
                max-height: 30px;
                /* & .react-select__multi-value {
                    & .react-select__multi-value__label {                        
                    }    
                } */
            }
            & .react-select__indicators {
                & .react-select__indicator-separator {
                    width: 0;
                }
                & .react-select__indicator {
                    padding: 5px 3px;
                    &.react-select__clear-indicator {
                        padding: 0;
                        & svg {
                            height: 12px;
                            width: 12px;
                        }
                    }
                }
            }
            &:hover {
                border-radius: 0;
                border-color: #E4E4E4;
            }
        }
        & .react-select__menu {
            border-radius: 0;
            margin-top: 0;
            z-index: 4;
            & .react-select__menu-list {
                max-height: 225px;
                & .react-select__option--is-focused, & .react-select__option--is-selected {
                    background-color: #f4f4f4;
                    color: inherit;
                }
            }
        }
        &.square {
            border: 0 solid #E4E4E4;            
            & .react-select__control, & .react-select__control--is-focused, & .react-select__control--menu-is-open {
                min-height: 46px;    
                max-height: 46px;
                border-color: #E4E4E4;
                & .react-select__value-container {
                    padding: 0 5px 0 20px;
                }
                & .react-select__value-container--is-multi.react-select__value-container--has-value {
                    max-height: 45px;
                }
                & .react-select__indicators {
                    & .react-select__indicator {
                        padding: 5px 8px;
                        &.react-select__clear-indicator {
                            padding: 0;
                            & svg {
                                height: 20px;
                                width: 20px;
                            }
                        }
                    }
                }
            }
            & .react-select__menu {
                z-index: 9999999;
            }
            &.selected {
                border: 1px solid #B2B2B2;
            }
        }
    }
    & .react-select__control {
        & .react-select__placeholder {
                color: #B2B2B2;
            @include breakpointMax(768) {
                color: #000;
            }
        }
    }
}
.tru-autocomp-compact{
    .rbt-input-main, .rbt-input-hint{
        @extend .tru-input;
        @include breakpointMin(768) {
            top:0px;
            right:0;
            box-sizing:inherit;
        }
        right:0px;
        border-radius:inherit;
        position: relative;
        // overrides for typeahead
        border: 1px solid #E4E4E4 !important;
        width: 100% !important;
        padding:5px 18px 5px  5px !important;
        /*background-color: white !important;*/
        color: #B2B2B2;
        font-size:13px;
        box-sizing:inherit !important;
        min-height:32px;
    }
    & .rbt-input {
        & ::placeholder {
            color:#B2B2B2;
            @include breakpointMax(768){
             color:#000;
            }
        }
    }
    &.large_input {
        & .rbt-input-main, & .rbt-input-hint{
            min-height:46px;
            padding:13px 20px 13px 20px !important;
        }
        & .close {
            right: 10px;
            top: 10px;
        }
    }

    &.autocomp {
        font-size: 18px;
        padding:0px;
        width: 100%;
        float:left;
        height:auto;
        background-color: transparent;
       div {
           width:100%;
            float:left; 
        }
        
    }

    .close {
        position: absolute;
        float: none;
        left:inherit;
        right:5px;
        z-index: 10001;
        top:5px;
    }
    & .dropdown-header {
        padding:3px 5px;
    }
    & .dropdown-menu {
        top:32px;
        left:0;
        margin: 0;
        font-size:14px;
        width:100%; 
        box-shadow:inherit;
        & li {
            & a {
                white-space:inherit;
                padding:3px 5px;
            }
        }
        & .dropdown-item {
            white-space:inherit;
            padding:3px 5px;
            font-weight: 400;
            color: #7f7f7f;
            clear: both;
            display: block;
            line-height: 1.42857143;
            &:focus, &:hover {
                color: #727272;
                text-decoration: none;
                background-color: #f5f5f5;
            }
        }
    }
}