
.full-width {
    width:100%;
}
.dp-block {
    display:block;
}
.dp-inline {
    display:inline-block;
}
.dp-table {
   display:table;
}
.vertical-mid-content {
    vertical-align: middle;
    display: table-cell;
    @include breakpointMax(575) {
        &.vertical-mid-full {
            display: block;
        }
    }
}
.mb-d-none {
    @include breakpointMax(991) {
        display:none !important;
    }
}
.v-align-md {
    vertical-align:middle;
}
.v-align-tp {
    vertical-align:top;
}
.v-align-bt {
    vertical-align:bottom;
}
.ft-none { 
    float:none;
}
.ft-lt { 
    float:left; 
}
.ft-rt { 
    float:right; 
}
.cr-lt { 
    clear:left;
}
.width-auto {
    width:auto;
}
.cursor-pointer {
    cursor: pointer;
}
.text-uppercase {
    text-transform:uppercase;
}
.text-justify {
    text-align:justify;
}
.ft-bd {
    font-weight:bold !important;
}
.pos-rl {
    position:relative;
}
.pos-ab {
    position:absolute;
}
.form-control-text {
    font-size: 13px;
    font-weight: normal;
    margin-bottom: 5px;
}
.br-bt-gray {
    border-bottom: 1px solid #e4e4e4;
}

/******************padding top********************/
.pd-tp-0 {
    padding-top:0px;
}
.pd-tp-5 {
    padding-top:5px;
}
.pd-tp-10 {
    padding-top:10px;
}
.pd-tp-15 {
    padding-top:15px;
}
.pd-tp-20 {
    padding-top:20px;
}
.pd-tp-24 {
    padding-top:24px;
}
.pd-tp-25 {
    padding-top:25px;
}
.pd-tp-30 {
    padding-top:30px;
}
.pd-tp-35 {
    padding-top:35px;
}
.pd-tp-40 {
    padding-top:40px;
}
.pd-tp-45 {
    padding-top:45px;
}
.pd-tp-50 {
    padding-top:50px;
}
.pd-tp-55 {
    padding-top:55px;
}
.pd-tp-60 {
    padding-top:60px;
}
/******************padding top end********************/

/******************padding right start********************/
.pd-rt-0 {
    padding-right:0;
}
.pd-rt-2 {
    padding-right:2px;
}
.pd-rt-10 {
    padding-right:10px;
}
.pd-rt-15 {
    padding-right:15px;
}
.pd-rt-20 {
    padding-right:20px;
}
.pd-rt-18 {
    padding-right:18px;
}
.pd-rt-19 {
    padding-right:19px;
}
.pd-rt-25 {
    padding-right:25px;
}
.pd-rt-30 {
    padding-right:30px;
}
.pd-rt-35 {
    padding-right:35px;
}
.pd-rt-40 {
    padding-right:40px;
}
.pd-rt-45 {
    padding-right:45px;
}
.pd-rt-50 {
    padding-right:50px;
}
.pd-rt-55 {
    padding-right:55px;
}
.pd-rt-60 {
    padding-right:60px;
}
/******************padding right End********************/

/******************padding bottom start********************/
.pd-bt-0 {
    padding-bottom:0px;
}
.pd-bt-5 {
    padding-bottom:5px;
}
.pd-bt-10 {
    padding-bottom:10px;
}
.pd-bt-15 {
    padding-bottom:15px;
}
.pd-bt-20 {
    padding-bottom:20px;
}
.pd-bt-24 {
    padding-bottom:24px;
}
.pd-bt-25 {
    padding-bottom:25px;
}
.pd-bt-30 {
    padding-bottom:30px;
}
.pd-bt-35 {
    padding-bottom:35px;
}
.pd-bt-40 {
    padding-bottom:40px;
}
.pd-bt-45 {
    padding-bottom:45px;
}
.pd-bt-50 {
    padding-bottom:50px;
}
.pd-bt-55 {
    padding-bottom:55px;
}
.pd-bt-60 {
    padding-bottom:60px;
}
/******************padding bottom End********************/

/******************padding left start********************/
.pd-lt-0 {
    padding-left:0px;
}
.pd-lt-5 {
    padding-left:5px;
}
.pd-lt-10 { 
    padding-left:10px; 
}
.pd-lt-15 { 
    padding-left:15px; 
}
.pd-lt-17 {
    padding-left:17px;
}
.pd-lt-20 { 
    padding-left:20px;
}
.pd-lt-23 { 
    padding-left:23px; 
}
.pd-lt-25 { 
    padding-left:25px;
}
.pd-lt-30 { 
    padding-left:30px;
}
.pd-lt-35 { 
    padding-left:35px;
}
.pd-lt-40 { 
    padding-left:40px;
}
.pd-lt-45 { 
    padding-left:45px;
}
.pd-lt-50 { 
    padding-left:50px;
}
.pd-lt-55 { 
    padding-left:55px;
}
.pd-lt-60 { 
    padding-left:60px;
}
/******************padding left End********************/

/******************padding Top Bottom start********************/
.pd-tp-bt-0 {
	padding-top:0px;
    padding-bottom:0px;
}
.pd-tp-bt-10 {
	padding-top:10px;
    padding-bottom:10px;
}
.pd-tp-bt-13 {
	padding-top:13px;
    padding-bottom:13px;
}
.pd-tp-bt-15 {
	padding-top:15px;
    padding-bottom:15px;
}
.pd-tp-bt-20 {
	padding-top:20px;
    padding-bottom:20px;
}
.pd-tp-bt-30 {
	padding-top:30px;
    padding-bottom:30px;
}
.pd-tp-bt-40 {
	padding-top:40px;
    padding-bottom:40px;
}
.pd-tp-bt-50 {
	padding-top:50px;
    padding-bottom:50px;
}

/******************padding Top Bottom End********************/

/******************padding Left Right start********************/
.pd-lt-rt-0 {
	padding-left:0;
    padding-right:0;
}
.pd-lt-rt-5 {
    padding-left:5px;
    padding-right:5px;
}
.pd-lt-rt-10 {
    padding-left: 10px;
    padding-right: 10px;
}
.pd-lt-rt-15 {
    padding-left:15px;
    padding-right:15px;
}
.pd-lt-rt-20 {
    padding-left:20px;
    padding-right:20px;
}
.pd-lt-rt-30 {
    padding-left:30px;
    padding-right:30px;
}
.pd-lt-rt-40 {
    padding-left:40px;
    padding-right:40px;
}
.pd-lt-rt-50 {
    padding-left:50px;
    padding-right:50px;
}
/******************padding Left Right End********************/

/******************padding All site start********************/
.pd-0 { 
    padding:0;
}
.pd-5 { 
    padding:5px;
}
.pd-10 { 
    padding:10px;
}
.pd-20 { 
    padding:20px;
}
/******************padding All site End********************/

/******************margin Top start********************/
.mg-tp-0 {
    margin-top:0; 
}
.mg-tp-5 { 
    margin-top:5px; 
}
.mg-tp-6 { 
    margin-top:6px; 
}
.mg-tp-8 {
    margin-top:8px; 
}
.mg-tp-10 { 
    margin-top:10px; 
}
.mg-tp-12 { 
    margin-top:12px; 
}
.mg-tp-15 { 
    margin-top:15px; 
}
.mg-tp-16 {
    margin-top:16px;
}
.mg-tp-20 { 
    margin-top:20px; 
}
.mg-tp-24 {
    margin-top:24px;
}
.mg-tp-25 { 
    margin-top:25px; 
}
.mg-tp-30 { 
    margin-top:30px; 
}
.mg-tp-35 { 
    margin-top:35px; 
}
.mg-tp-40 { 
    margin-top:40px; 
}
.mg-tp-45 { 
    margin-top:45px; 
}
.mg-tp-50 { 
    margin-top:50px; 
}
/******************margin Top End********************/

/******************margin right start********************/
.mg-rt-0 {
    margin-right:0;
}
.mg-rt-5 { 
    margin-right:5px; 
}
.mg-rt-7 { 
    margin-right:7px; 
}
.mg-rt-10 { 
    margin-right:10px;
}
.mg-rt-15 {
    margin-right:15px;
}
.mg-rt-20 { 
    margin-right:20px; 
}
.mg-rt-25 { 
    margin-right:25px; 
}
.mg-rt-30 { 
    margin-right:30px; 
}
.mg-rt-35 { 
    margin-right:35px; 
}
.mg-rt-40 { 
    margin-right:40px; 
}
.mg-rt-45 { 
    margin-right:45px; 
}
.mg-rt-50 { 
    margin-right:50px; 
}
/******************margin right End********************/

/******************margin bottom start********************/
.mg-bt-0 { 
    margin-bottom:0px; 
}
.mg-bt-5 { 
    margin-bottom:5px; 
}
.mg-bt-8 { 
    margin-bottom:8px; 
}
.mg-bt-10 { 
    margin-bottom:10px; 
}
.mg-bt-12 { 
    margin-bottom:12px;
}
.mg-bt-15 { 
    margin-bottom:15px; 
}
.mg-bt-16 {
    margin-bottom:16px;
}
.mg-bt-20 { 
    margin-bottom:20px; 
}
.mg-bt-25 { 
    margin-bottom:25px; 
}
.mg-bt-30 { 
    margin-bottom:30px; 
}
.mg-bt-35 { 
    margin-bottom:35px; 
}
.mg-bt-40 { 
    margin-bottom:40px; 
}
.mg-bt-45 { 
    margin-bottom:45px; 
}
.mg-bt-50 { 
    margin-bottom:50px; 
}
/******************margin bottom End********************/

/******************margin left start********************/
.mg-lt-0 {
    margin-left:0px;
}
.mg-lt-5 {
    margin-left:5px;
}
.mg-lt-8 {
    margin-left:8px;
}
.mg-lt-9 {
    margin-left:9px;
}
.mg-lt-10 {
    margin-left:10px;
}
.mg-lt-15 {
    margin-left:15px;
}
.mg-lt-20 {
    margin-left:20px;
}
.mg-lt-25 {
    margin-left:25px;
}
.mg-lt-30 {
    margin-left:30px;
}
.mg-lt-35 {
    margin-left:35px;
}
.mg-lt-40 {
    margin-left:40px;
}
.mg-lt-45 {
    margin-left:45px;
}
.mg-lt-50 {
    margin-left:50px;
}
.mg-lt-55 {
    margin-left:55px;
}
.mg-lt-60 {
    margin-left:60px;
}
/******************margin left End********************/

/******************margin All site start********************/
.mg-auto {
    margin:0px auto;
}
.mg-0 { 
    margin:0;
}
.mg-5 { 
    margin:5px;
}
.mg-10 { 
    margin:10px;
}
.mg-20 { 
    margin:20px;
}
/******************margin All site End********************/

/******************margin Top Bottom start********************/
.mg-tp-bt-0 {
    margin-top:0;
    margin-bottom:0;
}
.mg-tp-bt-5 {
    margin-top:5px;
    margin-bottom:5px;
}
.mg-tp-bt-10 {
    margin-top:10px;
    margin-bottom:10px;
}
.mg-tp-bt-15 {
    margin-top:15px;
    margin-bottom:15px;
}
.mg-tp-bt-20 {
    margin-top:20px;
    margin-bottom:20px;
}
.mg-tp-bt-25 {
    margin-top:25px;
    margin-bottom:25px;
}
.mg-tp-bt-30 {
    margin-top:30px;
    margin-bottom:30px;
}
.mg-tp-bt-40 {
    margin-top:40px;
    margin-bottom:40px;
}
.mg-tp-bt-50 {
    margin-top:50px;
    margin-bottom:50px;
}
/******************margin Top Bottom End********************/

/******************margin Left right start********************/
.mg-lt-rt-5 {
    margin-left:5px;
    margin-right:5px;
}
.mg-lt-rt-10 {
    margin-left:10px;
    margin-right:10px;
}
.mg-lt-rt-20 {
    margin-left:20px;
    margin-right:20px;
}
.mg-lt-rt-30 {
    margin-left:30px;
    margin-right:30px;
}
.mg-lt-rt-40 {
    margin-left:40px;
    margin-right:40px;
}
.mg-lt-rt-50 {
    margin-left:50px;
    margin-right:50px;
}
/******************margin Left right End********************/

/******************font********************/

.ft-size-15 {
    font-size:15px;
}
.ft-size-14 {
    font-size:14px;
}
.ft-size-12 {
    font-size:12px;
}
.ft-size-10 {
    font-size:10px;
}

/****************************************reponsive*******************/
@include breakpointMax(1199){
    .mb-ads-mg-bt-15 {
        margin-bottom:15px; 
    }
}
@include breakpointMax(768){ 
    .mb-mg-tp-12 { 
        margin-top:12px; 
    }
    .mb-mg-bt-15 { 
        margin-bottom:15px; 
    }
}
@include breakpointMax(767){
    .mb-mg-tp-14 { 
        margin-top:14px; 
    }
    .mb-mg-tp-15 { 
        margin-top:15px; 
    }
    .mb-mg-tp-10 { 
        margin-top:10px; 
    }
    .mb-mg-bt-10 { 
        margin-bottom:10px; 
    }
    .mb-mg-bt-14 {
        margin-bottom:14px;
    }
}