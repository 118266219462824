@import '_mixins.scss';


.MuiTypography-root {
	&.banner-title {
		font-size:35px;
		@include breakpointMax(1024) {
			font-size:35px;
		}
		@include breakpointMax(767) {
			font-size:24px;
		}
		& span {
			display: block;
		}
	}
	&.landing-mid-title {
		font-size:35px;
		@include breakpointMax(1024) {
			font-size:35px;
		}
		@include breakpointMax(767) {
			font-size:24px;
		}
	}
	&.white-card-title {
		font-size:26px;
		@include breakpointMax(768) {
			font-size:23px;
		}
	}
	&.white-card-sub-title {
		font-size:21px;
		@include breakpointMax(768) {
			font-size:20px;
		}
	}
	&.banner-sub-content {
		font-size: 16px !important;
		display: block;
	}
	&.loading-title {
		font-size:20px;
	}
	&.terms-full-cnt {
		& h3 {
		  margin:24px 0 10px 0;
		}
		@include breakpointMax(768) {
			& h1 {
				margin:10px 0 18px 0;
				font-size:30px;
			  }
		}
	}
}
.MuiTypography-h2 {
	&.banner-sub-title {
		font-size: 26px;
		padding-top: 30px;
		padding-bottom: 40px;
		line-height: 40px;
		@include breakpointMax(1024) {
			font-size: 22px;
		}
		@include breakpointMax(767) {
			font-size: 19px;
			line-height: 30px;
		}
		@include breakpointMax(320) {
			font-size: 14px;
		}
		& span {
			display:block;
		}
	}
	&.white-widget-sub-title {
		font-size:23px;
		padding-top:0px;
		padding-bottom:0px;
		line-height:25px;
		margin-bottom: 12px;
		@include breakpointMax(1024) {
			font-size:20px;
		}
		@include breakpointMax(767) {
			font-size:19px;
			line-height:20px;
		}
		@include breakpointMax(320) {
			font-size:14px;
		}
		& span {
			display:block;
		}
	}
}
.MuiTypography-body2 {
	font-size:inherit !important;
	font-weight:inherit !important;
	line-height:inherit !important;
	letter-spacing:inherit !important;
}

.MuiButtonBase-root {
	&.theme-btn {
		padding: 10px 15px;
		display: inline-block;
		border-radius: 4px;
		font-size: 15px;
		font-weight: normal;
		&.filter-button {
			font-size: 17px;
			@include breakpointMax(767) {
				font-size: 14px;
			}
		}
		&.mg-lt-15 {
			margin-left: 15px;
		}
		&.mg-lt-10 {
			margin-left:10px;
		}
		&.mg-rt-15 {
			margin-left: 15px;
		}
		&.mg-bt-10 {
			margin-bottom: 10px;
		}
		&.mg-bt-5 {
			margin-bottom:5px;
		}
		&.min-width {
			min-width:110px;
		}
		&.min-width-80 {
			min-width:80px;
		}
		&.theme-white-btn {
			padding:9px 15px;
		}
		&.small-padding-btn {
			padding:4px 15px;
		}
		&.mg-tp-5 {
            margin-top:5px;
        }
	}
}



