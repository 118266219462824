@import '_mixins.scss';

.desktop-menu {
	padding-top: 70px;
	@include breakpointMax(991) {
		text-align: left;
		padding-top: 68px;
	}
	& .navbar-expand-lg {
		@include breakpointMax(991) {
			flex-flow: row nowrap;
    		justify-content: flex-start;
		}
		& .navbar-nav {
			& .dropdown {
				& .dropdown-menu {
					padding: 0px;
					max-height: 312px;
    				overflow: auto;
				}
			}
		}
	}
	& .top-menu-logo {
		& img {
			max-height: 60px;
			width: auto;
			max-width: 270px;
			@include breakpointMax(1200) {
				/* max-height: 28px; */
				max-height: 60px;
			}
			@include breakpointMax(1199) {
				/* max-height: 32px; */
				max-height: 60px;
				max-width: 180px;
			}
			@include breakpointMax(1023) {
				/* max-height: 27px; */
				max-height: 60px;
				max-width: 155px;
			}
			@include breakpointMax(991) {
				/* max-height: 36px; */
				max-height: 60px;
				display: table;
			}
			@include breakpointMax(576) {
				/* max-width:150px; */
			}
			@include breakpointMax(400) {
				/* max-width:140px; */
				/* max-height: 30px; */
			}
			@include breakpointMax(320) {
				/* max-width:130px; */
			}
		}
	}
	& .purl-agent {
			margin: 0 5px 0 15px;
			display: inline-block;
			vertical-align: middle;
			padding: 0px;
			@include breakpointMax(991) {
				margin: 0 5px 0 25px;
			}
			@include breakpointMax(420) {
				margin:0 0 0 15px;
			}
			@include breakpointMax(320) {
				margin:0 0 0 10px;
			}
		& .purl-photo {
			width: 40px;
			height: 40px;
			overflow: hidden;
			background: #ffffff;
			padding: 2px;
			border-radius: 100%;
			text-align: center;
			border: 1px solid #ddd;
			display: inline-block;
    		vertical-align: middle;
			@include breakpointMax(1000) {
				width: 35px;
    			height: 35px;
			}
			@include breakpointMax(991) {
				width: 38px;
				height: 38px;
			}
			& img {
				border-radius: 50%;
				object-fit: cover; 
    			min-height: 100%;
			}
		}
		& .nav-link {
			padding: 0 0px;
			@include breakpointMax(420) {
				padding: 0 10px 0 0px;
			}
		}
	}
	& .tour-question {
		display: inline-block;
		width: 18px;
		height: 18px;
		border-radius: 100%;
		text-align: center;
		padding: 1px;
		line-height: 15px;
		font-size:14px;
		@include breakpointMax(1200) {
			width: 16px;
			height: 16px;
			line-height: 13px;
			font-size: 13px;
		}
		@include breakpointMax(991) {
			display: none;
		}
	}
	& .menu-store-icon {
		font-size:18px;
	}
	& .navbar {
		padding: 0px 20px;
		min-height: 68px;
		z-index: 1001;
		@include breakpointMax(991) {
			/* display: inline-block; */
			min-height: 68px;
			
		}
		& .navbar-nav {
			display:inline-block;
			@include breakpointMax(991) {
				display: flex;
				width: 100%;
				text-align: left;
				padding: 30px 50px 30px 50px;
				height: 100%;
				overflow-y: scroll;
				overflow-x: hidden;
			}
			@include breakpointMax(576) {
				padding:30px 15px 30px 15px;
				overflow-y:scroll;
				height:600px;
			}
			& .nav-link {
				font-size:14px;	
				padding-right:9px;
				padding-left:9px;
				padding-top:26px;
				padding-bottom:25px;
				@include breakpointMax(1230) {
					padding-right:3px;
					padding-left:3px;
					font-size:13px;
				}
				@include breakpointMax(991) {
					padding-top:17px;
					padding-bottom:17px;
					width:100%;
					display:block;
					border-bottom:1px solid #ddd;
				}
				&:hover, &.active, &:focus {
					outline:none;
				}
			}
			& .navbar-signup-button {
				padding:8px 16px;
				/* border:1px solid; */
				font-size:14px;
				margin:16px 15px 16px 0;
				@include breakpointMax(1200) {
					margin: 16px 8px 16px 0;
					padding: 8px 12px;
				}
				@include breakpointMax(991) {
					margin: 0px 35px 0px 0;
				}
				&:hover, &.active, &:focus {
					/* color:#ffffff; */
					outline: none;
				}
			}
			& .navbar-login-button {
				padding: 9px 16px;
				font-size: 14px;
				margin: 16px 0px 16px 0;
				border-radius: 4px;
				@include breakpointMax(991) {
					margin: 0px 0px 0px 0;
				}
				&.dropdown {
					width: inherit;
					/* margin-top: 0; */
					padding: 7px 16px 6px;
					@include breakpointMax(991) {
						width: auto;
					}
					& .dropdown-toggle {
						&.btn {
							font-size: 14px;
						}
					}
					& .dropdown-menu {
						left: inherit;
						right: 0;
						width: 188px;
						min-width: inherit;
						top: calc(100% + 17px);
						@include breakpointMax(991) {
							position: absolute;
							width: 200px;
							top: calc(100% + 4px);
						}
					}
				}
				&.login-dropdown-button {
					padding: 0;
					& .dropdown-toggle {
						padding: 9px 10px;
						border-radius: 4px;
						line-height: normal;
						&:after {
							color: #ffffff;
						}
					}
				}
			}
			& .dropdown-menu {
				& .dropdown-item {
					padding:11px 12px;
					border-bottom:1px solid #f3f0f0;
					font-size: 13px;
					position:relative;
					& .chat-not-cnt {
						&.inline {
							right:13px;
							top:4px;
						}
						&.scinline {
							right:-12px;
							top:-3px;
						}
						&.mainline {
							right:-12px;
							top:-3px;
						}
					}
					&:last-child {
						border-bottom:none;
					}
					&.active,&:hover {
						background-color:#f5f5f5;
					}
					&.add-client {
						border-bottom:1px solid #f3f0f0;
						background-color: #f5f5f5;
						font-weight: bold;
						& .add-client-icon {
							display:inline-block;
    						vertical-align:top;
							width:20px;
						}
						& .add-client-name {
							width: calc(100% - 20px);
							display:inline-block;
							vertical-align:top;
							white-space:normal;
							word-break:break-word;
							padding-left:5px;
						}
					}
					&.selected-client {
						border-bottom:1px solid #f3f0f0;
						background-color: #f5f5f5;
						font-weight: bold;
						& .close-icon {
							float: right;
							margin-top: -2px;
							padding-left: 10px;
						}
					}
				}
				& .selected-client-wrapper {
					background-color: #f5f5f5;
					& .selected-client {
						display: inline-block;
						vertical-align: middle;
						width: calc(100% - 36px);
					}
					& .close-icon {
						display: inline-block;
						vertical-align: middle;
						padding: 0 0 6px 4px;
						cursor: pointer;
					}
				}
			}
			& .neighborhoods-list {
				& .dropdown-menu {
					@include breakpointMax(991) {
						position:absolute;
						min-width:250px;
						height:200px;
						overflow-x:auto;
					}
				}
			}
		}
		& .nav-links {
			padding: 0;
			@include breakpointMax(991) {
				width: 100%;				
			}
		}
		& .other-style-nav {
			& .nav-link {
			  font-size: 18px;
			  padding-top: 23px;
			  padding-bottom: 22px;
			  padding-left: 25px;
			  padding-right: 25px;
			  @include breakpointMax(1230) {
				padding-right: 15px;
				padding-left: 15px;
			  }
			  @include breakpointMax(999) {
				font-size: 17px;
			  }
			  @include breakpointMax(991) {
				font-size: 16px;
				padding-top: 17px;
				padding-bottom: 17px;
				padding-left: 3px;
				padding-right: 3px;
			  }
			}
		  }
	}
	& .navbar-toggler {
		@include breakpointMax(991) {
			float:none;
			border:none;
			outline:none;
			padding-left:0;
			padding-right:0;
			position:absolute;
			top:19px;
			padding:0px;
			right:15px;
			&.collapsed {
				& .navbar-toggler-icon {
					/* background-image:url(../img/nd/mobile-burger.png); */
					background-image: none;
					&::after {
						position: absolute;
						top: 6px;
						left: 3px;
						width: 24px;
						content: '';
						border-bottom: 2px solid;
						padding-bottom: 6px;
						border-top: 2px solid;
						transform:none;
						background: none;
					}
					&::before {
						position: absolute;
						bottom: 0px;
						left: 3px;
						width: 24px;
						content: '';
						padding-top: 6px;
						border-top: 2px solid;
						transform:none;
						background: none;
						height: auto;
    					top: inherit;
					}
				}
			}
			& .navbar-toggler-icon {
				background-image:url(../img/nd/close.png);
				z-index:1;
				position:relative;
				width: 30px;
    			height: 30px;
				&::after {
					position: absolute;
					top: 15px;
					left: 1px;
					width: 30px;
					content: '';
					transform: rotate(45deg);
					height: 2px;
				}
				&::before {
					position: absolute;
					top: 15px;
					left: 1px;
					width: 30px;
					content: '';
					transform: rotate(-45deg);
					height: 2px;
				}
			}
		}
	}
	& .navbar-collapse {
		@include breakpointMax(991) {
			text-align:left;
			width:0;
			margin:0;
			background: #fff;
			position:fixed;
			top: 0;
			left: -100%;
			transition: 0.5s;
			height: 100vh;
			&.show {
				margin: 0;
				width: 100%;
				height: 100vh;
				left: 0;
				top:0;
				transition: all .1s ease-out 0s;
			}
		}
	}
	& .navbar-brand {
		/* padding-top:7px;
		padding-bottom:7px; */
		padding-top: 0px;
		padding-bottom: 0px;
		margin: 0px;
		display: table-cell; 
    	vertical-align: middle;
		@include breakpointMax(768) {
			padding-top:0px;
			padding-bottom:0px;
		}
	}
	& .nav-item {
		display:inline-block;
		&.dropdown {
			width: inherit;
			border: none;
		}
	}
	& .nav-link {
		display:inline-block;
	}
	& .bg-light {
		background-color: #ffffff !important;
		box-shadow:0 2px 4px 0 rgba(0,0,0,0.1);
		/* & .center-logo {
			@include breakpointMax(991) {
				display:table-cell;
				height:68px;
				vertical-align:middle;
			}
		} */
	}
	& .mb-nav-header {
		display:none;
		@include breakpointMax(991) {
			display:inline-block;
			padding:0px;
			position:absolute;
			top:26px;
			right:60px;
		}
		@include breakpointMax(420) {
			right:50px;
		}
		& .nav-link {
			@include breakpointMax(991) {
				padding:0 10px;
				vertical-align: top;
			}
		}
		& .chat-button {
			background: none;
			padding: 0px;
			margin: 5px 20px 0 0;
			display: inline-block;
			vertical-align: middle;
			position: relative;
			@include breakpointMax(991) {
				margin:-4px 8px 0 8px;
			}
			@include breakpointMax(420) {
				margin:-4px 10px 0 6px;
			}
		}
	}
	& .desktop-chat {
		background: none;
		padding: 0px;
		margin: 5px 20px 0 0;
		display: inline-block;
		vertical-align: middle;
		position: relative;
		@include breakpointMax(991) {
			display:none;
		}
	}
	/***********Menu login Profile Right*********/
	& .login-quicklink {
		padding-left:10px;
		padding-top:0px;
		padding-bottom:0px;
		display:inherit;
		float:right;
		min-height:69px;
		margin:0 0 0 10px;
		@include breakpointMax(1200) {
			padding-left: 6px;
			margin: 0 0 0 6px;
		}
		@include breakpointMax(991) {
			padding-left:0px;
			text-align:center;
			float:none;
			width:100%;
			display:block;
			min-height:60px;
			border-bottom:1px solid #ddd;
			padding-bottom:20px;
			margin:0 0 0 0;
		}
		&.single-boder-lt {
			border-left:1px solid #ddd;
			@include breakpointMax(991) {
				border-left:none;
			}
		}
		& .btn-group {
			margin-bottom:0px;
		}
		& .btn-primary {
			background-color:inherit;
			border-color:inherit;
			padding:0px;
			box-shadow:inherit;
			&:active, &:focus, &.dropdown-toggle {
				background-color:inherit !important;
				border-color:inherit !important;
				box-shadow:inherit !important;
				border:none;
				flex:inherit;
			}
			&.show {
				& .dropdown-toggle {
					background-color:inherit !important;
					border-color:inherit !important;
					box-shadow:inherit !important;
				}
			}
		}
		& .dropdown-toggle {
			&:after {
				font-size:15px;
				color: #9B9B9B;
			}
		}
		/************ Profile ovel**********/
		& .profile-image  {
			padding: 15px 0px 15px 0px;
			@include breakpointMax(1200) {
				padding: 18px 0px 17px 0px;
			}
			@include breakpointMax(991) {
				padding-bottom:5px;
				padding-top:0px;
			}
			&.dropdown {
				border: none;
				width: inherit;
				@include breakpointMax(991) {
					width: auto;
				}
			}
			& .profile-oval {
				display:inline-block;
				vertical-align:middle;
				border:1px solid #D8D8D8;
				padding:2px;
				height:38px;
				width:38px;
				border-radius:50%;
				text-align:center;
				font-size:13px;
				overflow: hidden;
				@include breakpointMax(1200) {
					height:33px;
					width:33px;
				}
				@include breakpointMax(991) {
					height:38px;
					width:38px;
				}
				& img {
					border-radius:50%;
					width:100%;
					/* height:33px; */
					display:inline-block;
					vertical-align:top;
				}
				&.profile-name {
					background:#D8D8D8;
					line-height:33px;
					@include breakpointMax(1200) {
						line-height:28px;
					}
					@include breakpointMax(991) {
						line-height:33px;
					}
				}
			}
			& .dropdown-menu {
				left:inherit;
				right:0;
				width:250px;
				min-width:inherit;
				padding:0px;
				@include breakpointMax(991) {
					position:absolute;
					left:0;
					right:inherit;
					width:240px;
					padding-top:0px;
					padding-bottom:0px;
				}
				@include breakpointMax(576) {
					left:inherit;
					right:0;
				}
			}
			& .agent-full-name {
				width:100%;
				float:left;
				padding:10px;
				font-size:13px;
				text-align:center;
				cursor:default;
				border-bottom:1px solid #ddd;
				line-height:normal;
				word-break:break-all;
			}
			/************Add a client**********/
			&.client-list {
				margin-right: 13px;
				@include breakpointMax(1200) {
					margin-right: 10px;
				}
				@include breakpointMax(991) {
					margin-right:35px;
				}
				@include breakpointMax(768) {
					margin-right:65px;
				}
				@include breakpointMax(375) {
					margin-right:110px;
				}
				& .dropdown-menu {
					width:250px;
					min-width:inherit;
					padding:0px;
					overflow-x: hidden;
					@include breakpointMax(991) {
						width:260px;
						min-width:inherit;
						left:inherit;
						right:0;
					}
					@include breakpointMax(576) {
						left:0;
						right:inherit;
					}					
					& .dropdown-item {
						& .material-icons {
							vertical-align:middle;
							font-size:20px;
						}
						& .client-name {
							width: calc(100% - 36px);
							display: inline-block;
							vertical-align:top;
							white-space:normal;
							word-break:break-word;
							padding-right:10px;
						}
						& .client-chart-icon {
							display:inline-block;
							vertical-align:top;
						}
					}
				}
			}
			/************Add a client**********/
			&.my-agent {
				margin-right: 13px;
				@include breakpointMax(1200) {
					margin-right: 10px;
				}
				@include breakpointMax(991) {
					margin-right:35px;
				}
				@include breakpointMax(768) {
					margin-right:65px;
				}
				@include breakpointMax(375) {
					margin-right:110px;
					&.my-adv {
						margin-right:36px;
					}
				}
				& .dropdown-menu {
					@include breakpointMax(991) {
						left:inherit;
						right:0;
					}
					@include breakpointMax(576) {
						left:0;
						right:inherit;
					}
				}
			}
		}
	}
	&.extra-nav-link {
		& .navbar-nav {
			& .nav-links {
				& .nav-link {
					@include breakpointMax(1300) {
						padding-right: 4px;
						padding-left: 4px;
					}
					@include breakpointMax(1200) {
						font-size: 12px;
					}
					@include breakpointMax(1100) {
						padding-right: 3px;
						padding-left: 3px;
						font-size: 12px;
					}
					@include breakpointMax(991) {
						font-size: 13px;
					}
				}	
			}
			& .navbar-signup-button {
				@include breakpointMax(1100) {
					padding: 8px 10px;
				}
				@include breakpointMax(991) {
					padding: 8px 12px;
				}
			}
		}
		& .desktop-chat {
			@include breakpointMax(1100) {
				margin: 5px 12px 0 0;
				& .material-icons {
					font-size: 20px;
				}
				& .notification-number {
					width: 18px;
					height: 18px;
					font-size: 10px;
					line-height: 18px;
					top: -11px;
					left: 9px;
				}
			}
		}
		& .top-menu-logo {
			& img {
				@include breakpointMax(1100) {
					max-height: 50px;
					max-width: 120px;
				}
				@include breakpointMax(991) {
					max-height: 60px;
					max-width: 155px;
				}
			}
		}
		& .login-quicklink {
			& .profile-image {
				&.client-list {
					@include breakpointMax(1100) {
						margin-right: 8px;
					}
					@include breakpointMax(991) {
						margin-right: 35px;
					}
					@include breakpointMax(768) {
						margin-right: 65px;
					}
				}
			}
		}
		& .purl-agent {
			& .purl-photo {
				@include breakpointMax(1100) {
					width: 28px;
					height: 28px;
				}
				@include breakpointMax(991) {
					width: 38px;
					height: 38px;
				}
			}
		}
	}
}

/**********Quick Bar Start**********/
.quick-blackbg {
	position: fixed;
    z-index:9999;
    top: 0;
    right: 0;
    width: 100%;
	background: rgba(0, 0, 0, 0.5);
	height:100vh;
	display:none;
}

.quick-bar {
	position:fixed;
    top:0;
    right:-320px;
    width:100% !important;
    height:100%;
    z-index:99999;
    transform:translateX(100%);
	box-shadow:0 0 4px #ddd;
	text-align:right;
	transition:transform .25s ease-out;
	display:none;
	&.open {
		transform:translateX(0);
		right:0px;
		display:block;
		+ {
			& .quick-blackbg {
				display:block;
			}	
		}
	}
	& .quick-bar-slider {
		width:320px;
		display:inline-block;
		background:#fff;
		text-align:left;
		vertical-align:top;
		@include breakpointMax(576) {
			width:100%;
		}
	}
	& .quick-bar-close {
		width:100%;
		display:block;
		height:68px;
		padding:5px 0 5px 15px;
		line-height:50px;
		border-bottom: 1px solid #ddd;
		& .material-icons {
			display:inline-block;
			vertical-align: middle;
			font-size:22px;
    		padding-right:10px;
			cursor: pointer;
		}
	}
	& .activity-container {
		padding:25px 25px 0 30px;
		overflow: auto;
		width:100%;
    	display:block;
		& .activity-list {
			margin:0;
			padding:0px;
			position: relative;
			&:after {
				position: absolute;
				top:-5px;
				bottom:0;
				left:0;
				width:2px;
				margin-left:-1px;
				content: "";
				background-color: #e4eaec;
			}
			& li {
				padding-left:30px;
				width:100%;
				float:none;
				display:block;
				position:relative;
    			margin-bottom: 22px;
			}
			& .activity-icon {
				position: absolute;
				width:30px;
				height:30px;
				top:0;
				left:-15px;
				line-height:30px;
				right:0;
				z-index:11;
				font-size:18px;
				color: #fff;
				text-align:center;
				cursor:pointer;
				border-radius:50%;
				&.bg-comment {
					background: #46be8a;
				}
				&.bg-favorite {
					background:#57c7d4;
				}
			}
			& .activity-content {
				float:none;
				width:100%;
				overflow:hidden;
				color: #84909a;
				font-size:14px;
				cursor:pointer;
				& .activity-time {
					font-size:12px;
					width:100%;
					float:left;
					padding-bottom:16px;
					line-height:normal;
				}
				& .activity-profile {
					float:left;
					border:1px solid #D8D8D8;
					padding:2px;
					height:35px;
					width:35px;
					border-radius:50%;
					overflow: hidden;
					& img {
						border-radius:50%;
						height: 100%;
    					object-fit: cover;
					}
					&.activity-profile-name {
						background:#D8D8D8;
						color: #033258;
						font-size:13px;
						line-height:30px;
						text-align:center;
					}
				}
				& .activity-name {
					width:calc(100% - 35px);
					float:none;
					display:block;
					overflow:hidden;
					padding-left:10px;
					line-height:20px;
				}
			}
		}
	}
	& h2 {
			font-size:24px;
			display:inline-block;
			vertical-align:middle;
		@include breakpointMax(768) {
			font-size:20px;
		}
	}
}
/**********Quick Bar End**********/

/************Chat Button**********/
.chat-button {
	background: none;
	padding: 0px;
	margin: 5px 5px 0 0;
	display: inline-block;
	vertical-align: middle;
	position: relative;
	border: none !important;
	outline: none !important;
	@include breakpointMax(1200) {
		margin: 10px 15px 0 0;
	}
	&:hover, &:active, &:focus{
		background:none;
	}
	& .notification-number {
		width:20px;
		height:20px;
		border-radius:100%;
		line-height:20px;
		position: absolute;
		/* top:-8px;
    	left:10px; */
		top:-8px;
    	left: 10px;
		font-size: 11px;
	}
}

.main-fixed {
	& .desktop-menu {
		& .bg-light {
			box-shadow:none;
		}
	}
}

.chat-not-cnt {
	width:20px;
	height:20px;
	border-radius:100%;
	line-height:20px;
	position: absolute;
	top:-5px;
	right: 8px;
	font-size: 11px;
	text-align: center;
	&.inline {
		right: -12px;
	}
}