.mg-top-0 {margin-top:0px; }
.mg-btm-0 {margin-bottom:0px;}
.mg-top-10 { margin-top: 10px; }
.mg-top-3 { margin-top:3px; }
.mg-top-4 { margin-top:4px; }
.mg-top-5 { margin-top:5px; }
.mg-top-6 { margin-top:6px; }
.mg-top-8 { margin-top:8px; }
.mg-top-12 { margin-top: 12px; }
.mg-top-15 { margin-top: 15px; }
.mg-top-20 { margin-top: 20px; }
.mg-top-25 { margin-top: 25px; }
.mg-top-30 { margin-top: 30px; }
.mg-top-35 { margin-top: 35px; }
.mg-top-40 { margin-top: 40px; }
.mg-top-50 { margin-top: 50px; }
.mg-btm-5 { margin-bottom: 5px; }
.mg-btm-6 { margin-bottom:6px; }
.mg-btm-8 { margin-bottom:8px; }
.mg-btm-10 { margin-bottom: 10px; }
.mg-btm-12 { margin-bottom: 12px; }
.mg-btm-15 { margin-bottom: 15px; }
.mg-btm-20 { margin-bottom: 20px; }
.mg-btm-25 { margin-bottom: 25px; }
.mg-btm-35 { margin-bottom: 35px; }
.mg-btm-40 { margin-bottom: 40px; }
.mg-topbtm-5 {margin:5px 0;}
.mg-topbtm-0 {margin:0 0;}
.mg-topbtm-10 {margin:10px 0;}
.mg-topbtm-15 {margin:15px 0;}
.mg-topbtm-20 { margin: 20px 0; }
.mg-topbtm-30 { margin: 30px 0; }
.mg-topbtm-40 { margin: 40px 0; }
.mg-ltrt-7 {margin:0 7px;}
.mg-ltrt-5 {margin:0 5px;}
.mg-ltrt-10 {margin:0 10px;}
.mg-lt-5 {margin-left:5px;}
.mg-lt-10 {margin-left:10px;}
.mg-lt-9 {margin-left:9px;}
.mg-lt-8 {margin-left:8px;}
.mg-lt-15 {margin-left:15px;}
.mg-rt-15 {margin-right:15px;}
.mg-rt-5 { margin-right:5px; }
.mg-rt-2 { margin-right:2px;}
.mg-rt-1 {margin-right:1px;}
.mg-rt-10 { margin-right: 10px; }
.mg-rt-20 { margin-right: 20px; }
.mg-all-10 { margin: 10px; }
.mg-all-20 { margin: 20px; }
.mg-lt-33 { margin-left:33px;}
.mg-rt-33 { margin-right:33px;}
.full-width { width: 100%; }
.row.no-gutters {
    // margin-right: 0;
    // margin-left: 0;
    > [class*=" col-"], > [class^="col-"], {
        padding-right: 0;
        padding-left: 0;
    }
}

@include breakpointMax(1200){
    .mb-text-ct {text-align:center;}
}
@include breakpointMax(800){
    .text-ct-800 {text-align:center;}
    .mb-pd-lt-rt-0 { 
        padding-left:0px;
        padding-right:0px;
    }
}
@include breakpointMax(1080){
    .mb-text-rt-1080 {text-align:right;}
}
@include breakpointMax(1000){
    .mb-text-lt-1000 {text-align:left;}
}
@include breakpointMax(768){
  .mb-mg-top-12 { margin-top: 12px; }
  .mb-mg-btm-15 { margin-bottom:15px; }
  .mb-mg-top-5 { margin-top:5px; }
}
@include breakpointMax(767){
    .mb-mg-top-15 { margin-top:15px; }
    .mb-mg-top-10 { margin-top:10px; }
    .mb-mg-btm-10 { margin-bottom:10px; }
    .mb-mg-rt-5 { margin-right:5px;}
    .mb-pd-lft-15 { padding-left:15px !important;}
    .mb-pd-btm-8 { padding-bottom:8px;}
    .mb-pd-btm-10 { padding-bottom:10px;}
    .mb-mg-btm-14 {margin-bottom:14px;}
    .mb-mg-top-0 { margin-top:0px; }
    .mb-mg-tp-bt-6{margin:6px 0 6px 0;}
    .mb-pd-top-0 { padding-top:0px !important; }
    .mb-pd-topbtm-15 {padding:15px 0 !important; }
    .text-ct-767 {text-align:center;}
    .mb-pd-lt-0 { padding-left:0px;}
  }
.pd-lft-0 { padding-left:0px; }
.pd-lft-5 { padding-left: 5px; }
.pd-lft-10 { padding-left: 10px; }
.pd-lft-15 { padding-left: 15px; }
.pd-lft-20 { padding-left: 20px; }
.pd-top-20 {padding-top:20px;}
.pd-top-25 {padding-top:25px;}
.pd-top-30 {padding-top:30px;}
.pd-bot-25 {padding-bottom:25px;}
.pd-bot-30 {padding-bottom:30px;}
.pd-bot-50 {padding-bottom:70px;}
.pd-bot-70 {padding-bottom:70px;}
.pd-top-50 {padding-top:50px;}
.pd-top-0 {padding-top:0;}
.pd-bot-0 {padding-bottom:0;}
.pd-rt-0 {padding-right:0;}
.pd-rt-10 {padding-right:10px;}
.pd-rt-15 {padding-right:15px;}
.pd-rt-18 {padding-right:18px;}
.pd-rt-19 {padding-right:19px;}
.pd-rt-20 {padding-right:20px;}
.pd-rt-55 {padding-right:55px;}
.pd-top-1 {padding-top:1px;}
.pd-top-2 {padding-top:2px;}
.pd-top-5 {padding-top:5px;}
.pd-bot-5 {padding-bottom:5px;}
.pd-top-6 {padding-top:6px;}
.pd-bot-6 {padding-bottom:6px;}
.pd-top-7 {padding-top:7px;}
.pd-bot-7 {padding-bottom:7px;}
.pd-top-10 {padding-top:10px;}
.pd-bot-10 {padding-bottom:10px;}
.pd-top-15 {padding-top:15px;}
.pd-bot-15 {padding-bottom:15px;}
.pd-bot-20 {padding-bottom:20px;}
.pd-lft-23 { padding-left: 23px; }
.pd-lft-35 { padding-left:35px;}
.pd-topbtm-10 {padding:10px 0;}
.pd-topbtm-13 {padding:13px 0;}
.pd-topbtm-15 {padding:15px 0;}
.pd-topbtm-20 {padding:20px 0;}
.pd-topbtm-30 {padding:30px 0;}
.pd-btm-30 { padding-bottom:30px; }
.pd-sides-10 {
    padding-left: 10px;
    padding-right: 10px;
}
.pd-sides-5 {
    padding-left: 5px;
    padding-right: 5px;
}
.pd-ltrt-0 {
    padding-left:0px;
    padding-right:0px;  
}
.pd-sides-15 {
    padding-left:15px;
    padding-right:15px;
}
.pd-sides-20 {
    padding-left:20px;
    padding-right:20px;
}
.pd-sides-50 {
    padding-left:50px;
    padding-right:50px;
}
.pd-none { padding: 0; }
.pd-all-10 { padding: 10px; }
.pd-all-5 { padding: 5px; }
.pd-all-20 { padding: 20px; }
.flt-right { float: right; }
.width_auto{ width:auto; }
.flt-left { float: left; }
.display-bk { display:block;}
.clear_left { clear: left;}
.display-ib { display: inline-block; }
.display-none { display:none;}
.ver-align-top { vertical-align:top }
.ver-align-mid { vertical-align:middle; }
.line-break {
	height: 3px;
	width: 100%;
	transform: scaleY(-1);
	background-color: #E4E4E4;
}

/*.App {
    background: linear-gradient(180deg, rgba(255,255,255,1) 90%, rgba(83,223,247,1) 100%);
}*/

.copyright, .footer-content { 
    margin-bottom:5px;
    
    a + a { margin-left: 10px; }
}
//.footer { margin-top: 100px; }
.mx-auto {margin:0 auto;}
.position-relative {position:relative;}
.float-none { float:none;}
.error_meg {
    width:100%;
    line-height:normal;
    margin-bottom:15px;
    background: #fbbcbc;
    float:left;
    color: #d53837;
    padding:5px 5px;
    border-radius:3px;
    text-align:center;
    font-size:14px;    
}

@media only screen and (max-width:823px){
.mb-pd-top-15 {padding-top:15px;}    
.mb-pd-bot-15 {padding-bottom:15px;}    
.mb-pd-bot-30 {padding-bottom:30px;}
}
@media only screen and (max-width:480px){
.mp-mg-btm-15 { margin-bottom:10px; }  
.mp-display-ib {display:inline-block;}  
.mp-pd-lft-5 {padding-left:5px; }   
}
.border-left-gray {
    border-left: 1px solid #e4e4e4;
    @media only screen and (max-width:767px){
        border-left:none;
    }
}
.border-right-gray {
    border-right: 1px solid #e4e4e4;
}

.propdetail_sidebar_container {
    @include breakpointMin(1096) {
        padding-left:35px;
    }
}
.remove-radius{border-radius:inherit;}
.cursor-pointer {cursor: pointer;}
.border-bottom-gray {
    border-bottom: 1px solid #e4e4e4;
}
.border-top-gray {
    border-bottom: 1px solid #e4e4e4;
}
.form-control-text {
    font-size: 13px;
    font-weight: normal;
    margin-bottom: 5px;
}
.font-bold {
    font-weight: bold !important;
}
.max-h215 {
    max-height:215px; 
}
.link-compact-text {
    display:inline-block;
    color: #fff;
    font-size:13px;
    padding-left:10px;
    text-shadow:1px 1px 1px #000;
    cursor:pointer;
    @include breakpointMax(1100) {
        margin-top:5px;
    }
    &.inner-link-compact {
        padding-left:15px; 
        @include breakpointMax(1023) {
            /* color:#fff; */
            padding-left:7px;
            text-shadow:1px 1px 1px #000;
        }
        @include breakpointMax(767) {
            padding-left:15px;
        }
        /* &:hover {
            @include breakpointMax(767) {
                color:#fff; 
            }
        } */
        
    }
    @include breakpointMax(1023) {
        /* color:#000;
        padding-left:0; 
        text-shadow:inherit; 
        margin-top:10px; */ 
    }
    @include breakpointMax(768) {
        padding-left:0px;
    }
    @include breakpointMax(767) {
        padding-left:15px;
    }
    &:hover {
        color:#fff; 
        text-decoration:underline;
        @include breakpointMax(767) {
            color:#000; 
        }
    }    
}
.z-index-3 { z-index: 3 !important;}
.z-index-4 { z-index: 4 !important;}
.z-index-5 { z-index: 5 !important;}
.z-index-10 { z-index: 10 !important;}