@import '_mixins.scss';

/*********Find agent header start**********/
.find-agent-header {
	width:100%;
	display:block;
	padding-top:110px;
	@include breakpointMax(1025) {
		padding-top:50px;
	}
}
/*********Find agent header start**********/

/*********************Agent/office Tap start*************/
.find-agent-filter {
	width:100%;
	margin: 0px;
	display:block;
	&.custom-container {
		width: 100%;
		padding: 15px 20px 50px 20px;
		@include breakpointMax(1366) {
			padding: 15px 15px 50px 15px;
		}
 	}
	& .tab-content {
		padding:26px 0;
	}
	& .nav-tabs {
		border-bottom:none;
		& .nav-link {
			font-size:16px;
			line-height:normal;
			outline:none;
			border-top: none;
			border-bottom:none;
			border-left:none;
			border-right:none;
			padding:8px 0px;
			margin-right:16px;
			&.active {
				font-size:16px;
				line-height:normal;
				background:none;
				padding:8px 0;
			}
		}
	}
	& .find-filter-input {
		border-radius:5px;
		min-height:55px;
		padding:15px;
		font-size:16px;
		width:100%;
		display:inline-block;
		vertical-align:top;
		box-shadow:0 4px 7px 3px rgba(0,0,0,0.07);
		@include breakpointMax(991) {
			padding:10px;
		}
		@include breakpointMax(767) { 
			margin-bottom:20px;
			padding:15px;
		}
		
	}
	& .theme-btn {
		&.result-button {
			min-height:50px;
			width:auto;
			margin-top: 2px;
			min-width: 120px;
		}
	}
	
}
/*********************Agent/office Tap End*************/
.realtor-agent-list {
	&.find-agent-result {
		&.custom-container {
			width: 100%;
			padding: 0 20px 40px 20px;
			@include breakpointMax(1366) {
				padding: 0 15px 40px 15px;
			}
		}
		& .accordion-listing {
			& .card-header {
				& .header-name {
					padding:20px 20px;
					&:after {
						top:30px;
					}
					&.open {
						&:before {
							top:30px;
						}
					}
					& .office-name {
						font-size:14px;
						@include breakpointMax(1190) {
							font-size:13px;
						}
						@include breakpointMax(767) {
							font-size:15px;
							line-height:20px;
						}
					}
					& .office-address {
						font-size:14px;
						@include breakpointMax(1190) {
							font-size:13px;
							line-height:17px;
						}
						@include breakpointMax(767) {
							padding-top:5px;
							padding-bottom:5px;
						}
					}
					& .office-phone {
						padding-right:26px;
						line-height:16px;
						font-size: 14px;
						& a {
							font-size:14px;
							@include breakpointMax(1190) {
								font-size:13px;
							}
						}
						& i {
							font-size:13px;
							vertical-align:middle;
							margin-right:5px;
						}
					}
				}
			}
			&.Office-results {
				& .card {
					box-shadow: inherit;
				}
				& .card-body {
					padding: 0px 40px 5px 40px;
					@include breakpointMax(1199) {
						padding: 0px 20px 5px 20px;
					}
				}
				& .card-header {
					& .header-name {
						padding: 30px 40px 30px 40px;
						@include breakpointMax(1199) {
							padding: 30px 20px 30px 20px;
						}						
					}
					& .offagent-loader {
						position: absolute;
						width: 100%;
						text-align: center;
						height: 100%;
						top: 0;
						left: 0;
						z-index: 1;
						padding: 30px 40px;
						background: rgba(0,0,0,0.5);
						color: #fff;
					}
				}
			}
			& .result-mg {
				margin-bottom:30px;
				padding:0px 17px;
				margin-top:0px;
				@include breakpointMax(1190) {
					margin-bottom:25px;
				}
			}
		}
		& h3 {
			color:#657388;
			font-size:20px;
			line-height:normal;
			padding-bottom:8px;
			
			@include breakpointMax(575) {
				font-size:18px;
			}
		}
		& .container {
			max-width: 100%;
			& .agent-results-text {
				padding-left: 5px;
				color:#BEC4CB;
				font-size:14px;
				line-height:20px;
				@include breakpointMax(575) {
					font-size:13px;
				}
				@include breakpointMax(1023) {
					padding-left: 0px;
				}
			}
		}
		& .agent-dt-box {
			box-shadow: 0 4px 7px 3px rgba(0,0,0,0.07);
			width:100%;
			float:left;
			/* height:121px; */
			min-height:130px;
			border-radius:8px;
			background:#ffffff;
			@include breakpointMax(1023) {
				min-height:inherit;
				text-align:left;
				padding:10px 15px;
			}
			& .agent-dt-left {
				width:140px;
				height:140px;
				float:left;
				overflow:hidden;
				border-radius:8px 0 0 8px;
				background: #f7f7f7;
				& img {
					border-radius:8px 0 0 8px;
					min-height: 100%;
					object-fit: cover;
					width: auto;
					max-width: 100%;
					@include breakpointMax(1199) {
						border-radius:8px 0 0 8px;
					}
					@include breakpointMax(1023) {
						border-radius:100%;
						width:100%;
						height:100%;
						padding:2px;
					}
				}
				@include breakpointMax(1270) {
					width:135px;
					height:150px;
				}
				@include breakpointMax(1023) {
					width:88px;
					height:88px;
					border-radius:100%;
					float:left;
					display: inline-block;
					border:1px solid #dddddd;
				}
				@include breakpointMax(680) {
					width:70px;
					height:70px;
				}
			}
			& .agent-dt-right {
				width:calc(100% - 150px);
				float:left;
				min-height:121px;
				padding:10px 10px 6px 15px;
				@include breakpointMax(1270) {
					width:calc(100% - 135px);
					padding:10px 7px 6px 7px;
				}
				@include breakpointMax(1199) {
					padding:10px 15px 6px 15px;
				}
				@include breakpointMax(1023) {
					width: auto;
					display: block;
					overflow: hidden;
					float: none;
					min-height: inherit;
					padding:4px 15px 0px 15px;
				}
				@include breakpointMax(350) {
					width:calc(100% - 70px);
				}
				& a {
					font-size:11px;
				}
				& .agent-company-name {
					font-size:11px;
					display:block;
					width:100%;
					line-height:normal;
					@include breakpointMax(1023) {
						max-height:inherit;
						font-size:13px;
					}
				}
				& .agent-name {
					font-size:18px;
					display:block;
					width:100%;
					padding-top:5px;
					padding-bottom:10px;
					max-height:inherit;
					/* height:27px;
					overflow: hidden;
					text-overflow:ellipsis;
					white-space:nowrap; */
					line-height:normal;
					& a {
							font-size:18px;
							@include breakpointMax(1366) {
								font-size: 16px;
							}
						}
					@include breakpointMax(1023) {
						max-height:inherit;
					}
				}
				& .agent-phone {
					display:block;
					width:100%;
					font-size:10px;
					padding-bottom:2px;
					& .number-box {
						width:auto;
						display:inline-block;
						@include breakpointMax(1270) {
							padding-left:0px;	
						}
						&:first-child {
							padding-right:5px;
						}
						@include breakpointMax(780) {
							padding-bottom: 3px;
						}
						@include breakpointMax(767) {
							&:first-child {
								padding-right:5px;
							}
						}
						@include breakpointMax(575) {
							width:100%;
							display:block;
							padding-right:0px;
							padding-bottom: 8px;
						}
					}
				}
				& .agent-desig {
					display:block;
					width:100%;
					font-size:10px;
					padding-bottom:10px;
					& .number-box {
						width:auto;
						display:inline-block;
					}
				}
				& .agent-website {
					font-size:11px;
					display:block;
					width:100%;
					padding-top:2px;
					& span {
						display:inline-block;
						vertical-align:middle;
						word-break:break-all;
						line-height:normal;
						width:calc(100% - 20px);
					}
				}
				& i {
					vertical-align: middle;
					font-size:12px;
					padding-right:4px;
					@include breakpointMax(1000) {
						vertical-align: top;
						padding-top: 2px;
					}
				}
				& .message-agent {
					display:block;
					width:100%;
					margin-top:12px;
				}
			}
		}
		& .result-mg {
			margin-top:24px;
			padding:0 15px ;
		}
		& .office-pagination {
			/* margin-top: 50px; */
			text-align: right;
			& .pagination {
				& .active {
					& .pagination-link {
						font-weight: bold;
					}
				}
				& .pagination-link {
					cursor: pointer;
					padding: 0px 8px;
					background-color: inherit !important;
					&:hover {
						background-color: inherit !important;
						font-weight: bold; 
					}
				}
			}
		}
	}
}


.find-office-result {
	& .container {
		@include breakpointMax(767) {
			padding:0 15px;
		}
		& .result-mg {
			margin-top: 0px;
		}
	}
}
/*********************pagination start*************/
.pagination-agent{
	text-align:right ;
	@include breakpointMax(560) {
		text-align:center;
	}
	& .pagination {
		text-align:right;
		background:none;
		font-size:12px;
		@include breakpointMax(560) {
			margin-top:15px;
		}
		& li {
			& a {
				background:none;
			}
		}
	}
}
/*********************pagination End*************/
.office-send-modal {
	& .office-send-message {
		& label {
			font-size:14px;
			margin-top: 20px;
			display: block;
			margin-bottom: 20px;
			@include breakpointMax(1199) {
				margin-top: 20px;
				margin-bottom: 8px;
			}
			@include breakpointMax(767) {
				margin-top: 15px;
				margin-bottom: 4px;
			}
		}
		& .office-info {
			display: block;
			font-size: 14px;
			word-break: break-word;
			&.office-social {
				& .social-icon {
					display: inline-block;
					margin-left: 22px;
					&:first-child {
						margin-left: 0px;
					}
					& .svg-inline--fa {
						font-size: 15px;
					}
				}
			}
		}
		& .office-field-input {
			border-radius:5px;
			min-height:55px;
			padding:15px;
			font-size:16px;
			width:100%;
			display:block;
			margin-bottom:20px;
			box-shadow:0 4px 7px 3px rgba(0,0,0,0.07);
		}
	}
} 