@import '_variables.scss';

.blue_gd_bg {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
    left: 0;
    transform: scaleY(-1);
	background: linear-gradient(180deg, rgba(146,223,209,0) 0%, #53DFF7 100%);
}
.large-heading h2  {
	line-height:40px;
	margin: 0px;
	font-size:33px;
	@media only screen and (max-width: 1024px){
		font-size:27px;
		line-height:36px;
	}
	@media only screen and (max-width: 768px){
		font-size:26px;
		line-height:36px;
	}
	@media only screen and (max-width: 480px){
		font-size:19px;
		line-height:30px;
	}
}
.large-heading h2 span {
	display:block;
}
.login_footer {
	background:#4A4A4A;
	color:#fff;
	padding-top:20px;
	padding-bottom:20px;
	font-size:10px;
	position:fixed;
	bottom:0px;
	left:0px;
	text-align:center;
	width:100%; 

	@media only screen and (max-device-width:823px) and (orientation: landscape) and (min-device-aspect-ratio: 1/1){
		position:relative;
	} 
	@media only screen and (max-width: 480px){
		padding-top:10px;
		padding-bottom:10px;
	}         
}
.logoheader{
	margin-top:-47px;

	@media only screen and (max-width: 480px){
		/* .navbar-brand  img	{
			margin:6px 0 !important;
			height:auto;
			width:130px; 
		} */
	}
}

/******************** custom checkbox start**************************/
$checkbox-background: #fff;
$checkbox-background-hover: #29b662;

/* Hide the browser's default checkbox */
/* Create a custom checkbox */
/* On mouse-over, add a grey background color */
/* When the checkbox is checked, add a blue background */
/* Create the checkmark/indicator (hidden when not checked) */
/* Show the checkmark when checked */
/* Style the checkmark/indicator */
.checkbox-style {
	display: block;
	position: relative;
    padding-left:48px;
    font-weight: normal;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
    user-select: none;
    font-family: FilsonProLight;
    margin-bottom:0px;  
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		&:checked {
			&~.checkmark {
				background-color: $checkbox-background-hover;
				&:after {
					display: block;
				}
			}
		}
    }
    @media only screen and (max-width: 768px){
        font-size:16px;
        padding-left:36px;   
    }
    @media only screen and (max-width:480px){
        font-size:13px;
        padding-left:35px;   
    }
	.checkmark {
		&:after {
			left:12px;
			top:3px;
			width:10px;
			height:20px;
			border: solid white;
			border-width: 0 3px 3px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
            transform: rotate(45deg);
            @media only screen and (max-width: 768px){
                width:8px;
                height:16px;
                top:5px;
            }
            @media only screen and (max-width: 480px){
                top:3px;
                left:10px;
            }
		}
    }
    
}
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height:35px;
    width:35px;
    border:1px solid #B2B2B2; 
    border-radius:10px;
	background-color: $checkbox-background;
	&:after {
		content: "";
		position: absolute;
		display: none;
    }
    @media only screen and (max-width: 768px){
        height:32px;
        width:32px; 
    }
    @media only screen and (max-width: 480px){
        height:28px;
        width:28px; 
        top: -5px;
    }
}
/******************** custom checkbox End**************************/
