.loader-container {
    margin-bottom: 10px;
    text-align: center;
    margin-top: 10px;

	& .loader {
		border: 5px solid #f3f3f3;
		border-top: 5px solid #555;
		border-radius: 50%;
		width: 30px;
		height: 30px;
		animation: spin 1s linear infinite;
		display: inline-block;
		vertical-align: middle;
	}
	& .loader-text {
		font-size:1.1em;
		line-height: 30px;
		margin-left: 10px;
		display: inline-block;
		vertical-align: middle;
	}
}

.pager-loader-container {
    position: absolute;
    top: 0;
    width: 370px;
    text-align: center;
    vertical-align: middle;
    height: 35px;
    padding-top: 5px;
    right: 0;
    background-color: rgba(230, 230, 230, 0.5);
    z-index: 9;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}