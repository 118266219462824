.contactus-send-message {
  width: 100%;
  display: block;
  /* margin-top: 30px; */
  /* @include breakpointMax(1367) {
		padding: 0 15px;
	} */ }
  .contactus-send-message .contactus-small-label {
    font-size: 14px;
    display: block;
    margin-top: 20px;
    margin-bottom: 15px; }
    @media only screen and (max-width: 1199px) {
      .contactus-send-message .contactus-small-label {
        margin-top: 20px;
        margin-bottom: 8px; } }
    @media only screen and (max-width: 767px) {
      .contactus-send-message .contactus-small-label {
        margin-top: 15px;
        margin-bottom: 4px; } }
  .contactus-send-message .office-info {
    display: block;
    font-size: 14px;
    word-break: break-word; }
    .contactus-send-message .office-info.contactus-social .contactus-social-icon {
      display: inline-block;
      margin-left: 22px; }
      .contactus-send-message .office-info.contactus-social .contactus-social-icon:first-child {
        margin-left: 0px; }
      .contactus-send-message .office-info.contactus-social .contactus-social-icon .svg-inline--fa {
        font-size: 15px; }
  .contactus-send-message .contactus-field-input {
    border-radius: 5px;
    min-height: 40px;
    padding: 10px;
    font-size: 13px;
    width: 100%;
    display: block;
    text-align: left;
    /* box-shadow: 0 4px 7px 3px rgba(0,0,0,0.07); */
    border: 1px solid #ddd; }
    .contactus-send-message .contactus-field-input:disabled {
      background: #f2f2f2; }
    .contactus-send-message .contactus-field-input .type-select .react-select__control {
      border: none; }
    .contactus-send-message .contactus-field-input .type-select .react-select__single-value {
      font-size: 13px; }
    .contactus-send-message .contactus-field-input.without-label-dropdown {
      padding: 0px; }
      .contactus-send-message .contactus-field-input.without-label-dropdown .type-select {
        padding: 10px 0px; }
        .contactus-send-message .contactus-field-input.without-label-dropdown .type-select .react-select__value-container {
          padding: 2px 10px; }
    .contactus-send-message .contactus-field-input.react-select-box-container.contact-option-dropdown {
      box-shadow: inherit;
      width: 100%;
      padding: 0px; }
      .contactus-send-message .contactus-field-input.react-select-box-container.contact-option-dropdown .react-select-box {
        padding: 17px 0; }
        .contactus-send-message .contactus-field-input.react-select-box-container.contact-option-dropdown .react-select-box:focus {
          box-shadow: inherit; }
        .contactus-send-message .contactus-field-input.react-select-box-container.contact-option-dropdown .react-select-box .react-select-box-label {
          font-weight: normal;
          font-size: 13px; }
      .contactus-send-message .contactus-field-input.react-select-box-container.contact-option-dropdown .react-select-box-options {
        width: 100%; }
  .contactus-send-message .contactus-info {
    display: block;
    font-size: 14px;
    word-break: break-word;
    line-height: 22px; }
    .contactus-send-message .contactus-info.contactus-social .contactus-social-icon {
      display: inline-block;
      margin-left: 22px; }
      .contactus-send-message .contactus-info.contactus-social .contactus-social-icon:first-child {
        margin-left: 0px; }
      .contactus-send-message .contactus-info.contactus-social .contactus-social-icon .svg-inline--fa {
        font-size: 15px; }

.download-kit {
  font-size: 17px;
  display: inline-block;
  text-align: right; }
  .download-kit .pdf-icon {
    font-size: 22px;
    color: #ff0000;
    padding-left: 5px; }
